import { useQuery } from "@tanstack/react-query";
import { getListByUrl } from "../../../actions/common_actions";
import { common_req } from "../../../helpers/common_helper";
import { RhfObjTypeOptionProp } from "../../../type";
import RHFAutocomplete from "../rhfComponents/rhfAutoComplete";
import { ReactNode } from "react";
import { ERROR_SHORT_INFO, FETCHING_SHORT_INFO } from "../../../consts";

type AudtiorsGetResProps = { data: { list: RhfObjTypeOptionProp[] } };

const AuditorsListAutoComplete = ({
  name,
  label,
  endIcon,
  actAs,
  roleCodes,
}: {
  name: string;
  label?: string;
  endIcon?: ReactNode;
  actAs: "dd" | "filter";
  roleCodes: string[];
}) => {
  // const
  const reqObj = common_req({} as any);

  // query
  const { data: auditorsList, isError } = useQuery<AudtiorsGetResProps>({
    queryKey: ["list_of_auditors", ...roleCodes],
    queryFn: () =>
      getListByUrl("common", "priv_users", {
        ...reqObj,
        priv_code: "",
        status: "enabled",
        role_codes: roleCodes,
      }),
    staleTime: 1000 * 60 * 5,
  });

  // options
  const auditorsOptions: RhfObjTypeOptionProp[] = [
    { name: "All (auditors)", id: "all" },
    ...((auditorsList &&
      auditorsList?.data.list
        .map((l) => ({
          name: l.name.split(" ")[0], // Split the name and take the first part
          id: l.id,
        }))
        .sort(
          // sorting by alphabetic asc
          (a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        )) || [
      {
        name: isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO,
        id: isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO,
      },
    ]),
  ];

  return (
    <RHFAutocomplete
      name={name}
      label={label!!}
      options={auditorsOptions}
      sx={{
        // overides the end icon style
        "& .MuiButtonBase-root": {
          ...(actAs === "filter" && { transform: "none" }),
          svg: {
            width: "20px",
            height: "20px",
            color: (theme) =>
              // @ts-ignore
              theme.palette.custom_colors.secondary_a_200,
          },
        },
      }}
      popupIcon={endIcon!!}
      getOptionLabel={(option: RhfObjTypeOptionProp | string) =>
        typeof option === "object" ? option.name : option
      }
      getOptionKey={(option: RhfObjTypeOptionProp | string) =>
        typeof option === "object" ? option.id : option
      }
      isOptionEqualToValue={(option, value) =>
        typeof option === "object" && typeof value === "object"
          ? option.id === value.id
          : option === value
      }
      disableClearable
    />
  );
};

export default AuditorsListAutoComplete;
