import { Box, Skeleton } from "@mui/material";
import { DataGrid, GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import ErrorUI from "./components/errorUI";
import DarkBgCommonStyleContainer from "./container/darkBgCommonStyleContainer";

const DataTableLoadingOrErrorUI = ({
  isError,
  errorText,
  loadingRows,
  loadingColumns,
}: {
  isError: boolean;
  errorText: string;
  loadingRows: number;
  loadingColumns: number;
}) => {
  // error UI
  if (isError) return <ErrorUI errorText={errorText} />;

  const columns = new Array(loadingColumns).fill("").map((_l, idx) => ({
    field: String(idx),
    headerName: "",
    headerAlign: idx === 0 ? "left" : "center",
    flex: 1,
    renderCell: () => (
      <Box
        display="flex"
        alignItems="center"
        height="100%"
        justifyContent={idx === 0 ? "left" : "center"}
      >
        <Box>
          <Skeleton
            variant="rectangular"
            height={20}
            width={150}
            sx={{ borderRadius: 2 }}
          />
        </Box>
      </Box>
    ),
    renderHeader: () => (
      <Skeleton
        variant="rectangular"
        height={20}
        width={150}
        sx={{ borderRadius: 2 }}
      />
    ),
  }));

  const rows = Array.from({ length: loadingRows }, () => ({
    id: Math.random(),
  }));

  return (
    <DarkBgCommonStyleContainer>
      <DataGrid
        rows={rows}
        columns={columns as unknown as GridColDef<GridValidRowModel>[]}
        disableRowSelectionOnClick
        disableColumnMenu
        disableAutosize
        disableColumnFilter
        disableColumnResize
        disableColumnSelector
        disableColumnSorting
        disableDensitySelector
        disableEval
        disableMultipleRowSelection
        disableVirtualization
        hideFooterPagination
        rowCount={undefined}
      />
    </DarkBgCommonStyleContainer>
  );
};

export default DataTableLoadingOrErrorUI;
