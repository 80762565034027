import React, { useState } from "react";
import { check_priv, Config, get } from "../../helpers/storage_helper";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MoveUpOutlinedIcon from "@mui/icons-material/MoveUpOutlined";
import TravelExploreSharpIcon from "@mui/icons-material/TravelExploreSharp";
import GroupRemoveRoundedIcon from "@mui/icons-material/GroupRemoveRounded";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import { getValueFromLangJson } from "../../helpers/common_helper";
import { useEffect } from "react";
import { role_based_screen_menus } from "../../helpers/users_menus";
import { useSelector } from "react-redux";
const MenuList = (Component) => {
  return function WrappedComponent(props) {
    const users_menu_list = Config("user_menu_list") ?? role_based_screen_menus;
    const { market } = useSelector((state) => state.HeaderSlice);
    let country = get("market") ? get("market").country_code : "";
    const [country_code, setcountry_code] = useState(country);

    let role_codes = get("role_codes") ? get("role_codes") : null;

    useEffect(() => {
      if (market && market.country_code) {
        setcountry_code(market.country_code);
      }
    }, [market]);
    const get_previleges = () => {
      let prev = get("privileges");
      let prev_obj = {};
      if (prev && Object.keys(prev).length > 0) {
        for (let key in prev) {
          let values = prev[key].split(",");
          let obj = {};
          values.forEach((value) => {
            obj[value] = true;
          });
          prev_obj[key] = obj;
        }
      }
      return prev_obj;
    };
    var privileges = get_previleges();

    const call_log_search_cond =
      privileges.call_log &&
      (privileges.call_log.all_search || privileges.call_log.self_search) &&
      !["app_support", "l2_support", "customer_success"].includes(
        get("role_codes")
      );
    const [main_menus, setMainmenus] = useState([
      {
        label: getValueFromLangJson("home"),
        icon: <HomeOutlinedIcon />,
        key: "home",
        prev: true,
      },
      { label: "KYC Audit", icon: <FindInPageOutlinedIcon />, key: "audit" },
      {
        label: "Dashboard",
        icon: <LeaderboardOutlinedIcon />,
        key: "dashboard",
      },
      {
        label: "Operations",
        icon: <ManageAccountsOutlinedIcon />,
        key: "operations",
      },
      {
        label: "Transactions",
        icon: <MoveUpOutlinedIcon />,
        key: "transactions",
      },
      { label: "Reports", icon: <TravelExploreSharpIcon />, key: "reports" },
      { label: "Market", icon: <GroupRemoveRoundedIcon />, key: "market" },
      { label: "Search", icon: <SearchOutlinedIcon />, key: "search" },
      { label: "Utilities", icon: <WidgetsOutlinedIcon />, key: "utilities" },
    ]);

    useEffect(() => {
      if (role_codes) {
        let menus = [
          ...(users_menu_list[role_codes]
            ? users_menu_list[role_codes]
                .map((key) => {
                  const menu = main_menus.find((menu) => menu.key === key);
                  return menu ? { ...menu, prev: true } : null;
                })
                .filter(Boolean)
            : main_menus.map((menu) => ({ ...menu, prev: false }))),
        ];
        setMainmenus(menus);
      }
    }, []);

    const sub_menus = {
      dashboard: [
        {
          label: "Auditor Performance",
          url: "/auditor_performance",
          prev: check_priv("dashboard", "audit_performance"),
          key: "auditor_performance",
        },
        {
          label: "Customer Location",
          url: "/cust_location",
          prev: check_priv("customer", "location"),
          key: "customer_location",
        },
        {
          label: "Live Dashboard",
          url: "/management-dashboard",
          prev: check_priv("mgmt", "live_dashboard"),
          key: "live_dashboard",
        },
        {
          label: "Monthly Dashboard",
          url: "/monthly-report",
          prev: check_priv("report", "mgmt_dashboard_monthly"),
          key: "monthly_dashboard",
        },
        {
          label: "Charts",
          url: "/charts",
          prev: check_priv("mgmt", "charts"),
          key: "charts",
        },

        {
          label: "Business Operations",
          sub_menus: [
            {
              label: "live",
              url: "/business_operations/live",
              prev: true,
              fuzzy_key: "business operations dashboard live ",
              key: "biz_ops_live",
            },
            {
              label: "past",
              url: "/business_operations/past",
              prev: true,
              fuzzy_key: "business operations dashboard past",
              key: "biz_ops_past",
            },
          ],
          prev: check_priv("biz_ops", "live_dashboard"),
          key: "business_operations",
        },
        {
          label: "RM Performance",
          url: "/rm_performance",
          prev: check_priv("report", "coo_dashboard"),
          key: "rm_performance",
          fuzzy_key: "rm performance dashboard",
        },
      ],
      operations: [
        {
          label: "Lead Pipeline",
          url: "/lead/lead_pipeline",
          prev: check_priv("lead", "lead_pipeline"),
          key: "lead_pipeline",
        },
        {
          label: "Swap Accounts",
          url: "/switch/disb_account",
          prev: check_priv("swap", "swap_accounts"),
          key: "swap_accounts",
        },
        {
          label: "Softphone Management",
          prev: check_priv("cs_mgnt", "softphone_mngt"),
          sub_menus: [
            {
              label: "Rosters",
              url: "/cs_management/cs_rosters",
              prev:
                check_priv("cs_mgnt", "cs_rostets") && country_code == "UGA",
              key: "rosters",
            },
            {
              label: "CS & RMSO Call Logs",
              url: "/inbound_call_list",
              prev:
                check_priv("cs_mgnt", "cs_rmso_call_logs") &&
                country_code == "UGA",
              key: "csrmso_call_logs",
            },
            {
              label: "Soft Phone",
              url: "/cs_managements/soft_phone",
              prev: check_priv("cs_mgnt", "soft_phone"),
              key: "soft_phone",
            },
          ],
          key: "softphone_management",
        },
        {
          label: "Churn Management",
          url: "/cust_follow_up",
          prev: check_priv("churn_mgnt", "churns") && country_code == "UGA",
          key: "churn_management",
        },
        {
          label: "Credit Scoring",
          prev: privileges.score,
          sub_menus: [
            {
              label: "Score Models",
              url: "/score_models",
              prev: privileges.score && privileges.score.list,
              key: "score_models",
            },
            {
              label: "Score Factors",
              url: "/score_factors",
              prev: privileges.score && privileges.score.factor_list,
              key: "score_factors",
            },
          ],
          key: "credit_scoring",
        },
        {
          label: "RM Management",
          prev:
            check_priv("rm_mgnt", "rm_target") ||
            check_priv("rm_mgnt", "rm_assign"),
          sub_menus: [
            { label: "Targets", url: "/assign/rm_target", prev: true },
            {
              label: "Live Routes",
              url: "/rm_live_routes",
              prev: check_priv("rm", "routes"),
              key: "live_routes",
            },
            {
              label: "Live Locations",
              url: "/rm_live_locations",
              prev: check_priv("rm", "routes"),
              key: "live_locations",
            },
            {
              label: "Reassignment",
              url: "/reassign",
              prev: check_priv("rm_mgnt", "rm_assign"),
              key: "reassignment",
            },
            {
              label: "RM Productivity Report",
              url: "/report/rm_productivity_report",
              key: "rm_productivity_report",

              prev:
                privileges.report.rm_report &&
                ![
                  "it_admin",
                  "market_admin",
                  "ops_admin",
                  "ops_analyst",
                ].includes(get("role_codes")),
            },
            {
              label: "RM Wise Repayment Report",
              url: "/report/rm_report",
              key: "rm_wise_repay",
              prev:
                privileges.report.rm_report &&
                ![
                  "it_admin",
                  "market_admin",
                  "ops_admin",
                  "ops_analyst",
                ].includes(get("role_codes")),
            },
          ],
          key: "rm_management",
        },
        {
          label: "Name Verification",
          url: "modal",
          prev: check_priv("cust", "name_verify"),
          modal_type: "name_verification",
          key: "name_verification",
        },
        {
          label: "Master Data",
          url: "/master_data_key/list",
          prev: privileges.data_key && privileges.data_key.list_view,
          key: "master_data",
        },
        {
          label: "Capital Funds",
          url: "/report/capital_funds",
          prev: privileges.report.capital_funds,
          key: "capital_funds",
        },
        {
          label: "Configuration",
          url: "modal",
          prev: check_priv("app", "config"),
          modal_type: "configuration",
          key: "configuration",
        },
        {
          label: "Agreement",
          url: "/agreement/list",
          prev: check_priv("biz_ops", "agreement"),
          key: "agreement",
        },
        {
          label: "OTP",
          url: "/otp/rbok",
          prev: check_priv("biz_ops", "otp") && country_code == "RWA",
          key: "otp",
        },
        {
          label: "WhatsApp Connect",
          url: "/whatsapp",
          prev: check_priv("biz_ops", "whatsapp"),
          key: "whatsapp_content",
        },
        {
          label: "Recovery Tasks",
          prev: check_priv("delinquency", "recovery_task"),
          sub_menus: [
            {
              label: "Overdue Recoveries",
              url: "/call_tasks",
              prev: check_priv("delinquency", "recovery_task"),
              key: "overdue_recoveries",
            },
            {
              label: "Pending RM Visits",
              url: "/pending_rm_visits",
              key: "pending_rm_visits",

              prev: check_priv("delinquency", "recovery_task"),
            },
          ],
          key: "recovery_tasks",
        },
        {
          label: "Customer Location",
          url: "/cust_location",
          prev:
            check_priv("customer", "location") &&
            ![
              "market_admin",
              "ops_admin",
              "risk_compl_head",
              "super_admin",
              "it_admin",
              "ops_analyst",
            ].includes(get("role_codes")),
          key: "customer_location",
        },

        {
          label: "Call Log",
          url: "/call_log/search",
          prev: get("role_codes") == "rm_support",
          key: "call_log",
        },

        {
          label: "Softphone",
          url: "/cs_managements/soft_phone",
          prev: check_priv("cs_mgnt", "soft_phone"),
          key: "softphone",
        },

        {
          label: "RM Performance",
          url: "/rm_performance",
          prev:
            ![
              "ops_analyst",
              "ops_admin",
              "super_admin",
              "it_admin",
              "risk_compl_head",
              "market_admin",
            ].includes(get("role_codes")) &&
            check_priv("report", "coo_dashboard"),
          key: "rm_performance",
        },

        {
          label: "Flagged Customers",
          url: "/flagged_customers",
          prev: check_priv("customer", "flag_report"),
          key: "flagged_customers",
        },
        {
          label: "Watchlist Customers",
          url: "/watchlist_customers",
          prev: ["risk_compl_head", "mce_officer"].includes(get("role_codes")),
          key: "watchlist_customers",
        },

        {
          label: "Business Operations",
          prev:
            check_priv("biz_ops", "sms_logs") ||
            check_priv("biz_ops", "sms_vendors"),
          sub_menus: [
            {
              label: "SMS Logs",
              url: "/sms_logs",
              prev: check_priv("biz_ops", "sms_logs"),
              key: "sms_logs",
            },
            {
              label: "SMS Vendors",
              url: "/switch_sms_vendors",
              prev: check_priv("biz_ops", "sms_vendors"),
              key: "sms_vendors",
            },
          ],
          key: "business_operations",
        },
        {
          label: "Account Settlement",
          url: "/settlement/list",
          prev:
            check_priv("settlement", "summary") &&
            !["it_admin", "ops_analyst"].includes(get("role_codes")),
          key: "account_settlement",
        },
        {
          label: "Duplicate Transaction",
          url: "/duplicate-txns-report",
          prev:
          check_priv("report","dup_txn_report") ,
          key: "duplicate_txns_report",
        },

     
      ],
      transactions: [
        {
          label: "Statement",
          url: "/lender/accounts",
          prev: privileges.recon && privileges.recon.search,
          key: "statement",
        },
        {
          label: "Add Transaction",
          url: "/statement/add",
          prev: privileges.recon && privileges.recon.add_stmt,
          key: "add_transaction",
        },
        {
          label: "Search Statement Import",
          url: "/stmt_imports/search",
          prev: check_priv("biz_ops", "reports"),
          key: "search_statement_import",
        },
        {
          label: "Unknown Transactions",
          url: "/unknown_txns",
          prev: privileges.recon && privileges.recon.search,
          key: "unknown_transactions",
        },
        {
          label: "Unimported transactions",
          url: "/unimported_txns",
          prev: privileges.recon && privileges.recon.search,
          key: "unimported_transactions",
        },
        {
          label: "Manual Capture Transactions",
          url: "/manual_capture_txns",
          prev:
            privileges.recon &&
            privileges.recon.manual_capture_txns &&
            !["market_admin", "ops_admin", "ops_analyst", "it_admin"].includes(
              get("role_codes")
            ),
          key: "manual_capture_transactions",
        },
      ],
      reports: [
        {
          label: "Switch ",
          url: "/switch_reports",
          prev: check_priv("report", "switch_report"),
          key: "reports_switch",
        },

        {
          label: "Portfolio",
          prev: privileges.report,
          sub_menus: [
            {
              label: "Portfolio Health",
              url: "/report/par_health_report",
              prev: privileges.report.par_health_report,
              key: "reports_portfolio_health",
            },
            {
              label: "Portfolio Quality",
              url: "/report/portfolio_quality",
              prev: privileges.report.portfolio_quality,
              key: "reports_portfolio_quality",
            },
            {
              label: "Historical PAR",
              url: "/portfolio_at_risk",
              prev: privileges.report.risk_portfolio,
              key: "reports_historical_par",
            },
          ],
          key: "reports_portfolio",
        },
        {
          label: "Overdues",
          prev:
            check_priv("report", "rm_rms_calls") ||
            check_priv("report", "rm_visit") ||
            check_priv("report", "overdue_report") ||
            check_priv("report", "partial_payment") ||
            check_priv("report", "cohort_report"),
          sub_menus: [
            {
              label: "RM / RMS Overdue Calls",
              url: "/rm_rms_call_report",
              prev: check_priv("report", "rm_rms_calls"),
              key: "reports_rm_rms_overdue_calls",
            },
            {
              label: "RM Overdue Visits",
              url: "/rm_visits_report",
              prev: check_priv("report", "rm_visit"),
              key: "reports_rm_overdue_visits",
            },
            {
              label: "Overdue FAs",
              url: "/overdue_report",
              prev: check_priv("report", "overdue_report"),
              key: "reports_overdue_fas",
            },
            {
              label: "Partial payments",
              url: "/partial_payments",
              prev: check_priv("report", "partial_payment"),
              key: "reports_partial_payments",
            },
            {
              label: "Recovery Cohort ",
              url: "/cohort_report",
              prev: check_priv("report", "cohort_report"),
              key: "reports_recovery_cohort",
            },
          ],
          key: "reports_overdues",
        },
        {
          label: "Daily Operations",
          prev: privileges.report && privileges.report.daily_activity_admin,
          sub_menus: [
            {
              label: "Overdue FA Repayments",
              url: "/report/overdue_fa_repayments",
              prev: ![
                "customer_success",
                "customer_success_officer",
                "risk_compl_head",
              ].includes(get("role_codes")),
              key: "reports_overdue_fa_repayments",
            },
            {
              label: "Daily Visits",
              url: "/report/daily_visits",
              key: "reports_daily_visits",
              prev:
                privileges.report.daily_activity_admin &&
                get("role_code") != "super_admin",
            },
            {
              label: "Daily Agreements",
              url: "/report/daily_agreements",
              key: "reports_daily_agreements",
              prev: privileges.report.daily_activity_admin,
            },
            {
              label: "Daily Activity",
              url: "/report/daily_activity",
              key: "reports_daily_activity",
              prev:
                get("role_codes") !== "ops_analyst" &&
                get("role_codes") !== "it_admin" &&
                privileges.report.daily_activity,
            },
          ],
          key: "reports_daily_operations",
        },
        {
          label: "Self Registration",
          url: "/report/self_registration_report",
          prev: check_priv("report", "self_reg_report"),
          key: "reports_self_registration",
        },
        {
          label: "Product Takeup ",
          url: "/report/product_takeup",
          prev: privileges.report.product_takeup,
          key: "reports_product_takeup",
        },
        {
          label: "Lender",
          url: "/report/fsdu",
          prev: check_priv("report", "fsdu"),
          key: "reports_lender",
        },

        {
          label: "RM Reports",
          prev: privileges.report.rm_report,
          sub_menus: [
            {
              label: "RM Productivity",
              url: "/report/rm_productivity_report",
              prev: privileges.report.rm_report,
              key: "reports_rm_productivity",
            },
            {
              label: "RM wise Repayment",
              url: "/report/rm_report",
              prev: privileges.report.rm_report,
              key: "reports_rm_wise_repayment",
            },
          ],
          key: "reports_rm_reports",
        },

        {
          label: getValueFromLangJson("overdue_fa_repayments"),
          url: "/report/overdue_fa_repayments",
          prev: privileges.report && privileges.report.daily_activity_sipem,
          key: "overdue_fa_repayments",
        },
        {
          label: getValueFromLangJson("overdue_fas"),
          url: "/overdue_report",
          prev: get("role_codes") == "loan_approver",
          key: "overdue_fas",
        },

        {
          label: "Capital Funds",
          url: "/report/capital_funds",
          prev: get("role_codes") == "finance_officer",
          key: "capital_funds",
        },

        // { label: "Overdue FAs", url: "/overdue_report", prev: get('role_codes') == "recovery_specialist"},

        {
          label: "Partial payments",
          url: "/partial_payments",
          prev: ["verification_officer"].includes(get("role_codes")),
          key: "reports_partial_payments",
        },

        {
          label: "Portfolio Health",
          url: "/report/par_health_report",
          prev: ["verification_officer"].includes(get("role_codes")),
          key: "reports_portfolio_health",
        },

        {
          label: "Monthly Report",
          url: "/monthly-report",
          prev: [
            "customer_success",
            "customer_success_officer",
            "mce_officer",
          ].includes(get("role_codes")),
          key: "monthly_report",
        },
        {
          label: "Daily Activity",
          url: "/report/daily_activity",
          prev: privileges.report.daily_activity,
          key: "reports_daily_activity",
        },

        {
          label: "Penalty Pending ",
          url: "/report/penalty_pending_report",
          prev: check_priv("report", "penalty_pending_rpt"),
          key: "reports_penalty_pending",
        },
        {
          label: "SMS ",
          url: "/report/sms_report",
          prev:
            check_priv("biz_ops", "reports") &&
            get("role_codes") != "super_admin",
          key: "reports_sms",
        },
        {
          label: "Manual Capture Transaction ",
          url: "/manual_capture_txns",
          prev:
            !["customer_success"].includes(get("role_codes")) &&
            privileges.recon &&
            privileges.recon.manual_capture_txns,
          key: "reports_manual_capture_transaction",
        },

        {
          label: "Toll Free Success  ",
          url: "/report/call_log_reports",
          prev: check_priv("report", "success_rate_report"),
          key: "toll_free_success",
        },
        {
          label: "Customer Performance ",
          url: "/customer_performance",
          prev: privileges.report.cust_performance,
          key: "customer_performance",
        },
        {
          label: "Outstanding FAs ",
          url: "/outstanding_advances",
          prev: privileges.report.os_advance,
          key: "reports_outstanding_fas",
        },
        {
          label: "Ontime Payments ",
          url: "/report/ontime_pay_report",
          prev: check_priv("report", "ontime_pay_report"),
          key: "reports_ontime_payments",
        },
        {
          label: "Schedulers ",
          url: "/scheduler/list",
          prev: check_priv("report", "scheduler_list_view"),
          key: "reports_schedulers",
        },
        {
          label: "Account Settlement ",
          url: "/settlement/list",
          prev:
            check_priv("settlement", "summary") &&
            get("role_codes") != "super_admin",
          key: "account_settlement",
        },
        {
          label: "CS & RMSO Call Logs ",
          url: "/inbound_call_list",
          prev:
            check_priv("cs_mgnt", "cs_rmso_call_logs") && country_code == "UGA",
          key: "cs_rmso_call_logs",
        },
        {
          label: "Cashbacks ",
          url: "/cashbacks_report",
          prev: check_priv("report", "cashbacks_report"),
          key: "cashbacks_report",
        },
        {
          label: `${
            check_priv("cs_mgnt", "cs_activity_report") ? "CS" : "Auditor"
          } Activity Report`,
          url: "/activity_report",
          prev:
            (check_priv("cs_mgnt", "cs_activity_report") ||
              check_priv("cs_mgnt", "auditor_activity_report")) &&
            country_code == "UGA",
          key: `${
            check_priv("cs_mgnt", "cs_activity_report") ? "cs" : "auditor"
          }_activity_report`,
        },
        {
          label: "Duplicate Transactions ",
          url: "/duplicate-txns-repor",
          prev: check_priv("report", "dup_txn_report"),
          key: "duplicate_txns",
        },
      ],
      search: [
        {
          label: "Customer",
          url: "/borrower/search",
          prev: privileges.customer && privileges.customer.search_list_view,
          key: "customer",
        },
        {
          label: "Lead",
          url: "/lead/search",
          prev: check_priv("lead", "search"),
          key: "search_lead",
        },
        {
          label: "Switch",
          url: "/sales/report",
          prev: check_priv("sales", "search_list_view"),
          key: "reports_switch",
        },

        {
          label: "Flagged Customers",
          url: "/flagged_customers",
          prev:
            check_priv("customer", "flag_report") &&
            !["risk_compl_head", "ops_admin"].includes(get("role_codes")),
          key: "flagged_customers",
        },
        {
          label: "Watchlist Customers",
          url: "/watchlist_customers",
          prev:
            check_priv("customer", "watchlist_reports") &&
            !["risk_compl_head", "mce_officer"].includes(get("role_codes")),
          key: "watchlist_customers",
        },

        {
          label: "Float Advance",
          url: "/fa/search",
          prev: privileges.loan && privileges.loan.search_list_view,
          key: "float_advance",
        },

        {
          label: "FA Application",
          url: "/fa_appl/search",
          prev:
            privileges.application && privileges.application.search_list_view,
          key: "search_fa_applications",
        },
        {
          label: "Customer Visits",
          url: "/field_visit/search",
          prev:
            privileges.field_visit &&
            (privileges.field_visit.all_search ||
              privileges.field_visit.self_search),
          key: "customer_visits",
        },

        {
          label: "Complaints",
          url: "/complaint_lists",
          prev: check_priv("cust", "raised_complaints"),
          key: "complaints",
        },
        {
          label: "Re-KYC",
          url: "/search_rekyc_leads",
          prev:
            get("role_codes") != "loan_approver" &&
            check_priv("re_kyc", "search"),
          key: "re_kyc",
        },
        {
          label: "Churn",
          url: "/churn_reports",
          prev:
            get("role_codes") != "loan_approver" &&
            check_priv("churns", "search"),
          key: "churn",
        },
        {
          label: "Product Upgrades",
          url: "/search_product_upgrade",
          prev: check_priv("prdt_upgrade", "search"),
          key: "product_upgrades",
        },

        {
          label: "Call Logs",
          url: "/call_log/search",
          prev: call_log_search_cond,
          key: "call_logs",
        },

        {
          label: "Products",
          url: "/fa_product/list",
          prev: privileges.product && privileges.product.search_list_view,
          key: "products",
        },
      ],
      utilities: [
        {
          label: "Call Logs",
          url: "/call_log/search",
          prev:
            privileges.call_log &&
            (privileges.call_log.all_search || privileges.call_log.self_search),
          key: "call_logs",
        },
        {
          label: "SMS Logs",
          url: "/sms_logs",
          prev: check_priv("biz_ops", "reports"),
          key: "sms_logs",
        },
        {
          label: "Rosters",
          url: "/cs_management/cs_rosters",
          prev: check_priv("cs_mgnt", "cs_rostets") && country_code == "UGA",
          key: "rosters",
        },
        {
          label: "CS & RMSO Call Logs",
          url: "/inbound_call_list",
          prev:
            check_priv("cs_mgnt", "cs_rmso_call_logs") && country_code == "UGA",
          key: "cs_rmso_call_logs",
        },
        {
          label: "Account Settlement",
          url: "/settlement/list",
          prev: check_priv("settlement", "summary"),
          key: "account_settlement",
        },
      ],
      market: [
        {
          label: "Markets",
          sub_menus: [
            {
              label: "Create Market",
              url: "/market/create",
              prev: privileges.market && privileges.market.create,
              key: "create_market",
            },
            {
              label: "List Markets",
              url: "/market/list",
              prev: privileges.market && privileges.market.list_view,
              key: "list_markets",
            },
          ],
          prev: privileges.market,
          key: "markets",
        },
        {
          label: "Flow Users",
          sub_menus: [
            {
              label: "Create Flow User",
              url: "/user/create",
              prev: privileges.user && privileges.user.create,
              key: "create_flow_user",
            },
            {
              label: "List Flow Users",
              url: "/user/list",
              prev: privileges.user && privileges.user.list_view,
              key: "list_flow_users",
            },
          ],
          prev: privileges.user && privileges.user.list_view,
          key: "flow_users",
        },

        {
          label: "Investors",
          sub_menus: [
            {
              label: "Create Investor",
              url: "/investor/create",
              prev: privileges.acc_prvdr && privileges.acc_prvdr.list_view,
              key: "create_investor",
            },

            {
              label: "List Investors",
              url: "/investor/list",
              prev: true,
              key: "list_investors",
            },
          ],
          prev:
            get("role_codes").includes("super_admin") ||
            get("role_codes").includes("ops_analyst"),
          key: "investors",
        },

        {
          label: "Lenders",
          sub_menus: [
            {
              label: "Create Lender",
              url: "/lender/create",
              prev: privileges.lender && privileges.lender.create,
              key: "create_lender",
            },
            {
              label: "List Lenders",
              url: "/lender/list",
              key: "list_lenders",
              prev: privileges.lender && privileges.lender.list_view,
            },
          ],
          prev: privileges.lender,
          key: "lenders",
        },

        {
          label: "Account Providers",
          sub_menus: [
            {
              label: "Create Account Provider",
              url: "/account_provider/create",
              prev: privileges.acc_prvdr && privileges.acc_prvdr.create,
              key: "create_account_provider",
            },
            {
              label: "List Account Providers",
              url: "/account_provider/list",
              prev: privileges.acc_prvdr && privileges.acc_prvdr.list_view,
              key: "list_account_providers",
            },
          ],
          prev: privileges.acc_prvdr,
          key: "account_providers",
        },
      ],
      audit: [
        {
          label: "Assigned for Me",
          url: "/assigned_leads",
          prev: check_priv("lead", "kyc_list"),
          key: "audit_assigned_for_me",
        },
        {
          label: "Unassigned for Audit",
          url: "/unassigned_leads",
          prev: check_priv("lead", "kyc_list"),
          key: "audit_unassigned_for_audit",
        },

        {
          label: "Pending with RM for Rectification",
          url: "/leads_pending_wrm",
          prev: check_priv("lead", "rm_rectify"),
          key: "audit_pending_with_rm_rectification",
        },
        {
          label: "In progress at customer’s place",
          url: "/inprogress_cust",
          prev: check_priv("lead", "audit_inprogress"),
          key: "audit_in_progress_at_customer",
        },
        {
          label: "Leads with pending KYC audit",
          url: "/pending_kyc",
          prev: check_priv("lead", "pending_kyc"),
          key: "audit_leads_with_pending_kyc_audit",
        },
      ],
    };
    return (
      <Component {...props} sub_menus={sub_menus} main_menus={main_menus} />
    );
  };
};
export default MenuList;
