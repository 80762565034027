import React, { useState, useEffect }  from 'react';
import { FaUserPlus,FaRegMoneyBillAlt} from 'react-icons/fa';
import{GrCurrency}from 'react-icons/gr';
import { GiTakeMyMoney } from 'react-icons/gi';
import { RiUserSearchFill } from 'react-icons/ri';
import { BsPersonCheck } from 'react-icons/bs';
import { GiArchiveResearch,GiClick } from 'react-icons/gi';
import { CgProfile} from 'react-icons/cg';
import { GrSchedule } from 'react-icons/gr';
import { VscCallIncoming,VscCallOutgoing } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import {format_date,lbl_amt} from './../../helpers/common_helper';
import {dd_value} from '../../actions/common_actions';
import './../../styles/flow.css';
import {Form,Modal,Col,Row} from 'react-bootstrap';
import FlowComponent from '../common/component/core/FlowComponent';
import {validateCustomer} from '../../actions/borrower_actions';
import {submitCheckin} from '../../actions/field_visit_actions';
import {StartCallLog} from '../../actions/call_log_actions';
import InstantDisburseLoan from './../loan/component/InstantDisburseLoan';
import {get,get_item,check_priv} from '../../helpers/storage_helper';
import {repeatFloatAdvance,get_repayment_summary,create_capture_payment} from '../../actions/loan_actions';
import moment from 'moment';
import {Tabs,Tab} from 'react-bootstrap';
import { getHolidayList } from '../../actions/lead_actions';
// import Flag from "react-flags";

import { MdRemoveRedEye } from "react-icons/md";


import {FaFlag} from 'react-icons/fa';
import CustomFlag from '../common/component/CustomFlag';

class HomeLinks extends FlowComponent{
    state = {
        cust_token: false,
        modalBox :false,
        capture_payment:false,
        cust_profile : 'hide_div',
        repayment_style :'disable_div',
        loan_type:"",
        loan_data :{},
        call_log :{},
        cust_data:{},
        checkin_req :{},
        timer_sec :0,
        timer : '',
        timer_min:0,
        reviewed : 0,
        opacity : "",
        market_holidays:[],
        indian_holidays:[],
        loader:false,
        common_holidays:[],
        disable_apply_fa : false
    }

    tick() {
        if(this.state.timer_sec == 0 || this.state.timer_sec != 0 ){
            this.setState({timer_sec: this.state.timer_sec + 1});
        if(this.state.timer_sec == 60){
            this.setState({
                timer_min:this.state.timer_min +1,
                timer_sec : 0
            })
        }
      }else{
        this.refresh_timer()
      }     
    }

    handle_custID_change = (event) => {
        this.setState({cust_token : event.target.value})       
    }
    refresh_timer = () =>{
        setInterval(() => this.tick(),1000) 
    }
    
    handleIncomingCall = () =>{
        this.setState({modalBox:true})
        this.server_time()
        if(this.state.timer_sec == 0){
            this.refresh_timer()
        }else{
            this.setState({timer_sec:0,timer_min:0})
        }
    }
    
    handleClose = (event) => {
        this.setState({modalBox: false, cust_profile : 'hide_div',cust_data:{}});
        //window.location.reload();
    }
    
    CaptureModalClose = (event) =>{
        this.setState({capture_payment: false,opacity:""});
    }

    submit_capture_payment = (event) =>{
        const resp = window.confirm("Are you sure capture the payment?");
            if(resp == false){
                return;
            }
        const request = {loan_doc_id : this.state.selected_payment.loan_doc_id,
                         acc_stmt_txn_id : this.state.selected_payment.acc_stmt_txn_id, 
                         amount : this.state.selected_payment.amount}
        create_capture_payment (this.req(request))
            .then((response)=>{
                if(!response){return null;}
                if(response.status === "success"){
                    this.setState({opacity:""})
                    alert('Payment captured successfully')
                    this.handleValidateCustomer();
                    // window.location.reload();
                    this.CaptureModalClose()
                }else{
                  alert(response.message)
                }
        })
    }

    componentWillMount(){
        this.get_holidays()
    }
    get_holidays=()=>{
        this.setState({loader:true})
        var request={
            days:14
        }
				getHolidayList(this.req(request))
				.then((response)=>{	
                    this.resp(response)
					if(!response){return this.setState({loader:false})};
					if(response.status == "success"){
                        this.setState({loader:false})
						this.setState({market_holidays:response.data.market_holidays,indian_holidays:response.data.indian_holidays,common_holidays:response.data.common_holidays})
					}
				 })
}
    /*capture_repayment = (event) => {
        this.setState({acc_stmts :{acc_stmt_txn_id:this.state.cust_data.acc_stmts.id , loan_doc_id:this.state.cust_data.ongoing_loan_doc_id }});
        const request = this.state.acc_stmts
        get_repayment_summary(this.req(request))
            .then((response) => {
                if(!response){return null;}
                if(response.status === "success"){
                  this.setState({capture_payment: true, selected_payment : response.data,opacity:0.9});
                }else{
                  alert(response.message)
                }
        })
  // loan_doc_id, acc_Stmt_id, amount

    }

    repeatFloatAdvance = (event) => {
        const resp = window.confirm("Are you sure to confirm?");
        if(resp == false){
            return;
        }
        const request = {"cust_id" : this.state.cust_data.cust_id, "loan_doc_id" : this.state.cust_data.last_loan_doc_id};
        repeatFloatAdvance(this.req(request))
            .then((response) => {
                if(!response){return };
                    if(response){ 
                  \
                        const loan_appl_doc_id = response.data.loan.loan_appl_doc_id;   
                        const approver_name = response.data.loan.loan_approver_name;    
                        this.setState({approver_name});
                        alert(response.message+" and submitted to "+approver_name);
                        this.handleValidateCustomer();
     
                    }else if (response.data.loan){
                        const loan_doc_id = response.data.loan.loan_doc_id
                        this.setState({loan_doc_id, viewLoan: true});
                        alert(response.message);
                    }
                    else{
                        alert(response.message)
                    }
                        
        });
    }

    handleReviewChange = (event) => {
      const target = event.target;
      const value = target.checked;   
      var reviewed = this.state.reviewed;
      var repayment_style = "disable_div"
      if(value){
        reviewed++
      }else{
        reviewed--
      }
      
      if(reviewed == 6){
        repayment_style = ""
      }
      this.setState({
       reviewed, repayment_style
      });
    }*/


    handleCallLog = (target,cust_id,cust_name) =>{
        var resp = null;
        if(target == "incoming"){
            resp = window.confirm(`Are you sure right now you have received a call from the ${cust_name} and speaking with the customer ?`)
        }else if (target == "outgoing"){
            resp = window.confirm(`Are you sure right now you have dialed ${cust_name} and speaking with the customer ?`)
        }
        if(resp == false){
            return;
        }else{
            var call_log = this.state.call_log
            call_log.cust_id = cust_id
            call_log.call_type = target
            StartCallLog(this.req({call_log}))
                .then((response)=> {
                    this.resp(response);
                    if(!response){return };
                    if(response.status === "success"){
                        const  log = response.data
                        var resp = true;
                        if(log.action == "lastcall"){
                            resp = window.confirm(log.message);
                        }
                        if(resp && log.call_log_id){
                            //window.open("/fa/view/"+loan_doc_id,"_blank");
                            window.open("/compl_call_log/"+log.cust_id +"/"+ log.call_log_id + '?type=' + target,"_blank")
                        }
                        }else{
                            alert(response.message  +"\n" + JSON.stringify(response.data));  
                        }
            })
        }
  }

    handleCheckIn = (target,cust_id,cust_name) =>{
        const resp = window.confirm(`Are you sure, right now you are at ${cust_name}'s business location and would like to check-in?`)
        if(resp === false){
            return;
        }else{
            var checkin_req = this.state.checkin_req
            checkin_req.cust_id =  cust_id    
            submitCheckin(this.req({checkin_req}))
               .then((response) => {
                this.resp(response);
                    if(!response){return };
                    if(response.status === "success"){
                        const  visit = response.data
                        var resp = true;
                        if(visit.action == "lastvisit"){
                            resp = window.confirm(visit.message);
                        }
                        if(resp && visit.visit_id){
                            window.open("/checkout/"+visit.cust_id+"/"+visit.visit_id+'?type=' + target,"_blank")
                        }
                     
                    }else{
                        alert(response.message  +"\n" + JSON.stringify(response.data));  
                    }
            })
    }

    /*
  
    this.setState({modalBox2: "true"});
    const request = {"cust_id" : this.props.customer.cust_id};
      this.setState({cust_id: this.props.customer.cust_id});

    touch(this.req(request))
           .then((response) => {
            
           })*/

  }
   
    handleValidateCustomer = (event) =>{
        if(event){
           this.target = event.target.id 
           if(event.target.id == 'apply_fa'){
            this.setState({disable_apply_fa:true})
           }
        }
        const cust_token = this.state.cust_token
        if(cust_token){
            validateCustomer(this.req({cust_token,incl_profile:true},true)).
                then((response) =>{
                    this.resp(response);
                    this.setState({disable_apply_fa:false})
                    if(!response){return };
                    if(response.status === "success"){
                        if(response.data && response.data.cust_id){
                            if(this.target == 'log_visit'){
                              this.handleCheckIn(this.target,response.data.cust_id,response.data.cust_name)
                            }else if(this.target == 'incoming' || this.target == 'outgoing' ){
                              this.handleCallLog(this.target,response.data.cust_id,response.data.cust_name)
                              //window.open(`/call_log/${response.data.cust_id}`,"_blank")
 
                            }else if(this.target == 'apply_fa'){
                                window.open(`/fa_appl_for/${response.data.cust_id}`,"_blank")
                                
                            }else if(this.target == 'view_profile'){
                                window.open(`/borrower/indiv/view/${response.data.cust_id}`,"_blank")
                            }

                        }

                    }else{
                        alert(response.message  +"\n" + JSON.stringify(response.data));  
                    }
                })
        }else{
            alert("Please Enter Customer ID / Mobile Number / National ID")
            return false
        }
    }

    RepaymentSummaryModal = (summary,loan_data) => {
    if(!summary){
      return null
    }
    return(
        <Modal show={this.state.capture_payment} onHide={this.CaptureModalClose}  size="l">
          <Modal.Header closeButton show={this.state.capture_payment} onHide={this.CaptureModalClose}>
              <Modal.Title>Capture FA Payment ({summary.loan_doc_id})</Modal.Title>
          </Modal.Header>
          <Modal.Body>        
              <div className="container">
              <p>Review the below payment data by clicking over them. </p>
              <form>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Account txn ID</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_1" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                          <label className="select_toogle" for="toggle_1">{summary.stmt_txn_id} </label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Txn description</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_2" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                          <label className="select_toogle" for="toggle_2">{summary.descr}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Customer</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_3" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                          <label className="select_toogle" for="toggle_3">{summary.cust_name} | {summary.biz_name}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Cust ID</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_4" className="toggle visually-hidden" onChange={this.handleReviewChange} value="2"/>
                          <label className="select_toogle" for="toggle_4">{summary.cust_id} | {summary.acc_number}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Payment amount</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_5" className="toggle visually-hidden" onChange={this.handleReviewChange} value="3"/>
                          <label className="select_toogle" for="toggle_5">{lbl_amt(loan_data.paid_amount, this.currency_code)}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Outstanding amount</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_6" className="toggle visually-hidden" onChange={this.handleReviewChange} value="3"/>
                          <label className="select_toogle" for="toggle_6">{lbl_amt(summary.current_os_amount, this.currency_code)}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>                   
                   <div className="row">
                      <label><input type="checkbox" onChange={this.handleSMSCheckboxClick} defaultChecked/>&nbsp;
                      Send payment confirmation notification SMS</label>
                    </div>
              </form>
              </div>              
             {this.state.processing && <p style={{color: "red",textAlign: "right"}}>{this.state.processing}</p>}
            </Modal.Body>
          <Modal.Footer>
            <div className="col-md-8 reset-margin reset-padding">
                <button type="button" className= {`btn btn-primary float-right mr-1 ${this.state.repayment_style}`} onClick={this.submit_capture_payment} disable>Capture Payment </button>
                <button type="button" className="btn btn-outline-primary float-right mr-1" onClick={this.CaptureModalClose} data-dismiss="modal" aria-label="Close">Cancel</button>    
            </div>
          </Modal.Footer>
        </Modal>
    )
  }
    render(){
        let {biz_name,cust_name,acc_number,cust_id,cust_mobile_num,cust_dob,ongoing_loan_doc_id,last_loan_doc_id}=this.state.cust_data
        let{loan_principal,duration,flow_fee,due_amount,loan_doc_id,cust_acc_id,to_acc_num,lender_code,acc_prvdr_code,currency_code,due_date,status,paid_amount,current_os_amount,overdue_days} = this.state.loan_data
        let{loan_type} = this.state
        this.instant_disburse_loan = {biz_name, cust_name, acc_number, loan_principal,
                                            duration, flow_fee, due_amount, loan_doc_id, 
                                            cust_id, cust_acc_id, to_acc_num, lender_code, acc_prvdr_code, currency_code,due_date }
        return(
            <>
            {this.RepaymentSummaryModal(this.state.selected_payment,this.state.loan_data)}

            <div className =" box col m-4 p-0 text-center">
                <div className="card card-pricing holiday_height">
                <div className="card-body">
                    {(check_priv('application', 'apply') || check_priv('field_visit','checkin') || check_priv('call_log','post')) &&
                    <>
                        <h3 className="card-title">Quick Actions</h3>
                        <Form.Control id="cust_token" autocomplete="off" placeholder ="Any Customer ID / Mobile No / National ID" onChange={(e)=>{this.handle_custID_change(e)}}/>
                    </>
                    }
                    
                    <p className="card-description row d-flex justify-content-around">
                    {check_priv('application', 'apply') &&
                    <><div className='col-md-5 m-2 p-0'>
                        <button type="button" className={`btn btn-primary w-100 font-10 py-2 px-1 ${this.state.disable_apply_fa ? "disable_div" : ""}`} id = "apply_fa" onClick={this.handleValidateCustomer}  ><GiClick className ="mr-1" color="white"/>Apply FA</button>
                    </div>
                    <div className='col-md-5 m-2 p-0'>
                        <button type="button" className="btn btn-primary w-100 font-10 py-2 px-1" id = "view_profile" onClick={this.handleValidateCustomer} ><CgProfile className ="mr-1" color="white"/>View Profile</button>
                    </div>
                    </>
                    }
                    
                {check_priv('field_visit','checkin') &&   
                <div className='col-md-5  m-2 p-0'>
                  <button id="log_visit" type="button" className="btn btn-primary font-10 w-100 py-2 px-1" disabled = {this.state.calling_api}  onClick={this.handleValidateCustomer}>
                    <BsPersonCheck  className ="mr-2" color="white"/>Checkin
                  </button>
                </div>  
                } 
                {check_priv('call_log','post') &&
                <>    
                <div className='col-md-5  m-2 p-0'>
                    <button id="incoming" type="button" disabled = {this.state.calling_api} className=" py-2 px-1 btn btn-secondary font-10 w-100 wrap_button" onClick={this.handleValidateCustomer}  ><VscCallIncoming className ="mr-1" color="white"/>Log Incoming Call </button>
                </div>
                <div className='col-md-5 m-2 p-0'>
                    <button id="outgoing" type="button" className="btn btn-secondary  py-2 px-1 font-10 w-100 wrap_button" disabled = {this.state.calling_api} onClick={this.handleValidateCustomer} ><VscCallOutgoing className ="mr-1" color="white"/>Log Outgoing Call</button>
                </div>
                </>
                }
                    </p>
                </div>
                </div>
            </div> 
            
            <div className="home-menu box col  m-2">
                <div className="card card-pricing holiday_height">
                <div className='w-100 p-0 m-0  h-100'>
                    <div className='card-description p-0 m-0 h-100'>
                <div className='home_header_d-flex_at_response'>
					<h4 className="card-title text-white tableHead_font mt-3 mb-0 remove_padding">Holidays in the  next 14 days</h4>
				</div>
                        {/* repective market  holiday*/}
                        <div>
                            <div className='d-flex row justify-content-center w-100 m-0 px-4'>
                            <div className='  p-0 m-0 row w-100'>
                                {((this.state.market_holidays||this.state.indian_holidays||this.state.common_holidays)&&
                                (this.state.market_holidays.length>0||this.state.indian_holidays.length>0||this.state.common_holidays.length>0))?
                                (<> 
                                    
                                    {this.state.common_holidays&&this.state.common_holidays.length > 0 && this.state.common_holidays.map((holiday,index)=>
                                        {return (
                                        <div
                                        key={index}
                                        className={`col-12 holiday_border  text-center margin_bottom_imp d-flex align-items-center justify-content-center rounded btn   px-0 py-2 holiday_border_date holiday_bg`}
                                        >
                                        {(moment().isSame(moment(holiday), 'day'))?
                                        <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                        <div className='col-2 p-0'>
                                            {/* <Flag id="market" name={`IN`} format="png" pngSize={24} shiny={false} alt={`IND`} basePath="/img/flags"/>
                                            <Flag id="market" name={`${get('market').country_code}`} format="png" pngSize={24} shiny={false} alt={`${get('market').country_code}`} basePath="/img/flags"/> */}
                                            <CustomFlag country_code={"IND"} height={30} width={30} alt='IND'/>
                                            <CustomFlag country_code={`${get('market').country_code}`} height={30} width={30} alt={`${get('market').country_code}`}/>
                                            </div>
                                                <div className='col-10 text-left text-white'>
                                            <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> - Today is a holiday
                                            </div>
                                        </p>
                                        :Math.abs(moment().diff(moment(holiday), 'days'))==0?
                                        <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                            <div className='col-2 p-0'>
                                            {/* <Flag id="market" name={`IN`} format="png" pngSize={24} shiny={false} alt={`IND`} basePath="/img/flags"/>
                                            <Flag id="market" name={`${get('market').country_code}`} format="png" pngSize={24} shiny={false} alt={`${get('market').country_code}`} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={"IND"} height={30} width={30} alt='IND'/>
                                                <CustomFlag country_code={`${get('market').country_code}`} height={30} width={30} alt={`${get('market').country_code}`}/>
                                                
                                            </div>
                                                <div className='col-10 text-left text-white'>
                                            <span className=' date_font_large' >  {moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> - Tomorrow is a holiday
                                            </div>
                                        </p>
                                        :
                                        <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                            <div className='col-2 p-0'>
                                            {/* <Flag id="market" name={`IN`} format="png" pngSize={24} shiny={false} alt={`IND`} basePath="/img/flags"/>
                                            <Flag id="market" name={`${get('market').country_code}`} format="png" pngSize={24} shiny={false} alt={`${get('market').country_code}`} basePath="/img/flags"/> */}

                                            <CustomFlag country_code={"IND"} height={30} width={30} alt='IND'/>
                                            <CustomFlag country_code={`${get('market').country_code}`} height={30} width={30} alt={`${get('market').country_code}`}/>
                                            </div>
                                                <div className='col-10 text-left text-white'>
                                            <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> -  {Math.abs(moment().diff(moment(holiday), 'days'))} days to go 
                                            </div>
                                        </p>
                                        }
                                        </div>
                                        );
                                        })
                                        }
                                    
                                        {this.state.market_holidays&&this.state.market_holidays.length > 0 && this.state.market_holidays.map((holiday,index)=>

                                            {return (
                                            <div
                                            key={index}
                                            className={`col-12 holiday_border  text-center margin_bottom_imp d-flex align-items-center justify-content-center rounded btn   px-0 py-2 holiday_border_date holiday_bg`}
                                            >
                                            {(moment().isSame(moment(holiday), 'day'))?
                                            <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                            <div className='col-2 p-0'>
                                                {/* <Flag id="market" name={`${get('market').country_code}`} format="png" pngSize={24} shiny={false} alt={`${get('market').country_code}`} basePath="/img/flags"/> */}
                                            <CustomFlag country_code={`${get('market').country_code}`} height={30} width={30} alt={`${get('market').country_code}`}/>
                                                </div>
                                                <div className='col-10 text-left text-white'>
                                                <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> - Today is a holiday 
                                                </div>
                                            </p>
                                            :Math.abs(moment().diff(moment(holiday), 'days'))==0?
                                            <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                            <div className='col-2 p-0'>
                                                {/* <Flag id="market" name={`${get('market').country_code}`} format="png" pngSize={24} shiny={false} alt={`${get('market').country_code}`} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={`${get('market').country_code}`} height={30} width={30} alt={`${get('market').country_code}`}/>
                                                </div>
                                                <div className='col-10 text-left text-white'>
                                                <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> - Tomorrow is a holiday
                                                </div>
                                            </p>
                                            :
                                            <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                            <div className='col-2 p-0'>
                                                {/* <Flag id="market" name={`${get('market').country_code}`} format="png" pngSize={24} shiny={false} alt={`${get('market').country_code}`} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={`${get('market').country_code}`} height={30} width={30} alt={`${get('market').country_code}`}/>
                                                </div>
                                                <div className='col-10 text-left text-white'>
                                                <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> -  {Math.abs(moment().diff(moment(holiday), 'days'))} days to go 
                                           </div>
                                            </p>
                                            }
                                            </div>
                                        );
                                        })
                                    }
                                

                            
                                        {this.state.indian_holidays&&this.state.indian_holidays.length > 0 && this.state.indian_holidays.map((holiday,index)=>
                                            
                                        {return (
                                            <div
                                            key={index}
                                            className={`col-12 holiday_border  text-center margin_bottom_imp d-flex align-items-center justify-content-center rounded btn  px-0 py-2 holiday_border_date holiday_bg`}
                                            >
                                            {(moment().isSame(moment(holiday), 'day'))?
                                            <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                            <div className='col-2 p-0'>
                                            {/* <Flag id="market" name={`IN`} format="png" pngSize={24} shiny={false} alt={`IND`} basePath="/img/flags"/> */}
                                            <CustomFlag country_code={`IND`} height={30} width={30} alt={`IND`}/>
                                            </div>
                                                <div className='col-10 text-left text-white'>
                                            <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> - Today is a holiday 
                                            </div>
                                            </p>
                                            :Math.abs(moment().diff(moment(holiday), 'days'))==0?
                                            <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                                <div className='col-2 p-0'>
                                                {/* <Flag id="market" name={`IN`} format="png" pngSize={24} shiny={false} alt={`IND`} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={`IND`} height={30} width={30} alt='IND'/>
                                                
                                                </div>
                                                <div className='col-10 text-left text-white'>
                                                <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> - Tomorrow is a holiday 
                                                </div>
                                            </p>
                                            :
                                            <p className='m-0 p-0 date_font_small w-100 row align-items-center'>
                                                <div className='col-2 p-0'>
                                                {/* <Flag id="market" name={`IN`} format="png" pngSize={24} shiny={false} alt={`IND`} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={`IND`} height={30} width={30} alt='IND'/>
                                                </div>
                                                <div className='col-10 text-left text-white'>
                                                <span className=' date_font_large'>{moment(holiday).format('DD-MMM') }, {moment(holiday).format('ddd') }</span> -  {Math.abs(moment().diff(moment(holiday), 'days'))} days to go 
                                                </div>
                                            </p>
                                            }
                                            </div>
                                        );
                                        })
                                        
                                    }
                                </>)
                                :this.state.loader?(<><div className="small_loader mt-5"></div><p className='text-center w-100 text-secondary'>Loading...</p></>)
                                :(<p className='text-center col font text-light'>
                                        No holidays in the next 14 days.
                                </p>)}
                            </div>
                        </div>
                    </div>
            </div>
        </div>   
                </div> 
                
            </div> 
            {check_priv('home', 'shortcuts') &&  
            <div className="home-menu box col  m-2">
            <div className="card card-pricing holiday_height">
                <div className="card-body">
                    <>                       
                     <h3 className="card-title">Shortcuts</h3>
                        <p className="card-description text-left">
                            <tr>
                                <td><h3 className='mb-3 mt-3'> <RiUserSearchFill size={"20"} className="icon home_links_icon"/></h3></td>
                                <td><Link to={'/borrower/search'} className="sizexm">Search Customer</Link></td>
                            </tr>
                            <tr>
                                <td><h3  className='mb-3 mt-0'> <GiArchiveResearch size={"20"}  className="icon home_links_icon"/></h3></td>
                                <td><Link to={'/fa/search'} className="sizexm">Search FA</Link></td>
                            </tr>
                            {/* <tr>
                                <td><h3> <FaUserPlus className="icon"/></h3></td>
                                <td><Link to={'borrower/indiv/create'}>Register Customer</Link></td>
                            </tr> */}
                            <tr>
                                <td><h3  className='mb-3 mt-0 '> <FaRegMoneyBillAlt size={"20"}  className="icon home_links_icon"  /></h3></td>
                                <td><Link to={'lender/accounts/todayfa'} className="sizexm">Today Disbursed FAs</Link></td>
                            </tr>

                            <tr>
                                <td><h3  className='mb-3 mt-0'>  <MdRemoveRedEye size={"20"}  className="icon home_links_icon" /></h3></td>
                                <td><Link to={'watchlist_customers'} className="sizexm">Watchlist Customers</Link></td>
                            </tr>

                            {check_priv("flagged_customers", "shortcuts") &&
                            <tr>
                                <td><h3  className='mb-3 mt-0'> <FaFlag className="icon home_links_icon" size={"20"}  /></h3></td>
                                <td><Link to={'/flagged_customers'} className="sizexm">Flagged Customers</Link></td>
                            </tr>
}

                        </p>
                    </>
                    </div>
            </div>
            </div>
    }
                 
        </>
           
        );
    }
}
export default HomeLinks;