import { Box, useTheme } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { lazy, LazyExoticComponent, Suspense, useCallback } from "react";
import { Link } from "react-router-dom";
import { FormDefaultProps } from "..";
import { getReport } from "../../../actions/report_actions";
import {
  common_req,
  Homeacc_prvdrLogoselector,
  resolvePromiseWithDelay,
} from "../../../helpers/common_helper";
import { check_priv } from "../../../helpers/storage_helper";
import DataTableLoadingOrErrorUI from "../../CommonComponent/table/dataTableLoadingOrErrorUI";
import { LightTooltip } from "../../CommonComponent/tooltip";
import { BodyVarientOneTypo } from "../../CommonComponent/typography";
import AdditonalFieldUI from "./additonalFieldUI";
import SoftEnableUI from "./softEnableUI";
import { TableUIProps } from "./tableUI";

// lazy
const TableUI: LazyExoticComponent<React.ComponentType<TableUIProps>> = lazy(
  () => resolvePromiseWithDelay(import("./tableUI"))
);

export type AuditorCoverageReportResProps = {
  data: {
    data: FlaggedListData[];
    summary_data: Record<string, string>;
    total: number;
  };
};

type FlaggedListData = {
  acc_prvdr_code: string;
  "Acc Number": string;
  "Cust ID": string;
  Cust: string;
  "RM Name": string;
  "Initiated by": string;
  "Flagged on": string;
  "Last Follow-up On": string;
  "Current Status": string;
  "Current FA Status": string;
  Reason: string;
};

const FieldsAndTableUI = ({
  sort,
  defaultSort,
  page,
  queryFilters,
  setpage,
  setsort,
}: {
  sort: GridSortModel;
  page: GridPaginationModel;
  defaultSort: GridSortModel;
  queryFilters: FormDefaultProps;
  setpage: Function;
  setsort: Function;
}) => {
  // hook
  const theme = useTheme();

  // cb
  const getSort = (getFor: "field" | "sort") =>
    sort.length > 0
      ? sort[0][getFor]
      : getFor === "field"
      ? defaultSort[0].field
      : defaultSort[0].sort;

  const reqObj = common_req({} as any);
  // query
  const { data, isLoading, isError, refetch } =
    useQuery<AuditorCoverageReportResProps>({
      queryKey: [
        "flagged_customers",
        { sort: [getSort("field"), getSort("sort")] },
        { page: [page.page, page.pageSize] },
        {
          filter: {
            start: queryFilters.startDate.format("YYYY-MM-DD"),
            initiatedBy: queryFilters.auditor.id,
            end: queryFilters.endDate.format("YYYY-MM-DD"),
            custInfo: queryFilters.custNameOrCustIdOrMblNo,
          },
        },
      ],
      queryFn: () =>
        getReport({
          ...reqObj,
          report_type: "flagged_customers",
          filters: {
            // tab_name: "",
            start_date: queryFilters.startDate.format("YYYY-MM-DD"),
            initiated_by: queryFilters.auditor.id,
            end_date: queryFilters.endDate.format("YYYY-MM-DD"),
            cust_info: queryFilters.custNameOrCustIdOrMblNo || null,
          },
          sort: {
            field: getSort("field"),
            order: getSort("sort"),
          },
          pagination: {
            skip: page.page * page.pageSize,
            per_page: page.pageSize,
          },
        }),
    });

  const getWidth = useCallback((o: string) => {
    let width;
    switch (o) {
      case "Acc Number":
        width = 200;
        break;
      case "Cust ID":
        width = 200;
        break;
      case "Reason":
        width = 250;
        break;
      case "Customer Status":
        width = 160;
        break;
      default:
        width = 150;
        break;
    }
    return width;
  }, []);

  const getColor = useCallback((o: string, val: string) => {
    let color = theme.palette.text.primary;
    switch (o) {
      case "Cust ID":
        color = "#5168CC !important";
        break;
      case "Current Status":
        if (val === "Disabled") color = "#FF0606 !important";
        break;
      case "Current FA Status":
        if (val === "Overdue") color = "#FF0606 !important";
        break;
      default:
        break;
    }
    return color;
  }, []);

  const TextShowTypo = ({
    o,
    params,
  }: {
    o: string;
    params: Record<string, string>;
  }) => (
    <BodyVarientOneTypo
      overRideStyles={{
        color: getColor(o, params.value),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      text={params.value}
    />
  );

  // rows and columns
  const rows = data?.data?.data?.map((d) => ({
    ...d,
    id: Math.random(),
  })) as GridRowsProp;

  const isCOOLoggedIn = check_priv("flagging", "soft_enable");
  const columnsToBeHide = [
    "acc_prvdr_code",
    "id",
    "flag_id",
    "borrower_id",
    "first_name",
    "last_name",
    !isCOOLoggedIn && "Customer Status",
  ].filter((nonFalse) => nonFalse);
  const columnsWithCustomUI = [
    "Acc Number",
    "Reason",
    "Cust ID",
    "Customer Status",
  ];

  const columns =
    data?.data && Array.isArray(data?.data?.data) && data?.data?.data.length > 0
      ? Object.keys(data?.data?.data[0])
          .map((o) => {
            if (!columnsToBeHide.includes(o))
              // it will gives the column options except the keys in the columnsToBeHide, (all the table coloumn names are controlled from server side)
              return {
                field: o,
                headerName: o,
                minWidth: getWidth(o),
                flex: 1,
                align: "left",
                headerAlign: "left",
                renderCell: (params: any) => (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {o === "Acc Number" && ( // custom account number and image UI
                      <>
                        <Box
                          sx={{
                            mr: 2,
                            "& img": { verticalAlign: "baseline" },
                          }}
                        >
                          <Homeacc_prvdrLogoselector
                            logotype={params?.row?.acc_prvdr_code}
                            product_takeup_print={""}
                            from_stmt={false}
                          />
                        </Box>
                        <TextShowTypo o={o} params={params} />
                      </>
                    )}
                    {o === "Reason" && ( // for the reason due to long text need to shows inside the popup
                      <LightTooltip
                        sx={{
                          width: "200px",
                          // for down arrow white bg
                          "& .MuiTooltip-arrow": { color: "#fff" },
                        }}
                        title={params.value}
                        arrow
                        placement="top"
                      >
                        <span style={{ overflow: "hidden" }}>
                          <TextShowTypo o={o} params={params} />
                        </span>
                      </LightTooltip>
                    )}
                    {o === "Cust ID" && ( // for the cust id need to redirect the user to indiv view page
                      <Link
                        to={`/borrower/indiv/view/${params.value}`}
                        target="_blank"
                      >
                        <TextShowTypo o={o} params={params} />
                      </Link>
                    )}
                    {o === "Customer Status" && ( // softenable feature for coo
                      <SoftEnableUI params={params} refetch={refetch} />
                    )}
                    {/* for all the other fields none of the conditions above were false */}
                    {!columnsWithCustomUI.includes(o) && (
                      <TextShowTypo o={o} params={params} />
                    )}
                  </Box>
                ),
              };
          })
          .filter((nonUndefined) => nonUndefined)
      : undefined;

  return (
    <>
      <AdditonalFieldUI
        isLoading={isLoading}
        isError={isError}
        data={data?.data?.summary_data}
      />
      <Suspense
        fallback={
          <Box p={2}>
            <DataTableLoadingOrErrorUI
              isError={false}
              errorText=""
              loadingRows={10}
              loadingColumns={6}
            />
          </Box>
        }
      >
        <TableUI
          rows={rows as unknown as GridValidRowModel[]}
          columns={columns as unknown as GridColDef<GridValidRowModel>[]}
          page={page}
          sort={sort}
          rowCount={data?.data?.total}
          setpage={setpage}
          setsort={setsort}
          isError={isError}
          isLoading={isLoading}
        />
      </Suspense>
    </>
  );
};

export default FieldsAndTableUI;
