import React from "react";
import { Navigate } from "react-router-dom";
import "../../../styles/flow.css";
import "../../market/container/datatables.css";
import { dd_value } from "../../../actions/common_actions";
import {
  capitalize_first_letter,
  title_case,
} from "../../../helpers/common_helper";
import FlowContainer from "../../common/container/core/FlowContainer";
import _ from "lodash";
import { getMasterData } from "../../../actions/common_actions";
import CommonReactTable from "../../../helpers/commonReact_table";
import SMSLogs from "../component/SmsLogs";
import { Card, Button } from "react-bootstrap";
import { listSmsLogs } from "../../../actions/sms_action";
import { format } from "date-fns";
import { BasicReactTable } from "../../../helpers/react_table";

class SMSLogsContainer extends FlowContainer {
  state = {
    columns: [],
    sms_list: [],
    copy: [],
    sms_search_list: {},
    sms_log_table: "hide_div",
  };

  componentWillMount() {
    // this.handleCurDate()
  }

  handleCurDate = () => {
    const date = new Date();
    const cur_date = format(date, "yyyy-MM-dd");
    const sms_search_list = this.state.sms_search_list;
    sms_search_list["start_date"] = cur_date;
    sms_search_list["end_date"] = cur_date;
    this.setState({ sms_search_list });
    this.handleSearch();
  };
  handleUpperCase = (event) => {
    if (event != null) {
      const result = event.toUpperCase();
      const str_replace = result.replace(/_/g, " ");
      return str_replace;
    } else {
      const str_replace = event;
      return str_replace;
    }
  };

  handleSearch = (event) => {
    listSmsLogs(
      this.req({ sms_search_criteria: this.state.sms_search_list })
    ).then((response) => {
      this.resp(response);
      if (!response) {
        return;
      }
      if (response.status === "success") {
        this.resp(response);
        var columns = this.state.columns;

        columns = [
          {
            Header: "Vendor Code",
            accessor: "vendor_code",
          },
          {
            Header: "Vendor Ref Id",
            accessor: "vendor_ref_id",
            id: "vendor_ref_id",
            accessor: (row) =>
              row.vendor_ref_id == null ? "NA" : row.vendor_ref_id,
            width: 250,
          },
          {
            Header: "Mobile Number",
            accessor: "mobile_num",
          },
          {
            Header: "Status",
            id: "status",
            accessor: (row) => capitalize_first_letter(row.status),
          },
          {
            Header: "Direction",
            id: "direction",
            accessor: (row) => capitalize_first_letter(row.direction),
          },
          {
            Header: "Purpose",
            id: "purpose",
            accessor: (row) => dd_value(row.purpose),
          },
          {
            Header: "Loan Doc Id",
            accessor: "loan_doc_id",
            id: "loan_doc_id",
            accessor: (row) =>
              row.loan_doc_id == null ? "NA" : row.loan_doc_id,
          },
          {
            Header: "Content",
            accessor: "content",
            width: 400,
            className: "table-txt-wrap",
          },
          {
            Header: "Created At",
            accessor: "created_at",
          },
        ];
      }
      this.setState({
        columns,
        sms_list: response.data,
        sms_log_table: "show_div",
        copy: response.data,
      });
    });
  };

  render() {
    return (
      <div className="container containerTopmargin max-width no-padding">
        <form className="mb-3" autocomplete="off">
          <Card>
            <Card.Header style={{ backgroundColor: "#01062c", color: "white" }}>
              <h4>SMS Logs</h4>
            </Card.Header>
            <Card.Body style={{ padding: 15 }}>
              <SMSLogs
                id="sms_search_list"
                onComponentChange={this.handleComponentChange}
                direction={this.state.sms_search_list.direction}
              />
              <div className="form-group row reset-margin justify-content-center py-4">
                <Button
                  disabled={this.state.calling_api}
                  className={`col-md-2 mr-3 btn btn-primary`}
                  onClick={this.handleSearch}
                >
                  Search
                </Button>
              </div>
            </Card.Body>
          </Card>
        </form>
        {!_.isEmpty(this.state.sms_search_list) && (
          <div className={`mt-3 ${this.state.sms_log_table}`}>
            <p className="no-margin text-white">
              <b>Total Entries : {this.state.copy.length}</b>
            </p>
            {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.sms_list !== undefined ? this.state.sms_list : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"sms"} csvData={this.state.sms_list ? this.state.sms_list : []}
                        searchData={[{...this.state.sms_search_list,title:'Search SMS Logs'}]} />    */}

            <BasicReactTable
              row_data={
                this.state.sms_list !== undefined ? this.state.sms_list : []
              }
              column_data={this.state.columns}
              FName={"sms"}
              csv_file={true}
              pagination={
                this.state.sms_list !== undefined &&
                this.state.sms_list.length > 9
                  ? true
                  : false
              }
              default_page_size={15}
              searchData={[
                { ...this.state.sms_search_list, title: "Search SMS Logs" },
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SMSLogsContainer;
