import { get, remove_all, set_logout, set, get_item } from "./storage_helper";
import { userService } from "./../actions/auth_actions";
import { fetAppPath, get_path } from "./common_helper";
import { error } from "jquery";

export const admin_path = process.env.REACT_APP_ADMIN_API_ROOT;
export const app_path = process.env.REACT_APP_APP_API_ROOT;

export const hasAccess = (user, permissions) => {
  permissions.some((permission) => user.permissions.includes(permission));
};

const headers = new Headers();
headers.append("Content-Type", "application/json");
let show_alert = false;
function handleFailure(error) {
  if (error?.message.includes("Failed to fetch")) {
    show_alert = true;
  }

  if (get("error_alert") && !show_alert) {
    show_alert = true;
    alert(error);
  }
}

export const call_fetch = (path, req, add_token = true, add_uuid = true) => {
  if (req == null) {
    req = {};
  }
  if (add_token) {
    req.token = get("access_token");
  }
  if (add_uuid) {
    req.uuid = get("uuid");
  }

  return (
    fetch(path, { method: "POST", body: JSON.stringify(req), headers: headers })
      //.then((res)=>res.json())call_fetch
      .then(handleResponse, handleFailure)
      .then(handleErrorNMessage, handleError)
    // .catch(error => {
    //     console.log(error)
    //     }
  );
};

//export const async call_await_fetch = (path, req, add_token = true) =>{
export async function call_await_fetch(
  path,
  req,
  add_token = true,
  add_uuid = true
) {
  if (add_token) {
    if (req == null) {
      req = {};
    }
    req.token = get("access_token");
  }
  if (add_uuid) {
    req.uuid = get("uuid");
  }

  // $market = get(market);
  //$time_zone = $market->time_zone;
  var resp = await fetch(path, {
    method: "POST",
    body: JSON.stringify(req),
    headers: headers,
  });
  resp = await resp.json();

  return resp;
}

export async function disburseReport_get(path, req, add_token = true) {
  const market = get("market");
  const token = get("access_token");
  if (add_token) {
    if (req == null) {
      req = {};
    }
    req.token = get("access_token");
    req.country_code = market.country_code;
    req.time_zone = market.time_zone;
    req.type = "disbursal_time_report";
    req.report = "report_type";
  }
  // const request = {
  //   [req.token] : token,
  //   [req.country_code] : market.country_code,
  //   [req.time_zone] : market.time_zone,
  //   [req.type] : "disbursal_time_report",
  //   [req.report] : "report_type"
  // }
  var resp = await fetch(path, {
    method: "POST",
    body: JSON.stringify(req),
    headers: headers,
  });
  resp = await resp.json();
  return resp;
}

function handleError(message) {
  let show = get("error_alert");
  if (show) {
    alert(message);
  }
}

function its_expired_token(message) {
  return message.includes("Token expired");
}

export const handleErrorNMessage = (response) => {
  let error_info = "";
  //response = response.json();
  if (!response) {
    return;
  }
  if (response.status === "error") {
    if (response.status_code == "401") {
      window.location.reload();
    } else if (response.status_code == "401") {
      error_info += "\n" + JSON.stringify(response.data);
    } else if (response.status_code == "409") {
      set("error_alert", false);
      remove_all();
      set_logout();
      window.location.replace("/login");
      return;
    }
    if (!its_expired_token(JSON.stringify(response.data))) {
      alert(response.message + error_info);
    }

    return false;
  }

  return response;
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    var error = null;

    if (!response.ok) {
      if (response.status == "401" || response.status == "409") {
        set("error_alert", false);
        error = data.message;

        remove_all();
        set_logout();
        window.location.replace("/login");
        if (!its_expired_token(error)) {
          alert(data.message);
        }
        return;
      } else {
        //if (response.status == "422" || response.status == "204" ) {
        error = data && data.message;
      }
      return Promise.reject(error);
    }
    return data;
  });
}

/*
export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.authdata) {
        return { 'Authorization': 'Basic ' + user.authdata };
    } else {
        return {};
    }
}
*/

// fileUpload = (event) =>{
//   alert(event.target.value);
// }

/*export function fileUpload(image){
  //console.log(JSON.stringify(image));
  const url = "http://192.168.73.168:8000/api/app/fileupload";
  //const formData = image;
  return fetch(url, {method: "POST", body:  JSON.stringify(image), headers: headers })
  //return post(url, formData)
    .then(handleResponse)
  //return fetch()
}*/
