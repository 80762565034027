import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { getSalesDetails, getSalesTxns } from '../../../actions/loan_product_actions';
import { get } from '../../../helpers/storage_helper';
import { lbl_amt } from '../../../helpers/common_helper';
import moment from 'moment';
import { dd_value } from '../../../actions/common_actions';

export default function SwitchScreen() {
    const { sales_doc_id } = useParams();
	const market = get('market')
	const marketData = {
		time_zone : market.time_zone,
		country_code:market.country_code
	}
    const currencyCode = market.currencyCode;
    const [loader, setLoader] = useState(true);
    const [salesData, setSalesData] = useState({});
    const [paymentProof ,setPaymentProof] = useState([]);
    const [floatIn ,setFloatIn] = useState([]);
    const [floatOut ,setFloatOut] = useState([]);

    useEffect(() => {
       getSwitchDetail()
    }, []);
    

    const getSwitchDetail = () => {
        setLoader(true)
        var request = {
            sales_search: {
                req_parameter: sales_doc_id,
            }
        };

        getSalesDetails({...request, ...marketData })
            .then((response)=>{
            if(!response){return setLoader(false)}
            if(response.status=='success'){
                let result = response.data.results;
				setSalesData(result[0]);
				getTxn(result[0])
                // setLoader(false)
            }
        })
    }

    const getTxn = (data) => {
		// setLoader(true)
		const photoPaymentProof = JSON.parse(data.photo_payment_proof);
		handlePaymentProof(photoPaymentProof);
		var request={
			sales_doc_id: sales_doc_id
		}
		getSalesTxns({...request, ...marketData })
		.then((response)=>{
		
			if(!response){return setLoader(false)};
			
			if(response.status=='success'){
				const floatInData = response.data.filter(val => val.txn_type == 'float_in');
                const floatOutData = response.data.filter(val => (val.txn_type == 'float_out' || val.txn_type =='float_in_refunded'));
                setFloatIn(floatInData.length > 0 ? floatInData[0] : [])
                setFloatOut(floatOutData.length >0 ? floatOutData[0] : [])
				
			}
			setLoader(false)
			
		})
	}
	const handlePaymentProof = (proof) =>{
		let lastProof = null;
		if (Array.isArray(proof) && proof.length > 0) {
			 lastProof = proof[proof.length - 1];
		}

		setPaymentProof(lastProof);
	}

    const calulate_txn_amount = (amount = null, fee = null, reward_amt = null, status) => {
        if(amount && fee && status !='refunded'){
            return lbl_amt( (amount-fee)+reward_amt , currencyCode)
        }else if(status =='refunded'){
            return lbl_amt( amount , currencyCode)
        }else if(amount == null){
            return lbl_amt( 0 , currencyCode)
        }
    }

    const parseAccountName =(accountName, type = null) => {
	
		if (accountName == undefined || accountName == null) {
			return null;
		}

		const regex = /^(\d+)\s\(([^-]+)\s-/;
		const match = accountName.match(regex);
	
		if (match) {
			if(type == 'acc_num'){
				return match[1];
			}else if(type == 'prvdr'){
				return match[2].trim();
			}else{
				return accountName;
			}
		}else{
			return null;
		}
	  
	}

    const checkVal = (value, type = null) => {
        if(value == null || value == undefined){
            return 'NA';
        }else{
            if (type == 'date'){
                return moment(value).format('DD MMM YYYY hh:mm a');
            }else if(type =='amt'){
                return lbl_amt(value,currencyCode);
            }else{
                return  value;
            }
            
        }
        
    }

  return (
    <div>
      {  !loader ?
				<div className='px-4'>
					<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
						<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Customer Details</p>
					</div>

					<div className='py-3'>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Customer ID</p>
							<Link to={"/borrower/indiv/view/"+salesData.cust_id} target="_blank" ><p className='m-0  txn_model_subTxt' style={{fontSize :'15px' , color:'#17A2B8'}}>{salesData.cust_id}</p></Link>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Customer Name</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{salesData.cust_name}</p>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Switch Amount</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{lbl_amt(salesData.product_amt,currencyCode)}</p>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Fee</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{lbl_amt(salesData.product_fee,currencyCode)}</p>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Transaction Amount</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{calulate_txn_amount(salesData.product_amt, salesData.product_fee,salesData.reward_amt,salesData.status)}</p>
						</div>
					</div>

					<div className=' row'>
			
						<div  className='col-sm-12 col-md-6 col-lg-6'>
							<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
								<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Customer Transfer Details</p>
							</div>
						
							<div className='py-3'>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >From A/C</p>
									<img className={`img-fluid ${checkVal(floatIn.from_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${parseAccountName(floatIn.from_ac_name, 'prvdr')}_logo_color.png`} alt={parseAccountName(floatIn.from_ac_name, 'prvdr')}/> 
									<p className={`${checkVal(floatIn.from_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{checkVal(parseAccountName(floatIn.from_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >To A/C</p>
									<img className={`img-fluid ${checkVal(floatIn.to_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${parseAccountName(floatIn.to_ac_name, 'prvdr')}_logo_color.png`} alt={parseAccountName(floatIn.to_ac_name, 'prvdr')}/>	
									<p className={`${checkVal(floatIn.to_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{checkVal(parseAccountName(floatIn.to_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Amount</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{checkVal(floatIn.amount, 'amt')}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction ID</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{checkVal(floatIn.txn_id)}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction Time</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{checkVal(floatIn.txn_date, 'date')}</p>
								</div>
							</div>
						
						</div>
						<div  className='col-sm-12 col-md-6 col-lg-6'>
							<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
								<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Flow Transfer Details</p>
							</div>
						
							<div className='py-3'>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >From A/C</p>
									<img className={`img-fluid ${checkVal(floatOut.from_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${parseAccountName(floatOut.from_ac_name, 'prvdr')}_logo_color.png`} alt={parseAccountName(floatOut.from_ac_name, 'prvdr')}/>	
									<p className={`${checkVal(floatOut.from_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{checkVal(parseAccountName(floatOut.from_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >To A/C</p>
									<img className={`img-fluid ${checkVal(floatOut.to_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${parseAccountName(floatOut.to_ac_name, 'prvdr')}_logo_color.png`} alt={parseAccountName(floatOut.to_ac_name, 'prvdr')}/>	
									<p className={`${checkVal(floatOut.to_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{checkVal(parseAccountName(floatOut.to_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Amount</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{checkVal(floatOut.amount, 'amt')}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction ID</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{checkVal(floatOut.txn_id)}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction Time</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{checkVal(floatOut.txn_date, 'date')}</p>
								</div>
							</div>
						
						</div>
							
					</div>

					<div >
						<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
							<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Switch Progress</p>
						</div>
						<div className='py-3'>
							<div className='d-flex switch_txn_pad'>
								<p className='m-0 col-3 txn_model_txt' >Status</p>
								<p className='m-0 txn_model_txt txn_model_subTxt' >{salesData.status == 'dropped' ? "Failed" : salesData.status == 'pymt_proof_uploaded' ? "Payment Proof Uploaded" : dd_value(salesData.status, salesData.status)}</p>
							</div>
							<div className='d-flex switch_txn_pad'>
								<p className='m-0 col-3 txn_model_txt' >Switch Initiated</p>
								<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.created_at).format('DD MMM YYYY hh:mm a')}</p>
							</div>
							{salesData.payment_date && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Payment received</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.payment_date).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{paymentProof && paymentProof.uploaded_at && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Payment Proof Uploaded</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(paymentProof.uploaded_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{paymentProof && paymentProof.captured_at && paymentProof.captured &&
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Payment Proof {paymentProof.status =='approve' ? "Approved" : "Rejected"}</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(paymentProof.captured_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{salesData && salesData.refund_requested_at &&
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Refund Requested</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.refund_requested_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}		

							{salesData && salesData.refunded_date &&
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Refunded Date</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.refunded_date).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}	

							{salesData && salesData.status =='dropped' && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Switch Failed</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.updated_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{salesData && salesData.delivery_date && salesData.status =='delivered' && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Switch successful</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.delivery_date).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}
						</div>
					</div>
				</div>

				 :
					<>
						<p className='text-light mt-5 text-center'>Loading...</p>
						<div className="product_takeup_loader"></div>
					</>
				} 
    </div>
  )
}
