import { Box } from "@mui/material";
import { ReactNode } from "react";

const DarkBgCommonStyleContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        "& .MuiDataGrid-root": {
          // @ts-ignore
          backgroundColor: (theme) => theme.palette.custom_colors.primary_800,
          border: (theme) =>
            // @ts-ignore
            `solid 1px ${theme.palette.custom_colors.secondary_a_400}`,
        },
        "& .MuiDataGrid-cell": {
          borderTop: (theme) =>
            // @ts-ignore
            `solid 1px ${theme.palette.custom_colors.secondary_a_400}`,
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: (theme) =>
            // @ts-ignore
            `${theme.palette.custom_colors.primary_800} !important`,
        },
        "& .MuiTablePagination-displayedRows": {
          display: "none",
        },
        "& .MuiTablePagination-selectLabel": {
          display: "none!important",
        },
        "& .MuiToolbar-root .MuiInputBase-root": {
          mr: "0px!important",
        },
        "& .Mui-selected": {
          backgroundColor: (theme) =>
            // @ts-ignore
            `${theme.palette.custom_colors.secondary_500}!important`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default DarkBgCommonStyleContainer;
