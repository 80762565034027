import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ViewConfigurations, updateConfigurations } from '../../../actions/common_actions';
import { listAccountProvider } from '../../../actions/account_provider_actions';
import Form from 'react-bootstrap/Form';
import AutoRetryConfiguration from '../../../UI/Configurations/Component/AutoRetryConfigurations';
import FlowComponent from './core/FlowComponent';
import { get, Config } from '../../../helpers/storage_helper';
import { it, th } from 'date-fns/locale';
import {FiSettings} from  "react-icons/fi";
import { connect } from 'react-redux';
import { changeHeaderSlice } from '../../../Redux/Slices/HeaderSlice';
import { getAgentDetails } from '../../../actions/account_actions';

class CommonModal extends FlowComponent {
    constructor() {
        super();
        this.state = {
            show_modal: true,
            active_tab: "failed_fas_retry",
            arr: [],
            loader: true,
            addl_key:false,
            name_verification_loader:false
        };
    }
    handleClose(type=null) {

        if(type){
            this.props.changeHeaderSlice({configuration:false,name_verification:false})
        }
        this.setState({ show_modal: false,addl_key:true })
    }

    componentWillMount(){
        this.setState({ show_modal: true })
      if(this.props.type =="name_verification"){
        
            this.setOptionsListToStateFromAppApi('common', 'retrieval_accounts', {country_code : get('market').country_code}, ['acc_prvdr_code', 'name'], "retrievel_accs");
        
      }
       

    }

    handlenameSearch=()=>{
      var request={
        data:{
          acc_prvdr_code:this.state.retrvl_acc,
          acc_number:this.state.mobile_num
        }
      }
      if(this.state.mobile_num[0]==0){
        alert('Please remove the zero in the prefix')
      }
      else{
        this.setState({name_verification_loader:true,error_data:'',retrieved_name:''})
        getAgentDetails(this.req(request))
        .then((response)=>{
          if(!response){this.setState({error_data:'No Data Found',name_verification_loader:false})
          };
          if(response && response.status=="success"){
            if(response.data.status=='failed'){
              this.setState({error_data:response.data.error,name_verification_loader:false})
            }
            else{
              this.setState({retrieved_name:response.data.full_name,name_verification_loader:false})
            }
          }
      })
      }
     
    }
    render() {

        return (
            <>
            {(this.props.type =="name_verification") && 
             <Modal
             show={this.state.show_modal}
             onHide={() =>this.handleClose("name_verification")}
             className='modal_background_blur'
             size='lg'
             >
             <Modal.Header className='p-0' closeButton >
                 <Modal.Title className='py-3' style={{background:'#484848'}}>
                 <h4 className="modal-title text-light" id="ccaNameVerificationModalLabel">Name Verification</h4>
   
                     </Modal.Title>
             </Modal.Header>
             <Modal.Body className='px-4 pt-0'>
               
             <div className='d-flex align-items-center'>
            
            <div className='col-md-6 mt-3'>
                <label htmlFor="acc_prvdr_code">Account Provider<span className='product_red_star star_font'>*</span></label>
                <select className="form-control" id="acc_prvdr_code" onChange={(e)=>this.setState({retrvl_acc : e.target.value})}>
                    {this.state.retrievel_accs_dd}
                </select>
            </div>
            <div className='col-md-6 mt-3'>
              <div className="form-group remove_spinner_number_input">
                <label htmlFor="mobile_num">Registered Mobile Number <span className='product_red_star star_font'>*</span></label>
                <input type="number"value={this.state.mobile_num} className="form-control " id='mobile_num' onChange={(e)=>this.setState({mobile_num:e.target.value})}  placeholder="Mobile Number" required/>
              </div>
            </div>
          </div>
          
          <div className='d-flex  justify-content-center mt-3'>
            <button className='btn cca_retrieve_btn'disabled={(this.state.mobile_num!==''&&this.state.name_verification_loader===false)?false:true} onClick={()=>this.handlenameSearch()}>Submit</button>
          </div>
          {this.state.name_verification_loader&&<><div className="product_takeup_loader mt-2"></div>
          <p className='text-center text-light'>Retrieving...</p></>}
          {this.state.error_data && 
            <div className='p-3 m-3 row error_msg_cca'>
              <p className=' text-center col-md-6 py-2 m-0 success_msg_cca_text font'>Error : </p>
              <p className=' text-center col-md-6 py-2 m-0 success_msg_cca_text font'>{this.state.error_data}</p>
            </div>
            }
          {this.state.retrieved_name &&
           <div className='row p-3 success_msg_cca my-3'>
            <p className='text-center col-md-6 py-2 m-0 success_msg_cca_text font'>Retrieved Name : </p>
            <p className='text-center col-md-6 py-2 m-0 success_msg_cca_text font'>{this.state.retrieved_name}</p>
            </div>
            }           </Modal.Body>
             <Modal.Footer>
             <button type="button" className="btn cca_retrieve_btn btn-sm"onClick={()=>this.handleClose()} data-dismiss="modal">Close</button>
   
             </Modal.Footer>
            </Modal>
            
            }
            {this.props.type =="configuration" &&
                <Modal
                    show={this.state.show_modal}
                    onHide={() => this.handleClose("configuration")}
                    size='lg'
                    className='modal_background_blur'

                >
                    <Modal.Header className='p-0' closeButton >
                        <Modal.Title>
                        <div className='row m-0 '  style={{background:'gray',border:'1px solid black'}}>
                <div className='col-lg-3 py-2 modal_settings_border' >
                    <div className='d-flex align-items-center justify-content-center  row m-0'>
                     <p className='m-0 col-10 text-center text-white' style={{fontSize:'15px '}} >Settings</p>
                    <div className=' col-2 p-1 settings_top' >
                      <FiSettings size={"17"} color='white'/>
                    </div>
                    </div>
                </div>
              
                <div className='col-lg-8 py-2'>
                <p className='m-0 text-center text-white' style={{fontSize:'15px '}}>Auto Retry Configurations</p>
               </div>
                </div>
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='px-0 pt-0'>
                       
                        <AutoRetryConfiguration  handleClose={()=>this.handleClose("configuration")}/>
                    </Modal.Body>
                    <Modal.Footer>
                       
                    </Modal.Footer>
                </Modal>
            }
            </>
        );
    }
}
const mapStateToProps = (state) => {
	return {

	};
  };

const mapDispatchToProps ={
    changeHeaderSlice,

  
  };

export default connect( mapStateToProps,mapDispatchToProps)(CommonModal);
