import { colors } from "@mui/material";

export const typography = (theme)=> [
    {
        props: { variant: 'xl_poppins_bold' },
        style: { fontSize: "2rem",fontWeight:'700'}
    },
    {
        props: { variant: 'xl_poppins_semibold' },
        style: { fontSize: "2rem",fontWeight:'600'}
    },
    {
        props: { variant: 'l_poppins_bold' },
        style: { fontSize: "1.75rem",fontWeight:'700'}
    }, {
        props: { variant: 'l_poppins_semi_bold' },
        style: { fontSize: "1.75rem",fontWeight:'600'}
    }, {
        props: { variant: 'm_poppins_bold' },   
        style: { fontSize: "1.5rem",fontWeight:'700'}
    },
    {
        props: { variant: 'm_poppins_semibold' },   
        style: { fontSize: "1.5rem",fontWeight:'600'}
    },
    {
        props: { variant: 'm_poppins_semibold_white' },   
        style: { fontSize: "1.5rem",fontWeight:'600',color: theme.palette.primary.contrastText}
    },
    {
        props: { variant: 's_poppins_bold' },   
        style: { fontSize: "1.25rem",fontWeight:'700'}
    },  {
        props: { variant: 's_poppins_semibold' },   
        style: { fontSize: "1.25rem",fontWeight:'600'}
    },  {
        props: { variant: 'xs_poppins_semibold' },   
        style: { fontSize: "1rem",fontWeight:'600'}
    },
    {
        props: { variant: 'xs_poppins_bold' },   
        style: { fontSize: "1rem",fontWeight:'700'}
    },

    // styles for paragraph

    {
        props: { variant: 'xl_inter_medium' },   
        style: { fontSize: "1.25rem",fontWeight:'500'}
    }, 
    {
        props: { variant: 'xl_inter_regular' },   
        style: { fontSize: "1.25rem",fontWeight:'400'}
    },
    {
        props: { variant: 'l_inter_medium' },   
        style: { fontSize: "1.125rem",fontWeight:'500'}
    },
    {
        props: { variant: 'l_inter_regular' },   
        style: { fontSize: "1.125rem",fontWeight:'400'}
    },
    {
        props: { variant: 'm_inter_medium' },   
        style: { fontSize: "1rem",fontWeight:'500'}
    },
    {
        props: { variant: 'm_inter_regular_white' },   
        style: { fontSize: "1rem",fontWeight:'400',color: theme.palette.custom_colors.secondary_a_50
    }
    },
    {
        props: { variant: 'm_inter_regular_gray' },   
        style: { fontSize: "1rem",fontWeight:'400',color: theme.palette.custom_colors.secondary_a_400}
    },
    {
        props: { variant: 'm_inter_semi_bold_white' },   
        style: { fontSize: "0.79rem",fontWeight:'600',color: theme.palette.custom_colors.secondary_a_50}
    },

    {
        props: { variant: 's_inter_medium_white' },   
        style: { fontSize: "0.875rem",fontWeight:'500',color:theme.palette.custom_colors.secondary_a_50}
    },
    {
        props: { variant: 's_inter_medium_gray' },   
        style: { fontSize: "0.875rem",fontWeight:'500',color:theme.palette.custom_colors.secondary_a_400}
    },

    {
        props: { variant: 's_inter_regular_white' },   
        style: { fontSize: "0.79rem",fontWeight:'400',color:theme.palette.custom_colors.secondary_a_50,lineHeight:'24px'}
    },
    {
        props: { variant: 'm_inter_medium_regular_white' },   
        style: { fontSize: "1rem",fontWeight:'500',color:theme.palette.custom_colors.secondary_a_50}
    },

    {
        props: { variant: 's_inter_regular' },   
        style: { fontSize: "0.875rem",fontWeight:'400'}
    },

    {
        props: { variant: 'xs_inter_medium' },   
        style: { fontSize: "0.75rem",fontWeight:'500'}
    },
    {
        props: { variant: 'xs_inter_regular' },   
        style: { fontSize: "0.75rem",fontWeight:'400'}
    },

    {
        props: { variant: 'xs_inter_regular_gray' },   
        style: { fontSize: "0.75rem",fontWeight:'400',color: theme.palette.custom_colors.secondary_a_500}
    },

    {
        props: { variant: 'xxs_inter_medium' },   
        style: { fontSize: "0.625rem",fontWeight:'500'}
    },
    {
        props: { variant: 'xxs_inter_regular' },   
        style: { fontSize: "0.625rem",fontWeight:'400'}
    },
    {
        props: { variant: 'xxs_inter_regular_blue' },   
        style: { fontSize: "0.625rem",fontWeight:'400',color:theme.palette.custom_colors.secondary_300}
    },


    {
        props: { variant: 'h1' },
        style: { fontSize: "2rem"}
    },
    {
        props: { variant: 'h2' },
        style: { fontSize: "1.5rem" ,fontWeight:'500'}
    }, 
    {
        props: { variant: 'h3' },
        style: { fontSize: "1.5rem" }
    }, 
    {
        props: { variant: 'h4' },
        style: { fontSize: "1rem" }
    }, 
    {
        props: { variant: 'h5' },
        style: { fontSize: "0.875rem" }
    }, 
    {
        props: { variant: 'h6' },
        style: { fontSize: "0.75rem" }
    }, 
    {
        props: { variant: 'sub_head' },
        style: { fontSize: "1.125rem ",fontWeight:'600' }
    },
    {   props: { variant: 'sub_title' },
        style: { fontSize: "1.1rem ",fontWeight:'400' }  
    },
    {
        props:{variant :"secondary_text"},
        style:{ 
            color:theme.palette.custom_colors.secondary_a_400,
            marginTop:"5px",
            fontSize: "1.12rem ",
            fontWeight:'400'
        }

    },
    {
        props:{variant :"secondary_text_w_500"},
        style:{ 
            color:theme.palette.custom_colors.secondary_a_400,
            marginTop:"5px",
            fontSize: "1.12rem ",
            fontWeight:'500'
        }

    },
    {
        props:{variant :"secondary_a_50"},
        style:{ 
            color:theme.palette.custom_colors.secondary_a_400,
            fontSize: "1.12rem ",
            fontWeight:'500'
        }

    }

    
    

]