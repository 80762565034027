import { SxProps, TypographyStyle } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export type TabItemsProp = {
  label: string;
  icon: JSX.Element;
}[];

const TabSwitchUI = ({
  tabItems,
  tab,
  setTab,
  activeColor,
  inActiveColor,
  textOverRideStyles,
  viewOverRideStyles,
}: {
  tabItems: TabItemsProp;
  tab: number;
  activeColor: string;
  inActiveColor: string;
  setTab: Function;
  textOverRideStyles?: TypographyStyle;
  viewOverRideStyles?: SxProps;
}) => {
  // handler
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box
      sx={{
        "& .MuiTabs-indicator": {
          backgroundColor: activeColor,
          height: "5px",
          borderRadius: "3px",
        },
        ...viewOverRideStyles,
      }}
    >
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{
          display: "flex",
          "& .MuiTabs-flexContainer": {
            borderBottom: `5px solid ${inActiveColor}`,
          },
        }}
      >
        {tabItems.map(({ label, icon }) => (
          <Tab
            key={label}
            label={label}
            icon={<>{icon}</>}
            sx={{
              ...textOverRideStyles,
              flexGrow: 1,
              maxWidth: "50%",
              "&.Mui-selected": {
                color: `${activeColor} !important`,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabSwitchUI;
