import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { get } from '../../helpers/storage_helper';

const initialState = {

    default_val : 0,
    mode : "dark",

    show_bg:true,
    value: 0,
    softPhone: false,
    showChat: false,
    showMenus: false,
    isIncomingCall:false,
    call_duration:'00:00:00',
    cust_data:"",
    callStatus:'',
    call_type:"outgoing",
    WsStatus:'',
    HoldAndMute:{},
    postMessage:{},
    client:null,
    missed_calls_view:false,
    show_nums:false,
    logData:{},
    soft_phone_unique_messages_ids : [],
    // hide_menus : true
    admin_roles : ['super_admin', 'market_admin', 'it_admin','ops_analyst'],

    softphone_icon_prev : ['/cust_follow_up', '/fa/view/', '/fa_appl_for/', '/fa_appl/view/','/call_tasks' ,'/borrower/indiv/view/']
    
}

const CommonReducer = createSlice({
  name: 'common_slice', 
  initialState,

  reducers: {
    setValuesIntoRedux : (state,action) =>{
     const data = action.payload
     for (const values in data){
        state[values] = data[values]
     }
    },
    change_soft_phone_unique_messages_id : (state, action)=>{
      state.soft_phone_unique_messages_ids = [
        ...state.soft_phone_unique_messages_ids,
        action.payload
      ];
    },
    toggleMenu: (state, action) => {

      const type = action.payload;
      if (type === "soft_phone") {
        state.softPhone = !state.softPhone;
        state.outgoingCall = false
        // state.hide_menus = false
      }else if (type === "show_menus"){
        state.softPhone = !state.softPhone;
        state.showMenus = false;

      }
      else if (type == "missed_call"){
        state.missed_calls_view = false
        state.softPhone = false;

      }

       else {
        state.showMenus = !state.showMenus;
        state.showChat = false;
        state.missed_calls_view = false
        state.softPhone = false;

      }
       
    },
    rehydrate: (state, action) => {
      
        return { ...state, ...action.payload };
      
    },

    resetState : ()=>initialState
 
  },
});

export const { setValuesIntoRedux,toggleMenu,rehydrate, change_soft_phone_unique_messages_id, resetState} = CommonReducer.actions;

export default CommonReducer.reducer;
