import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {borrowerSearch} from '../../actions/borrower_actions';
import {format_date_tbl,capitalize_first_letter, Homeacc_prvdrLogoselector,title_case} from '../../helpers/common_helper';
import { Link} from 'react-router-dom';
import {searchLead, updateAuditedby} from "../../actions/lead_actions";
import {TooltipLabel} from "../common/partial";
import { RMNameSplit } from '../../helpers/common_helper';
import { get } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortDatecommon, sortBizcommon } from '../../helpers/sortingHelpers';
import { Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { check_priv } from '../../helpers/storage_helper';
import {dd_value} from "../../actions/common_actions";
import { BasicReactTable } from '../../helpers/react_table';
import withRouter from '../../Router/Withrouter';
class KycPendingList extends FlowComponent {

	state = {
		columns :[],
		lists : [],
		copy:[],
		dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],	
		show_table: false
	}

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
	
	componentWillMount() {		
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})		
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs) 				           
			}
		});
		this.drawTable()		
    }

	sortDate (a,b) {		
		const current_Date = a.props.children[1]
		const prev_Date = b.props.children[1]		
		return sortDatecommon(current_Date,prev_Date)
	}
	sortBiz (a,b) {		
		return sortBizcommon(a.props.full_text,b.props.full_text)
	}

	drawTable () {
		const market = get('market')
		const market_name = market.country_code
		let lead_search_criteria = {status : 'pending_name_n_audit', last_30_days : true}
      	searchLead(this.req({lead_search_criteria}))
			.then((response) => {				
				if(!response){return };
				if(response.status === "success"){
					this.setState(state => state.columns = this.columndatas())									
					if (response.data.length > 0){																							
						this.setState({lists:response.data})
						this.setState(state=>state.all_count = response.data.length)																					
						const newArr = get([market_name]+'_kycAuditpending') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_kycAuditpending')														
						this.state.all_Acc_pvdrs.map(each=>{																				
							const count = response.data.filter(indiv => indiv.acc_prvdr_code === each)		
							const length = count.length										
							this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
								this.dynClickhandler(this.state.filter_actions[each][1].status,each)									

							})
							this.setState(state=>state.filter_actions[each][0].count = length)
						})	
						if(this.props.is_open_accordion){
							this.handleToggle("Account")
						}	
					}											       		
				}
			})
	}

	handleAuditKyc = (e, row) =>{
        var audited_by = row.original.audited_by
        var auditor_name = row.original.auditor_name
        var resp = null
        if(audited_by == null && check_priv('lead','pending_kyc_list')){
            resp = window.confirm("You are going to initiate the audit KYC process for this lead. Once you start, no other auditor can act on this lead.Are you sure?")
            if(resp){
                updateAuditedby(this.req({id: row.original.id}))
                .then((response) => {
                    this.resp(response)
                    if(!response){return};
                    if(response.status === "success"){

                        // window.location.href = "/lead/audit_kyc/"+row.id;
						this.props.navigate("/lead/audit_kyc/"+row.original.id)
                    }
                })
            }
        }
		else if ( !check_priv('lead','pending_kyc_list')){
			window.alert(`You cannot take any action to this lead. However you can view the KYC record`)
			// window.location.href = "/lead/audit_kyc/"+row.id;
			this.props.navigate("/lead/audit_kyc/"+row.original.id)

		} 
		else if (audited_by != null && get('user_id') !=  audited_by){
            window.alert(`${auditor_name} has already initiated audit process for this KYC record. You can not take action. However you can view the KYC record`)
            // window.location.href = "/lead/audit_kyc/"+row.id;
			this.props.navigate("/lead/audit_kyc/"+row.original.id)


        }else if (audited_by != null && get('user_id') ==  audited_by){
            // window.location.href = "/lead/audit_kyc/"+row.id;
			this.props.navigate("/lead/audit_kyc/"+row.original.id)
        }  
		     
        
    }


	columndatas () {
		return [
			// 	{
			// 	Header : "Cust ID",
			// 	accessor: 'cust_id' ,
			// 	Cell: e =>  <Link to={"/borrower/indiv/view/"+e.value} target="_blank" >{e.value}</Link>,
			//
			// },
			{  
				Header: 'Account Number',  
				id :'acc_prvdr_code',
				accessor:row => <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {row.account_num}</p> </div> ,
			},
			{
				Header : "Biz Name",
				id: 'biz_name',
				sortable:true, 
				accessor: row =><TooltipLabel full_text={row.biz_name} overlay={row.cust_name} no_reduce={true} />,	
			},
			{  
				Header : "Lead Created Date",
				id: 'lead_date', 
				// sortMethod: (a,b) => this.sortDate(a,b),
				accessor:row => format_date_tbl(row.lead_date),
		     },
			 {  
				Header: "Mobile Number",  
				id :'mobile_num',
				accessor : row =>(row.mobile_num),
				style : {textAlign:"center"} 
			},
			{  
				Header: 'RM Name',  
				id :'flow_rel_mgr_name',
				sortable:true,
				accessor : row =>title_case(row.flow_rel_mgr_name),
			   
			},
			{
				Header : "Type",
				id: 'type',
				sortable:true,
				accessor:row=>dd_value(row.type, 'lead_type')
			},{
				Header : "Initiated By",
				id: 'auditor_name',
				accessor:row=> row.auditor_name ? row.auditor_name : "NA"
			},
			{
				Header : "Audit Initiated",
				// sortable: false,
				id: 'audit_start_time',
				accessor:row=>row.audit_start_time ? row.audit_start_time+" ago" : "NA"
			},
			{
				Header : "Action",
				// sortable: false,
				accessor: 'id' ,
				Cell: ({ row }) => (<Button variant="primary" onClick={e => this.handleAuditKyc(e, row)}>
					{check_priv('lead','pending_kyc_list')?'Audit KYC':'View KYC'}</Button> )

			}
					
			]
	}



	dynClickhandler (key,val) {
		const market = get('market')
		const market_name = market.country_code												
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each=> each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state=>state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state=>state.all_Btn_status = true)
					}
				}					
				localStorage.setItem([market_name]+'_kycAuditpending',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e=>{
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state=>state.dyn_Btn_selected = newOne)																															
				localStorage.setItem([market_name]+'_kycAuditpending',JSON.stringify(newOne))
				this.setState(state=>state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each=>each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state=>state.filter_actions[val][1].status = key)
				}							
				this.setState(state=>state.all_Btn_status=false)													
				localStorage.setItem([market_name]+'_kycAuditpending',JSON.stringify(this.state.dyn_Btn_selected))
			}else{				
				this.state.all_Acc_pvdrs.map(e=>
					this.state.filter_actions[e][1].status = false
				)				
				this.setState(state=>state.dyn_Btn_selected = [])								
				localStorage.setItem([market_name]+'_kycAuditpending',JSON.stringify([]))
				this.setState(state=>state.all_Btn_status=key)
			}
		}					
		const newArr = get([market_name]+'_kycAuditpending')				
		let combinedArr = []
		newArr.map(each=> {			
			const filterArr = this.state.lists.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)			
		})		
		this.setState(state=>state.copy = combinedArr)									
	}	

    render(){		
        return (
			<Accordion defaultActiveKey={this.props.is_open_accordion ? '0' : undefined}>
                <Card className={this.props.is_open_accordion ? "mt-3":""}>
					<div className={`${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
						<div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
							<h4 className="card-title text-white tableHead_font title-bottom">Leads with Pending KYC Audit</h4>
							{this.state.lists.length > 0 &&
								<>
									<div className='countREL card-title'>
										<button className={'filterBtns allBtn_size'} onClick={()=>this.dynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
										<div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
											<label>{this.state.all_count}</label>
										</div>
									</div>									
									{this.state.all_Acc_pvdrs.map((each,idx)=>									
										<div key={idx} className='countREL  card-title'>																					
												<>
													<div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.dynClickhandler(!this.state.filter_actions[each][1].status,each)}>													  
														<img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img> 																	
													</div>
													<div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>													
														<label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>																																
													</div>
												</>										
										</div>																		
									)}
							</>
							}
							<Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")} >
								<BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
							</Accordion.Toggle>
						</div>	
						</div>
						<div className="card-body no-padding">
						<Accordion.Collapse eventKey="0">
							<Card.Body className='react_common_table'>
								{/* <CommonReactTable showPag={false} defaultPageSize={50} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"lead_date",desc:true}]}/>		 */}

									<BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true}   csv_file={false} default_page_size={50}  defaultSorted={[{id:"lead_date",desc:true}]} />  				
							</Card.Body>
						</Accordion.Collapse>
					</div>
				</Card>
            </Accordion>
        )

    }
}


export default withRouter(KycPendingList);
