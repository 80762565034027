import { Button } from "react-bootstrap"
import React from "react"






const CustomButton = ({button_text, handleClick, onclick_props = {}, disabled = false, is_loading = false, loading_text = '', variant = '', size = '', custom_styles = {}, custom_class=''})=>{


    let styles = {
        textTransform :'none',
        ...custom_styles}

    const handleButtonClick = ()=>{
        handleClick(onclick_props)
    }


    return(
        <Button className={`${custom_class}`} variant = {variant} onClick={()=>handleButtonClick()} style={styles} size={size} disabled={disabled || is_loading}>{is_loading ? loading_text : button_text}</Button>
    )
}

export default CustomButton