import { createContext, Dispatch } from "react";

// state
export type AlertState = {
  showModal: boolean;
};

// dispatch handler
export type TriggerModalHandler = {
  type: typeof TRIGGER_MODAL;
  payload: {
    show: boolean;
  };
};

// dispatch type
export const TRIGGER_MODAL = "TRIGGER_MODAL";

// ctx type
type HmeAlertCtxProps = {
  hmeAlrtState: AlertState;
  dispatch: Dispatch<DispatchProps>;
};

export type DispatchProps = TriggerModalHandler;

export const HmeAlrtCtx = createContext({} as HmeAlertCtxProps);
