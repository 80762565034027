import React from "react";

import {get, Config} from '../../../helpers/storage_helper';
import {capitalize_first_letter,getLabel,get_dir_path,rekyc_modify_state_handler} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';

import {dd_value} from '../../../actions/common_actions';

import { Homeacc_prvdrLogoselector , convert_sec_to_min_sec,RoundOffSplitter} from '../../../helpers/common_helper';
import FlowComponent from "../../common/component/core/FlowComponent";
import{ CgNotes}from'react-icons/cg';


class RekycOwnerInfoComponent extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
        this.national_id_front_side=["first_name","last_name","gender","dob","id_proof_num"]
        this.customer_name=["first_name","last_name"]
    }


  
    audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }

      checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }

      rekyc_modify_checking(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(addl_key !=null && idx ==null ){
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected_n_modified")))){
            return this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }

       else if(idx!=null){
          if(( (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected_n_modified")))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }
        else{
     

          if( cust_reg_json[json_type][json_key].hasOwnProperty(key) && ((cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  || (cust_reg_json[json_type][json_key][key].hasOwnProperty("collected_n_modified")))){
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
         }
        }
       
      }
      

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "Re-KYCed"
         }
         
         else{
          return "MODIFIED"
         }
        }

        rekyc_modify_checking_status(json_arr,type=null){
          let a=false
        
            for(let i=0;i<json_arr.length;i++){
            
            if(type!=null){
              if(((this.props.owner_info.new_cust_reg_json.id_proof[json_arr[i]].hasOwnProperty("modified") && (this.props.owner_info.new_cust_reg_json.id_proof[json_arr[i]].modified))||
              (this.props.owner_info.new_cust_reg_json.id_proof[json_arr[i]].hasOwnProperty("collected_n_modified") && (this.props.owner_info.new_cust_reg_json.id_proof[json_arr[i]].collected_n_modified)))){
                return true
              }
            }
  
  
            }

        }

  

      
      display_image(proof_full_path,proof_path,actual_file=null,file_of=null,height=null,id=null,rotate=null){
        

        return(
            <div className={`w-100 no-padding `}>
      
            <DisplayImage image_path={get_dir_path(proof_full_path,proof_path)} index={null} rotate={rotate!=null ?false:true} file_of={file_of} lead_id={this.props.lead_id} style={{height: "560px" ,width:'100%'}} file_name={proof_path}  view_lead={()=>this.props.view_lead()} actual_file={actual_file} />
            </div> 
        )
     }

  
    render(){
        return(
           <>
           {this.props.owner_info.audit_kyc_wizard_menus.id_proof &&
            <div className='row no-margin justify-content-center'> 
            
            {this.props.owner_info.View_lead_section_loader ? <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:
            <>
            <div className=' col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                    <h2 className='text-white text-center'>
                    <b>1 - Personal Info Verification</b> 
                    </h2>
                    <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.owner_info.active_section && (this.props.owner_info.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

            </div> 
            <div className=' col-lg-12 py-3 mb-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_info && this.props.owner_info.cust_reg_json && this.props.owner_info.cust_reg_json.hasOwnProperty('id_proof') && 
                    <b>{this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value ?<>{ get('kyc_docs_config')[this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value] + " Verification"}</>:'-' }</b> 
                   }  
                   </h2>

            </div> 
            {this.props.owner_info &&
             <>
             
             <>
           
            
             <div className='col-lg-10 py-5 mt-3 px-5' style={{background:'#223b56'}}>
             {(this.props.owner_info.cust_reg_json&&this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="national_id") &&
                    <h2 className='text-white'>
                    <b> {getLabel('national_id')} Front Side Verification</b>
                    </h2>
              }
             {this.props.owner_info.old_cust_reg_json &&
             <>
             <div className="row">
              <div className="col-md-12 no-padding col-lg-6">
              <h2 className={`text-white p-2 px-3  old_json_text_bg_color  text-center`}> <b>ID Proof Details (As per KYC)</b></h2>

              </div>

              <div className="col-md-12 col-lg-6">
              { (  this.props.owner_info.new_cust_reg_json.id_proof.hasOwnProperty("photo_id_proof_full_path") && this.props.owner_info.new_cust_reg_json.id_proof.photo_id_proof_full_path.modified ) ?
              <h2 className='text-white p-2   new_re_kyc_text_bg_color text-center'> <b>ID Proof Details (As per Re-KYC)</b></h2>
              :
              <h2 className={`text-white p-2 px-3  old_json_text_bg_color  text-center`}> <b>ID Proof Details (As per Re-KYC)</b></h2>

              }
              </div>

             </div>
             <div className=' mt-4 d-flex justify-content-center '>
             { this.props.owner_info.active_section &&
                <div>
                    {this.props.owner_info.active_section.verify &&
                      <div className="closedStamp ">
                      <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                      </div>   
                    }
                </div>
               }   
            <div className='col-md-6 w-100 old_json_text_bg_color' style={{background:'#223b56'}}>
              {(this.props.owner_info.old_cust_reg_json&& ((this.props.owner_info.old_cust_reg_json.id_proof?.id_proof_type.value=="nira_letter") || (this.props.owner_info.old_cust_reg_json.id_proof?.id_proof_type.value=="nat_id_replacement_cer"))) ?
                   <>
                   <div className=' col-lg-12 py-3 my-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_info && this.props.owner_info.old_cust_reg_json && this.props.owner_info.old_cust_reg_json.hasOwnProperty('id_proof') && 
                        <b> {this.props.owner_info.old_cust_reg_json.id_proof.id_proof_type.value? get('kyc_docs_config')[this.props.owner_info.old_cust_reg_json.id_proof.id_proof_type.value] :"-"} </b> 
                        }  
                    </h2>

                    </div> 

                    <div className='col-lg-12 pb-5 mt-3 px-5' style={{background:'#223b56'}}>
                   
                    {this.props.owner_info.old_cust_reg_json &&
                    <>
                    <div className=' row '>
                   
                    <div className='col-md-12 no-padding col-lg-12' >

                        {this.display_image(this.props.owner_info.old_cust_reg_json.id_proof.photo_id_proof_full_path.value,this.props.owner_info.old_cust_reg_json.id_proof.photo_id_proof.value,"rekyc_photo_id_proof","560px",null,null,false)}
                   
                    </div>
                   

                    {this.props.owner_info.allow_biz_owner_manual_id_capture &&

                    <div className='col-lg-6 p-3 d-flex mt-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b> Flow app was not able to extract text from the National ID and RM typed the data fields from National ID manually.  If both are not matching, please re-assign back to RM and ask to correct it.</p>
                    </div>
                    
                   }
                    </div>

                   

                   
                    </>
                    }
                   
                    </div>

                    <div className=' col-lg-12 py-3 my-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_info && this.props.owner_info.old_cust_reg_json && this.props.owner_info.old_cust_reg_json.hasOwnProperty('id_proof') && 
                        <b>  National ID Copy</b> 
                        }  
                    </h2>

                    </div> 
                   </>
                   :
                   <>
                   {this.props.owner_info.cust_reg_json&&(this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="nira_letter") &&
                   <>
                    <div className=' col-lg-12 py-3 my-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        Nira letter
                    </h2>

                    </div> 
                    <p className="text-white m-0 text-center" style={{minHeight:'610px'}}> NO DATA</p>

                   </>
                  }
                   </>
              }
             <div className='col-md-12 col-lg-12  px-3 ' >

             <div className='mt-3 text-light  h-100 w-100' >
             {(this.props.owner_info.old_cust_reg_json&& ((this.props.owner_info.old_cust_reg_json.id_proof?.id_proof_type.value=="nira_letter") || (this.props.owner_info.old_cust_reg_json.id_proof?.id_proof_type.value=="nat_id_replacement_cer"))) ? 

              <DisplayImage className={` `} image_path={get_dir_path(this.props.owner_info.old_cust_reg_json.id_proof.photo_national_id_copy_full_path.value,this.props.owner_info.old_cust_reg_json.id_proof.photo_national_id_copy.value)} style={{height:'560px',width:'100%'}} file_name={this.props.owner_info.old_cust_reg_json.id_proof.photo_national_id_copy.value}   lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} />
              :
             <DisplayImage className={` `} image_path={get_dir_path(this.props.owner_info.old_cust_reg_json.id_proof?.photo_id_proof_full_path.value,this.props.owner_info.old_cust_reg_json.id_proof?.photo_id_proof.value)} style={{height:'560px',width:'100%'}} file_name={this.props.owner_info.old_cust_reg_json.id_proof?.photo_id_proof.value}   lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} />

              }
              </div>
             </div>
             <div className='col-md-12 no-padding col-lg-12' >
             <div className='m-0 text-light  h-100 w-100 ' style={{background:'#223b56'}} >
             
                <div className=' py-4 px-3' >
                    


                <div className=' py-4 px-4' >
                    {this.national_id_front_side.map((item,idx)=>{return(
                      <div className={`align-items-center col-12  mb-3  ${this.props.owner_info.cust_reg_json.id_proof[item]?.modified ? "my-5":"my-4" } `}>
                        
                       <div className='row no-margin'>
                            <p className='no-margin text-white business_operations_text col-4'>  {dd_value(item.toUpperCase())}  </p>
                            <h2 className='text-white audit_kyc_text col-8 '> <b>{ capitalize_first_letter( this.props.owner_info.old_cust_reg_json.id_proof?.[item] ?this.props.owner_info.old_cust_reg_json.id_proof[item].value :'')}</b></h2>
                        </div>
                    
                       </div>
                    )})}
                    <div className='row no-margin my-4 py-2 px-3'>
                      <p className='no-margin text-white business_operations_text pt-2 col-4'>  NATIONALITY  </p>
                      <h2 className='text-white audit_kyc_text col-8 '> <b>{get("market").country_code }</b></h2>
                    </div>
                </div>

               
                 <br/>
         
                </div>
                
               
              { this.props.owner_info.old_cust_reg_json.active_section &&
                <div>
                    {this.props.owner_info.old_cust_reg_json.active_section.verify &&
                      <div className="closedStamp ">
                      <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                      </div>   
                    }
                </div>
               }   
             </div>
            
           
             </div>
           

             </div>

             <div className='col-md-6  w-100'>
              <fieldset className= {` h-100 ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof") ? ` ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof")+"_field_set"}`:"new_re_kyc_text_bg_color" } ` } style={{background:'#223b56'}}>
              {this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof")+"_text_color"} `}> 
              <b> {this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof") } </b> 
              </legend> :""}

              {(this.props.owner_info.cust_reg_json&& ((this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) &&
                  <>
                   <div className=' col-lg-12 py-3 mt-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_info && this.props.owner_info.cust_reg_json && this.props.owner_info.cust_reg_json.hasOwnProperty('id_proof') && 
                        <b> {this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value?   get('kyc_docs_config')[this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value] :"-"} </b> 
                        }  
                    </h2>

                    </div> 

                    <div className='col-lg-12 py-5  px-5' style={{background:'#223b56'}}>
                   
                    {this.props.owner_info.cust_reg_json &&
                    <>
                    <div className=' row '>
                   
                    <div className='col-md-12 no-padding col-lg-12' >


                        {this.display_image(this.props.owner_info.cust_reg_json.id_proof.photo_id_proof_full_path.value,this.props.owner_info.cust_reg_json.id_proof.photo_id_proof.value,"photo_id_proof","nira_letter","560px")}
                   
                    </div>
                   

                    {this.props.owner_info.allow_biz_owner_manual_id_capture &&

                    <div className='col-lg-6 p-3 d-flex mt-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b> Flow app was not able to extract text from the National ID and RM typed the data fields from National ID manually.  If both are not matching, please re-assign back to RM and ask to correct it.</p>
                    </div>
                    
                   }
                    </div>

                   

                  
                    </>
                    }
                   
                    </div>

                    <div className=' col-lg-12 py-3 my-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_info && this.props.owner_info.cust_reg_json && this.props.owner_info.cust_reg_json.hasOwnProperty('id_proof') && 
                        <b> National ID Copy</b> 
                      
                        }  
                    </h2>

                    </div> 
                   </>
              }
             <div className='col-md-12  px-3 col-lg-12' >
             <div className='mt-3 text-light   h-100 w-100' >
              {(this.props.owner_info.new_cust_reg_json&& ((this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) ? 
              this.display_image(this.props.owner_info.new_cust_reg_json.id_proof.photo_national_id_copy_full_path.value,this.props.owner_info.new_cust_reg_json.id_proof.photo_national_id_copy.value,"photo_id_proof",this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value,"560px",null)
              :
             this.display_image(this.props.owner_info.new_cust_reg_json.id_proof.photo_id_proof_full_path.value,this.props.owner_info.new_cust_reg_json.id_proof.photo_id_proof.value,"photo_id_proof",this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value,"560px",null)

            }

             </div>
             </div>
             <div className='col-md-12 col-lg-12 no-padding' >
             <div className='m-0 text-light  h-100 w-100 ' style={{background:'#223b56'}} >
                  <div className=' py-4 px-4' >
                    
                    {this.national_id_front_side.map((item,idx)=>{return(
                      <div className={`align-items-center col-12  mb-3 my-4 `}>
                      {this.props.rekyc_modify_state_handler(this.props.owner_info,"new_cust_reg_json","id_proof",item,null,null,`id_proof.${item}`)} 
                     </div>
                    )})}

                    <div className='row no-margin my-4 py-2 px-3'>
                      <p className='no-margin text-white business_operations_text pt-2 col-3'>  NATIONALITY  </p>
                      <h2 className='text-white audit_kyc_text col-7  '> <b>{get("market").country_code }</b></h2>
                    </div>
                    
                </div>
               
                 
           
             </div>
            
           
             </div>
           
             </fieldset>
           
             </div>

             
             </div>
             
             
             </>
             }
             </div>

             <div className={`col-lg-10 pt-3 pb-5 px-5 ${this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="national_id" ? "mt-3":"mt-0"}`} style={{background:'#223b56'}}>
           {get('market').country_code=="UGA" && (this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="national_id") &&
            <>
             <h2 className='text-white px-3'>
            <b> National ID Back Side Verification</b>
             </h2>
             {this.props.owner_info.old_cust_reg_json &&
             <>
             <div className="row">
              <div className="col-md-12 p-0 col-lg-6">
              <h2 className={`text-white p-2 px-3  old_json_text_bg_color text-center`}> <b>ID Proof Details (As per KYC)</b></h2>

              </div>
              <div className="col-md-12 col-lg-6">
              

              <h2 className={`text-white p-2    text-center ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof_back")?"new_re_kyc_text_bg_color":"old_json_text_bg_color" }`}> <b> ID Proof Details (As per Re-KYC)</b></h2>

              </div>

             </div>

             <div className=' mt-4 row '>
          
            <div className='col-md-6 old_json_text_bg_color' style={{background:'#223b56'}}>

             <div className='col-md-12 col-lg-12 mb-3 px-3 ' style={{background:'#223b56'}} >
             {this.props.owner_info.old_cust_reg_json.id_proof?.photo_id_proof_back_full_path?.value ?
             <div className={`my-3 mt-4 text-light  h-100 w-100`}>
             {this.display_image(this.props.owner_info.old_cust_reg_json.id_proof.photo_id_proof_back_full_path.value,this.props.owner_info.old_cust_reg_json.id_proof.photo_id_proof_back.value,this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value ,"560px","back","photo_id_proof_back","id_proof_back")}

             </div>:
             <p className="my-3 text-center text-white">NO DATA</p>
             }
             </div>

           
           
            
             </div>
             <div className='col-md-6 '>
              <fieldset className= {` h-100 ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof_back") ? ` ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof_back")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
              {this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof_back") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof_back")+"_text_color"} `}> 
              <b> {this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_id_proof_back") } </b></legend> :""}

                <div className='col-md-12 col-lg-12  my-4 px-3 '  >

                <div className={`m-0 text-light  h-100 w-100`}>

                <DisplayImage image_path={get_dir_path(this.props.owner_info.new_cust_reg_json.id_proof.photo_id_proof_back_full_path.value,this.props.owner_info.new_cust_reg_json.id_proof.photo_id_proof_back.value)} actual_file={"national_id_back"} rotate={true} file_of={"photo_id_proof_back"} lead_id={this.props.lead_id} style={{height: '560px',width:'100%'}} file_name={this.props.owner_info.new_cust_reg_json.id_proof.photo_id_proof_back.value}  view_lead={()=>this.props.view_lead()} />
                </div>
                </div>
              </fieldset>
            </div>
             </div>
             
             
             </>
             }

             <h2 className='rekyc_note mt-4 text-center' > <b>NOTE</b> : Compare the existing data (as per FLOW) with the current data (as entered by RM). </h2>
            </>}
             <div className='row'>
              <div className="col-6"></div>
              <div className="col-6">
               {this.props.owner_info.form_spinner ? 
               this.checkbox_spinner()
                :
                <div className='col-lg-12 py-2 '>
               {this.props.owner_info.active_section &&
                <form className={`${(this.props.owner_info.active_section.verify || (this.props.owner_info.active_section.to_reassign==true) )? "disable_div":''}  ${ (((this.props.owner_info.responseData.audited_by== get("user_id")) || (this.props.owner_info.responseData.audited_by==null )) && this.props.owner_info.active_section.pending_with !="rm")?"":'disable_div'}`}>

                   { (this.props.owner_info.new_cust_reg_json&& ((this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) &&

                    <div className='d-flex '>
                    <input type="checkbox" id="vehicle1" className='pointer mt-1' style={{height:'19px',width:'25px'}} checked={ (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") ?this.props.owner_info.active_section.flags.nira_letter_clear_nd_readable : this.props.owner_info.active_section.flags.nat_id_replacement_cer_clear_nd_readable } name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked, (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") ?"nira_letter_clear_nd_readable" :"nat_id_replacement_cer_clear_nd_readable")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1" style={{lineHeight:'23px'}}>The {(this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value=="nira_letter")?"NIRA": "Certificate for Replacement of National ID "} letter is in a clear and readable state.</label><br/><br/><br/>
                    </div>
                   } 
                   
                    <div className='d-flex '>
                    <input type="checkbox" id="vehicle1" className='pointer mt-1' style={{height:'19px',width:'25px'}} checked={this.props.owner_info.active_section.flags.original_id_proof && true} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"original_id_proof")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1" style={{lineHeight:'23px'}}> The 
                    {(this.props.owner_info.new_cust_reg_json&& ((this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) ?  " National ID Proof copy " :
                     this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value ? ` ${get('kyc_docs_config')[this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value]} `:"-"}
                      {" details matches with the details entered by the RM."}</label><br/><br/><br/>
                    </div>
                    <div className='d-flex my-3'>
                    <input type="checkbox" id="vehicle2" name="vehicle2 " className='pointer mt-1' value="Car" checked={this.props.owner_info.active_section.flags.clear_and_readable && true} style={{height:'19px',width:'25px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_and_readable")}/>
                    <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle2" style={{lineHeight:'23px'}}> The text in the {

                    (this.props.owner_info.new_cust_reg_json&& ((this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) ? "National ID Proof copy":
                    this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value ? get('kyc_docs_config')[this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value]:"-"
                    } is in a clear & readable state.</label><br/><br/>
                    </div>

                    <div className='d-flex  mt-3'>
                    <input type="checkbox" id="vehicle3" name="vehicle3 " className='pointer mt-1' checked={this.props.owner_info.active_section.flags.face_recogonizable && true} style={{height:'19px',width:'25px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"face_recogonizable")}/>
                    <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle3" style={{lineHeight:'23px'}}>  The face of the customer in the {
                      (this.props.owner_info.new_cust_reg_json&& ((this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_info.new_cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) ? "National ID Proof copy":
                    this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value ? get('kyc_docs_config')[this.props.owner_info.cust_reg_json.id_proof.id_proof_type.value]:"-"
                    } is recognizable.</label><br/>
                    </div>
                </form>
                }
                 
             </div>
               }
               </div>
             </div>
             </div>

             { (this.props.owner_info.cust_reg_json && this.props.owner_info.cust_reg_json.id_proof.hasOwnProperty("photo_lc_letter")) && (this.props.owner_info.cust_reg_json.id_proof.photo_lc_letter.value !=null) && 
                    <>
                     <div className=' col-lg-12 py-3  my-5' style={{background:'#4a4c51'}}>
                     <h2 className='text-white text-center'> <b> LC Letter</b> </h2>
 
                     </div> 
                      
                    <div className='row m-0 justify-content-center col-10 px-0 py-3' >
                      <div className="row col-12">
                        <div className="col-md-6 p-0 col-lg-6">
                        <h2 className={`text-white p-2 px-3  old_json_text_bg_color text-center`}> <b>LC Letter (As per KYC)</b></h2>

                        </div>
                        <div className="col-md-6 col-lg-6">
                        

                        <h2 className={`text-white p-2    text-center ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_lc_letter_full_path")?"new_re_kyc_text_bg_color":"old_json_text_bg_color" }`}> <b> LC Letter (As per Re-KYC)</b></h2>

                        </div>

                      </div>

                      <div className="row m-0 col-12">
                      <div className='col-md-6 old_json_text_bg_color' style={{background:'#223b56'}}>

                        <div className='col-md-12 col-lg-12 mb-3 px-3 ' style={{background:'#223b56'}} >
                        {this.props.owner_info.old_cust_reg_json.id_proof.photo_lc_letter_full_path.value ? 
                        <div className={`my-3 mt-4 text-light  h-100 w-100`}>

                        <DisplayImage image_path={get_dir_path(this.props.owner_info.old_cust_reg_json.id_proof.photo_lc_letter_full_path.value,this.props.owner_info.old_cust_reg_json.id_proof.photo_lc_letter.value)} actual_file={"lc_letter"}  file_of={"photo_lc_letter"} lead_id={this.props.lead_id} style={{height: '560px',width:'100%'}} file_name={this.props.owner_info.old_cust_reg_json.id_proof.photo_lc_letter.value}  view_lead={()=>this.props.view_lead()} />



                        </div>
                        :<p className="text-white text-center mt-3 " style={{alignSelf:'center'}}> NO DATA</p>}
                        </div>
                        </div>
                        <div className='col-md-6'>

                        <fieldset className= {`${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_lc_letter_full_path") ? ` ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_lc_letter_full_path")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
                        {this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_lc_letter_full_path") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_lc_letter_full_path")+"_text_color"} `}> 
                        <b> {this.rekyc_modify_checking(this.props.owner_info,"new_cust_reg_json","id_proof","photo_lc_letter_full_path") } </b></legend> :""}


                          <div className={`m-0 text-light  h-100 w-100`}>

                          <DisplayImage image_path={get_dir_path(this.props.owner_info.new_cust_reg_json.id_proof.photo_lc_letter_full_path.value,this.props.owner_info.new_cust_reg_json.id_proof.photo_lc_letter.value)} actual_file={"lc_letter"} rotate={true} file_of={"photo_lc_letter"} lead_id={this.props.lead_id} style={{height: '560px',width:'100%'}} file_name={this.props.owner_info.new_cust_reg_json.id_proof.photo_lc_letter.value}  view_lead={()=>this.props.view_lead()} />
                          </div>
                         
                        </fieldset>
                             
                        </div>
                        <div className=" row d-flex align-items-center justify-content-center col-12 m-0">
                          <div className="col-6"></div>
                          <div className="col-6">
                          <div className='col-lg-12 col-md-12 py-2 px-0  mt-4'>

                          {this.props.owner_info.active_section &&
                          <form className={`${(this.props.owner_info.active_section.verify || (this.props.owner_info.active_section.to_reassign==true) )? "disable_div":''}  ${ ((this.props.owner_info.responseData.audited_by== get("user_id")) || (this.props.owner_info.responseData.audited_by==null )) ?"":'disable_div'}`}>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="lec_letter" className='pointer' style={{height:'19px',width:'20px'}} checked={this.props.owner_info.lc_letter_clear_and_readable ? this.props.owner_info.lc_letter_clear_and_readable : this.props.owner_info.active_section.flags.lc_letter_clear_and_readable} name="lec_letter" onChange={(e)=>this.props.checkbox_verification(e.target.checked,`lc_letter_clear_and_readable`)}/>
                            <label className='no-margin pl-3 audit_kyc_text pointer'  for="lec_letter">Text in LC letter is clear and readable</label><br/><br/><br/>
                            </div>
                            
                          </form>
                          }
                          </div>
                          </div>
                        </div>
                      </div>

                       
                    </div>
                    </>
                    
             }
             
          
             </>
            </>
            }
            </>}
            </div>
           }

         
       
           

            </>
        )
    }
}
export default RekycOwnerInfoComponent;