import { Badge, Button, IconButton } from "@mui/material"
import { CircularProgress } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useTheme } from "@emotion/react";


const CustomButton = ({ text, variant, onClick, isDisabled = false, isLoading = false, startIcon = '', endIcon = '', style = {}, loader_size = 30,badge_style={}, loader_styles, component = "p", badge = false, badge_content = "" ,type = undefined}) => {

    const theme = useTheme()
    return <Button sx={style} variant={variant} onClick={() => onClick()} startIcon={startIcon} endIcon={endIcon} disabled={isDisabled || isLoading} component={component} type={type} >
        {isLoading ? 
        <CircularProgress variant="indeterminate"thickness={4} value={100} size={loader_size} /> :
            badge ?
            <Badge color="secondary" sx={badge_style} badgeContent={badge_content} >
                <NotificationsNoneIcon sx={{color: theme.palette.custom_colors.secondary_a_50, fontSize: '25px' }} />
            </Badge>
        : text
        }


    </Button>



}

export default CustomButton