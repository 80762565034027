import React,{useEffect, useRef, useState} from 'react';
import * as moment from "moment";
import {dd, dd_value, getListByUrl, getNameList} from '../actions/common_actions';
import {Config, get} from './storage_helper';
import {Overlay, OverlayTrigger, Popover} from "react-bootstrap";
import { CSVLink} from "react-csv";

import { GoVerified } from "react-icons/go";
import { rekycVerify,viewLead} from '../actions/lead_actions';


import {FcInfo} from 'react-icons/fc';
import {requestForExportCsv} from '../actions/common_actions';
import { Tooltip,Button } from 'react-bootstrap';	

import $ from 'jquery' 
import { lbl_dur } from './product_helper';
import StatusButton from '../UI/common/component/core/StatusButton';
import { elements } from 'chart.js';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { NO_RES_OPT, DEF_OPT, DEF_OBJ_OPT} from '../constants';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';


export function percent(portion, total){
	return ((portion/total) * 100 ).toFixed(2) + " %";
}


export function get_path(path_type = null) {

	if ( process.env.REACT_APP_UI_VERSION && (process.env.REACT_APP_UI_VERSION !== "local")) {
	  const marketDetails = JSON.parse(localStorage.getItem("market")) || {};
	  const countryCode = marketDetails.country_code === "MD" ? "MG" : marketDetails.country_code.toUpperCase();
	  const S3_data = JSON.parse(localStorage.getItem("jsonData")) || {};
	  const countryPath = S3_data[countryCode] || {};
  
	  const path = countryPath[path_type === "kyc" ? path_type.toUpperCase() : (path_type === "file_upload" || path_type === "view_file") ? "FILE" : "CORE"] || process.env.REACT_APP_APP_API_ROOT;
	  if (path_type === 'kyc') {
		return `${path}/api/${path_type}`;
	  }else if (path_type === 'view_file'){
		return `${path}`;
	  }
	 else {
		const apiType = path_type === "admin" ? "admin" : (path_type === "app" ? "app" : (path_type === "file_upload" ? "kyc" : ""));
		return `${path}/api/${apiType}`;
	  }
	} else {
		const localPaths = {
		  admin: process.env.REACT_APP_ADMIN_API_ROOT,
		  app: process.env.REACT_APP_APP_API_ROOT,
		  kyc: process.env.REACT_APP_UGA_KYC_APP_API_ROOT,
		  file_upload:process.env.REACT_APP_ADMIN_API_ROOT,
		  view_file: window.location.origin
		};  

	  return localPaths[path_type] || process.env.REACT_APP_APP_API_ROOT;
	}
  }
  

export function percent_rnd(portion, total){
	if(total == 0){
		return "NA";
	}
	return Math.round((portion/total) * 100 ) + " %";
}


export function addr_txt(address){
	return  address.parish + ", " + address.region
}

Date.prototype.addDays = function(days){
	var result = new Date(this.valueOf);
	result.setDate(result.getDate() + days);
	return result;
}

export const CSVDownload = (props) => {
	const btnRef = useRef(null);
	useEffect(() => {
	  if (btnRef.current) {
		console.log("btnRef")
		console.log(btnRef)
		console.log(props)
		btnRef.current.click();
	  }
	}, [btnRef]);
  
	return (
			<CSVLink {...props}>
			<span id='remp' ref={btnRef}/>
	  		</CSVLink>
	);
  };

export function make_readable(searchData){
	let data = [];
	Object.keys(searchData[0]).forEach((key)=>{
	let data_c = searchData[0][key]
		if(data_c !== undefined && data_c  !== null && data_c !== true && data_c !== false && key != 'acc_prvdr_code'){
			data[key] = dd_value(searchData[0][key],"any");
		}
		else if(data_c == true){
			data[key] = 'Yes'
		}
		else if(data_c == false){
			data[key] = 'No'
		}
		else{
			data[key] = searchData[0][key];
		}
	});
	searchData[0] = {...data};
	get_csv(searchData)	

}

export function getSelectOptions(data_key, add_default = true, parent_data_code = null,addl_condition=null,status_enabled=false,remove_choose_child = false){    // Take dropdown values from "master_data" table
    const filtered_data = dd(data_key, parent_data_code);

    if(addl_condition !=null){
      return filtered_data;
    }
    //alert(JSON.stringify(filtered_data));
    var options = [];
    if(filtered_data.length > 0){
      if (status_enabled) {
        options = filtered_data
          .filter(item => item.status == 'enabled')
          .map((item, index) => (
            <option key={index} value={item.data_code}>{item.data_value}</option>
          ));
      } else {
        options = filtered_data.map((item, index) => (
          <option key={index} value={item.data_code}>{data_key == 'float_switch_status' ? dd_value(item.data_code ,item.data_code) : item.data_value}</option>
        ));
      }
      if(add_default){
        options = [...DEF_OPT, ...options];
      }

      if(remove_choose_child || data_key=="cashback_type"){
        options.splice(0,1)
      }

      return options;
    }
    else{
       //this.get_db_options(data_key);
    }
  }

export function get_csv(searchData){
	let sent_to=Config('user_role');
	let market = get('market');
    const country_code =market.country_code;
	let admin = sent_to[country_code].ops_admin;
    const time_zone =market.time_zone;
	if(window.confirm(`Are you sure you want to send a request to Ops Admin (${admin}) to download the CSV?`)){
	requestForExportCsv({searchData : searchData[0],country_code,time_zone})
	.then((response) => {
		if(!response){return };
		if(response.status === "success"){
			alert(`Request for download CSV Sent to Ops Admin (${admin}) Successfully`);
		}
	}) 
  }
}
/*
export function append_request(request){
	if(request == null){ request = {}}
    
   request = hlpr_add_status(request);   
    const market = get('market')
    if(market){
      request.time_zone = market.time_zone;
    }
    
    return request;
}*/

export function hlpr_add_status(request){
	 if(!request.hasOwnProperty('status')){
      request.status = null;
    }
    const element = Object.keys(request)[0];
    const child = request[element];
    if(child != undefined && child != null && child.constructor == Object && !child.hasOwnProperty('status')){
      request[element].status = null;
    }
    
    return request;
}
export function addDays(date, days, date_format = "YYYY-MM-DD"){
	if(date instanceof moment){
		return date.add(days, "days");	
	}else{
		return moment(date, date_format).add(days, "days");	
	}
	
	/*var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;*/
}

export function c(message){

}

export function frmt_date(date){
	var options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'}
	//var date = Date.now();
	//return date.setTime(Date.parse(date)).toLocaleDateString();
	return options;
}

export function frmt_datetime(date){
	//var options = {weekday: 'short', year: 'numeric', month: 'short', day: '2-digit', timeZoneName: 'short'}
	return date;
}

export function current_date(){
	var date = new Date();
	var current_date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
	return current_date;
}

export function title_case(string){     //remove underscore and capitalize words
	if (string == null){
		return string
	}
	var i = "";
	var str = string.split("_");
	for(i=0;i<str.length;i++){
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1) ;
	}
	return str.join(" ");
}

export function format_month_change(date){
	return moment(date, "YYYY-MM-DD").format('DD MMM YYYY');
}

export function capitalize_first_letter(string){
	if(isNaN(string) && string){
		
		return string.charAt(0).toUpperCase() + string.slice(1);
	}else{
		return string;
	}
}



export function filter(array, filter_by, filter_value){
	return array.filter(item => item[filter_by] == filter_value);
}


export function format_date_tbl(date, incl_time = false, year_digits = 4, str_date_format = "YYYY-MM-DD h:mm a",hide = false){
	var formatted_date = format_date(date, incl_time, year_digits, str_date_format);
	return (
		<>
			<span className="hide">{date}</span>{formatted_date}
		</>
	)
}



export function format_date(date, incl_time = false, year_digits = 4, str_date_format = "YYYY-MM-DD h:mm a"){

	 var out_format = "DD MMM YYYY"

	if(year_digits == 2){
		out_format = "DD MMM YY";
	}
	if(incl_time){
		out_format = out_format + " h:mm a"	
	}
	
	if(date instanceof moment){
		//alert("INSIDE")
		return date.format(out_format);
	}
	else if(date == null){
		return "";
	}
	else{
     return moment(date, str_date_format).format(out_format);	
	}
}

export function get_time_diff(date1, date2){
	if(date1 == null || date2 == null){return}
	let time_diff = moment.duration(moment(date1).diff(moment(date2)))
	let days = moment(date1).diff(moment(date2), 'days');
	let hours =  time_diff.hours()
	let mins = time_diff.minutes()
	let str = (days > 0 ? days+'d ' : "") + (hours > 0 ? hours+'h ' : "") + (mins > 0 ? mins+'m ' : "")
	return str === "" ? "0m ago" : str+'ago'
}

export function convert_sec_to_min_sec (seconds){
	let minutes = Math.floor(seconds / 60);
	let new_seconds = seconds % 60;
	return `${minutes} min ${new_seconds} sec`
}


export function autofill(max_loan_amount, duration, flow_fee, flow_fee_duration, currency_code){
	
	let product_name = "";
	if(max_loan_amount){
		product_name += "STD " + max_loan_amount +" "+ currency_code +" ";
	}
	if(flow_fee_duration){
		product_name += flow_fee_duration;
	}else if(duration){
		product_name += "for " + duration + " days";
	}
	if(flow_fee){
		product_name += " @" + flow_fee;
	}
	
	return product_name;
}

export function to_amount(amount){
    //var result = parseFloat(amount).toFixed(2);
    var result = Number(amount);
    return result;
}

export function word_count(text){
	if(text){
		var word_count = text.length;
		return word_count;
	}else{
		return 0;
	}
}

export function person_name(obj){
	var name = "";
	if(obj.hasOwnProperty('first_name') && obj.first_name){
		name += obj.first_name;
	}

	if(obj.hasOwnProperty('middle_name') && obj.midde_name){
		name = name + " " +obj.middle_name;
	}

	if(obj.hasOwnProperty('last_name') && obj.last_name){
		name = name + " " +obj.last_name;
	}

	return name;
}
export function lbl_amt(amount, currency_code = get('market').currency_code){
	var nf = new Intl.NumberFormat();
	if(amount){
		return nf.format(amount) + " " + currency_code;
	}else{return "-"}
}

export function lbl_status(status){
	return status === "enabled" ? "Disable" : "Enable";
}

export function style_status(status ){
  return status === "enabled" ? 'btn btn-danger do_disable' : 'btn do_enable';
}

export function variant_color(value)
{
	if(value == "in_progress" || value == 0)
	{
		return 'warning';
	}
	else if(value === "enabled" || value == "completed")
	{
		return 'success' 
	}else if(value === "disabled" ||  value == "pending" || value == 2)
	{
		return 'danger';
	}
	else if(value == 1){
		return 'secondary';
	}
	else{
		return 'info'
	}
} 

export function cust_score_color(value)
{
	if(value === "eligible"){
		return 'success' 	
	}else if ( value === "requires_flow_rm_approval"){
		return "primary"
	}else {
		return "danger"
	}

	}

export function variant_value(status)
{

	return status === "enabled" ? 'E' : 'D';
}

export function lbl_date(date, alt_text = "-NA-"){

	return date ? format_date(date, false, 2): alt_text	
}

export function short(labelValue) {

    // Nine Zeroes for Billions
    
    var value = labelValue;
    if(Math.abs(Number(labelValue)) >= 1.0e+9){
    	value =  Math.abs(Number(labelValue)) / 1.0e+9 
    	value = value.toFixed(3) + "B"
    }else if (Math.abs(Number(labelValue)) >= 1.0e+6){
    	value = Math.abs(Number(labelValue)) / 1.0e+6 
    	value = value.toFixed(3) + "M"
    }else{
    	value = Math.abs(Number(labelValue));	
    }
    ;
    return value;

}


export function calc_percent(value, total, zero_value = ''){
	if(Number(total) == 0 ){
		return zero_value;
	}
	return (100 * (Number(value) / Number(total))).toFixed(2) + "%"
}

export function reduce_character(char, limit=10)
{
  if(char)
  { 
     var str = char;
     var rs = str.substring(0,limit);
     return rs+"...";
  }
  else
  {
      return 0; 
  }
  
}

export function empty_obj(obj){

	var obj_keys = Object.keys(obj);

	obj_keys.map(function (key){
		 (obj)[key] = ""
	})

	return obj;
}

export function checkNA(val){
	if(val){
		return val;
	}else{
		return "NA";
	}
}

export function get_code(str, size = 4){
       var str_wo_vow = remove_vowels(str);
       if(str_wo_vow.length < size){
               return str_wo_vow.toUpperCase();
       }
       return str_wo_vow.substr(0, size).toUpperCase();

}

export function remove_vowels(str){
       return str.replace(/[aeiou]/ig, '');
}

export function lbl_acc_name(account){
	return account.acc_prvdr_name + " (" + dd_value(account.type, 'account_type') +  " - "+  stringify_acc_purpose(account.acc_purpose) +") "  +account.acc_number
}

export function stringify_acc_purpose(acc_purposes){
	acc_purposes = acc_purposes.map((purpose, i) => {
		return dd_value(purpose)
	})
	return acc_purposes.join(' | ')
}

export function get_result_msg(ln_prod, state, category){
	if(state.all_ineligible){
		return "Customer is ineligible for this FA product. "+ state.opm_name + " shall decide to allow condonation to the customer."
	}
	else {
		return "Can not apply FA. "+  ln_prod.result_msg
	}
	
}

export function fa_product_action(result_code, acc_prvdr_code)  
{
	const is_partner_with_portal = Config('aps_w_flow_portal_int').includes(acc_prvdr_code); 
	if(result_code == 'eligible'){
		return is_partner_with_portal ? "Notify to Partner" : "Select";
	}else if(result_code === "requires_flow_rm_approval"){
		return is_partner_with_portal ? "Notify to Partner" : "Request Approval";
	}else if(result_code === "product upgrade"){
		return "Request Upgrade";
	}
	else if (result_code == "ineligible"){
		return "Submit for Approval";
	}
	else{
		return result_code;
	}
}



export function get_eligibility(result_code, approver){
	if(result_code == 'eligible'){
		return "Eligible";
		
	}else if(result_code === "requires_flow_rm_approval"){
		return "Need "+ approver +"'s Approval"
	}else if(result_code == "ineligible"){
		return "Ineligible";
	}
	else if(result_code == "product upgrade"){
		return "Need Upgrade";
	}
	else if(result_code){
		return capitalize_first_letter(result_code)
	}else{
		return result_code;
	}
	
}


 export function rt_algn(value)
 {
return (<span style={{float:'right'}}>
   {value}
	</span>);
}

export function get_pending_info(loan) {
	let result = {}
	if(loan.otp_status == 'sent') {result.status = 'Pending w/ SMS Provider'; result.overlay_info = "Sending of the confirmation code SMS has been initiated and is now pending with the SMS Provider. The SMS will reach the customer shortly. If the status doesn't change for 2 minutes, if required, you shall decide to resend the code.";}
	else {result.status = 'Pending w/ Customer'; result.overlay_info = loan.confirm_code_info;}
	return result
}


export function get_loan_status(loan){
	if(loan.customer_consent_rcvd == false && loan.status === 'pending_disbursal'){
		let info = get_pending_info(loan)
		return <OverlayTrigger placement='bottom' trigger="hover" overlay={  <Popover style={{fontSize: "15px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}>{info.overlay_info}</Popover>}><span>{info.status} <sup><FcInfo/></sup></span></OverlayTrigger>;
	}
	else if(loan.status === 'pending_disbursal' || loan.status === 'hold'){
		return <OverlayTrigger placement='bottom' trigger="hover" overlay={  <Popover style={{fontSize: "13px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}><b><u>{"Disbursal Status:"}</u><br/>{title_case(loan.disbursal_status||"not_initiated")}</b></Popover>}><span>{dd_value(loan.status)} <sup><FcInfo/></sup></span></OverlayTrigger>;
	}
	else{
		return dd_value(loan.status)
	}
}

export function get_dir_path(full_path, file_name) {
        if(full_path && file_name){
            let regex = new RegExp('/' +file_name + '+$')
            return full_path.replace(regex, '')
        }
    }

export function multi_acc_pur_tostring (acc_purposes,cust_review_reason=null) {						
	return(
		<>
			{acc_purposes.map((each,idx)=>{
				return(
					 <OverlayTrigger placement='bottom' trigger="hover" overlay={  <Popover style={{fontSize: "13px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}><b>{dd_value(each,cust_review_reason!=null?"cust_review_reason": "account_purpose")} </b></Popover>}>
						<span style={{paddingRight:3}} key={idx}>   {dd_value(each,cust_review_reason!=null?"cust_review_reason": "account_purpose")} {(acc_purposes.length >1 && idx ==0) ? "," :" "} <br/></span>
					 </OverlayTrigger>

       				
				)
			})}
		</>
	)	
}	

export function RMNameSplit (val) {		
	var str = val;
	var splited = str.split(/(\s+)/); 
	var matches = str.match(/\b(\w)/g);      
	return(
		<p>{splited[0]}&nbsp;{matches[1]}</p>
	)
}

export function Homeacc_prvdrLogoselector ({logotype,product_takeup_print,from_stmt}) {	
	if(from_stmt==true){
		return(<div style={{height:10,width:30,margin:`${product_takeup_print ? 'auto':'0px'}`}}>
		<img className="img-fluid" style={{maxWidth:'30px'}} src={`/img/${logotype}_logo_color.png`} alt={logotype}/>			
		</div>
		)
	}
	return(
		
		<div style={{height:30,width:60,margin:`${product_takeup_print ? 'auto':'0px'}`}}>			
			<img className="img-fluid" style={{maxWidth:'60px'}} src={`/img/${logotype}_logo_color.png`} alt={logotype}/>			
		</div>
	)
}

export function RoundOffSplitter ({val,unit,align_center,position,notify,from,vs_value,negativesign,type,currency,noRoundoff,font_color, format=true, copy_val = null, fontWeight = 600, fontSize = '16px', text = null, text_color = null, normal_text = false }) {
   	const [show, setShow] = useState(false);
	const [iscopy,setIscopy]=useState(false)
  	const target = useRef(null);

	  if(val !='Infinity'){
		var clr= text_color ? text_color : 'black';
		var curcy = currency ? currency :''
		var noRoundoff= noRoundoff ? noRoundoff:''
		
			
		if((val) ==0){
			clr = 'gray'
		}else if((val) <0){
			if(negativesign){
				clr = 'green'
			}else{
				clr = 'red'
			}
		}else{
			if(negativesign){
				clr = 'red'
			}else{
				if(from)
					clr = 'green'
				else
					clr = text_color ? text_color : 'black'
			}
		}
			
		}
	  var ranges = [
		{ divider: 1e9 , suffix: 'B' },
		{ divider: 1e6 , suffix: 'M' },
		{ divider: 1e3 , suffix: 'K' }	
	  ];
	  function formatNumber(n,type) {	

		if(format){
			for (var i = 0; i < ranges.length; i++) {
				if (n >= ranges[i].divider) {
					return (n / ranges[i].divider).toFixed(2) + " " + ranges[i].suffix + " <span style='font-weight:lighter'>" + unit+"</span>";
				}
			}
			const round_off_val = Number(n)? Number(n).toFixed(1) : 0+" <span style='font-weight:lighter,font-size:'7px'>" + unit+"</span>";	
			return round_off_val
		}	
		else{	
			return val
		}
	
	  }	
	  
		function close_tool_time(){
			setShow(false)
		}
		function start_tool_time(){
			setShow(true)
		}
		
		function CopyToClipboard(val) {
			function copy_time(){
				
				setIscopy(false);
			}
		  try {
			
			if(copy_val){
				navigator.clipboard.writeText(copy_val)
			}
			else{
				navigator.clipboard.writeText(vs_value?vs_value+" "+ curcy:val+" "+ unit)
			}
			
			setIscopy(true)
			let k=setTimeout(copy_time, 1500)
			
		} 
		catch (e) {
			setIscopy(false)
		}
		 
		}
	  
	  
  return (
    <>
      <div ref={target} onMouseOverCapture={() => {setTimeout(start_tool_time,10)}}  onMouseLeave={() => {setTimeout(close_tool_time,300)}} >
	  {!notify ?
		normal_text ? 
		<p style={{color:clr}}>{text}</p>
		:
	  	<p className='no-margin' onClick={()=>CopyToClipboard(val)} style={{fontWeight:600 ,color: clr,fontSize:'16px' , textAlign: !align_center ? "" : "center"}} dangerouslySetInnerHTML={{__html: noRoundoff ? Math.round(val)+" " + unit :from && val >0 ?  '+' + formatNumber(val)  : formatNumber(val) }}></p> :
		<InfoTwoToneIcon sx={{color:`${font_color? "#3992AB":"#4bafe3"}`,fontSize:'20px',}}/>}
 		{/* // <i class="fa-solid fa fa-exclamation-circle" style={{color:`${font_color? "#3992AB":""}`}}></i>} */}

      </div>
	  
      <Overlay target={target.current} show={show} placement="top" >
        {(props) => (
          <Tooltip id={"overlay-example,tool-tip "} {...props}  >
            			{ from ? <b style={{fontSize:'13px'}} >{vs_value ? (<div className={`${iscopy ? 'bg-primary':null}`}>{vs_value.toLocaleString('en-GB',{maximumFractionDigits: 1})+" "+ curcy } 
						
						</div>) :'N/A'}</b> 
						: <b style={{fontSize:'13px'}} className={`${iscopy ? 'bg-primary':null}`}>{typeof(val)=='object' ?  val : val?val.toLocaleString('en-GB',{maximumFractionDigits: 1}):0} {unit} 
						
						</b>}	
          </Tooltip>
        )}
	
      </Overlay>
	  {iscopy ?<Overlay  target={target.current} show={show} placement="bottom">
        {(props) => (
          <button className='  btn btn-sm bg-default text-light font ' id={"overlay-example,tool-tip "} {...props}  >
            			{iscopy?'Copied':null}
							
          </button>
        )}
		
      </Overlay>:null}
	 
    </>
  );
}


	  
// 	return(		
// 		<OverlayTrigger
// 			key={"tool-tip"}
// 			placement={position}
// 			overlay={
// 				<Tooltip id={"tool-tip"}>
// 				{ from ? <b style={{fontSize:'13px'}}>{vs_value ? vs_value.toLocaleString('en-GB',{maximumFractionDigits: 1})+" "+ curcy:'N/A'}</b> : <b style={{fontSize:'13px'}}>{typeof(val)=='object' ?  val : val.toLocaleString('en-GB',{maximumFractionDigits: 1})} {unit}</b>}	
// 				</Tooltip>
// 			}
// 		>	
// 			{!notify ?
// 				<p className='no-margin' style={{fontWeight:600 ,color:clr,fontSize:'14px' , textAlign: !align_center ? "" : "center"}} dangerouslySetInnerHTML={{__html: noRoundoff ? Math.round(val)+" " + unit :from && val >0 ?  '+' + formatNumber(val)  : formatNumber(val) }}></p> :
// 				<i class="fa-solid fa-circle-exclamation"></i>
// 			}
			
// 		</OverlayTrigger>
// 	)
// 		}else{
// 			return 'N/A';
// 		}
// }

export function time_duration(seconds){
	let min = Math.trunc(seconds/60);
	let sec = seconds%60;
	return (
		<p>
			{min}M {sec}S
		</p>
	);
}

export function DateFormatter ({val,type}) {	
	const new_Date = new Date(val)
	const date = new_Date.getDate()		
	const year = new_Date.getFullYear()
	const month = new_Date.toLocaleString('default', {
		month: 'long'
	})
	const time = new_Date.toLocaleTimeString(undefined, {
		hour:   '2-digit',
		minute: '2-digit',		
	})			
		return(
			<>
				{type === "date-monthName-year" ?
					<p className='no-margin text-white headerTitle preHeaderpad' style={{fontWeight:600}}>Live Report - {date+" "+month+" "+year+" "}at{" "+time}</p> :
					<p className='no-margin text-white headerTitle preHeaderpad' style={{fontWeight:600}}>Report Run Date - {date+" "+month+" "+year} </p>
				}
			</>
		)		
}



export function convertMinutesToHours(minutes) {
    var decimalHours = minutes / 60;
    decimalHours = decimalHours.toFixed(2);

    return decimalHours;
}


export function get_cs_agent_names(agent) {
    let match = agent.match(/agent\d+/i);
    return match[0].charAt(0).toUpperCase() + match[0].slice(1).toLowerCase();
}



export function page_count(total,pagination_count){
			
    
    const page_count_mod = (total%pagination_count)

    const page_count =  Math.trunc(total / pagination_count) + (page_count_mod > 0 ? 1: 0);
    return page_count

  }



  export function amount_currency_label(labelValue, fix_val = 3, currency_code) {
    
    var value = labelValue;
    if(Math.abs(Number(labelValue)) >= 1.0e+9){
    	value =  Math.abs(Number(labelValue)) / 1.0e+9 
    	value = value.toFixed(fix_val) + "B"
    }else if (Math.abs(Number(labelValue)) >= 1.0e+6){
    	value = Math.abs(Number(labelValue)) / 1.0e+6 
    	value = value.toFixed(fix_val) + "M"
    }else{
    	value = Math.abs(Number(labelValue));	
    }
    ;
    return value + " " + currency_code;

}

export const split_amount = (amount, currency_code)=>{
	let amount_str = String(Math.round(amount))

	return amount_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + currency_code;



}

  export function find_notification_time(date_time_str){
    const diff = moment().diff(moment(date_time_str));
    const duration = moment.duration(diff);
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    if (years !== 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
      } else if (months !== 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
      } else if (days !== 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours !== 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (minutes !== 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else if (seconds !== 0) {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
      } else {
      return 'just now';
      }

  }

 export const copy_text = (text)=>{
	return new Promise((resolve, reject)=>{
		navigator.clipboard.writeText(text).then(()=>{
			resolve(text)
		}).catch((error)=>{
			reject(error)
		})
	})
}



export const getValueFromLangJson = (key) =>  {
	let language_json = get('language_json');
	return language_json[key]
}

export const getLabel = (key) => {
	let market = get('market');
    const country_code =market.country_code;
	const getLabelArray = Config('id_proof_web_ui_label');
	return getLabelArray[country_code][key];
}
export const capitalize_first_letter_string = (word) => {
	console.log(word)
	if(!word){
		return 
	}
    let splitted_word = word.split(" ");
    for (let i = 0; i < splitted_word.length; i++) {
        splitted_word[i] = splitted_word[i][0].toUpperCase() + splitted_word[i].slice(1).toLowerCase();
    }
    return splitted_word.join(" ");
}


export function format_amount(amount, space = " ") {
    const absNumber = Math.abs(amount);
    const roundedNumber = Math.round(absNumber);
    let formattedNumber;

    if (roundedNumber >= 1e9) {
        formattedNumber = (roundedNumber / 1e9).toFixed(2) + space + 'B';
    } else if (roundedNumber >= 1e6) {
        formattedNumber = (roundedNumber / 1e6).toFixed(2) + space +'M';
    } else if (roundedNumber >= 1e3) {
        formattedNumber = (roundedNumber / 1e3).toFixed(2) + space + 'K';
    } else {
        formattedNumber = roundedNumber.toString();
    }

    return formattedNumber;
}

export function dynAccPrvdrFilter (key,val,row_datas,all_Acc_pvdrs,dyn_Btn_selected,filter_actions,state_name) {
	const market = get('market')
	const market_name = market.country_code											
	if(key){												
		if(val !== "ALL"){								
			const find_val = dyn_Btn_selected.findIndex(each=> each === val)
			if(find_val == -1) {					
				dyn_Btn_selected.push(val)		
				state_name['filter_actions'][val][1].status = key			
				
				if(dyn_Btn_selected.length === all_Acc_pvdrs.length) {	
					state_name['all_Btn_status'] = true
				}
			}				
			localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(dyn_Btn_selected))										
		}else{					
			const newOne = []
			all_Acc_pvdrs.map(e=>{
				filter_actions[e][1].status = true
				newOne.push(e)
			})		
			state_name['dyn_Btn_selected'] = newOne			
																																
			localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(newOne))
			state_name['all_Btn_status']=key
		}					
	}		
	else if(!key){					
		if(val !== "ALL"){											
			const find_val = dyn_Btn_selected.findIndex(each=>each === val)							
			if(find_val > -1){					
				dyn_Btn_selected.splice(find_val,1)
				state_name.filter_actions[val][1].status = key
			}			
			state_name.all_Btn_status=false				
			localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(dyn_Btn_selected))								
		}else{		
			console.log('all_Acc_pvdrs', all_Acc_pvdrs)
			console.log('filter_actions', filter_actions)
			all_Acc_pvdrs.map(e=>
				filter_actions[e][1].status = false
			)	

			state_name.dyn_Btn_selected = []	
			localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify([]))
			state_name.all_Btn_status=key
		}
	}					
	const newArr = get([market_name]+'_faPendingdisb')			
	let combinedArr = []
	newArr.map(each=> {			
		const filterArr = row_datas.filter(indiv=>indiv.acc_prvdr_code === each)
		combinedArr.push(...filterArr)			
	})	
	console.log('combinedArr', combinedArr)
	console.log('state_name', state_name)

	state_name.copy= combinedArr	

	return state_name;
	
}   


export const common_req =(request = null, acc_prvdr_code = null)=>{

	if (request  === null) {
		return {}
	}
	let market = get('market')
	let final_req = {...request, country_code : market.country_code, time_zone : market.time_zone, access_token:get('access_token')}

	if(acc_prvdr_code){
		final_req[acc_prvdr_code] = acc_prvdr_code
	}

	return final_req;

}

const calculate_duration = (product) => {
	return lbl_dur(product.duration, product.flow_fee_duration, product.loan_purpose) 
}

export const get_time_zone_str =(country_code= null)=>{

	if(!country_code){
		country_code = get('market').country_code
	}

	return Config('country_time_zone_str')[country_code]
	
}




export const create_column = (data) => {
	const columns = data.map((item, idx) => ({

	  Header: () => <div className='text-center'>{item.header}</div>,
	  id: item.id,
	  accessor: item.id,
	  Cell: (cell) => <div className='text-center'>{
		item.id == "si_no" ? cell.row.index+1:
		item.id == "lbl_duration" ? calculate_duration(cell.row.original):
	    item.is_full_name ? person_name(cell.row.original):
		item.is_date ? format_date(cell.row.original[item.id]):
		item.is_amount ? lbl_amt(cell.row.original[item.id]):
		item.is_status_bg ?  
		<div className='d-flex justify-content-center align-items-center'>
		<StatusButton id={cell.row.original.id} hide_action={true} entity="loan_products" status={cell.row.original.status}/>
        </div>
		 :
		cell.row.original[item.id] ? dd_value(cell.row.original[item.id]) :" No data"
		}</div>

	}));
  
	console.log('columns', columns);
	return columns;
  };

  
export const generate_row_data = (headers, rows)=>{

    const result = rows.map(row => {
      const obj = {};
      headers.forEach((header, index) => {
          obj[header] = row[index];
      });
      return obj;
   });

  return result

  }

export const useDebounce = (callback, delay) => {
    // const debounceTimeout = useRef(null);

    // const debouncedFunction = (...args) => {
    //   if (debounceTimeout.current) {
    //     clearTimeout(debounceTimeout.current);
    //   }

    //   debounceTimeout.current = setTimeout(() => {
    //     callback(...args);
    //   }, delay);
    // };

    // return debouncedFunction;

	let timer;
	return(...args)=>{
		clearTimeout(timer);
		timer = setTimeout(()=>callback(...args), delay)
	}
  };
  
export const reduce_text_length = (text) =>{
  const value =   text.length > 7 ? text.substring(0,7)+ '...': text
   return value;
}

export const convert_to_options = (options_arr = [], option, id) => {
    let menu_items = [];
    if (options_arr.length > 0) {
        options_arr.forEach((items) => {
            const optionObj = {
                label: items[option],
                id: items[id]
            };
            menu_items.push(optionObj);
        });
    }
    return menu_items;
};
  

export const encodeImageFileAsURL=(element) => {
    var result 
    var file = element;
    var reader = new FileReader();
    reader.onloadend = () => {
		result = reader.result
    }   
     reader.readAsDataURL(file);
	result  =  reader.result
   console.log('result', reader,result)
    return result;
  }




export const req=(request = null, send_ap_code = true, key = null, calling_api = true) =>{
    
   return append_request({...request}, this, send_ap_code, key);
  }
  
export const  append_request = (request, this_comp, send_ap_code, key)=>{

	if(request == null){ request = {}}
	  if(!request.hasOwnProperty("country_code") && (get('role_codes') === 'super_admin' || get('role_codes') === 'it_admin' || get('role_codes') === 'ops_analyst' || (Config('global_user') && Config('global_user').includes(get('user_id'))))){
		request.country_code = get('market').country_code;
	  }
	  // if(send_ap_code && !request.hasOwnProperty("acc_prvdr_code")){
		
	  //   request.acc_prvdr_code = this_comp.acc_prvdr_code;
	  // }
	  if(send_ap_code && get('acc_prvdr_code') != null){
		request.acc_prvdr_code = get('acc_prvdr_code');
	  }
  
	  else if(!request.hasOwnProperty("acc_prvdr_code") && key != null && request[key].hasOwnProperty("acc_prvdr_code")){
		request.acc_prvdr_code = request[key].acc_prvdr_code;
		delete request[key].acc_prvdr_code;
	  }
	  if(!request.hasOwnProperty("time_zone")){
		request.time_zone = get('market').time_zone;
	  }
	  request = hlpr_add_status(request);  
	 /* 
	  const market = get('market')
	  if(market){
		request.time_zone = market.time_zone;
	  }*/
	  // console.log(request)
	  return request;
  }

export const setOptionsToStateFromApi = async (data_key , request = null, addl_key = null, path = 'admin', default_value = null)=>{
	var state_key = data_key;
	if(request == null){
	request =  {country_code : get('market').country_code, status: "enabled"}
   }
   else if(request.hasOwnProperty('associated_with')){
	 state_key = request.associated_with  + "_" + data_key;
   }
   else if(request.hasOwnProperty('report_to')){
	 state_key = request.report_to  + "_" + data_key;
   }
   let options = [];
   
	await getNameList(data_key, req(request), path )
		.then((response) => {
		//  this.resp(response)
		 if(!response){return };
			 if(response.status === "success"){
			   if(addl_key!=null){
				 options =  selectdropdownOptions(response.data,state_key, default_value)
			   }
			   else{
				 options =  getSelectOptionsFromResult(response.data, state_key);

			   }
			 }else{
			   alert(response.message + "\n" + JSON.stringify(response.data));  
			 }
		   }
		 ); 
	
	return options
   }
  export const selectdropdownOptions = (name_list,state_key, default_value = null, data_holders = ["id" , "name"]) =>{
	
	 var data= Array.isArray(name_list)? name_list: name_list.list
	 if( data.length > 0) {
	   var options = [];
	  
	   const id = data_holders[0];
	   const name = data_holders[1];
	   options = data.map((item, index) => {
		 var obj={}
		   var value = item[id];

		  obj["label"]=item[name]
		  obj["value"]=value
		   obj["index"]=index
		   
		   options.push(obj)
		   
	   return obj;


  });

  const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
  if(default_value){
   sortedOptions.unshift(default_value)
  }
  
//   this.setState({[state_key]: sortedOptions});
 return sortedOptions
 
 }


   }
  export function select_custom_styles(){
	var customStyles = {
	   option: (provided, state) => ({
		 ...provided,
		 backgroundColor: state.isFocused ? '#097afa' :   state.isSelected ? "blue" :'#202940',
		 color: state.isSelected ? 'white' : 'white',

		 padding:state.isFocused ? '3px 0px 3px 10px' : '3px 0px 3px 10px',
	   }),
	   singleValue: (provided) => ({
		 ...provided,
		 color: 'black',
	   }),
	   control: (provided, state) => ({
		 ...provided,
		 backgroundColor: state.isFocused ? ' ' : '',
		 borderTop: state.isFocused ? 0 :0,
		 borderLeft:state.isFocused ? 0 : 0,
		 borderRight:state.isFocused ? 0 : 0,
		 borderBottom: state.isFocused ? "2px solid  #5d616f": "1px solid #5d616f",
		 boxShadow: state.isFocused ? 'none' : "",
		 }),

		 input: (provided) => ({
		   ...provided,
		   color: 'white', 
		 }),
	   };
	 return customStyles;
   }


   export function array_to_string(acc_purposes) {
	console.log('acc_purposes', acc_purposes);
  
	return (<>
		{acc_purposes.length > 0 && acc_purposes.map((each, idx) => (
			<span style={{ paddingRight: 3 }} key={idx}>
			  {dd_value(each, each)}
			  {idx < acc_purposes.length - 1 && ', '}
			</span>
		  ))
		}
	  </>);
  }

   export const show_softphone_icon = (path) => {

	const data = useSelector(state => state.cs_soft_phone);
	const prev_arr = data.softphone_icon_prev;
	return prev_arr.some(item => path.includes(item));
  };

  export const convert_json_to_parse = (json) =>{
	return JSON.parse(json)
  }

  export const getSelectOptionsFromResult = (name_list, state_key = null, data_holders = ["id" , "name"],add_default = true)=>{

	var options = [];

	if(name_list.length > 0) {
		const id = data_holders[0];
		const name = data_holders[1];
		var is_obj = false;
		options = name_list.map((item, index) => {
			var value = item[id];
			if (id == 'obj') {
				value = JSON.stringify(value);
				is_obj = true;
			}

			if (item.selected) {
				this.selected[state_key] = value;
			}
			//alert(JSON.stringify(item.selected));
			return <option key={index} value={value}>{item[name]}</option>;

		});

		state_key += "_dd";
		if (add_default== false) {
			options = [...options];
		} else {
			if (is_obj) {

				options = [...DEF_OBJ_OPT, ...options];
			} else {

				options = [...DEF_OPT, ...options]; 
			}

		}
	}else{
			 options = NO_RES_OPT;//this.get_db_options(data_key);
	}

	if(state_key != null){
	  if(state_key == "operations_auditor_dd" || state_key == "acc_prvdr_dd"){
		// this.setState({[state_key]: options.slice(1)});
		return options.slice(1)

	  }else{
		// this.setState({[state_key]: options});
		return options

	  }
	}
	
	return options;
}



export const get_suffix_date_str = (day)=>{
		
	const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return 'st';
    }
    if (j === 2 && k !== 12) {
      return 'nd';
    }
    if (j === 3 && k !== 13) {
      return 'rd';
    }
    return 'th';
}



export const setOptionsListFromAppApi = async(type, data_key , request = null, data_holders = ["id" , "name"], state_key = null,is_multiselect=null)=>{

    if(state_key == null){
      state_key = data_key;
    }
	let options = []
    await getListByUrl(type, data_key, req(request))
                  .then((response) => {
                     if(!response){return };
                     if(response.status === "success"){
                      if(is_multiselect!=null){

                        options = selectdropdownOptions(response.data,state_key)
                      }else{
                        options = getSelectOptionsFromResult(response.data.list, state_key, data_holders);
                      }
                     }else{
                        alert(response.message + "\n" + JSON.stringify(response.data));  
                     }
                      
                  }
                ); 
	
	return options
  }

export const common_login_header = () =>{
	const theme = useTheme()
	return(
		<Grid container alignItems="start" justifyContent="space-between">
		<Grid item xs={8} lg={8}>
			<Typography variant="m_poppins_semibold_white" component={'div'} >
				Hi, Welcome Back!
			</Typography>
			<Typography variant="m_inter_regular_gray" component={'p'} >
				Login to your account
			</Typography>
		</Grid>
		<Grid item xs={4} lg={4} justifyContent="end" display={'flex'} >
			<img

				src={`/img/Flow_White.png`}
				alt="Flow Logo white"
				loading="lazy"
				className='login_logo mt-2'
			/>
		</Grid>
	</Grid>
	)
}

export const remove_bg_scroll = (is_open)=>{
	if (is_open) {
		document.body.style.overflow = 'hidden';
		document.documentElement.style.overflow = 'hidden';
	} else {
		document.body.style.overflow = '';
		document.documentElement.style.overflow = '';
	}
	return () => {
		document.body.style.overflow = '';
		document.documentElement.style.overflow = '';
	};
}

// promise with delayed resolve
export const resolvePromiseWithDelay = (promise) =>
  new Promise((resolve) => setTimeout(resolve, 1000)).then(() => promise);