import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


export const searchLoan = request  => {
   return call_fetch(get_path('app') + '/loan/loans', request);
}

export const searchHeldLoan = request  => {
   return call_fetch(get_path('app') + '/loan/held_loans', request);
}

export const release_loan = request  => {
   return call_fetch(get_path('app') + '/loan/release', request);
}

export const resend_OTP = request => {
   return call_fetch(get_path('app') + '/loan/resend_otp', request);
}

export const checkExistingOtp = request => {
   return call_fetch(get_path('app') + '/otp/status', request);
}


export const getLoan = request  => {
   return call_fetch(get_path('app') + '/loan', request);
}

export const cancelLoan = request  => {
   return call_fetch(get_path('app') + '/loan/cancel', request);
}

export const repeatFloatAdvance = request  => {
   return call_fetch(get_path('app') + '/loan/repeat_fa', request);
}

export const disburseLoan = request => {
	return call_fetch(get_path('app') + '/loan_txns/disburse', request);
  
}

export const instantDisburseLoan = request => {
	return call_fetch(get_path('app') + '/loan_txns/instant_disburse', request);
  
}

export const createCaptureRepayment = request  => {
   return call_fetch(get_path('app') + '/loan_txns/capture_repayment', request);
}

export const listLoanTxn = request => {
	return call_fetch(get_path('app') + '/loan_txns/list', request);
  
}

export const createLoanComment = request  => {
   return call_fetch(get_path('app') + '/loan/comment', request);
}

export const viewComments = request  => {
   return call_fetch(get_path('app') + '/loan/comments/list', request);
}


export const getAssignList = request  => {
   return call_fetch(get_path('app') + '/loan/comment/assign', request);
}



export const get_repayment_summary = request  => {
   return call_fetch(get_path('app') + '/loan/payment_summary', request);
}

export const create_capture_payment = request  => {
   return call_fetch(get_path('app') + '/loan/capture_payment', request);
}

export const unlink_payment = request  => {
   return call_fetch(get_path('app') + '/loan/unlink_payment', request);
}

export const allowPartialPayment = request  => {
   return call_fetch(get_path('app') + '/loan/allow_pp', request);
}

export const retryDisbursal = request => {
   return call_fetch(get_path('app') + '/loan/retry_disb', request);
}

export const retryTransferAttempt = request => {
   return call_fetch(get_path('admin') + '/sales/retry_float_transfer', request);
}

export const getDisburseAttempt = request => {
   return call_fetch(get_path('app') + '/loan/get_disb_attempt', request)
}

export const changeDisbursalStatus = request => {
   return call_fetch(get_path('app') + '/loan/change_disb_status', request)
}

export const changeSwitchDisbursalStatus = request => {
   return call_fetch(get_path('admin') + '/sales/change_switch_disb_status', request)
}

export const bypass_cust_conf = request => {
   return call_fetch(get_path('app') + '/loan/bypass_cust_conf', request)
}

export const createRecoveryRequest = request => {
   return call_fetch(get_path('app') + '/loan/create_recovery', request)
}

export const checkOngoingRecovery = request => {
   return call_fetch(get_path('app') + '/loan/check_ong_rec', request)
}

export const cancelOngoingRecovery = request => {
   return call_fetch(get_path('app') + '/loan/cancel_ong_rec', request)
}

export const captureRecovery = request => {
   return call_fetch(get_path('app') + '/loan/capture_recovery', request)
}

export const searchRecoveries = request => {
   return call_fetch(get_path('app') + '/loan/list_rec', request)
}

export const handleTransactionID = request => {
   return call_fetch(get_path('app') + '/loan/check_txn_id', request)
}

export const validateTxnId = request => {
   return call_fetch(get_path('app') + '/loan/validate_txn_id', request)
}

export const update_waiver = request  => {
   return call_fetch(get_path('app') + '/loan/waive', request);
}

export const removePayment = request => {
   return call_fetch(get_path('app') + '/loan/remove_payment', request)
}

export const captureExcess = request => {
   return call_fetch(get_path('app') + '/loan/capture_excess', request)
}

export const cancelCaptureDisb = request => {
   return call_fetch(get_path('app') + '/loan/cancel_capt_disb', request)
}

export const listFAUpradeRequests = request => {
   return call_fetch(get_path('app') + '/loan/list_upg_reqs', request)
}

export const updgradeReqStatus = request => {
   return call_fetch(get_path('app') + '/loan/upgrade', request)
}


export const updateReconStatus = request => {
   return call_fetch(get_path('app') + '/loan/recon', request)
}
export const reinitiateRecon = request => {
   return call_fetch(get_path('app') + '/loan/reinitiate_recon', request)
}
export const removeDisbursal = request => {
   return call_fetch(get_path('app') + '/loan/remove_disbursal', request)
}
export const list_mnl_cap_rep = request => {
	return call_fetch(get_path('app') + '/loan/mnl_capture_fas', request);
}
export const unreversed_excess = request => {
	return call_fetch(get_path('app') + '/loan/reversed_excess', request);
}
export const unreversed_duplicate_disbursals = request => {
	return call_fetch(get_path('app') + '/loan/unreversed_duplicate_disbursals', request);
}
export const ListProofs = request => {
	return call_fetch(get_path('admin') + '/file/list/proof', request);
}
export const excess_reversal = request => {
   return call_fetch(get_path('app')+'/loan/excess_reversal', request);
}
export const cancelproofs = request => {
	return call_fetch(get_path('admin') + '/file/cancel_proof', request);
}


export const ListSwitchProof = request => {
   return call_fetch(get_path('admin') + '/sales/list_pymt_proof', request);
}

export const approveProofAction = request => {
   return call_fetch(get_path('admin') + '/sales/action_proof', request);
}

export const pending_cashback_list = request => {
   return call_fetch(get_path('app')+'/cashback/pending_list', request);
}

export const update_cashback_status = request =>{
   return call_fetch(get_path('app')+'/cashback/mark_as_failed', request);
}

export const retry = request =>{
   return call_fetch(get_path('app')+'/cashback/retry', request);
}

export const pending_capture = request =>{
   return call_fetch(get_path('app')+'/cashback/pending_capture', request);
}

export const late_auth_req_pending = request =>{
   return call_fetch(get_path('app')+'/loan_appl/late_auth_req/pending', request);
}

export const late_auth_req_action = request =>{
   return call_fetch(get_path('app')+'/loan_appl/approval', request);
}


