import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Box, Grid, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

import RHFAutocomplete from "../../../UI/CommonComponent/rhfComponents/rhfAutoComplete";
import RhfDatePicker from "../../../UI/CommonComponent/rhfComponents/rhfDatePicker";
import { HeaderVarientH2Typo } from "../../../UI/CommonComponent/typography";
import AuditorsListAutoComplete from "../../CommonComponent/commonAutoCompleteDD/auditorsListAutoComplete";
import moment from "moment";
import {
  MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD,
  QUARTERS_IN_YEAR,
} from "../../../consts";

/**
 *
 * @returns form ui with auditor field, duration field, and date selection field
 */
const FilterFormUI = ({ tab }: { tab: number }) => {
  // hook
  const theme = useTheme();
  const { watch } = useFormContext();

  // const
  const choosenSplitType = watch("splitType");
  const isQuarterlySplitTypeChoosen = choosenSplitType === "Quarterly";
  const isMonthlySplitTypeChoosen = choosenSplitType === "Monthly";
  const isDateSplitTypeChoosen = choosenSplitType === "Daily";

  return (
    <Box sx={{ pb: 4 }}>
      <HeaderVarientH2Typo
        text={"Performance Dashboard"}
        overRideStyles={{
          color: theme.palette.text.primary,
          textTransform: "capitalize",
          fontFamily: "Poppins",
          fontWeight: 600,
          py: 4,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={3}>
          <AuditorsListAutoComplete
            name="auditor"
            endIcon={<FilterAltOutlinedIcon />}
            actAs="filter"
            roleCodes={
              tab === 0
                ? ["operations_auditor", "verification_officer"]
                : ["operations_auditor"]
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <RHFAutocomplete
            name="splitType"
            options={["Quarterly", "Monthly", "Daily"]}
            sx={{
              // overides the end icon style
              "& .MuiButtonBase-root": {
                transform: "rotate(0deg)!important",
                svg: {
                  width: "20px",
                  height: "20px",
                  color: (theme) =>
                    // @ts-ignore
                    theme.palette.custom_colors.secondary_a_200,
                },
              },
            }}
            popupIcon={<FilterAltOutlinedIcon />}
            disableClearable
          />
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          {isQuarterlySplitTypeChoosen ? (
            <RHFAutocomplete
              name="quarterlySplit"
              options={QUARTERS_IN_YEAR}
              sx={{
                // overides the end icon style
                "& .MuiButtonBase-root": {
                  transform: "rotate(0deg)!important",
                  svg: {
                    width: "20px",
                    height: "20px",
                    color: (theme) =>
                      // @ts-ignore
                      theme.palette.custom_colors.secondary_a_200,
                  },
                },
              }}
              popupIcon={<FilterAltOutlinedIcon />}
              disableClearable
            />
          ) : (
            <RhfDatePicker
              name="date"
              disableFuture
              minDate={moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD)}
              maxDate={moment()}
              views={
                isDateSplitTypeChoosen
                  ? ["year", "month", "day"]
                  : isMonthlySplitTypeChoosen
                  ? ["month", "year"]
                  : ["year", "month", "day"]
              }
              format={
                isDateSplitTypeChoosen
                  ? "D MMM YYYY"
                  : isMonthlySplitTypeChoosen
                  ? "MMM YYYY"
                  : "D MMM YYYY"
              }
              sx={{
                // overides the end icon style
                "& .MuiButtonBase-root": {
                  svg: {
                    width: "20px",
                    height: "20px",
                    color: (theme) =>
                      // @ts-ignore
                      theme.palette.custom_colors.secondary_a_200,
                  },
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterFormUI;
