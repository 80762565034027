
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineZoomOutMap, MdOutlineCall, MdOutlineCallEnd, MdPhoneMissed } from "react-icons/md";
import { SlCallIn } from "react-icons/sl";
import { IoPersonCircleSharp } from "react-icons/io5";
import { custDetails } from "../../../actions/cs_management_actions";

import { useSelector, useDispatch } from 'react-redux'
import { FaPhoneAlt } from "react-icons/fa";
import { dd_value } from "../../../actions/common_actions";
import { IoIosInformationCircle, IoMdClose } from "react-icons/io";
import CallTimer from "../components/CallTimer";
import { HiOutlineSignal } from "react-icons/hi2";
import CommonCallLogModal from "../components/CommonCallLogModal";
import { setValuesIntoRedux,toggleMenu } from "../../../Redux/Slices/CommonSlice";
import { get } from "../../../helpers/storage_helper";
import ConfirmModal from "../../common/component/ConfirmModal";


const SoftphoneCallSections = ({ screen_from = false, cust_id ,call_handler,cancel_call,client,callAccepted,callRejected,toggle_modal}) => {

    const channel = new BroadcastChannel('cs_soft_phone')
    const dispatch = useDispatch()
    const [viewAddNum, setviewAddNum] = useState(false)
    const [cust_details, setcust_details] = useState(false)
    const [callData, setcallData] = useState(false)

    useEffect(() => {
        const handleStorageChange = (event) => {
          if (event.key === 'persist:root') {
            const newValue = JSON.parse(event.newValue);
            Object.entries(newValue).map(([key, value]) => {

                dispatch(setValuesIntoRedux({[key] : JSON.parse(value)}))
             
            })
          }
        };
    
        window.addEventListener('storage', handleStorageChange);
    
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, [dispatch]);



    // useEffect(()=>{
    //     var call_data = get('persist:root')
    //     console.log('call_data', call_data)
    //     setcallData(call_data)
    // },[get('persist:root')])


    

    useEffect(()=>{
        getCustDetails()
    if(screen_from){
        dispatch(setValuesIntoRedux({screen_from:screen_from,cust_id:cust_id}))
        //  channel.postMessage({message:"save_cust_values", data:{screen_from:screen_from,cust_id:cust_id}})

    }
    },[cust_id])

    const getCustDetails = () =>{
        custDetails({cust_id:cust_id})
        .then((response)=>{
            if(!response){return}
            if(response.status =="success"){
                setcust_details(response.data)
            }
        })
    }
    const setIsCallAccepted = () => {

        dispatch(setValuesIntoRedux({isCallAccepted:true}))
        callAccepted()
        // let channel = new BroadcastChannel('webrtc')
        // channel.postMessage({ message: 'isCallAccepted' })
        // dispatch(webRtcCallsStatus())
    };

    const setIsCallRejected = () => {
        callRejected()
        // let channel = new BroadcastChannel('webrtc')
        // channel.postMessage({ message: 'isCallRejected' })
        dispatch(setValuesIntoRedux({isCallInProcess:false,  isIncomingCall:false,callStatus:""
        }))

    };

    const get_mob_num_ui = (key,value,cust_details = null) =>{
        return(
            <div className="d-flex align-items-center my-3 justify-content-between">
            <div>
            <p className="m-0">{dd_value(key)}</p>
            <p className="my-2 font_weight_600">{value}</p>
            </div>
            <p className={`m-0 text-secondary pointer secondary_text_width p-2 border_radius_circle bg_blue ${datas.WsStatus || localStorage.getItem('client_tab_id') ?"":"disable_div"}`} onClick={()=>{CalltoCustomer(value,cust_details)}}>
            <FaPhoneAlt color="white" size={17} />
            </p>
        </div>
        )
    }

    const CalltoCustomer = (values,details = null,type = null) => {
        var data
        if(details){
             data = {cust_name:details.cust_name,cust_id:details.cust_id,mobile_num:values}
        }else{
             data = values
        }

        let mob_num = (type == "missed_call_view") ? values.cust_number : values
        // const payload = { key: "outgoingCall", value: true };
        dispatch(setValuesIntoRedux({cust_data: data,pending_modal:true, cust_id:data?.cust_id}))
        channel.postMessage({message:"save_cust_values", data:{cust_data: data,pending_modal:true, cust_id:data?.cust_id}})

        call_handler(mob_num,null,values)

    }

    const handleCancelCall = () => {
        cancel_call()
        // dispatch(setValuesIntoRedux({hide_menus:false}))

    }

    const close_modal = () =>{
        dispatch(setValuesIntoRedux({CallLogModal:!datas.CallLogModal}))

        // setCallLogModal(false)
      }
    
    var datas = useSelector((state) => state.cs_soft_phone)


    const show_screen = (datas.isIncomingCall  ||datas.missed_calls_view  || datas.outgoingCall || datas.show_nums )
    return (
        <>
        {datas.pending_call_dialog && datas.pending_call_log_msg&& datas.pending_modal &&
                        <> 
                        <ConfirmModal alert_message={datas.pending_call_log_msg} is_toggle={(a) => { toggle_modal(a) }} is_pending_call= {true}/>
                        </>
        }
        {show_screen &&
        <div className={`call_log_modal_size over_view_z_index  ${datas.outgoingCall ?"outgoing_call_div":""} `}>

            <div className="d-flex justify-content-end">
                <div className="call_log_close pointer" onClick={() => { (datas.isIncomingCall || datas.outgoingCall) ? null: dispatch(toggleMenu(datas.missed_calls_view?"missed_call": "soft_phone")) }}>
                    {(datas.isIncomingCall || datas.outgoingCall) ?
                    <MdOutlineZoomOutMap size={15} color="white" />
                    :
                    <IoMdClose size={15} color="white" />

                    }

                </div>
            </div>

            <div className={`${(((screen_from || datas.missed_calls_view )&& (!datas.outgoingCall))  ) ? "call_log_from_screen pb-3  call_chat_height " : "generic_call_log call_log_modal_size p-3 "} ${datas.outgoingCall ?"outgoing_call_div":""}`}>
                {datas.isIncomingCall &&
                    <div className="incoming_call_log py-2">

                        {datas.isCallInProcess && (datas.callStatus != "bridged")  &&
                        <p className="m-0 text-secondary text-center sizem secondary_text_width"> <span className="call_in_icon"><SlCallIn size={"12"} /></span>  <span className="mx-2 ">Incoming Call</span> </p>
                     }

                        {datas.callStatus == "bridged" &&
                            <div className="d-flex justify-content-between mx-2">
                            <p className="m-0 text-secondary"> <b> In Call <HiOutlineSignal color={"#02D130"} className="pl-2" size={25}  />
                            </b></p>

                            <p className="m-0  text-secondary text-center"> <b><CallTimer /></b></p>
                            </div>    
                        } 

                        <div className="d-flex justify-content-center  mt-4">
                            <div className=" ">
                                <IoPersonCircleSharp color="#4f5167" size={100} />
                            </div>
                        </div>
                        <p className="mt-3 mb-1 text-dark text-center size_medium secondary_text_width "> <b> {datas.incoming_call_details && datas.incoming_call_details.length>0&&  datas.incoming_call_details[0].cust_name ? datas.incoming_call_details[0].cust_name: "UNKNOWN NUMBER"} </b></p>
                        <p className="mb-0  text-secondary text-center size_medium secondary_text_width ">  {datas.incoming_call_details  && datas.incoming_call_details.length>0 &&datas.incoming_call_details[0].cust_mobile_num ? datas.incoming_call_details[0].cust_mobile_num: datas.incomingcall_num ? datas.incomingcall_num :"-"} </p>


                        <div className= {`incoming_buttons d-flex align-items-center my-4 ${datas.callStatus != "bridged" ? "justify-content-between" :"justify-content-center"} px-4`}>
                           {datas.callStatus != "bridged" &&
                            <div className="bg-green call_btn_bg pointer" onClick={setIsCallAccepted}>
                                <MdOutlineCall size={25} color="white" />

                            </div>
                          }
                            <div className="bg-red call_btn_bg pointer " onClick={setIsCallRejected}>
                                <MdOutlineCallEnd size={25}  color="white"/>

                            </div>

                        </div>
                        {datas.isCallInProcess &&  datas.screen_from != "churn_management" && datas.screen_from != "delinquency" && datas.screen_from != "pending_rm_visits" && <div className="d-flex justify-content-center align-items-center cs_log_call_bttn col-12 my-2 mt-4" style={{cursor:'pointer'}}>
                            <button type="button" class="btn btn-primary " disabled={datas.CallLogModal || datas.callStatus != "bridged" } onClick={()=>close_modal()}> <b>log call</b></button>
                        </div>}

                    </div>
                }

                {screen_from  && (cust_details) && (!datas.outgoingCall) && (!datas.isIncomingCall) && (datas.show_nums) &&
                <>
                {
                    <>
                        <div className="bg_dark_blue px-4 py-3">
                            <h2 className="text-white m-0"><b>Softphone</b></h2>
                        </div>
                        <div className="m-3 soft_phone_dats p-3 px-4 numbers_section">
                            <div className="d-flex align-items-center">
                                <p className="m-0"><IoPersonCircleSharp color="#4f5167" size={40} />
                                </p>
                                <p className="m-0 pl-3 text_secondary sizem "> <b>{(cust_details.customer && cust_details.customer.cust_name) ? cust_details.customer.cust_name:"-"}</b></p>
                            </div>
                            <div className="dashed_bottom_border my-3"></div>
                            <div>
                                <>{cust_details.customer &&
                                <>
                            {Object.entries(cust_details.customer.mobile_nums).map(([key, value], index) => {
                                return (
                                value &&( key !== "addl_mob_num" && key !== "cust_id" && key !== "cust_name") && (
                                    <div key={index}>
                                        {get_mob_num_ui(key,value,cust_details.customer.mobile_nums)}
                                    </div>
                                )


                                );
                            })}
                            </>
                            }
                               </>

                               {screen_from !="delinquency" &&
                                <div>
                                    <p className="m-0 sizem  text-info font_weight_600 underlined_text pointer" onClick={() => setviewAddNum(!viewAddNum)}> Additional numbers</p>

                                    {viewAddNum &&
                                        <div className="my-3">
                                            {<div>
                                        {cust_details.customer.mobile_nums.addl_mob_num.map((item,idx)=>{return(
                                                    <div key={idx}>
                                                        {item.mobile_num &&
                                                        <>
                                                    {get_mob_num_ui(`Additional Number ${idx+1}`,item.mobile_num)}
                                                    </>}
                                                    </div>
                                                )})}

                                            </div>
                                        }


                                        </div>
                                    }
                                </div>
                                }
                            </div>

                        </div>
                    </>
                }
                </>
                }

                {datas.missed_calls_view &&
                <div className="">
                   
                    <div className="bg_dark_blue px-4 py-3">
                            <h2 className="text-white m-0"><b>Missed Calls</b></h2>
                    </div>
                    <div className="scrollable_height">
                    {datas.missed_calls_history.missed_calls &&datas.missed_calls_history.missed_calls.length>0 &&
                    datas.missed_calls_history.missed_calls.map((items,idx)=>{return(
                        <div className="mx-3 my-3 misscall_card_border p-2">
                 
                            <p className="my-0 ml-3 mt-1 text-gray small_fontSize secondary_text_width">  <b>{items.missed_call_count} Missed Call{items.missed_call_count>1 ?"s" :""}</b>
                           <span className="ml-3  secondary_bg_gray"><MdPhoneMissed className="icon_top_margin" size={9}/>
                           </span>
                            </p>

                            <div className="d-flex align-items-center my-1 mt-2 justify-content-between">
                                <div className="d-flex ">
                                <p className="m-0"><IoPersonCircleSharp color="#4f5167" size={40} />
                                </p>

                                <div className="ml-2">
                                <p className="m-0 font_weight_600 "><b>{items.cust_name}</b></p>
                                <p className=" mb-0  text-gray secondary_text_width">{items.cust_number}</p>
                                </div>
                                </div>

                                <div>

                                <div className="d-flex align-items-center justify-content-center">
                                    <div>
                                  <div className={`call_back_bttn pointer ml-1 d-flex justify-content-center ${datas.client || localStorage.getItem('client_tab_id') ?"":"disable_div"}`} onClick={()=>{CalltoCustomer(items,null,"missed_call_view")}}>
                                    <div className=" d-flex align-self-center">
                                    <FaPhoneAlt color="white" size={14} />

                                    </div>
                                  </div>
                                  <p className="m-0 small_fontSize text-gray secondary_text_width"> Call Back </p>
                                  </div>

                                </div>

                                
                                </div>
                            </div>

                            <div className="d-flex justify-content-center col-12 my-2 mb-3">
                            <p className="m-0  bg_light_gray text-dark px-2 py-2 xs_font "><IoIosInformationCircle color="black"  className="mr-1" size={18} />
                            This list will be disappear after 30 mins
                            </p>
                            </div>
                        </div>
                    )})
                    }
                    </div>
                </div> 
                }

                {/* { ((callData && JSON.parse(callData.outgoingCall)) ) && */}
                {datas.outgoingCall &&
                
                 <div className="incoming_call_log py-2" >

                {datas.isCallInProcess && (datas.callStatus != "bridged")  &&
                 <p className="m-0 text-secondary text-center sizem secondary_text_width"> <span className="call_in_icon"><SlCallIn size={"12"} /></span>  <span className="mx-2 ">Calling ... </span> </p>
                }

                 {datas.callStatus == "bridged" &&
                       <div className={`d-flex justify-content-between mx-2`}>
                       <p className="m-0 text-secondary"> <b> In Call <HiOutlineSignal className="pl-2" size={22} color="#02D130" />
                       </b> </p>

                       <p className="m-0 text-secondary  text-center"> <b><CallTimer /></b></p>
                     </div>    
                 } 



                 <div className="d-flex justify-content-center  ">
                     <div className=" ">
                         <IoPersonCircleSharp color="#4f5167" size={100} />
                     </div>
                 </div>
                 <p className="mt-3 mb-1 text-dark text-center size_medium secondary_text_width ">  <b>{(datas.cust_data && datas.cust_data.cust_name) ? datas.cust_data.cust_name :  datas.rmDetails ? datas.rmDetails.rm_details.rm_name:"Unknown Customer"} </b></p>
                 
                 <p className="mb-0  text-secondary text-center size_medium secondary_text_width ">  {(datas.cust_data &&datas.cust_data.mobile_num )? datas.cust_data.mobile_num :  datas.callNum ?  datas.callNum :'-' } </p>

                 <div className="incoming_buttons d-flex align-items-center my-4 justify-content-center px-4">

                     <div className="bg-red call_btn_bg pointer " onClick={()=>handleCancelCall()}>
                         <MdOutlineCallEnd size={25} color="white"/>

                     </div>

                 </div>
                
                {datas.isCallInProcess && (datas.callStatus != "bridged") &&
                 <div className="d-flex justify-content-center align-items-center col-12 px-0">
                 <p className="m-0  bg_light_gray xs_font text-dark px-3 py-2" ><IoIosInformationCircle color="black"  className="mr-1" size={18} />
                 You can log the details during the call         
                </p>
                 </div>
                }


                 {datas.isCallInProcess &&  datas.screen_from != "churn_management" && datas.screen_from != "delinquency" && datas.screen_from != "pending_rm_visits" && datas.screen_from != "lead_num_verify" &&<div className="d-flex justify-content-center align-items-center cs_log_call_bttn col-12 my-2 mt-4">
                    <button type="button" class="btn btn-primary " disabled={datas.CallLogModal || datas.callStatus != "bridged" } onClick={()=>close_modal()}> <b>log call</b></button>
                 </div>}

                 </div>
               }

                  


            </div>





        </div>
}
        </>
        )
}

export default SoftphoneCallSections;