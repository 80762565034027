import { Box, useTheme } from "@mui/material";
import { SubTitleVarientOneTypo } from "../../typography";

const ErrorUI = ({ errorText }: { errorText: string }) => {
  // hook
  const theme = useTheme();
  return (
    <Box
      sx={{
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.custom_colors.primary_800,
        p: 2,
        borderRadius: 3,
      }}
    >
      <SubTitleVarientOneTypo
        text={errorText}
        overRideStyles={{
          color: theme.palette.text.primary,
          textTransform: "capitalize",
          fontFamily: "Poppins",
          fontWeight: 500,
          textAlign: "center",
        }}
      />
    </Box>
  );
};

export default ErrorUI;
