import {  Navigate, useParams } from 'react-router-dom';
import React from 'react';
import {createMarket, viewMarket} from '../../../actions/market_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import Market from '../component/Market';
import AddressInfo from '../../common/component/AddressInfo';
import Organisation from '../../common/component/Organisation';
import withRouter from '../../../Router/Withrouter';
import Person from '../../common/component/Person';
import '../../../styles/flow.css';


class MarketContainer extends FlowContainer {

  state = {
    toList: false,
    org:{},
    reg_address: {},
    head_person : {},
    market: {country_code: this.country_code, currency_code: this.currency_code}
   
   
  }
  
  componentWillMount() {

   // alert(this.props.match.params);
  //var market = {};
    if(this.props.mode === 'view'){
      const { country_code } = this.props.params;

      
      //alert(JSON.stringify(country_code));
      viewMarket(this.req({country_code}))
        .then((response) => {
          if(!response){return };
            this.market = response.data;
            
            this.setState({market: this.market,
                            org: this.market.org,
                            head_person: this.market.head_person,
                            reg_address: this.market.org.reg_address
                           });
          }
        );
    }else if(this.props.mode  === 'create'){
    
     
    }
  } 


  handleSubmit = (event)=>{
    event.preventDefault();
    
    var market = this.state.market;
    var org = this.state.org;
    org.reg_address = this.state.reg_address;
    org.country_code = this.country_code;
    market.org = org;
    market.head_person = this.state.head_person;
    market.head_person.country_code = this.country_code;
    createMarket(this.req({market:market}))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){

              //alert(response.data);
              
              this.setState({new_country_code: response.data});
              this.setState({toList: true});
              alert("New Market created successfully");
              
            }else{
             alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }

 
  render(){
    if (this.state.toList === true) { 
      
      return <Navigate to={`/market/view/${this.state.new_country_code}`}/>
    }
   
  return ( 
 
      <div className="container text-labelblue mt-5">
         <h4>{this.props.title + (this.state.market.name? " : " + this.state.market.name : "")}</h4> 
        <form id="market_form" onSubmit={this.handleSubmit}>
            <div id="">
            <Market id="market" title="Market Details" editable="false" data={this.state.market} mode={this.props.mode}
                    onComponentChange={this.handleComponentChange}/>
            <br/>
            <hr/>
            
            <br />

            <Organisation id="org" title="Organisation Details" entity="market" data={this.state.org} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <br/>
            <hr/>
            <AddressInfo id="reg_address" title="Registered Address" entity="market" data={this.state.reg_address} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}
                    onComponentReset={this.handleComponentReset}/>

            <br/>
            <hr/>
          
            <Person id="head_person" title="Market Head" entity="market" data={this.state.head_person} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <br/>

            <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create New Market"/>
          </div>

        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default  withRouter(MarketContainer);
