import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {repeatFloatAdvance} from '../../../actions/loan_actions';
import {Alert, Row, Col, Badge} from 'react-bootstrap';
import '../../../styles/flow.css';
import * as moment from "moment";
import { FcInfo } from "react-icons/fc";
import {Link} from 'react-router-dom';
import {dd_value} from '../../../actions/common_actions';
import DisplayImage from '../../common/component/DisplayImage';
import  {variant_color, format_date, title_case, get_dir_path, getValueFromLangJson, lbl_amt, format_date_tbl} from '../../../helpers/common_helper';
import {person_name, calc_percent} from '../../../helpers/common_helper';
import { Modal , Button} from 'react-bootstrap';
import AccountContainer from '../../account/container/account_container';
import CustomerStatus from '../../common/component/core/CustomerStatus';
import  {lbl_status, style_status, addr_txt,capitalize_first_letter} from '../../../helpers/common_helper';
import {bring_to_probation, closeProfile, allowManualCapture, initiateRekyc, createOverdueCustpdf, flagging_profile,addWatchLIst,profileUnFlagged, sendReferralSMS, get_cust_switch_details, profileEditHistory, createCustFilePdf} from '../../../actions/borrower_actions';


import {submitCheckin,allowForceCheckin} from '../../../actions/field_visit_actions';
import {touch} from '../../../actions/common_actions';
import {ImLocation2} from "react-icons/im";
import {FaAngleDoubleDown} from "react-icons/fa";
import {FiRepeat} from "react-icons/fi";
import {GiGrowth} from "react-icons/gi";
import {GrView} from "react-icons/gi";
import {get} from '../../../helpers/storage_helper';
import  {check_priv} from '../../../helpers/storage_helper';
import Agreement from './Agreement';
import { event } from 'jquery';
import {OverlayTrigger,Tooltip } from 'react-bootstrap';
import GoogleMapSetup from '../../../helpers/googleMap';
import Toggle from '../../common/component/Toggle';
import {allowCustAppAccess} from '../../../actions/borrower_actions';
import{view_gps, updateShopType} from '../../../actions/borrower_actions';
import {FaMobileAlt} from "react-icons/fa";
import {Config} from '../../../helpers/storage_helper';
import AccPurposeMultiSelect from '../../../helpers/accPurposemulti';
import { updateUnderWatch } from '../../../actions/lead_actions';

import {  TiDocument} from "react-icons/ti";
import { GoVerified } from "react-icons/go";
import { IoIosArrowDown,IoIosArrowUp } from "react-icons/io";
import { RoundOffSplitter} from '../../../helpers/common_helper';
import { default as ReactSelect } from "react-select";
import ConfirmModal from '../../common/component/ConfirmationModal';
import WatchlistProfile from './WatchlistProfile';
import { FaTrashAlt,FaFlag } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";
import { BsPersonFillAdd } from "react-icons/bs";
import Select from "react-select";
import CustomerProfileHistory from './CustomerProfileHistory';
import PDFtoImage from '../../lead/component/Pdf_to_image';
import withRouter from '../../../Router/Withrouter';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';


class BorrowerPanel extends FlowComponent {

state = {server_time : {}, 
		checkin_req :{},
		pointer_evt : '',
        mapShow:false, 
        cust_app_access : false,
        show_force_checkin_btn : "disable_div",
        gps : ""     ,
        rekyc_modal : false,
        rekyc: {} ,
        shop_type_model: false,
        shop_type:"",
        show_input_shop_type : false,
        custom_shop_type:"",
        shop:"",
        under_watch_reason:[],
        showDropdown:false,
        switch_details : {},
        currency_code : get('market').currency_code,
        show_edit_history : false
	}

	componentWillReceiveProps(props){
        
        if(props.customer.profile_status == 'closed'){
			this.setState({ pointer_evt: 'pointer_evt'});
		}
        if(props.customer.show_force_checkin_btn){
			this.setState({ show_force_checkin_btn: ""});

        }
        this.setState({cust_app_access: props.app_access})
        if(props.customer.under_watch==1){
            this.setState({flag : true});
            if (props.customer.under_watch_reason) {
                this.setState({ under_watch_reason: props.customer.under_watch_reason });
            }
            else{
                this.setState({under_watch_reason:[]})
            }
        }
        else{
            this.setState({flag : false});
        }

        // get_cust_switch_details(this.req({cust_id: props.customer.cust_id}))
        // .then((response) => {
        //     console.log(response)
        //     if(!response){return};
        //     if(response.status === 'success'){
        //         this.setState({switch_details :response.data })
        //     }
        // })
        
	}

    componentDidUpdate(prevProps)
    {
        if(prevProps && prevProps.customer && prevProps.customer != this.props.customer){
            get_cust_switch_details(this.req({cust_id: this.props.customer.cust_id}))
            .then((response) => {
                this.resp(response)
                if(!response){return};
                if(response.status === 'success'){
                    this.setState({switch_details :response.data })
                }
            })
            }
    }


	handleViewAccountsClick = (event,acc_number,person_name) => {
    this.setState({cust_id: event.target.id});
    this.acc_number =  acc_number;
    this.person_name = person_name;
    this.setState({modalBox1: "true"});   
  }

    handleViewgps = () => { 
        this.setState({mapShow : true});
        view_gps(this.req({cust_id: this.props.customer.cust_id}))
        .then((response) => {
            this.resp(response)
            if(!response){return};
            if(response.status === 'success'){
                if(response.data == false){
                    this.setState({mapShow: false}, () => {
                        alert("Unable to get customer current location.Please try again");
                    });    
                }else{
                    this.setState({gps : response.data});
                }
            }
        }) 
    }

    updateBorrowerShopType = () => {
        const resp = window.confirm("Are you sure you want to update the shop type for this customer?")
        if(resp === false){
            return;
        }else{
            let req = {cust_id :this.props.customer.cust_id, shop_type: this.state.shop_type.data_code}
            if( this.state.shop_type.data_code == 'others'){
                req.custom_shop_type = this.state.custom_shop_type;
            }
            
            updateShopType(this.req(req))
            .then((response)=>{
                this.resp(response)
                if(!response){return};
                if(response.status === 'success'){
                    alert(response.message)
                    this.setState({shop_type_model:false, shop:{shop_type:this.state.shop_type.data_value, custom_type : this.state.custom_shop_type}})
                }
            })
        }
    }

  	handleModalClose = (event) => {
      this.setState({ modalBox1: false,
      					modalBox2: false ,mapShow : false});
  } 

  handleToggleBtn = (toggle_val) =>{
    if(this.state.cust_app_access !== toggle_val){
        this.setState({'cust_app_access' : toggle_val},()=>this.handleAppAccess())
        
    }
}

    handleAppAccess = () => {
        let sts = "disabled"
        if(this.state.cust_app_access){
            sts = "enabled"
        }
        allowCustAppAccess(this.req({cust_id: this.props.customer.cust_id,status:sts,
        cust_app_access: this.state.cust_app_access}))
        .then((response) => 
        {
            this.resp(response)
            if(!response){return};
            if(response.status === "success")
            {
                alert(response.message)
            }
        })
    }

  handleCheckIn = (event) =>{
  	
  	const resp = window.confirm("Are you sure to Check-In?")
  	if(resp === false){
			return;
		}else{

  		var checkin_req = this.state.checkin_req
  		checkin_req.cust_id = this.props.customer.cust_id
  		submitCheckin(this.req({checkin_req}))
    	         .then((response) => {
                     if(!response){return };
                 if(response.status === "success"){
                 	const  visit = response.data
                 	var resp = true;
                     if(visit.action == "lastvisit"){
                     	resp = window.confirm(visit.message);
                     }
                     if(resp && visit.visit_id){
                     	window.open(`/checkout/${visit.cust_id}/${visit.visit_id}`,"_blank")
					 }
                     
                 }
                 else{
                   alert(response.message  +"\n" + JSON.stringify(response.data));  
                 }
            })
		}

  	

  	
  	/*
  	

  	
  	this.setState({modalBox2: "true"});
  	const request = {"cust_id" : this.props.customer.cust_id};
		  this.setState({cust_id: this.props.customer.cust_id});

  	touch(this.req(request))
		       .then((response) => {
		       	
		       })*/

  }

  handleForceCheckIn = (event) =>{
  	
    const resp = window.confirm("Are you sure to allow Force Check-In?")
    if(resp === false){
          return;
    }else{
        const cust_id = this.props.customer.cust_id
        allowForceCheckin(this.req({cust_id}))
               .then((response) => {
                   if(!response){return };
               if(response.status === "success"){
                alert(response.message);  
               }else{
                 alert(response.message);  
               }
               window.location.reload();

          })
      }

}

handleModalClose = (event) => {
    this.setState({
        modalBox1: false,
        modalBox2: false, mapShow: false
    });
}
getMultiresons = (data)=>{
    var arr=[]
    var labels = []
    let selectedOptions = data.map((item) => {
        arr.push(item.value)
        labels.push(item.label)
       });
    
    this.setState({flag_reason:arr, flag_labels : labels})
}

sendReferralSMSToCustomer = () => {
    let message = ""
    if(this.props.already_referral_sms_send == true){
        message = "We have already sent the referral SMS to this customer. Would you like to send it again?"
    }else{
        message = "Would you like to send the customer app referral message to this customer?"
    }

    const response = window.confirm(message)
    if(response == true){
        sendReferralSMS(this.req({cust_id: this.props.customer.cust_id}))
                .then((response) => {
                    this.resp(response)
                    if(!response){return};
                    if(response.status == 'success'){
                        alert(response.message);
                    }else{
                        alert(response.message)
                    }
                    window.location.reload();
                })
    }
}


handleManualCapture = (event) =>{
  	
    const resp = window.confirm("Are you sure to allow manual capture?")
    if(resp === false){
          return;
    }else{
        const cust_id = this.props.customer.cust_id
        allowManualCapture(this.req({cust_id}))
               .then((response) => {
                this.resp(response)
                   if(!response){return };
               if(response.status === "success"){
                alert(response.message);  
               }else{
                 alert(response.message);  
               }
               window.location.reload();

          })
      }

}

handleShoptype = (val) => {
    this.setState({shop_type:val})
    if(val.data_code == "others"){
        this.setState({show_input_shop_type:true})
    }else{
        this.setState({show_input_shop_type:false})
    }
}

handleInputShopType = (val) => {
    if(val.length > 20){
        this.setState({custom_shop_type:"exceed"})
    }else{
        this.setState({custom_shop_type:val})
    }
}

RekycModal = () => {
    
    return(
        <Modal show={this.state.rekyc_modal} onHide={this.handleRekycModalClose} size="m">
            <Modal.Header closeButton show={this.state.rekyc_modal} onHide={this.handleRekycModalClose}>
                <Modal.Title>{(this.state.modal_type=="rekyc")? "ReKYC":"FLAG"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            {this.state.loader?
                <div className='my-5'>
                    <p className='text-light  text-center' >Loading...</p>
                    <div className="product_takeup_loader"></div>
                </div>
                :     
                <>
                <div className=" d-flex align-items-center  mx-0 mb-2 ">
                    <label for=""  className="col-sm-5 font-weight-bold label__name pl-3 no-margin"> ReKYC Reason <font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>

                        {(this.state.modal_type=="flag") ? 
                          <Select onChange={(e) =>{this.getMultiresons(e)}}
                          value = {this.state.select_value}
                          options= {this.getMultiSelectOptions('rnc_flag_reason',true) }
                          maxMenuHeight={120}
                          placeholder={"Reason"}
                          styles={this.select_custom_styles()}
                          classNamePrefix="my-select" // Add a custom class prefix to target the components
                          isMulti/>
                          :
                        <select id="reason" onChange={this.handlereasonChange}  className="form-control" type="text" >
                            {this.getSelectOptions("rekyc_reason",true,null,null,true)}
                        </select>

    }

                        </div>
                        <div className="control-me"></div>
                    </div>
                </div>
                <div className=" d-flex align-items-center row no-margin">
                    <label for="" className="col-sm-5 font-weight-bold label__name  no-margin  ">Remarks
                    {/* <font className="text-danger">   *  </font> */}
                    </label>
                    <div className="col-sm-7 reset-padding">
                        <div>
                            <textarea type="text" id="remarks" className="form-control" onChange={this.handlereasonChange}  maxLength='256'/>
                        </div>
                    </div>
                </div> 
                </>
                }

            </Modal.Body>
            <Modal.Footer>
                {!this.state.loader&&
                <div className={(this.state.modal_type == "flag") ? ((this.state.flag_reason && this.state.rekyc.remarks) ? "" : "disable_div") : ((this.state.rekyc.remarks && this.state.rekyc.reason )? "":"disable_div" )}>
                <Button variant="primary"   disabled={this.state.calling_api } onClick={this.handleRekyc}>Submit</Button>
                </div> 
             }  </Modal.Footer>
        </Modal>
    )
}

Change_shop_type = () => {
    return(
        <Modal show={this.state.shop_type_model} onHide={()=>this.handleShoptypeModel(false)} size="m">
            <Modal.Header closeButton show={this.state.shop_type_model} onHide={()=>this.handleShoptypeModel(false)}>
                <Modal.Title>Update Shop Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <p style={{color:"white"}}>Shop Type<span style={{fontSize:"16px", color:'white', fontWeight:"600", marginLeft:"10px"}}>{dd_value(this.props.customer.old_biz_addr_prop_type)}</span><span style={{fontSize:"11px", fontWeight:"600", color:"white"}}>{" (outdated)"}</span></p><br/>
                    <DisplayImage className="img-fluid" image_path={this.props.customer.photo_shop_path} file_name={this.props.customer.photo_shop} style={{width:"100%", height:"260px"}}></DisplayImage>
                    <ReactSelect
                        options={this.getSelectOptions("biz_addr_prop_type", null, null, "shop_type")}
                        value={this.state.shop_type}
                        onChange={(value) => this.handleShoptype(value)}
                        getOptionLabel={(option) => option.data_value}
                        styles={this.style_for_react_select()}
                        placeholder='Choose'
                    />
                    {this.state.show_input_shop_type ?
                    <div className='mt-3'>
                        <input type="text" placeholder='Enter shop type' onChange={(e)=>this.handleInputShopType(e.target.value)}  id="shop_type" className="form-control"   />
                        {this.state.custom_shop_type == 'exceed' ? <span style={{color:"red", fontWeight:"600", fontSize:"10px"}}>Maximum 20 characters</span> : null}
                    </div> : null
                    }
                    <div style={{marginTop:"15px", display: "flex", justifyContent: "center"}}>
                        <Button variant="primary" disabled={this.state.shop_type == "" ? true : (this.state.shop_type.data_code == "others" && (this.state.custom_shop_type == "" || this.state.custom_shop_type == "exceed") ? true : false)} onClick={()=>this.updateBorrowerShopType()}>Submit</Button>
                    </div>
                </div> 
            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
        </Modal>
    )
}

handleShoptypeModel = (value) => {
    this.setState({shop_type_model:value})
    if(value == false){
        this.setState({shop_type:"", show_input_shop_type:false})
    }
}

    handlereasonChange = (event) => {
        var rekyc = this.state.rekyc
        rekyc[event.target.id] = event.target.value

        this.setState({rekyc})
    }

    initiateRekyc = (type) => {
        this.setState({rekyc_modal:true,modal_type:type})
    }

    handleRekycModalClose = () => {       
        this.setState({rekyc_modal:false, rekyc: {}})
    }

    handleRekyc = (event) =>{
        let if_condition = (this.state.modal_type=="flag") ? (this.state.flag_reason && this.state.flag_reason.length>0) :this.state.rekyc.reason
        if(!if_condition ){
            alert("Please select reason")
            return
        }

        var req={
            id:this.props.customer.id,
            cust_id: this.props.customer.cust_id,
            acc_prvdr_code:this.props.customer.acc_prvdr_code,

            remarks: this.state.rekyc.remarks,
            flagged_by:get('user_id'), 
            acc_purpose: this.props.customer.acc_purpose ? [this.props.customer.acc_purpose] :null,
            kyc_reason : (this.state.modal_type=="flag")? this.state.flag_reason :this.state.rekyc.reason , 
            kyc_reason_labels : (this.state.modal_type=="flag")? this.state.flag_labels : []
          
        }

        if(this.state.modal_type=="flag"){
            req['is_flagged'] = true
            // req['lead_id'] =this.props.customer.lead_id
        }
        

        const resp = window.confirm("This action will initiate the rekyc. Are you sure you want to continue?")
        if(resp){
        this.setState({loader:true})
        flagging_profile(this.req(req))
        .then((response) => {
            this.resp(response)
            
            if(!response){
                this.setState({loader:false,rekyc:{},flag_reason:''})
            return 
            };

            if(response.status == 'success'){
                
                this.handleRekycModalClose()
                this.props.view_borrower()
                this.setState({loader:false})
                alert((this.state.modal_type=="flag")? "The profile has been flagged now and Rekyc has been initiated successfully":"Rekyc has been initiated successfully");  
            }

        })
        }
      
            
    }
  

  handleSubmit = (event) =>{
  	
  	var checkin_req = this.state.checkin_req
  	checkin_req.cust_id = this.props.customer.cust_id
  	checkin_req.visit_start_time = this.state.server_time.server_time_db
 
  	submitCheckin(this.req({checkin_req}))
		        .then((response) => {
 			      	if(!response){return };
		            if(response.status === "success"){
		            	alert(response.message)
		            	this.setState({modalBox2 : false})
		              
		            }
		            else{
		              alert(response.message + "\n" + JSON.stringify(response.data));  
		            }
		        })
  }

  handleCheckinChange = (event) =>{
  	var checkin_req = this.state.checkin_req
  	checkin_req[event.target.id] = event.target.value
  	 // this.setState({checkin_req[event.target.id] : event.target.value});
  	this.setState({checkin_req});

  }

  	bring_to_probation = (event) => {
		// var cond_msg = this.props.customer.cond_count == 0 
		// ? "This is the customer's first condonation. He can be condonated only 2 times." 
		// : (this.props.customer.cond_count == 1) 
		// 	? "This is the customer's last condonation. If the customer still do not perform well, He can no longer be associated with Flow"
		// 	: false;
		// if(cond_msg == false){
		// 	alert("Condonation count is exhausted for this customer.");
		// 	return;
		// }
		
	  	// const resp = window.confirm("Allowing condonation reset all the past performance of the customer and customer will be able to apply for an FA again only after 7 days.\n\n"+cond_msg+"\n\nAre you sure to continue?");
		// if(resp === false){
		// 	return;
		// }
		  
		  const request = {"cust_id" : this.props.customer.cust_id};

		  bring_to_probation(this.req(request,true))
		       .then((response) => {
				this.resp(response);
		        if(!response){return };
		            if(response.status === "success"){

		              window.location.reload();
		            }
		            else{
		              alert(response.message + "\n" + JSON.stringify(response.data));  
		            }
		          }
		        ); 
	      //this.setState({credit_score_factor_id: event.target.id, toView: true});
	   } 
	   
	repeatFloatAdvance = (event) => {

		const request = {"cust_id" : this.props.customer.cust_id};
		repeatFloatAdvance(this.req(request))
			.then((response) => {
				if(!response){return };
					if(response){ 

						alert("Float Advance Application created successfully")
						window.location.reload();
					}	
			});
	}

    handleunderwatch=()=>{
        var check=false;
        if(!this.state.flag){
            if(this.state.under_watch_reason.length==0){
                alert('Please Select Reason')
                return;
            }
           check= window.confirm('Are you sure to add this profile to watchlist ?') 
        }
        else{
           check= window.confirm('Are you sure, this action will remove the profile from watchlist ?')
        }
        if(check){
            var request={
                cust_id:this.props.customer.cust_id,
                id:this.props.customer.lead_id,
                under_watch:!this.state.flag,
                under_watch_reason:this.state.under_watch_reason
            }
            updateUnderWatch((this.req(request)))
            .then((response)=>{
                this.resp(response)
                if(!response){
                };
                if(response && response.status=='success'){
                    
                    if(!this.state.flag&&response.data==0){
                        alert('Failed to add to watchlist')
                    }
                    else if (this.state.flag&&response.data==0){
                        alert('Failed to remove from watchlist')
                    }
                    else if(!this.state.flag&&response.data==1 ){
                        alert('Successfully added to watchlist')
                        this.setState({ flag: !this.state.flag })
                    }
                    else if(this.state.flag&&response.data==1){
                        alert('Successfully removed from watchlist')
                        this.setState({ flag: !this.state.flag })
                        this.setState({under_watch_reason:[]})
                    }
                    
                }
            })
        }
        else{
            return
        }
    }

	handleSignAgreement = (event) => {
		var resp = true;
		if(this.props.customer.current_aggr_doc_id){
			resp = window.confirm("An agreement already exists for this customer. Do you want to inactivate it and generate a new agreement? ")
		}
		
		if(resp){
			window.open(`/agreement/upload/${this.props.customer.cust_id}`, "_blank")
		}	
	}

	close_profile = (event) => {
		const resp = window.confirm("Are you sure to close this profile?");
		if(resp === false){
			return;
		}
		const request = {"cust_id" : this.props.customer.cust_id};
		  
		closeProfile(this.req(request))
			.then((response) => {
			this.resp(response);
			if(!response){return };
				if(response.status === "success"){

					window.location.reload();
				}
				else{
					alert(response.message + "\n" + JSON.stringify(response.data));  
				}
				}
			); 
	} 
    togglePrev(key_val,key){
        var value=false
        if(this.state[key]){
          this.setState({[key]: !this.state[key] })
        }else{
          this.setState({[key]: true })

        }
      }
   
    create_overdue_cust_pdf = () => {

        const request = {"cust_id" : this.props.customer.cust_id};

        createOverdueCustpdf(this.req(request))
        .then(response => {
            this.resp(response)
            if(!response){return };
            if(response.data){
                const linkSource = `data:application/pdf;base64,${response.data}`;
                const downloadLink = document.createElement('a');
                const fileName = 'document.pdf';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
            else{
                alert("Failed to fetch data")
            }
        });

    }

    create_cust_file = () => {

        const request = {"cust_id" : this.props.customer.cust_id};

        createCustFilePdf(this.req(request))
        .then(response => {
            this.resp(response)
            if(!response){return };
            if(response.data){
                const linkSource = `data:application/pdf;base64,${response.data}`;
                const downloadLink = document.createElement('a');
                const fileName = this.props.customer.cust_id+'.pdf';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
            else{
                alert("Failed to fetch data")
            }
        });

    }
    
    FlagInHandler =()=>{
        this.setState({rekyc_modal:true})
    }
    // handleShow(val){		
	// 	const splited = val.split(",")		
	// 	const floatedlat = parseFloat(splited[0])
	// 	const floatedlng = parseFloat(splited[1])		
	// 	if(!isNaN(floatedlng)){
	// 		this.setState(state=>state.mapShow=true)            
	// 		this.setState(state=>state.lng=floatedlng)
	// 		this.setState(state=>state.lat=floatedlat)
	// 	}else{
	// 		return
	// 	}
	// }
    // handleClose(){
	// 	this.setState(state=>state.mapShow=false)
	// }
    
    handleOptionChange = (event) => {
    const { value } = event.target
    const { under_watch_reason } = this.state;
    if(value==[]){
        this.setState({ under_watch_reason: [] });
        return;
    }
        if (under_watch_reason.includes(value)) {
        const updatedOptions = this.state.under_watch_reason.filter(option => option != value);
        this.setState({ under_watch_reason: updatedOptions });
        } else {
        this.setState({under_watch_reason:  value});
        }
    };

    collected_documents_lists(data) {

        var collected_documents =
          [
            { "label": <span>Self <br/> Declaration</span>, "check_box_key": "Self Declaration", "key": "self_declaration", "addl_key": "photo_self_declaration", "full_path": "photo_self_declaration_file_path" },
            { "label": "Third Party National ID", "check_box_key": "Third Party National ID", "key": "tp_national_id", "addl_key": "photo_id_proof", "full_path": "photo_id_proof_path" },
            { "label": <span>Consent <br/> Letter</span>, "check_box_key": "Consent Letter", "key": "consent_letter", "addl_key": "photo_consent_letter", "full_path": "photo_consent_letter_file_path" },
            { "label":  <span>Sales <br/> Agreement</span>, "check_box_key": "Sales Agreement ", "key": "sales_agreement", "addl_key": "photo_sales_agreement", "full_path": "photo_sales_agreement_file_path" },
          ]
    
    
        if(data.acc_ownership){
        if (data.acc_ownership == "owned") {
    
          collected_documents.splice(1);
    
        } else if (data.acc_ownership == "bought") {
    
          collected_documents.splice(2, 1);
        }
        else if (data.acc_ownership == "rented") {
    
          collected_documents.splice(3, 1);
    
        }

      
        return(
            <div className='mb-3 row mx-0 py-3 d-flex align-items-center'>
                {collected_documents && collected_documents.map((item, idx) => {
              return (
                <>
                {(data[item.addl_key]!= null) &&

                <div className='col-sm-6 col-lg-2 col-md-2 doc_list_div'>
                    <div>
                    {(data[item.addl_key] && data[item.addl_key]!= null) &&
                    <p className={`m-0   text-center   text-white text-width ${(item.label).length >=20 ? "mb-1":(!data.addl_documents)?"mb-1": "mb-3 "}`} >{item.key == "tp_national_id" ? <span>Third Party <br/>National ID</span> :item.label}</p>
                    }
                    <div className='img_box_size' >
                        {(item.key == "self_declaration" ) ? 
                        <div style={{background:'#f6f6f6'}} onClick={()=>{window.open(`${this.root}${data[item.full_path]}/${data[item.addl_key]}?#toolbar=0&navpanes=0&page=1`, '_blank')}}>
                            <img src='/img/pdf.png' className='pointer' style={{height: '120px',width:'120px'}}/>
                        </div>
                         :
                        <DisplayImage image_path={get_dir_path(data[item.full_path], data[item.addl_key])} style={{height: '120px',width:'120px'}} file_name={data[item.addl_key]} />
                      }  </div>
                    
                </div>
                </div>}
                    
             </>
                  
               
              )
            })}

            {data.addl_documents &&
            <>
            {Object.entries(data.addl_documents).map(([key, value]) => { 
                return(
                
                <>
           { value != "Not Submitted" &&
            <div className='col-sm-6 col-lg-2 col-md-2 doc_list_div'>
                
                <div>
                <p className={` mx-0 text-center  text-white text-width ${(dd_value(key.replace('photo_',''), "biz_documents")).length >= 40 ?"reduce_top_margin":""} ${(dd_value(key.replace('photo_',''), "biz_documents")).length >= 20 ? " mb-0 text_minHeight": 
                (dd_value(key.replace('photo_',''), "biz_documents")=="Form 20") ? "my-3":"mb-3"}`}>
                    {dd_value(key.replace('photo_',''), "biz_documents")} </p>

                <div  className="img_box_size" >
                <DisplayImage image_path={get_dir_path(data[`${key}_file_path`], value)} style={{height: '120px',width:'120px'}} file_name={value} />

              </div>

              </div>
            </div>
          }
          </>
            )})}
            </>}


            </div>
        )
        this.setState({ collected_audit_documents: collected_documents })

       }
    
      }

       verified_icon(){
        return(
            <p className='m-0'> <GoVerified size={15} color="green" /></p>

        )
    }

    showModal(){
       this.setState({bgBlur:!this.state.bgBlur}) 
    }
    
    unflaggedProfile(){
        const resp = window.confirm("Are you sure, this action will remove this customer from flagging?")
        if(resp){
        var req={
            id:this.props.customer.id,
            flag_id: this.props.customer.flagged_details.id,
            lead_id:this.props.customer.lead_id
        }
        profileUnFlagged(this.req(req))
        .then((response)=>{
            this.resp(response)

            if(!response){return}
            if(response.status=="success"){
                alert(response.message)
                this.props.view_borrower()
            }
        })
    }
    }

    profile_edit_history = () =>{
        const request = {"cust_id" : this.props.customer.cust_id};

        profileEditHistory(this.req(request))
        .then((response)=>{
            this.resp(response)
            if(!response){return}
            if(response.status=="success"){
                let data = response.data
                this.setState({edit_history : data, show_edit_history : true})
                
            }
        })
    }

    handle_history_modal(){
        this.props.setValuesIntoRedux({is_modalOpened:!this.state.profileHistory})
        this.setState({profileHistory:!this.state.profileHistory})

    }

    handle_qualitative_modal(type){
        this.setState({qualitative_assess_pdf_modal: type=="open" ? true :false})
      }
    Pdf_modal(props) {

        return (
          <>
    
            {/* <div  className="w-100 h-100 p-0  " style={{position:'relative'}}  >
              {props.pdf_view_error ?                     
                <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`}  width={'100%'} height={'100%'} allowtransparency='true' onLoad={()=>props.pdf_viewer(`${props.root}${props.modal_title}`)}>  
               
                {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                <p className='text-center'>NO DATA</p>
               </object> 
               :
                <div class="d-flex align-items-center">
                <p className='text-center float_acc_balance_view_bttn' >NO DATA</p>
  
              </div> }
               
            </div>
            <div className={`w-100 h-100 p-0 ${props.device?"":'position_ab'}`} style={{top:0}}  ></div> */}
          
            <Modal show={props.pdfMOdal} onHide={()=>props.handleModalClose("close")} size = 'lg' >
                <Modal.Header closeButton  onHide={()=>props.handleModalClose("close")}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                      <Modal.Body >
                      <div className="modal-body py-0" >
                  <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='true'>
                  {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                  </object>
                  </div>
                  <div className="modal-footer d-flex">
                  <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                    <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={()=>props.handleModalClose("close")}>Close</button>
                    
                  </div>
                      </Modal.Body>
              </Modal>
          </>
        );
      }
      Ismobile = () => {
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        if (isMobileDevice) {
          return true
        }
        else {
          return false
        }
      }

	render(){
		const bring_to_prob_priv = check_priv('customer' , 'bring_to_probation');
        const unflag_condition = (this.props.customer && this.props.customer.flagged_details &&  this.props.customer.flagged_details.status == "enabled" );
        

		return(
            <div className={`${this.state.bgBlur ? "disable_div":""}`}>
            {this.RekycModal()}
            {this.Change_shop_type()}
            {this.state.qualitative_assess_pdf_modal && <div className='m-2 text-light border h-100 w-100' >
                {/* <div style={{height:'100%'}} onClick={()=> {!this.state.pdfMOdal ? this.handleModalClose('open'):null}}> */}
                    <this.Pdf_modal title={"Qualitative Assessment PDF"} root={this.root} device={this.Ismobile()} modal_title={this.props.customer?.cust_eval_pdf_path} pdf_view_error={null} pdf_viewer={(a)=>{null}} pdfMOdal={this.state.qualitative_assess_pdf_modal}  handleModalClose ={(type)=>{this.handle_qualitative_modal(type)}}/>
                {/* </div> */}
            </div>}
			      <div className={`${this.state.pointer_evt} ${this.state.confimation_message ? "disable_div":""}`}>					
                <div className="floatAdvancecontainer floatAdvancecontainer_nopad">
                    {this.props.customer.profile_status == 'closed' &&

                        <div class="closedStamp">
                            <div className="stamp is-nope">Closed</div>
                        </div>
                    }					

                    <section>

                        <div className='d-flex justify-content-between  m-0 mb-3  align-items-center'>
                            <div>
                            <div>
                                <p className="personalDetailstextval no-margin preHeaderpad headerTitle" style={{display:'inline-flex'}}>{this.props.customer.owner_name}</p>
                                <p className="mt-10 headerTitle no-margin preHeaderpad" style={{display:"inline-flex"}}>&nbsp; | {this.props.customer.cust_id}
                                {this.props.customer && this.props.customer.flagged_details  &&

                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{
                                        <div>
                                           <p className='m-0 d-flex align-items-center justify-content-start sizesm'>
                                            { <FaFlag size={20}  className="pr-2" color={`${unflag_condition ? "red":"#606268"}`}/>  }

                                            {!unflag_condition ? "UnFlagged On" :"Flagged on"}  <span className='ml-2 sizesm' style={{fontWeight:'500'}}> { !unflag_condition ? moment(this.props.customer.flagged_details.unflagged_on).format('DD MMM YYYY') :moment(this.props.customer.flagged_details.flagged_on).format('DD MMM YYYY')} </span></p> 

                                            {this.props.customer.status=="enabled" ? 

                                            <p className='mb-0 mt-1  d-flex align-items-center justify-content-start sizesm'><GiSandsOfTime size={20} color="#e9a06e" className='pr-2'/>{this.props.customer.status ? dd_value(this.props.customer.status):""} </p>:
                                            
                                            <>
                                            <p className='mb-0 mt-1  d-flex align-items-center sizesm justify-content-start'><GiSandsOfTime size={20} color="#e9a06e" className='pr-2'/>Disabled </p> 
                                           <p className='mb-0 mt-1  d-flex align-items-center  justify-content-start'><GiSandsOfTime size={20} color="#e9a06e" className='pr-2'/> 
                                           
                                           <span  className='sizesm' style={{fontWeight:'500',marginRight:'5px'}}>{ 
                                          ((moment().diff(moment(this.props.customer.flagged_details.flagged_on).add(60, 'days'), 'days')).toString()).replace("-"," ")
                                          }
                                            </span> days left to be disabled 
                                            
                                            </p> 
                                            </>
                                            }


                                           <p className='mb-0 sizesm  mt-1 d-flex align-items-center justify-content-start'><BsPersonFillAdd size={20} color="blue" className='pr-2'/> {(unflag_condition) ?"Initiated by" :"UnFlagged by" }<span  className='ml-2 sizesm' style={{fontWeight:'500'}}>{ unflag_condition ?this.props.customer.flagged_details.flagged_by : this.props.customer.flagged_details.unflagged_by } </span></p> 

                                        </div>
                                    }</Tooltip>}>
                                    <p className="mx-2 headerTitle my-0 ml-4" style={{display:"inline-flex"}}>
                                        {this.props.customer.flagged_details.status =="enabled" ? 
                                         <img className='flag_profile_img' src='/img/flag.gif' />

                                         : <FaFlag  color='#606268' className='ml-2 mt-1' size={20}/>

                                         }
                                    </p>
                                    </OverlayTrigger>    
                              
                                }


                                {check_priv('customer', 'edit') &&
                                <Link to={"/borrower/indiv/edit/"+this.props.customer.cust_id} target="_blank" className="px-3 fs-6" ><i className=" material-icons flag-cursor">edit</i></Link>}</p>
                                
                                {/* {check_priv('customer', 'profile_edit_history') && 
                                    <a  className="px-3 d-inline pointer" style={{fontSize:"16px",color:"#9c27b0", fontWeight:'500'}} onClick={()=>this.profile_edit_history()}><u>Profile Edit History</u></a>
                                } */}

                                <button className="btn btn-primary mx-2 btn-sm sm_bttn_padding  profile_history_btn" onClick={()=>{this.handle_history_modal()}}> Profile History</button>
                            
                             
                                <Modal className='txn_modal edit_history_model' show={this.state.show_edit_history} onHide={()=>this.setState({show_edit_history : false})} backdrop="static" keyboard={false}>
                                    <Modal.Header className='p-0 shadow' closeButton>
                                        <Modal.Title className=' p-3 shadow'><h4 className=' text-center text-light' style={{fontWeight:'500',fontSize:'20px'}}>Profile Edit History</h4></Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <div className='profile_edit_scroll'>
                                            {this.state.edit_history && this.state.edit_history.length > 0 ?
                                                <>
                                                {this.state.edit_history.slice().reverse().map((val, index)=>{
                                                  
                                                    return (
                                                        <div key={index} className='border border-white px-5 pt-4 my-3' style={{borderRadius:'10px'}}>
                                                            <div className='d-flex flex-wrap justify-content-start align-items-center'>
                                                            {val.edited_sections.map((section, secIndex) => (
                                                                <React.Fragment key={secIndex}>
                                                                    <span className='text-white edit_history pr-2'>
                                                                        {dd_value(section, section)}
                                                                        {secIndex < val.edited_sections.length - 1 && (
                                                                            <span> | </span>
                                                                        )}
                                                                    </span>
                                                                </React.Fragment>
                                                            ))}
                                                                
                                                            </div>

                                                            <div className='py-4'>
                                                                <p className=' text-white sizem edit_field' >Edited Fields</p>
                                                                {val.edited_fields.map((field, fieldIndex) => (
                                                                    <span className='text-white edit_history_subHead pr-2'>
                                                                        {field}
                                                                        {fieldIndex < val.edited_fields.length - 1 && (
                                                                            <span>, </span>
                                                                        )}
                                                                    </span>
                                                                  
                                                                ))}
                                                            </div>
                                                            {val.edited_by && 
                                                                <div className='row py-2'>
                                                                    <div className='col-sm-12 col-md-5'>
                                                                        <p className=' text-white sizem edit_field' >Edited By</p>
                                                                        <p className=' text-white edit_history_subHead' style={{fontSize:'14px'}} >{val.edited_by} {val.edited_role && `(${dd_value(val.edited_role, val.edited_role)})`}</p>
                                                                    </div>
                                                                    <div className='col-sm-12 col-md-6'>
                                                                        <p className=' text-white sizem edit_field' >Edited On</p>
                                                                        <p className=' text-white edit_history_subHead' style={{fontSize:'14px'}} >{val.edited_at ? moment(val.edited_at).format('DD MMM YYYY') : 'NA'}</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {val.verified_by && 
                                                                <div className='row py-2'>
                                                                    <div className='col-sm-12 col-md-5'>
                                                                        <p className=' text-white sizem edit_field' >Verified By</p>
                                                                        <p className=' text-white edit_history_subHead' style={{fontSize:'14px'}} > {val.verified_by} {val.verify_role && `(${dd_value(val.verify_role, val.verify_role)})`}</p>
                                                                    </div>
                                                                    <div className='col-sm-12 col-md-6'>
                                                                        <p className=' text-white sizem edit_field' >Verified On</p>
                                                                        <p className=' text-white edit_history_subHead' style={{fontSize:'14px'}}>{val.verified_at ? moment(val.verified_at).format('DD MMM YYYY') : 'NA'}</p>
                                                                    </div>
                                                                </div>
                                                            }



                                                        </div>
                                                    )
                                                })}
                                                </>
                                                :
                                                <h4 className='text-center text-light p-5 m-5'>No Data Found</h4>
                                            }
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            
                        
                            <div className="d-flex no-margin" style={{paddingBottom:15}}>                                                                
                                <span className='statusStyle'>
                                    <span data-toggle="tooltip" data-placement="top" title="Category">
                                        {dd_value(this.props.customer.category)}
                                    </span>
                                    <span data-toggle="tooltip" data-placement="top" title="FAs to next life cycle">
                                        {'current_cycle_total_fas' in this.props.customer &&                                
                                            <span className='text-white'>({this.props.customer.tot_loans}/ {this.props.customer.current_cycle_total_fas})</span>
                                        }
                                    </span>
                                    <span className='text-white'>&nbsp;|&nbsp;</span>  
                                </span>
                                <span className='statusStyle' data-toggle="tooltip" data-placement="top" title="Total FAs">{this.props.customer.tot_loans} FAs<span className='text-white'>&nbsp;|&nbsp;</span></span>
                                <span className='statusStyle' data-toggle="tooltip" data-placement="top" title="Activity Status">{capitalize_first_letter(this.props.customer.activity_status)}<span className='text-white'>&nbsp;|&nbsp;</span></span>
                                {check_priv('customer' , 'risk_category') &&
                                    <span className='statusStyle' data-toggle="tooltip" data-placement="top" title="Risk Category">{dd_value(this.props.customer.risk_category)}</span>
                                }                                                                                        
                                <span className='statusStyle' data-toggle="tooltip" data-placement="top" title="FA Status"><span className='text-white'>&nbsp;|&nbsp;</span>{dd_value(this.props.customer.fa_status)}<span className='text-white'>&nbsp;|&nbsp;</span></span>

                                {this.props.customer.fa_limit && <span className='statusStyle' data-toggle="tooltip" data-placement="top" title="FA Limit">{this.props.customer.fa_limit} {get('market').currency_code}<span className='text-white'>&nbsp;|&nbsp;</span></span>}
                                <CustomerStatus show_1l="true" id={this.props.customer.id} entity="borrowers"
                                                fa_status={this.props.customer.fa_status} category={this.props.customer.category}
                                                kyc_status={this.props.customer.kyc_status}
                                                cust_id= {this.props.customer.cust_id } 
                                                mobile_num={this.props.owner_person?.mobile_num }/>

                                <div className="px-2">
                                    {check_priv('customer','close') &&
                                    <button type="button" className="btn btn-danger btn-std " disabled={this.state.calling_api} onClick={this.close_profile}>{getValueFromLangJson("close_profile")}</button>
                                    }
                                </div>
                                <div className='ml-2 align-self-center'>
                                    {check_priv('customer', 'download_pdf') &&
                                    <p className='m-0 pointer statusStyle'  onClick={this.create_cust_file} style={{textDecoration:'underline',cursor:'pointer'}}>{getValueFromLangJson("download_customer_file")}</p>
                                    }
                                </div> 
                                <div className='ml-2 align-self-center'>
                                    {check_priv('customer', 'download_pdf') &&
                                    <p className='m-0 pointer statusStyle'  onClick={this.create_overdue_cust_pdf} style={{textDecoration:'underline',cursor:'pointer'}}>{getValueFromLangJson("download_pdf")}</p>
                                    }
                                </div> 
                            </div>
                           </div>

                           <div className='text-white'>
                           <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{
                                        <p className='m-0 '>Customer is {this.props.customer.cust_app_access ? "using":" not using" }  the Customer App </p>
                                    }</Tooltip>}>
                                        <img src={`/img/${this.props.customer.cust_app_access ? 'active':'Inactive'}.svg`} className='mr-2 pointer' alt='active_svg' onClick={this.props.customer.cust_app_access ? '' : ()=>this.sendReferralSMSToCustomer()}/>

                                    </OverlayTrigger>

                           </div>

                     
                        </div>
                    </section>
                    <section>
                        <div className="floatAdvancetitleBox agreementDateHeaderBox py-2">
                            <div className="row no-margin agreementDateHeaderBox_pad verticalAlign">
                                <div>
                                <p className='m-0 floatAdvanceperiodHeader no-margin  text-white padL_15'> Profile Summary</p>
                                {/* <div className="no-padding col-lg-12 d-flex align-items-center text-center">
                                    <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate">Last Visit : </h2>
                                    <h2 className="no-margin agreementDateHeaderBoxdate agreementDateHeaderBoxdateno_pad text-blue">&nbsp;{format_date(this.props.customer.last_visit_date)}</h2>
                                </div> */}
                                </div>
                              
                               
                            
                            </div>
                           
                        </div>
                        <div className=' mx-0 d-flex justify-content-between py-4 verticalAlign loggedPersondetailsBox'>
                        
                            {/* <div className="no-padding col-lg-3 last_visit_dates  ">
                                    <div>
                                    <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate sizesm" >Last Visit : </h2>
                                    <h2 className="ml-4 agreementDateHeaderBoxdate sizesm agreementDateHeaderBoxdateno_pad text-blue">{this.props.customer.last_visit_date?format_date(this.props.customer.last_visit_date):"-"}</h2>                                    

                                    </div>
   
                         
                                </div> */}


                                

                                <div className="no-padding mx-4 text-center">
                                    {check_priv('customer','rekyc') &&

                                        <button className="btn btn-primary btn-sm sm_bttn_padding" onClick={()=>this.initiateRekyc("rekyc")}> <FiRepeat className='mr-2'  size="13"/> ReKYC</button>

                                     }
                                    {/* {check_priv('customer','flag') && */}
{/*                                         
                                        <button className="btn btn-primary btn-sm sm_bttn_padding" onClick={()=>{((this.props.customer && this.props.customer.flagged_details))? this.unflaggedProfile():this.initiateRekyc("flag")}}> <FaFlag className='mr-2'  size="13"/> {(this.props.customer.flagged_details.status=="enabled"  )?"UNFLAG":'FLAG'} </button> 
                                        
                                        */}

                                           
                                        <button className="btn btn-primary btn-sm sm_bttn_padding" onClick={()=>{((unflag_condition))? this.unflaggedProfile():this.initiateRekyc("flag")}}>
                                             <FaFlag className='mr-2'  size="13"/> {unflag_condition?"UNFLAG": 'FLAG' }
                                             
                                              </button>

                                    {/* } */}
                                    {check_priv('customer','watchlist') &&

                                        <button className="btn btn-primary btn-sm sm_bttn_padding" onClick={this.handleCheckIn}><i className="fa fa-map-marker mr-2" aria-hidden="true"></i>{getValueFromLangJson("check_in")}</button>
                                    } 
                                    
                                    <button className="btn btn-primary btn-sm sm_bttn_padding" onClick={this.handleViewAccountsClick}><i className="fa fa-eye mr-2" z></i>{getValueFromLangJson("view_acc")}</button>
                                    {check_priv("lead","re_kyc") &&

                                    <button className="btn btn-primary px-4 btn-sm sm_bttn_padding" onClick={()=>this.initiateRekyc("rekyc")}>Re-kyc</button>

                                    }
{/* 
                                     {check_priv('field_visit','allow_force_checkin') &&
                                        <button className={`btn btn-primary btn-sm sm_bttn_padding mr-2 ${this.state.show_force_checkin_btn}`} disabled={this.state.calling_api}  onClick={this.handleForceCheckIn}>{getValueFromLangJson("allow_force_checkin")}</button>
                                     } */}

                                     {check_priv('account','allow_tp_nid_manual_capture') &&
                                        <button className={`btn btn-primary  btn-sm sm_bttn_padding mr-2 ${this.state.show_force_checkin_btn}`} disabled={this.state.calling_api}  onClick={this.handleManualCapture}>{getValueFromLangJson("allow_tp_nid_manual_capture")}</button>
                                     }
                                     {check_priv('customer','view_gps') && this.state.cust_app_access &&
                                        <button className='btn btn-primary btn-sm sm_bttn_padding mr-2'  disabled={this.state.calling_api} onClick={this.handleViewgps}><i className="fa fa-map-marker"></i>{getValueFromLangJson("view_gps")}</button>
                                     }
                                </div>
                                <div className="pl-0 pr-4  d-flex justify-content-end">
                                    <div className='d-flex'>
                                    {get("role_codes") != "loan_approver" && <>
                                    <Link to={"/field_visit/search/"+this.props.customer.cust_id} target="_blank"><a  className='custVisit_link pl-0'>{getValueFromLangJson("recent_visits")}</a><span className='text-white' style={{fontSize:15}}>&nbsp;|&nbsp;</span></Link>
                                    <Link to={"/call_log/search/"+this.props.customer.cust_id} target="_blank"><a  className='custVisit_link'>{getValueFromLangJson("recent_calls")}</a><span className='text-white' style={{fontSize:15}}>&nbsp;|&nbsp;</span></Link>
                                    </>}
                                    {this.props.customer.ongoing_loan_doc_id != null &&
                                        <Link to={"/fa/view/"+this.props.customer.ongoing_loan_doc_id} target="_blank"><a  className='custVisit_link'>{getValueFromLangJson("view_onging_fa")}</a><span className='text-white' style={{fontSize:15}}>&nbsp;|&nbsp;</span></Link>
                                    }
                                    {this.props.customer.last_loan_doc_id != null &&
                                        <Link to={"/fa/view/"+this.props.customer.last_loan_doc_id} ><a href="" className='text-white'>{getValueFromLangJson("view_last_fa")} |</a></Link>
                                    }
                                    {/* {this.props.customer.last_loan_doc_id != null && */}
                                        <Link to={`/sales/${this.props.customer.cust_id}`} target="_blank" className={`px-2 ${check_priv('customer','close') ? "":"display_block "}`} ><p className='text-white'>  Switch History</p></Link>
                                    {/* } */}
                                    </div>
                              
                                
                                    {/* <Link to={"/report/growth_chart_for/"+this.props.customer.cust_id} ><button className="btn btn-light">Growth</button> </Link> */}
                                    
                                </div>
                        </div>
                        {/* {check_priv("customer","watchlist") && */}
                        <div className='row mx-0 col-12 px-0 py-3 d-flex align-items-center verticalAlign loggedPersondetailsBox'>
                            <WatchlistProfile customer={this.props.customer}  showModal={()=>{this.showModal()}} view_borrower={()=>{this.props.view_borrower()}}/>      
                        </div>
                        {/* } */}
                        <div className="loggedPersondetailsBox mapModal_width">
                            <section>
                            {get('market').country_code == 'UGA' && 
                            <div className='px-3'>
                                <h2 className="ml-3 my-0 text-white agreementDateHeaderBoxdate sizesm" >Switch Transaction </h2>
                                <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate sizesm" >Count</h2>
                                <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate" style={{fontWeight: "600"}} >{this.state.switch_details.count ? this.state.switch_details.count : 0}</h2>
                                <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate sizesm" >|</h2>
                                <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate sizesm" >Amount</h2>
                                <h2 className="ml-4 my-0 text-white agreementDateHeaderBoxdate sizesm" style={{fontWeight: "600"}} >{this.state.switch_details.amount ? <div className='roundoffvalue'><RoundOffSplitter val={this.state.switch_details.amount} unit={this.state.currency_code} /></div>  : lbl_amt(0, this.state.currency_code)}</h2>
                            </div>    
                            }                               
                                <div className="row no-margin">
                                    <div className="col-lg-3">
                                        <p className="no-margin loggedPersondetails loggedPersonid text-center">{this.props.customer.cust_id}</p>
                                            <div className="loggedPersondetailsImg">
                                                <DisplayImage className="img-fluid" image_path={this.props.customer.photo_pps_path} file_name={this.props.customer.photo_pps}></DisplayImage>
                                            </div>
                                        <p className="personalDetailstextval no-margin loggedPersondetails loggedPersonname text-center">{this.props.customer.cust_name}</p>
                                    </div>
                                    <div className="col-lg-3 personalDetailsbox">
                                        <ul className='no-padding'>
                                            {/* <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext personalDetailsviewText no-margin">Name </p>
                                                    </div>
                                                    <div className="col-lg-7 no-padding">
                                                        <p className="personalDetailstextval no-margin">{this.props.customer.biz_name}</p>
                                                    </div>                        
                                                </div>
                                            </li> */}
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin"><i class="fa-solid fa-phone cusVisiticon"></i>{getValueFromLangJson("mobile_no")}</p>                                                    
                                                    <p className="viewCus_labelVal  no-margin">{this.props.owner_person?.mobile_num}</p>                                                    
                                                </div>
                                            </li>
                                            <li>                                                
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin"><i class="fa-solid fa-location-dot cusVisiticon"></i>{getValueFromLangJson("location")}</p>
                                                    <p className="viewCus_labelVal no-margin">{title_case(this.props.customer.location)}</p>
                                                </div>                                                                                                    
                                            </li>
                                            <li>    
                                                <div className="">                                            
                                                    <p className="viewCus_label no-margin"><i class="fa-solid fa-map cusVisiticon"></i>{get('market').country_code != 'MDG' ?'Territory' : getValueFromLangJson("district")}</p>                                                                                                    
                                                    <p className="viewCus_labelVal no-margin">{get('market').country_code !== 'MDG' ? title_case(this.props.customer.territory) : title_case(this.props.customer.district)}</p> 
                                                   
                                                </div>                                                   
                                            </li>                                             
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin"><i class="fa-solid fa-map-pin cusVisiticon"></i>GPS</p>                                                    
                                                    <p className="viewCus_labelVal  no-margin" data-toggle="modal" data-target="#exampleModal" style={{cursor:"pointer"}}>{this.props.customer.gps}</p>                                                    
                                                </div>
                                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">{getValueFromLangJson("map_view")}</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>  
                                                            <div class="modal-body">
                                                                <GoogleMapSetup coordinates={this.props.customer.gps}/>
                                                            </div>                                                                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <Modal show={this.state.mapShow} size="xl" onHide={()=>this.handleClose()}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Map View</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <GoogleMapSetup lat={this.state.lat} lng={this.state.lng}/>								
                                                    </Modal.Body>					
                                                </Modal> */}
                                            </li>                                           
                                            {/* <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">Address</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{this.props.customer.cust_addr_txt}</p>                                                    
                                                </div>
                                            </li>     */}
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 personalDetailsciewIdbox">
                                        <ul className='no-padding'>
                                            {/* <li>
                                                <div className="">                                                   
                                                    <p className="viewCus_label no-margin">{this.props.customer.acc_prvdr_code} Cust Id</p>                                                    
                                                    <p className="viewCus_labelVal  no-margin">{this.props.customer.acc_number}</p>                                                                     
                                                </div>
                                            </li> */}
                                            
                                            {/* <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">Registration Date</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{format_date(this.props.customer.reg_date)}</p>                                                    
                                                </div>
                                            </li> */}
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">Flow RM</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{this.props.customer.flow_rel_mgr_name}</p>                                                    
                                                </div>
                                            </li>
                                            {/* <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">Partner RM</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{this.props.customer.dp_rel_mgr_name}</p>                                                    
                                                </div>
                                            </li> */}
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{getValueFromLangJson('registered_rm')}</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{this.props.customer.reg_flow_rel_mgr_name}</p>                                                    
                                                </div>
                                            </li>
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{getValueFromLangJson("last_audit_date")} & Audited By</p>    
                                                    <p className="viewCus_labelVal no-margin">
                                                        {(!this.props.customer.last_audit_kyc_end_date && !this.props.customer.last_audited_by)
                                                            ? 'NA'
                                                            : `${this.props.customer.last_audit_kyc_end_date ? format_date(this.props.customer.last_audit_kyc_end_date) : ''}${this.props.customer.last_audited_by ? ' & ' + this.props.customer.last_audited_by : ''}`
                                                        }
                                                    </p>                                             
                                                </div>
                                            </li>
                                            {check_priv("customer","edit_shop_type") ? 
                                                <li>
                                                <div className="">                                                    
                                                        <p className="viewCus_label no-margin">{getValueFromLangJson("shop_type")}</p>                                                
                                                        {this.props.customer.biz_addr_prop_type ? 
                                                        <p className="viewCus_labelVal no-margin">{this.props.customer.biz_addr_prop_type == "others" ? dd_value(this.props.customer.biz_addr_prop_type, "biz_addr_prop_type") + " (" + dd_value(this.props.customer.custom_biz_addr_prop_type) + ")" : dd_value(this.props.customer.biz_addr_prop_type, "biz_addr_prop_type")}</p>
                                                        :
                                                        this.state.shop != "" ? <p className="viewCus_labelVal no-margin">{this.state.shop.shop_type == "Others" ? this.state.shop.shop_type + " (" + this.state.shop.custom_type + ")" : this.state.shop.shop_type}</p> :
                                                        <div>
                                                            <p className="viewCus_labelVal no-margin">{dd_value(this.props.customer.old_biz_addr_prop_type)} <span style={{fontSize:"10px"}}>{"(outdated)"}</span>
                                                                <div className='d-inline-block pl-2 borrower_shop_type'>
                                                                <RoundOffSplitter val={"The current shop type is outdated. Please use Update Shop Type link to update the shop type"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                                </div> 
                                                                <span className='borrower_update_shop_type' onClick={()=>this.handleShoptypeModel(true)}>Update Shop Type</span>
                                                            </p>
                                                        </div>
                                                        }
                                                    </div>
                                                </li>
                                                :
                                                <li>
                                                    <div className="">                                                    
                                                        <p className="viewCus_label no-margin">{getValueFromLangJson("last_kyc_date")}</p>                                                
                                                        <p className="viewCus_labelVal no-margin">{this.props.customer.last_kyc_date ? format_date(this.props.customer.last_kyc_date):"-"}</p>                                                    
                                                    </div>
                                                </li>
                                            }
                                            
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 personalDetailsciewIdbox">
                                        <ul className='no-padding'>
                                            {/* <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{this.props.customer.acc_prvdr_code} Cust Id</p>                                                    
                                                    <p className="viewCus_labelVal  no-margin">{this.props.customer.acc_number}</p>                                                    
                                                </div>
                                            </li> */}                                            
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{getValueFromLangJson("registration_date")}</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{format_date(this.props.customer.reg_date)}</p>                                                   
                                                </div>
                                            </li>                                            
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{getValueFromLangJson('last_visit_date')}</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{format_date(this.props.customer.last_visit_date)}</p>                                                    
                                                </div>
                                            </li>
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{getValueFromLangJson('last_rm_kyc_date')}</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{this.props.customer.rm_kyc_end_date?format_date(this.props.customer.rm_kyc_end_date):"NA"}</p>                                                    
                                                </div>
                                            </li>
                                            <li>
                                                <div className="">                                                    
                                                    <p className="viewCus_label no-margin">{getValueFromLangJson('last_assessment_date')}</p>                                                    
                                                    <p className="viewCus_labelVal no-margin">{this.props.customer.last_assessment_date?format_date(this.props.customer.last_assessment_date):"NA"}</p>                                                    
                                                </div>
                                            </li>

                                            
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="row no-margin">
                                    <div className="col-lg-2">
                                        <p className="no-margin loggedPersondetails agreementLoggedpersonImg_title loggedPersonid text-center">CCA-390125</p>
                                        <div className="loggedPersondetailsImg">
                                            <img className="img-fluid" src="images/profile.png"></img>
                                        </div>
                                        <p className="no-margin loggedPersondetails loggedPersonname agreementLoggedpersonImg_title text-center">MUYOMBA ISAAC</p>
                                    </div>
                                    <div className="col-lg-5 no-padding personalDetailsbox">
                                        <ul>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Data Provider Customer ID</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">776156298</p>
                                                    </div>                        
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Business Name</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">Teopista BUWAYA Namatembe</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Establishment Adress Type</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">Hardware Shop</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Ownership</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">Rented</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Business Distance from Home</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">Walkable from home</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Flow Relationship Manager</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">RITAH NABWIRE</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Registered Date</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">04 Jun 2020</p>
                                                    </div> 
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5 no-padding agreementPersonaldetailsBox">
                                        <ul>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Flow Customer IDs</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">CCA-534429</p>
                                                    </div>                        
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Data Provider</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">CHAP CHAP</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Lender</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">FLOW</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Data Provider Relationship Manager</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">hussein ntabazi NTABAZI</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Source Run ID</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">Flow</p>
                                                    </div> 
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row no-margin">
                                                    <div className="no-padding col-lg-5">
                                                        <p className="personalDetailstext agreementPersonalDetailstext no-margin">Business Licence Photo</p>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <p className="personalDetailstextval agreementPersonalDetailstext no-margin">Not uploaded</p>
                                                    </div> 
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                            </section>
                            <section className="agreementPhotobox">
                                <div className="agreementPhotobox_padTop">
                                    <div className="row no-margin text-center">
                                        <div className="col-lg-4 cusVisit_imgCen">
                                            <span className='no-padding viewCus_imglabel'>Selfie</span>
                                            <DisplayImage className="img-fluid" image_path={this.props.customer.photo_selfie_path} file_name={this.props.customer.photo_selfie}></DisplayImage>
                                        </div>
                                        {/* <div className="col-lg-3 no-padding">
                                            <span>Owner Photo</span>
                                            <DisplayImage style={{width: "120px", height: "95px", margin: "0 auto"}} className="img-fluid" image_path={this.props.customer.photo_pps_path} file_name={this.props.customer.photo_pps}></DisplayImage>
                                        </div> */}
                                        <div className="col-lg-4 cusVisit_imgCen">
                                            <span className='no-padding viewCus_imglabel'>{getValueFromLangJson("establishment_photo")}</span>
                                            <DisplayImage className="img-fluid" image_path={this.props.customer.photo_shop_path} file_name={this.props.customer.photo_shop}></DisplayImage>
                                        </div>
                                        <div className="col-lg-4 cusVisit_imgCen">

                                            <span className='no-padding viewCus_imglabel'>
                                                {this.props.owner_person && this.props.owner_person.photo_lc_letter_path ? "Lc Letter Photo" : getValueFromLangJson('biz_license_photo')}
                                              
                                                </span>
                                            {this.props.owner_person && this.props.owner_person.photo_lc_letter_path ? 
                                            <DisplayImage className="img-fluid" image_path={this.props.owner_person.photo_lc_letter_path} file_name={this.props.owner_person.photo_lc_letter}></DisplayImage>
                                            :
                                            <DisplayImage className="img-fluid" image_path={this.props.customer.photo_biz_lic_path} file_name={this.props.customer.photo_biz_lic}></DisplayImage>}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="floatAdvanceperiodHeaderBox">
                                <h2 className="floatAdvanceperiodHeader no-margin padL_15">{getValueFromLangJson('agreement_and_data_consent')}</h2>
                            </div>
                            <section className="agreementSignbtn_Box">
                                
                                <ul className="pad_15 no-margin ul4_count cust_agreementBox">
                                    <li>                                                
                                        <div className="">
                                            <p className="viewCus_label no-margin no-padding">{getValueFromLangJson("agreement_id")} </p>
                                            <p className="viewCus_labelVal no-margin">{this.props.customer.current_aggr_doc_id}</p>                                                                                                                              
                                        </div>
                                    </li>
                                    <li>                                               
                                        <div className="">
                                            <p className="viewCus_label no-margin no-padding">{getValueFromLangJson("status")} </p>
                                            <p className="viewCus_labelVal no-margin text-capitalize">{this.props.customer.aggr_status}</p>
                                        </div>                                                                                                                            
                                    </li>                                                                                                                            
                                    <li>                                       
                                        <div className="">
                                            <p className="viewCus_label no-margin no-padding">{getValueFromLangJson("valid_upto")}</p>
                                            <p className="viewCus_labelVal no-margin">{this.props.customer.aggr_valid_upto}</p>
                                        </div>                                                                                                          
                                    </li>
                                    <li>   
                                        {this.props.customer?.cust_eval_pdf_path && <button className='btn btn-light no-margin' style={{marginBottom: 0 }} 
                                        onClick={() => {
                                            this.handle_qualitative_modal("open")
                                            }}
                                         >
                                            { getValueFromLangJson('qualitative_assessment')}
                                         </button>}                                     
                                        <Link to={"/agreement/list/"+this.props.customer.cust_id} ><button className="btn btn-light no-margin">{getValueFromLangJson('list_customer_agreements')}</button></Link>                                                                                        
                                        {/* <button className="btn btn-light" style={{marginLeft:10,marginBottom:0}} onClick={this.handleSignAgreement}>Sign Agreements</button>   */}
                                        {this.props.customer.data_consent_path &&

                                       <button className="btn btn-light " style={{ marginLeft: 10, marginBottom: 0 }} onClick={() => {
                                         const pdfUrl = `${this.props.customer.data_consent_path}/${this.props.customer.file_data_consent}`;
                                         window.location.href = pdfUrl;
                                       }}
                                       >
                                       {getValueFromLangJson('view_customer_data_consent')}
                                     </button>
                                        }
                                                                                 
                                    </li>                            
                                </ul>                                                                    
                            </section>
                        </div>
                    </section> 
                    {this.props.customer && (this.props.customer.accounts && this.props.customer.accounts.length > 0) && check_priv("customer","kyc_docs") &&
                        <section className='loggedPersondetailsBox'>
                            <div className="floatAdvanceperiodHeaderBox">
                                <h2 className="floatAdvanceperiodHeader no-margin padL_15">{getValueFromLangJson("address_business_documents")}</h2>
                            </div>
                            <div className='px-5'>
                                {this.props.customer.accounts && this.props.customer.accounts.map((items,idx)=>{
                                    return(
                                    <div key={idx}>
                                        {items.acc_ownership != null  && items.photo_self_declaration != null &&
                                            <fieldset className= {`px-3 acc_doc_fieldset my-3 rel_position` }>
                                                <div className='doc_list_position' >
                                                    <div className='d-flex align-items-center py-1 px-3 justify-content-around' style={{border:'1px solid gray', borderRadius : '3px'}} onClick={(e)=>{this.togglePrev(e,`accordian_${idx}`)}}>
                                                        <p className='text-white m-0 py-1 px-3 pointer d-flex align-items-center' > 
                                                            <TiDocument className='doc_icon mr-2' size={20} /> {getValueFromLangJson('document_list')} 
                                                        </p>
                                                        <div className='pointer'>
                                                            {this.state[`accordian_${idx}`] ? 
                                                                < IoIosArrowUp size={20} color='white' />:
                                                                <IoIosArrowDown size={20} color='white' />
                                                            }
                                                        </div>
                                                    </div>
                                                
                                                    {this.state[`accordian_${idx}`] && 
                                                    
                                                    <div className='px-2 py-2 mt-1' style={{background:'#fbfbfb', borderRadius : '3px' }}>

                                                    <div className='d-flex  justify-content-between mb-1  '>
                                                        <p className=' m-0 sizesm'>{getValueFromLangJson('self_declaration')}  <span className='text-danger'> <b>*</b></span></p>
                                                        {items.photo_self_declaration != null ? this.verified_icon() : "" }
                                                    </div>
                                                    {(items.acc_ownership !== "owned")   &&
                                                    <>
                                                        <div className='d-flex  justify-content-between mb-1 '>
                                                            <p className=' m-0 sizesm'>{getValueFromLangJson('thrid_party_national_id')}({getValueFromLangJson('optional')})</p>
                                                            {items.photo_id_proof != null && items.photo_id_proof_path != null ? this.verified_icon() : ""}
                                                        </div>

                                                    {(items.acc_ownership != "bought") &&
                                                        <div className='d-flex  justify-content-between  mb-1'>
                                                            <p className=' m-0  sizesm'>{getValueFromLangJson('consent_letter')}({getValueFromLangJson('optional')}) </p>                                
                                                            {items.photo_consent_letter != null  ? this.verified_icon() : ""}
                                                        </div>
                                                    }
                                                    { (items.acc_ownership != "rented") &&
                                                        <div className='d-flex  justify-content-between mb-1  '>
                                                            <p className=' m-0  sizesm'>{getValueFromLangJson('sales_agreement')}({getValueFromLangJson('optional')}) </p>
                                                            {items.photo_sales_agreement != null ? this.verified_icon() : ""}
                                                        </div>
                                                    }
                                                    </>
                                                    }
                                                    
                                                    {items.addl_documents &&  Object.keys(items.addl_documents).length >0 &&
                                                        <div>
                                                            <p className='m-0' style={{fontWeight:'500'}}>{getValueFromLangJson('business_documents')} ({getValueFromLangJson('at_least_one')}) <b className='text-danger'> *</b> </p>
                                                            {Object.entries(items.addl_documents).map(([key, value]) => { return(

                                                                <div className='d-flex  justify-content-between mb-1  '>
                                                                    <p className=' m-0  sizesm'>{dd_value(key.replace('photo_',''), "biz_documents")} </p>
                                                                    {value != "Not Submitted" ? this.verified_icon():""}

                                                                </div>
                                                            )})}
                                                        </div>
                                                    }

                                                    </div>
                                                    }

                                                </div>

                                                <legend className={`legend_rekyc MODIFIED_text_color text-white d-flex  align-items-center`} style={{color:"#FF4C29",fontSize:'16px'}}>
                                                    <div className='d-flex align-items-center mx-1'>{getValueFromLangJson("account")} - {idx+1}</div> <img className='mx-3' src={`/img/${items.acc_prvdr_code}_logo_color.png`} style={{width:'61px',height:'33px'}}/>  {items.acc_number} </legend> 
                                            
                                                        <div className='row m-0 d-flex justify-content-center col-12' >
                                                            <div className='d-flex justify-content-between col-md-12 col-sm-12 col-lg-6 py-4 px-5 acc_ownership_div my-2 mb-3' >
                                                            
                                                                <p className='my-0 text-white mx-2'>{getValueFromLangJson("account_ownership")}  <b className='sizem ml-4' > {items.acc_ownership ? dd_value(items.acc_ownership,items.acc_ownership ):''} </b>{items.acc_ownership!="owned" ? "(Third Party)":""}</p>
                                                                <p className='my-0 mx-2 text-white'>{getValueFromLangJson("business_type")} <b className='sizem ml-4'>{items.biz_type ? dd_value(items.biz_type,items.biz_type):"" }</b> </p>

                                                            </div>
                                                        </div>

                                                <div className='mt-3'>
                                                    {this.collected_documents_lists(items)}
                                                </div>
                                        
                                            </fieldset>
                                        }
                                    </div>
                                )})}
                            </div>
                        </section> 
                    }         
                </div>
							
                            <Modal show={this.state.modalBox1 } onHide={this.handleModalClose} size="xl">
                    <Modal.Header closeButton className="modal-close-btn" show={this.state.modalBox1 } onHide={this.handleModalClose}>

                                <Modal.Title style={{fontSize:20}}> {getValueFromLangJson("customer_accounts")} :  {this.props.customer.cust_id} </Modal.Title>
                           
						</Modal.Header>

						<Modal.Body>
                          
                            <AccountContainer entity="customer" acc_number={this.acc_number} cust_name={this.person_name} entity_id={this.props.customer.cust_id}/>
                            
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleModalClose}>{getValueFromLangJson("close")}</Button>
                        </Modal.Footer>
					</Modal>


					<Modal show={this.state.modalBox2} onHide={this.handleModalClose} size="l">
						<Modal.Header closeButton show={this.state.modalBox1} onHide={this.handleModalClose}>
							<Modal.Title>Check In</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="container">
							 	<form>
				                	<div className="rule-form-group  form-group row reset-margin">
				                  		<label for="" className="col-sm-5 mb-3 label__name font-weight-bold reset-padding">{getValueFromLangJson('flow_cust_id')}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
						                  		<label className="" id = "cust_id" >{this.props.customer.cust_id} | {this.props.customer.acc_number}</label>
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				          
				                  	</div>
				                  
				                  	<div className="rule-form-group form-group row reset-margin">
				                  		<label for="" className="col-sm-5 mb-3 font-weight-bold  label__name reset-padding">{getValueFromLangJson("address")}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
						                  		<label className="" id = "cust_id">{this.props.customer.cust_addr_txt}</label>
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				          
				                  	</div>
				                  	<div className="rule-form-group form-group row reset-margin">
				                  		<label for="" className="col-sm-5 mb-3 font-weight-bold  label__name reset-padding">{getValueFromLangJson("check_in_at")}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
					                    		<label className="" id = "visit_start_time">{this.state.server_time.server_time_ui}</label>
						                  		
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				                     
				                  	</div>
				                  	<div className="rule-form-group form-group row reset-margin">
				                  		<label for="" className="col-sm-5 font-weight-bold label__name reset-padding">{getValueFromLangJson("time_spent")}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
						                  		<select id = "time_spent"  className = "form-control mb-3" onChange={(e) =>{this.handleCheckinChange(e);}}>
						                  			<option value ="" >{getValueFromLangJson("choose")}</option>
						                  			<option value ="5">5 Minutes</option>
						                  			<option value ="10">10 Minutes</option>
						                  			<option value ="15">15 Minutes</option>
						                  			<option value ="20">20 Minutes</option>
						                  			<option value ="25">25 Minutes</option>
						                  			<option value ="30">30 Minutes</option>
						                  		</select>
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				                     
				                  	</div>
				                  	<div className="rule-form-group form-group row reset-margin ">
				                  		<label for=""  className="col-sm-5 font-weight-bold label__name reset-padding">{getValueFromLangJson("visit_purpose")}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
						                  		<select  id="visit_purpose" className = "form-control mb-3" onChange={(e) =>{this.handleCheckinChange(e);}} >
                									{this.getSelectOptions("visit_purpose")}
               									</select>
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				                     
				                  	</div>
				                  	<div className="rule-form-group form-group row reset-margin">
				                  		<label for="" className="col-sm-5 font-weight-bold label__name reset-padding">{getValueFromLangJson("remarks")}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
						                  		<input autocomplete="off"  type="text" id="remarks" className="form-control" onChange={(e) =>{this.handleCheckinChange(e);}}  maxLength='50'/>
						                  		
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				                     
				                  	</div>



				                  	<div className="rule-form-group form-group row reset-margin">
				                  	<label className="cameraButton">{getValueFromLangJson("take_a_picture")}</label>
					                    <div className="col-sm-7 reset-padding">
					                    	<div>
						                  		 	 
    											<input type="file" accept="image/*" capture=""/>
  									
						                  		
                        					</div>
					                    	<div className="control-me"></div>
					                    </div>
				                     
				                  	</div>

				                </form>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button type="button" className= "btn btn-primary float-right mr-1" onClick={this.handleSubmit} disable>{getValueFromLangJson("submit")}</button>
							<Button variant="secondary" onClick={this.handleModalClose}>{getValueFromLangJson("close")}</Button>
						</Modal.Footer>
					</Modal>
                    <Modal show={this.state.mapShow} onHide={this.handleModalClose} size = 'xl' >
                        <Modal.Header closeButton className="modal-close-btn" show={this.state.mapShow} onHide={this.handleModalClose}>
                            <Modal.Title>{this.props.customer.cust_id} {getValueFromLangJson("current_location")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{minHeight : '650px'}}>
                            {this.state.gps ? <GoogleMapSetup coordinates={this.state.gps}/>:<div class="container-fluid" style={{marginTop:'22.5%' }}>
                                                                                                <div class="row justify-content-center">
                                                                                                    <div class="spinner-border text-primary" role="status">
                                                                                                        <span class="sr-only">{getValueFromLangJson("loading")}...</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row justify-content-center">
                                                                                                    <strong class="text-primary">{getValueFromLangJson("retrieving_gps")}</strong>
                                                                                                </div>
                                                                                            </div>}
                        </Modal.Body>					
                    </Modal>
                        
                    {this.state.profileHistory  && 
                                <> 
                                 <CustomerProfileHistory handle_history_modal={()=>this.handle_history_modal()}/> 
                                 </>
                    }
        </div>
        </div>
    );
	}

}

const mapStateToProps = (state) => {
	return {
	//   softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BorrowerPanel));


