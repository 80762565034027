import React, { useEffect } from 'react';
import {borrowerSearch, customerLocations} from '../../../actions/borrower_actions';
import FlowComponent from '../../common/component/core/FlowComponent';
import GoogleMap from '../../../helpers/googleMap';
import Toggle from '../../common/component/Toggle';
import Select from "react-select";
import {get} from '../../../helpers/storage_helper';
class RMRoutesContainer extends FlowComponent{

    constructor(props){
        super(props);
        this.state = {
            location_coordinates:'',
            location_center:'',
            clusterer_view:false,
            active_tab:"all",
            customer_locations_filter:[
                {label:"All",key:"all"},
                {label:"Enabled ",key:"enabled_cust"},
                {label:"Active ",key:"active_cust"},
                {label:"Overdue ",key:"overdue_cust"},
            ],
            borrower_search_criteria:{},
            filterOptions:[
                {label:(get('market').country_code=="UGA")? "Region": "Province",
                key: (get('market').country_code=="UGA")? "region": "province"},
                {label:'Gender',key:"gender"},
                {label:'Age',key:"age"},
                {label:'Shop Type',key:"biz_addr_prop_type"},
                {label:'Customer App Installed',key:"installed_cust_app"},
                {label:'Customer App Usage',key:"cust_app_usage"},


        ],
        cust_appOptions:[
            {label:'Yes', value:true},
            {label:'No', value:false},

        ],
        AgeOptions:[
            {label:'Below 35', value:"below_35"},
            {label:'Above 35', value:"above_35"},

        ]
        }; 
    }
    
    componentWillMount(){
     this.customers_locations()
     this.load_flow_rel_mgr(null,"multiselect_rm_name");

    }

    active_tab(value,key){
        this.setState({[key]:value })
    }
    
    map_view_handler(){
        this.setState({clusterer_view:!this.state.clusterer_view})
    }

    locationFilter(value,key,addl_key){
        var key = (key=="province")?"region":key
        if(addl_key!=null){
            var state= {...this.state.borrower_search_criteria}
            state[key]= value?value.value :""
            this.setState({borrower_search_criteria:state})
        }else{
        this.setMultiselectOptionOnState(value,key,this.state.borrower_search_criteria)
        }
    }
  
    customers_locations(req=null){
            this.setState({loader:true})
            customerLocations(this.req(req!=null ? req:'' ))
            .then((response)=>{
                if(!response){return}
                if(response.status=="success"){
                    this.setState({
                        all_datas:response.data.all,
                        enabled_cust:response.data.enabled,
                        active_cust:response.data.active,
                        overdue_cust:response.data.overdue,
                        loader:false

                        })
                }
            })
    }

   

    filerArray(data,key,value){
        const filterValue = data.filter((item,idx)=>{ return item[key] === value})
        return filterValue;

    }

    SearchHandler(){

        this.setState({
            all_datas:"",
            enabled_cust:"",
            active_cust:"",
            overdue_cust:"",
            loader:true

            })
        var borrower_search_criteria = this.state.borrower_search_criteria

        for (const key in borrower_search_criteria) {
            if (Array.isArray(borrower_search_criteria[key]) && borrower_search_criteria[key].length === 0) {
                delete borrower_search_criteria[key];
            }
        }

        var req={ borrower_search_criteria:borrower_search_criteria}
        this.customers_locations(req)
        
    }

    loader(){
        return(
            <div>
            <div className="product_takeup_loader mt-2"></div>
             <p className='text-center text-light'>Retrieving...</p>
          </div>
        )
    }
    no_data(){
       return( <p className='text-white text-center my-2 mt-4'>No Data </p>)
    }
    render(){
        return(
         <>
         <h2 className='text-white text-center mt-2 mb-4'> <b>Customer Locations</b></h2>
         <div style={{position:'relative'}}>
         <div className='row  mx-0  justify-content-center d-flex my-5 '>
            <div className='col-lg-5 col-md-12 col-sm-12 justify-content-center d-flex '>
                <div className='row col-12 m-0 py-2 px-3 justify-content-center d-flex' style={{background:"#383e4f",borderRadius:"10px"}}>
            {this.state.customer_locations_filter && this.state.customer_locations_filter.map((item,idx)=>{return(
                <div className={`col-lg-3 pointer d-flex justify-content-center text-white ${(this.state.active_tab== item.key) ? "active_call_tasks_tab" :"unactive_tab"}`} onClick={()=>{this.active_tab(item.key,"active_tab")}}>
                <p className={`m-0 px-2 py-3  text-white `} >{item.label} </p>
                </div>
            )}) }
            </div>
            </div>
           
        </div> 
        <div className=' m-0 justify-content-end d-flex align-item-center col-lg-3 col-sm-12 col-md-12 cust_locations_div' >
        <Toggle className="d-flex " leftLabel= {" Cluster View "} rightLabel={"Individual View "} fontSize="10" val={this.state.clusterer_view} set_tog_val={(res) => this.map_view_handler(res)} />

        </div>
        </div>

        <div className='d-flex align-items-center  row mx-0 mb-3 mt-2'>
            <div className='col-lg-10 col-sm-12 col-md-10 d-flex align-items-center row mb-1 mt-3'>
            {this.state.filterOptions.map((item,idx)=>{return(
                <div key={idx} className='d-flex align-items-center justify-content-between mx-2 cust_locations_filters col p-0' >
                    <div className='col p-0'>
                       <Select onChange={(e) =>{this.locationFilter(e,item.key,(((item.key=='installed_cust_app') ||(item.key=="age") || ((item.key=="cust_app_usage"))) ?"is_not_multiple":null));}}
                              value = {this.state.select_value}
                              options={(item.label=="RMs") ? this.state.flow_rel_mgr : ((item.key=='installed_cust_app')  || ((item.key=="cust_app_usage")))?this.state.cust_appOptions:
                              (item.key=="age")?this.state.AgeOptions:
                              this.getMultiSelectOptions(item.key)} 
							  placeholder={`${item.label}`}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
                              isClearable={((item.key=='installed_cust_app') ||(item.key=="age") || ((item.key=="cust_app_usage")))?true:false}
                              hideSelectedOptions={ false}

							  isMulti={((item.key=='installed_cust_app') || (item.key=="age") || (item.key=="cust_app_usage")) ? false:true} />

                              
                   </div>

                </div>
            )})}

           </div>
          
            <div className='col-lg-2 col-sm-12 col-md-2'>
            <button type="button" className="btn btn-md btn-primary" id="float_style" disabled={ Object.keys(this.state.borrower_search_criteria).length >0 ? false:true} onClick={()=>{this.SearchHandler()}} > Search</button>
            </div>
        </div>
       {this.state.loader ? 
       
           this.loader(): 
        <>
        {(this.state.all_datas  ) ? 
        <>
         {this.state.active_tab=="all" && this.state.all_datas && this.state.all_datas.coordinates?.length>0  && this.state.all_datas.center ?

            <GoogleMap 
            cust_coordinates={this.state.all_datas.coordinates} 
            location_center={this.state.all_datas.center} clusterer_view={ !this.state.clusterer_view} />:  
            this.state.active_tab=="all"  &&    this.no_data()

         }
         {(this.state.active_tab=="enabled_cust" && this.state.enabled_cust && this.state.enabled_cust.coordinates?.length>0 && this.state.enabled_cust.center) ?
        
            <GoogleMap 
            cust_coordinates={this.state.enabled_cust.coordinates} 
            location_center={this.state.enabled_cust.center} clusterer_view={ !this.state.clusterer_view} />:         this.state.active_tab=="enabled_cust"  && this.no_data()

          
         }
         {this.state.active_tab=="overdue_cust" && this.state.overdue_cust && this.state.overdue_cust.coordinates?.length>0 && this.state.overdue_cust.center ?

            <GoogleMap 
            cust_coordinates={this.state.overdue_cust.coordinates} 
            location_center={this.state.overdue_cust.center} clusterer_view={ !this.state.clusterer_view} />:         this.state.active_tab=="overdue_cust" && this.no_data()

         }
         {this.state.active_tab=="active_cust" && this.state.active_cust && this.state.active_cust.coordinates?.length>0 && this.state.active_cust.center ?

            <GoogleMap 
            cust_coordinates={this.state.active_cust.coordinates} 
            location_center={this.state.active_cust.center} clusterer_view={ !this.state.clusterer_view} />:         this.state.active_tab=="active_cust" &&this.no_data()

         }
        </>:
         this.no_data()
        }
        </>
         }
         </>

        );
    }


}

export default RMRoutesContainer;