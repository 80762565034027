
import './styles/flow.css';
import React, { Component, useEffect, useState } from 'react';
import { Navigate, RouterProvider} from 'react-router-dom';
import {Config, check_priv, get,get_item, remove_all, set} from './helpers/storage_helper';
import Login from './UI/auth/component/Login';
import AdminHome from './UI/home/AdminHome';
import SelectMarket from './UI/home/SelectMarket';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import { get_notification_permission, } from "./firebase/config";
import Router from '../src/Router/Router';
import FreshChat from './UI/utility/Component/FreshChat';
import { touch } from './actions/common_actions';
import { useDispatch, useSelector } from 'react-redux';
import { setValuesIntoRedux } from './Redux/Slices/CommonSlice';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import createCustomTheme, { theme } from './MUI/Theme';
import custom_colors from './MUI/colors';
import CSSoftPhoneContainer from './UI/cs_management/container/CSSoftPhoneContainer';

import { Modal } from 'react-bootstrap';
import '@fontsource/poppins';
import {FaPhoneAlt, FaPhoneSlash} from "react-icons/fa";
import { changeHeaderClientTabId ,changeHeaderSlice} from './Redux/Slices/HeaderSlice';
import { store } from './Redux/Store';
$.dataTable = require('datatables.net');
require("datatables.net-buttons/js/dataTables.buttons.js");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");





const  App = ()=>{

  const datas = useSelector(state=>state.Values)
  const dispatch = useDispatch();

  const acc_prvdr_code = get('acc_prvdr_code');
  const role_codes = get('role_codes')

  const [cust_data, setCustdata] = useState({
    cust_name: "Lydia Martin",
    mobile_num: "98654323234"
  })    //softphone
  const [isIncomingCall, setIsincomingCall] = useState(false)   //softphone

  const {header_client_tab_id} = useSelector((state)=>state.HeaderSlice)

 

  useEffect(()=>{
    
    get_notification_permission()

    if(!get('role_codes') || !get('access_token')){
      Router.navigate('/login')
    }
    else if(get('role_codes') && get('market')){
      server_time()
    }
  
  }, [])


  // useEffect(() => {

  //   alert(rememberMe)
  //   const handleTabClose = () => {
  //     alert("inside the use effe func")

  //     if (!rememberMe) {
  //        alert("keo")
  //       localStorage.clear(); 
  //     }
  //   };
  
  //   window.addEventListener('beforeunload', handleTabClose);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, [rememberMe]);


  useEffect(() => {
    const channel = new BroadcastChannel('cs_soft_phone')

    const handleBeforeUnload = (event) => {
        console.log('Page is about to be refreshed or closed');

        if (localStorage.getItem('client_tab_id')) {
            if (store.getState().HeaderSlice.header_client_tab_id == localStorage.getItem('client_tab_id')) {
                localStorage.removeItem('client_tab_id')
                changeHeaderClientTabId(null)
                channel.postMessage({message:'client_destroyed'})
            }
        }

    };


    window.addEventListener('beforeunload', handleBeforeUnload);


    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        channel.close()
    };
}, []);



  const server_time = async() =>{
    return await touch({country_code:get('market').country_code, time_zone: get('market').time_zone})
             .then((response) => {
                 if(!response){return false};
                 
                 if(response.status === "success"){
                   dispatch(setValuesIntoRedux({serevr_time:response}))
                 }
                 else{
                      remove_all()
                      Router.navigate('/login')
                 }
                }
              ); 
}


    return (
      <>{datas &&
      <ThemeProvider theme={createCustomTheme(datas.mode)}>
        <CssBaseline />
        <GlobalStyles styles={{ p:{fontFamily:'"Inter", sans-serif !important'} }}/>
        { <div className="App">
            <RouterProvider router={Router} />

            {/* {get('role_codes') && check_priv('user','chat_support') && <FreshChat/>}  */}
            <div className='incoming_call_modal' >
                    <Modal className='incoming_call_modal' show={isIncomingCall} onHide={()=>setIsincomingCall(false)} backdrop='static' keyboard={false}>
                      <Modal.Header className='p-0'></Modal.Header>

                    <Modal.Body className='py-2'>  
                      <div className='row m-0 d-flex align-items-center'>

                        <div className='col-3 call-icon-container call-reject ' onClick={()=>setIsCallRejected()} type="button">
                          <div style={{marginRight: "45px"}}>
                            <div className="ripple-wave ripple-red">
                              <div className="ripple-wave-2 ripple-red-2">
                                <FaPhoneSlash size={20} className='call-modal-icon text-danger ' />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-6 text-center p-0' >
                          <div>
                            <h3 className="fs-5 p-0 m-0 text-center col-12">{cust_data && cust_data.cust_name === "unknown" ? "UNKNOWN" : cust_data.cust_name}</h3>
                            <h3 className="fs-6 m-0 p-0 text-center col-12">{cust_data && cust_data.mobile_num}</h3>
                          </div>
                        </div>

                        <div className='col-3 call-icon-container' onClick={()=>setIsCallAccepted()} type="button" >
                          <div>
                            <div className="ripple-wave ripple-green">
                              <div className="ripple-wave-2 ripple-green-2">
                                <FaPhoneAlt size={20} className='call-modal-icon text-success' />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>    
                
                    </Modal.Body>

                    </Modal>
                    </div>
        </div>}
    </ThemeProvider>}
       </> 
    );
  
}

export default App;
