import { createBrowserRouter } from "react-router-dom";
import admin_routes from "../Router/Admin_routes";
import ForgetPassword from "../UI/auth/component/ForgetPassword";
import ListMarket from "../UI/auth/component/ListMarket";
import ValidateOTP from "../UI/auth/component/ValidateOTP";
import Home from "../UI/home/Home";
import Chart from "../UI/performance_dashboard_auditor/index";
import HomeLayOut from "./Layouts/HomeLayout";
import LoginLayout from "./Layouts/LoginLayout";
import LogoutLayout from "./Layouts/LogoutLayout";
import user_routes from "./User_routes";

const Router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginLayout />,
  },
  {
    path: "/logout",
    element: <LogoutLayout />,
  },
  {
    path: "/select_market",
    element: <ListMarket />,
  },
  {
    path: "/forgot_password",
    element: <ForgetPassword />,
  },
  {
    path: "/validate_otp",
    element: <ValidateOTP />,
  },
  {
    path: "/chart",
    element: <Chart />,
  },
  {
    path: "/",
    element: <HomeLayOut />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      ...admin_routes,
      ...user_routes,
    ],
  },
]);

export default Router;
