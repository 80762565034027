import React, { useState } from 'react';
import { useEffect } from 'react';
import { BasicReactTable } from '../../../helpers/react_table';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { updateStatus } from '../../../actions/common_actions';
import { req } from '../../../helpers/common_helper';
import { listPerson } from '../../../actions/person_actions';

const ListInvestors = () => {
   const [row_data,setrow_data] = useState([])
   const [column_data,setcolumn_data] = useState()
   const [loader,setloader] = useState(false)

    const column_fields = [
        { id: "full_name", label: "Name" },
        { id: "email_id", label: "Email" },
        { id: "mobile_num", label: "Mob Number" },
        { id: "created_by_name", label: "Created by " },
        { id: "created_at", label: "Created on" },
        { id: "status", label: "Status" },
        { id: "manage", label: "Manage" },
        { id: "action", label: "Action" },

    ]
    useEffect(() => {
        setcolumn_data(column_datas())
        list_investors()
    }, [])

    const list_investors =()=>{
    setloader(true)
    listPerson(req({'associated_with':'FLOW',role_codes:"investor"}))
    .then((response) => {
    setloader(false)

      if(!response){return };
      setrow_data(response.data)
    });
    }

    const change_status = (cell) =>{
        var request ={persons:{id:cell.id,status: cell.status=="enabled" ? "disabled" :"enabled"}}
       if(window.confirm('Are you sure to change the status?')){
        updateStatus(req(request)).
        then((response)=>{
            if(!response){return}
            if(response&& response.status=="success"){
                list_investors()
            }
        })
       }
     
    }

    const column_datas = () => {
        const columns = [
            column_fields && column_fields.map((item, idx) => {
                return {
                    Header: <p className='text-center m-0'>{item.label}</p>,
                    id: item.id,
                    accessor: item.id,
                    Cell: (cell) => {
                        {
                            return <div className='d-flex justify-content-center'>
                                {(item.id == "manage") ? <Link to={`/user/view/${cell.row.original.id}`}> View / Edit</Link>:
                                (item.id == "action") ? <Button onClick={()=>{change_status(cell.row.original)}}> { status =="enabled"  ? 'Disable' :'Enabled'}</Button>:
                                <p className='m-0'> { cell.row.original[item.id] ?  item.id=="created_at"  ? moment(cell.row.original[item.id]).format('DD MMM YYYY') :cell.row.original[item.id] :"-"}</p>
                                }
                                
                            </div>
                        }
                    },
                    minWidth: 150,

                };
            }),
        ];

        return columns[0];

    }


    return (
        <div className='mt-4 react_common_table'>
            <h2 className='text-white'> Investors List</h2>
            {row_data && row_data.length>0 &&column_data ?
            <BasicReactTable  row_data={row_data} csv_file={true} FName="Cashback Report" column_data={column_data} default_page_size={10} pagination={row_data.length > 10 ? true : false} />
            :<p className='mb-0 mt-5 text-center text-white'> {loader ? "loading..." :"No Data"}</p>
          }
        </div>
    );
};

export default ListInvestors;