import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";
import{FaHandsHelping} from "react-icons/fa";
import{HiOutlineDocumentAdd,HiPhoneOutgoing,HiArrowCircleRight,HiArrowCircleLeft}from"react-icons/hi";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import{ CgNotes}from'react-icons/cg';
import{BsClipboardData}from'react-icons/bs';
import FileUpload from '../../common/component/FileUpload';
import Alert from 'react-bootstrap/Alert';
import {get, Config} from '../../../helpers/storage_helper';
import { viewLead ,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode} from '../../../actions/lead_actions';
import {capitalize_first_letter,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import { approveKYC,updateLeadStatus} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import GoogleMapSetup from '../../../helpers/googleMap';
import moment from 'moment';
import PDFtoImage from './Pdf_to_image';
import { Homeacc_prvdrLogoselector , convert_sec_to_min_sec} from '../../../helpers/common_helper';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import RekycOwnerInfoComponent from './RekycOwnerInfoComponent';
import RekycAcOwnershipComponent from './RekycAcOwnershipComponent';
import RekycPhotoVerificationComponent from './RekycPhotoVerificationComponent';
import RekycAgreementandDataConsent from './RekycAgreementandDataConsent';
import RekycMobileNumberVerification from './RekycMobileNumberVerification';
import RekycHandlerandMapLocation from '../container/RekycHandlerandMapLocation';

class AuditRekycComponent extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","region","location","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
      this.state={lead:{}} 
    }

      audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }
      
   


      rekyc_modify_checking(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(addl_key !=null && idx ==null ){
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected"))  && (cust_reg_json[json_type][json_key][key].collected)&& (cust_reg_json[json_type][json_key][key].modified))){
            return this.get_class_name(cust_reg_json,"collected_modified")
          }
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][key].modified))){
            return  this.get_class_name(cust_reg_json,"modified")

          }

          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected")) && (cust_reg_json[json_type][json_key][key].collected))){
            return this.get_class_name(cust_reg_json,"collected")

          }
          else{
            return false;
           }

        }

       else if(idx!=null){
          if(((cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected"))&& (cust_reg_json[json_type][json_key][addl_key][idx][key].collected)&& (cust_reg_json[json_type][json_key][addl_key][idx][key].modified))) {
            return  this.get_class_name(cust_reg_json,"collected_modified")
          }
          if(((cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("modified"))  && (cust_reg_json[json_type][json_key][addl_key][idx][key].modified))) {
            return  this.get_class_name(cust_reg_json,"modified")
          }
          if(( (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected"))&& (cust_reg_json[json_type][json_key][addl_key][idx][key].collected))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }
        else{
          if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  && (cust_reg_json[json_type][json_key][key].collected)&& (cust_reg_json[json_type][json_key][key].modified))){
		
            return  this.get_class_name(cust_reg_json,"collected_modified")
        
             }
             if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("modified"))  && (cust_reg_json[json_type][json_key][key].modified))){
		
              return  this.get_class_name(cust_reg_json,"modified")
          
               }
               if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  && (cust_reg_json[json_type][json_key][key].collected))){
		
                return  this.get_class_name(cust_reg_json,"collected")
            
                 }
                 else{
                  return false;
                 }
        }
       
      }
      

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "COLLECTED"
         }
         
         else{
          return "MODIFIED"
         }
        }



    render(){
        return(
            <div>
             
            {(this.props.data.audit_kyc_wizard_menus.id_proof )  &&
            <RekycOwnerInfoComponent  lead_id={this.props.lead_id} view_lead={(a,b,c)=>{this.props.viewLead()}} rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{return this.props.rekyc_modify_state_handler(a,b,c,d,e,f,g,h,)}} rekyc_modify_checking={(a,b,c,d,f,g)=>{this.rekyc_modify_checking(a,b,c,d,f,g)}}    checkbox_verification={(a,b,c)=>{this.props.checkbox_verification(a,b,c)}}  owner_info={this.props.data}/>
            }

            {(this.props.data.audit_kyc_wizard_menus.gps_address || this.props.data.audit_kyc_wizard_menus.contact_persons)  &&
            <RekycHandlerandMapLocation lead_id={this.props.lead_id} view_lead={(a,b,c)=>{this.props.viewLead()}}  rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{return this.props.rekyc_modify_state_handler(a,b,c,d,e,f,g,h)}}   rekyc_modify_checking={(a,b,c,d,e,f)=>{this.rekyc_modify_checking(a,b,c,d,e,f)}}  checkbox_verification={(a,b,c)=>{this.props.checkbox_verification(a,b,c)}} handler_and_maplocation={this.props.data}  />
            }

           {(this.props.data.audit_kyc_wizard_menus.biz_accounts)  &&
            <RekycAcOwnershipComponent root={this.props.root} update_mismatch_reason={(a,b,c,d)=>this.props.update_mismatch_reason(a,b,c,d)}  sufficient_document_radio_button_handler={(a,b)=>{this.props.sufficient_document_radio_button_handler(a,b)}} lead_id={this.props.lead_id} view_lead={(a,b,c)=>{this.props.view_lead()}}  rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{return this.props.rekyc_modify_state_handler(a,b,c,d,e,f,g,h)}}   rekyc_modify_checking={(a,b,c,d,e,f)=>{this.rekyc_modify_checking(a,b,c,d,e,f)}}  checkbox_verification={(a,b,c)=>{this.props.checkbox_verification(a,b,c)}} acownership={this.props.data} account_owner_name_handler={(a,b,c,d,e,f,g,h)=>this.props.account_owner_name_handler(a,b,c,d,e,f,g,h)} file_upload_submit_handler={(a,b,c,d)=>this.props.file_upload_submit_handler(a,b,c,d)} setAuditLeadState={(a)=>this.props.setAuditLeadState(a)} />
            }
      
            {(this.props.data.audit_kyc_wizard_menus.kyc_photos || this.props.data.audit_kyc_wizard_menus.shop_photo)  &&
            <RekycPhotoVerificationComponent lead_id={this.props.lead_id} view_lead={(a,b,c)=>{this.props.viewLead()}} rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{return this.props.rekyc_modify_state_handler(a,b,c,d,e,f,g,h)}}    rekyc_modify_checking={(a,b,c,d,e,f)=>{this.rekyc_modify_checking(a,b,c,d,e,f)}}  checkbox_verification={(a,b,c)=>{this.props.checkbox_verification(a,b,c)}} photosverification={this.props.data} />
            }

            {(this.props.data.audit_kyc_wizard_menus.data_consent || this.props.data.audit_kyc_wizard_menus.agreements)  &&
            <RekycAgreementandDataConsent   operators_selection={(a,b,c,d,e)=>{return this.props.operators_selection(a,b,c,d,e)}} previous_comments_section={(a)=>{this.props.previous_comments_section(a)}} lead_id={this.props.lead_id} view_lead={(a,b,c)=>{this.props.viewLead()}} rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{return this.props.rekyc_modify_state_handler(a,b,c,d,e,f,g,h)}} account_owner_name_handler={(a,b,c,d,e,f,g,h)=>this.props.account_owner_name_handler(a,b,c,d,e,f,g,h)} rekyc_modify_checking={(a,b,c,d,e,f)=>{this.rekyc_modify_checking(a,b,c,d,e,f)}}  file_upload_submit_handler={(a,b,c,d)=>this.props.file_upload_submit_handler(a,b,c,d)}  checkbox_verification={(a,b,c)=>{this.props.checkbox_verification(a,b,c)}} setAuditLeadState={(a)=>this.props.setAuditLeadState(a)} Datas={this.props.data}  />
            }
           
           {(this.props.data.audit_kyc_wizard_menus.mobile_numbers)  &&
            <RekycMobileNumberVerification lead_id={this.props.lead_id} rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{return this.props.rekyc_modify_state_handler(a,b,c,d,e,f,g,h)}}   rekyc_modify_checking={(a,b,c,d,e,f)=>{this.rekyc_modify_checking(a,b,c,d,e,f)}}  audit_kyc_submit_callLog={()=>this.props.audit_kyc_submit_callLog()}  callLogRejectHandler={()=>this.props.callLogRejectHandler()} audit_kyc_modify_handler={(a,b,c,d)=>this.props.audit_kyc_modify_handler(a,b,c,d)} modify_handler={(a,b)=>this.props.modify_handler(a,b)} operators_selection={(a,b,c,d,e)=>this.props.operators_selection(a,b,c,d,e)} previous_comments_section={(a)=>this.props.previous_comments_section(a)} verify_handler={(a,b,c,d)=>this.props.verify_handler(a,b,c,d)} audit_kyc_confirm_identity={(a,b,c,d,e)=>this.props.audit_kyc_confirm_identity(a,b,c,d,e)} file_upload_submit_handler={(a,b,c,d)=>this.props.file_upload_submit_handler(a,b,c,d)} setAuditLeadState={(a)=>this.props.setAuditLeadState(a)} biz_mobilenumbers_sdata={this.props.data} audit_kyc_handleClose={(a,b,c)=>this.props.audit_kyc_handleClose(a,b,c)}  holder_num_verify={()=>this.props.holder_num_verify()} account_owner_name_handler={(a,b,c,d,e,f,g,h)=>this.props.account_owner_name_handler(a,b,c,d,e,f,g,h)}/>
           }
            </div>

        )        
     }
}



export default AuditRekycComponent;