import React, { ReactNode, useEffect, useState } from "react";
import AccordionWithTable from "../CommonComponent/AccordionWithTable";
import { getProductUpgrades } from "../../actions/loan_product_actions";
import {
  append_request,
  capitalize_first_letter,
  req,
} from "../../helpers/common_helper";
import moment from "moment";
import { amount_format, common_req, format_date } from "../../helpers/helper";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const FAUpgradedCustomers = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [row_data, setrow_data] = useState<any[]>([]);

  useEffect(() => {
    get_products_ist();
    get_columns();
  }, []);

  interface ProductUpgradeCriteria {
    fa_upgrade_wo_disburse: boolean;
  }

  const TextField = ({ link, value }: { link?: string; value: string }) => (
    <Box sx={{ py: 1, textAlign: "center" }}>
      {link ? (
        <Link to={link} style={{ fontSize: "0.75rem" }}>
          {value}
        </Link>
      ) : (
        capitalize_first_letter(value)
      )}
    </Box>
  );

  const column_fields = [
    { label: "Cust ID", key: "cust_id", is_link: true },
    { label: "FA before Upgrade Req", key: "crnt_fa_limit", is_amount: true },
    { label: "Requested Amount", key: "requested_amount", is_amount: true },
    { label: "Status", key: "status" },
    { label: "Upgraded Amount", key: "upgrade_amount", is_amount: true },
    { label: "Requested At", key: "created_at", is_date: true },
    { label: "Action Taken At", key: "updated_at", is_date: true },
  ];

  const get_columns = () => {
    const columns = column_fields.map((item, idx) => {
      return {
        Header: <div style={{ textAlign: "center" }}>{item.label}</div>,
        accessor: item.key + idx,
        id: item.key + idx,
        Cell: ({ row }: any) => {
          const cellValue = row.original[item.key] || "N/A";
          const formattedValue = item.is_amount
            ? amount_format(JSON.parse(row.original.task_json)[item.key])
            : item.is_date
            ? format_date(cellValue)
            : cellValue;
          const link = item.is_link
            ? `/borrower/indiv/view/${formattedValue}`
            : "";
          return <TextField link={link} value={formattedValue} />;
        },
      };
    });

    setColumns(columns);
  };

  const get_products_ist = () => {
    const product_upgr_criteria: ProductUpgradeCriteria = {
      fa_upgrade_wo_disburse: true,
    };
    getProductUpgrades(
      common_req({ prdt_upgr_search_criteria: product_upgr_criteria })
    ).then((response) => {
      if (response && response.status == "success") {
        setrow_data(response.data);
      }
    });
  };
  return (
    <div>
      {columns?.length > 0 && (
        <AccordionWithTable
          title="FA Upgraded Customers W/O Disbursal"
          table_data={{
            row_data: row_data,
            column_data: columns,
          }}
        />
      )}
    </div>
  );
};

export default FAUpgradedCustomers;
