import { Box, useTheme } from "@mui/material";
import { SubTitleVarientOneTypo } from "../../../UI/CommonComponent/typography";
import { ReactNode } from "react";

/**
 *
 * @param param0 text for header
 * @param param1 link for view details
 * @param param2 link text
 * @returns the header text with or without the view details link
 */
const HeaderTextUI = ({
  text,
  rightSec,
}: {
  text: string;
  rightSec?: ReactNode;
}) => {
  // hook
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1}
    >
      <SubTitleVarientOneTypo
        text={text}
        overRideStyles={{
          color: theme.palette.text.primary,
          textTransform: "capitalize",
          fontFamily: "Poppins",
          fontWeight: 500,
        }}
      />
      {rightSec && rightSec}
    </Box>
  );
};

export default HeaderTextUI;
