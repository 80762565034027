import React, { useEffect, useState } from "react";
import { listAccountProvider } from "../../../actions/account_provider_actions";
import { get } from "../../../helpers/storage_helper";
import Select from "react-select";

const formatSelectedOptions = (selectedOptions) =>
  Array.isArray(selectedOptions) && selectedOptions.length > 0
    ? `(${selectedOptions.map((option) => `'${option && option.value}'`).filter(Boolean).join(", ")})`
    : null;

const selectCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#097afa" : "#202940",
    color: state.isSelected ? "red" : "white",
    padding: state.isFocused ? "3px 0px 3px 10px" : "3px 0px 3px 10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? " " : "",
    borderTop: state.isFocused ? 0 : 0,
    borderLeft: state.isFocused ? 0 : 0,
    borderRight: state.isFocused ? 0 : 0,
    borderBottom: state.isFocused ? "2px solid  #5d616f" : "1px solid #5d616f",
    boxShadow: state.isFocused ? "none" : "",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
};

export default function CustomChartFilter({
  onFilterChange,
  country_code,
  target_state,
  selected_tab,
  disable_provider,
  acc_prvdr_changed,
  dateWiseFilter,
  lender,
  viewBy,
  acc_prvdr,report_weekly,amount_type,start_date,end_date
}) {
  const [selectedAccountProvider, setSelectedAccountProvider] = useState(acc_prvdr);
  const [allAccPrvdr, setAllAccPrvdr] = useState([]);
  const [loadingAccPrvdr, setLoadingAccPrvdr] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if ((allAccPrvdr.length === 0 || !allAccPrvdr) && (viewBy || disable_provider || selected_tab === "get_portfolio_chart") && country_code !== "*") {
      fetchAccountProvider();
    }
    setSelectedAccountProvider(acc_prvdr);
    onFilterChange(formatSelectedOptions(selectedFilters), acc_prvdr);
  }, [country_code, target_state, selected_tab, dateWiseFilter, lender, viewBy, acc_prvdr, selectedFilters, allAccPrvdr,report_weekly,amount_type,start_date,end_date]);

  const fetchAccountProvider = () => {
    const request = {
      country_code: country_code,
      time_zone: get("market").time_zone,
    };
    listAccountProvider({ ...request, status: "enabled", biz_account: true }).then((response) => {
      if (response && response.status === "success") {
        setAllAccPrvdr(response.data.list.map((each) => each.acc_prvdr_code));
      }
      setLoadingAccPrvdr(false);
    });
  };

  const prvdrSelectHandler = (selectedAccPrvdr) => {
    setSelectedAccountProvider(selectedAccPrvdr);
    acc_prvdr_changed?.(selectedAccPrvdr);
    onFilterChange(formatSelectedOptions(selectedFilters), selectedAccPrvdr);
  };

  const handleFilterTypeChange = (selectedOptions) => {
    setSelectedFilters(selectedOptions);
    onFilterChange(formatSelectedOptions(selectedOptions), selectedAccountProvider);
  };

  const getMultiSelectOptions = () => {
    return country_code === "*"
      ? [{ label: "Age", value: "age" }, { label: "Gender", value: "gender" }]
      : country_code === "RWA"
      ? [
          { label: "Age", value: "age" },
          { label: "Gender", value: "gender" },
          { label: "Province", value: "region" },
        ]
      : [
          { label: "Age", value: "age" },
          { label: "Gender", value: "gender" },
          { label: "Region", value: "region" },
        ];
  };

  const renderAccountProviderButtons = () => (
    <div className="d-flex align-items-center">
      <label className="no-margin" style={{ paddingRight: 15 }}>
        Provider
      </label>
      <button
        className={`filterBtns allBtn_size pointer ${selectedAccountProvider === "All" ? "" : "opac_50"}`}
        style={{ height: 30, width: 60, cursor: "pointer" }}
        onClick={() => prvdrSelectHandler("All")}
      >
        ALL
      </button>
      {allAccPrvdr.map((each, idx) => (
        <div
          className={`mx-1 pointer ${selectedAccountProvider === each ? "" : "opac_50"}`}
          style={{ height: 30, width: 60, cursor: "pointer" }}
          key={idx}
          onClick={() => prvdrSelectHandler(each)}
        >
          <img className="img-fluid" src={`/img/${each}_logo_color.png`} alt={each} />
        </div>
      ))}
    </div>
  );

  const renderSelect = () => (
    <div className="d-flex align-items-center">
      <label className="no-margin" style={{ paddingRight: 15 }}></label>
      <div style={{ width: "" }}>
        <Select
          onChange={handleFilterTypeChange}
          value={selectedFilters}
          options={getMultiSelectOptions()}
          placeholder="Choose Filter"
          styles={selectCustomStyles}
          classNamePrefix="my-select"
          isMulti
        />
      </div>
    </div>
  );

  const renderSpinner = () => (
    <div className="d-flex align-items-center">
      <div className="simple-spinner"></div>
    </div>
  );

  const renderAccountProviderSection = () => (
    <div className="custom-filter-switch" style={{ marginLeft: "15px" }}>
      {renderAccountProviderButtons()}
    </div>
  );

  const showProviderSection = () => {
    return loadingAccPrvdr ? renderSpinner() : renderAccountProviderSection();
  };

  const isPortfolioChart = () => {
    return (selected_tab === "get_portfolio_chart" || viewBy !== false) && country_code !== "*";
  };

  const isSpecificReport = () => {
    return (
      !dateWiseFilter &&
      selected_tab !== "get_portfolio_chart" &&
      selected_tab !== "cohort_report" &&
      selected_tab !== "get_ontime_payments" &&
      viewBy === false
    );
  };

  const filterAccountProvider = () => {
    if (country_code !== "*") {
      if (disable_provider !== null) {
        if (loadingAccPrvdr) {
          return renderSpinner();
        } else {
          return renderAccountProviderSection();
        }
      }
    }
    return null;
  };
  
  const renderReportSection = () => (
    <div className="p-3 rounded mt-3">
      {filterAccountProvider()}
      {renderSelect()}
    </div>
  );
  

  return (
    <>
      {isPortfolioChart() ? (
        showProviderSection()
      ) : isSpecificReport() ? (
        renderReportSection()
      ) : null}
    </>
  );

}


