import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createClient, getCustDetail, getCsCallLogs, csStatusUpdate, getWebrctConfigs, softphoneCallDuration, updateCallScreen, process_call_log, getPrevCallIntercations, get_uncompleted_call_log, completeMissedCall, missedCallsList, getCSDevice, csDetailsUpdate } from "../../../actions/cs_management_actions";
import { useSelector, useDispatch } from 'react-redux'
import { MdOutlineCallEnd } from "react-icons/md";

import Africastalking from 'africastalking-client'
// import '/theme/assets/css/soft-phone.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPhone, faArrowUpLong, faGripLinesVertical, faMicrophone, faMicrophoneSlash, faCircleUser} from '@fortawesome/free-solid-svg-icons'
// import { handleException, isLoginUser, updateCSDetails } from "../utils/utils";
import CallTimer from "../components/CallTimer";
import ShowCalledTime from "../components/ShowCalledTime";
import { get, set } from "../../../helpers/storage_helper";
import { FaPhoneAlt, FaLongArrowAltUp } from "react-icons/fa";
import Select from "react-select";
import { FiPhoneIncoming, FiPhoneOutgoing } from "react-icons/fi";
import { IoIosInformationCircle } from "react-icons/io";
import { IoPersonCircleSharp } from "react-icons/io5";
import { dd_value, getMultiSelectOptions } from "../../../actions/common_actions";
import { array_to_string, format_date, lbl_amt, multi_acc_pur_tostring, select_custom_styles, useDebounce } from "../../../helpers/common_helper";
import moment from "moment";
import { RiQuestionFill } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";
import ConfirmModal from "../../common/component/ConfirmModal";
import CommonCallLogModal from "../components/CommonCallLogModal";
import { change_soft_phone_unique_messages_id, setValuesIntoRedux } from "../../../Redux/Slices/CommonSlice";
import SoftphoneCallSections from "./SoftphoneCallSections";
import Draggable from 'react-draggable';
import { v4 as uuidv4 } from 'uuid';
import { store } from "../../../Redux/Store";
import withRouter from "../../../Router/Withrouter";
import { PiClockCountdownBold } from "react-icons/pi";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {changeHeaderClientTabId } from "../../../Redux/Slices/HeaderSlice";
// import { BroadcastChannel } from 'broadcast-channel';



export const updateCSDetails = async(data) => {
    try{
        const response = await csDetailsUpdate(data)
        if(response.status === 'success') {
           return response;
        }else{
            alert(response.message)
        }
    }
    catch (error) {
        console.log(error)
    }
}

const Softphone = ({ screen_from, cust_id, type = null, is_direct_outgoing = false, outgoing_call_data = false, rm_id = null, location }) => {

    const client = useRef(null);
    const channel = new BroadcastChannel('cs_soft_phone')
    const tab_id = useRef(uuidv4())
    const call_log_view = sessionStorage.getItem("selected_call_log")

    const [csCallLogs, setCsCallLogs] = useState([])
    const [callLogCondition, setcallLogConditon] = useState(call_log_view ? call_log_view : "All")
    const [WsStatus, setWsStatus] = useState(false)
    const [isCallInProcess, setIsCallInProgress] = useState(false)
    const [calleeNum, setCalleeNum] = useState("")
    const [cust_data, setCustData] = useState({ cust_name: "", mobile_num: "" })
    const [cust_info, setcust_info] = useState(false)
    const [remarks, setremarks] = useState(null);
    const [callStatus, setCallStatus] = useState('')
    const [holdAndMute, setHoldAndMute] = useState({ hold: false, mute: false })
    const [mobileNumbs, setmobileNumbs] = useState(["mobile_num", "alt_biz_mobile_num_1", "alt_biz_mobile_num_2"])
    const [Number, setNumber] = useState(null);
    const [Reason, setReason] = useState([]);
    const [CallDurations, setCallDurations] = useState();
    const [SearchType, setSearchType] = useState(null);
    const [CustName, setCustName] = useState(null);
    const [call_completed, setcall_completed] = useState(false);
    // const [customer_id,setcust_id] = useState(null);
    const [selected_options, setselected_options] = useState([]);
    const [callInteractions, setcallInteractions] = useState([]);
    const [CallLogModal, setCallLogModal] = useState(false)
    const [csDev, setCSDev] = useState({})

    const customer_id = useRef(null);
    const datas = useSelector((state) => state.cs_soft_phone)
    const remarks_text = useRef(null);

    const [loader, setloader] = useState(false)

    // const interactions = useMemo(updateInteractions, [])
    const params = {
        sounds: {
            dialing: '/sounds/dial.mp3',
            ringing: '/sounds/ring.mp3'
        },
    }

    const customer_details = {
        cust_info: [
            { label: "Name", key: "cust_name" },
            { label: "Location", key: "location" },
            { label: "Mobile Number", key: "cust_mobile_num" },
            { label: "Flow Cust ID", key: "cust_id" },
            { label: "DOB", key: "cust_dob" },
            { label: "Data Prvdr Cust ID", key: "cust_id" },

        ],
        fa_details: ["loan_doc_id", "status", "paid_amount", "due_date", "current_os_amount", "due_amount"]
    };
    const dispatch = useDispatch()

    const {header_client_tab_id} = useSelector((state)=>state.HeaderSlice)

    const createAitClient = async () => {
        try {
            const config_data = await getWebrctConfigs();
            if (config_data.status === "success") {
                const response = await createClient(config_data.data);
                localStorage.setItem('at_token', JSON.stringify(response.token))

            } else {
                alert(config_data.message)
            }

        } catch (error) {
            console.error(error);
        }
    }



    const handleClockToggle = async() => {
        let cs_break = ""
        if(csDev.on_break == 0){
            cs_break = 'start';
        }else{
            cs_break = 'end';
        }
        let data = csDev
        data.break = cs_break
        let confirm = false
        if(cs_break == 'start'){
            confirm = window.confirm('Are you sure you want to take a break ? ')
        }else{
            confirm = true;
        }
        if(confirm){
            let response =  await updateCSDetails(data)
            if(response){
                setCSDev(response.data.cs_device)
            }
        }
        
        
    }

    const csDeviceDetails = async() => {
        try{
            let data = {'type' : 'webrtc'}
            let response = await getCSDevice(data)
            if(response.status == "success"){
                setCSDev(response.data)
            }else{
                alert(response.message)
            }
        }catch(error){
            console.error(error)
        }
       
    }

    useEffect(()=>{
        updateCSDetails({logout:false})
        csDeviceDetails()

    }, [])

    useEffect(() => {
        // const worker = new SharedWorker('/cs_soft_phone_worker.js', {
        //     name:'soft_phone',
        //     type:"classic"
        // });
        // worker.port.start();
        // console.log(worker, 'worker initiated')
        let access_token = get('access_token')
        if (!access_token) {
            window.close()
        }
        if ( !localStorage.getItem('client_tab_id') && csDev?.status == "enabled") {
            initializeClient()
        } else {
            client.current = datas.client
        }

        let call_status = localStorage.getItem('selected_call_log')
        if (call_status) {
            dispatch(setValuesIntoRedux({ callStatus: datas.callStatus }))
            // setCallStatus(callStatus)
        }

        if (!screen_from) {
            getTodaysCallLog()
        }

        // channel.onmessage = event => {
        //     let message = event.data.message
        //     if (message == "isCallAccepted") {
        //         IncomingCall.answer()
        //     }
        //     if (message == "isCallRejected") {
        //         // IncomingCall.cancel()
        //     }
        //     // console.log('new_tab',  event)

        //     // if(message == "new_tab"){
        //     //     console.log('first_event',  event)
        //     //     dispatch(setValuesIntoRedux(datas))
    
        //     // }
        // }
        getCallDurations()


        // worker.port.onmessage = (event)=>{
        //     if(event.data.type == 'soft_phone_client_not_exist'){
        //         console.log('new_client')
        //         initializeClient()
        //         worker.port.postMessage({type:'soft_phone_client_created'})
        //     }
        // }
        if (location.pathname == "/cs_managements/soft_phone") {
            
            dispatch(setValuesIntoRedux({screen_from: "soft_phone" }))
        }


       


    }, [csDev])

    // useEffect(() => {

    //     const handleBeforeUnload = (event) => {
    //         console.log('Page is about to be refreshed or closed');

    //         if (localStorage.getItem('client_tab_id')) {
    //             if (tab_id.current == localStorage.getItem('client_tab_id')) {
    //                 localStorage.removeItem('client_tab_id')
    //                 // sessionStorage.removeItem('client_tab_id')
    //             }
    //         }

    //     };


    //     window.addEventListener('beforeunload', handleBeforeUnload);


    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    useEffect(() => {
        channel.onmessage = (event) => {

            let state_msg_ids = store.getState().Values.soft_phone_unique_messages_ids

            if (localStorage.getItem('client_tab_id') && (store.getState().HeaderSlice.header_client_tab_id == localStorage.getItem('client_tab_id'))) {

                if(event.data.unique_msg_id && !state_msg_ids.includes(event.data.unique_msg_id)){
                    console.log('message_recieved - new message id')
                    dispatch(change_soft_phone_unique_messages_id(event.data.unique_msg_id))
                    if (event.data.type == "hang_up") {
                        IncomingCall.cancel() 
                    }

                    if (event.data.type == "outgoing_call") {
                        console.log('outgoing_message')
                        // outgoingCall("+256785623486",event.data.call_datas.missed_calls_val)
                        outgoingCall(event.data.call_datas.num, event.data.call_datas.missed_calls_val)
                
                    }   

                    if (event.data.type == "call_accepted") {
                        
                     IncomingCall.answer()
                        
                    }
                    if (event.data.type == "call_rejected") {
                        
                    IncomingCall.cancel()
                        
                    }
            }
            } else {
                // if(event.data.type == "set_call_values" ){
                //     setValuesIntoRedux({isIncomingCall:datas.isIncomingCall,cust_data:datas.cust_data,call_duration:datas.call_duration,missed_calls_view:datas.missed_calls_view,outgoingCall:datas.outgoingCall,isCallInProcess:datas.isCallInProcess,cust_id:datas.cust_id,})
                // }
            }


        }
        

    }, [])

    useEffect(() => {
        // if (is_direct_outgoing && outgoing_call_data && (client.current || datas.client) && datas.WsStatus) {
        if(is_direct_outgoing && outgoing_call_data && localStorage.getItem('client_tab_id')){
            
            var num = (screen_from == "pending_rm_visits") ? outgoing_call_data.rm_mob_num : (screen_from == "lead_num_verify") ? outgoing_call_data.lead_mob_num : outgoing_call_data.cust_mobile_num

            var confirm = window.confirm(`Are you sure you want to make call to this number ${num} ?`)

            if (screen_from == "pending_rm_visits") {
                let rm_data = { cust_name: outgoing_call_data.rm_name, mobile_num: outgoing_call_data.rm_mob_num,is_rm:true ,id:outgoing_call_data.owner_id,rm_name: outgoing_call_data.rm_name}
                channel.postMessage({message:"set_outgoing_call_details", data:{cust_data: rm_data,rmDetails :rm_data}})
                dispatch(setValuesIntoRedux({ cust_data: rm_data ,rmDetails :{rm_data}}))
            }else if(screen_from == 'lead_num_verify'){
                channel.postMessage({message:"set_outgoing_call_details", data:{cust_data: outgoing_call_data ,lead_id:outgoing_call_data.lead_id}})
                dispatch(setValuesIntoRedux({ cust_data: outgoing_call_data,lead_id:outgoing_call_data.lead_id, lead_details : outgoing_call_data}))
            } else {
                channel.postMessage({message:"set_outgoing_call_details", data:{cust_data: outgoing_call_data ,cust_id:outgoing_call_data.cust_id}})
                dispatch(setValuesIntoRedux({ cust_data: outgoing_call_data,cust_id:outgoing_call_data.cust_id}))

            }



            if (confirm) {
                call_handler(num)
            } else{
                channel.postMessage({message:"save_cust_values", data:{pending_cust_loader: false,pending_rms_details :null,pending_cust_details:null, duplicate_txn_data:null, lead_num_verify : false, lead_details:null}})

                dispatch(setValuesIntoRedux({ pending_cust_loader: false,pending_rms_details :null,pending_cust_details:null, duplicate_txn_data:null, lead_num_verify:  false, lead_details:null}))
             
            }
        }
    }, [is_direct_outgoing,outgoing_call_data,datas.WsStatus])

    const initializeClient = async () => {
        let at_token = get('at_token')

        if (!at_token) {
            await createAitClient()
            at_token = get('at_token')
        }



        if (!client.current || !localStorage.getItem('client_tab_id')) {
            client.current = new Africastalking.Client(at_token, params);

            client.current.on('ready', () => {
                // setWsStatus(true)
                dispatch(setValuesIntoRedux({ WsStatus: true }))
            }, false)

            client.current.on('notready', () => {
                // setWsStatus(false)
                alert("client is not ready")
                dispatch(setValuesIntoRedux({ WsStatus: false }))

            }, false)

            client.current.on('incomingcall', (params) => {
                // channel.postMessage({type:"set_call_values"})
                let full_num = params.from
                let mobile_num = full_num.replace('+256', '')
                IncomingCall.show(mobile_num, "incoming_call")
            }, false)

            client.current.on('hangup', async (hangupCause) => {
                // channel.postMessage({ type: 'hang_up' ,tabId:tab_id.current});
                // channel.postMessage({type:"set_call_values"})
                endCallLog()

                IncomingCall.cancel()
                // channel.postMessage({message:"hangup", data:cust_data})
                dispatch(setValuesIntoRedux({ callStatus: '', HoldAndMute: { hold: false, mute: false, isCallInProcess: false, outgoingCall: false, lead_num_verify:false } }))

                channel.postMessage({message:"CallHangup", data:{  callStatus: '', HoldAndMute: { hold: false, mute: false, isCallInProcess: false, outgoingCall: false , lead_num_verify:false} }})

                // setHoldAndMute({hold : false, mute: false})


            }, false);

            client.current.on('call', (e) => {
                alert("real_call")
            })

            client.current.on('offline', (e) => {
                alert('token expires please login to soft phone again')
                window.close()
            })

            client.current.on('callaccepted', () => {
                var store_value = store.getState().cs_soft_phone
                if(store_value && store_value.rmDetails && (store_value.rmDetails.is_rm || (store_value.rmDetails.rm_details && store_value.rmDetails.rm_details.is_rm)) ){
                    dispatch(setValuesIntoRedux({ callStatus: 'bridged', CallLogModal: false, ModalIsOpen: true }))
                    channel.postMessage({message:"callAccepted", data:{callStatus: 'bridged', CallLogModal: false, ModalIsOpen: true }})
                }else if(store_value?.lead_details?.lead_id){
                   
                    dispatch(setValuesIntoRedux({ callStatus: 'bridged',CallLogModal: false, ModalIsOpen: false}))
                    channel.postMessage({message:"callAccepted", data:{callStatus: 'bridged', CallLogModal: false, ModalIsOpen: false }})
                }else{
                    dispatch(setValuesIntoRedux({ callStatus: 'bridged', CallLogModal: true, ModalIsOpen: true }))
                    channel.postMessage({message:"callAccepted", data:{callStatus: 'bridged', CallLogModal: true, ModalIsOpen: true }})
    
                }

             
                // setCallStatus('bridged')
            }, false)


            client.current.on('isAudioMuted', () => {
                alert("audioIsMuted")
            }, false)

            client.current.on('closed', () => {
                window.location.reload()
            }, false)

            let header_tab_id = uuidv4()

            dispatch(setValuesIntoRedux({ client: client.current }))

            dispatch(changeHeaderClientTabId(header_tab_id))

            localStorage.setItem('client_tab_id', header_tab_id)

            
        }



    }


    const generate_unique_ids = (key)=>{
        return key + uuidv4()
    }
    

    const getCallDurations = () => {
        softphoneCallDuration()
            .then((response) => {
                if (response && response.status == "success") {
                    dispatch(setValuesIntoRedux({ CallDurations: response.data }))

                    // setCallDurations(response.data)
                } else { return }
            })
    }

    // get todays call log by cs_id
    const getTodaysCallLog = async () => {
        try {
            const response = await getCsCallLogs();
            if (response.status === "success") {
                setCsCallLogs(response.data);

            } else {
                alert(response.message)
            }
        } catch (error) {
            console.error(error);
        }
    };

    // function to show the call logs
    const showCallLogs = (condition = "All") => {

        setcallLogConditon(condition)
        // sessionStorage.setItem("selected_call_log", condition)

    }

    const updateInteractions = () => {

    }

    const CallInteractions = (id) => {
        getPrevCallIntercations({ cust_id: id })
            .then((response) => {
                if (response && response.status == "success") {
                    setcallInteractions(response.data)
                } else { return }
            })

    }

    // function to get the customer details using mobile number
    const getCustDetails =  (value, req_parameter = null, type = false) => {
        // try {
            var customer_list = []
            var req = {}
            if (req_parameter == "name_filter") {
                setloader(true)
            }
           
            if (type == "incoming_call") {
                req.incoming_call = true
            }

            if (req_parameter) {
                req.req_parameter = value
                req.search_num = calleeNum

            } else {
                req.mobile_num = value;

            }
            return getCustDetail(req)
            .then((response)=>{
                if (response.status === 'success') {
                    setCustData(response.data);
                    setcall_completed(false)
                     customer_list = response.data.customer_list ? response.data.customer_list : []
                    if(response.data && response.data.is_rm){
                        
                        
                        if(cust_data){
                            setCustData({})
                        }
                        if(cust_info){
                            setcust_info(false)

                        }
                        dispatch(setValuesIntoRedux({rmDetails: response.data }))
                        channel.postMessage({message:"is_rm_call", data:{rmDetails: response.data}})             
                    } else{
                        if (customer_list && customer_list.length == 1) {
                            if (type !== "incoming_call") {
                                dispatch(setValuesIntoRedux({cust_data:customer_list[0]}))
                                call_handler(value, customer_list[0].isd_code, null, false, customer_list[0])
                            }
                            viewCustomerDetails(customer_list[0])
                            if (type == "incoming_call") {
                                dispatch(setValuesIntoRedux({ incoming_call_details: customer_list,rmDetails:null }))
                            }
                        }                       
                         dispatch(setValuesIntoRedux({rmDetails:null}))
                         channel.postMessage({message:"is_rm_call", data:{rmDetails: null}})         


                    }

                 
                    if (response.data.message) {
                        setcust_info(null)
                        setSearchType(null)

                    }
                    setloader(false)

                    return customer_list
                } 
                else {
                    alert(response.data)
                }
                return customer_list
            })


            // if (response) {
            //     if (response.status === 'success') {
            //         setCustData(response.data);
            //         setcall_completed(false)
            //         let customer_list = response.data.customer_list ? response.data.customer_list : []
            //         if (customer_list && customer_list.length == 1) {
            //             if (type !== "incoming_call") {
            //                 call_handler(value, customer_list[0].isd_code)
            //             }
            //             viewCustomerDetails(customer_list[0])
            //             if (type == "incoming_call") {
            //                 dispatch(setValuesIntoRedux({ incoming_call_details: customer_list }))
            //             }
            //         }
            //         if (response.data.message) {
            //             setcust_info(null)
            //             setSearchType(null)
            //         }
            //         setloader(false)

            //         return customer_list
            //     } else {

            //         alert(response.data)
            //     }
            // }

        
    };



    // incoming call modal trigger 

    const IncomingCall = {

        show: async (mobile_num, call_type = "incoming_call") => {
            // channel.postMessage({type:"set_call_values"})
            dispatch(setValuesIntoRedux({ isIncomingCall: true, outgoingCall: false, show_nums: false, missed_calls_view: false }))
            let cust_data =  await getCustDetails(mobile_num, null, call_type)
            dispatch(setValuesIntoRedux({ incomingcall_num: mobile_num }))
            channel.postMessage({message:"show_incomingCall", data:{incoming_call_details:cust_data,incomingcall_num:mobile_num,isIncomingCall: true, outgoingCall: false, show_nums: false, missed_calls_view: false}})
        },

        answer: () => {
            client.current.answer();
            dispatch(setValuesIntoRedux({ callStatus: "bridged", isCallInProcess: true }))
            channel.postMessage({message:"incomingCall_accepted", data:{callStatus: "bridged", isCallInProcess: true}})

            // setCallStatus("connecting")
            // setIsCallInProgress(true)
        },

        cancel: (c_from) => {
            sessionStorage.removeItem('cust_data')
            dispatch(setValuesIntoRedux({
                holdAndMute: { hold: false, mute: false }, isCallInProcess: false, outgoingCall: false, callStatus: "", isIncomingCall: false, call_duration: '00:00:00',pending_rms_details :null,pending_cust_details:null
            }))
            channel.postMessage({message:"incomingCall_hangup", data:{
                holdAndMute: { hold: false, mute: false }, isCallInProcess: false, outgoingCall: false, callStatus: "", isIncomingCall: false, call_duration: '00:00:00',pending_rms_details :null,pending_cust_details:null
            }})
            // channel.postMessage({type:'call_rejected'})
            client.current.hangup()
            // channel.postMessage({type:"setcust_details_call_values"})

          
            getTodaysCallLog()


            // setIsCallInProgress(false)
            // setHoldAndMute({hold : false, mute: false})
        }
    }


    // // make a call to callee
    const call = async () => {

        let number = setMobilenumber()
        await getCustDetails(number)

    }
    const setMobilenumber = (num = calleeNum) => {
        let number = num.replace(/\s/g, '');
        if (number.length > 0 && (!number.startsWith('+256'))) {
            number = !isNaN(number) ? number = "+256" + number : (!(number.toLowerCase().startsWith('flow.'))) ? number = "flow." + number.toUpperCase() : number
        }
        return number
    }
    const viewCustomerDetails = (data) => {
        CallInteractions(data.cust_id)
        customer_id.current = data.cust_id
        setCalleeNum(data.cust_mobile_num)
        setcust_info(data)
        dispatch(setValuesIntoRedux({cust_data:data}))
        channel.postMessage({message:"save_cust_values", data:{cust_data:data}})         

    }

    const logout = async () => {
        let data = {logout : true}
        let status = await updateCSDetails(data)
        if(status) {
            localStorage.removeItem('cs_details');
            localStorage.removeItem('at_token');
            window.close()
        }


    }

    // function to set the call log number to input field when click the number
    const setNumToinput = (number) => {
        let cust_num = number.replace(/\s/g, '')
        //   if(!(cust_num.startsWith('+256') || ))
        if (!isNaN(cust_num)) {
            let formattedNumber = '';
            for (let i = 0; i < cust_num.length; i++) {
                formattedNumber += cust_num[i];
                if ((i + 1) % 5 === 0 && i !== cust_num.length - 1) {
                    formattedNumber += ' ';
                }
            }
            setCalleeNum(formattedNumber);

        }
        else {
            setCalleeNum(cust_num)
        }

    }

    // function for hold and unhold calls
    const toggleHoldUnhold = () => {
        if (holdAndMute.hold) {
            setHoldAndMute({ ...holdAndMute, hold: false })
            client.current.unhold()
        }
        else {
            setHoldAndMute({ ...holdAndMute, hold: true })
            client.current.hold()
        }
    }

    // function for mute and unmute the calls
    const toggleMuteUnmute = () => {

        if (holdAndMute.mute) {
            setHoldAndMute({ ...holdAndMute, mute: false })
            client.current.unmuteAudio();


        } else {
            setHoldAndMute({ ...holdAndMute, mute: true })
            client.current.muteAudio();
        }
    }

    const getInputHandler = (value, type) => {
        setselected_options(value)
        var reason_arr = [];
        for (var i = 0; i < value.length; i++) {
            reason_arr.push(value[i].value);
        }
        dispatch(setValuesIntoRedux({ reason: reason_arr }))
        setReason(reason_arr)
    }

    const callLogSubmit = () => {
        var fa_details = (cust_info && cust_info.current_loan) ? "current_loan" : "last_loan"
        var req = {}
        // if(reason && reason.length>0 ){
        req.reason = Reason
        req.remarks = remarks
        req.call_log_id = datas.call_log_details.call_log_id
        req.loan_doc_id = (cust_info && cust_info[fa_details].loan_doc_id) ? cust_info[fa_details].loan_doc_id : null
        //  }
        process_call_log(req)
            .then((response) => {
                if (response && response.status == "success") {
                    setReason([])
                    setremarks(null)
                    setcall_completed(true)
                    setselected_options([])
                    remarks_text.current = null
                    // setcall_log_details(null)
                    setcust_info(null)
                    setCalleeNum("")
                    setCustData({})
                    // CallInteractions(cust_info.cust_id)
                    alert(response.message)
                    dispatch(setValuesIntoRedux({ call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null}))

                    channel.postMessage({message:"submit_call_log", data:{call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null}})
                   

                } else { return }
            })
    }

    const toggle_modal = (type = null) => {
        if (type == "continue") {
            call_handler()
        }
        if(datas.pending_call_dialog){
          
            if (type == "confirm" ) {
                pending_call_log_modal(true)
            }
            dispatch(setValuesIntoRedux({pending_call_dialog:false,pending_call_log_msg:null,                pending_cust_loader: false,pending_rms_details :null,pending_cust_details:null
            }))
            channel.postMessage({message:"pending_call_confirmation", data:{pending_call_dialog:false,pending_call_log_msg:null,pending_cust_loader: false,pending_rms_details :null,pending_cust_details:null
            }})
  
        } 

        setSearchType(type)
        
    
    }

    const call_handler = (number = calleeNum, isd_code = 256, missed_calls_val = null, get_cust_frm_state = false, cust_details = null) => {
        var num = setMobilenumber(number)
        // dispatch(setValuesIntoRedux({hide_menus:false}))
        if (num) { 
            channel.postMessage({ type: 'outgoing_call', tabId: store.getState().HeaderSlice.header_client_tab_id, call_datas: { num: num, missed_calls_val: missed_calls_val ,rmDetails:null},  unique_msg_id : generate_unique_ids('outgoing_call') });
            
            if(get_cust_frm_state){
                channel.postMessage({message:'save_cust_values', data:{cust_data:cust_info, cust_id:cust_info.cust_id}})
            }
            else if(cust_details){
                channel.postMessage({message:'save_cust_values', data:{cust_data:cust_details, cust_id:cust_details.cust_id}})
            }
        }
    }

    const endCallLog = () => {

        
        // customer_id.current = (datas.screen_from == "pending_rm_visits") ? datas.rm_id : datas.cust_data.cust_id ? datas.cust_data.cust_id : datas.cust_id ? datas.cust_id : customer_id.current

        //    if(customer_id.current){
        var store_values =  store.getState().cs_soft_phone;
        var req = {
            screen: (store_values.screen_from) ? store_values.screen_from : screen_from ? screen_from : "soft_phone",
        }
        if (store_values && store_values.screen_from == "pending_rm_visits" || location.pathname == "/pending_rm_visits" || (store_values.rmDetails && store_values.rmDetails.is_rm) ) {
            req.rm_id =  store_values.rmDetails &&  store_values.rmDetails.is_rm ? store_values.rmDetails.id ? store_values.rmDetails.id : store_values.rmDetails.rm_details.id  : store_values.rm_id ? store_values.rm_id : rm_id
        }

        if(store_values?.lead_details?.lead_id){
            req.lead_id = store_values.lead_details.lead_id
        }

        updateCallScreen(req)
            .then((response) => {
                if (response) {
                    if (response.status == "success") {
                        dispatch(setValuesIntoRedux({ call_log_details: response.data.call_log_details, show_nums: false,rmDetails:null, lead_details:null, lead_num_verify:false}))
                        channel.postMessage({message:"end_call", data:{ call_log_details: response.data.call_log_details, show_nums: false,rmDetails:null, lead_details:null,lead_num_verify:false}})
                        // setcall_log_details(response.data)
                    }
                    else { return }
                }
            })
        // } 

    }

    const outgoingCall = (num, val) => {
        get_uncompleted_call_log()
            .then((response) => {
                if (response.status == "success") {
                    // setcall_log_details(response.data.call_log)
                    dispatch(setValuesIntoRedux({ call_log_details: response.data.call_log_details, logData: response.data,pending_cust_loader:false }))

                    channel.postMessage({message:"save_cust_values", data:{call_log_details: response.data.call_log_details, logData: response.data,pending_cust_loader:false}})

                    if (response.data.next_call) {

                        dispatch(setValuesIntoRedux({ outgoingCall: true, isCallInProcess: true, callStatus: 'calling', hide_menus: true, show_nums: false, missed_calls_view: false ,callNum:num }))
        
                        channel.postMessage({message:"outgoing_nextcall_true", data:{outgoingCall: true, isCallInProcess: true, callStatus: 'calling', hide_menus: true, show_nums: false, missed_calls_view: false , callNum:num  }})

                        // channel.postMessage({type:"set_call_values"})
                        client.current.call(num);
                        if (val && datas.missed_calls_view) {
                            complete_missed_call(val)
                        }

                    } else {

                        channel.postMessage({message:"pending_call_confirmation", data:{outgoingCall: false, pending_call_log: true,pending_call_dialog:true , pending_call_log_msg:response.data.message}})

                        dispatch(setValuesIntoRedux({outgoingCall: false, pending_call_log: true,pending_call_dialog:true , pending_call_log_msg:response.data.message}))
                        // var confirm = window.confirm(response.data.message)
                        pending_call_log_modal()

                    }

                } else { return }
            })
    }

    const pending_call_log_modal = (confirm=false) =>{
        if (confirm) {
        
            channel.postMessage({message:"outgoing_nextcall_false", data:{ CallLogModal: true, ModalIsOpen: true}})

            dispatch(setValuesIntoRedux({ CallLogModal: true, ModalIsOpen: true }))
        }
    }

    const complete_missed_call = (val) => {
        var req = {
            missed_call_id: val.id
        }
        completeMissedCall(req)
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    CallsList()
                }
            })
    }

    const CallsList = () => {
        missedCallsList()
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    dispatch(setValuesIntoRedux({ missed_calls_history: response.data }))

                }
            })
    }

    const close_modal = () => {
        dispatch(setValuesIntoRedux({ CallLogModal: !datas.CallLogModal }))
        // setCallLogModal(false)
    }

    const hangUpcall = () => {
        // IncomingCall.cancel()
        channel.postMessage({ type: 'hang_up', tabId: store.getState().HeaderSlice.header_client_tab_id , unique_msg_id:generate_unique_ids('hang_up')});
    }
    
    const is_call_accepted = () => {
        channel.postMessage({ type: 'call_accepted', tabId: store.getState().HeaderSlice.header_client_tab_id , unique_msg_id:generate_unique_ids('call_accepted')});
    }

    const is_call_rejected = () => {
        channel.postMessage({ type: 'call_rejected', tabId: store.getState().HeaderSlice.header_client_tab_id , unique_msg_id:generate_unique_ids('call_rejected')})
    }


    // const debouncedUpdate = useDebounce((newValue) => {
    //     setremarks(newValue)
    //     dispatch(setValuesIntoRedux({call_log_remarks :newValue }))
    // }, 300);

    // Handle input change
    const handleChange = ((event) => {
        dispatch(setValuesIntoRedux({ remarks: event.target.value }))

        //   debouncedUpdate(event.target.value); 
        // useDebounce((event)=>{dispatch(setValuesIntoRedux({call_log_remarks :event.target.value })); console.log("Debounce", event.target.value)}, 300)
    });

    const reason_remarks_section = ((size = true) => {
        return (
            <>
                <p className="text-white sizel mt-5 "> <b>Reasons & Remarks</b><font className="text-danger">   *  </font></p>
                
                <div>
                    <div className={`d-flex row mx-0 ${size ? "col-12 px-0" : ""}`}>
                        <div className="col-6 px-0">
                            <Select onChange={(e) => { getInputHandler(e, "call_log_reason") }}
                                // value={selected_options}
                                options={getMultiSelectOptions(`${datas.call_type}_call_purpose`)} styles={select_custom_styles()} classNamePrefix="my-select" isMulti />
                        </div>
                        <div className="col-6">
                            <div className={`col-12 w-100 h-100 p-0 mt-4 `}>
                                <textarea className='pl-3 pt-3 text-white call_log_textArea' maxlength="150" rows="5" cols="50" placeholder='Remarks'
                                    // value={remarks?remarks:""}
                                    onChange={(e) => { setremarks(e.target.value) }} style={{ background: '#4f5167' }}></textarea>
                                <div className='d-flex align-items-center row m-0'>

                                    {/* {remarks_text.current &&remarks_text.current.length==150 && 
                                                        <p className='m-0 col-10 px-0' style={{color:'#FF0000',fontWeight:'500'}}>You have reached your character limit</p>
                                            } */}
                                    {/* <p className={`text-white ${remarks &&remarks.length==150 ?'col-2' :'col-12'}  text-right m-0 px-0 d-flex justify-content-end `}>  {remarks? (remarks).length : "0"}/150</p> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`d-flex align-items-center justify-content-center  my-3 ${size ? "col-6" : "col-12"}  ${(Reason) ? "" : "disable_div"}`}>
                        <button type="button" className={`${(datas.call_log_details && datas.call_log_details.call_log_id && (Reason && Reason.length > 0) && (remarks)) ? "" : "disable_div"} btn btn-primary`} onClick={() => callLogSubmit()}>submit</button>
                    </div>
                </div>
            </>
        )
    })

    const set_customer_avatar = (type = null) => {
        return (
            <div className="row mx-0 col-12 px-0">
                <div className={`col-1 d-flex ${type ? "justify-content-center" : "justify-content-start px-0"} `}>
                    <div className=" ">
                        <IoPersonCircleSharp color="white" size={60} />
                    </div>
                </div>
                <div className="col-11">
                    <div className="d-flex justify-content-between align-items-center ">
                        {type == "existing_cust" &&
                            <div>
                                <p className="m-0 text-white text-center size_medium secondary_text_width ">  <b>{cust_info.cust_name ? cust_info.cust_name : "UNKNOWN CUSTOMER"} </b> </p>
                                <p className="my-2 sizem text-white">(Customer Since: {cust_info.customer_since})</p>
                            </div>
                        }

                        <div className={`d-flex align-items-center col-5 ${type ? "justify-content-center" : "justify-content-between"}`}>
                            <div>
                                <p className="text-end mx-3 m-0 sizel font_weight_600  text-white pointer" >{cust_info ? cust_info.cust_mobile_num : calleeNum}</p>
                                {datas.isCallInProcess &&
                                    <p className="my-1 text-white sizem text-center">({datas.callStatus == "bridged" ? <CallTimer /> : "Caling ....."})</p>}
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                {datas.isCallInProcess ?


                                    // <div onClick={() => IncomingCall.cancel()}>
                                    <div onClick={() => hangUpcall()}>
                                        <div className="bg-red p-3 mx-3 pointer border_radius_circle">
                                            <MdOutlineCallEnd size={20} />
                                        </div>
                                        <p className="m-0 text-white text-center">End Call</p>
                                    </div> :
                                    <div onClick={() => { call_handler((cust_info && cust_info.mobile_num) ? cust_info.mobile_num : calleeNum, cust_info ? cust_info.isd_code : null, null, true) }}>
                                        <div className="bg-green p-3 mx-3 pointer border_radius_circle">
                                            <FaPhoneAlt size={20} />
                                        </div>
                                        <p className="m-0 text-white text-center">Call</p>
                                    </div>
                                }


                            </div>
                        </div>


                    </div>

                </div>
            </div>
        )
    }
    const durations = ["incoming", "outgoing"]
    const fa_details = (cust_info && cust_info.current_loan) ? "current_loan" : "last_loan"
    const path = (datas.path_name == "/cs_managements/soft_phone")
    
    // const modal_condition =  (datas.call_log_details && datas.call_log_details.call_type == "outgoing") ? (datas.logData && !datas.logData.next_call) : (datas.call_log_details && datas.call_log_details.call_type !== "outgoing") ? true: (datas.logData && !datas.logData.hasOwnProperty('next_call')) ? false : (datas.logData && !datas.logData.next_call) ? true : (datas.isIncomingCall && datas.callStatus == "bridged") ? true : 
    // ((datas.incoming_call_details || datas.incomingcall_num) && !datas.outgoingCall ) ? true : false

    const modal_condition =  (datas.call_log_details && datas.call_log_details.call_type == "outgoing") ? (datas.logData && !datas.logData.next_call) : (datas.call_log_details && datas.call_log_details.call_type !== "outgoing") ? true : (datas.isIncomingCall && datas.callStatus == "bridged") ? true:((datas.incoming_call_details && datas.incoming_call_details.length>0) || datas.incomingcall_num )? true: false



    return (
        <div className="rel_position">
            {datas.pending_call_dialog && datas.pending_call_log_msg&& 
            <> 
            <ConfirmModal alert_message={datas.pending_call_log_msg} is_toggle={(a) => { toggle_modal(a) }} is_pending_call= {true}/>
            </>
            }

            <>
                {(type !== "cs_softphone") && (datas.missed_calls_view || datas.outgoingCall || datas.isIncomingCall || screen_from || ((screen_from) && (datas.isIncomingCall || (datas.outgoingCall) || (datas.callStatus == "bridged")))) ?
                    <>
                        {(!is_direct_outgoing) &&
                            <SoftphoneCallSections screen_from={screen_from} callAccepted={() => { is_call_accepted() }} callRejected={() => { is_call_rejected() }} cust_id={cust_id} call_handler={(number, isd, type) => { call_handler(number, isd, type) }} cancel_call={() => hangUpcall()} client={client}  toggle_modal={(a) => { toggle_modal(a) }} />}
                    </>
                    :

                    <>

                        <header id="header" className="card m-0 py-5">
                            <h3 style={{ margin: "0", color: "#ffff" }}>FLOW SOFT PHONE</h3>
                            <div className="header-right-sec">
                                {datas.CallDurations &&
                                    <div className="d-flex align-items-center mr-5  ">
                                        {durations.map((item, idx) => {
                                            return (
                                                <div>
                                                    {datas.CallDurations[item].duration &&
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <p className="m-0 pointer border_radius_circle bg_gray  p-2">
                                                                    {item == "incoming" ?
                                                                        <FiPhoneIncoming color="white" size={18} /> :
                                                                        <FiPhoneOutgoing color="white" size={18} />

                                                                    }
                                                                </p>
                                                                <div>
                                                                    <p className="text-white m-0 px-3 ">{datas.CallDurations[item].duration.hours ? datas.CallDurations[item].duration.hours : 0}h {datas.CallDurations[item].duration.mins ? datas.CallDurations[item].duration.mins : 0}m {datas.CallDurations[item].duration.secs ? datas.CallDurations[item].duration.secs : 0}s</p>
                                                                    <p className="m-0 text-white text-center font_weight_600">{datas.CallDurations[item].count} calls</p>
                                                                </div>
                                                            </div>
                                                            {item == "incoming" &&
                                                                <div className="soft_phone_vl mx-5"></div>}
                                                        </div>

                                                    }
                                                </div>
                                            )
                                        })}



                                    </div>
                                }
                                {csDev.status == "enabled" && 
                                <div className="mr-5 d-flex align-items-center" style={{width:"58px", height:'29px'}}>
                                    {csDev.on_break == 1 ? 
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip id="tooltip-bottom" >
                                            On Break
                                            </Tooltip>
                                        }
                                        >
                                            <div className={`clock_toggle_con toggle_on_color`}>
                                            <PiClockCountdownBold className={`clock_toggle clock_toggle_on`} onClick={handleClockToggle}/>
                                            </div>
                                        </OverlayTrigger>
                                        :
                                        <div className={`clock_toggle_con  toggle_off_color `}>
                                        <PiClockCountdownBold className={`clock_toggle clock_toggle_off`} onClick={handleClockToggle}/>
                                        </div>
                                    }
                                
                                </div>
                                }
                                <div className="mr-5 d-flex align-items-center">
                                    <div id="socket-status" className={(csDev.status && csDev.status == 'enabled' ? "ws-connected" : "ws-not-connected") + " " +"ws-status mr-1 mb-1"}></div>
                                    <p id="socket-text" className="d-inline-block text-white m-0 ">{csDev.status && csDev.status == 'enabled' ? "ASSIGNED" : "NOT ASSIGNED"}</p>
                                </div>
                                <div className="mr-5 d-flex align-items-center">
                                    <div id="socket-status" className={(datas.WsStatus || localStorage.getItem('client_tab_id') ? "ws-connected" : "ws-not-connected") + " " + "ws-status mr-1"}></div>
                                    <p id="socket-text" className="d-inline-block text-white m-0 ">{datas.WsStatus || localStorage.getItem('client_tab_id') ? "CONNECTED" : "NOT CONNECTED"}</p>
                                </div>
                                {/* <button className="btn btn-light" type="button" onClick={() =>IncomingCall.show('78144816')}>IncomingCall</button> */}
                                <button className="btn btn-light" type="button" onClick={logout}>logout</button>
                            </div>
                        </header>
                        <div className="home-body row p-10 m-0" >
                            <div className="h-left-section col-lg-4 col-md-12 col-sm-12 p-0">
                                <div id="call-section" className={`p-10 card m-0 d-flex align-items-center ${(datas.isIncomingCall || datas.outgoingCall)?"disable_div":"" }`}>
                                    <div className="w-75 col-8">
                                        <input id="receiverNum" className="form-control mt-0 rounded-pill" maxLength={10} type="text" value={calleeNum} onChange={(e) => { setNumToinput(e.target.value) }} placeholder="Enter the number to call" />
                                    </div>
                                    <div className="soft_phone_vl"></div>
                                    <div className={`${datas.isCallInProcess ? "disable" : ""}`}>
                                        <div id="dial-icon" className={(datas.isCallInProcess ? "disable" : "") + " " + "icons-holder call-icon"} type="button" onClick={() => { datas.isCallInProcess ? null : call() }} title="press to call">
                                            {/* <FontAwesomeIcon icon={faPhone} size='2x' id ="call" />  */}
                                            <FaPhoneAlt size={15} />
                                        </div>
                                        <p className="mb-0 mt-1 text-center text-white">call</p>
                                    </div>
                                </div>
                               
                                <div className="history-section  p-10 card mt-10 text-center">

                                    <form className="switch-field d-flex">
                                        <input
                                            type="radio"
                                            id="daterange_left"
                                            name="switchToggle"
                                            onChange={() => showCallLogs('All')}
                                            checked={callLogCondition === "All"}
                                        />
                                        <label htmlFor="daterange_left">All</label>

                                        <input
                                            type="radio"
                                            id="daterange_middle"
                                            name="switchToggle"
                                            onChange={() => showCallLogs('missed')}
                                            checked={callLogCondition === "missed"}
                                        />
                                        <label htmlFor="daterange_middle">Missed</label>
                                        <input
                                            type="radio"
                                            id="daterange_right"
                                            name="switchToggle"
                                            onChange={() => showCallLogs('success')}
                                            checked={callLogCondition === "success"}
                                        />
                                        <label htmlFor="daterange_right">Connected</label>
                                    </form>
                                    <div id="history-view" className="history-view mt-3 px-2">
                                        {csCallLogs && csCallLogs.length > 0 ? csCallLogs.map((call) => {
                                            if ((callLogCondition === "success" && call.call_start_time !== null) || (callLogCondition === "missed" && call.call_start_time === null && call.hangup_causes == 'NO_ANSWER') || (callLogCondition === "All")) {
                                                let call_direction = call.direction === "Inbound" ? "incoming-arrow" : "outgoing-arrow";
                                                let icon_color = call.call_start_time != null ? "text-success" : 'text-danger';

                                                // let timeDiff = showDailedTime(call.created_at);
                                                return (<div className="indivial-call-contents" onClick={() => setNumToinput(call.cust_number)} key={call.id}>
                                                    <div className="row p-0 m-0 ">
                                                        <div className={"col-2 p-0 call-direction" + " " + icon_color}>
                                                            <FaLongArrowAltUp className={call_direction + " " + "direction-arrow"} size={20} />
                                                            <FaPhoneAlt size={20} />
                                                        </div>
                                                        <div className="col-7 p-0">
                                                            <h4 className="size_medium">{call.cust_name == "unknown" ? "UNKNOWN" : call.cust_name}</h4>
                                                            <h3 className="my-0 sizem ">{call.cust_number}</h3>
                                                        </div>
                                                        <div className="col-3 p-0 d-flex align-items-center justify-content-center">
                                                            <ShowCalledTime CallDialedTime={call.created_at} />

                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>)
                                            }
                                        }) : <p className="mt-5">Empty Logs...</p>}

                                    </div>
                                </div>
                            </div>
                            <div className=" col-lg-8  col-sm-12 col-md-12 p-10 my-0 card" >
                                {cust_data && cust_data.message &&
                                    <div>
                                        {!SearchType && !datas.isIncomingCall &&
                                        <>
                                            <ConfirmModal alert_message={cust_data.message} is_toggle={(a) => { toggle_modal(a) }} is_editable_btns={true} cust_data={cust_data} />
                                            </>
                                        }


                                        {(SearchType == "continue" && (calleeNum && !call_completed)) &&
                                            <div className="px-5 mt-4 ">
                                                {
                                                    set_customer_avatar()
                                                }
                                                {
                                                    <>{reason_remarks_section()}</>

                                                }
                                            </div>
                                        }

                                        {SearchType == "search_customer" &&
                                            <div>
                                                <div className="d-flex justify-content-center col-12 mt-5">
                                                    <p className="m-0 sizem bg_light_gray text-dark px-3 py-2"><IoIosInformationCircle color="black" className="mr-1" size={18} />
                                                        The customer details for the mobile number you entered are not found. You can try searching by customer name
                                                    </p>


                                                </div>
                                                <div className="row mx-4 my-4 mt-5 col-10 d-flex justify-content-start align-items-end ">

                                                    <div className="search_input col-8">
                                                        <input id="receiverNum" className="form-control mt-0 w-100 p-3 rounded-pill" type="text" onChange={(e) => setCustName(e.target.value)} placeholder="Search by Customer Name" />
                                                    </div>
                                                    <div className="col-2">
                                                        <button type="button" class={`btn btn-primary ${loader ? "disable_div" : ""}`} onClick={() => getCustDetails(CustName, "name_filter")}>submit</button>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>

                                }
                                
                            
                             

                                
                                {datas &&datas.rmDetails && datas.rmDetails.is_rm   ?

                                <div className="row mx-0 col-12 px-0">
                                <div className={`col-1 d-flex ${"justify-content-start px-0"} `}>
                                    <div className=" ">
                                        <IoPersonCircleSharp color="white" size={60} />
                                    </div>
                                </div>
                                <div className="col-11">
                                <div className="d-flex justify-content-between align-items-center ">
                                
                                <div>
                                <p className="m-0 text-white text-center size_medium secondary_text_width ">  <b>{datas.rmDetails.rm_details? datas.rmDetails.rm_details.rm_name : datas.rmDetails.rm_name ? datas.rmDetails.rm_name:"UNKNOWN CUSTOMER"} </b> </p>
                                <p className="my-2 sizem text-white">(Relationship Manager)</p>

                                </div>


                                <div className={`d-flex align-items-center col-5 ${type ? "justify-content-center" : "justify-content-between"}`}>
                                <div>
                                <p className="text-end mx-3 m-0 sizel font_weight_600  text-white pointer" >{ (datas.rmDetails &&  datas.rmDetails.rm_details && datas.rmDetails.rm_details.rm_mob_num )?  datas.rmDetails.rm_details.rm_mob_num: calleeNum}</p>
                                {datas.isCallInProcess &&
                                <p className="my-1 text-white sizem text-center">({datas.callStatus == "bridged" ? <CallTimer /> : "Caling ....."})</p>}
                                </div>
                                <div className="d-flex mx-2 align-items-center">
                                {datas.isCallInProcess ?


                                <div onClick={() => IncomingCall.cancel()}>
                                    <div className="bg-red p-3 mx-3 pointer border_radius_circle">
                                        <MdOutlineCallEnd size={20} />
                                    </div>
                                    <p className="m-0 text-white text-center">End Call</p>
                                </div> :
                                <div onClick={() => { call_handler((cust_info && cust_info.mobile_num) ? cust_info.mobile_num : calleeNum, cust_info ? cust_info.isd_code : null) }}>
                                    <div className="bg-green p-3 mx-3 pointer border_radius_circle">
                                        <FaPhoneAlt size={20} />
                                    </div>
                                    <p className="m-0 text-white text-center">Call</p>
                                </div>
                                }


                                </div>
                                </div>


                                </div>

                                </div>
                                </div>
                                :

                                    <>
                                {((cust_info || datas.isCallInProcess && !SearchType)) ?
                                <>{cust_info &&
                                    <div className="px-5">
                                        <div className="row d-flex  align-items-center my-3">
                                            {set_customer_avatar("existing_cust")}
                                        </div>
                                        <div className="d-flex align-items-center mt-2">
                                            <div className="bg_gray p-2 px-3 soft_phone_text_width ">
                                                <p className="m-0 text_gray sizem ">No.of FAs</p>
                                                <p className="m-0 py-2 text-white  sizem text-secondary secondary_text_width">{cust_info.tot_loans ? cust_info.tot_loans : 0}</p>

                                            </div>
                                            <div className="bg_gray p-2 mx-4 soft_phone_text_width">
                                                <p className="m-0 text_gray sizem">No.of Switches</p>
                                                <p className="m-0 py-2 text-white sizem text-secondary secondary_text_width">{cust_info.tot_switch ? cust_info.tot_switch : 0}</p>

                                            </div>  <div className="bg_gray p-2 soft_phone_text_width ">
                                                <p className="m-0 text_gray sizem">Customer Status</p>
                                                <p className="m-0 py-2 text-white sizem text-secondary secondary_text_width">{dd_value(cust_info.activity_status ? cust_info.activity_status : 0)}</p>

                                            </div>
                                        </div>
                                        <div className="row col-12 mx-0 my-2 px-0">
                                            <div className="col-lg-7 px-0 col-md-12 col-sm-12">
                                                <p className="text-white sizel mt-5"> <b>Customer Info</b></p>
                                                <div className="d-flex align-items-center justify-content-between row mx-0">
                                                    {customer_details.cust_info.map((cust_datas, idx) => {
                                                        return (
                                                            <div className="col-lg-6 col-sm-12 col-md-6 my-3 pl-0">
                                                                <p className="m-0 sizem text_gray">{cust_datas.label}</p>
                                                                <p className="mb-0 mt-2 font_weight_600 sizem text-white secondary_text_width">
                                                                     { cust_info[cust_datas.key] && cust_datas.key =="cust_dob"  ? moment(cust_info[cust_datas.key]).format('DD MMM YYYY')  : 

                                                                    cust_info[cust_datas.key] ? cust_info[cust_datas.key] : "-"}
                                                                    </p>

                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="text_bottom_border my-2" style={{ width: '27vw' }}></div>
                                                <p className="text-white sizel mt-5"> <b>FA Details</b></p>
                                                <div className="d-flex align-items-center row mx-0 justify-content-between">
                                                    {customer_details && customer_details.fa_details && customer_details.fa_details.map((items, idx) => {
                                                        return (
                                                            <div className="col-lg-6 col-md-6 col-sm-12 my-3 pl-0">
                                                                <p className="m-0 sizem text_gray">{items == "loan_doc_id" ? "FA ID" : dd_value(items, items)}</p>

                                                                <p className="mb-0 mt-2 font_weight_600 sizem text-white secondary_text_width">
                                                                    {(cust_info && cust_info[fa_details] && cust_info[fa_details][items]) ?
                                                                        <p>
                                                                            <>{items == "loan_doc_id" ? cust_info[fa_details][items] :
                                                                                items == "due_date" ? format_date(cust_info[fa_details][items]) :
                                                                                    items == "status" ? dd_value(cust_info[fa_details][items]) :

                                                                                        lbl_amt(cust_info[fa_details][items], get('market').currency_code)
                                                                            }

                                                                            </>

                                                                        </p>

                                                                        : "-"}</p>

                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="text_bottom_border my-2 mt-3" style={{ width: '27vw' }}></div>

                                                <>{reason_remarks_section()}</>

                                            </div>
                                            <div className="col-lg-5 col-md-12 col-sm-12">
                                                <div className="bg_gray  px-3 py-4">
                                                    <p className="text-white sizel px-3 mb-3"><b>Previous Interaction</b></p>
                                                    {(callInteractions && callInteractions.length > 0) ?
                                                        <div id="past_churn_remarks_scroll_end  my-3" className={` col-lg-12 col-md-12 col-sm-12 m-0 p-0  ${callInteractions.length > 3 ? "overdue_call_tasks_scroll prev_interction_height" : ""}`}>
                                                            {callInteractions && callInteractions.map((item, idx) => {
                                                                return (
                                                                    <div className='callLog_prev_interactions px-4 my-3 mx-4  py-3'>
                                                                        <div className='row mx-0 mt-2' style={{ position: 'relative' }}>
                                                                            <p className='m-0 col-1 p-0'>
                                                                                <FaPhoneAlt className='call_log_icon_size ' size="20" color='white' />
                                                                            </p>
                                                                            <div className='col-10'>
                                                                                <p className='text-white m-0 '> <b>{item.caller_name}</b></p>
                                                                                <p className='text-white m-0 '> {moment(item.call_date).format("DD MMM YYYY")} | <>{item.call_dialed_time ? item.call_dialed_time + " " + (moment(item.call_dialed_time, 'hh:mm:ss')).format('A') :"-" }</></p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row mx-0 mt-2'>
                                                                            <p className='m-0 col-1 p-0'>
                                                                                <RiQuestionFill className='call_log_icon_size ' size="20" color='white' />
                                                                            </p>
                                                                            <div className='col-10'>
                                                                                <p className='text-white m-0 '> {item.call_purpose ? item.call_purpose : "-"}</p>

                                                                            </div>
                                                                        </div>

                                                                        <div className='row mx-0 mt-2'>
                                                                            <p className='m-0 col-1 p-0'>
                                                                                <BiMessageDetail className='call_log_icon_size mt-1' size="20" color='white' />
                                                                            </p>
                                                                            <div className='col-10'>
                                                                                <p className='text-white m-0 '> {item.remarks ? dd_value(item.remarks) : "-"}</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        <div>
                                                            <p className='m-0 text-white text-center py-4'>No Previous Interactions</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                 }
                                </>

                                    :
                                    <div className="row d-flex justify-content-center col-12">
                                        {loader ? 
                                        <div className="col-10 ">
                                            <div className='product_takeup_loader'></div>
                                                <p className='text-light text-center'>loading...</p>
                                        </div> :
                                            <div className="col-10">
                                                {cust_data.customer_list && cust_data.customer_list.length > 1 && cust_data.customer_list.map((item, idx) => {
                                                    return (

                                                        <div className="soft_phone_dats py-4 px-5  mt-2 mb-4">
                                                            <div className="row d-flex ">
                                                                <div className="col-1 d-flex justify-content-center">
                                                                    <div className=" ">
                                                                        <IoPersonCircleSharp color="#4f5167" size={60} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-11">
                                                                    <div className="d-flex justify-content-between align-items-center ">
                                                                        <div>
                                                                            <p className="m-0 text-dark text-center size_medium secondary_text_width ">  <b>{item.cust_name} </b> </p>
                                                                            <p className="m-0 text-secondary">(Customer Since: {item.customer_since} )</p>
                                                                        </div>
                                                                        <p className="text-end mb-0 mt-2 sizem font_weight_600 underlined_text text-info pointer" onClick={() => viewCustomerDetails(item)}>view customer</p>

                                                                    </div>
                                                                    <div className="dashed_bottom_border my-3"></div>
                                                                    <div className="row mx-0 d-flex justify-content-between">
                                                                        {mobileNumbs.map((keys, index) => {
                                                                            return (
                                                                                <>
                                                                                    {item.mob_nums[keys] &&
                                                                                        <div className="col-4 pl-0">
                                                                                            <>
                                                                                                <p className="m-0 text-secondary sizem secondary_text_width "> {dd_value(keys)}</p>
                                                                                                <div className="d-flex row mx-0 align-items-center">
                                                                                                    <p className="m-0 px-0 text-secondary sizem secondary_text_width col-7"> <b>{item.mob_nums[keys]}</b></p>

                                                                                                    <div className="col-2 px-0 ">
                                                                                                        <p className="m-0 text-secondary pointer d-flex justify-content-center  secondary_text_width p-2 border_radius_circle bg_blue" onClick={() => { call_handler(item.mob_nums[keys], item.isd_code); viewCustomerDetails(item) }}>
                                                                                                            <FaPhoneAlt color="white" style={{ height: '20px', width: '15px' }} />
                                                                                                        </p>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </>

                                                                                        </div>}
                                                                                </>
                                                                            )
                                                                        })}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })

                                                }
                                            </div>}
                                    </div>
                                }
                                    </>
                                }

                            </div>
                        </div>

                        {datas.isIncomingCall &&
                            <Draggable >
                                <div className="genric_draggable_softphone  zoomIn_animation" >
                                    <SoftphoneCallSections screen_from={screen_from} callAccepted={() => { IncomingCall.answer() }} callRejected={() => { IncomingCall.cancel() }} cust_id={cust_id} call_handler={(number, isd, type) => { call_handler(number, isd, type) }} cancel_call={() => hangUpcall()} client={client} />
                                </div>
                            </Draggable>

                        } 

                        {((datas.CallLogModal && modal_condition)) &&  
                            <div>
                                <CommonCallLogModal cust_id={(datas.call_log_details && datas.call_log_details.cust_id) ? datas.call_log_details.cust_id : "incoming_call" } call_log={datas.call_log_details && datas.call_log_details.call_log_id ? datas.call_log_details : "outgoing"} close_modal={() => close_modal()} />
                            </div>
                        }

                    </>


                }

            </>

        </div>
    )
}

export default withRouter(Softphone);