import { Box, useTheme } from "@mui/material";
import { HeaderVarientH2Typo } from "../UI/CommonComponent/typography";
import { ReactNode } from "react";

const SearchBoxContainer = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  // hook
  const theme = useTheme();

  return (
    <Box sx={{ mt: 4 }}>
      <Box
        sx={{
          backgroundColor: "#3D3E53",
          borderRadius: "5px 5px 0px 0px",
          p: 2,
        }}
      >
        <HeaderVarientH2Typo
          text={title}
          overRideStyles={{
            color: theme.palette.text.primary,
          }}
        />
      </Box>
      <Box
        //@ts-ignore
        sx={{ backgroundColor: theme.palette.custom_colors.primary_500 }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SearchBoxContainer;
