import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

// light tooltip
export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // adds custom styles for the root class of the tooltip *if want, can able to extract the global theme here
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "14px",
  },
}));
