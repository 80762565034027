import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import AuditDashboardInfoUI from "./auditDashboardInfoUI";
import DataTableLoadingOrErrorUI from "../../CommonComponent/table/dataTableLoadingOrErrorUI";
import HeaderTextUI from "./headerTextUI";
import BorderContainer from "./container/borderContainer";
import { ERROR_INFO, FETCHING_INFO, SUSPENSE_INFO } from "../../../consts";

/**
 *
 * @returns the kyc verification tab UI for suspense
 */
const KycVerficationTabFallBackUI = ({
  isError,
  isLoading,
}: {
  isError: boolean;
  isLoading: boolean;
}) => {
  // hook
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid container rowGap={2} columnSpacing={2} alignItems="stretch">
        <Grid
          item
          lg={7}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <AuditDashboardInfoUI
            text={
              isError ? ERROR_INFO : isLoading ? FETCHING_INFO : SUSPENSE_INFO
            }
            height={isMdUp ? 600 : 335}
          />
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <AuditDashboardInfoUI
            text={
              isError ? ERROR_INFO : isLoading ? FETCHING_INFO : SUSPENSE_INFO
            }
            height={isMdUp ? 600 : 335}
          />
        </Grid>
      </Grid>
      {/* table UI */}
      <Box my={2}>
        <BorderContainer>
          <HeaderTextUI text={"KYC Verification Efficiency"} />
          <DataTableLoadingOrErrorUI
            isError={isError}
            errorText={ERROR_INFO}
            loadingRows={4}
            loadingColumns={6}
          />
        </BorderContainer>
      </Box>
    </>
  );
};

export default KycVerficationTabFallBackUI;
