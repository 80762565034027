import { configureStore } from '@reduxjs/toolkit';
import CommonReducer, { rehydrate } from './Slices/CommonSlice';
import CommonSearchReducer from './Slices/CommonSearchSlice';
import HeaderSlice from './Slices/HeaderSlice';

const rootReducer = {
  Values: CommonReducer,
  HeaderSlice,
  cs_soft_phone: CommonReducer,
  CommonSearch : CommonSearchReducer,
};

const store = configureStore({
  reducer: {
    Values:CommonReducer,
    HeaderSlice:HeaderSlice,
    cs_soft_phone: CommonReducer,
    CommonSearch : CommonSearchReducer,
  },

});



// const persistor = persistStore(store);
export { store };



