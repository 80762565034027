import { useQuery } from "@tanstack/react-query";
import { ReactNode } from "react";
import { getNameList } from "../../../actions/common_actions";
import { common_req } from "../../../helpers/common_helper";
import { RhfObjTypeOptionProp } from "../../../type";
import RHFAutocomplete from "../rhfComponents/rhfAutoComplete";
import { ERROR_SHORT_INFO, FETCHING_SHORT_INFO } from "../../../consts";

type RMsGetResProps = { data: RhfObjTypeOptionProp[] };

const RMsListAutoComplete = ({
  name,
  label,
  endIcon,
  actAs,
}: {
  name: string;
  label?: string;
  endIcon?: ReactNode;
  actAs: "dd" | "filter";
}) => {
  // const
  const reqObj = common_req({} as any);

  // query
  const { data: rmSList, isError } = useQuery<RMsGetResProps>({
    queryKey: ["list_of_rms"],
    queryFn: () =>
      getNameList(
        "rel_mgr",
        {
          ...reqObj,
          associated_with: "flow",
          status: "enabled",
        },
        "kyc"
      ),
    staleTime: 1000 * 60 * 5,
  });

  // options
  const rmSOptions: RhfObjTypeOptionProp[] = [
    { name: "All (Rms)", id: "all" },
    ...((rmSList &&
      rmSList?.data
        .map((l) => ({
          name: l.name, // Split the name and take the first part
          id: l.id,
        }))
        .sort(
          // sorting by alphabetic asc
          (a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        )) || [
      {
        name: isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO,
        id: isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO,
      },
    ]),
  ];

  return (
    <RHFAutocomplete
      name={name}
      label={label!!}
      options={rmSOptions}
      sx={{
        // overides the end icon style
        "& .MuiButtonBase-root": {
          ...(actAs === "filter" && { transform: "none" }),
          svg: {
            width: "20px",
            height: "20px",
            color: (theme) =>
              // @ts-ignore
              theme.palette.custom_colors.secondary_a_200,
          },
        },
      }}
      popupIcon={endIcon!!}
      getOptionLabel={(option: RhfObjTypeOptionProp | string) =>
        typeof option === "object" ? option.name : option
      }
      getOptionKey={(option: RhfObjTypeOptionProp | string) =>
        typeof option === "object" ? option.id : option
      }
      isOptionEqualToValue={(option, value) =>
        typeof option === "object" && typeof value === "object"
          ? option.id === value.id
          : option === value
      }
      disableClearable
    />
  );
};

export default RMsListAutoComplete;
