import { IoCloseCircleOutline } from "react-icons/io5";
import React from "react";
import { Tooltip } from "react-tooltip";
import { GiWhiteBook } from "react-icons/gi";
import { BsArrowRightShort, BsDot } from "react-icons/bs";
import moment from "moment";
import { Link } from "react-router-dom";
import {useEffect, useRef} from 'react'
import { find_notification_time } from "../../helpers/common_helper";

const Notification = ({props, handleBell, handle_modal, set_portfolio_details, change_read}) => {

  let show_notification = props.show_notification
  let notification_arr = props.notification_arr
  let loader = props.loader
  let no_notification = props.no_notification
  


  return (
    show_notification === true ? (
      <div style={{position:'absolute', zIndex:1000, top:'70px', right:'85px', width:'22%', backgroundColor:'#12012E', padding:'2%', borderRadius:'20px'}} onMouseLeave={()=>handleBell(false)}>
        <div className="row d-flex align-items-center p-2">
          <div className="col-md-auto text-white" style={{fontSize:'20px', fontWeight:400}}>
            Notifications
          </div>
          <div className="col-md-auto ml-auto">
            <span data-tooltip-id="notification-element" style={{cursor:'pointer'}} data-tooltip-content="close"><IoCloseCircleOutline size={25} color="white" onClick={()=>handleBell(false)}/></span>
            <Tooltip  id="notification-element"  style={{zIndex:1000, color:'white'}}/>
          </div>
        </div>
        <div className="notification-scroll">
        {loader==true ?
         <div className='product_takeup_loader' style={{marginTop:'21vh'}}></div>
         :no_notification == true ? <div className="d-flex justify-content-center" style={{marginTop:'20vh'}}><p className="m-0" style={{color:'white', fontSize:'15px'}}>No Notifications </p></div>
         :notification_arr && notification_arr.length>0 ? 
         notification_arr.map((notification)=>{
         
           return(
             <div className="card notification-content" key={notification.id}  style={{opacity:`${notification.read==false?'1':'0.6'}`, fontWeight:400}} onClick={()=>{set_portfolio_details(notification)}}>
        

               <h4 className="card-text " style={{fontSize:'15px'}}>{notification.message_content.title}</h4>
              {notification.type=="flagged_customers" ||  notification.type=="duplicate_disbursal_alert" &&
               <p className="text-white m-0">{notification.message_content.body}</p>
               }
               <span style={{position: "absolute",bottom: "4px",right: "27px", color:'white'}}>{find_notification_time(notification.created_at)}</span>

               {notification.read==false &&<span style={{position:'absolute' , top:'2px',right:'4px'}}><BsDot color='red' size={25}/></span>}
               
            
           </div>
           )
           

         })
         :
         no_notification==true?
         <p className='text-light text-center' style={{marginTop:'21vh'}}>No Data Found </p>
         :
         ''
         
         }
          
        </div>
        {!no_notification && !loader && notification_arr.length>4 &&
            <div  className="d-flex justify-content-end" style={{paddingTop:'25px' ,cursor:'pointer'}} >
            <Link style={{textDecoration:'none'}} to={`/view_all_notifications`} ><div className="col-md-auto " style={{color:"white", fontSize:'16px', fontWeight:400}} onClick={()=>{handle_modal(false); handleBell(false)}}>
              View All <BsArrowRightShort/>
            </div></Link>
        </div>
        
        }
         </div>
    ) : null
  );
};

export default Notification;