import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {dd_value} from '../../../actions/common_actions';
import {capitalize_first_letter, format_date,lbl_amt,Homeacc_prvdrLogoselector} from '../../../helpers/common_helper';
import FlowContainer from '../../common/container/core/FlowContainer';
import _ from 'lodash';
import {getMasterData} from '../../../actions/common_actions';
import CommonReactTable from '../../../helpers/commonReact_table';
import ProductUpgrade from '../component/product_upgrade';
import {Card,Button} from 'react-bootstrap';
import { getProductUpgrades } from '../../../actions/loan_product_actions';
import { format } from 'date-fns';
import { BasicReactTable } from '../../../helpers/react_table';

class SearchProductUpgrades extends FlowContainer{

    state ={
        columns :[],
        upgr_list:[],
        copy :[],
        prdt_upgr_list:{},
        prdt_upgr_table:"hide_div",
        csv_data: []
        
    }

    componentWillMount(){

        this.handleCurDate()
    }

    handleCurDate = () =>{

        const date = new Date()
        const cur_date = format(date, 'yyyy-MM-dd')
        const prdt_upgr_list = this.state.prdt_upgr_list
        prdt_upgr_list['start_date'] = cur_date
        prdt_upgr_list['end_date'] = cur_date
        this.setState({prdt_upgr_list})
        this.handleSearch()

    }
    handleUpperCase = event => {
        if(event != null){
            const result = event.toUpperCase();
            const str_replace = result.replace(/_/g," ") 
            return str_replace
        }else{
            const str_replace = event
            return str_replace
        }   
    }
    handlePurposeChange = (selected_options,id) =>{
         this.setMultiselectOptionOnState(selected_options,id,this.state.prdt_upgr_list)
    }

    handleStatus = (event) => {
        let value =  event.target.value
        if(value != "approved"){
            this.setState({prdt_upgr_list : {...this.state.prdt_upgr_list, amt_rang : null}})
        }
    }

    getDataForCsv = (columns, data) => {
        let csv_headers = columns.map(col => col.id)
        let csv_data = data.map(row => columns.map(
            (col) => {
                if(typeof(col.accessor) == 'string') return row[col.accessor]
                else{
                    let val =  col.accessor(row)
                    if (typeof(val) == 'object') return val.props.children
                    return val
                } 
                    
            }
        ))
        return [csv_headers, ...csv_data]
    }

    handleSearch =(event)=>{
        this.setState({loader : true, upgr_list : [],no_data:false})
        let search_criteria = this.state.prdt_upgr_list
        if(search_criteria )
        getProductUpgrades(this.req({prdt_upgr_search_criteria: this.state.prdt_upgr_list}))
			.then((response) => {
				this.resp(response)
				if(!response){this.setState({loader : false, no_data : true}) 
                return };
				    if(response.status === "success"){
                        this.setState({loader : false})
                            this.resp(response)
                            var columns = this.state.columns
                           
                                columns = [
                                            // {  
                                            // Header : "Account Prvdr",
                                            // id: "acc_prvdr_code",
                                            // sortable:false,
                                            // accessor:'acc_prvdr_code' ,
                                            // Cell : (cell)=><div className='d-flex align-items-left'> <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code}/> </div>
                                            // },
                                            {  
                                                Header: 'Cust ID', 
                                                id: 'cust_id',
                                                accessor: 'cust_id',
                                            },{  
                                                Header: 'FA before Upgrade Req',  
                                                id :'crnt_fa_limit',
                                                accessor : row =>row.crnt_fa_limit == 0 ? "NA" : lbl_amt(JSON.parse(row.task_json).crnt_fa_limit, this.currency_code),
                                               
                                            },{  
                                                Header: 'Requested Amount',  
                                                id :'requested_amount',
                                                accessor : row =>row.requested_amount == 0 ? "NA" : lbl_amt(JSON.parse(row.task_json).requested_amount, this.currency_code)
                                            },{  
                                                Header: 'Status',  
                                                id :'status',
                                                accessor : row =>capitalize_first_letter(row.status)
                                            },{  
                                                Header: <p className='m-0 text-center'>Upgraded Amount</p>,  
                                                id :'upgrade_amount',
                                                accessor : row => {return (<p className='m-0 text-center'>{row.upgrade_amount == 0 ? "NA" : lbl_amt(JSON.parse(row.task_json).upgrade_amount, this.currency_code)}</p>)}
                                            },{  
                                                Header: 'Requested At',  
                                                id :'created_at',
                                                accessor: row =>format_date(row.created_at, false,"dd-MM-yy") ,
                                            },{  
                                                Header: 'Approver',  
                                                id :'approver',
                                                accessor: 'approver_name' ,
                                            },{  
                                                Header: 'Action Taken At',  
                                                id :'updated_at',
                                                accessor: row =>format_date(row.updated_at, false,"dd-MM-yy") ,
                                            },{
                                                Header: 'Availed Upgrade',  
                                                id :'next_upgraded_fa',
                                                accessor: row => {return row.loan_doc_id ? (<a href = {`/fa/view/${row.loan_doc_id}`}>{row.loan_doc_id}</a>) : '-'},
                                            },{
                                                Header: 'Availed Upgrade On',  
                                                id :'next_upgraded_fa_date',
                                                accessor: row => row.disbursal_date ? format_date(row.disbursal_date, false,"dd-MM-yy") : '-' ,
                                            }]
                        }
                        let csv_data = this.getDataForCsv(columns, response.data)
                        this.setState({columns,upgr_list:response.data,prdt_upgr_table:"show_div", copy : response.data, csv_data}) 

                                  
            })
                  
    }
                    
    render(){
        return(
    
            <div className="container containerTopmargin max-width no-padding">            
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Search Product Upgrades</h4></Card.Header>
                            <Card.Body style={{padding:15}}>
                                <ProductUpgrade  id="prdt_upgr_list" onComponentChange={this.handleComponentChange} status = {this.state.prdt_upgr_list.status} handlePurposeChange={this.handlePurposeChange} handleStatus={this.handleStatus}/>   
                                <div className="form-group row reset-margin justify-content-center py-4">
                                    <Button disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary`} onClick={this.handleSearch}>Search</Button>
                                </div>
                            </Card.Body>
                    </Card>
                </form>
                {this.state.upgr_list.length > 0 && 
                    <div className={`mt-3 ${this.state.prdt_upgr_table}`} >	
                    <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>					
                        {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.upgr_list !== undefined ? this.state.upgr_list : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"Product Upgrades"} csvData={this.state.upgr_list ? this.state.upgr_list : []}
                        searchData={[{...this.state.prdt_upgr_list,title:'Search Product Upgrades'}]} />    
                        	 */}
                        <BasicReactTable row_data={this.state.upgr_list !== undefined ? this.state.upgr_list : []} column_data={this.state.columns}  pagination={this.state.upgr_list.length > 10} default_page_size = {10} csv_file={true} searchData={[{...this.state.prdt_upgr_list,title:'Search Product Upgrades'}]} FName={"Product Upgrades"}  export_csv_datas={this.state.csv_data ? this.state.csv_data : []} />  
                        				
                    </div>
                }
                 {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
                {this.state.loader&&
                <>
                    <p className='text-light  text-center' style={{marginTop:'15vh'}}>Loading...</p>
                    <div className="product_takeup_loader"></div>
                </>
                }
            </div>
        )
    }
}
           
            

export default SearchProductUpgrades