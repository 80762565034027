import { createSlice } from '@reduxjs/toolkit';

const initialState = {

}

const CommonSearchReducer = createSlice({
    name : 'common_search_reducer',
    initialState,

    reducers : {
        setScreachedFields : (state, action) => {
            const {key, value} = action.payload
            state[key] = value
        }
    }
})

export const {setScreachedFields} = CommonSearchReducer.actions;
export default CommonSearchReducer.reducer;