import React, {useEffect, useState} from "react";
import {get,Config} from "../../../helpers/storage_helper";
import {getCurrencyDetails, getForexRates, getManagementDashboardReport} from "../../../actions/report_actions";
import {listAccountProvider} from "../../../actions/account_provider_actions";
import {DateFormatter} from "../../../helpers/common_helper";
import {Link} from "react-router-dom";
// import Flag from "react-flags";
import {Button, Form} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { IoIosGlobe } from "react-icons/io";
import moment from "moment";
import {listMarkets} from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import { BiCalendar } from "react-icons/bi";
import Toggle from "../../common/component/Toggle";
import LenderCode from "./LenderCode";
import CustomFlag from "../../common/component/CustomFlag";



export default function ChartSelectionHeader({set_attr, showMonthRange, default_start_date="" , default_end_date="" ,show_currency=true ,show_global=true, showSubmitbtn,onsubmit,submitbtnenable,acc_prvdr_changed,LenderCodes = null,showOnlyCurrency = false,onMonthChange=false,selected_country = "*"}) {

    const [data, setdata] = useState([])
    const [startDate, setStartDate] = useState( default_start_date)
    const [endDate, setEndDate] = useState(default_end_date)
    const [cursorPointerStatus,setcursorPointerStatus] = useState(true)
    const [marketCodes,setmarketCodes] = useState([])
    const [market_list,setmarket_list]=useState([])
    const [current_country,setcurrent_country] = useState(null)
    const [country, setcountry] = useState({
        
        country: show_global ? 'Global' : get('market').country,
        country_code: show_global ? "*":  get('market').country_code,
        showlist:show_global ? false : true 
    
    })
    const [currentCountryactiveCurrency, setcurrentCountryactiveCurrency] = useState({
        active_currency:''
    })
    const [activeCurrency, setactiveCurrency] = useState({
        currency_change:false,
        active_currency:'',
        active_currency_val:''
    })
    const [showaccList,setshow] = useState(true)
    const [currencyDetails,setcurrencyDetails] = useState({})
    const[active_acc_prvdr,setactive_acc_prvdr]=useState(null)
    const[token,settoken]=useState()
    const[dateWiseFilter,setdateWiseFilter]=useState(false)
    const[lender,setLender] = useState(null)
    const[switch_market_list,switchCountries] = useState([])

    
    useEffect(() => {if (dateWiseFilter !== undefined) 
      {
        const months = dateWiseFilter ? 1 : 6;
        handleButtonClick(months);
      }}, [dateWiseFilter]);

    const calculateMonthDifference = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
      };
    
      const getInitialDateRange = () => {
        const months = dateWiseFilter ? 1 : 6;
        const currentDate = new Date();
        const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - months));
        const endDate = new Date();
        
        return {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          month: months
        };
      };
    
      const [dateRange, setDateRange] = useState(getInitialDateRange());
    
      const handleButtonClick = (months) => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - months));
        const endDate = new Date().toISOString().split('T')[0];
    
        setDateRange({
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate,
          month: months
        });
    
        setStartDate(startDate.toISOString().split('T')[0]);
        setEndDate(endDate);
      };
      

    useEffect(() => {
        setStartDate(default_start_date);
        setEndDate(default_end_date);
      }, [default_end_date,default_start_date]);
      
    useEffect(() => {
        setcountry({

            ["country"] :show_global ? "Global" : get('market').country ,
            ["country_code"] :  show_global ? "*" : get('market').country_code ,
            ['showlist'] : show_global ? false :true

        })
        setactiveCurrency({
            ["currency_change"] : get('market').country_code === "UGA"  || get('market').country_code === "RWA" ? false : true ,
            ["active_currency"] : get('market').currency_code,
            ["active_currency_val"] : ''
        })
        const req = {"time_zone":get('market').time_zone}
    
    
        getCurrencyDetails(req)
            .then((response)=>{
                if(!response){return ""}
                if(response.status === "success"){
                    setdata(response.data)
                    const currency_details = response.data
                    setcurrencyDetails(currency_details)
                    settoken(Math.floor(Math.random() * 100) + 1)
                    setcurrentCountryactiveCurrency({
                        ["active_currency"] :currency_details[get('market').country_code].currency_code
                    })
                }
            })

        var request = {country_code: country.country_code, time_zone: get('market').time_zone, status: ""};
        listAccountProvider({...request, status: 'enabled', biz_account: true})
            .then((response)=>{
                if(!response){return };
                if(response.status === "success"){
                    let acc_prvdrs = []
                    response.data.list.map(each=>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                }
            });
          
    }, [setdata,listAccountProvider])
    
    useEffect(()=>{
        if(Config("switch_supported_countries")){
          switchCountries(Config("switch_supported_countries"))
        }
        if(token){
        setCurrencyHandler("USD")
        }

        if(get('market_list')){
            setmarket_list(get('market_list'))


        }else if(market_list.length == 0){
            listMarkets().then((response)=>{
                if(!response){return}
                if(response){
                 setmarket_list(response.data)
                 get_country_name(response.data)
                }
            }) 
        }
    },[token, country,current_country])
    
    

    const get_country_name = (data) => {
      const current_country = data.filter((item,idx)=>{ return item.country_code == country.country_code})
       setcurrent_country(current_country)
    }
    
    const countrySelecthandler = (countryCode,countryName) => {
        if(countryCode == "*"){
            setcountry({["country"] : countryName,["country_code"] : countryCode,['showlist']:false})
        }else{
            setcountry({["country"] : countryName,["country_code"] : countryCode,['showlist']:true})
        }

        setshow(false)
        setLender(null)
        setcursorPointerStatus(true)
        setactive_acc_prvdr(null)
        if(countryCode!="*"){
           
           if( currencyDetails[countryCode]){
                setactiveCurrency({
                    ['currency_change'] : countryCode == country.country_code ? false : true ,
                    ["active_currency"] : currencyDetails[countryCode].currency_code,
                    ["active_currency_val"] : ''
                })
                const currentCountryactiveCurrency = currencyDetails[countryCode].currency_code
                setcurrentCountryactiveCurrency({
                    ["active_currency"] : currentCountryactiveCurrency
                })
            }
        }
        else{
            
            setactiveCurrency({
                ['currency_change'] : countryCode == country.country_code ? false : true ,
                ["active_currency"] : 'UGX',
                ["active_currency_val"] : ''
            }) 
        }
        setCurrencyHandler("USD")
        var req = {country_code: countryCode, time_zone: countryCode === "UGA" ? "EAT" : "CAT", status: ""};
        listAccountProvider({...req, status: 'enabled', biz_account: true})
            .then((response)=>{
                if(!response){return };
                if(response.status === "success"){
                    let acc_prvdrs = []
                    response.data.list.map(each=>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                }
            });
      
        
    }

    
    const handleStartDate = (value) => {
        const formattedStartDate = moment(value).format("YYYY-MM-DD");
        setStartDate(formattedStartDate);
        const updatedDateRange = {
          ...dateRange,
          startDate: formattedStartDate,
          month: calculateMonthDifference(formattedStartDate, dateRange.endDate)
        };
        setDateRange(updatedDateRange);
      };
    
      const handleEndDate = (value) => {
        const formattedEndDate = moment(value).format("YYYY-MM-DD");
        setEndDate(formattedEndDate);
        const updatedDateRange = {
          ...dateRange,
          endDate: formattedEndDate,
          month: calculateMonthDifference(dateRange.startDate, formattedEndDate)
        };
        setDateRange(updatedDateRange);
      };
    
      const handlRangeClearBtn = () => {
        const defaultStartDate = moment().subtract(dateWiseFilter ? 1 : 6, 'months').format("YYYY-MM-DD");
        const defaultEndDate = moment().format("YYYY-MM-DD");
    
        setDateRange({
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          month: calculateMonthDifference(defaultStartDate, defaultEndDate)
        });
    
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
      };
    
    
    const setCurrencyHandler = (curren) => {
        if(country.country_code !="*" &&  currencyDetails[country.country_code]){
            if(curren === "USD" || curren === "EUR" ){
                setactiveCurrency({
                    ["currency_change"] : true,
                    ["active_currency"] : curren,
                    ["active_currency_val"] : currencyDetails[country.country_code].forex_rates[curren]
                })
            }
            else if (curren === "UGX" || curren === "RWF") {
                setactiveCurrency({
                    ["currency_change"] : false,
                    ["active_currency"] : curren,
                    ["active_currency_val"] : ""
                })
            }
           
        }else{
           if(currencyDetails &&  currencyDetails[country.country_code]){
            setactiveCurrency({
                ["currency_change"] : true,
                ["active_currency"] : curren,
                ["active_currency_val"] : currencyDetails["UGA"].forex_rates[curren]
            })
        }
 
        }
       
    }
      
  const handleLenderCode = (res) =>{
    setLender(res.value)
  }
     
        set_attr({country_code : country.country_code, activeCurrency : activeCurrency.active_currency, activeCurrencyVal : activeCurrency.active_currency_val, country : country.country, startDate , endDate,gds_report_active_accPrvds:active_acc_prvdr , showlist:country.showlist, showaccList:showaccList,dateWiseFilter,lender:lender,switch_countries:switch_market_list ?? [],market_countries:market_list.length>0 ? market_list.map(country => country.country_code):[]});

        return (
            <>
            <div>
            <div className={`m-0 col p-0  d-flex align-items-end   justify-content-start`}  style={{width:'100%'}}>
             
             
              {!showOnlyCurrency &&<>
              
                <div className="col-md-auto">
                    <label className='no-margin'>Market</label>
                                
                    <div className=" m-0 dropdown country_dropDownbg  p-0 " style={{width:'180px',backgroundColor:'#3149A1'}} >
                                    
                        <div className="dropdown-toggle d-flex align-items-center dropdown_trig justify-content-between" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {country.country_code !="*" ? 
                            <>
                            <CustomFlag country_code={country.country_code} alt={country.country_code} height={30} width={30}/>
                            <span className="small_rem" style={{color:"white"}}>{country.country ? country.country : ((current_country !=null) ? current_country[0].country :'')}</span>
                            <span style={{color:"white"}}><i className="fa-solid fa-chevron-down"></i></span>
                            </> 
                            :
                            <>
                            <IoIosGlobe size={20} style={{margin:'4px 0px'}} color="white"/>
                            <span className="text-white small_rem">{country.country}</span>
                            <span style={{color:"white"}}><i className="fa-solid fa-chevron-down" ></i></span> 
                            </>
                            }                
                        </div>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:190,zIndex:2}}>
                            {show_global &&
                                <li className="country_selector" onClick={()=>countrySelecthandler("*","Global")}>
                                    <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                    <span className="small_rem" style={{paddingLeft:15 }}>Global</span>
                                </li>
                                }
                                { market_list &&  market_list.map((each,idx)=>
                                    <li key={idx} className="country_selector" onClick={()=>countrySelecthandler(each.country_code,each.country)}>
                                      <CustomFlag country_code={each.country_code} alt={each.country_code} height={30} width={30}/>
                                        <span className="small_rem" style={{paddingLeft:5}}>{each.country}</span>
                                    </li>
                                )}

                            </ul>
                    </div>
                </div>
                {LenderCodes &&
                    <div className=" pr-2 p-0 col-md-auto date_picker_label" style={{position:'relative'}}>
                        <LenderCode country_code={country.country_code} onChange = {(res)=>handleLenderCode(res) } width ="170px" />
                    </div>
                }
                {showMonthRange && (
        <div className={'col-md-auto no-padding d-flex align-items-center  mr-3 ml-3'} style={{ marginTop: (country.country_code === '*' || activeCurrency.active_currency === currentCountryactiveCurrency.active_currency) ? '17px' : '' }}>
          <div className="" style={{ border: '1px solid #3149A1' }}>
            <div className='manDash_currencySelector justify-content-between align-items-center' style={{ borderRadius: '1px' }}>
              <div
                className='border_left'
                onClick={() => handleButtonClick(dateWiseFilter ? 6 : 12)}
              >
                <div
                  className='no-margin px-3 py-2'
                  style={{
                    backgroundColor: dateRange.month === (dateWiseFilter ? 6 : 12) ? '#3149A1' : '',
                    borderRight: dateRange.month === (dateWiseFilter ? 6 : 12) ? '1px solid #3149A1' : '',
                    borderRadius: '0px',
                    color: 'white',
                    fontSize: '13px',
                    fontWeight: 400,
                    cursor: 'pointer',
                    borderBottom: '1px solid #3149A1'
                  }}
                >
                  {dateWiseFilter ? '6 months' : '1 year'}
                </div>
              </div>
              <div onClick={() => handleButtonClick(dateWiseFilter ? 3 : 6)}>
                <div
                  className='no-margin px-3 py-2 small_rem'
                  style={{
                    backgroundColor: dateRange.month === (dateWiseFilter ? 3 : 6) ? '#3149A1' : '',
                    borderLeft: dateRange.month === (dateWiseFilter ? 3 : 6) ? '1px solid #3149A1' : '',
                    borderRight: dateRange.month === (dateWiseFilter ? 3 : 6) ? '1px solid #3149A1' : '',
                    borderRadius: '0px',
                    color: 'white',
                    fontSize: '13px',
                    fontWeight: 400,
                    cursor: 'pointer',
                    borderBottom: '1px solid #3149A1'
                  }}
                >
                  {dateWiseFilter ? '3 months' : '6 months'}
                </div>
              </div>
              <div className='border_right' onClick={() => handleButtonClick(dateWiseFilter ? 1 : 3)}>
                <div
                  className='no-margin px-3 py-2 small_rem'
                  style={{
                    backgroundColor: dateRange.month === (dateWiseFilter ? 1 : 3) ? '#3149A1' : '',
                    borderLeft: dateRange.month === (dateWiseFilter ? 1 : 3) ? '1px solid #3149A1' : '',
                    borderRadius: '0px',
                    color: 'white',
                    fontSize: '13px',
                    fontWeight: 400,
                    cursor: 'pointer',
                    borderBottom: '1px solid #3149A1'
                  }}
                >
                  {dateWiseFilter ? '1 month' : '3 months'}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
                {showMonthRange &&
                    <div className="d-flex  m-0" style={{ marginTop: "-16px", paddingLeft: "5px" }}>
                    <div className="col-md-5 px-sm-3 px-lg-0 px-md-0">
                    <label className="text-white no-margin small_rem">{dateWiseFilter ? 'Start Date' : 'Start Month'}</label>
                    <div className="form-group monthly_report_input col-md-12 col-lg-12 col-sm-12 px-2 py-0 m-0 d-flex align-items-center churn_report_date_picker">
                        <label className="col-1.5 no-margin d-flex p-0" style={{ position: 'static' }} htmlFor="churn_report_start_date">
                        <p className="m-0 pr-1"><BiCalendar size={20} /></p>
                        </label>
                        <div className="col px-2 py-1 pr-0 date_picker_label">
                        <DatePicker
                            value={startDate ? moment(startDate).format(dateWiseFilter ? 'DD MM YYYY' : 'MMMM YYYY') : ''}
                            onChange={(value) => handleStartDate(moment(value).format("YYYY-MM-DD"))}
                            dateFormat={dateWiseFilter ? "DD MM YYYY" : "MMMM yyyy"}
                            timeFormat={false}
                            style={{ maxWidth: "200px" }}
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-md-5 px-3">
                    <label className="text-white no-margin small_rem">{dateWiseFilter ? 'End Date' : 'End Month'}</label>
                    <div className="form-group monthly_report_input col-md-12 col-lg-12 col-sm-12 px-2 py-0 m-0 d-flex align-items-center churn_report_date_picker">
                        <label className="col-1.5 no-margin d-flex p-0" style={{ position: 'static' }} htmlFor="report_end_date">
                        <p className="m-0 pr-1"><BiCalendar size={20} color="#ACACAC" /></p>
                        </label>
                        <div className="col p-0 py-1 pr-0 date_picker_label">
                        <DatePicker
                            value={endDate ? moment(endDate).format(dateWiseFilter ? 'DD MM YYYY' : 'MMMM YYYY') : ''}
                            onChange={(value) => handleEndDate(moment(value).format("YYYY-MM-DD"))}
                            dateFormat={dateWiseFilter ? "DD MM YYYY" : "MMMM yyyy"}
                            timeFormat={false}
                            style={{ maxWidth: "200px" }}
                        />
                        </div>
                    </div>
                    </div>
                      <div className=" col-md-2  p-0 d-flex justify-content-center align-items-end" style={{ marginTop: (country.country_code === '*' || activeCurrency.active_currency === currentCountryactiveCurrency.active_currency )? '17px' : '' }} >
                            <div className="">
                                {(startDate !== "" || endDate !== "") &&
                                    <>
                                    {showSubmitbtn?
                                    <button data-tip data-for="Submit" disabled={!submitbtnenable} className={"btn btn-sm btn btn-info  align-items-center no-margin "} onClick={()=>onsubmit()}>Submit</button>
                                    :
                                    <button data-tip data-for="dateRangeClear" className={'grey_button px-3 py-2'} onClick={() => handlRangeClearBtn()}>Reset</button>
                                    }                        
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }
                {country.country_code !="*" &&
                <div  className={'col-md-auto no-padding d-flex align-items-center ml-auto '}>
                           {show_currency &&                            
                            <div style={{border:'1px solid #3149A1' }}>
                                <div className='manDash_currencySelector  justify-content-between align-items-center' style={{borderRadius:'1px'}}>
                                    
                                    <div className='border_left' style={{ }} onClick={() => setCurrencyHandler('USD')}>
                                        <div className='no-margin small_rem px-3 py-2' style={{backgroundColor:activeCurrency.active_currency === "USD" ? '#3149A1' : '',borderRight:activeCurrency.active_currency === "EUR" ? '1px solid #3149A1' : '',borderRadius:'0px',color:activeCurrency.active_currency === "USD" ? 'white' : 'white',fontSize:'13px',fontWeight:400,cursor:'pointer',borderBottom:'1px solid #3149A1'}}>USD</div>
                                    </div>
                                    <div style={{}}  className='' onClick={() => setCurrencyHandler('EUR')}>
                                        <div className=' no-margin px-3 py-2 small_rem' style={{backgroundColor:activeCurrency.active_currency === "EUR" ? '#3149A1' : '',borderLeft:activeCurrency.active_currency === "USD" ? '1px solid #3149A1' : '',borderRight:activeCurrency.active_currency === currentCountryactiveCurrency.active_currency ? '1px solid #3149A1' : '',borderRadius:'0px',color:activeCurrency.active_currency === "EUR"  ? 'white' : 'white',fontSize:'13px',fontWeight:400,cursor:'pointer',borderBottom:'1px solid #3149A1'}}>EUR</div>
                                    </div>
                                    <div style={{ }} className='border_right ' onClick={() => setCurrencyHandler(currentCountryactiveCurrency.active_currency)} >
                                        <div className='no-margin small_rem px-3 py-2' style={{backgroundColor:activeCurrency.active_currency === currentCountryactiveCurrency.active_currency ? '#3149A1' : '',borderLeft:activeCurrency.active_currency === "EUR" ? '1px solid #3149A1' : '',borderRadius:'0px',color:activeCurrency.active_currency === currentCountryactiveCurrency.active_currency  ? 'white' : 'white',fontSize:'13px',fontWeight:400,cursor:'pointer',borderBottom:'1px solid #3149A1'}}>{currentCountryactiveCurrency.active_currency}</div>
                                    </div>
                                </div>
                                <div className={`col d-flex align-items-center justify-content-center`}style={{margin: activeCurrency.active_currency === currentCountryactiveCurrency.active_currency ? '-9px 0px': '10px 0px'}}>
                                    <p className='no-margin text-white small_rem' style={{fontWeight:600}}>{activeCurrency.active_currency_val === "" ? ""  : `1 ${activeCurrency.active_currency} =`}&nbsp;</p>
                                    <p className='no-margin text-white small_rem' style={{fontWeight:600}}>{activeCurrency.active_currency_val === "" ? ""  : Number(1/activeCurrency.active_currency_val).toFixed(0)}&nbsp;{activeCurrency.active_currency_val === "" ? ""  : currencyDetails[country.country_code].currency_code}</p>
                                </div>
                            </div>
                            }
                </div>
                }

                </>
}     
            </div>

            <div className="d-flex ">
            <div className=" m-0 col p-0 row d-flex  justify-content-start chart_radio_font   ">
                <div className=" mt-4 mr-2 w-80" >
                    <Toggle perWidth="90px" id='day_to_month'  leftLabel= {"Day wise"} rightLabel={"Month wise"} fontSize="15" aligntext={'start'} paddingSize ={'0'} marginsize = {'0'} val={!dateWiseFilter} fromChart={false} set_tog_val={(res) => setdateWiseFilter(res)} fontFamily={'Montserrat'} chartfield = {true}/>
                </div>
            </div>
            </div>
            </div>
        </>
    )

    }
