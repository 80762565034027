import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {Alert,Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import CreateAgreement from './CreateAgreement';
import Agreement from '../../borrower/component/Agreement';
import {check_priv, get} from '../../../helpers/storage_helper';
import {format_date, getValueFromLangJson, get_path} from '../../../helpers/common_helper';
import {percent_rnd} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import {title_case} from '../../../helpers/common_helper';
import Sms_modal from '../../loan/container/Sms_modal';
class CustPanel extends FlowComponent {
	state = {
		variant: "primary"
	};
	
	componentDidMount(){
		if(this.props.parent === 'apply' || this.props.parent === 'selection' ){
			this.setState({variant: 'dark'})
		}else if(this.props.parent === 'loan_appl'){
			this.setState({variant: 'success'})
		}else if(this.props.parent === 'loan'){
			this.setState({variant: 'primary'})
		}

	
	}

	/*handleAgreementLinkClick = () => {
		if(this.props.customer.account_id == null){
			this.setstate({link_clickable : false});
			alert("No Account Configured for Customer");
			return;
		}	
	}*/



Customer = () => (
				<>	
					{/* <Alert.Heading>
					<b>Customer : <Link to={"/borrower/indiv/view/"+this.props.customer.cust_id} ><span style = {{fontSize : "17px"}}>{this.props.customer.cust_id}</span></Link></b>
					</Alert.Heading>
					<Row>
						<Col>Business Name </Col>
						<Col as='b'>{this.props.customer.biz_name}</Col>
						<Col>Customer Name </Col>
						<Col as='b'>{this.props.customer.cust_name}</Col>
						
					</Row>
					<Row>
						<Col>FLOW Cust ID</Col>
						<Col as='b'>{this.props.customer.cust_id}</Col>
						<Col>{this.props.customer.data_prvdr_name} Cust ID</Col>
						<Col as='b'>{this.props.customer.data_prvdr_cust_id}</Col>
					</Row>
					<Row>
						<Col>Mobile Number</Col>
						<Col as='b'>{this.props.customer.cust_mobile_num}</Col>
						<Col>Customer Type</Col>
						<Col as='b'>{this.props.customer.biz_type}</Col>
					</Row>	
					<Row> 
						<Col md={3}>Address</Col>
						<Col md={9} as='b'>{this.props.customer.cust_addr_text}</Col>
						
					</Row>
					<Row>
						<Col md={3}>Relationship Manager</Col>
						<Col  md={9} as='b'><span>{this.props.customer.flow_rel_mgr_name} (m: {this.props.customer.flow_rel_mgr_mobile_num})<br/></span></Col>
					</Row>	
					<Row>
						<Col md={3}>Data Provider</Col>
						<Col  md={9} as='b'>{this.props.customer.data_prvdr_name}&nbsp;
						  - {this.props.customer.dp_rel_mgr_name} (m: {this.props.customer.dp_rel_mgr_mobile_num})</Col>
					</Row>
					<Row>
						<Col md={3}>Lender</Col>
						<Col  md={9} as='b'>{this.props.customer.lender_name}</Col>
					</Row>	 */}
					<div>
						<div className="floatAdvancecontainer">
							<div className="floatAdvancetitleBox d-flex align-items-center">
								<h2 className="floatAdvancetitle no-margin sizexm">{getValueFromLangJson('customer')}</h2>
								<a href={`/borrower/indiv/view/${this.props.customer.cust_id}`} target="_blank"> <h2 className="floatAdvanceBluetitle sizexm no-margin">{this.props.customer.cust_id}</h2></a>
								<div className='col d-flex justify-content-end'>
									{get("role_codes") != "loan_approver" && this.props.customer.loan_doc_id&& this.props.from_fa_view &&<div className='col-md-3'>
									<Sms_modal btn_style={'my-2'} view={'fa_page'} loan_doc_id={this.props.customer.loan_doc_id}/>
									</div>}
								</div>
							</div>
							<div className="loggedPersondetailsBox">
							<div className="row no-margin">
								<div className="col-lg-2 no-padding">
									<p className="no-margin loggedPersondetails loggedPersonid text-center">{this.props.customer.cust_id}</p>
										<div className="loggedPersondetailsImg">
											<img className="img-fluid" src={get_path("view_file") + this.props.customer.photo_pps_path +"/s_"+ this.props.customer.photo_pps}></img>
										</div>
									<p className="personalDetailstextval no-margin loggedPersondetails loggedPersonname text-center">{this.props.customer.cust_name}</p>
								</div>
                    			<div className="col-lg-5 no-padding personalDetailsbox">
                        <ul>
                            <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">{getValueFromLangJson('name')} </p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval no-margin">{this.props.customer.cust_name}</p>
                                    </div>                        
                                </div>
                            </li>
                            <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">{getValueFromLangJson('location')}</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval no-margin">{dd_value(this.props.customer.location)}</p>
                                    </div> 
                                </div>
                            </li>
                            <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">Flow RM</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval no-margin">{this.props.customer.flow_rel_mgr_name}</p>
                                    </div> 
                                </div>
                            </li>
                            <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">{getValueFromLangJson('address')}</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval no-margin">{this.props.customer.cust_addr_text}</p>
                                    </div> 
                                </div>
                            </li>    
                        </ul>
                     </div>
                    			<div className="col-lg-5 no-padding personalDetailsciewIdbox">
                        <ul>
                            <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">{this.props.customer.acc_prvdr_code} {getValueFromLangJson('account_num')}</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval  no-margin">{this.props.customer.acc_number}{this.props.customer.acc_branch == null ? " " : " ("+title_case(this.props.customer.acc_branch)+")"}</p>
                                    </div>                        
                                </div>
                            </li>
                            <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">{getValueFromLangJson('mobile_no')}</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval  no-margin">{this.props.customer.cust_mobile_num}</p>
                                    </div> 
                                </div>
                            </li>
							<li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">{getValueFromLangJson('rm_fa_approved_reason')}</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval  no-margin">{this.props.customer.appr_reason && this.props.customer.appr_reason.trim() ? this.props.customer.appr_reason: "-"}</p>
                                    </div> 
                                </div>
                            </li>
                            {/* <li>
                                <div className="row no-margin">
                                    <div className="no-padding col-lg-5">
                                        <p className="personalDetailstext personalDetailsviewText no-margin">Partner RM</p>
                                    </div>
                                    <div className="col-lg-7 no-padding">
                                        <p className="personalDetailstextval no-margin">{this.props.customer.dp_rel_mgr_name}</p>
                                    </div> 
                                </div>
                            </li> */}
                        </ul>
                    </div>
                			</div>
							{/* <div className="row no-margin">
								<div className="col-lg-2">
									<p className="no-margin loggedPersondetails agreementLoggedpersonImg_title loggedPersonid text-center">{this.props.customer.cust_id}</p>
									<div className="loggedPersondetailsImg">
										<img className="img-fluid" src={get_path("view_file") + this.props.customer.photo_pps_path + this.props.customer.photo_pps}></img>
									</div>
									<p className="no-margin loggedPersondetails loggedPersonname agreementLoggedpersonImg_title text-center">{this.props.customer.cust_name}</p>
								</div>
								<div className="col-lg-5 no-padding personalDetailsbox">
									<ul>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Data Provider Customer ID</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.data_prvdr_cust_id}</p>
												</div>                        
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Business Name</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.biz_name}</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Establishment Adress Type</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">Hardware Shop</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Ownership</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.biz_type}</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Business Distance from Home</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">Walkable from home</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Flow Relationship Manager</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.flow_rel_mgr_name}</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Registered Date</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">04 Jun 2020</p>
												</div> 
											</div>
										</li>
									</ul>
								</div>
								<div className="col-lg-5 no-padding agreementPersonaldetailsBox">
									<ul>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Flow Customer IDs</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.cust_id}</p>
												</div>                        
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Data Provider</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.data_prvdr_name}</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Lender</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.lender_name}</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Data Provider Relationship Manager</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">{this.props.customer.dp_rel_mgr_name}</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Source Run ID</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">Flow</p>
												</div> 
											</div>
										</li>
										<li>
											<div className="row no-margin">
												<div className="no-padding col-lg-5">
													<p className="personalDetailstext agreementPersonalDetailstext no-margin">Business Licence Photo</p>
												</div>
												<div className="col-lg-7">
													<p className="personalDetailstextval agreementPersonalDetailstext no-margin">Not uploaded</p>
												</div> 
											</div>
										</li>
									</ul>
								</div>
							</div> */}
								
							</div>
						</div>
					</div>
				</>	
				)





PastLoans1 = () => (
			<>
					
								<div className="floatAdvanceperiodHeaderBox">
									<h2 className="floatAdvanceperiodHeader no-margin">Past Float Advances</h2>
								</div>
								<div className="row no-margin pastFloatAdvanceBox">
									<div className="col-lg-6 no-padding personalDetailsbox">
										<ul>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">Total FA Application</p>
													</div>
													<div className="col-lg-7 no-padding">
														<p className="personalDetailstextval no-margin">{this.props.customer.tot_loan_appls}</p>
													</div>                        
												</div>
											</li>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">Total Late FAs</p>
													</div>
													<div className="col-lg-7 no-padding">
														<ul className="personalPastDetailstext">
															<li>{this.props.customer.late_loans == null ? 0 : (this.props.customer.late_loans).length} / {this.props.customer.tot_loans} ({percent_rnd(this.props.customer.late_loans, this.props.customer.tot_loans)})</li>
														</ul>                            
													</div> 
												</div>
											</li>                            
										</ul>
									</div>
									<div className="col-lg-6 no-padding">
										<ul>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">Total Disbursed FAs</p>
													</div>
													<div className="col-lg-7 no-padding">
														<ul className="personalPastDetailstext">
															<li>{this.props.customer.tot_loans} / {this.props.customer.tot_loan_appls} ( {percent_rnd(this.props.customer.tot_loans, this.props.customer.tot_loan_appls)} )</li>                           
														</ul>
													</div>                        
												</div>
											</li>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">First FA Disbursement Date</p>
													</div>
													<div className="col-lg-7 no-padding">
														<p className="personalDetailstextval no-margin">{format_date(this.props.customer.first_loan_date)}</p>                          
													</div> 
												</div>
											</li>                            
										</ul>
									</div>
								</div>
					
			</>		
)

render(){
	if(this.props.parent === 'selection' ){
		return(
		<>
			{this.Customer()}	

			{/* <Alert variant={this.state.variant}>
					{this.PastLoans1()}
			</Alert> */}

			{this.PastLoans1()}
			

			{!this.props.customer.account_id &&
				
				<label className="agreement">( Please configure account for customer to generate / upload agreement. )</label>
				
			}
			
			
		</>
		);
	}else{
		return(

				<>

					{/* <Alert variant={this.state.variant}>
							{this.Customer()}
						<hr/>
							
					</Alert>	 */}

					{this.Customer()}
	              
					{/* <Agreement customer={this.props.customer} variant={this.state.variant} loan_products={this.props.loan_products} cust_agreement_status={this.props.cust_agreement_status}  />					
					 */}
				</>
		);
	}
}
}


export default CustPanel;
