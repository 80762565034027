import {  Navigate } from 'react-router-dom';
import React from 'react';
import {Card,Button,Modal} from 'react-bootstrap';
import Lead from '../component/Lead';
import FlowContainer from '../../common/container/core/FlowContainer';
import {submitLead,viewLead,closeLead, searchLead,allowManualCapture, updateAuditedby,verified_by_audit,leadReopen} from '../../../actions/lead_actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { check_priv, Config } from '../../../helpers/storage_helper';
import {dd_value} from "../../../actions/common_actions";
import {Form} from 'react-bootstrap';
import {Edit} from '../../common/partial';
import {get} from '../../../helpers/storage_helper';
import StmtUploader from './StmtUploader';

import {AssignAuditor} from '../../../actions/lead_actions';
import withRouter from '../../../Router/Withrouter';



 class LeadContainer extends FlowContainer {
    
    state = {
        comp_name: "lead",
        lead_list : {},
        reason: "",
        to_edit: false, 
        lead : {},
        lead_data : {},
        edit_mode: false,
        capture_modal :false,
        person_type:null,
        file_json:null,
        files:null,
        StmtUpload:false,
        auditor_dd:'',
        team_lead_ids : Config('team_lead_ids'),
		user_id : get('user_id'),
        tm_ids : Config('rm_head_person_id'),
    }

   

    componentWillMount(){
        if(this.props.mode == "view"){
          this.view_lead()
        }else if(this.props.mode == "create"){
            
            
        }
        this.setOptionsToStateFromApi("auditor", {...this.def_req}, null, "kyc")
    }

    view_lead(){
        this.lead_ref = React.createRef()
        this.id = this.props.params.id;
         viewLead(this.req({id: this.id}))
        .then((response) => {
            this.resp(response)
            if(!response){return };
            if(response.status === "success"){
                this.setState({lead : response.data,div_style : "disable_div",submit_btn_style : "hide_div"})
               
            if(response.data.file_json){
                this.setState({file_json:JSON.parse(response.data.file_json),files:(JSON.parse(response.data.file_json)).files})
            }
           
            }
        })
    }

    validate_fields(lead_data) {
        if(lead_data.acc_purpose && lead_data.acc_purpose.includes == "terminal_financing" && !lead_data.mobile_num){
            alert("Please enter mobile number")
            return false  
        }

        if(lead_data.account_num && !lead_data.acc_prvdr_code){
            alert("Please select account provider")
            return false  
        }

		if(lead_data.acc_prvdr_code  && lead_data.acc_purpose  && lead_data.biz_name ){
			return true
  		}else{
  			alert("Please enter required fields")
  			return false
  		}
	}

    handleAuditKyc = (event) =>{
        var audited_by = this.state.lead.audited_by
        var auditor_name = this.state.lead.auditor_name
        var resp = null
        if(audited_by == null && get('role_codes') ==  'operations_auditor'){
            resp = window.confirm("You are going to initiate the audit KYC process for this lead. Once you start, no other auditor can act on this lead.Are you sure?")
            if(resp){
                updateAuditedby(this.req({id: this.id}))
                .then((response) => {
                    this.resp(response)
                    if(!response){return};
                    if(response.status === "success"){
                        window.location.href = "/lead/audit_kyc/"+this.id;
                    }
                })
            }
        }
        else if ( get('role_codes') != 'operations_auditor'){
			window.alert(`You cannot take any action to this lead. However you can view the KYC record`)
			window.location.href = "/lead/audit_kyc/"+this.id;
		} else if (audited_by != null && get('user_id') !=  audited_by){
            window.alert(`${auditor_name} has already initiated audit process for this KYC record. You can not take action. However you can view the KYC record`)
            window.location.href = "/lead/audit_kyc/"+this.id;

        }else if (audited_by != null && get('user_id') ==  audited_by){
            window.location.href = "/lead/audit_kyc/"+this.id;
        }


        

        
        
    }

    handleLead = (event) =>{
      const lead_data = this.state.lead 
      var is_valid = this.validate_fields(lead_data)
      if(is_valid){
        submitLead(this.req({"lead":lead_data})).
               then((response) =>{
                    this.resp(response)
                    if(!response){return };
                    if(response.status === "success"){
                        alert(response.message); 
                        this.setState({to_edit : true,id:response.data})
                    }
                })
      }    
    }

    handleCaptureModal = (event) =>{
        this.setState({capture_modal : true})
    }

    handleAllowManualCapure = (event) =>{
        const lead_id = this.state.lead.id 
        const type = this.state.person_type        
        var resp = window.confirm("Are you sure to allow manual capture ?")
        if(resp){
            allowManualCapture(this.req({lead_id,type})).
            then((response) =>{
            this.resp(response)
            if(!response){return };
            if(response.status === "success"){
                alert(response.message); 
                window.location.reload()
            }
        })
        }
        
    }

  
    handleReassignCaptureModal = (event) => {
        this.setState({ capture_reassign_modal: true });
    };

    handleLeadProfile = (profile_status) => {
        this.setState({ modalBox1: "true" });
    };

    handleModalClose = () => {
        this.setState({ modalBox1: false });
    };

    handleCaptureModalClose = () => {
        this.setState({ capture_modal: false, capture_reassign_modal: false });
    };

    handleModalOpen = (event) => {
        this.setState({ modalBox1: true });
    };

    handleLeadReopen = () => {
        let confirm = window.confirm("Are you sure to open this lead?")
        if(confirm) {
            console.log('state');
            console.log(this.state);
            let req = { lead_id: this.state.lead.id };
            leadReopen(this.req(req)).then((response) => {
                if (!response) {
                    return;
                }
                if (response.status == "success") {
                    alert(response.message);
                    this.view_lead();
                }
            });
        }
    }


    handleModalCloseLead = (event) =>{
        const lead_id = this.state.lead.id 
        const lead_reason = this.state.reason
        const profile_status = this.state.lead.profile_status
        const remarks = this.state.remarks
            
            closeLead(this.req({"lead_id":lead_id,"reason":lead_reason, 'remarks': remarks, "profile_status":profile_status})).
            then((response) =>{
                this.resp(response)
                if(!response){return };
                if(response.status === "success"){
                    alert(response.message); 
                    window.location.reload()
                }
            })
             
    }

       handleChange = (event) =>{
        this.setState({[event.target.id]: event.target.value});
       }


       handleEditprofile= (event)=>{
           this.setState({edit_mode:true,StmtUpload:false,div_style:""});

          
      }

      handlePersonType = (event)=>{
        this.setState({person_type:event.target.value});
      }
      handleStmtUpload=()=>{
          this.setState({div_style:"hide_div",StmtUpload:true,edit_mode:false})
      }
    
      handleModalAssignAuditor = (event) =>{
        AssignAuditor(this.req({"auditor_id" : this.state.audit_lead.auditor, 'lead_id' : this.state.lead.id}))
        .then((response) =>{
            this.resp(response)
            if(!response){return };
            if(response.status === "success"){
                alert(response.message); 
              window.location.reload()
            }
        })
    }

    RedirectToSelfRegistration(){
        var req = {country_code: get('market').country_code,"id": this.state.lead.id};		
        verified_by_audit(this.req(req))
        .then((response)=>{
          if(!response){return}
          if(response.status=="success"){
            
            if(window.confirm("Are you sure  to initiate verification for this lead ?")) {
                window.location.replace(`/lead/self_registration/${ this.state.lead_id}`);

            }
          }
        })
    }

    render(){
        let lead_status_index = null
        let lead_profile_status = this.state.lead.profile_status
        if(this.state.lead.status){
         
            lead_status_index = this.state.lead.status.split(/_/)[0]
        }

        if(this.state.to_edit === true) {
            return <Navigate to={`/lead/edit/${this.state.id}`}/> 
        }

        return(
            <div >
						{lead_profile_status == 'closed' &&

							<div className="closedStamp">
								<div className="stamp is-nope">Closed</div>
							</div>
						}
        
            <div  style={{maxWidth :"1500px"}}>
                <br/>
                <br/>
                <form autoComplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}>
                            <div className="d-flex justify-content-between" >
                                <h4 className='sizel'>{this.props.title}</h4>
                                {this.state.lead.status && <h4 className='sizel'>Status: {dd_value(this.state.lead.status)}</h4>}
                               
                                <div > 
                                   
                                    { this.props.mode == 'view' && lead_profile_status == 'open' && check_priv("lead","allow_manual_capture") &&
                                        <Button disabled = {this.state.calling_api} className='mr-3 sizexs  text-white' variant='primary' onClick={this.handleCaptureModal} >Allow Manual ID Capture</Button>
                                    }
                                    { this.props.mode == 'view' && lead_profile_status == 'open' && (lead_status_index < 50 ) &&
                                        <Button className='mr-3 sizexs text-white' variant='primary' onClick={this.handleEditprofile} >EDIT</Button>

                                    }
                                    {this.props.mode == 'view' && check_priv("lead","stmt_upload") && this.state.lead?.flags?.can_upload_stmt &&
                                        <Button className='mr-3 sizexs  text-white' variant='primary' onClick={()=>this.handleStmtUpload()}>STMT UPLOAD</Button>
                                    }

                                    {lead_status_index && (lead_status_index >= 40) && check_priv("lead","audit_kyc") && ((this.state.lead.type =="re_kyc") || (this.state.lead.type =="kyc") || (this.state.lead.type =="self_reg" && (lead_status_index == 50 || lead_status_index == 60))) && 
                                        <Button onClick={this.handleAuditKyc}  className='mr-3 sizexs'>{check_priv('lead','pending_kyc_list')?"View KYC":lead_status_index == 50 ? "Audit KYC" : "View KYC"}</Button>
                                    }  
                                     
                                    {lead_status_index && this.state.lead.type =="self_reg" && (lead_status_index == 53) && 
                                        <Button onClick={this.RedirectToSelfRegistration()}  className='mr-3 sizexs'>{check_priv('lead','pending_kyc_list')? "Verify Self Registration" : "View Self Registration"}</Button>
                                    }

                                     {(this.state.lead && (get('role_codes') == 'risk_compl_head') && this.state.lead.hasOwnProperty('audited_by') && (this.state.lead.audited_by !=null) && (lead_status_index == 50)) && 
                                     <Button disabled = {this.state.calling_api} className='mr-2 sizexs text-white' variant='primary' onClick={this.handleReassignCaptureModal} >Reassign Auditor</Button>
                                    } 
                                    
                                    {/* {(this.props.mode == 'view')&& lead_profile_status == 'open' && (lead_status_index <= 50) &&
                                    <Button  className='mr-2' variant="danger"  onClick={this.handleLeadProfile}>Close Profile</Button>
                                    } */}
                                    {this.state.lead.can_close_lead && check_priv('lead','close_lead') && (
                                        <Button className="mr-2 sizexs" variant="danger" onClick={this.handleLeadProfile}>
                                            Close Profile
                                        </Button>
                                    )}
                                    {this.state.lead.can_reopen_lead && (
                                        <Button className="mr-2 sizexs" variant="success" onClick={this.handleLeadReopen}>
                                            Open Profile
                                        </Button>
                                    )}
                                </div>
                            </div>
                           
                            </Card.Header>
                            <Card.Body className = "p-5">
                                {this.state.file_json && this.state.StmtUpload && this.state.lead?.flags?.can_upload_stmt &&
                                <StmtUploader file_json={this.state.file_json} lead_id={this.state.lead.id}  />
                                }
                            {/* {(this.props.mode == 'create' || !_.isEmpty(this.state.lead)) &&  */}
                                    
                                    <Lead mode={this.props.mode} div_style={this.state.div_style} file_json={this.state.file_json} id = "lead" data = {this.state.lead} lead_status={this.state.lead.status}  editable ={this.state.edit_mode} onComponentChange={this.handleComponentChange} />
                                    
                                {/* } */}
                                <div className="form-group row reset-margin justify-content-center py-4">
                                {(this.props.mode == 'create') && 
                                    <Button  disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary${this.state.submit_btn_style}`} onClick={this.handleLead} >Submit</Button>
                                 }
                                </div>
                            </Card.Body>
                        </Card>
                </form>
                
                <Modal show={this.state.capture_reassign_modal} onHide={this.handleCaptureModalClose} size="m">
                                    <Modal.Header closeButton show={this.state.capture_reassign_modal} >
                                        <Modal.Title> Reassign Auditor </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group row mx-0 mb-2 p-3 ">
                                            <div for=""  className="col-sm-5 font-weight-bold text-white reset-padding"> Assign Audit to</div>
                                            <div className="col-sm-7 reset-padding">
                                                <div className = "mb-2">
                                                <select className="form-control" id="audit_lead" onChange={(e) => {this.setState({audit_lead: {...this.state.audit_lead, auditor: e.target.value}})}}>
                                                
                                                 {this.state.auditor_dd} 
                                                </select>
                                                <div className="control-me"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body> 
                                    <Modal.Footer>
                                        <Button onClick={this.handleModalAssignAuditor} >Assign</Button>
                                        
                                    </Modal.Footer>
                            </Modal>


                <Modal show={this.state.modalBox1} onHide={this.handleModalClose} size="l">
                    <Modal.Header closeButton show={this.state.modalBox1} onHide={this.handleModalClose}>
                        <Modal.Title>Close Lead Profile </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                        <div className="form-group row mx-0 mb-2 p-3 ">
                            <div for=""  className="col-sm-5 font-weight-bold text-white reset-padding"> Reason<font className="text-danger">   *  </font></div>
                            <div className="col-sm-7 reset-padding">
                                <div >
                                    <Form.Control as="select" id="reason"  onChange={this.handleChange}>
                                        {this.getSelectOptions("close_reason")}
                                    </Form.Control>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mx-0 mb-2 p-3 ">
                            <div for=""  className="col-sm-5 font-weight-bold text-white reset-padding"> Remarks<font className="text-danger">   *  </font></div>
                            <div className="col-sm-7 reset-padding">
                                <textarea type="text" id="remarks" className="form-control" onChange={this.handleChange}  maxLength='256'/>
                            </div>
                        </div>
                            
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="danger" onClick={this.handleModalCloseLead} disabled = {!this.state.reason || !this.state.remarks} >Close Lead</Button>
                        
                    </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.capture_modal} onHide={this.handleCaptureModalClose} size="l">
                    <Modal.Header closeButton show={this.state.modalBox1} onHide={this.handleModalClose}>
                        <Modal.Title>Manual ID Capture </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                        <div className="form-group row mx-0 mb-2 p-3 ">
                                <div for=""  className="col-sm-5 font-weight-bold text-white reset-padding">Type<font className="text-danger">   *  </font></div>
                                <div className="col-sm-7 reset-padding">
                                    <div >

                                  
							<label><input id="person" type="radio" name="person" value="biz_owner" onChange={this.handlePersonType} />  ID of Biz Owner &nbsp;&nbsp;&nbsp;</label>
							<label><input id="person" type="radio" name="person" value="third_party_owner" onChange={this.handlePersonType}  />  ID of Third Party A/C Owner &nbsp;&nbsp;&nbsp;</label>
					
                                        {/* <Form.Control as="select" id="type"  onChange={this.handleChange}>
                                            <option value="" selected   >Choose</option>
                                            <option value = "owner_person">Biz Owner</option>
                                            <option value = "third_party_owner">Third Party Owner</option>

                                        </Form.Control> */}
                                    </div>
                                    <div className="control-me"></div>
                                </div>
                            </div>
                            
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handleAllowManualCapure}>Submit</Button>
                        
                    </Modal.Footer>
                    </Modal>
            </div>
        </div>

        )
    }
}

export default  withRouter(LeadContainer);
