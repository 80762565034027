import React, { } from 'react';
import { Link } from 'react-router-dom';
import {set, get, sync_master_data} from '../../helpers/storage_helper';
import {append_request,hlpr_add_status} from '../../helpers/common_helper';
import {get_country_master_data} from '../../actions/common_actions';
import {listAccountProvider} from '../../actions/account_provider_actions';
// import Flag from "react-flags";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {Logo} from './../common/partials/logo';


import Modal from 'react-bootstrap/Modal';
import history from '../../history';
import CustomFlag from '../common/component/CustomFlag';
import ReactCountryFlag from 'react-country-flag';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../Redux/Slices/CommonSlice';
import withRouter from '../../Router/Withrouter';

export class SelectMarket extends React.Component {
  handleClose = (event) => {
    // window.location.reload("/");
    this.setState({ modalBox : false });
    this.props.onClose()
  }

   componentWillMount(){
    this.market_list = get('market_list');
         const ls_market = get('market')
      if(ls_market && (this.props.action == 'select' 
            || this.props.toChange == 'acc_prvdr' 
            || this.props.toChange == 'acc_prvdr_only')){
        this.setState({country_code: ls_market.country_code, market_style:"disable_div"})
        var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
        req = hlpr_add_status(req);  
        this.list_acc_prvdr_api(req);
  
      }
  
      //this.setState({ });
      var marketLinks = null
      if(this.props.toChange != 'acc_prvdr_only'){
          marketLinks = this.market_list.map((market, index)=>
            <ul  key={index}>
              <div className="row">
                <Link to="#">
                  <CustomFlag country_code={market.country_code} height={25} width={25}/>
                  &nbsp;
                  <a className="linkText ml-10" onClick={this.clickMarket.bind(this)} variant="primary" id={index}>{market.country} ({market.country_code})</a>
                </Link>
              </div>
            </ul>
          );
        
      }
  
      if(this.props.toChange == "acc_prvdr_only" || this.props.toChange == "acc_prvdr"){
        this.toChangeStr = 'account provider';
      }
      else if(this.props.toChange == "market"){
        this.toChangeStr = 'market / account provider';
      }
      var showX = false;
      if(this.props.action == 'change'){
        showX = true;
      }

      this.setState({marketLinks,  modalBox: true, showX} );
 }

 clickAccProvider = (acc_prvdr_code,acc_prvdr_logo) =>{
  
    if(this.selectedMarket != null){
      set('market',this.selectedMarket);  
    }

    const admin_roles = ['super_admin', 'ops_admin', 'market_admin', 'it_admin','ops_analyst']


    if(admin_roles.includes(get('role_codes'))){
      set('acc_prvdr_code',acc_prvdr_code);
      set('acc_prvdr_logo',acc_prvdr_logo);
      if(this.roles === "super_admin" || this.roles === "it_admin" || this.roles === "ops_analyst"){
        // history.push('/management-dashboard')
        this.props.navigate('/management-dashboard')
      }
    }
    
    if(this.props.action == "select"){

      this.props.onSelect();
     }else if(this.props.action == "change"){
      window.location.reload("/")
     }
     this.props.setValuesIntoRedux({update_market_details:false});
   
  }

  clickMarket = (event) => {
    //alert(event.target.id);
    
    this.selectedMarket = this.market_list[event.target.id];

    //localStorage.setItem('market', JSON.stringify(market));
    
    const country_code = this.selectedMarket.country_code;
    const time_zone = this.selectedMarket.time_zone;
    const status = null;
    const req = {country_code, time_zone, status}
      get_country_master_data(req)
       .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                sync_master_data(response.data.master_data);            
            }else{
              alert(response.message    + "\n"    +JSON.stringify(response.data));  
            }
          this.list_acc_prvdr_api(req);  

        });   
      let country={}
      country["country_code"]= country_code.slice(0, -1)
      set("market_details",country)
   }

  list_acc_prvdr_api(req) {
    listAccountProvider({...req, status: 'enabled', biz_account: true})
              .then((response)=>{
                 if(!response){return };
                 if(response.status === "success"){
                   this.list_account_provider(req.country_code, response.data);
                 }
              });
    }
 
   list_account_provider = (country_code, acc_prvdr_list) => {
      const acc_prvdr_links = acc_prvdr_list.list.map((acc_prvdr,index)=>
         <ul key={index}>
           <div className="row">
             <Link to="#">
               <Logo file_name={"t_"+  acc_prvdr.acc_provider_logo} entity="acc_prvdr" entity_code={acc_prvdr.acc_prvdr_code} country_code={acc_prvdr.country_code} />
               <a className="linkText ml-10" onClick={this.clickAccProvider.bind(this,acc_prvdr.acc_prvdr_code,acc_prvdr.acc_provider_logo)} 
                   variant="primary">{acc_prvdr.name}</a>
             </Link>
           </div>
         </ul>
         );
       this.setState({country_code, acc_prvdr_links, market_style:"disable_div"})
 
 
    }


  render() {
    
 
    return (
      <></>
      // <>
      //   <Modal show={this.state.modalBox} onHide={this.handleClose}>
      //     <Modal.Header>
      //       <Modal.Title style={{fontSize:22}}>Hello User!</Modal.Title>
      //          { this.state.showX && 
      //           <button type="button" onClick={this.handleClose} className="close" aria-label="Close">
      //            <span aria-hidden="true">&times;</span>
      //           </button>
      //         }
      //     </Modal.Header>
      //     <Modal.Body>
      //                   <h4 className='no-margin text-center' style={{fontSize:18}}>Please select the {this.toChangeStr} you would like to administrate.</h4>
      //         {this.props.toChange == 'market' 
      //          && 
      //         <Card>
      //            <Card.Header>
      //               Choose Market
      //            </Card.Header>
      //               <Card.Body className={this.state.market_style}>{this.state.marketLinks}</Card.Body>
      //         </Card>}
      //         {this.state.country_code && 
      //           <Card>
      //            <Card.Header className='text-white'>
      //            Choose Account Provider from 
      //            <CustomFlag country_code={this.state.country_code} height={25} width={25}/>
      //               <b> {this.state.country_code}</b>
      //            </Card.Header>
      //               <Card.Body>{this.state.acc_prvdr_links}</Card.Body>
      //         </Card>
      //         }
  
      //       {/*<Accordion defaultActiveKey='0'>
      //         <Card>
      //            <Card.Header>
      //               <Accordion.Toggle as={Button} variant="link" eventKey="0">
      //               Choose Market
      //               </Accordion.Toggle>
      //            </Card.Header>
      //            <Accordion.Collapse eventKey="0">
      //               <Card.Body>{this.state.marketLinks}</Card.Body>
      //            </Accordion.Collapse>
      //         </Card>
      //           <Card>
      //            <Card.Header>
      //               <Accordion.Toggle as={Button} variant="link" eventKey="0">
      //               Choose Data Provider 
      //               </Accordion.Toggle>
      //            </Card.Header>
      //            <Accordion.Collapse eventKey="0">
      //               <Card.Body>{this.state.dataproviderLinks}</Card.Body>
      //            </Accordion.Collapse>
      //         </Card>
      //        </Accordion>
      //      */}
      //     </Modal.Body>
      //   </Modal>
      // </>
    );
  }
}

const mapDispatchToProps ={
  setValuesIntoRedux

};
const mapStateToProps = (state) => {
 
};
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SelectMarket));
