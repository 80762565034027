import React, {useContext} from 'react';
import FlowComponent from '../common/component/core/FlowComponent';
import {getRetryAccounts, listSettlementLogs, Rescheduletask, viewRunReport, unknownTxnsPrcn, getDownloadFolder, sendEmail} from '../../actions/settlement_actions';
import {listMarkets} from '../../actions/common_actions';
import {Accordion, AccordionContext, Button, Card, Dropdown, Form, Modal, useAccordionToggle} from 'react-bootstrap';
import { FcCalendar } from 'react-icons/fc';
import DatePicker from 'react-datetime';
import '../../../src/styles/flow.css';
import { BsChevronDown } from 'react-icons/bs';
import { dd } from '../../actions/common_actions';
import { title_case } from '../../helpers/common_helper';
import {check_priv, get} from "../../helpers/storage_helper";
import MonthPicker from '../../helpers/monthPicker';
import { default as ReactSelect } from "react-select";
// import Flag from "react-flags";
import { th } from 'date-fns/locale';
import { IoIosGlobe } from 'react-icons/io';
import moment from 'moment';
import Select, { components } from 'react-select';
import CustomFlag from '../common/component/CustomFlag';
import { BasicReactTable } from '../../helpers/react_table';


function AccordionArrow({ eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
          eventKey,
          () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <BsChevronDown className = {isCurrentEventKey ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow" }
                           size="18" onClick={decoratedOnClick}
                           style={{marginTop: "-52%", marginRight:"-66%"}}/>
        );
      }


      const { MultiValueLabel } = components;
class SettlementComponent extends FlowComponent{
    state = {
        month:false,
        day:false,
        week:false,
        run_type: "monthly",
        start_date:null,
        end_date:null,
        columns:[],
        sample:null,
        show_table:false,
        show_resch_table:false,
        show_failed_table:false,
        show_retry_modal:false,
        show_history_modal:false,
        selected_retry_accs:[],
        country_code:"UGA",
        market_list: [],
        dorp_down_value:"Uganda",
        style:"",
        filter_button : "",
        copy_log_summary : [],
        log_summary : [],
        
        retry_accounts:[],
        history:[],
        failed_accounts:[],
        filtered_history:[],
        selectedOptions: [],
        files : {},
        total_os : 0,
        unknown_txn_percen : 0,
        unknown_threshold_percn :0,
        result_range : null,
        email : null,
    }

    componentWillMount(){
        if(check_priv("settlement","summary")){
            if(get('market_list')){
                this.setState({market_list:get('market_list')})
            }
            else{
                listMarkets().then((response)=>{
                    this.resp(response)
                    if(!response){return}
                    if(response){
                        this.setState({market_list:response.data})
                    }
                }) 
            }
            this.getSummary()
        }
       }

    getSummary = (run_type = null) => {
        run_type = run_type ? run_type : this.state.run_type
        let search_range = null
        if(this.state.start_date != null && this.state.end_date != null){
            if(this.state.run_type == 'weekly'){
                this.getLastMonday(this.state.start_date, "start_date")
                this.getLastMonday(this.state.end_date, "end_date")
            }
            search_range = {from: this.state.start_date, to: this.state.end_date}
        }
        let req = {run_type, search_range, country: this.state.country_code}
        listSettlementLogs(this.req(req)).then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                this.setState({log_summary:response.data, copy_log_summary:response.data}, () => this.FilterRunHistory(false, null))
                // this.FilterRunHistory(false, null)
            }
        });
        this.setState(state => state.columns =  this.columnDatas())
        // this.setState(state => state.filtered_history =  this.state.history)
        this.setState(state => state.filter_button = "all")
    }

    getLastMonday(selected_date, type) {

        const formatted_date = moment(selected_date).format("YYYYMMDD")
        const selected_day = formatted_date.toString().substring(6, 8)
        const selected_month = formatted_date.toString().substring(4, 6)
        const selected_year = formatted_date.toString().substring(0, 4)
        const date = new Date(selected_year.concat("-", selected_month, "-", selected_day));
        var day = date.getDay();
        var diff = date.getDate() - day + (day === 0 ? -6 : 1); 
        var lastMonday = new Date(date);  
        lastMonday.setDate(diff); 
        const formatted_monday = moment(lastMonday).format("YYYY-MM-DD 00:00:00");

        if(type == "start_date"){
            this.setState(state => state.start_date = formatted_monday)
        }else{
            this.setState(state => state.end_date = formatted_monday)
        }
        
      }


    selectRunType=(run_type)=>{
        this.setState({run_type, log_summary: [], history: []})
        this.setState({start_date:null, end_date:null})
    }

    handleSearch=()=>{
        this.getSummary(this.state.run_type)
    }


    handleDropDown=(country_code, country)=>{
        if(country_code){
            this.setState({country_code:country_code, log_summary: [], history: []})
        }
        this.setState(state => state.dorp_down_value = country)
    }


    handleToggle = (e) => {

        switch(e){
            case("list"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
            case("Reschedule"):
                this.setState(state => state.show_resch_table = !this.state.show_resch_table);
                break;
            case("stage"):
                this.setState(state => state.show_failed_table = !this.state.show_failed_table);
                break;
        }
    }

    handleModalClose=(e)=>{
        switch(e){
            case("retry"):
                this.setState({show_retry_modal:false, retry_accounts: []});
                break;
            case("history"):
                this.setState({show_history_modal:false, report_pdf: null, history: []});
                break;
        }
    }

    handleReschApicall=(isInstantRetry, sch_id)=>{
        let conf = window.confirm("Are you sure all the issues in the last run have been resolved and you want to rerun the settement process?")
        if(!conf){return}

        let accounts = this.state.selectedOptions.map((acc)=> acc.value)
        let files = this.state.files
        let req = {retry_now: isInstantRetry, sch_id, accounts, files , email : this.state.email_case,country: this.state.country_code}
        Rescheduletask(this.req(req)).then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                alert(response.message)
                this.handleModalClose("retry")
                this.getSummary()
            }
        });
    }  
    

    handleWeekCalculation=(input_date)=>{
        let week_num = moment(input_date, "YYYY-MM-DD").week()
        let cur_year = moment(input_date).format("YYYY")
        let dateString
        dateString = cur_year.concat(week_num)
        const year = dateString&&dateString.toString().substring(0, 4);
        const weekNumber = dateString&&dateString.toString().substring(4);
        const date = new Date(year, 0, 1); 
        const daysToAdd = (weekNumber - 1) * 7; 
        date.setDate(1 + daysToAdd); 
        const month = date.toLocaleString('default', { month: 'long' });
        const weekInMonth = `W${Math.ceil(date.getDate() / 7)}`;
        const formattedOutput = `${year}-${month.charAt(0).toUpperCase()}${month.slice(1)}-${weekInMonth}`; 
        return ( <div>{formattedOutput}</div>);
    }

    handleSelect=(selected_retry_accs)=>{

        this.setState({selected_retry_accs});

    }

    handleHistoryFilter=(type, history)=>{

        let filtered_array = []

        if(type == "All"){
            this.setState({filtered_history:this.state.history});
            this.setState(state => state.filter_button = "all")
        }
        else if(type == "Success"){
            this.state.history.map((item, index)=>{
                return(
                    item.status == 'success' ? filtered_array.push(item) : null
            )})
            this.setState({filtered_history:filtered_array})
            this.setState(state => state.filter_button = "success")
        }
        else if(type == "Failed"){
            this.state.history.map((item, index)=>{
                return(
                    item.status == 'failed' ? filtered_array.push(item) : null
            )})
            this.setState({filtered_history:filtered_array})
            this.setState(state => state.filter_button = "failed")
        }
    }

    columnDatas(){
        const columns = [
            {
              Header: "Run No",
              accessor: "run_no",
              show:true,
              minWidth:60,
              Cell : cell => {return <>{cell.row.index + 1}</>}    

            },
            {
                Header: "Run Type",
                accessor: "run_type",
                show:true,
                
                Cell : cell => {return <>{ (cell.row.original.created_by == 0 || cell.row.original.created_by == null )? "System Scheduled" : 'User Scheduled'}</>}    

            },
            {
                Header: "Created By",
                accessor: "created_by",
                show:true,
                Cell : cell => {return <>{(cell.row.original.created_by == 0 || cell.row.original.created_by == null) ? "System" : cell.row.original.created_by_name}</>}    

                // accessor: row =>{return(row.created_by == 0 || row.created_by == null) ? "System" : row.created_by_name},
            },
            {
                Header: "Created at",
                accessor: "created_at",
                show:true,
                Cell : cell => {return  <div className='col p-0'><div className='text-left'>{moment(cell.row.original.created_at).format("MMM DD YYYY")}</div><div className='text-left'>{moment(cell.row.original.created_at).format("hh:mm A")}</div></div>}    

                // accessor: row =>{return <div className='col p-0'><div className='text-left'>{moment(row.created_at).format("MMM DD YYYY")}</div><div className='text-left'>{moment(row.created_at).format("hh:mm A")}</div></div>},
            },
            {
                Header: "Run at",
                accessor: "scheduled_at",
                show:true,
                Cell : cell => {return <div className='col p-0'><div className='text-left'>{moment(cell.row.original.scheduled_at).format("MMM DD YYYY")}</div><div className='text-left'>{moment(cell.row.original.scheduled_at).format("hh:mm A")}</div></div>}    

                // accessor: row =>{return <div className='col p-0'><div className='text-left'>{moment(row.scheduled_at).format("MMM DD YYYY")}</div><div className='text-left'>{moment(row.scheduled_at).format("hh:mm A")}</div></div>},
            },
            {
                Header: "Status",
                accessor: "status",
                show:true,
                Cell : cell => {return <>{cell.row.original.status}</>}    

                // accessor:  row  => {return title_case(row.status)}

            },
            {
                Header: "Action",
                accessor: "action",
                show:true,
                Cell : cell => {return <>{cell.row.original.status == 'scheduled' ? <></>: <button className="product_takeup_submit font btn btn-sm" disabled={this.state.calling_api} onClick={()=>this.viewRunReport(cell.row.original)}>View</button>}</>}    

                // accessor:  row  => {return row.status == 'scheduled' ? <></>: <button className="product_takeup_submit font btn btn-sm" disabled={this.state.calling_api} onClick={()=>this.viewRunReport(row)}>View</button>} ,

            }
        ]
        return columns
    }

    viewRunReport(row) {        
        this.setState({result_range: moment(row.range_start).format("MMM DD, YYYY") + " - " + moment(row.range_end).format("MMM DD, YYYY")})
        this.setState({email : row.id})
        let req = {run_id: row.id, country: this.state.country_code}
        viewRunReport(this.req(req)).then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                this.setState({history: response.data.accounts, filtered_history: response.data.accounts, filter_button: 'all', show_history_modal: true, report_pdf: response.data.balance_report}, () => this.handleHistoryFilter('Failed'))
            }
        });
        unknownTxnsPrcn(this.req(req)).then((response) => {
            if(!response){return}
            if(response.status == "success"){
                this.setState({total_os: response.data.total_os, unknown_txn_percen : response.data.unknown_txn_percn, unknown_threshold_percn: response.data.unknown_threshold_percn})
            }
        })
    }

    handleEmailApicall = () =>{
        sendEmail(this.req({sch_id : this.state.email})).then((response) =>{
            this.resp(response)
            if(!response){return}
            if(response){
                alert("The email has been sent successfully to the relevant stakeholders.")
            }
        })
    }


    downloadFile = (path,file_name) => {
        const url = window.location.origin + path ;
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = file_name;
        anchor.click();
    };

    handleDownloadFolder(item){
        let req = {data:{acc_prvdr_code:item.acc_prvdr_code, schedule_id:item.schedule_id, acc_number:item.acc_number, exec_id:item.exec_id}}
        getDownloadFolder(this.req(req)).then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                this.downloadFile(response.data['file_path'], response.data['file_name'])
            }
        });
    }

    handleFileUpload = (value, file) => {
        const reader = new FileReader();
      
        reader.onload = (event) => {
          const fileData = event.target.result;
          let files = {...this.state.files}
          files[value] = {
            file_data: fileData,
            file_type: file['type'],
            file_name: file['name'],
            file_data_type: "data-url",
            entity_code:value,
            file_of: "settlement_files"
          }
          this.setState({
            files
          })
        };
      
        reader.readAsDataURL(file);
      };
    
      CustomMultiValueLabel = ({ children, innerProps, data }) => (
        <div>
          <MultiValueLabel {...innerProps}>{children}</MultiValueLabel>
          <input
            type="file"
            onChange={(e) => this.handleFileUpload(data.value, e.target.files[0])}
          />
        </div>
      );
    
      handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
        let accounts = selectedOptions.map((acc)=> acc.value)
        let files = this.state.files
        let value = {}
        accounts.forEach((val, key) => {
            if(files[val]){
            value[val] = files[val]
            }
        });

        this.setState({files : value})
      };

    
      handleFilterRunHistory = (run_history)=>{
          let ids = []
          let manual_sch = []
          let result = []
          for (let i = 0; i < run_history.length; i++) {
              if (run_history[i].created_by === 0) ids.push(run_history[i].id)
              else if (run_history[i].created_by != 0) manual_sch.push(run_history[i].id)
          }
          const max_sys = Math.max(...ids);
          const max_man = Math.max(...manual_sch);
          const max_sys_sch = run_history.find(element => element.id === max_sys);
          const max_man_sch = run_history.find(element => element.id === max_man);
          result.push(max_sys_sch)
          if(max_man_sch != undefined){
            result.push(max_man_sch)
          }  
          run_history = result.sort((a, b) => b.id - a.id);
          //   manual_sch = run_history.filter(object => object.created_by > 0);
          //   manual_sch.push(max_sys_sch)
          //   run_history = manual_sch.slice().sort((a, b) => b.id - a.id);
          return run_history
      }

      FilterRunHistory =(e, index) =>{
        let temp_summary = JSON.parse(JSON.stringify(this.state.copy_log_summary));
        if(e == true){
            for (let i = 0; i < temp_summary.length; i++) {
                if (i == index) {
                    temp_summary[index].run_history = this.state.log_summary[index].run_history;
                }
                else {
                    temp_summary[i].run_history = this.handleFilterRunHistory(temp_summary[i].run_history);
                }
                temp_summary[i].run_count = temp_summary[i].run_history.length
            }   
        }
        else{
            if(index){
                temp_summary[index].run_history = this.handleFilterRunHistory(temp_summary[index].run_history);
                temp_summary[index].run_count = temp_summary[index].run_history.length
            }
            else{
                for(let k=0 ; k < temp_summary.length; k++){
                    temp_summary[k].run_history = this.handleFilterRunHistory(temp_summary[k].run_history)
                    temp_summary[k].run_count = temp_summary[k].run_history.length
                }
            }
        }
        this.setState({copy_log_summary : temp_summary})
      }
    


    render(){
        const { selectedOptions } = this.state;
        if(!check_priv('settlement','summary') || this.state.market_list.length==0){return <></>}
        return(
            <div>
                <div className='col-3 p-0 ml-5 mt-2'><label className='text-light'><b>Select Country</b></label></div>
                
                <Dropdown>
                        <Dropdown.Toggle style={{width:"12vw"}}>
                            {this.state.dorp_down_value.length>0 ? this.state.dorp_down_value : "Country"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width:"12vw"}}>
                            <Dropdown.Item onClick={()=>this.handleDropDown(false, "Country")}>Country</Dropdown.Item>
                            {this.state.market_list.map((each, index)=>{
                                        return(
                                            <Dropdown.Item onClick={()=>this.handleDropDown(each.country_code, each.country)}> <div className='mr-5'>{each.country}</div> 
                                            {/* <Flag id="market" name={each.country_code} format="png" pngSize={48} shiny={false} alt={each.country_code} basePath="/img/flags"/> */}
                                            <CustomFlag country_code={each.country_code} height={30} width={30}/>
                                            </Dropdown.Item>
                                    )})}
                        </Dropdown.Menu>
                    </Dropdown>
            <div style={{marginTop: "10px"}}>        
                <div className="row p-0 m-0 w-100 justify-content" >
                    <div className={`row col-md-5 align-items-center`}>
                        <div>
                            <Button className="mr-2" variant={`${this.state.run_type == 'monthly' ? 'primary':  'light'}`} onClick={() => this.selectRunType('monthly')}> <b className={`${this.state.run_type == 'monthly' ? 'text-light':  'text-dark'}`}>Monthly</b> </Button>
                            <Button className="mr-2" variant={`${this.state.run_type == 'weekly' ? 'primary':  'light'}`} onClick={() => this.selectRunType('weekly')}> <b className={`${this.state.run_type == 'weekly' ? 'text-light':  'text-dark'}`}>Weekly</b> </Button>
                            <Button className="mr-2" variant={`${this.state.run_type == 'daily' ? 'primary':  'light'}`} onClick={() => this.selectRunType('daily')}> <b className={`${this.state.run_type == 'daily' ? 'text-light':  'text-dark'}`}>Daily</b> </Button>
                        </div>
                    </div>

                    <div className={`row col-md-6 px-5 mx-5 justify-content-between settl`}>
                        <div className='col-md-4 p-0'>
                            <div className=' m-2 row pl-0'>
                                <label className='col p-0' >{this.state.run_type =='monthly' ? "Start Month" : this.state.run_type =='weekly' ? "Start Week" : "Start Date"} <span className='imp_star'>*</span></label>
                                {this.state.run_type == 'monthly' ? <div className='col-12 p-0 month_picker'><input type='month' className='form-control' onChange={(e) => this.setState({ log_summary: [], start_date: moment(e.target.value).format("YYYY-MM-01 00:00:00")})}/></div> : this.state.run_type == 'weekly' ? <DatePicker className='col-12 p-0' inputProps={{ id: 'start_date', name: 'start_date', autoComplete:'off' }} filterDate={(e) => e.getDay() === 1} closeOnSelect={true}  onChange={(e) => this.setState({ log_summary: [], start_date: moment(e).format("YYYY-MM-DD 00:00:00")})}  dateFormat="YYYY MM DD"  value={this.state.start_date ? moment(this.state.start_date).format("YYYY MM DD"):null} timeFormat={false} /> : <DatePicker className='col-12 p-0' inputProps={{ id: 'start_date', name: 'start_date', autoComplete:'off' }} closeOnSelect={true}  onChange={(e) => this.setState({ log_summary: [], start_date: moment(e).format("YYYY-MM-DD 00:00:00")})}  dateFormat="YYYY MM DD" value={this.state.start_date ? moment(this.state.start_date).format("YYYY MM DD") : null} timeFormat={false} /> }
                            </div>
                        </div>
                        <div className='col-md-4 p-0'>
                            <div className=' m-2 row pl-0'>
                                <label className='col p-0' > {this.state.run_type =='monthly' ? "End Month" : this.state.run_type =='weekly' ? "End Week" : "End Date"} <span className='imp_star'>*</span></label> 
                                {this.state.run_type == 'monthly' ? <div className='col-12 p-0 month_picker'><input type='month' className='form-control' onChange={(e)=>this.setState({log_summary: [], end_date: moment(e.target.value).format("YYYY-MM-01 00:00:00")})}/></div> : this.state.run_type == 'weekly' ? <DatePicker className='col-12 p-0' inputProps={{ id: 'end_date', name: 'end_date', autoComplete:'off' }} filterDate={(e) => e.getDay() === 1} closeOnSelect={true}  onChange={(e)=>this.setState({log_summary: [], end_date:moment(e).format("YYYY-MM-DD 00:00:00")})}   dateFormat="YYYY MM DD" timeFormat={false} value={this.state.end_date ? moment(this.state.end_date).format("YYYY MM DD") : null}/> : <DatePicker className='col-12 p-0' inputProps={{ id: 'end_date', name: 'end_date', autoComplete:'off' }} filterDate={(date) => date.getDay() === 1} closeOnSelect={true}  onChange={(e)=>this.setState({log_summary: [], end_date:moment(e).format("YYYY-MM-DD 00:00:00")})}   dateFormat="YYYY MM DD" value={ this.state.end_date ? moment(this.state.end_date).format("YYYY MM DD") : null} timeFormat={false}/>}
                                
                            </div>
                        </div>
                        <div className='col-md-4 mt-4 p-0'>
                            <button className="product_takeup_submit btn btn-lg" disabled={this.state.calling_api} onClick={()=>this.getSummary()}>Search</button>
                        </div>
                    </div>
                </div>
            </div>

                <div style={{width: "60vw", margin: "auto", marginTop: "80px"}} className='settlement-border p-2'>
                    <div className="card bg-black text-white m-0" style={{height:"63px"}}>
                        <div className="card-body" style={{marginTop:"10px",fontSize:"20px"}}><b className='text-light'>Settlement Run Results</b></div>
                    </div>

                    <div>
                        <div style={{width: "59.2vw"}} >
                            <div className="card p-0 text-white m-0" >
                                <div className="card-body p-0 home_header_d-flex_at_response justify-space-between">
                                    <Accordion.Toggle className = "col invisible" as = {Card.Header} eventKey="0" >
                                        <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" style={{marginTop: "-38%", marginRight:"-66%"}}/>
                                    </Accordion.Toggle>

                                    <h4 className="card-title p-0 w-100 text-white  ">
                                        <div className='d-flex justify-content-around p-0 row' style={{fontSize: "13px", alignItems: "center", marginTop: "10px"}}>
                                            <div className='col-md-2' style={{textAlign:"center"}}> {this.state.run_type == 'monthly' ? "Month" : this.state.run_type == 'weekly' ? "Week" : "Day"}</div>
                                            <div className='col-md-2' style={{textAlign:"center"}}>Status</div>
                                            <div className='col-md-2' style={{textAlign:"center"}}>Pending Accounts</div>
                                            <div className='col-md-2' style={{textAlign:"center"}}>Action</div>
                                            <div className='col-md-2' style={{textAlign:"center"}}>No.of Runs</div>
                                        </div>
                                    </h4>
                                </div>
                            </div>
                        </div>


                        <div>
                            <Accordion>
                        {this.state.copy_log_summary.length>0 && this.state.copy_log_summary.map((item,index)=>{
                            return (
                                            <Card className={`mt-2 mb-0 settlement-${['success', 'failed'].includes(item.last_status) ? item.last_status : 'rescheduled'}`}>
                                                <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                    <Accordion.Toggle className = "col" as = {Card.Header} eventKey={index + 1} >
                                                        <div>
                                                            <AccordionArrow eventKey={index + 1}/>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Toggle className="card-title w-100 text-white mt-2" as='h4' eventKey={index + 1} >
                                                    {/* <h4 className="card-title w-100 text-white mt-2"> */}
                                                        <div className='d-flex justify-content-around mt-1 mp-1 row' style={{fontSize: "13px", alignItems: "center"}}>
                                                            <div className='col-md-2' style={{textAlign:"center"}}>{this.state.run_type == 'daily' ? moment(item.start_day).format("MMM DD, YYYY") : this.state.run_type == 'monthly' ? moment(item.start_day).format("MMM YYYY") : this.handleWeekCalculation(item.start_day)}</div>
                                                            <div className='col-md-2' style={{textAlign:"center"}}>{title_case(item.last_status)}</div>
                                                            <div className='col-md-2' style={{textAlign:"center"}}>{item.failed_accounts}</div>
                                                            <div className='col-md-2' style={{textAlign:"center"}}> {item.last_status=="failed" ? <button className="product_takeup_submit font btn btn-sm sizes" disabled={this.state.calling_api} onClick={(e)=>this.initiateRetry(item.max_id, e)}>Rerun</button>:<></>}</div>
                                                            <div className='col-md-2' style={{textAlign:"center"}}>{item.run_count}</div>
                                                        </div>
                                                    {/* </h4> */}
                                                    </Accordion.Toggle>
                                                </div>
                                                <div className="card-body no-padding justify-content-center">
                                                    <Accordion.Collapse eventKey={index + 1}>
							                            <Card.Body className='settl-card-background m-1'>
                                                            <div className='row align-items-center'>
                                                                <div className='col-3'>
                                                                    <p className='text-left m-0' style={{fontSize:"15px",color:"white"}}>Run History</p>
                                                                </div>
                                                                <div className='col-3 '>
                                                                    <div class="form-check d-flex align-items-center" >
                                                                        <input type="checkbox" className='mr-2' style={{height:"17px", width:"14px"}} value="all_run_history" id="all_run_history" onChange={(e) => this.FilterRunHistory(e.target.checked, index)} />
                                                                        <span className='m-0' for="all_run_history" ><p className='m-0' style={{fontSize:"15px",color:"white"}}>View all run history</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <p className='text-right m-0' style={{fontSize:"15px",color:"white"}}>Range : {moment(item.run_history[0].range_start).format("MMM DD, YYYY hh:mm A")} - {moment(item.run_history[0].range_end).format("MMM DD, YYYY  hh:mm A")}</p>
                                                                </div>
                                                            </div>
                                                            <BasicReactTable default_page_size={20}  row_data={item.run_history} column_data={this.state.columns} />

                                                        </Card.Body>
						                            </Accordion.Collapse>
                                                </div>
                                            </Card>
                                )})}
                            </Accordion>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show_retry_modal} onHide={()=>this.handleModalClose("retry")} size = 'lg' >
                     <div className="row ml-0">
                            <div className="bg-black text-white pt-2 pb-2 col-8">
                                <div className="card-body" style={{fontSize:"20px"}}><b className='text-light'>Rerun Settlement</b></div>
                            </div>
                            <div className="bg-black text-white col-4 row justify-content-end">
                                <img src='/img/settl-cancel.png' onClick={()=>this.handleModalClose("retry")} className='mt-4' style={{height:"20px"}}/> 
                            </div>
                    </div>
                    <Modal.Body>
                        <div>
                                <div className='text-light'>
                                 <b>Note: </b>
                                 <ul>
                                    <li>
                                    Ensure that all the issues reported in the previous runs have been resolved for each account before selecting to run again. 
                                    </li>
                                    <li>
                                    The accounts that have been successfully settled will not be executed again and the same details will be displayed in the report.
                                    </li>
                                </ul>   
                                 </div>
                            <div className='mt-5'>
                                <b className='text-light' style={{fontSize:"20px"}}>Select accounts to rerun</b><br/><br/><br/>
                                <span
                                                    class="d-inline-block"
                                                    data-toggle="popover"
                                                    data-trigger="focus"
                                                    data-content="Please selecet account(s)"
                                                    style={{width:"400px",color:'black',marginLeft:"15px"}}
                                                >
                                                    {/* <ReactSelect
                                                    options={this.state.retry_accounts}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    allowSelectAll={true}
                                                    isOptionDisabled={(option) => option.status == 'success'}
                                                    onChange={this.handleSelect}

                                                    /> */}
                                                <Select
                                                    options={this.state.retry_accounts}
                                                    isMulti
                                                    value={selectedOptions}
                                                    onChange={this.handleChange}
                                                    isOptionDisabled={(option) => option.status == 'success'}
                                                    components={{
                                                    MultiValueLabel: this.CustomMultiValueLabel
                                                    }}
                                                />
                                            </span>
                                          
                            </div>
                            <div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <div>
                                    { (check_priv('settlement', 'run_now'))  && <button className="product_takeup_submit font sizes btn btn-sm" disabled={this.state.calling_api} onClick={()=>this.handleReschApicall(true, this.state.retry_accounts[0].max_id)}>Rerun Now</button>}
                                    <button className="product_takeup_submit font sizes btn btn-sm" disabled={this.state.calling_api} onClick={()=>this.handleReschApicall(false, this.state.retry_accounts[0].max_id)}>Schedule</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.show_history_modal} onHide={()=>this.handleModalClose("history")} size = 'lg'>
                    <div className="row ml-0 bg-black mr-0">
                            <div className="text-white pt-2 pb-2 col-9">
                                <div className="card-body" style={{fontSize:"20px"}}><b className='text-light'>Settlement Results : {this.state.result_range}</b></div>
                            </div>
                            <div className="ext-white col-3 row justify-content-end">
                                <i class="fa fa-times-circle mt-4" style={{color:"gray"}} onClick={()=>this.handleModalClose("history")} ></i>
                            </div>
                    </div>
                    <div className='p-3'>
                        <div className='mt-3 mb-3'><b className='text-light ml-4'>Note : </b></div>
                        <p className='text-light ml-4'>Below are the tentative next steps to take for each scenario:</p>
                        <ul>
                            <li className='text-light'><label >Failed / Error </label>:  Report this to L2 and have them resolve the issue.</li>
                            <li className='text-light'><label>Inconsistencies in Statement Comparison </label>:  Report to L2. Ensure that they fix the underlying issue and correct the data.</li>
                            <li className='text-light'><label>Unknown Transactions </label>:  Work with the business team to identify and account for these transactions.</li>
                            <li className='text-light'><label>Other Inconsistencies in Recon </label>:  Investigate these transactions using the Excel sheet attached and the Flow App and understand the root causes. Involve L2 if necessary.</li>
                        </ul>
                        <p className='text-light ml-4'>In all the above cases, ensure that the process is rerun for those accounts once issues are resolved.</p>
                    </div>
                    <Modal.Body style={{minHeight : '400px'}}>
                        <>
                         <div className="row">
                            <div className="btn-group-sm mt-3 col-7">
                                <button type="button" className={`${this.state.filter_button == 'all' ? 'btn btn-primary':  'btn btn-light'}`} onClick={()=>this.handleHistoryFilter("All", this.state.history)}>All</button>
                                <button type="button" className={`${this.state.filter_button == 'success' ? 'btn btn-primary':  'btn btn-light'}`} onClick={()=>this.handleHistoryFilter("Success", this.state.history)}>Success</button>
                                <button type="button" className={`${this.state.filter_button == 'failed' ? 'btn btn-primary':  'btn btn-light'}`} onClick={()=>this.handleHistoryFilter("Failed", this.state.history)}>Failed</button>
                            </div>
                            <div className='col-2 mt-3 mr-6 mr-5'>
                                <button className="product_takeup_submit font sizes btn btn-sm" disabled={this.state.calling_api} onClick={()=>this.handleEmailApicall()}>Send Report</button>
                             </div>
                            { this.state.history.length > 0 &&
                             <div className="col-2 mt-3 row justify-content-end">
                                 <a className="product_takeup_submit btn btn-sm text-light font" href={this.state.report_pdf} title='Download Balance Report'><i class="fa fa-file-pdf mr-2"></i>Balance Report</a>
                             </div>}
                         </div>
                        </>
                        <br/>
                        <div>
                            <label>Total Outstanding : <label>{this.state.total_os}</label></label><br/>
                            <label  className={`${this.state.unknown_txn_percen > this.state.unknown_threshold_percn ?'text-danger':'text-light'}`}>Unknown Amount Percentage : {this.state.unknown_txn_percen} % <label className='ml-2'>[Unknown transaction amount percentage should not be greater than {this.state.unknown_threshold_percn} % .]</label> </label>
                        </div>

                        {this.state.filtered_history.length>0 ? this.state.filtered_history.map((item, index)=>{
                            return(
                                <div className='mt-5 ml-5 mr-5'>
                                    <fieldset className='text-light settl-fieldset-border p-2 my-2'>
                                        <legend style={{width:"17vw", marginLeft:"45px", paddingLeft:"41px", paddingRight:"20px"}}>{!item.is_over_all ? <div><b className='step-exist-text' >{item.acc_prvdr_code} - {item.acc_number}</b>{item.step_info.step_one || item.step_info.step_two || item.step_info.step_three || item.step_info.step_four ? <button type="button" class="btn btn-sm btn-info ml-3" onClick={()=>this.handleDownloadFolder(item)}><i class="fa fa-download"></i></button> : null}</div> : <b className='step-exist-text' >Overall Checks</b>}</legend>
                                            <div>
                                                {item.excel_file &&
                                                    <div class="row justify-content-end mr-4">
                                                        <a className="text-white download_sheet" href={item.excel_file} title='Download Differences Sheet' style={{ fontSize: '13px' }}><i class="fa fa-file-excel mr-1"></i> Differences Sheet</a>
                                                    </div>
                                                }
                                            </div>
                                            {!item.is_over_all &&
                                            <div>
                                            <Accordion>
                                                <Card className='mt-2 mb-0'>
                                                    <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                        <Accordion.Toggle className = {`${item.step_info.step_one && item.step_info.step_one.status != 'success' ? "col" : "col invisible"}`} as = {Card.Header} eventKey={`${index}0`} >
                                                            <div>
                                                                <BsChevronDown className = {this.state.show_failed_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" onClick = {()=>this.handleToggle("stage")} style={{marginTop: "-59%", marginRight:"-12%"}}/>
                                                            </div>
                                                        </Accordion.Toggle>
                                                        <Accordion.Toggle className = 'card-title w-100 text-white mt-2' as = {'h4'} eventKey={`${index}0`} >

                                                        {/* <h4 className="card-title w-100 text-white mt-2"> */}
                                                            <div className='d-flex justify-content-around row'>
                                                                {/* <div className={`col-md-4 ${item.step_info.step_one ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}>Step One</div> */}
                                                                <div className={`col-md-4 ${item.step_info.step_one ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}>Import Statement</div>
                                                                <div className='col-md-4 text-center' style={{fontSize:"12px", fontWeight:"bold"}}>{item.step_info.step_one ? item.step_info.step_one.status == 'success' ? <img src='/img/settl-check.png'/>:<img src='/img/settl-cancel.png'/> : <></>}</div>
                                                            </div>
                                                        {/* </h4> */}
                                                        </Accordion.Toggle>
                                                    </div>
                                                    <div className="card-body no-padding justify-content-center">
                                                        <Accordion.Collapse eventKey={`${index}0`}>
							                                <Card.Body>
                                                            {item.step_info.step_one && item.step_info.step_one.status != 'success' && item.step_info.step_one.messages.length>0 && item.step_info.step_one.messages.map((lambda, index)=>{
                                                                    return(
                                                                        <div className='text-light ml-4 mt-2' style={{fontSize:"12px", fontWeight:"bold"}}>{lambda}</div>
                                                                    )})}
                                                            </Card.Body>
						                                </Accordion.Collapse>
                                                    </div>
                                                </Card>
                                            </Accordion>

                                            <Accordion>
                                                <Card className='mt-2 mb-0'>
                                                    <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                        <Accordion.Toggle className = {`${item.step_info.step_two && item.step_info.step_two.status != 'success' ? "col" : "col invisible"}`} as = {Card.Header} eventKey={`${index}1`} >
                                                            <div>
                                                                <BsChevronDown className = {this.state.show_failed_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" onClick = {()=>this.handleToggle("stage")}  style={{marginTop: "-59%", marginRight:"-12%"}}/>
                                                            </div>
                                                        </Accordion.Toggle>

                                                        <Accordion.Toggle className = "card-title w-100 text-white mt-2" as = {'h4'} eventKey={`${index}1`} >

                                                        {/* <h4 className="card-title w-100 text-white mt-2"> */}
                                                            <div className='d-flex justify-content-around row'>
                                                                {/* <div className={`col-md-4 ${item.step_info.step_two ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}><b>Step Two</b></div> */}
                                                                <div className={`col-md-4 ${item.step_info.step_two ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}><b>Sync Statement</b></div>
                                                                <div className='col-md-4 text-center' style={{fontSize:"12px", fontWeight:"bold"}}>{item.step_info.step_two ? item.step_info.step_two.status == 'success' ? <img src='/img/settl-check.png'/>:<img src='/img/settl-cancel.png'/> : <></>}</div>
                                                            </div>
                                                        {/* </h4> */}
                                                        </Accordion.Toggle>
                                                    </div>
                                                    <div className="card-body no-padding justify-content-center">
                                                        <Accordion.Collapse eventKey={`${index}1`}>
							                                <Card.Body>
                                                            {item.step_info.step_two && item.step_info.step_two.status != 'success' && item.step_info.step_two.messages.length>0 && item.step_info.step_two.messages.map((lambda, index)=>{
                                                                    return(
                                                                        <div className='text-light ml-4 mt-2' style={{fontSize:"12px", fontWeight:"bold"}}>{lambda}</div>
                                                                    )})}
                                                            </Card.Body>
						                                </Accordion.Collapse>
                                                    </div>
                                                </Card>
                                            </Accordion>

                                            <Accordion>
                                                <Card className='mt-2 mb-0'>
                                                    <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                        <Accordion.Toggle className = {`${item.step_info.step_three && item.step_info.step_three.status != 'success' ? "col" : "col invisible"}`} as = {Card.Header} eventKey={`${index}2`} >
                                                            <div>
                                                                <BsChevronDown className = {this.state.show_failed_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" onClick = {()=>this.handleToggle("stage")} style={{marginTop: "-59%", marginRight:"-12%"}}/>
                                                            </div>
                                                        </Accordion.Toggle>

                                                        <Accordion.Toggle className = 'card-title w-100 text-white mt-2' as = {'h4'} eventKey={`${index}2`} >
                                                        {/* <h4 className="card-title w-100 text-white mt-2"> */}
                                                            <div className='d-flex justify-content-around row'>
                                                                {/* <div className={`col-md-4 ${item.step_info.step_three ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}><b>Step Three</b></div> */}
                                                                <div className={`col-md-4 ${item.step_info.step_three ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}><b>Recon</b></div>
                                                                <div className='col-md-4 text-center' style={{fontSize:"12px", fontWeight:"bold"}}>{item.step_info.step_three ? item.step_info.step_three.status == 'success' ? <img src='/img/settl-check.png'/>:<img src='/img/settl-cancel.png'/> : <></>}</div>
                                                            </div>
                                                        {/* </h4> */}
                                                        </Accordion.Toggle>
                                                    </div>
                                                    <div className="card-body no-padding justify-content-center">
                                                        <Accordion.Collapse eventKey={`${index}2`}>
							                                <Card.Body>
                                                                {item.step_info.step_three && item.step_info.step_three.status != 'success' && item.step_info.step_three.messages.length>0 && item.step_info.step_three.messages.map((lambda, index)=>{
                                                                    return(
                                                                        <div className='text-light ml-4 mt-2' style={{fontSize:"12px", fontWeight:"bold"}}>{lambda}</div>
                                                                    )})}
                                                            </Card.Body>
						                                </Accordion.Collapse>
                                                    </div>
                                                </Card>
                                            </Accordion>

                                            <Accordion>
                                                <Card className='mt-2 mb-0'>
                                                    <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                        <Accordion.Toggle className ={`${item.step_info.step_four  ? "col" : "col invisible"}`} as = {Card.Header} eventKey={`${index}3`} >
                                                            <div>
                                                                <BsChevronDown className = {this.state.show_failed_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" onClick = {()=>this.handleToggle("stage")} style={{marginTop: "-59%", marginRight:"-12%"}}/>
                                                            </div>
                                                        </Accordion.Toggle>

                                                        <Accordion.Toggle className = 'card-title w-100 text-white mt-2' as = {'h4'} eventKey={`${index}3`} >
                                                        {/* <h4 className="card-title w-100 text-white mt-2"> */}
                                                            <div className='d-flex justify-content-around row'>
                                                                {/* <div className={`col-md-4 ${item.step_info.step_four ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}><b>Step Four</b></div> */}
                                                                <div className={`col-md-4 ${item.step_info.step_four ? 'step-exist-text':'step-not-exist-text'}`} style={{fontSize:"12px", fontWeight:"bold"}}><b>Balance Matching</b></div>
                                                                <div className='col-md-4 text-center' style={{fontSize:"12px", fontWeight:"bold"}}>{item.step_info.step_four ? item.step_info.step_four.status == 'success' ? <img src='/img/settl-check.png'/>:<img src='/img/settl-cancel.png'/> : <></>}</div>
                                                            </div>
                                                        {/* </h4> */}
                                                        </Accordion.Toggle>
                                                    </div>
                                                    <div className="card-body no-padding justify-content-center">
                                                        <Accordion.Collapse eventKey={`${index}3`}>
							                                <Card.Body>
                                                                {item.step_info.step_four && item.step_info.step_four.status != 'success' && item.step_info.step_four.messages.length>0 && item.step_info.step_four.messages.map((lambda, index)=>{
                                                                    return(
                                                                        <div className='text-light ml-4 mt-2' style={{fontSize:"12px", fontWeight:"bold"}}>{lambda}</div>
                                                                    )})}
                                                                {item.step_info.step_four && item.step_info.step_four.balance_report && item.step_info.step_four != 'preferred_condition_failed' &&
                                                                 <div className='row ml-2 mt-2'>
                                                                     <div className = 'col-4'>
                                                                        <div className='font'>Derived Closing Balance</div>
                                                                        <div className='font text-light sizem'>{Number(item.step_info.step_four.balance_report.expected_balance).toLocaleString()}</div>
                                                                     </div>
                                                                    <div className='col-3 font text-light sizel'>{item.step_info.step_four.balance_report.expected_balance == item.step_info.step_four.balance_report.closing_balance ? "=" : "=/="}</div>
                                                                     <div className = 'col-4'>
                                                                        <div className='font'>Actual Closing Balance</div>
                                                                        <div className='font text-light sizem'>{Number(item.step_info.step_four.balance_report.closing_balance).toLocaleString()}</div>
                                                                     </div>
                                                                </div>}
                                                            </Card.Body>
						                                </Accordion.Collapse>
                                                    </div>
                                                </Card>
                                            </Accordion>
                                            </div> }
                                            {item.is_over_all && 
                                                <div>
                                                    <Accordion>
                                                        <Card className='mt-2 mb-0'>
                                                            <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                                <Accordion.Toggle className={`${item.step_info.step_one && item.step_info.step_one.status != 'success' ? "col" : "col invisible"}`} as={Card.Header} eventKey={`${index}1`} >
                                                                    <div>
                                                                        <BsChevronDown className={this.state.show_failed_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" onClick={() => this.handleToggle("stage")} style={{ marginTop: "-59%", marginRight: "-12%" }} />
                                                                    </div>
                                                                </Accordion.Toggle>

                                                                <Accordion.Toggle className="card-title w-100 text-white mt-2" as={'h4'} eventKey={`${index}1`} >
                                                                    <div className='d-flex justify-content-around row'>
                                                                        <div className={`col-md-5 p-0 ${item.step_info.step_one ? 'step-exist-text' : 'step-not-exist-text'}`} style={{ fontSize: "12px", fontWeight: "bold" }}><b>Cash Flow Analysis - Float Advance</b></div>
                                                                        <div className='col-md-2 p-0 text-left' style={{ fontSize: "12px", fontWeight: "bold" }}>{item.step_info.step_one ? item.step_info.step_one.status == 'success' ? <img src='/img/settl-check.png' /> : <img src='/img/settl-cancel.png' /> : <></>}</div>
                                                                    </div>
                                                                </Accordion.Toggle>
                                                            </div>
                                                            <div className="card-body no-padding justify-content-center">
                                                                <Accordion.Collapse eventKey={`${index}1`}>
                                                                    <Card.Body>
                                                                        {item.step_info.step_one && item.step_info.step_one.status != 'success' && item.step_info.step_one.messages.length > 0 && item.step_info.step_one.messages.map((lambda, index) => {
                                                                            return (
                                                                                <div className='text-light ml-4 mt-2' style={{ fontSize: "12px", fontWeight: "bold" }}>{lambda}</div>
                                                                            )
                                                                        })}
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </div>
                                                        </Card>
                                                    </Accordion>

                                                    <Accordion>
                                                        <Card className='mt-2 mb-0'>
                                                            <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                                                <Accordion.Toggle className={`${item.step_info.step_two && item.step_info.step_two.status != 'success' ? "col" : "col invisible"}`} as={Card.Header} eventKey={`${index}1`} >
                                                                    <div>
                                                                        <BsChevronDown className={this.state.show_failed_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" onClick={() => this.handleToggle("stage")} style={{ marginTop: "-59%", marginRight: "-12%" }} />
                                                                    </div>
                                                                </Accordion.Toggle>

                                                                <Accordion.Toggle className="card-title w-100 text-white mt-2" as={'h4'} eventKey={`${index}1`} >
                                                                    <div className='d-flex justify-content-around row'>
                                                                        <div className={`col-md-5 p-0 ${item.step_info.step_two ? 'step-exist-text' : 'step-not-exist-text'}`} style={{ fontSize: "12px", fontWeight: "bold" }}><b>Cash Flow Analysis - Float Switch</b></div>
                                                                        <div className='col-md-2 p-0 text-left' style={{ fontSize: "12px", fontWeight: "bold" }}>{item.step_info.step_two ? item.step_info.step_two.status == 'success' ? <img src='/img/settl-check.png' /> : <img src='/img/settl-cancel.png' /> : <></>}</div>
                                                                    </div>
                                                                </Accordion.Toggle>
                                                            </div>
                                                            <div className="card-body no-padding justify-content-center">
                                                                <Accordion.Collapse eventKey={`${index}1`}>
                                                                    <Card.Body>
                                                                        {item.step_info.step_two && item.step_info.step_two.status != 'success' && item.step_info.step_two.messages.length > 0 && item.step_info.step_two.messages.map((lambda, index) => {
                                                                            return (
                                                                                <div className='text-light ml-4 mt-2' style={{ fontSize: "12px", fontWeight: "bold" }}>{lambda}</div>
                                                                            )
                                                                        })}
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </div>
                                                        </Card>
                                                    </Accordion>
                                                </div>}
                                    </fieldset>
                                </div>
                            )}): <div className='text-light text-center'><b>No data found</b></div>}
                    </Modal.Body>
                </Modal>
            </div>

        )
    }

    initiateRetry(max_id, event) {
        event.stopPropagation()
        let req = {max_id, country: this.state.country_code}
        getRetryAccounts(this.req(req)).then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                let options = response.data.map((i) => {
                    return {value: i.account_id, label: `${i.acc_prvdr_code} - ${i.acc_number}`, status: i.status, max_id}
                })

                this.setState({retry_accounts: options, show_retry_modal: true})
            }
        });
    }
}

            
export default SettlementComponent;
