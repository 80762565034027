import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import { Homeacc_prvdrLogoselector, get_path} from '../../helpers/common_helper';
import { Link} from 'react-router-dom';
import {TooltipLabel} from "../common/partial";
import { RMNameSplit } from '../../helpers/common_helper';
import { get } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortBizcommon } from '../../helpers/sortingHelpers';
import Accordion from 'react-bootstrap/Accordion';
import {Button, Card, Modal} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import GoogleMapSetup from '../../helpers/googleMap';
import { allowForceCheckin, forceCheckIn } from '../../actions/field_visit_actions';
import DisplayImage from '../common/component/DisplayImage';
import { BasicReactTable } from '../../helpers/react_table';

class ForceCheckIn extends FlowComponent {

	state = {
		columns :[],
		lists : [],
		copy:[],
		all_count:0,				
		each_count:[],
		all_Acc_pvdrs:[],	
		show_table: false,
		reject_force_checkin:false
	}
	handleToggle = (e) => {
        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
	componentDidMount() {
			this.drawTable()		
    }
	sortBiz (a,b) {		
		return sortBizcommon(a.props.full_text,b.props.full_text)
	}
	columndatas () {
		return [
            {
				Header : "Account Provider",
				id: 'acc_prvdr_code' ,
				sortable: false,
				accessor:row =>{return <div className='d-flex '><Homeacc_prvdrLogoselector  logotype={row.acc_prvdr_code}/></div> }
			},
            {
				Header : "Cust ID",
				accessor: 'cust_id' ,
				Cell: e =>  <Link to={"/borrower/indiv/view/"+e.value} target="_blank" >{e.value}</Link>,
			
			},
			{
				Header : "Biz Name",
				id: 'biz_name', 
				sortMethod : (a,b) => this.sortBiz(a,b),
				accessor: row => <TooltipLabel full_text={row.biz_name} overlay={row.biz_name} no_reduce={true} />
			},
			{
				Header : "Mobile Number",
				sortable: false,
				id: 'mobile_num',
				accessor:row=>{return row.mobile_num}

			},
			{
				Header : "Requested RM Name",
				sortable: true,
				id:"req_rm_name",
				accessor: row => <div className='rmUpper_case'>{row.req_rm_name !==null && RMNameSplit(row.req_rm_name)}</div>,
			},
			
			{
				Header : "Action",
				sortable: false,
				accessor: 'id' ,
				Cell: ({ row }) =>{return (<button onClick={()=>this.setState({force_check_in_details:[row.original], force_checkin:true})} className='btn primary_color_btn'>Action</button>)}}
	]}
	drawTable () {
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})		
		.then((response)=>{
			this.resp(response)
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs) 
				this.filter_count()			           
			}
		});
		this.getForceCheckIn()
	}
	getForceCheckIn=()=>{
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
		forceCheckIn({...req, status: 'enabled', biz_account: true})
			.then((response) => {	
				this.resp(response)			
				if(!response){return};
				if(response.status === "success"){
					this.setState(state => state.columns = this.columndatas())																															
						this.setState({lists:response.data, all_count : response.data.length}, ()=>this.filter_acc('All'))
						// this.setState(state=>state.all_count = response.data.length)																					
						
						this.filter_count(response.data)
					}
			})
	}
	filter_count=(lists = this.state.lists)=>{
		var count=[]
		const filter_each=(a)=>{
			let each=lists.filter((item)=>item.acc_prvdr_code==a)
			count.push(each.length)}
		this.state.all_Acc_pvdrs.map((item)=>filter_each(item))
		this.setState({each_count:[...count]})
	}
	filter_acc=(acc)=>{
		this.setState({copy:[]})
		if(acc=='All'){
			this.setState({copy:this.state.lists})
		}
		else{
		let easy =this.state.lists.filter((item,index)=>item.acc_prvdr_code==acc)
		this.setState({copy:easy})}
	}
	handleForceCheckIn = (id, action) =>{
		const resp = window.confirm("Are you sure to "+ action +" Force Check-In?")
		
		if(!resp){
			return;
		}else{
			const cust_id = id
			allowForceCheckin(this.req({cust_id, action, remarks :this.state.remarks}))
				.then((response) => {
					this.resp(response)
					if(!response){return};
				if(response.status === "success"){
				alert(response.message);
				this.setState({force_checkin : false, reject_force_checkin : false})
				this.getForceCheckIn()
				}else{
					alert(response.message);  
					this.getForceCheckIn()
				}
			})
		}
	}

	handleForceCheckInCloseModal = (event) =>{
		this.setState({force_checkin:false,opacity:""})
	}


	handleRejectReasonModal = (event) =>{
		
		this.setState({reject_force_checkin:!this.state.reject_force_checkin,opacity:""})
	}

	handleModalChange = (event) =>{
 
		this.setState({remarks:event.target.value})
	 }


    render(){	
        return (<>

		<Modal show={this.state.reject_force_checkin} onHide={this.handleRejectReasonModal}  size="l"  >
						<Modal.Header closeButton className="modal-close-btn" show={this.state.reject_force_checkin} onHide={this.handleRejectReasonModal}>
							<Modal.Title>Reject Reason</Modal.Title>
						</Modal.Header>
						<Modal.Body  >
				
						<div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
								<label className="col-sm-5 text-center mt-4"><b>Remarks</b></label>
							<div className="col-sm-7 reset-padding">
								<textarea id = "remarks" type="text" onChange = {this.handleModalChange} className="form-control reset-padding"/>
							</div>
						</div> 
						
						</Modal.Body>
						<Modal.Footer>
					{this.state.force_check_in_details && this.state.force_check_in_details.map((item, index) => (
						<div key={index}>
						<Button variant="primary" disabled={this.state.calling_api} style={{ marginLeft: 10 }} onClick={() => this.handleForceCheckIn(item.cust_id, 'reject')}>Subimt</Button>
						</div>
					))}
					</Modal.Footer>
            </Modal> 


			<Modal show={this.state.force_checkin} onHide={this.handleForceCheckInCloseModal} size="xl" backdrop='static'>
				<Modal.Header closeButton className=" p-0" show={this.state.force_checkin} onHide={this.handleForceCheckInCloseModal}>
					<Modal.Title class="modal-title modal_header_bg p-4" style={{fontSize:"larger"}}  >FORCE CHECK-IN REQUEST </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.state.force_check_in_details && this.state.force_check_in_details.map((item, index) => {
					return (
						<div key={index}>
						<div>
							<div className='row text-center justify-content-center'>
							<div className='col-md-6 for_modal_img'>
								<h6 className='col-md-12 force_font p-0' style={{color:'white'}}>Customer Profile Photo</h6>
								<img className='w-100 h-100 col-12 modal_img_height' src={`${get_path("view_file")}${item.profile_photo}`} alt='Customer Photo' style={{color:'white'}} />
							</div>
							<div className='col-md-6 for_modal_img'>
								<h6 className='col-md-12 force_font p-0 ' style={{color:'white'}}>Today's Selfie With Customer</h6>
								<img className='w-100 h-100 col-12 modal_img_height' src={`${get_path("view_file")}${item.photo_checkin_selfie}`} alt='Customer selfie with RM' style={{color:'white'}}/>
							</div>
							<div className='px-2 force_note mt-5'>
								<h6 className='force_font w-100'><span className='text_red'>Note : </span><span style={{color:'white'}}>Compare customer profile photo with current selfie photo of RM with the customer</span></h6>
								<hr className='for_hr' />
							</div>
							</div>
							<GoogleMapSetup coordinates={item.old_gps} current_coordinates={item.current_gps} height={350} />
						</div>
						<div className=' m-0 d-flex justify-content-center'>
							<div className="modal-footer text-center d-flex justify-content-center">
							<button type="button" className='primary_color_btn btn btn-lg' onClick={() => this.handleForceCheckIn(item.cust_id, 'allow')}>Allow Force check-in</button>
							</div>
							<div className="modal-footer text-center d-flex justify-content-center">
							<button type="button" className='btn btn-danger btn-lg' onClick={() => this.handleRejectReasonModal()}>Reject Force check-in</button>
							</div>
						</div>
						</div>
					);
					})}
				</Modal.Body>
			</Modal>
			{/* <div  className='font modal fade' id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={0}  role="dialog"  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered d-flex justify-content-center force_checkin_modal" role="document">
							<div class="modal-content width_for_modal modal_body_bg">
							{ this.state.force_check_in_details&&this.state.force_check_in_details.map((item,index)=>{return(
								<div key={index}>
							<div  class="modal-header width_for_modal modal_header_bg  p-0  m-0 mt-0  w-100 align-items-center">
								<h4 class="modal-title modal_header_bg p-3" id="exampleModalLongTitle">FORCE CHECK-IN REQUEST</h4>
								<button type="button" class="btn bg-white btn-sm text-secondary font px-2 m-2" data-bs-dismiss="modal" aria-label="Close">
								x
								</button>
							</div>
							<div class="modal-body width_for_modal ">
								<div className='row text-center justify-content-center'>
									<div className='col-md-6 for_modal_img'>
										<h6 className='col-md-12 force_font p-0'>Customer Profile Photo</h6>
										<img className='w-100 h-100 col-12 modal_img_height'   src={`${this.root}${item.profile_photo}`} alt='Customer Photo'/>
									</div>
									<div className='col-md-6 for_modal_img'>
										<h6 className='col-md-12 force_font p-0 '>Today's Selfie With Customer</h6>
										<img className='w-100 h-100 col-12 modal_img_height' src={`${this.root}${item.photo_checkin_selfie}`}alt='Customer selfie with RM'/>
									</div>
									<div className='px-2 force_note'>
										<h6 className='force_font w-100'><span className='text_red'>Note : </span>Compare customer profile photo with current selfie photo of RM with the customer</h6>
										<hr className='for_hr'/>
									</div>
								</div>
							<GoogleMapSetup coordinates={item.old_gps} current_coordinates={item.current_gps} height={false} />
							</div>
							<div className='row m-0 d-flex justify-content-center'>
							<div class="modal-footer text-center d-flex justify-content-center">
									<button type="button" className='primary_color_btn btn btn-lg'  onClick={()=>this.handleForceCheckIn(item.cust_id,'allow')}  >Allow Force check-in</button>
								</div>
								<div class="modal-footer text-center d-flex justify-content-center">
									<button type="button" className='danger_color_btn btn btn-lg'  onClick={()=>this.handleForceCheckIn(item.cust_id,'reject')}  data-bs-toggle="my_modal" data-bs-target="#reson_reject">Reject Force check-in</button>
								</div>
							</div>
							</div>
							)})} 
							</div>
							
						</div>
					</div> */}
			<Accordion>
                <Card>
					<div className={`${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
					<div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
						<h4 className="card-title text-white tableHead_font title-bottom">Force Check-in Request</h4>
						{this.state.lists.length > 0 &&<>
								<div className='countREL card-title' onClick={()=>this.filter_acc('All')}>
									<button className={'filterBtns allBtn_size'}  >ALL</button>
									<div className='countABS' >
										<label>{this.state.all_count}</label>
									</div>
								</div>						
								{this.state.all_Acc_pvdrs.map((each,idx)=>									
									<div key={idx} className='countREL  card-title'>																					
											<>
												<div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.filter_acc(each)}>													  
													<img className='img-fluid' src={`/img/${each}_logo_color.png`}></img> 																	
												</div>
												<div className='countABS' >													
													<label>{this.state.each_count[idx]}</label>																																
												</div>
											</>										
									</div>																		
								)}
						</>
						}
						<Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
					</div>

					</div>
										
					<div className="card-body no-padding">
						<Accordion.Collapse eventKey="0">
							<Card.Body className='react_common_table'>
							<BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true}    default_page_size={50}  defaultSorted={[{id:"lead_date",desc:true}]} />  

								{/* <CommonReactTable showPag={false} defaultPageSize={50} minRow={0} row_data={ this.state.copy} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"lead_date",desc:true}]}/>						 */}
							</Card.Body>
						</Accordion.Collapse>
					</div>
				</Card>
            </Accordion>
			</>
        )}
}
export default ForceCheckIn;