import { Navigate } from 'react-router-dom';
import React from 'react';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import { Modal , Button, Row, Col} from 'react-bootstrap';
import {RepaymentDetail} from '../../common/CommonMessages';
import {lbl_amt, lbl_flow_fee_obj, lbl_repayment_amt } from '../../../helpers/product_helper';
import {capitalize_first_letter,rt_algn, fa_product_action, get_result_msg,get_eligibility} from '../../../helpers/common_helper';
import CustPanel from '../component/CustPanel';
import Agreement from '../../borrower/component/Agreement';
import {viewCustomerDetails, validateAppl, request_fa_upgrade, request_fa_upgrade_status_web, applyLoan} from '../../../actions/loan_appl_actions';
import LoanApplSummary from '../component/LoanApplSummary';
import LastNFAs from '../component/LastNFAs';
//import InputGroup from 'react-bootstrap/InputGroup';
import {CustScore, FAProductActionOverlay} from '../../common/partial';
import {dd_value, getListByUrl} from '../../../actions/common_actions';
import $ from 'jquery';
import {viewBorrower} from "../../../actions/borrower_actions";
import Table from 'react-bootstrap/Table';
import {check_priv, Config, get} from '../../../helpers/storage_helper';
import {RoundOffSplitter} from '../../../helpers/common_helper';

import {OverlayTrigger, Popover} from "react-bootstrap";
import { Tooltip } from 'chart.js';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';
import ConfirmModal from '../../common/component/ConfirmModal';

import { connect } from 'react-redux';
import {setValuesIntoRedux} from '../../../Redux/Slices/CommonSlice';
import Softphone from '../../../../src/UI/cs_management/container/cs_soft_phone'

class ProductSelectionContainer extends FlowContainer {

    state = {
	           customer_div_style: "hide_div",
             comp_name: "product",
	           customer: {},
             modalBox: false,
             toSummary: false,
             CSvalue: false,
             searchResult: false,
             cs_result_code:"",
             search_loan: 'show_div',
             acc_selected: false,
             amount: null,
             requested: false,
             popup_modal_box:false,
             row_data:[],
             column_data:[],
            };
 
    componentWillMount(){
      this.currency_code = get('market') && get('market')['currency_code']
      if(this.props.mode == 'result'){
          this.setState({search_loan :"hide_div"});
          this.props.setValuesIntoRedux({screen_from:"fa_apply"})
      }
      this.get_borrower_and_cust_accs()
    }

    get_borrower_and_cust_accs = (get_cust_accs = true)=>{
      if(this.props && this.props.params.cust_id){
        this.customer_id = this.props.params.cust_id;
        this.props.setValuesIntoRedux({cust_id:this.customer_id})

        viewBorrower(this.req({cust_id: this.customer_id, screen: "view"}, true))
			.then((response) => {
        this.resp(response)


			if(!response){return };
            this.state.customer_div_style = 'show_div'
            response.data.cust_mobile_num = response.data.owner_person.mobile_num
            response.data.cust_addr_text = response.data.cust_addr_txt
			this.setState({customer : response.data, loan_application_exists_mgs: response.data?.application_exists_msg,
                               cust_agreement_status: response.data.aggr_status
						   });

         const status = "enabled";
        const cust_id = response.data.cust_id
        const acc_purpose = 'float_advance';
        const customer_account = {cust_id,status,acc_purpose};
        // this.setOptionsListToStateFromAppApi("common","customer_accounts", this.req({customer_account}), ["account_id", "acc_num_disp_txt"])
          if(get_cust_accs){
            getListByUrl('common', 'customer_accounts', this.req({customer_account}))

                  .then((response) => {
                    this.resp(response)
                     if(!response){return };
                     if(response.status === "success"){
                         if(response.data.list.length == 1){
                             this.getSelectOptionsFromResult(response.data.list, 'customer_accounts', ["account_id", "acc_num_disp_txt"], false);
                             let cust_account = {value: response.data.list[0].account_id}
                             this.setState({acc_selected: true, cust_account}, ()=>this.productSearch())
                             
                         }
                         else{
                            this.getSelectOptionsFromResult(response.data.list, 'customer_accounts', ["account_id", "acc_num_disp_txt"]);
                         }
                     }else{
                        alert(response.message + "\n" + JSON.stringify(response.data));
                     }

                  }
                );
          }

			}
		);
      }
    }


    handleEnter = (event) => {
      if (event.keyCode === 13) {
        this.handleSearchClick();
        
      }
    }

    handleCustIDChange = (event) => {
      this.customer_id = event.target.value;
    }
    handleProductSelectClick = (event, product_id, product_type, cust_score, result_code,duration=null,amount=null) => {

      if(this.customer.kyc_status == "completed"){
       
     
        this.product_type = product_type;
        // this.selectedProduct =  this.loan_products[event.target.id];
        var product = this.loan_products &&  this.loan_products.filter(val=>val.id == product_id);
        this.selectedProduct = product.length > 0 ? product[0] : null
        this.selectedProduct.currency_code = this.currency_code;
        if(cust_score == null)
        {
          this.setState({cust_score: 0});
        }else{
          this.setState({cust_score: cust_score,
                        cs_result_code : result_code});
        }
        const request = {product_id: product_id, cust_id: this.customer.cust_id, country_code : this.country_code, product_type: this.product_type, status: 
          "enabled",perf_eff_data : this.customer.perf_eff_date }
        validateAppl(this.req(request, true))
          .then((response) => {
            this.resp(response)
            if(!response){return };
              if(response.status === "success"){
                this.master_loan_doc_id = response.data.master_loan_doc_id;
                  this.setState({popup_modal_box: true,fa_duration:duration,fa_amount:amount})

                   // this.setState({toSummary: true}
                
                
                //this.selectedProduct =  this.loan_products[event.target.id];
                //this.selectedProduct.currency_code = this.currency_code;

              }else{

                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          );
      }
      else{
        alert("Could not allow new application. KYC status is "+this.customer.kyc_status);
      }
      
    
     

      //this.setState({toSummary: true});
     
    }

    handleClose = (event) => {
      this.setState({ modalBox: false});
    }
    handleSearchClick = (event) =>{
       //this.setState({ searchResult: false});

      if(this.customer_id != null)
      {
       
       //window.location.reload();
        this.setState({searchResult: true});
        //this.productSearch();
      }else{
        alert("Please Enter Customer ID or Mobile Number");
      }
    }

    // handleModifySearch = (event) => {
    //   this.setState({search_loan:"show_div"})
    // }

    productSearch(){
        this.requestUpgradeStatusWeb()
        const req_parameter = this.customer_id;
        const account_id = this.cust_account.value
        viewCustomerDetails(this.req({req_parameter, account_id}, true))
          .then((response) => {
            this.resp(response)
            if(!response) {return;}
              if(response.status === "error"){
                    this.setState({customer_div_style: "hide_div"});
                    return;
              }
              const result = response.data;
            
              this.setState({
                            'cust_agreement_status':response.data.cust_agreement_status,
                            'all_ineligible': response.data.all_ineligible,
                            
                          
              })
              if(result.hasOwnProperty("borrower")){
                
                  this.productList(result);
              }else if(result.hasOwnProperty("borrowers")){
                  this.borrowersList(result);    
              }
              if(result.all_ineligible == true)
              {
                alert("Customer is ineligible for all the FA products");
              }
              if(result.hasOwnProperty("late_loans")) {
                this.setState({
                  'late_loans': response.data.late_loans,
                  
                
    })
              }
          });
    }
  borrowersList(result){
      const borrowersList = result.borrowers.map((borrower,i)=> <tr>
                    <td> {borrower.biz_name}</td>
                    <td> {borrower.cust_id}</td>
                    <td> {borrower.acc_number}</td>
                    <td> <input id={i} type='submit' value='Select' onClick={(e) => this.handleBorrowerSearch(e, borrower.cust_id)} className='btn btn-primary' /></td>
                      </tr>
                      );

      this.setState({borrowersList: borrowersList});
      this.setState({modalBox: true});        
      $('#list_brwr').dataTable({           
          "bAutoWidth": true,
          "bDestroy": true, 
      });
  }

  accountSelect = (event) =>{
       this.setState({acc_selected: true, cust_account: this.cust_account})
       $('#list').DataTable().destroy();
       this.productSearch()
  }

  requestUpgradeStatusWeb(){
    const cust_id = this.state.customer.cust_id;
    request_fa_upgrade_status_web(this.req({cust_id}))
    .then((response) => {
      this.resp(response)
      if(response.data != null){
        this.setState({requested_amount: response.data.requested_amount})
      }
    })   
  }

  requestUpgrade(upgradable_amount){
    const cust_id = this.state.customer.cust_id
    alert(cust_id)
    const amount = upgradable_amount
    const acc_number = this.state.customer.acc_number
    const acc_prvdr_code = this.state.customer.acc_prvdr_code
    const task_type = 'fa_upgrade_request'
    const res = window.confirm(`Any amount under ${lbl_amt(upgradable_amount, this.currency_code)} will also be considered as requested amount.\nDo you want to continue?`)
    if (res){
        request_fa_upgrade(this.req({task_type, cust_id, amount, acc_number, acc_prvdr_code}, true))
          .then((response) => {
            this.resp(response)
            this.setState({amount: amount})
            if(response && response.message != null){
              this.setState({requested: true})
              window.location.reload()
            }
          }) 
      }        

  }
 
  fa_apply_modal_handleClose=(e)=>{
    this.setState({popup_modal_box:false})
  }
//

 submit_for_approval = (fa_appl_details) => {
  if(this.state.loan_application_exists_mgs){
    alert(this.state.loan_application_exists_mgs)
    return
  }
      this.setState({fa_appl_details,show_submit_alert:true})
 }

generate_column_datas = ()=>{

  return [
    {
      Header : <div className='d-flex justify-content-center'>Product Name</div>,
      id: "product_name",
      accessor: "product_name",
      Cell : (cell)=><div className='d-flex justify-content-center'>{cell.row.original.product_name}</div>,
      width:200

    },
    {
      Header : <div className='d-flex justify-content-center'>Cust Score</div>,
      id: "cust_score",
      accessor: "cust_score",
      Cell : (cell)=><div className='d-flex justify-content-center'>{<CustScore loan_product={cell.row.original} meta_data={this.meta_data} />}</div>

    },
    {
      Header : <div className='d-flex justify-content-center'>Product Type</div>,
      id: "product_type",
      accessor: "product_type",
      Cell : (cell)=><div className='d-flex justify-content-center'>{dd_value(cell.row.original.product_type)+ " / " + dd_value(cell.row.original.cs_model_code)}</div>,
      width:'250px'

    },
    {
      Header : <div className='d-flex justify-content-center'>FA Amount</div>,
      id: "max_loan_amount",
      accessor: "max_loan_amount",
      Cell : (cell)=><div className='d-flex justify-content-center'>{lbl_amt(cell.row.original.max_loan_amount, this.currency_code)}</div>

    },
    {
      Header : <div className='d-flex justify-content-center'>Duration</div>,
      id: "duration",
      accessor: "duration",
      Cell : (cell)=><div className='d-flex justify-content-center'>{cell.row.original.duration} days</div>

    },
    {
      Header : <div className='d-flex justify-content-center'>Repayment</div>,
      id: "repayment",
      accessor: "repayment",
      Cell : (cell)=><div className='d-flex justify-content-center'>{lbl_repayment_amt(cell.row.original, this.currency_code)}</div>

    },
    {
      Header : <div className='d-flex justify-content-center'>Eligibility</div>,
      id: "eligibility",
      accessor: "eligibility",
      Cell : (cell)=><div className='d-flex justify-content-center'>{get_eligibility(cell.row.original.result_code, cell.row.original.approver_first_name)}</div>

    },
    {
      Header : <div className='d-flex justify-content-center'>Action</div>,
      id: "action",
      accessor: "action",
      Cell : (cell)=><div className='d-flex justify-content-center'> {(cell.row.original.result_code == 'eligible' || cell.row.original.result_code == 'requires_flow_rm_approval') ? 
        <input  type='button' value={fa_product_action(cell.row.original.result_code, this.customer.acc_prvdr_code)} 
        onClick={(e) =>{ this.handleProductSelectClick(e, cell.row.original.id, cell.row.original.product_type, cell.row.original.cust_score, cell.row.original.result_code,cell.row.original.duration,lbl_amt(cell.row.original.max_loan_amount, this.currency_code))}}
        className={cell.row.original.result_code == 'eligible' ? 'btn btn-success loan_select' : cell.row.original.result_code == 'requires_flow_rm_approval' ? 'btn btn-primary' : 'btn btn-danger'}/>                          
        :
        
        (cell.row.original.result_code == 'ineligible') ?
        <>
        {check_priv('late_auth_req', 'approval') && <input disabled={this.state.disable_submit_for_approval_btn} type='button' value={fa_product_action(cell.row.original.result_code, this.customer.acc_prvdr_code)} 
        onClick={(e) =>{ this.submit_for_approval(cell.row.original)}} className='late_authorized_fa'/>} 
        </>:
        (cell.row.original.result_code == 'product upgrade') ? 
        <>
          {cell.row.original.max_loan_amount <= this.state.requested_amount ?
          <OverlayTrigger placement='top' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "12px", padding: "15px", fontFamily: "Montserrat"}}>{`Pending with ${cell.row.original.pending_with}`}</Popover>}>
                <div className='pointer-event-fa-upgrade'>
                <input  type='button' value={this.state.requested_amount === null ? fa_product_action(cell.row.original.result_code, this.customer.acc_prvdr_code) : cell.row.original.max_loan_amount <= this.state.requested_amount ? "Requested" : fa_product_action(cell.row.original.result_code, this.customer.acc_prvdr_code)}
                onClick={() => this.requestUpgrade(cell.row.original.max_loan_amount)}
                className="btn btn-warning" disabled = {(cell.row.original.max_loan_amount <= this.state.requested_amount && true) || this.state.calling_api}/>
                </div>
          </OverlayTrigger>
          :
          <input  type='button' value={this.state.requested_amount === null ? fa_product_action(cell.row.original.result_code, this.customer.acc_prvdr_code) : cell.row.original.max_loan_amount <= this.state.requested_amount ? "Requested" : fa_product_action(cell.row.original.result_code, this.customer.acc_prvdr_code)}
                onClick={() => this.requestUpgrade(cell.row.original.max_loan_amount)}
                className="btn btn-warning" disabled = {(cell.row.original.max_loan_amount <= this.state.requested_amount && true) || this.state.calling_api}/>}
        </>
        :
        <span>
        <FAProductActionOverlay result_msg={cell.row.original.result_msg} result_code={cell.row.original.result_code} />
        </span>
       }</div>

    },
  ]

}
  productList(result){

      const all_csf_values = result.all_csf_values;
      
      this.meta_data = all_csf_values.filter(item => item.csf_type.includes("meta"));
        //alert(JSON.stringify(meta_data));
      //var meta_data_arr = [];
      //meta_data_arr = meta_data.map((meta_data,i) => <span ><br/>&nbsp;&nbsp;&nbsp;{meta_data_arr[meta_data.csf_type]} = {format_date(meta_data.csf_normal_value)} </span>);
        //alert(meta_data_arr);
      this.customer =  result.borrower;
      
      // this.loan_products = result.loan_products; 
      this.loan_products = result.loan_products.filter(item => item.result_code !== 'product upgrade') 
      // const productList = this.loan_products.map(
      //   (ln_prod,i)=> {
      //             var btn_value = fa_product_action(ln_prod.result_code, this.customer.acc_prvdr_code)
      //             var eligibility = get_eligibility(ln_prod.result_code, ln_prod.approver_first_name)
                  
      //             const result_msg = get_result_msg(ln_prod, this.state, this.customer.category)
      //             return (
      //               <tr key={i} className="float-advance-products"> 
      //                 <td> {ln_prod.product_name}</td>
      //                 {/* <td> <CustScore loan_product={ln_prod} meta_data={this.meta_data} /> </td> */}
      //                 <td> <CustScore loan_product={ln_prod} meta_data={this.meta_data} /> </td>
      //                 <td> {dd_value(ln_prod.product_type)+ " / " + dd_value(ln_prod.cs_model_code)}</td>
      //                 <td data-sort={ln_prod.max_loan_amount}> {lbl_amt(ln_prod.max_loan_amount, this.currency_code)}</td>
      //                 <td> {ln_prod.duration} days</td>
      //                 {/* <td data-sort={ln_prod.flow_fee}> {lbl_flow_fee_obj(ln_prod, this.currency_code)}</td>*/}
      //                 <td data-sort={ln_prod.max_loan_amount+ln_prod.flow_fee}> {rt_algn(lbl_repayment_amt(ln_prod, this.currency_code))}</td>
      //                 <td>{eligibility}</td>
      //                  <td>
      //                 {(ln_prod.result_code == 'eligible' || ln_prod.result_code == 'requires_flow_rm_approval') ? 
      //                     <input id={i} type='button' value={btn_value} 
      //                     onClick={(e) =>{ this.handleProductSelectClick(e, ln_prod.id, ln_prod.product_type, ln_prod.cust_score, ln_prod.result_code,ln_prod.duration,lbl_amt(ln_prod.max_loan_amount, this.currency_code))}}
      //                     className={ln_prod.result_code == 'eligible' ? 'btn btn-success loan_select' : ln_prod.result_code == 'requires_flow_rm_approval' ? 'btn btn-primary' : 'btn btn-danger'}/>                          
      //                     :
      //                     (ln_prod.result_code == 'product upgrade') ? 
      //                     <>
      //                       {ln_prod.max_loan_amount <= this.state.requested_amount ?
      //                       <OverlayTrigger placement='top' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "12px", padding: "15px", fontFamily: "Montserrat"}}>{`Pending with ${ln_prod.pending_with}`}</Popover>}>
      //                             <div className='pointer-event-fa-upgrade'>
      //                             <input id={i} type='button' value={this.state.requested_amount === null ? btn_value : ln_prod.max_loan_amount <= this.state.requested_amount ? "Requested" : btn_value}
      //                             onClick={() => this.requestUpgrade(ln_prod.max_loan_amount)}
      //                             className="btn btn-warning" disabled = {(ln_prod.max_loan_amount <= this.state.requested_amount && true) || this.state.calling_api}/>
      //                             </div>
      //                       </OverlayTrigger>
      //                       :
      //                       <input id={i} type='button' value={this.state.requested_amount === null ? btn_value : ln_prod.max_loan_amount <= this.state.requested_amount ? "Requested" : btn_value}
      //                             onClick={() => this.requestUpgrade(ln_prod.max_loan_amount)}
      //                             className="btn btn-warning" disabled = {(ln_prod.max_loan_amount <= this.state.requested_amount && true) || this.state.calling_api}/>}
      //                     </>
      //                     :
      //                     <span>
      //                     <FAProductActionOverlay result_msg={result_msg} result_code={ln_prod.result_code} />
      //                     </span>
      //                    }
      //                 </td>
      //               </tr>
      //               )
      //             }
      //   );
       

      this.setState({customer: this.customer, 
                    // loanProducts_tr: productList,
                    customer_div_style: "show_div",
                    column_data : this.generate_column_datas(this.loan_products), 
                    row_data: this.loan_products});             
                
        // $('#list').removeAttr('width').dataTable({
        //               "order" : [],
        //               "pageLength": 15,
        //              "columns": [
        //                {"width" : "10%"},
        //                {"width": "10%"},
        //                {"width": "10%"},
        //                {"width": "10%"},
        //                {"width": "10%"},
        //                {"width": "10%"},

        //                {"width": "20%"},
        //                {"width": "20%"}

        //              ],
        //           dom:'Bfrtip',

        //           buttons: [
        //              'csv','excel','print'
        //           ]
        //         });


  }


  handleBorrowerSearch = (event, cust_id) => {
    this.customer_id = cust_id;
    this.productSearch();
    this.setState({ modalBox: false });
  }

  handle_submit_fa(reason){
    this.props.setValuesIntoRedux({calling_api:true})
    var req={
      remarks:reason, 
      "is_late_authorized": true,
      "cust_id": this.customer_id,
      "late_loans": this.state.late_loans,
      "product_id": this.state.fa_appl_details.id,
      ...this.state.fa_appl_details
    }

     applyLoan(this.req({"loan_application": req}))
    .then((response)=>{

      if(!response) {  
        this.props.setValuesIntoRedux({calling_api:false})
        this.setState({ show_submit_alert: false })
        return
      }
      this.setState({disable_submit_for_approval_btn: true})
      if(response&& response.status=="success"){
        alert(response.message)
        this.props.setValuesIntoRedux({calling_api:false})

        this.setState({ show_submit_alert: false });
        this.get_borrower_and_cust_accs(false)
        this.setState({disable_submit_for_approval_btn: false})

      }

    })

  }

  toggle_modal(type = null,reason) {
    if (type == "confirm") {
        this.handle_submit_fa(reason)
    }else{
      this.setState({show_submit_alert:false})
    }

   
    
}


	render(){

    if (this.state.toSummary === true) { 
      return (<LoanApplSummary mode="apply" cust_account={this.state.cust_account} credit_score={this.state.cust_score} customer={this.customer} selectedProduct={this.selectedProduct}
                              require_agreement={this.state.require_agreement} product_type={this.product_type} master_loan_doc_id={this.master_loan_doc_id}
                              min_credit_score={this.min_credit_score} currency={this.currency_code} cust_agreement_status={this.state.cust_agreement_status} 
                              eligible = {this.eligible} cs_result_code = {this.state.cs_result_code}  loan_products ={this.loan_products}
                              rel_mgr ={{id :this.state.customer.flow_rel_mgr_id, name :this.state.customer.flow_rel_mgr_name}}/>);
    }
    if (this.state.searchResult === true) {
     
      return <Navigate to={`/fa_appl_for/${this.customer_id}`} />

    }
 
		return(
        <div className="container">
          <div>
            {this.state.show_submit_alert && 
             <ConfirmModal header="Flow-Admin Says" messages={[`Please note that this customer has paid past ${this.state.late_loans} FAs late & is ineligible for any product. For applying this FA kindly submit to COO for approval.`]} is_toggle ={(type,reason)=>{this.toggle_modal(type,reason)}} addl_field="input" submit_bttn ={true}/>
            }
      
        
          <div className={`mt-5 ${this.state.search_loan}`}>
                <div className="row">

                <div className="col-md-12">
                  
                  <div className="card">
                    <div className="card-header card-header-icon card-header-info">
                      <div className="card-icon">
                        <i className="material-icons">search</i>
                      </div>
                      <h3 className="card-title">Create Float Advance Application</h3>
                    </div>
                    <div className="card-body mt-50" >
                  <form>
                    <div className="row">

                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="md-label-floating">Any Customer ID / Mobile No / National ID   </label>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="md-label-floating">Enter ...</label>
                          <input type="text" id="customer_id" onChange={this.handleCustIDChange} onKeyUp={this.handleEnter}  className="form-control" required />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <button type="button" className="btn btn-primary pull-right" onClick={this.handleSearchClick}>Search</button>
                        </div>
                      </div>
                    </div>
                  </form>
                     
                    </div>
                  </div>
                </div>

                </div>
          </div>
          </div>
        
     
    
            {/*<div className="input-group col-md-3">
            <input id="customer_id" onChange={this.handleCustIDChange} onKeyUp={this.handleEnter} placeholder="Enter..." className="form-control" required />
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon1" onClick={this.handleSearchClick}>
                <i className="fa fa-search"></i>
              </InputGroup.Text>
            </InputGroup.Append>
          
            </div>
          </div>*/}
          <div className="row">
            <div className={this.state.customer_div_style}>
              <CustPanel parent="selection" cust_agreement_status={this.state.cust_agreement_status} customer={this.state.customer}  loan_products={this.loan_products}/>


                {this.state.customer.cust_id && <LastNFAs cust_id = {this.state.customer.cust_id} last_n_fas ='4' />}

            <div className="card">
                <div className="card-body">
						<div className="row">
							<label className="col-sm-3" style={{fontSize: "15px", padding: "17px 13px"}}>Select Customer Account <font className="text-danger"> *  </font></label>
							<div className="col-sm-7">
								<div className="form-group md-form-group">
                                <select className="form-control" style={{fontSize: "15px"}}    onChange={this.accountSelect} ref={select => this.cust_account = select} >
										{this.state.customer_accounts_dd}
									</select>
								</div>
							</div>
						</div>
                </div>
            </div>

                {this.state.acc_selected && <div className="row">
                <div className="card">
									<div className="card-header card-header-icon card-header-primary">
										<h3 className="card-title text-white d-inline-block">Select Float Advance Product</h3>
									</div>
									<div className="card-body applyfa_table_responsive">
              <div className=" selectFloatProduct">
              {/* <Table responsive id='list'>
                  <thead className={"text-info"}>
                    <tr>
                        <th>Product Name</th>
                        <th>Cust Score</th>
                        <th>Product Type</th>
                        <th>FA Amount</th> 
                        <th>Duration</th> */}
                        {/* <th>Flow Fee Type</th>*/}
                        {/*<th>Flow Fee</th>*/}
                        {/* <th>Repayment</th>
                        <th>Eligibility</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                  <tbody> 
                  {this.state.loanProducts_tr}
                  </tbody>
                </Table>    */}
                
                <BasicReactTable row_data={this.state.row_data != undefined ? this.state.row_data : []} column_data={this.state.column_data != undefined ? this.state.column_data : []} pagination={this.state.row_data.length > 0 ? true : false} export_csv_datas={this.state.row_data != undefined ? this.state.row_data : []} global_search={true} default_page_size={5}/>

                <RepaymentDetail/>
                
              </div>
              </div>
              </div>

              </div>}
          <Modal show={this.state.modalBox} onHide={this.handleClose}>
              <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
                  <Modal.Title>More than one customer results found. Choose one among the following.</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table id ='list_brwr' className={"table"}>
                  <thead className={"text-info"}>
                  <tr><th>Business Name</th>
                  <th>FLOW Cust ID</th>
                  <th>Data Provider Cust ID</th>
                  <th>Action</th>
                  </tr></thead>
                  <tbody> 
                  {this.state.borrowersList}
                  </tbody>
                </table>   
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>Close</Button>
              </Modal.Footer>
          </Modal>
          
            </div>
          </div>           
            {this.state.popup_modal_box &&
            <Modal show={this.state.popup_modal_box} onHide={()=>this.fa_apply_modal_handleClose()}    backdrop="static"
            keyboard={false}>
            <Modal.Header className='no-padding mt-3' closeButton>
              <Modal.Title style={{fontSize:'18px'}}> <b> Account Confirmation </b> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className='d-flex row justify-content-center align-items-center '>
                <div  className='pointer col-4' >		
                  <p className='text-white no-margin'>Account Provider</p>	
                  <img  className="img-fluid pt-1" style={{height:30,width:60,marginRight:'10px'}} src={`/img/${this.state.customer.acc_prvdr_code}_logo_color.png`} />	
                </div>
                <div className='col-1'></div>
                <div className='col-4'>			
                <p className='no-margin text-white'>Account Number</p>
                <p className='no-margin pt-1 pb-2' style={{fontSize:'14px'}}> <b>{this.state.customer.acc_number} </b></p>
                </div>
                
                </div>
                <div className='d-flex mt-4 row justify-content-center align-items-center '>
                <div  className='pointer col-4' >		
                  <p className='text-white no-margin'>Amount</p>	
                  <p className='no-margin pt-1 pb-2' style={{fontSize:'14px'}}> <b>{this.state.fa_amount && this.state.fa_amount  } </b> </p>     
                </div>
                <div class='col-1'></div>
                <div className='col-4 '>			
                <p className='no-margin text-white'>Duration</p>
                <p className='no-margin pt-1 pb-2' style={{fontSize:'14px'}}> <b>{this.state.fa_duration && this.state.fa_duration + " Days"} </b></p>
                </div>
                
                </div>
                <div className='d-flex align-items-center mt-4' style={{background:'#fe9f9f'}}><p className='no-margin p-1' style={{color:'black'}}><b className='mr-2' style={{color:'red'}}>Note :</b> The amount will be transferred to the account number mentioned above.</p> </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={(e)=>this.fa_apply_modal_handleClose()}>
                Close
              </Button>
              <Button variant="primary" className='ml-3' onClick={(e)=>this.setState({toSummary:true})}>
                continue
              </Button>
            
            </Modal.Footer>
            </Modal>
            }


          {this.props.softPhoneState.softPhone && (!this.props.softPhoneState.missed_calls_view)   && (!this.props.softPhoneState.isIncomingCall) && (!this.props.softPhoneState.outgoingCall) && ( (this.props.mode == 'result') && this.props.softPhoneState.show_nums) && (get('market').country_code =="UGA") &&

          <div className='Call_log_section zoomIn_animation'>
            <Softphone screen_from ={"fa_apply"} cust_id={this.props.params.cust_id?this.props.params.cust_id:null}/>
              
          </div>


          }
        </div>
			);
	}
}


const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSelectionContainer));




