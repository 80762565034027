import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material";
import {
  GridPaginationModel,
  GridSortModel
} from "@mui/x-data-grid";
import moment from "moment";
import {
  useEffect,
  useState
} from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import SearchBoxContainer from "../../commonContainers/searchBoxContainer";
import {
  END_DATE_LT_START_DATE,
  END_DATE_MIN_DATE_INFO,
  END_DATE_REQUIRED,
  END_DATE_WONT_FUTURE,
  MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD,
  START_DATE_GT_END_DATE,
  START_DATE_MIN_DATE_INFO,
  START_DATE_REQUIRED,
  START_DATE_WONT_FUTURE,
} from "../../consts";
import { RhfObjTypeOptionProp } from "../../type";
import FormProvider from "../CommonComponent/rhfComponents/formProvider";
import FieldsAndTableUI from "./components/fieldsAndTableUI";
import FilterFormUI from "./components/filterFormUI";

export type AuditorCoverageReportResProps = {
  data: {
    data: FlaggedListData[];
    summary_data: Record<string, string>;
    total: number;
  };
};

type FlaggedListData = {
  acc_prvdr_code: string;
  "Acc Number": string;
  "Cust ID": string;
  Cust: string;
  "RM Name": string;
  "Initiated by": string;
  "Flagged on": string;
  "Last Follow-up On": string;
  "Current Status": string;
  "Current FA Status": string;
  Reason: string;
};

export type FormDefaultProps = {
  auditor: {
    name: string;
    id: string;
  };
  custNameOrCustIdOrMblNo: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
};

const FormSchema = Yup.object().shape({
  auditor: Yup.mixed<RhfObjTypeOptionProp>().required("Auditor is required"),
  custNameOrCustIdOrMblNo: Yup.string().default(""),
  startDate: Yup.mixed<moment.Moment>()
    .required(START_DATE_REQUIRED)
    .test("startDate", START_DATE_MIN_DATE_INFO, (val) => {
      const minDate = moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD);
      return !val.isBefore(minDate, "day");
    })
    .test("startDate", START_DATE_WONT_FUTURE, (val) => {
      const minDate = moment();
      return !val.isAfter(minDate, "day");
    })
    .test("startDate", START_DATE_GT_END_DATE, function (val, ctx) {
      const { endDate } = this.parent;
      if (!endDate || !val) return true;
      return !val.isAfter(endDate, "day");
    }),
  endDate: Yup.mixed<moment.Moment>()
    .required(END_DATE_REQUIRED)
    .test("endDate", END_DATE_MIN_DATE_INFO, (val) => {
      const minDate = moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD);
      return !val.isBefore(minDate, "day");
    })
    .test("endDate", END_DATE_WONT_FUTURE, (val) => {
      const minDate = moment();
      return !val.isAfter(minDate, "day");
    })
    .test("endDate", END_DATE_LT_START_DATE, function (val, ctx) {
      const { startDate } = this.parent;
      if (!startDate || !val) return true;
      return !val.isBefore(startDate, "day");
    }),
});

const FlaggedCustomers = () => {
  // router hook
  const [searchParams] = useSearchParams();
  const fromDate = searchParams.get("defaultStartDate");
  const endDate = searchParams.get("defaultEndDate");

  // default value for form
  const defaultValues = {
    auditor: { name: "All (Auditors)", id: "all" },
    custNameOrCustIdOrMblNo: "",
    startDate: fromDate
      ? moment(fromDate)
      : moment().subtract(6, "months").startOf("month"),
    endDate: endDate ? moment(endDate) : moment(),
  };

  // hookForm
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });
  const theme = useTheme();

  // rhf returns
  const {
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = methods;

  // const
  const defaultSort: GridSortModel = [{ field: "Flagged on", sort: "desc" }];
  const defaultPage: GridPaginationModel = {
    page: 0,
    pageSize: 10,
  };
  const rhfStartDate = watch("startDate");
  const rhfEndDate = watch("endDate");

  // effect
  useEffect(() => {
    if (
      (rhfEndDate &&
        rhfStartDate &&
        Object.keys(errors).includes("startDate") &&
        errors.startDate?.message === "Start date cannot be after end date") ||
      (Object.keys(errors).includes("endDate") &&
        errors.endDate?.message === "End date cannot be before start date")
    ) {
      if (rhfStartDate.isBefore(rhfEndDate))
        clearErrors(["startDate", "endDate"]);
    }
  }, [rhfEndDate, rhfStartDate, clearErrors]);

  // state
  const [sort, setsort] = useState<GridSortModel>(defaultSort);
  const [page, setpage] = useState<GridPaginationModel>(defaultPage);
  const [queryFilters, setqueryFilters] = useState(defaultValues);

  // handler
  const onSubmit = handleSubmit((formData) => {
    setqueryFilters({ ...formData });
    // page to initial
    setpage(defaultPage);
  });

  return (
    <SearchBoxContainer title="Flagged Customers">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <FilterFormUI />
        <FieldsAndTableUI
          sort={sort}
          page={page}
          defaultSort={defaultSort}
          queryFilters={queryFilters}
          setpage={setpage}
          setsort={setsort}
        />
      </FormProvider>
    </SearchBoxContainer>
  );
};

export default FlaggedCustomers;
