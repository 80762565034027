import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';

import {get, Config} from '../../../helpers/storage_helper';

import {capitalize_first_letter,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';

import {dd_value} from '../../../actions/common_actions';
import GoogleMapSetup from '../../../helpers/googleMap';
import moment from 'moment';


class RekycHandlerandMapLocation extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
        this.biz_details=["biz_name","first_name"]
        this.national_id_front_side=["first_name","last_name","gender","dob","id_proof_num"]
        this.customer_name=["first_name","last_name"]
    }

    componentWillMount(){
      if(this.props.handler_and_maplocation){
        // var gps_address_coordinates=[]
        // gps_address_coordinates.push({ this.props.handler_and_maplocation.old_cust_reg_json.gps_address.gps.value},this.props.handler_and_maplocation.new_cust_reg_json.gps_address.gps.value)
        //  this.setState({gps_address_coordinates})
      }
    }

    checkbox_spinner(){
      return(
        <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
       </div>
      )
    }
    audit_section_spinner(){
        return(
          <div className='d-flex align-items-center'>
          <div class="audit_spinner" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        </div>
        )
      }
      

      rekyc_modify_checking(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(addl_key !=null && idx ==null ){

          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected")) ||  (cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected_n_modified")))){      
            return this.get_class_name(cust_reg_json,"collected")
          }
          else{

            return false;
           }
        }

       else if(idx!=null){
        if(addl_key!=null){
          if(( (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected_n_modified")))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
        }
        if(addl_key==null){
          if(( (cust_reg_json[json_type][json_key][idx][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][idx][key].hasOwnProperty("collected_n_modified")))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
        }
         
          else{
            return false;
           }
        }
        else{
          if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  || (cust_reg_json[json_type][json_key][key].hasOwnProperty("collected_n_modified")))){ 
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
         }
        }
       
      }
      

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "Re-KYCed"
         }
         
         else{
          return "MODIFIED"
         }
        }


        get_modify_classname(cust_reg_json,json_type,json_key,item,addl_key=null,idx=null,addl_details,addrs_type){
          if(item=="biz_name"){
             
            let a = this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license",item,null,null)
            if(a){return a}
             return false

            }
            else if(item=="first_name" || item=="last_name"){
              let b= this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","national_id",item,null,null)
              if(b){return b}
              return false
            }
            else{

            let c=this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","gps_address",item,addrs_type)
            if(c){return c}
            return false
            }
        }
        
        rekyc_modify_checking_status(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,addl_details,addrs_type=null){
         let a;
         addl_details.map((item,idx)=>{
          
            a = this.get_modify_classname(cust_reg_json,json_type,json_key,item,addl_key=null,idx=null,addl_details,addrs_type)

         })
         if(a){
                return a
            }
            return false
      }

    render(){
        return(
            <div>
           {this.props.handler_and_maplocation.audit_kyc_wizard_menus.contact_persons &&
             <div className='row no-margin justify-content-center'> 
            
             {this.props.handler_and_maplocation.View_lead_section_loader ?  <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:
             <>
             <div className=' col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
               <h2 className='text-white text-center'>
                <b>2 - Handler Verification </b> 
               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.handler_and_maplocation.active_section && (this.props.handler_and_maplocation.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>
 
              </div> 
             {this.props.handler_and_maplocation &&
              <>
              
              <>
             
              <div className='col-lg-10 py-5 mt-3 px-5' style={{background:'#223b56'}}>
              <h2 className='text-white '>
             <b> National ID Front Side Verification</b>
              </h2>
              {this.props.handler_and_maplocation.old_cust_reg_json &&
              <>
              <div className="row">
               <div className="col-md-12 no-padding col-lg-6">
               <h2 className={`text-white p-2 px-3  old_json_text_bg_color  text-center`}> <b>Business Details (As per KYC)</b></h2>
 
               </div>
               <div className="col-md-12 col-lg-6">
           

               {this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof",null,0) ?
               <h2 className='text-white p-2   new_re_kyc_text_bg_color text-center'> <b>Business Details (As per Re-KYC)</b></h2>
               :
               <h2 className={`text-white p-2 px-3  old_json_text_bg_color  text-center`}> <b>Business Details (As per Re-KYC)</b></h2>
 
               }
               </div>
              </div>
              <div className=' mt-4 d-flex align-items-center '>
              { this.props.handler_and_maplocation.active_section &&
                 <div>
                     {this.props.handler_and_maplocation.active_section.verify &&
                       <div className="closedStamp ">
                       <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                       </div>   
                     }
                 </div>
                }   
             <div className='col-md-6 w-100 old_json_text_bg_color' style={{background:'#223b56'}}>
               
              <div className='col-md-12 col-lg-12  px-3 no-padding' >
 
              <div className='mt-3 text-light  h-100 w-100' >
              {this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0].photo_id_proof_full_path ?
              <DisplayImage image_path={get_dir_path(this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0].photo_id_proof_full_path.value,this.props.handler_and_maplocation.cust_reg_json.contact_persons[0].photo_id_proof.value)} style={{height:'100%',width:'100%'}} file_name={this.props.handler_and_maplocation.cust_reg_json.contact_persons[0].photo_id_proof.value} rotate={false}  saved_file_name={"photo_id_proof"} lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} />
              :""}</div>
              </div>
              <div className='col-md-12 no-padding col-lg-12' >
              <div className='m-0 text-light  h-100 w-100 ' style={{background:'#223b56'}} >
              
                 <div className=' py-4 px-3' >
                     
 
 
                 <div className=' py-4 px-4' >
                     {this.national_id_front_side.map((item,idx)=>{return(
                       <div className={`align-items-center col-12  mb-3 my-4 `}>
                       <div className='row no-margin'>
                             <p className='no-margin text-white business_operations_text col-4'>  {dd_value(item.toUpperCase())}  </p>
                             <h2 className='text-white audit_kyc_text col-8 '> <b>{ capitalize_first_letter( this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0][item] ?this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0][item].value :'')}</b></h2>
                         </div>
                        </div>
                     )})}
                     <div className='row no-margin my-4 py-2 px-3'>
                       <p className='no-margin text-white business_operations_text pt-2 col-4'>  NATIONALITY  </p>
                       <h2 className='text-white audit_kyc_text col-8 '> <b>{get("market").country_code }</b></h2>
                     </div>
                 </div>
 
                
                  <br/>
          
                 </div>
                 
                
               { this.props.handler_and_maplocation.old_cust_reg_json.active_section &&
                 <div>
                     {this.props.handler_and_maplocation.old_cust_reg_json.active_section.verify &&
                       <div className="closedStamp ">
                       <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                       </div>   
                     }
                 </div>
                }   
              </div>
             
            
              </div>
            
 
              </div>
 
              <div className='col-md-6  w-100'>
               <fieldset className= {` h-100 ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_full_path",null,0) ? ` ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_full_path",null,0)+"_field_set"}`:"new_re_kyc_text_bg_color" } ` } style={{background:'#223b56'}}>
               {this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_full_path",null,0) ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_full_path",null,0)+"_text_color"} `}> 
               <b> {this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_full_path",null,0) } </b> 
               </legend> :""}
 
              <div className='col-md-12 no-padding  px-3 col-lg-12' >
              <div className='m-0 text-light   h-100 w-100' >
              <DisplayImage image_path={get_dir_path(this.props.handler_and_maplocation.new_cust_reg_json.contact_persons[0].photo_id_proof_full_path.value,this.props.handler_and_maplocation.cust_reg_json.contact_persons[0].photo_id_proof.value)} style={{height:'100%',width:'100%'}} file_name={this.props.handler_and_maplocation.cust_reg_json.contact_persons[0].photo_id_proof.value} rotate={false}  saved_file_name={"photo_id_proof"} lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} />
 
              </div>
              </div>
              <div className='col-md-12 col-lg-12 no-padding' >
              <div className='m-0 text-light  h-100 w-100 ' style={{background:'#223b56'}} >
                   <div className=' py-4 px-4' >
                     
                     {this.national_id_front_side.map((item,idx)=>{return(
                       <div className={`align-items-center col-12  mb-3 my-2 `}>
                       {this.props.rekyc_modify_state_handler(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons",item,null,0,`contact_persons.0.${item}`)} 
                      </div>
                     )})}
 
                     <div className='row no-margin my-4 py-2 px-3'>
                       <p className='no-margin text-white business_operations_text pt-2 col-3'>  NATIONALITY  </p>
                       <h2 className='text-white audit_kyc_text col-7  '> <b>{get("market").country_code }</b></h2>
                     </div>
                     
                 </div>
                
                  
            
              </div>
             
            
              </div>
            
             </fieldset>
            
              </div>
              </div>
              
              
              </>
              }
              </div>
 
 
              <div className='col-lg-10 py-5 mt-3 px-5' style={{background:'#223b56'}}>
              <h2 className='text-white px-3'>
             <b> National ID Back Side Verification</b>
              </h2>
              {this.props.handler_and_maplocation.old_cust_reg_json &&
              <>
              <div className="row">
               <div className="col-md-12 p-0 col-lg-6">
               <h2 className={`text-white p-2 px-3  old_json_text_bg_color text-center`}> <b>Business Details (As per KYC)</b></h2>
 
               </div>
               <div className="col-md-12 col-lg-6">
               <h2 className= {`text-white p-2  ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_back",null,0)?"new_re_kyc_text_bg_color" : "old_json_text_bg_color"}  text-center`}> <b>Business Details (As per Re-KYC)</b></h2>
 
               </div>
 
              </div>
 
              <div className=' mt-4 row '>
           
             <div className='col-md-6 old_json_text_bg_color' style={{background:'#223b56'}}>
 
              <div className='col-md-12 col-lg-12 mb-3 px-3 ' style={{background:'#223b56'}} >
 
              <div className={`my-3 mt-4 text-light  h-100 w-100`}>
              <DisplayImage image_path={get_dir_path(this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0].photo_id_proof_back_full_path.value,this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0].photo_id_proof_back.value)} rotate={false} saved_file_name={"photo_id_proof_back"} lead_id={this.props.lead_id} style={{height: '500px',width:'100%'}} file_name={this.props.handler_and_maplocation.old_cust_reg_json.contact_persons[0].photo_id_proof_back.value}  view_lead={()=>this.props.view_lead()} />
              </div>
              </div>
 
            
            
             
              </div>
              <div className='col-md-6'>
               <fieldset className= {`h-100  ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_back_full_path",null,0)  ? ` ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_back_full_path",null,0) +"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
               {this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_back_full_path",null,0)  ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_back_full_path",null,0) +"_text_color"} `}> 
               <b> {this.rekyc_modify_checking(this.props.handler_and_maplocation,"new_cust_reg_json","contact_persons","photo_id_proof_back_full_path",null,0) } </b></legend> :""}
 
                 <div className='col-md-12 col-lg-12 mb-3 px-3 '  >
 
                 <div className={`m-0 text-light  h-100 w-100`}>
                 <DisplayImage image_path={get_dir_path(this.props.handler_and_maplocation.new_cust_reg_json.contact_persons[0].photo_id_proof_back_full_path.value,this.props.handler_and_maplocation.new_cust_reg_json.contact_persons[0].photo_id_proof_back.value)} rotate={false} saved_file_name={"photo_id_proof_back"} lead_id={this.props.lead_id} style={{height: '500px',width:'100%'}} file_name={this.props.handler_and_maplocation.new_cust_reg_json.contact_persons[0].photo_id_proof_back.value}  view_lead={()=>this.props.view_lead()} />
                 </div>
                 </div>
               </fieldset>
             </div>
              </div>
              
              
              </>
              }
 
              <h2 className='rekyc_note mt-4 text-center' > <b>NOTE</b> : Compare the existing data (as per FLOW) with the current data (as entered by RM). </h2>
              <div className='row'>
               <div className="col-6"></div>
               <div className="col-6">
                {this.props.handler_and_maplocation.form_spinner ? 
                this.checkbox_spinner()
                 :
                 <div className='col-lg-12 py-2 '>
                {this.props.handler_and_maplocation.active_section &&
                 <form className={`${(this.props.handler_and_maplocation.active_section[0].verify || (this.props.handler_and_maplocation.active_section[0].to_reassign==true) )? "disable_div":''}  ${ (((this.props.handler_and_maplocation.responseData.audited_by== get("user_id")) || (this.props.handler_and_maplocation.responseData.audited_by==null )) && this.props.handler_and_maplocation.active_section[0].pending_with !="rm")?"":'disable_div'}`}>
                     <div className='d-flex '>
                     <input type="checkbox" id="vehicle1" className='pointer mt-1' style={{height:'19px',width:'25px'}} checked={this.props.handler_and_maplocation.active_section[0].flags.original_national_id && true} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"original_national_id")}/>
                     <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1" style={{lineHeight:'23px'}}>The National ID details matches with the details entered by the RM.</label><br/><br/><br/>
                     </div>
                     <div className='d-flex my-3'>
                     <input type="checkbox" id="vehicle2" name="vehicle2 " className='pointer mt-1' value="Car" checked={this.props.handler_and_maplocation.active_section[0].flags.clear_and_readable && true} style={{height:'19px',width:'25px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_and_readable")}/>
                     <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle2" style={{lineHeight:'23px'}}> The text in the National ID is in a clear & readable state.</label><br/><br/>
                     </div>
 
                     <div className='d-flex  mt-3'>
                     <input type="checkbox" id="vehicle3" name="vehicle3 " className='pointer mt-1' checked={this.props.handler_and_maplocation.active_section[0].flags.face_recogonizable && true} style={{height:'19px',width:'25px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"face_recogonizable")}/>
                     <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle3" style={{lineHeight:'23px'}}>  The face of the customer in the National ID is recognizable.</label><br/>
                     </div>
                 </form>
                 }
                  
              </div>
                }
                </div>
              </div>
              </div>
 
             
 
 
          
              
           
             </>
             </>
             }
             </>}
             </div>
           } 

            {this.props.handler_and_maplocation.audit_kyc_wizard_menus.gps_address &&
            
              <div className='row no-margin justify-content-center'>
             {this.props.handler_and_maplocation.View_lead_section_loader ?  <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:

             <>
              <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                <h2 className='text-white text-center'>
                <b> {`${this.props.handler_and_maplocation.cust_reg_json.same_as_owner_person ? "2" :"3"} - Map Location Verification`}</b> 
                </h2>
                <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.handler_and_maplocation.active_section && (this.props.handler_and_maplocation.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

              </div>
              {this.props.handler_and_maplocation &&
              <>
              <>
             
              <div className='col-lg-10  px-5' >

                <div className='third_party_bg p-5'>
                <div className='col-md-12 w-100 p-0' style={{margin:'auto'}} >
                <GoogleMapSetup current_coordinates={this.props.handler_and_maplocation.new_cust_reg_json.gps_address?.gps.value} coordinates={ this.props.handler_and_maplocation.old_cust_reg_json.gps_address?.gps.value} height={'400px'} color={true} />
                </div>

             


                <p className='no-margin text-white pt-3 pb-3' style={{fontSize:'15px'}}><b style={{color:'red'}}>Note :</b> Zoom In/Zoom Out to verify if the gps location pointed by the RM matches with the business address captured</p>

              

              <div className="row  no-margin pt-2">
                <div className="col-md-12 p-0 col-lg-6">
                <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>Business Address (As per KYC)</b></h2>

                </div>
                <div className="col-md-12 col-lg-6">
                  {this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment,"biz_address") ?
                      <h2 className='text-white p-2 px-3  new_re_kyc_text_bg_color text-center'> <b> Business Address (As Per Re-KYC)</b></h2>
                      :
                      <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>Business Address (As per Re-KYC)</b></h2>

                  }
               

                </div>
              </div> 
         
              <div className='row  no-margin py-3'>
                
                <div className="col-md-12 col-lg-6 old_json_text_bg_color" style={{background:'#202940'}}>
                <div  className='my-4 '  >
                
                <div className=' p-4 mb-4' >

                { (get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment).map((item,idx) => {
                   return(
                     <div className={`d-flex row align-items-center my-5 mx-2 py-2 align-items-center `}>
                     <p className='no-margin text-white col-4 business_operations_text'> { dd_value(item).toUpperCase() } </p>
                     <h2 className='text-white pl-4 col-8 audit_kyc_text'> <b> { this.props.handler_and_maplocation.old_cust_reg_json.gps_address?.biz_address[item].value ? dd_value(capitalize_first_letter(this.props.handler_and_maplocation.old_cust_reg_json.gps_address.biz_address[item].value)):<p className='no-margin text-white'> - </p> } </b></h2>
                     </div>
                   )
                  
                   })}
                
                 
               </div>
              </div>
                </div>

                <div className='col-md-12 col-lg-6 '>
                <div  className=' ' >
                {/* <hr className='no-margin'/> */}
                <fieldset className= {`${this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment,"biz_address") ? ` ${this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#202940'}}>
                {this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address")+"_text_color"} `}> 
                <b> {this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address") } </b></legend> :""}

                <div className=' p-4'  >

                { (get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment).map((item,idx) => {
                   return(
                    <div className={` py-2 col-12  align-items-center ${this.props.handler_and_maplocation.active_section.biz_address[item].hasOwnProperty("modified") &&this.props.handler_and_maplocation.active_section.biz_address[item].modified ? "my-3":"my-5" }  mx-2 align-items-center `}>
                     {this.props.rekyc_modify_state_handler( this.props.handler_and_maplocation,"new_cust_reg_json","gps_address",item,"biz_address",null,`gps_address.biz_address.${item}`)}
               </div>
                     
                   )
                  
                   })}
                
                 
               </div>
               </fieldset>
              </div>
                </div>
                <p className='no-margin text-white pt-5 ' style={{fontSize:'15px'}}><b style={{color:'red'}}>Note :</b> Verify this checklist based on the ReKYC data seen on the Right hand side </p>

              </div>

              </div>
              <div className="row mt-5">
                <div className="col-md-12 p-0 col-lg-6">
                <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>Home Address (As per KYC) </b></h2>

                </div>
                <div className="col-md-12 col-lg-6">
               { this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment,"biz_address") ?         
                          <h2 className='text-white p-2 px-3  new_re_kyc_text_bg_color text-center'> <b> Home Address  (As Per Re-KYC)</b></h2>
                    :  <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>Home Address (As per Re-KYC)</b></h2>

              }
                </div>
                </div> 
              <div className='row mt-2'>
                
                <div className="col-md-12 col-lg-6 old_json_text_bg_color" style={{background:'#202940'}}>
                <div  className=''>
                
                <div className=' p-4'  >

                { (get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment).map((item,idx) => {
                   return(
                     <div className={`d-flex row align-items-center my-5 mx-2 py-2 align-items-center `}>
                     <p className='no-margin text-white col-4 business_operations_text'> { dd_value(item).toUpperCase() } </p>
                     <h2 className='text-white pl-4 col-8 audit_kyc_text'> <b> { this.props.handler_and_maplocation.old_cust_reg_json.gps_address?.owner_address[item].value ? dd_value(capitalize_first_letter(this.props.handler_and_maplocation.old_cust_reg_json.gps_address?.owner_address[item].value)):<p className='no-margin text-white'> - </p> } </b></h2>
                     </div>
                   )
                  
                   })}
                
                 
               </div>
              </div>
                </div>

                <div className='col-md-12 col-lg-6 ' >
                <div >
                {/* <hr className='no-margin'/> */}
                <fieldset className= {`${this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment,"biz_address") ? ` ${this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#202940'}}>
                {this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address")+"_text_color"} `}> 
                <b> {this.rekyc_modify_checking_status(this.props.handler_and_maplocation,"new_cust_reg_json","biz_license","photo_biz_lic",null,null,(get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment),"biz_address") } </b></legend> :""}

                <div className=' p-4' style={{marginTop:'-10px'}} >

                { (get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment).map((item,idx) => {
                   return(
                     <div className={`col-12  align-items-center ${this.props.handler_and_maplocation.active_section.owner_address[item].hasOwnProperty("modified") &&this.props.handler_and_maplocation.active_section.owner_address[item].modified ? "my-2":"my-5"} py-2 align-items-center `}>
                     {this.props.rekyc_modify_state_handler( this.props.handler_and_maplocation,"new_cust_reg_json","gps_address",item,"owner_address",null,`gps_address.owner_address.${item}`)}

                     </div>
                   )
                  
                   })}
                {/* (get('market').country_code =="UGA"? this.uga_owner_address_alignment : this.rwa_owner_address_alignment) */}
                 
               </div>
                </fieldset>
                </div>
                </div>

              </div>

              </div>
              
              {this.props.handler_and_maplocation.active_section &&
              <div className='col-lg-10 py-2 px-5 mt-4'>
                <div className='row m-0'>
                  <div className='col-6'></div>
                  <div className='col-6'>
                  {this.props.handler_and_maplocation.form_spinner ? 
                this.checkbox_spinner()
                :
                  <form className={`${(this.props.handler_and_maplocation.active_section.verify || (this.props.handler_and_maplocation.active_section.to_reassign==true) )? "disable_div":''}   ${ (((this.props.handler_and_maplocation.responseData.audited_by== get("user_id")) || (this.props.handler_and_maplocation.responseData.audited_by==null )) && (this.props.handler_and_maplocation.active_section.pending_with !="rm")) ?"":'disable_div'}`}>
                      <div className='d-flex '>
                      <input type="checkbox" id="vehicle1"  className='pointer mt-1' style={{height:'19px',width:'20px'}} checked={this.props.handler_and_maplocation.active_section.flags.gps_address_match && true}  onChange={(e)=>this.props.checkbox_verification(e.target.checked,"gps_address_match")}/>
                      <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1">The location in the Business Address matches with the gps location  </label><br/><br/>
                      </div>
                      <br/>
                   
                  </form>
                  }  
                  </div>
                </div>
              
              </div>
              }
              </>
              </>
              }
              </>}
              </div>
            }
            </div>

        )        
     }
}

export default RekycHandlerandMapLocation;