import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Config, get } from "../../helpers/storage_helper";


export const updateNotficationModalstate = createAsyncThunk(
    'header/updateNotficationModalstate',
    async (notification, { dispatch }) => {
      dispatch(changeNotificationState({
        portfolio_details: notification
      }));
      return notification;
    }
  );

const initialState = {
    notification:{
        un_read_notifications_count:0,
        show_notification:false,
        notification_arr : [],
        no_notification:false,
        loader:false,
        portfolio_modal : false,
        portfolio_details:null
      },
      auto_filled : false,
      show_login_after_reset_pass:false,
      admin_roles : Config('global_users'), 
      profile_path:'',
      root:  window.location.origin ,
      header_client_tab_id : null,  
      markets:get('market_list')  ? get('market_list')  :[]
}
const HeaderSlice = createSlice({
    name: 'header',
    initialState,
    reducers:{
        changeNotificationState:(state, action)=>{
            state.notification = {
                ...state.notification,
                ...action.payload
            }
        },
        changeHeaderSlice : (state, action)=>{
          const obj = action.payload
          for (const key in obj){
              state[key] = obj[key]
          }
        },
        changeHeaderClientTabId : (state, action)=>{
          state.header_client_tab_id = action.payload
        },
    },
    extraReducers: (builder)=>{
        builder.addCase(updateNotficationModalstate.fulfilled, (state, action)=>{
        })
    }
})




export const {changeNotificationState,changeHeaderSlice, changeHeaderClientTabId} = HeaderSlice.actions;
export default HeaderSlice.reducer