import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Toggle from "./Toggle";
import { useSelector } from "react-redux";

  const ConfirmModal = ({alert_message=null,note=null,is_toggle=null,is_editable_btns =null,cust_data=null,is_pending_call, is_swap_acc,SoftphoneCallOptions, header=false, messages = false, addl_field = null, submit_bttn = false})=>{

  const [reason,setreason] = useState(null);

  const [show,setshow] = useState(false);
  const {calling_api} = useSelector(state=>state.cs_soft_phone)

  useEffect(()=>{
    if(is_editable_btns || is_pending_call || SoftphoneCallOptions){
      setshow(true)
    }else{
      setshow(!show)
    }
  },[is_toggle])
        
    const ToggleModal =(type=null ,submit_reason =reason)=>{

        is_toggle(type,submit_reason)
        setshow(!show)

    }

    

    return(
  <div className="">
         <Modal className="confirm_modal" show={show} onHide={()=>ToggleModal("close")}
          size="lg"
       aria-labelledby="contained-modal-title-vcenter"
backdrop={header?"static":""}
centered ={header? false :true}
    >
     <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter confirm_Modal">
      
       <b>{is_editable_btns ? cust_data.search_button ? "Number Not Found " :"Customer Not Found" :"Confirmation"}</b> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      

        {messages && messages.length>0 ?
        <>{ messages.map((item,idx)=>{return(
           <p className="m-0 text-white sizem " key={idx} style={{lineHeight:'20px'}}>
           {item}
          </p>
        )}) }
        </>:
        <p className="m-0 text-white sizem" style={{lineHeight:'20px'}}>
         {alert_message}
        </p>
        }
        {note!=null &&
        <p  className="mb-0 my-3  sizem text-info">
         Note : {note}
       </p>
        }

        {(addl_field && addl_field =="input" )&&
        <div  id="reason" className="my-4">
        <input type="text" onChange={ (e)=>{setreason(e.target.value)}} className="fa_appl_submit_input w-100" placeholder=" Reason to submit...." required />
        </div>  
        } 

         
        {SoftphoneCallOptions ? 
           <div className="d-flex align-items-center justify-content-end">
              <button type="button" class="btn btn-confirm mx-3  px-4" onClick={(e)=>ToggleModal("yes")} >  physical phone</button>
              <button type="button" class="btn btn-cancel mx-3  text-white  px-4" onClick={(e)=>ToggleModal("no")} > soft-phone</button>

   
           </div>
        :
        
      
      <div className="d-flex align-items-center justify-content-end">

      { addl_field &&addl_field =="input" &&  <div className={`${(addl_field &&addl_field =="input") ?reason && reason.trim().split(/\s+/).length >=5 ?"" :"disable_div":"" }`}>
                  <button type="button" disabled={calling_api } class="btn btn-confirm mx-3 px-4 text-white" onClick={(e)=>ToggleModal("confirm")} >{submit_bttn ? "Submit" : "Confirm"}</button>

         </div>
         }

        {/* <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal("confirm")} >Confirm</button>
        <button type="button" class="btn btn-cancel mx-2"  onClick={()=>ToggleModal(null)} >Cancel</button> */}
        <button type="button" class="btn btn-cancel text-white mx-2"  onClick={()=>ToggleModal( is_editable_btns  ? "continue" : is_pending_call ? "cancel": null)} >{is_editable_btns ? "Continue" :"Cancel"}</button>
        {cust_data && cust_data.search_button &&
        <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal( is_editable_btns  ? "search_customer" :"confirm")} > {is_editable_btns ? "Search Customer":"Confirm"}</button>
        }
        {(is_pending_call || is_swap_acc) &&
         <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal("confirm")} > Confirm</button>
        }


        </div>
        
        }
      </Modal.Body>
     
    </Modal>
    </div>
    )

}


export default ConfirmModal