
import React, { useState} from "react";
import moment from "moment";
import { BiAlarmAdd, BiUser, BiMoney, BiIdCard, BiCommentCheck, BiTask } from "react-icons/bi";
import { BsFillPersonCheckFill, BsFileEarmarkCheckFill,BsReverseLayoutTextSidebarReverse } from 'react-icons/bs';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FlowContainer from '../../common/container/core/FlowContainer';
import ReportChart from '../component/ReportChart';
import { BsInfo} from "react-icons/bs";
import { BsXOctagonFill } from "react-icons/bs";
import {convertMinutesToHours, get_cs_agent_names, make_series_data} from '../../../helpers/common_helper';
import { th } from 'date-fns/locale';
import { Biz_operation_live_reports,getCurrencyDetails, inbound_voice_call_report } from '../../../actions/report_actions';
import { get } from '../../../helpers/storage_helper';
import { RoundOffSplitter } from '../../../helpers/common_helper';
import { AiOutlineForm, AiOutlineUserSwitch, AiOutlineTablet, AiOutlineSchedule, AiOutlineFieldTime } from "react-icons/ai";
import { MdAlarm } from "react-icons/md";
import { FaLongArrowAltRight, FaUserNurse, FaArrowsAltH } from "react-icons/fa";
import { getFloatSwitchAccounts } from '../../../actions/account_txn_actions';
import { GiBackwardTime} from "react-icons/gi";
import { AiOutlineBarChart } from 'react-icons/ai';
import ReactApexChart from "react-apexcharts";


class Biz_opertations_yesterday_results extends FlowContainer {
  
  constructor(props) {
    super(props);

    this.state={

      ChurnManagementCount:[{"title":"Churn Count","value":5},
      {"title":"Calls made by CS","value":0},
      {"title":"FAs taken over CS call","value":0}],
      dup_data: [0, 10],
      options: {
        chart: {
          width: 450,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke:{
          colors:['#1ABB82','#4291DA']
         },
        fill: {
          type: 'gradient',
        },
        labels: ["Returned", "Un-Returned"],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8
        }
      },
       
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }],
        legend: {
          position: 'bottom',
          height: '100',
          offsetY: -10
        }
      },
        active:'Today',
        totalValue:[],
        TotalAndOverdueFA:[],
        Faapplied_by_count:[],
        activeCurrencyVal:'',
        startDate:moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate:moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),
        FAApplications:[],
        total_fas:null,
        country_code: get('market').country_code,
        history_datas:'',
        active_currency:'',
        penalty_view:false,
        view_bttn:false,
        acc_prvdr:null,
        DelayReasons:[],
        Delay_total:[],
        onbord:"false",
        Disbursement_delay:null,
        DisbursementDelayMinutes:60,
        Disbursement_time_x_axis:[],
        Disbursement_time_Y_axis:[],
        Disbursement_total:[],
        apply_to_disb_time_chart:[],
        disb_time_all_bttn:true,
        repayment_time_x_axis:[],repayment_time_y_axis:[],repayment_total:[],
        repayment_time:{
          manual_capture:true,
          auto_capture:true,
        },
        lazy_loading:[],
        disbursement_spin_loader:false,
        disbursement_checkbox:
            {
             manual_approval :true,
             auto_approval:true, 
             auto_capture:true,
             manual_capture:true,
             multi_attempt:true,   
             single_attempt:true, 
             otp:true,
             not_otp:true,
             manual_disbursal:true,
             auto_disbursal:true,
             },
             loader:false,
      inbound_voice_chart_data: []
    };
  }


  componentWillMount() {
    this.setState({ startDate: this.props.data.start_date == "" ? this.state.startDate : this.props.data.start_date, endDate: this.props.data.end_date == "" ? this.state.endDate : this.props.data.end_date, country_code: this.props.data.country_code, history_datas: this.props.data.history_data })

    this.props.report_history()
    this.props.set_attr({ lazy_loading: this.state.lazy_loading })
    this.currency_details()
    this.loadAccounts()

  }
  currency_details() {
    getCurrencyDetails(this.req())
      .then((response) => {
        if (response.status == "success") {
          this.setState({ currency_details: response.data, active_currency: response.data[this.props.data.country_code].currency_code })
        }
      })
  }
  tabCheck = (e) => {

    this.setState({
      ...this.state,
      active: e,

    })
  }

  float_switch_accounts_options(accounts) {
    var arr = [];
    if (accounts.length > 0) {
      for (var i = 0; i < accounts.length; i++) {
        for (var j = 0; j < accounts.length; j++) {
          if (accounts[i] !== accounts[j]) {
            var obj = {
              from: accounts[i],
              to: accounts[j],
            };

            arr.push(obj);
          }
        }
      }
    }
    this.setState({ switch_acc_options: arr })
  }

  loadAccounts = () => {
    var account = { "country_code": this.country_code };
    getFloatSwitchAccounts(this.req({ account }, true))
      .then((response) => {
        this.resp(response)
        if (!response) { return };
        if (response.status === "success") {
          if (response.data != "") {
            this.setState({ accounts: response.data.acc_prvdrs })
            this.float_switch_accounts_options(response.data.acc_prvdrs)

          }
        } else {
          alert(response.message + "\n" + JSON.stringify(response.data));
        }
      }
      );

  }

  get_switch_accounts_options = (accounts, all = false) => {

    let acc_prvdr = all ? "ALL" : accounts.to
    this.setState({
      selected_options: accounts, switch_perf_data: [{ data: this.state.switch_perf_report.data[acc_prvdr], name: this.state.switch_perf_report.name }],
      both_switchs: all, switch_perf_data_label: this.state.switch_perf_report.data_label[acc_prvdr]
    })

  }

  componentDidUpdate(prevProps, prevState) {


    // this.setState({history_datas:this.props.data.history_data})
    if (prevProps == undefined || prevProps.data.start_date != this.props.data.start_date) {
      this.props.report_history()
      if (this.state.lazy_loading != undefined && this.state.lazy_loading.length > 0) {
        this.props.report_history(this.state.lazy_loading)

      }
    }
    if (this.props.data.country_code != this.state.country_code) {

      var curreny_code = this.state.currency_details ? this.state.currency_details[this.props.data.country_code].currency_code : ''
      this.setState({ active_currency: curreny_code, country_code: this.props.data.country_code, loader: true }, () => this.props.report_history(this.state.lazy_loading != undefined && this.state.lazy_loading.length > 0 ? this.state.lazy_loading : null),
        this.props.set_attr({ loader: true })

      )
    }
    if (this.props.data.gds_report_active_accPrvds != this.state.acc_prvdr) {
      if (this.props.data.gds_report_active_accPrvds != null) {
        this.setState({ acc_prvdr: this.props.data.gds_report_active_accPrvds }, () => {
          this.props.report_history()
          this.props.set_attr({ loader: true })
          if (this.state.lazy_loading != undefined && this.state.lazy_loading.length > 0) {
            this.props.report_history(this.state.lazy_loading)

          }
        }
        )
      }

        
    }
    if (JSON.stringify(this.props.data.history_data) !== JSON.stringify(this.state.history_datas) || prevState.DisbursementDelayMinutes != this.state.DisbursementDelayMinutes) {

      this.setState({ history_datas: this.props.data.history_data, loader: this.props.data.loader ? false : false, disbursement_spin_loader: false })

      if (this.props.data.history_data.appl_approvals) {
        const FAApplications = [{ data: this.props.data.history_data.appl_approvals.data, name: 'No. of FAs' }]
        const data = this.props.data.history_data.appl_approvals.category
        const total_fas = this.props.data.history_data.appl_approvals.total
        this.setState({ data, FAApplications, total_fas })
      }

      if (this.props.data.history_data.disb_delay_reason) {
        const DelayReasons = this.props.data.history_data.disb_delay_reason.category
        const Disbursement_delay = this.props.data.history_data.disb_delay_reason.data[0]
        const Delay_total = this.props.data.history_data.disb_delay_reason.data[0][this.state.DisbursementDelayMinutes].total
        const delay_chart = Object.values(Disbursement_delay[Object.keys(Disbursement_delay).filter((key) => key == this.state.DisbursementDelayMinutes)])
        const popped = delay_chart.pop()
        const delay_chart_data = [{ data: delay_chart, name: 'No. of FAs' }]
        this.setState({ DelayReasons, Delay_total, Disbursement_delay, delay_chart_data })
      }

      if (this.props.data.history_data.rm_visit_chart) {

        const TotalAndOverdueFARaw = this.props.data.history_data.rm_visit_chart;
        const TotalAndOverdueFA = this.make_series_data(TotalAndOverdueFARaw);
        this.setState({ TotalAndOverdueFARaw, TotalAndOverdueFA, TotalAndOverdueFADiv: true })

      }
      if (this.props.data.history_data.fa_applied_by_count){
        const Faapplied_by_countFARaw = this.props.data.history_data.fa_applied_by_count;
        const Faapplied_by_count = this.make_series_data(Faapplied_by_countFARaw);
        this.setState({Faapplied_by_countFARaw, Faapplied_by_count, Faapplied_by_countDiv: true })
      }
      if (this.props.data.history_data.float_switch_report_day_wise) {

        const sales_report_raw = this.props.data.history_data.float_switch_report_day_wise;
        const sales_report = this.make_series_data(sales_report_raw);
        this.setState({ sales_report_raw, sales_report, sales_report_rawDiv: true })

      }
      if (this.props.data.history_data.apply_to_disb_time_chart) {
        const Disbursement_time_x_axis = [{ data: this.props.data.history_data.apply_to_disb_time_chart.data, name: 'No. of FAs' }]
        const Disbursement_time_Y_axis = this.props.data.history_data.apply_to_disb_time_chart.category
        const Disbursement_total = this.props.data.history_data.apply_to_disb_time_chart.total
        this.setState({ Disbursement_time_x_axis, Disbursement_time_Y_axis, Disbursement_total, disbursement_spin_loader: false })

      }
      if (this.props.data.history_data.repay_to_settle_time_chart) {
        const repayment_time_x_axis = [{ data: this.props.data.history_data.repay_to_settle_time_chart.data, name: 'No. of FAs' }]
        const repayment_time_y_axis = this.props.data.history_data.repay_to_settle_time_chart.category
        const repayment_total = this.props.data.history_data.repay_to_settle_time_chart.total
        this.setState({ repayment_time_x_axis, repayment_time_y_axis, repayment_total })
      }

      if (this.props.data.history_data.switch_perf_report) {
        const switch_perf_report = this.props.data.history_data.switch_perf_report
        const switch_perf_data = [{ data: switch_perf_report.data.ALL , name: switch_perf_report.name }]
        const switch_perf_data_label = switch_perf_report.data_label.ALL
        this.setState({ switch_perf_report, switch_perf_data, switch_perf_data_label });
      }

      if (this.props.data.history_data.disb_perf_report) {
        const disb_perf_report = this.props.data.history_data.disb_perf_report
        const disb_perf_data = [{ data: disb_perf_report.data, name: disb_perf_report.name }]
        const disb_perf_data_label = this.props.data.history_data.disb_perf_report.data_label
        this.setState({ disb_perf_report, disb_perf_data, disb_perf_data_label });
      }
      if (this.props.data.start_date !== this.state.startDate) {
        this.setState({ startDate: this.props.data.start_date })
      }
      if (this.props.data.history_data && this.props.data.history_data.disb_dup_n_rtn_count) {
        const { return_disb, dup_disb } = this.props.data.history_data.disb_dup_n_rtn_count;
    
        if (return_disb !== undefined && dup_disb !== undefined) {
          const totalValue_from = dup_disb - return_disb;
          const dup_data_from = [return_disb, dup_disb];
          this.setState({dup_data:dup_data_from,totalValue:totalValue_from});
        }
      }
      if(this.props.data.history_data.churn_management_count){
         
        const ChurnManagementCountRaw =this.props.data.history_data.churn_management_count;
        const ChurnManagementCountRaw_order = ['chu_count','cmd_cs_count','tk_cs_rd_count'];
        const emptyarray=[];

        ChurnManagementCountRaw_order.forEach((items,idx)=>{
          emptyarray[idx] = ChurnManagementCountRaw[items]
        })
        this.setState({ChurnManagementCount:emptyarray,churn_management_count:this.props.data.history_data.churn_management_count})
       }
    }


  }

  onboarded_notification(val){

    this.setState({onbord:val})

  }

  make_series_data = (array, type) => {

    var series = [];
    const curVal = this.state.activeCurrencyVal == '' ? 1 : this.state.activeCurrencyVal
    var orginal_data = JSON.parse(JSON.stringify(array));
    var data_arr = []
    var startDate = this.state.startDate ? this.state.startDate : ""
    var endDate = this.state.endDate ? this.state.endDate : ""
    if (startDate !== "" || endDate !== "") {

      Object.keys(orginal_data).map(function (key) {
        data_arr[key] = [];
        orginal_data[key].map(function (each, idx) {
          let crnt_date = moment(orginal_data[key][idx]['x'])
          data_arr[key].push(orginal_data[key][idx])
        })
      })
    }
    else {
      data_arr = orginal_data
    }

    Object.keys(data_arr).map(function (key) {


      var series_data = {};
      if (key != "*") {
        series_data['name'] = key
      } else {
        series_data['name'] = "Global"
      }

      if (type == 'currency' && key != '*') {
        data_arr[key].map(function (each, idx) {
          if (idx != "type") {
            data_arr[key][idx]['y'] = data_arr[key][idx]['y'] * curVal
          }
        })
      }
      series_data['data'] = data_arr[key]
      if ("type" in data_arr[key]) {
        // alert(data_arr[key]["type"])
        series_data['type'] = data_arr[key]["type"];
      }
      series.push(series_data)
    });
    return series;
  }

  common_percentage_cal(val, total) {
    if (total == 0) {
      return "0 % ";
    }
    else if ((val / total) * 100 >= 0) {
      return (val / total * 100).toFixed() + "% ";
    } else {
      return "0 % ";
    }

  }

  disbursement_time_charts(type, val) {

    var disbursement_checkbox = this.state.disbursement_checkbox
    disbursement_checkbox[type] = !val
    this.setState({ disbursement_checkbox }, () => {

      const datas = this.state.disbursement_checkbox
      var flg = true;
      for (const key in datas) {

        if (datas[key] == false) {
          flg = false
          this.setState({ disb_time_all_bttn: false })
          break;
        }

      }
      if (flg) {
        this.setState({ disb_time_all_bttn: true })
      }

      this.props.report_history("apply_to_disb_time_chart", this.props.data.start_date, this.props.data.end_date, this.props.data.gds_report_active_accPrvds, this.props.data.country_code, { "apply_to_disb_time_chart": this.state.disbursement_checkbox })

    })

  }
  disb_time_all_handler() {

    const datas = this.state.disbursement_checkbox
    var disb_val = this.state.disbursement_checkbox;
    for (const key in datas) {
      disb_val[key] = true

    }
    this.setState({ disbursement_checkbox: disb_val, disb_time_all_bttn: true })
    this.props.report_history("apply_to_disb_time_chart", this.props.data.start_date, this.props.data.end_date, this.props.data.gds_report_active_accPrvds, this.props.data.country_code, { "apply_to_disb_time_chart": this.state.disbursement_checkbox })
  }

  repayment_time_charts(type, val) {
    var repayment_time = this.state.repayment_time
    repayment_time[type] = !val
    this.setState({ repayment_time }, () => {

      this.props.report_history("repay_to_settle_time_chart", this.props.data.start_date, this.props.data.end_date, this.props.data.gds_report_active_accPrvds, this.props.data.country_code, { "repay_to_settle_time_chart": this.state.repayment_time })
    })
  }

  lazy_loading_handler(val) {


    var lazy_loading_array = this.state.lazy_loading
    lazy_loading_array.push(val)
    this.setState({ lazy_loading: lazy_loading_array, val: true })

    this.props.set_attr({ lazy_loading: lazy_loading_array })
    this.props.report_history(lazy_loading_array.length > 0 ? lazy_loading_array : null)
    this.setState({ val: false })
  }
  handleDelay = (e) => {
    this.setState({ DisbursementDelayMinutes: e })
  }
      get_inbound_voice_call_data = ()=>{
        var request ={report_type:'inbound_voice_calls_downtime',acc_prvdr:this.props.active_acc_prvdr,start_date:this.props.data.start_date,end_date:this.props.data.end_date,screen:'past'}

        inbound_voice_call_report(this.req({...request})).then((response)=>{
          if(!response) return;
          if(response.status =='success'){
            if(response.data){
              let x_axis_data = response.data.map((val)=>{
                let match = get_cs_agent_names(val.x);
                if (match) {
                    return match;
                }
                return val.x;
              })
              let y_axis_data_in_mins = response.data.map((val)=>val.y)

              let y_axis_data_in_hrs = response.data.map((val)=>{
                let formattedTime = convertMinutesToHours(val.y);
                return formattedTime;
              })

              this.setState({inbound_voice_chart_data:[{data:y_axis_data_in_mins,name:'Down Time In Minutes'},{data:y_axis_data_in_hrs,name:'Down Time In Hours'}],x_axis_inbound_voice_data:x_axis_data})
            }
          }
          else{
            return;
          }

        }).catch(error=>{
          alert(error)
        })
      }

    render(){ 
    return (
      <div>
        <div className='row ' style={{ background: '#4291da', padding: '15px' }}>

          <div className='d-flex align-items-center'>
            {this.props.data.start_date_view_btn ? "" :
              <p className='text-white no-margin business_operations_text biz_operation_font_size' style={{ fontSize: '18px' }}><b>{this.props.data.today_btn ? "Today" : this.props.data.yester_day_btn ? "Yesterday" : this.props.data.day_bf_btn ? "Day b/f y/day" : this.props.data.this_month_btn ? "This Month" : this.props.data.last_month_btn ? "Last Month" : ""}</b></p>
            }

            {this.props.data.start_date_view_btn ?

              <div className='d-flex align-items-center'>
                <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>

              </div>
              :
              <div className='d-flex align-items-center biz_operation_font_size'>
                {this.props.data.this_month_btn ?
                  <>
                    <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>
                  </>
                  :
                  <div className='d-flex align-items-center'>
                    {this.props.data.last_month_btn ?
                      <>
                        <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>
                      </> :
                      <>
                        {this.props.data.last_days_and_month_btn ?
                          <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>

                          :
                          <>
                            <p className='no-margin biz_operation_font_size' ><b>{this.state.startDate == moment().subtract(1, 'days').format('dddd') ? moment().subtract(1, 'days').format('dddd') : moment(this.state.startDate).format('dddd')} <span className='biz_operation_font_size' > | </span></b>  </p>
                            <p className='no-margin biz_operation_font_size'  ><b>{this.state.startDate == moment().subtract(1, 'days').format('dddd') ? moment().subtract(1, 'days').format('DD MMM YYYY') : moment(this.state.startDate).format('DD MMM YYYY')}</b></p>
                          </>
                        }

                      </>
                    }

                  </div>

                }

              </div>

            }
          </div>
        </div>
        <div style={{ minHeight: '80vh' }}>
          {this.props.data.loader ?
            <div class="loader">

              <div class="cogs">
                <div class="cog cog0">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog1">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog2">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
              </div>
              <div className='load_dot'>
                <div class="dot">F</div>
                <div class="dot">L</div>
                <div class="dot">O</div>
                <div class="dot">W</div>
                <div class="dot">I</div>
                <div class="dot">N</div>
                <div class="dot">G</div>
              </div>

            </div>
            :
            <>
              <div className='d-flex align-items-center justify-content-between row'>
                <div className='col-md-12 col-lg-4 col-sm-12'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ minHeight: '310px' ,height: "343px"}}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <BiAlarmAdd size={25} color={"white"} style={{ marginTop: '-4px', fontWeight: 'bold'}} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px', fontWeight: 'bold' }} >Penalty</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.penalty ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler("penalty")} > <b>Show</b></button> </div>
                    }
                    <div style={{ padding: '16px' }} className={`${this.state.history_datas.penalty ? "" : 'float_account_balance_bgblur'}`}>
                      <div>
                      <div className='yesterday_penalty' style={{ padding: '14px 5px 6px' }}>
                          <p className='text-white text-center no-margin yesterday_penalty_text_color' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '18px', fontWeight: 'bold' }}>Penalty Imposed</p>
                          <div className='d-flex align-items-center justify-content-between roundoffvalue' style={{ margin: '14px 42px', fontSize: '19px', fontWeight: 'bold' }}>
                              <RoundOffSplitter val={this.state.history_datas.penalty ? this.state.history_datas.penalty.imposed : ''} unit={this.state.active_currency} align_center={true} position={"top"} />
                              <div style={{ width: "14%", height: "44px", borderRadius: "50%", border: "1px solid rgba(125, 166, 203, 1)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <span className='no-margin business_operations_text biz_operation_font_size no-padding fas_pending_icon_color' style={{  fontSize: '16px', color: '#ec9498', fontWeight: "bold" }}>{this.state.history_datas.penalty ? this.state.history_datas.penalty.imposed_count : ''}</span>
                              </div>
                          </div>
                      </div>


                        <div className='row d-flex align-items-center justify-content-between no-margin '>
                          <div className='yesterday_penalty col-md-5' style={{ padding: '9px 10px 7px', margin: '18px 10px 10px 10px' }}>
                            <div >
                              <p className='text-white text-center no-margin yesterday_penalty_text_color' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '18px', fontWeight: 'bold' }} >Collected </p>
                              <div className='d-flex align-items-center justify-content-between roundoffvalue ' style={{ padding: '20px 7px', fontSize: '19px',fontWeight:"bold" }}>
                                <RoundOffSplitter val={this.state.history_datas.penalty ? this.state.history_datas.penalty.collected : ''} unit={this.state.active_currency} align_center={true} position={"top"} />
                                <p className='no-margin business_operations_text biz_operation_font_size  no-padding fas_pending_icon_color' ><b style={{  fontSize: '16px', color: '#ec9498' ,fontWeight:"bold"}} >{this.state.history_datas.penalty ? this.state.history_datas.penalty.collected_count : ''} </b></p>

                              </div>
                            </div>
                          </div>

                          <div className='yesterday_penalty col-md-5' style={{ padding: '9px 10px 7px', margin: '18px 10px 10px 10px' }}>
                            <div >
                              <p className='text-white text-center no-margin yesterday_penalty_text_color' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '18px', fontWeight: 'bold' }} >Waived</p>
                              <div className='d-flex align-items-center justify-content-between roundoffvalue' style={{ padding: '20px 7px', fontSize: '19px' ,fontWeight:"bold"}}>
                                <RoundOffSplitter val={this.state.history_datas.penalty ? this.state.history_datas.penalty.waived : ''} unit={this.state.active_currency} align_center={true} position={"top"} />
                                <p className='no-margin business_operations_text biz_operation_font_size  no-padding fas_pending_icon_color' ><b style={{  fontSize: '16px', color: '#ec9498' ,fontWeight :"bold"}} >{this.state.history_datas.penalty ? this.state.history_datas.penalty.waived_count : ''} </b></p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className='col-md-12 col-lg-4 col-sm-12'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ minHeight: '310px' ,height: "343px"}}>
                      <div className='row'>
                        <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                            <img className={`img-fluid `}src={`/img/Disbursal.png`} alt={""}  style={{ marginTop: '-10px',width:"7%" }}/> 
                            <h2 className='text-white no-margin yesterday_penalty_text_color' style={{ padding: '3px 0px 10px 10px', fontSize: '18px', fontWeight: 'bold' }} >Duplicate Disbursement</h2>
                        </div>
                      </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.disb_dup_n_rtn_count ? "" :
                        <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('disb_dup_n_rtn_count')} > <b>Show</b></button> </div>
                      }
                      <div className = "d-flex align-items-center" style={{height: '88%'}}>
                        <div className="col-md-6">
                            <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{background:"rgba(66, 145, 218, 0.08)",borderColor:"rgba(0, 0, 0, 0.25)",boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 0px"}}>
                                  <p className='text-white no-margin yesterday_penalty_text_color' style={{ fontSize: '13px', fontWeight: 'bold',paddingBottom:"15px"}} >Returned  Disbursements</p>
                                  <div className="d-flex align-items-center">
                                  <p className=' no-margin  fas_pending_icon_color' > 
                                  <b style={{ fontSize: '19px', fontWeight: 'bold' ,color:"rgba(255, 255, 255, 1)"}}>{this.state.history_datas.disb_dup_n_rtn_count ? this.state.history_datas.disb_dup_n_rtn_count.return_disb : ''}</b></p>
                                  <p className="my-0 ml-3" style={{fontSize:"12px",color:"rgba(255, 255, 255, 1)" }}>{this.state.history_datas.disb_dup_n_rtn_count ? (this.state.history_datas.disb_dup_n_rtn_count.return_disb  / this.state.history_datas.disb_dup_n_rtn_count.dup_disb * 100) + '%':''}</p>
                                  </div>
                                  {/* <p className='text-white no-margin text-center biz_ops_green_text'> <b>90 %</b></p> */}
                            </div>

                            <div className='biz_operration_padding business_operations_border  biz_opr_fas_pending biz_operation_font_size' style={{background:"rgba(66, 145, 218, 0.08)",borderColor:"rgba(0, 0, 0, 0.25)",boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 0px"}}>
                              <p className='text-white no-margin yesterday_penalty_text_color' style={{ fontSize: '12px', fontWeight: 'bold',paddingBottom:"13px"}} >Un-Returned Disbursement</p>
                              <div className="d-flex align-items-center">
                                <p className=' no-margin  fas_pending_icon_color' > <b style={{ fontSize: '19px', fontWeight: 'bold',color:"rgba(255, 255, 255, 1)"}}>{this.state.totalValue}</b></p>
  <p className="my-0 ml-3" style={{fontSize:"12px" ,color:"rgba(255, 255, 255, 1)"}}>{this.state.history_datas.disb_dup_n_rtn_count ? (this.state.totalValue / this.state.history_datas.disb_dup_n_rtn_count.dup_disb * 100) + '%':''}</p>
                              </div>
                            </div>
                        </div>
                        {this.state.dup_data && this.state.totalValue &&
                        <div className='col-md-6 d-flex justify-content-center' style={{"margin-top":"9%"}}>
                          <ReactApexChart options={this.state.options} series={this.state.dup_data} type="donut" width={380}  />
                          <div className='text-center' style={{ position: 'absolute', top: '50%', transform: 'translate(2px, -80px)','margin-left': '87px' }}>
                            <h2 className='text-white monthly_report_piechart_gender'>Total</h2>
                            <h2 className='text-white monthly_report_piechart_gender' style={{ fontSize: '14px', fontWeight: 'bold' ,color:"rgba(255, 255, 255, 1)"}} >{this.state.history_datas.disb_dup_n_rtn_count ? this.state.history_datas.disb_dup_n_rtn_count.dup_disb : ''}</h2>
                          </div>
                        </div>
                        }
                    </div>

                  </div>
                </div>
                <div className='col-md-12 col-lg-4 col-sm-12'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: "343px" }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        {/* <FaUserNurse size={20} color={"white"} style={{ marginTop: '-4px' }} /> */}
                        <BsReverseLayoutTextSidebarReverse size={20} color={"white"} style={{ marginTop: '-4px' }}/>
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px', fontWeight: 'bold' }}  >FA Application Mode</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.fa_applied_by_count ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('fa_applied_by_count')} > <b>Show</b></button> </div>
                    }
                    {this.state.Faapplied_by_count.length > 0?
                      <div className={`row d-flex justify-content-between align-items-center disbursement_chart ${this.state.history_datas.rm_metrics ? "" : 'float_account_balance_bgblur'} `} style={{ padding: ' 0px 20px' }}>
                        <div className='col-12'>
                          <ReportChart data={this.state.Faapplied_by_count ? this.state.Faapplied_by_count : ''} type="line" xaxistype='category' show_toolbar={false} axisBorder={true}
                             x_axis_format={true} grid={false} biz_ops_chart_height={this.state.Faapplied_by_count ? 400 : 300} />
                        </div>
                      </div>
                      :
                      <p className='text-white text-center' style={{ marginTop: '30%', fontSize: '15px' }}>No Data</p>
                    }
                  </div>
                </div>

              </div>

              <div className='d-flex align-items-center justify-content-between row'>
                <div className='col-md-12  col-lg-4 col-sm-12' >
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '370px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineUserSwitch size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }}  >RM Visit</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.rm_metrics ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('rm_metrics')} > <b>Show</b></button> </div>
                    }
                   <div className={`${this.state.history_datas.rm_metrics ? "" : 'float_account_balance_bgblur'}`}>
                      <Tabs
                        defaultActiveKey={this.state.active}
                        id="justify-tab-example"
                        className={`mb-3`}
                        justify style={{ marginTop: '10px' }}
                        onSelect={(e) => this.tabCheck(e)}
                      >

                        <Tab eventKey="Today" title={this.state.active == 'Today' ? <button type="button" class="btn  no-margin " style={{ padding: '8px 70px', "background-color": "rgba(255, 255, 255, 0.83)",color:"rgba(0, 0, 0, 1)","font-size" : "11px ","border-radius" : "7px","font-weight":"700"}}> <b> Check-In</b></button> : <p className='text-white no-margin' style={{fontWeight:"bold"}}> Check-In</p>}>

                          <div className={` d-flex justify-content-around align-items-center `} style={{  marginTop: '40px',marginRight:"14px",marginLeft:"1px" }}>
                            <div className='col h-100' >
                              <div className='biz_operration_padding  biz_opr_fas_pending  regular_checkin' style={{height: "173px", width:"111%",border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)"}}>
                                <p className='text-white no-margin text-center' style={{ "padding-top": "16px" ,fontSize: '13px', fontWeight: 'bold'}}>Regular Check-In</p>
                                <div className=" d-flex justify-content-around align-items-center" style={{"margin-top": "50px"}}>
                                  <p className=' no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px' ,color:"rgba(235, 199, 232, 1)"}}> <b style={{ fontSize: '18px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.regular_checkin_count : ''}</b></p>
                                  <h2 className=' no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '18px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.regular_checkin_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                </div>
                              </div>

                            </div>

                            <div className='col h-100' style={{}}>
                              <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px", width:"111%",border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                <p className='text-white no-margin text-center' style={{ "padding-top": "16px" ,fontSize: '14px', fontWeight: 'bold'}}>Force Check-In <br /> <span style={{ fontSize: '13px' }}>(Inside circle)</span></p>
                                <div className=" d-flex justify-content-around align-items-center" style={{"margin-top": "35px"}}>
                                <p className=' no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px' ,color:"rgba(235, 199, 232, 1)" }}> <b style={{ fontSize: '18px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkin_in_count : ''}</b></p>
                                <p className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '18px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkin_in_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></p>
                              </div>
                              </div>
                            </div>
                            <div className='col h-100' style={{}}>
                              <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px", width:"111%",border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                <p className='text-white no-margin text-center' style={{ "padding-top": "16px" ,fontSize: '14px', fontWeight: 'bold'}}>Force Check-In <br /> <span style={{ fontSize: '13px' }}>(Outside circle)</span></p>
                                <div className=" d-flex justify-content-around align-items-center" style={{"margin-top": "35px"}}>
                                <p className=' no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px'  ,color:"rgba(235, 199, 232, 1)"}}> <b style={{ fontSize: '18px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkin_out_count : ''}</b></p>
                                <h2 className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '18px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkin_out_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                              </div>
                              </div>
                            </div>
                          </div>

                        </Tab>
                        <Tab eventKey="YesterDay" title={this.state.active === 'YesterDay' ? 
                              <button type="button" class="btn  no-margin " style={{ padding: '8px 70px', "background-color": "rgba(255, 255, 255, 0.83)",color:"rgba(0, 0, 0, 1)","font-size" : "11px ","border-radius" : "7px","font-weight":"700"}}> <b> Check-Out</b></button> : 
                              <p className='text-white no-margin' style={{fontWeight:"bold"}}> Check-Out</p>}>

                            <div className={` d-flex justify-content-around align-items-center `} style={{  marginTop: '40px',marginRight:"14px",marginLeft:"1px" }}>
                              <div className='col h-100' >
                                <div className='biz_operration_padding  biz_opr_fas_pending  regular_checkin' style={{ height: "173px", width: "111%", border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                  <p className='text-white no-margin text-center' style={{ paddingTop: "16px", fontSize: '13px', fontWeight: 'bold' }}>Regular Check-Out</p>
                                  <div className=" d-flex justify-content-around align-items-center" style={{ marginTop: "39px" }}>
                                    <p className='no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px'  ,color:"rgba(235, 199, 232, 1)"}}> <b style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.regular_checkout_count : ''}</b></p>
                                    <h2 className='no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.regular_checkout_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                  </div>
                                </div>
                              </div>

                              <div className='col h-100' style={{}}>
                                <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px", width: "111%", border: "1px solid rgba(125, 166, 203, 1)" ,background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)"}}>
                                  <p className='text-white no-margin text-center' style={{ paddingTop: "16px", fontSize: '14px', fontWeight: 'bold' }}>Force Check-Out<br /> <span style={{ fontSize: '13px' }}>(Inside circle)</span></p>
                                  <div className=" d-flex justify-content-around align-items-center" style={{ marginTop: "35px" }}>
                                    <p className='no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px' ,color:"rgba(235, 199, 232, 1)" }}> <b style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkout_in_count : ''}</b></p>
                                    <h2 className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkout_in_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                  </div>
                                </div>
                              </div>
                              <div className='col h-100' style={{}}>
                                <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px", width: "111%", border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                  <p className='text-white no-margin text-center' style={{ paddingTop: "16px", fontSize: '14px', fontWeight: 'bold' }}>Force Check-out <br /> <span style={{ fontSize: '13px' }}>(Outside circle)</span></p>
                                  <div className=" d-flex justify-content-around align-items-center" style={{ marginTop: "35px" }}>
                                    <p className='no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px'  ,color:"rgba(235, 199, 232, 1)"}}> <b style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkout_out_count : ''}</b></p>
                                    <h2 className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkout_out_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Tab>


                      </Tabs>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 col-lg-4 col-sm-12'>

                <div className='business_operations_border biz_operation_fas_pending' style={{ height: '370px' }}>

                    <div className='row'>
                        
                        <div className='d-flex align-items-center'>
                            
                            <div className='d-flex p-0 align-items-center biz_operation_font_size' style={{ margin: '10px 0px 0px 10px' }}>
                                <img className={`img-fluid `}src={`/img/Disbursal.png`} alt={""}  style={{ marginTop: '-10px',width:"12%" }}/> 
                                    <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }} >Churn Management</h2>
                            </div>

                            
                            <div>
                                {this.state.history_datas.churn_management_count ? " " :
                                <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("churn_management_count")}> <b>Show</b></button> </div>
                                }
                            </div>

                        </div>

                    </div>
                    
                    <div className='business_operations_bottom_border'></div>

                    <div className='d-flex justify-content-between align-items-center row ' style={{padding: '5%' ,marginTop:"24px"}}>

                        {this.state.ChurnManagementCount.map((item, idx) => (

                            <div className='col-4' key={idx} >

                                <div className=' biz_operration_padding yesterday_penalty business_operations_border  ' >
                                
                                {item.title !== "Churn Count" && (
                                    <div style={{height:"150px"}}>
                                      <div className='align-items-center justify-content-center ' style={{marginTop:"38px"}}>
                                        <p className='text-white no-margin text-center' style={{ fontSize: '14px', fontWeight: 'bold'}}> {item.title} </p>
                                      </div>
                                      <div className='align-items-center justify-content-center ' style={{marginTop:"47px"}}> 
                                        <p className={`text-white no-margin  text-center ${this.state.history_datas.churn_management_count ? " " : 'float_account_balance_bgblur'}`}>
                                          <b className=''  style={{ color:"rgba(223, 140, 148, 1)",fontSize: '19px', fontWeight: 'bold'}}> {item.value} </b>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                {item.title === "Churn Count" && (
                                      <div style={{height:"150px"}}>
                                        <div className='align-items-center justify-content-center' style={{marginTop:"38px"}} >
                                          <p className='text-white no-margin text-center' style={{ fontSize: '14px', fontWeight: 'bold'}}> {item.title} </p>
                                        </div>
                                        <div className='align-items-center justify-content-center ' style={{marginTop:"66px"}}>  
                                          <p className={`text-white no-margin  text-center ${this.state.history_datas.churn_management_count ? " " : 'float_account_balance_bgblur'}`}>
                                            <b className=''  style={{color:"rgba(223,140, 148, 1)", fontSize: '19px', fontWeight: 'bold'}}> {item.value} </b>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    
                                </div>

                            </div>

                        ))}

                    </div>

                </div>
                
            </div>
                <div className='col-md-12 col-lg-4 col-sm-12'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ minHeight: '370px', maxHeight:'400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineTablet size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }}  >Mobile App Users</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.mobile_users ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('mobile_users')} > <b>Show</b></button> </div>
                    }
                    <div className={`${this.state.history_datas.mobile_users ? "" : 'float_account_balance_bgblur'}`} style={{ padding: '0px 10px' }}>
                      <div className='row d-flex align-items-center justify-content-between  no-margin '>

                        <div className='biz_opr_fas_pending col-md-5 col-lg-5' style={{ padding: '0px 10px', margin: '8px 10px' ,border: "1px solid rgba(125, 166, 203, 1)"}}>
                          <div >
                            <div className="d-flex justify-content-between">
                              <p className='text-white text-center no-margin' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '14px', paddingTop: '5px',fontWeight:"bold" }}> Newly Onboarded</p>
                              {this.state.onbord === "false" ? (
                                <BsInfo size={25} color={"white"} onClick={() => this.onboarded_notification("true")} />
                              ) : (
                                <BsXOctagonFill size={15} color={"white"} onClick={() => this.onboarded_notification("false")} style={{transform:" translate(3px, 7px)"
                                }}/>
                              )}
                            </div>
                            {this.state.onbord === "false" ? (
                            <div className='d-flex align-items-center justify-content-between ' style={{ padding: '5px 5px 10px', fontSize: '16px', marginTop: '10px' }}>
                              <p className='no-margin business_operations_text biz_operation_font_size no-padding'><b> {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.newly_onboarded.total : ''}/</b>{this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.newly_onboarded.value : ''}</p>
                              <div className='biz_ops_mobile_installations' style={{ background: `#993b96`, padding: '5px' }}>
                                <p className='text-white no-margin' style={{ fontSize: '16px' }}>{<BiUser />}</p>
                              </div>
                            </div>
                            ) :(
                              <div>
                                <h6 className='text-white' style={{fontSize:"5px",marginTop:"3px"}}>The counts of newly onboarded customers who installed the app during the onboarding process.</h6>
                                <div className='d-flex justify-content-between '>
                                  <p className='text-white no-margin  ' style={{ fontSize: '11px',fontWeight:"bold" }}>Total Onboarded</p>
                                  <p className='text-white no-margin  ' style={{ fontSize: '11px',fontWeight:"bold" }}>
                                  {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.newly_onboarded.value : ''}
                                  </p>
                                </div>
                                <div className='d-flex justify-content-between '>
                                  <p className='text-white no-margin ' style={{ fontSize: '11px',fontWeight:"bold"  }}>App installed</p>
                                  <p className='text-white no-margin  ' style={{ fontSize: '11px',fontWeight:"bold"  }}>
                                  {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.newly_onboarded.total : ''}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='biz_opr_fas_pending col-md-5 col-lg-5' style={{ padding: '0px 10px', margin: '8px 10px' ,border: "1px solid rgba(125, 166, 203, 1)"}}>
                          <div >
                            <p className='text-white text-center no-margin' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '14px', paddingTop: '5px',fontWeight:"bold"  }}> Installations </p>
                            <div className='d-flex align-items-center justify-content-between ' style={{ padding: '5px 5px 10px', fontSize: '16px', marginTop: '10px' }}>
                              <p className='no-margin business_operations_text biz_operation_font_size no-padding'><b> {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.registered.value : ''}</b></p>
                              <div className='biz_ops_mobile_installations' style={{ background: `#bfc95c`, padding: '5px' }}>
                                <p className='text-white no-margin' style={{ fontSize: '16px' }}>{<BsFillPersonCheckFill />}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='biz_opr_fas_pending col-md-5 col-lg-5' style={{ padding: '0px 10px', margin: '8px 10px' ,border: "1px solid rgba(125, 166, 203, 1)"}}>
                          <div >
                            <p className='text-white text-center no-margin' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '14px', paddingTop: '5px',fontWeight:"bold"  }}> Active Installations </p>
                            <div className='d-flex align-items-center justify-content-between ' style={{ padding: '5px 5px 10px', fontSize: '16px', marginTop: '10px' }}>
                              <p className='no-margin business_operations_text biz_operation_font_size no-padding'><b> {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.active.value : ''}</b></p>
                              <div className='biz_ops_mobile_installations' style={{ background: `#bfc95c`, padding: '5px' }}>
                                <p className='text-white no-margin' style={{ fontSize: '16px' }}>{<BsFillPersonCheckFill />}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='biz_opr_fas_pending col-md-5 col-lg-5' style={{ padding: '0px 10px', margin: '8px 10px' ,border: "1px solid rgba(125, 166, 203, 1)"}}>
                          <div >
                            <p className='text-white text-center no-margin' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '14px', paddingTop: '5px',fontWeight:"bold"  }}>FAs Applied  </p>
                            <div className='d-flex align-items-center justify-content-between ' style={{ padding: '5px 5px 10px', fontSize: '16px', marginTop: '10px' }}>
                              <p className='no-margin business_operations_text biz_operation_font_size no-padding'><b> {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.fas_applied.value : ''}</b></p>
                              <div className='biz_ops_mobile_installations' style={{ background: `#c47630`, padding: '5px' }}>
                                <p className='text-white no-margin' style={{ fontSize: '16px' }}>{<BiTask />}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                       <div className='biz_opr_fas_pending col-md-5 col-lg-5' style={{ padding: '0px 10px', margin: '8px 10px' ,border: "1px solid rgba(125, 166, 203, 1)"}}>
                          <div >
                            <p className='text-white text-center no-margin' id='justify-tab-example-tab-YesterDay' style={{ fontSize: '14px', paddingTop: '5px',fontWeight:"bold"  }}> FAs Repaid </p>
                            <div className='d-flex align-items-center justify-content-between ' style={{ padding: '5px 5px 10px', fontSize: '16px', marginTop: '10px' }}>
                              <p className='no-margin business_operations_text biz_operation_font_size no-padding'><b> {this.state.history_datas.mobile_users ? this.state.history_datas.mobile_users.fas_repaid.value : ''}</b></p>
                              <div className='biz_ops_mobile_installations' style={{ background: `#3d9668`, padding: '5px' }}>
                                <p className='text-white no-margin' style={{ fontSize: '16px' }}>{<BiTask />}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

              <div className='d-flex align-items-center justify-content-between row'>
              <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ 'height': '427px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                      <FaUserNurse size={20} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }} >Visits</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>


                    {this.state.history_datas.rm_visit_chart ? "" :
                        <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('rm_visit_chart')} > <b>Show</b></button> </div>
                    }

                    <div className={`row d-flex justify-content-between align-items-center `} style={{ padding: ' 0px 20px',marginTop:'6%' }}>
                      <div className='col-md-12 disbursement_chart'>
                          <ReportChart data={this.state.TotalAndOverdueFA ? this.state.TotalAndOverdueFA : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} biz_ops_chart_height={this.state.TotalAndOverdueFA ? 400 : 300} x_axis_format={true} grid={false}  colors={['#0370BE','#F0F34F', '#FF578D', '#ff8cc6']} />
                      </div>
                    </div>
                  </div>
                </div>


                <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ minHeight: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineSchedule size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }}>FA Applications</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>


                    {this.state.history_datas.appl_approvals ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('appl_approvals')} > <b>Show</b></button> </div>
                    }
                    <div className={`total_fa_count_biz_operation ${this.state.history_datas.appl_approvals ? "" : 'float_account_balance_bgblur'}`} style={{ padding: '5px' }}><p className='text-white no-margin'>Total : {this.state.history_datas.appl_approvals ? this.state.history_datas.appl_approvals.total : ''}</p></div>

                    <div className={`row d-flex justify-content-between align-items-center `} style={{ padding: ' 30px 52px' }}>
                      <div className='col-md-12 disbursement_chart'>
                        <ReportChart data={this.state.FAApplications ? this.state.FAApplications : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} grid={false}
                          x_axis_format={false} total_fas={this.state.total_fas ? this.state.total_fas : ''} y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.data ? this.state.data : ''} dataLabel={true} distributed={true} colors={['#1F9D57', '#0370BE', '#FF578D', '#F0F34F']} biz_ops_chart_height={300} biz_ops_colunm_width={'25%'} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='d-flex align-items-center justify-content-between row'>
              <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineFieldTime size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }}  >Disbursement Delay Reasons</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.disb_delay_reason ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('disb_delay_reason')} > <b>Show</b></button> </div>
                    }
                    <div className='total_fa_count_biz_operation' style={{ padding: '5px' }}>
                      <select className='form-control delayMin' id='delay_minutes' value={this.state.DisbursementDelayMinutes} onChange={(e) => this.handleDelay(e.currentTarget.value)} required>
                        {this.state.Disbursement_delay && Object.keys(this.state.Disbursement_delay).map((key, index) => {
                          return (
                            <option value={key}>Above {moment.utc(1000 * key).format('m')} minute</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className={`row d-flex justify-content-between align-items-center ${this.state.history_datas.disb_delay_reason ? "" : 'float_account_balance_bgblur'}  `} style={{ padding: '30px' }}>
                      <div className='col-md-12 disbursement_chart'>
                        {this.state.delay_chart_data && <ReportChart data={this.state.delay_chart_data ? this.state.delay_chart_data : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} grid={false}
                          x_axis_format={false} total_fas={this.state.Delay_total ? this.state.Delay_total : ''} y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.DelayReasons ? this.state.DelayReasons : ''} dataLabel={true} distributed={true} colors={['#1F9D57', '#0370BE', '#FF578D', '#F0F34F']} biz_ops_chart_height={300} biz_ops_colunm_width={'25%'} />

                        }    </div>
                    </div>
                  </div>
                </div>

              
              <div className='col-md-12 col-lg-6'>
                <div className='business_operations_border biz_operation_fas_pending' style={{height:'400px'}}>
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <AiOutlineSchedule size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}}>Float Switch Reports (Day Wise)</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    
                    
                    {this.state.history_datas.float_switch_report_day_wise ? 
                    <>
                     {this.state.history_datas.float_switch_report_day_wise && this.state.TotalAndOverdueFA.length>0 && (this.state.sales_report  && this.state.sales_report.length>0) ?
                    <div className={`row d-flex justify-content-between align-items-center disbursement_chart ${this.state.history_datas.rm_metrics ? "" : 'float_account_balance_bgblur'} `} style={{padding:' 0px 52px'}}>
                        <div className='col-12'>
                        <ReportChart data={this.state.sales_report ?this.state.sales_report:''} type="line" xaxistype ='category' show_toolbar={false} axisBorder={true} 
                      legend={true} y_axis_tittle={"Switch Count"}   x_axis_format={true} grid={false}  biz_ops_chart_height={this.state.sales_report ? 450:300} />
                    </div>
                    </div>
                    :
                    <p className='text-white text-center' style={{marginTop:'21%',fontSize:'15px'}}>no data</p>
                  }
                    </>
                    :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.lazy_loading_handler('float_switch_report_day_wise')} > <b>Show</b></button> </div>
                     }
                  
                    
                    </div>
                   </div>

            
            </div>

              

              {/* <div className='d-flex align-items-center justify-content-between row'>
                <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineFieldTime size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }} >Disbursement Performance Report</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.disb_perf_report ? <>
                      <div className={`row d-flex justify-content-between align-items-center ${this.state.history_datas.disb_perf_report ? "" : 'float_account_balance_bgblur'}  `} style={{ padding: '30px' }}>
                        <div className='col-md-12 disbursement_chart'>
                          {this.state.disb_perf_data && this.state.disb_perf_data[0].data.length > 0 ?
                            <ReportChart data={this.state.disb_perf_data ? this.state.disb_perf_data : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true}
                              legend={false} x_axis_format={false} grid={false} y_axis_tittle={'Seconds'} x_axis_title={'Percentile'} show_bar_label={true} bar_labels={this.state.disb_perf_data_label} dataLabel={true} distributed={true} colors={['#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2']} biz_ops_chart_height={300} />
                            : <p className='text-white text-center' style={{ marginTop: '21%', fontSize: '15px' }}>No Data</p>}    </div>
                      </div>
                    </> :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('disb_perf_report')} > <b>Show</b></button> </div>
                    }

                  </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineFieldTime size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }} >Switch Performance Report</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.switch_perf_report ? <>
                      {this.state.switch_perf_data && this.state.switch_perf_data[0].data.length > 0 ? 
                      <>
                        <div className='total_fa_count_biz_operation' style={{ padding: '5px', width: '190px', zIndex: 1 }}>
                          <div className={`dropdown country_dropDownbg churn_report_date_picker dark_theme_bg full_width p-0 `} >


                            <div className={"dropdown-toggle py-2 dropdown_trig "} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >

                              <div className='d-flex align-items-center justify-content-around'>
                                {this.state.selected_options ?

                                  <div className='col-12 row m-0 d-flex align-items-center p-0'>
                                    <div className='col-5  p-0 d-flex justify-content-center align-items-center'>
                                      <img src={`/img/${this.state.selected_options.from}_logo_color.png`} className='choosen_float_switch_acc_prvdr' />
                                    </div>
                                    <div className='col-2 p-0'>
                                      {this.state.both_switchs ?
                                        <FaArrowsAltH style={{ width: "30px", height: '25px' }} /> : <FaLongArrowAltRight style={{ width: "30px", height: '25px' }} />}

                                    </div>

                                    <div className='col-5 d-flex align-items-center  justify-content-center p-0'>
                                      <img src={`/img/${this.state.selected_options.to}_logo_color.png`} className='choosen_float_switch_acc_prvdr' />
                                    </div>

                                  </div>


                                  :
                                  <p className='text-white m-0'>choose Accounts</p>

                                }
                                <span><i className="fa-solid fa-chevron-down"></i></span>
                              </div>

                            </div>


                            <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1" style={{ width: '10vw', background: "rgb(187 187 187)" }} >
                              <li className="country_selector" onClick={() => { this.get_switch_accounts_options(this.state.switch_acc_options[1], true) }}>
                                <div className='d-flex align-items-center px-0 py-2 m-0 col-12' style={{ width: '13vw' }}>

                                  <div className='col-12 row m-0 d-flex align-items-center p-0'>
                                    <div className='col-5  p-0 d-flex justify-content-center align-items-center'>
                                      <img src={`/img/${this.state.switch_acc_options[1].from}_logo_color.png`} className='float_switch_acc_prvdr' />
                                    </div>
                                    <div className='col-2 p-0'>
                                      <p className='my-0' style={{ fontSize: '20px' }}> <FaArrowsAltH /></p>
                                    </div>

                                    <div className='col-5 d-flex align-items-center  justify-content-center p-0'>
                                      <img src={`/img/${this.state.switch_acc_options[1].to}_logo_color.png`} className='float_switch_acc_prvdr' />
                                    </div>
                                  </div>
                                </div>
                              </li>
                              {this.state.switch_acc_options && this.state.switch_acc_options.map((items, idx) => {
                                return (
                                  <li className="country_selector" onClick={() => { this.get_switch_accounts_options(items) }} >

                                    <div className='d-flex align-items-center px-0 py-2 m-0 col-12' style={{ width: '13vw' }}>

                                      <div className='col-12 row m-0 d-flex align-items-center p-0'>
                                        <div className='col-5  p-0 d-flex justify-content-center align-items-center'>
                                          <img src={`/img/${items.from}_logo_color.png`} className='float_switch_acc_prvdr' />
                                        </div>
                                        <div className='col-2 p-0'>
                                          <p className='my-0' style={{ fontSize: '20px' }}> <FaLongArrowAltRight /></p>
                                        </div>

                                        <div className='col-5 d-flex align-items-center  justify-content-center p-0'>
                                          <img src={`/img/${items.to}_logo_color.png`} className='float_switch_acc_prvdr' />
                                        </div>

                                      </div>


                                    </div>
                                  </li>

                                )
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className={`row d-flex justify-content-between align-items-center ${this.state.history_datas.switch_perf_report ? "" : 'float_account_balance_bgblur'}  `} style={{ padding: '30px' }}>
                          <div className='col-md-12 disbursement_chart'>
                            <ReportChart data={this.state.switch_perf_data ? this.state.switch_perf_data : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} grid={false}
                              x_axis_format={false} x_axis_title={'Percentile'} y_axis_tittle={"Minutes"} legend={false} dataLabel={true} show_bar_label={true} bar_labels={this.state.switch_perf_data_label} distributed={true} colors={['#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2']} biz_ops_chart_height={300} biz_ops_colunm_width={'25%'} />

                          </div>
                        </div>
                      </>
                        : <p className='text-white text-center' style={{ marginTop: '21%', fontSize: '15px' }}>No Data</p>}
                    </> :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('switch_perf_report')} > <b>Show</b></button> </div>
                    }

                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-between row'>

                <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineSchedule size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }}>Float Switch Report (Day Wise)</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>


                    {this.state.history_datas.float_switch_report_day_wise ?
                      <>
                        {this.state.history_datas.float_switch_report_day_wise && this.state.TotalAndOverdueFA.length > 0 && (this.state.sales_report && this.state.sales_report.length > 0) ?
                          <div className={`row d-flex justify-content-between align-items-center disbursement_chart ${this.state.history_datas.rm_metrics ? "" : 'float_account_balance_bgblur'} `} style={{ padding: ' 0px 52px' }}>
                            <div className='col-12'>
                              <ReportChart data={this.state.sales_report ? this.state.sales_report : ''} type="line" xaxistype='category' show_toolbar={false} axisBorder={true}
                                legend={true} y_axis_tittle={"Switch Count"} x_axis_format={true} grid={false} biz_ops_chart_height={this.state.sales_report ? 450:300} />
                            </div>
                          </div>
                          :
                          <p className='text-white text-center' style={{ marginTop: '21%', fontSize: '15px' }}>No Data</p>
                        }
                      </>
                      :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('float_switch_report_day_wise')} > <b>Show</b></button> </div>
                    }


                  </div>
                </div>

                <div className='col-md-12 col-lg-6'>
                <div className='business_operations_border biz_operation_fas_pending' style={{height:'400px'}}>
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <AiOutlineBarChart size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}}>SIP Down Time chart</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    
                    
                    {this.state.inbound_voice_chart_data.length>0 ? 
                    <>
                     {this.state.x_axis_inbound_voice_data && this.state.x_axis_inbound_voice_data.length>0 ?
                    <div className={`row d-flex justify-content-between align-items-center disbursement_chart`} style={{padding:' 0px 52px'}}>
                        <div className='col-12'>
                        <ReportChart data={this.state.inbound_voice_chart_data ? this.state.inbound_voice_chart_data :[]} type="bar"  show_toolbar={false} axisBorder={true} 
                      legend={true} y_axis_tittle={"Time"}  x_axis_format={false} grid={false} dataLabel={true} biz_ops_chart_height={this.state.inbound_voice_chart_data ? 450:300} xaxistype='category' categories={this.state.x_axis_inbound_voice_data} distributed={true} />
                    </div>
                    </div>
                    :
                    <p className='text-white text-center' style={{marginTop:'21%',fontSize:'15px'}}>No Data</p>
                  }
                    </>
                    :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.get_inbound_voice_call_data()} > <b>Show</b></button> </div>
                     }
                    
                    </div>
                   </div>


              </div>

              <div className='d-flex align-items-center justify-content-between row'>
              <div className='col-md-12 col-lg-6' style={{height:'710px'}}>
                <div className='business_operations_border biz_operation_fas_pending' >
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <MdAlarm size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}} >Disbursement Time</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    {this.state.history_datas.apply_to_disb_time_chart ? "" :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.lazy_loading_handler('apply_to_disb_time_chart')} > <b>Show</b></button> </div>
                     }
                    <div className={`row align-items-center   ${this.state.history_datas.apply_to_disb_time_chart ? "" : 'float_account_balance_bgblur'} `} style={{padding:' 23px 52px'}}>
                       
                    <div className='col-md-12 disbursement_chart' style={{minHeight:315}}>
                    {this.props.data.disbursement_spin_loader &&
                       <div class="d-flex justify-content-center float_acc_balance_view_bttn" >
                       <div class="spinner-border text-light" role="status">
                         <span class="sr-only">Loading...</span>
                       </div>
                       </div>
                         }
                    <ReportChart data={this.state.Disbursement_time_x_axis?this.state.Disbursement_time_x_axis:''} type="bar" xaxistype ='category' show_toolbar={false} axisBorder={true} grid={false} 
                       x_axis_format={false} total_fas={this.state.Disbursement_total?this.state.Disbursement_total:''}  y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.Disbursement_time_Y_axis?this.state.Disbursement_time_Y_axis:''}   dataLabel={true} distributed={true} colors={['#ff9f9e']} biz_ops_chart_height={300} title={"Application to Disbursement"}/>
                  
                      </div> 
                      
                    <div className=' row d-flex justify-content-around' style={{marginTop:'25px'}}>
                    <div className='col-md-6' >
                            <div className='business_operations_border' style={{padding:'10px'}} > 
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="rm_manual_approval" name="rm_manual_approval" value="rm_manual_approval" onChange={(e)=>this.disbursement_time_charts("manual_approval",this.state.disbursement_checkbox.manual_approval)} checked={this.state.disbursement_checkbox.manual_approval}  />
                            <label className='no-margin' for="rm_manual_approval" style={{paddingLeft:'10px'}}> With RM Manual Approval</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="rm_pre_approval" name="rm_pre_approval" value="rm_pre_approval"  onChange={()=>this.disbursement_time_charts("auto_approval",this.state.disbursement_checkbox.auto_approval)}  checked={this.state.disbursement_checkbox.auto_approval}/>
                            <label className='no-margin' for="rm_pre_approval" style={{paddingLeft:'10px'}}> With RM Pre-Approval</label><br/>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-6' >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Manual_Capture" name="With_Manual_Capture" value="With_Manual_Capture"  onChange={()=>this.disbursement_time_charts("manual_capture",this.state.disbursement_checkbox.manual_capture)}  checked={this.state.disbursement_checkbox.manual_capture} />
                            <label className='no-margin' for="With_Manual_Capture" style={{paddingLeft:'10px'}}> With Manual Capture</label><br/>
                            </div>
                            <div className='d-flex align-items-center'> 
 
                            <input type="checkbox" id="With_Auto_Capture" name="With_Auto_Capture" value="With_Auto_Capture"  onChange={()=>this.disbursement_time_charts("auto_capture",this.state.disbursement_checkbox.auto_capture)}  checked={this.state.disbursement_checkbox.auto_capture}/>
                            <label className='no-margin' for="With_Auto_Capture" style={{paddingLeft:'10px'}}> With Auto Capture</label><br/>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-6' >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Multiple_Attempts" name="With_Multiple_Attempts" value="With_Multiple_Attempts"  onChange={()=>this.disbursement_time_charts("multi_attempt",this.state.disbursement_checkbox.multi_attempt)}  checked={this.state.disbursement_checkbox.multi_attempt} />
                            <label className='no-margin' for="With_Multiple_Attempts" style={{paddingLeft:'10px'}}> With Multiple Attempts</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Single_Attempt" name="With_Single_Attempt" value="With_Single_Attempt"  onChange={()=>this.disbursement_time_charts("single_attempt",this.state.disbursement_checkbox.single_attempt)}   checked={this.state.disbursement_checkbox.single_attempt}/>
                            <label className='no-margin' for="With_Single_Attempt" style={{paddingLeft:'10px'}}> With Single Attemptl</label><br/>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-6' >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_OTP_Confirmation" name="With_OTP_Confirmation" value="With_OTP_Confirmation"  onChange={()=>this.disbursement_time_charts("otp",this.state.disbursement_checkbox.otp)}  checked={this.state.disbursement_checkbox.otp}  />
                            <label className='no-margin' for="With_OTP_Confirmation" style={{paddingLeft:'10px'}}> With OTP Confirmation</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="Without_OTP_Confirmation" name="Without_OTP_Confirmation" value="Without_OTP_Confirmation"  onChange={()=>this.disbursement_time_charts("not_otp",this.state.disbursement_checkbox.not_otp)}   checked={this.state.disbursement_checkbox.not_otp}/>
                            <label className='no-margin' for="Without_OTP_Confirmation" style={{paddingLeft:'10px'}}> Without OTP Confirmation</label><br/>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-6' >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Auto_Disbursal" name="With_Auto_Disbursal" value="With_Auto_Disbursal"  onChange={()=>this.disbursement_time_charts("auto_disbursal",this.state.disbursement_checkbox.auto_disbursal)}   checked={this.state.disbursement_checkbox.auto_disbursal}/>
                            <label className='no-margin' for="With_Auto_Disbursal" style={{paddingLeft:'10px'}}>With Auto Disbursal</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Manual_Disbursal" name="With_Manual_Disbursal" value="With_Manual_Disbursal"  onChange={()=>this.disbursement_time_charts("manual_disbursal",this.state.disbursement_checkbox.manual_disbursal)}   checked={this.state.disbursement_checkbox.manual_disbursal}/>
                            <label className='no-margin' for="With_Manual_Disbursal" style={{paddingLeft:'10px'}}> With Manual Disbursal</label><br/>
                            </div>
                            </div>
                            <button type="button" class={`btn btn-outline-info btn-sm disb_time_all_btn `}  onClick={()=>this.disb_time_all_handler()} style={{ background:`${this.state.disb_time_all_bttn ? "#4291da" :''}`}}> <b style={{color:'white'}} disabled={this.state.disb_time_all_bttn ? false : true}> All</b></button>
                        </div>

                      
                    </div>
                    </div>
        
                </div>
              </div>

              <div className='col-md-12 col-lg-6' style={{height:'710px'}}>
                <div className='business_operations_border biz_operation_fas_pending ' >
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <GiBackwardTime size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}} >Repayment Time</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    {this.state.history_datas.repay_to_settle_time_chart ? "" :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.lazy_loading_handler('repay_to_settle_time_chart')} > <b>Show</b></button> </div>
                     }
                    <div className={`row align-items-center  disbursement_chart  ${this.state.history_datas.repay_to_settle_time_chart ? "" : 'float_account_balance_bgblur'} `} style={{margin:'30px 20px'}}>

                        <div className='col-md-12 disbursement_chart' style={{minHeight:465}}>
                        {this.props.data.repayment_spin_loader &&
                       <div class="d-flex justify-content-center float_acc_balance_view_bttn" >
                       <div class="spinner-border text-light" role="status">
                         <span class="sr-only">Loading...</span>
                       </div>
                       </div>
                         }
                        <ReportChart data={this.state.repayment_time_x_axis?this.state.repayment_time_x_axis:''} type="bar" xaxistype ='category' show_toolbar={false} axisBorder={true} grid={false} 
                       x_axis_format={false} total_fas={this.state.repayment_total?this.state.repayment_total:''}  y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.repayment_time_y_axis?this.state.repayment_time_y_axis:''}   dataLabel={true} distributed={true} colors={['#8FE3CF']} biz_ops_chart_height={450} title={"Repayment to Settlement"}/>
                  
                     
                      </div>
                    <div className='row d-flex justify-content-center' style={{margin:'17px 0px 0px 31%'}}>
                    
                        <div className='' >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="repayment_Manual_Capture" name="repayment_Manual_Capture" value="repayment_Manual_Capture"  onChange={()=>this.repayment_time_charts("manual_capture",this.state.repayment_time.manual_capture)}  checked={this.state.repayment_time.manual_capture} />
                            <label className='no-margin' for="repayment_Manual_Capture" style={{paddingLeft:'10px'}}> With Manual Capture</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
            
                            <input type="checkbox" id="repayment_auto_Capture" name="repayment_auto_Capture" value="repayment_auto_Capture"  onChange={()=>this.repayment_time_charts("auto_capture",this.state.repayment_time.auto_capture)}  checked={this.state.repayment_time.auto_capture} />
                            <label className='no-margin' for="repayment_auto_Capture" style={{paddingLeft:'10px'}}> With Auto Capture</label><br/>
                            </div>
                            </div>
                        </div>
                      
                      
                    </div>
                    </div>
        
                </div>
              </div>
            </div> */}
            

            
            
            </>
          }
        </div>

      </div>
    );
  }
};

export default Biz_opertations_yesterday_results;