import { Box } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { lazy, LazyExoticComponent, Suspense } from "react";
import { FormDefaultProps } from "..";
import { getReport } from "../../../actions/report_actions";
import {
  common_req,
  resolvePromiseWithDelay,
} from "../../../helpers/common_helper";
import DataTableLoadingOrErrorUI from "../../CommonComponent/table/dataTableLoadingOrErrorUI";
import AdditonalFieldUI from "./additonalFieldUI";
import { TableUIProps } from "./tableUI";

// lazy
const TableUI: LazyExoticComponent<React.ComponentType<TableUIProps>> = lazy(
  () => resolvePromiseWithDelay(import("./tableUI"))
);

type AuditorCoverageReportResProps = {
  data: {
    data: RmsData[];
    summary_data: Record<string, string>;
    total: number;
  };
};

type RmsData = {
  "Flagged Customers": number;
  "Flagged to Visit Ratio": string;
  "RM Name": string;
  "Total Customers": number;
  "Visited Customers": number;
};

const FieldsAndTableUI = ({
  sort,
  defaultSort,
  page,
  queryFilters,
  setpage,
  setsort,
}: {
  sort: GridSortModel;
  page: GridPaginationModel;
  defaultSort: GridSortModel;
  queryFilters: FormDefaultProps;
  setpage: Function;
  setsort: Function;
}) => {
  // cb
  const getSort = (getFor: "field" | "sort") =>
    sort.length > 0
      ? sort[0][getFor]
      : getFor === "field"
      ? defaultSort[0].field
      : defaultSort[0].sort;

  const reqObj = common_req({} as any);
  // query
  const { data, isLoading, isError } = useQuery<AuditorCoverageReportResProps>({
    queryKey: [
      "audit_coverage_report",
      { sort: [getSort("field"), getSort("sort")] },
      { page: [page.page, page.pageSize] },
      {
        filter: {
          start: queryFilters.startDate.format("YYYY-MM-DD"),
          auditor: queryFilters.auditor.id,
          end: queryFilters.endDate.format("YYYY-MM-DD"),
          rm: queryFilters.rm.id,
        },
      },
    ],
    queryFn: () =>
      getReport({
        ...reqObj,
        report_type: "auditor_performance",
        filters: {
          tab_name: "audit_coverage_report", // dashboard tab name with respect to active tab
          start_date: queryFilters.startDate.format("YYYY-MM-DD"),
          auditor_id: queryFilters.auditor.id,
          end_date: queryFilters.endDate.format("YYYY-MM-DD"),
          rm_id: queryFilters.rm.id,
        },
        sort: {
          field: getSort("field"),
          order: getSort("sort"),
        },
        pagination: {
          skip: page.page * page.pageSize,
          per_page: page.pageSize,
        },
      }),
  });

  // rows and column data
  const rows = data?.data?.data?.map((d) => ({
    ...d,
    id: Math.random(),
  })) as GridRowsProp;

  const columns =
    data?.data && Array.isArray(data?.data?.data) && data?.data?.data.length > 0
      ? Object.keys(data?.data?.data[0]).map((o, oIdx) => ({
          field: o,
          headerName: o,
          flex: 1,
          align: oIdx === 0 ? "left" : "center",
          headerAlign: oIdx === 0 ? "left" : "center",
        }))
      : undefined;

  return (
    <>
      <AdditonalFieldUI
        isLoading={isLoading}
        isError={isError}
        data={data?.data?.summary_data}
      />
      <Suspense
        fallback={
          <Box p={2}>
            <DataTableLoadingOrErrorUI
              isError={false}
              errorText=""
              loadingRows={10}
              loadingColumns={5}
            />
          </Box>
        }
      >
        <TableUI
          rows={rows as unknown as GridValidRowModel[]}
          columns={columns as unknown as GridColDef<GridValidRowModel>[]}
          page={page}
          sort={sort}
          rowCount={data?.data?.total}
          setpage={setpage}
          setsort={setsort}
          isError={isError}
          isLoading={isLoading}
        />
      </Suspense>
    </>
  );
};

export default FieldsAndTableUI;
