import React from "react";
import { Controller } from "react-hook-form";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function CustomInput({name,control,rules = {},label = '',type, error, helperText,icon,...others}) {
  const [showPassword, setShowPassword] = React.useState(false);
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <Controller
      name={name}
      control={control} 
      rules={rules} 
      render={({ field }) => (
        <TextField
          {...field}
          {...others}
          label={label}
          fullWidth
          type={showPassword && type === "password" ? "text" : type}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {type === "password" ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility sx={{ fontSize: 20 }}/> : <VisibilityOff sx={{ fontSize: 20 }}/>}
                  </IconButton>
                ) : (
                  <>
                    {icon && <IconButton>{icon}</IconButton>}
                  </>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

export default CustomInput;
