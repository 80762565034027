import { Box } from "@mui/material";
import { ReactNode } from "react";

/**
 *
 * @param param0 ReactNode
 * @returns returns the react node with spacing, border, bg and border radius
 */
const BorderContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        border: "solid 1px #98959a", // need to take from theme
        borderRadius: "12px",
        p: 2,
        flexGrow: 1,
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.custom_colors.primary_500,
      }}
    >
      {children}
    </Box>
  );
};

export default BorderContainer;
