import FlowComponent from "../common/component/core/FlowComponent";
import React from "react";
import CommonReactTable from '../../helpers/commonReact_table';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Card, Modal } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import {ListProofs,cancelproofs, late_auth_req_pending, late_auth_req_action, validateTxnId, create_capture_payment} from '../../actions/loan_actions';
import { Homeacc_prvdrLogoselector, format_date_tbl, get_dir_path } from "../../helpers/common_helper";
import DisplayImage from "../common/component/DisplayImage";
import { Link, } from "react-router-dom";
import { get } from "../../helpers/storage_helper";
import { listAccountProvider } from "../../actions/account_provider_actions";
import { BasicReactTable } from "../../helpers/react_table";
import { getRefAccounts } from "../../actions/account_txn_actions";
import DateTimePicker from 'react-datetime';
import moment from 'moment';
import { addAccStmt} from "../../actions/lender_actions";
import withRouter from "../../Router/Withrouter";
import LastNFAs from "../loan_appl/component/LastNFAs";
import { lbl_amt } from "../../helpers/product_helper";
import { CircularProgress } from "@mui/material";



const modal_data_fields = [{id:"account_id", label:"Accounts", type:"dropdown"},
                            {id:"stmt_txn_date", label:"Stmt Txn Date", type:"date"},
                            {id:"reason_for_add_txn", label:"Reason for Add Txn", type:"dropdown"},
                            {id:"stmt_txn_id", label:"Stmt Txn ID", type:"text"}, 
                            {id:"amount", label:"Amount", type:'text', is_numeric : true}, 
                            {id:"descr", label:"Description", type:'text'}];    
const fa_auth_cols =  
[{id:"cust_id", label:"Customer ID"},
{id:"cust_name", label:"Customer Name"},
{id:"rm_name", label:"RM Name"},
{id:"rm_mobile_num", label:"RM Number"}, 
{id:"last_fa_amount", label:"Last FA Amount" ,type:"amt"}, 
{id:"tot_late_payment", label:" Delayed Repayment",},
{id:"repayment_rate", label:" Repayment Rate" ,type:"perc"},
{id:"late_authorized_count", label:" Late Authorization"},
{id:"prev_fa_details", label:" Previous FA Details"},

]; 

const fa_auth_modal_fields =  
[{id:"total_fa", label:"Total FA Applications"},
{id:"repayment_rate", label:"Repayment Rate"},
{id:"tot_late_payment", label:"Total Late FA Payments"},
{id:"first_repay_date", label:"First FA Repayment Date"}, 
{id:"tot_ontime_payment", label:"Total On-time Repayments"}, 
{id:"last_repay_date", label:" Last FA Repayment Date"},
{id:"cs_reason", label:" CS Reason "},
{id:"rm_reason", label:"RM Reason "},


]; 


class ListPaymentProof extends FlowComponent{
    state={
        rowdata : [],
        columndata : [],
		lists : [],
        show_table:false,
        all_count:0,				
		each_count:[],
		copy:[],
		all_Acc_pvdrs:[],
        modal_data:{},
        reject_payment_proof : false,
        rejected_proof_data : {},
        acc_stmts:{},
        stmt_exists:false,
        stmt_verified:false,
        verify_message : "",
        verification_loader : false,
        existing_stmt_data:{},
        is_part_payment:false,
        show_capture:false,
        capture_data : {stmt_txn_id : 'Statement Transaction ID', account_num : 'Credit Account', cr_amt : 'Repayment Amount', stmt_txn_date : "Payment Date"},
        capturing : false,
        submiting:false
    }

    componentWillMount(){
      this.get_data()
       
    }

    get_data = () => {
        if(this.props.task_type =="fa_approval"){
            this.late_payment_list()
            this.setState({columndata:this.late_auth_columns()})
          }else{
              this.drawTable()
              this.getAccount()
          }
    }

    late_payment_list(){
        late_auth_req_pending(this.req())
        .then((response)=>{
            if(!response){return}
            if(response&& response.status=="success"){

                this.setState({copy:response.data})
            }
        })
    }

    late_auth_columns(){
            const columns = [
                fa_auth_cols.map((item, idx) => {
                return {
                            Header: <p className='text-center m-0'>{item.label}</p>,
                            id: item.id,
                            accessor: item.id,
                            Cell: (cell) => {
                                {
                                    return <div className="d-flex justify-content-center">
                                        {item.id == "prev_fa_details" ? 
                                         <p className="m-0 text_decoration text-center  pointer" style={{fontSize:"13px"}} onClick={()=>{this.setState({view_auth_details:cell.row.original,show_fa_modal:true})}}> <b>View</b></p>
                                        :
                                        <>
                                        {cell.row.original[item.id] ? <p className="m-0 text-white">{item.type =="amt" ? lbl_amt(cell.row.original[item.id],get('market').country_code): item.type=="type" ?(cell.row.original[item.id]).toFixed(2) : cell.row.original[item.id] }</p>:"-"}
                                        </>
                                        }
                                        </div>
                                }
                            }
    
    
                };
                }),
            ];
    
                return columns[0];
       
    }

    handleToggle = (e) => {

        this.setState(state => state.show_table = !this.state.show_table);
    }

    validate_fields(acc_stmts) {
        return acc_stmts.account_id && acc_stmts.stmt_txn_id && acc_stmts.amount && acc_stmts.amount > 0 &&  this.state.acc_stmts.amount.charAt(0) != '0' &&
                acc_stmts.stmt_txn_date && acc_stmts.descr && acc_stmts.reason_for_add_txn && this.state.mode 
     }

    handleApprovalModal = (e, event) =>{
        this.setState({approval_modal: true, modal_data : event, stmt_exists:false, stmt_verified : false, existing_stmt_data : {}, verify_message : "", is_part_payment : true, show_capture:false})
        let proof_data = {}
        if(event.proof_extracted_data){
            proof_data = event.proof_extracted_data
            if(proof_data.stmt_txn_date){
                proof_data.stmt_txn_date = moment(proof_data.stmt_txn_date, "YYYY-MM-DD hh:mm:ss")
                this.setState({mode:true, txn_date:moment(proof_data.stmt_txn_date).format("YYYY-MM-DD hh:mm A")})
            }
        }
        proof_data.loan_doc_id = event.loan_doc_id
        proof_data.stmt_txn_type = 'credit'
        this.setState({acc_stmts:proof_data})
        if(proof_data.stmt_txn_id){
            this.check_stmt_exists(proof_data.stmt_txn_id, event.loan_doc_id)
        }
    }

    getAccount = () => {
        var account = {"country_code": this.country_code,'status' : 'enabled', to_recon : true};    
      getRefAccounts(this.req({account},true))
        .then((response) => {
          if(!response){return };
          if(response.status === "success"){
            if(response.data){
                this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
            }
          }else{ 
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
      );
    }

    back_to_the_page = (value) => {
        this.setState({show_capture:value})
    }

    handlesubmit = () => {
       
        var is_valid = this.validate_fields(this.state.acc_stmts)
        if(is_valid){
        
            let id = this.state.modal_data.id
            let loan_doc_id=this.state.modal_data.loan_doc_id
            let photo_payment_evidence = this.state.modal_data.payment_proof
            let acc_stmts = this.state.acc_stmts

            acc_stmts.photo_statement_proof = photo_payment_evidence
            acc_stmts.stmt_txn_date = moment(this.state.acc_stmts.stmt_txn_date).format("YYYY-MM-DD HH:mm:ss")
            acc_stmts.by_evidence = {id, loan_doc_id, photo_payment_evidence}
            this.setState({submiting:true})
            addAccStmt(this.req({acc_stmts: acc_stmts}))
                .then((response) => {
                        this.setState({submiting:false})
                        this.resp(response)
                        if(!response){return };
                        if(response.status === "success"){
                        alert(response.message);  
                        this.handleApprovalModalClose()
                        this.get_data()
                    }
                })
        }
        else if(this.state.acc_stmts.amount <= 0){
            alert('Amount should be greater than 0')
        }
        else if(this.state.acc_stmts.amount.charAt(0) == '0'){
            alert("The amount should not start with 0.")
        }
        else{
            alert("Please enter required fields.")
        }
    }

    capture_payment = () => {
        this.setState({capturing:true})
        let data = this.state.existing_stmt_data
        let request = {acc_stmt_txn_id : data.stmt_txn_id, 
                        txn_date:data.stmt_txn_date, 
                        to_ac_id : data.account_id, 
                        is_part_payment:this.state.is_part_payment, 
                        is_skip_txn_id_chk : false, 
                        mode:'capture', 
                        amount:data.amount,
                        loan_doc_id:this.state.modal_data?.loan_doc_id}
        create_capture_payment (this.req(request,true))
        .then((response)=>{
            this.setState({capturing:false})
           this.resp(response)
           if(!response){return null;}
           if(response.status === "success"){
                alert('Payment captured successfully')
                this.handleApprovalModalClose()
                this.get_data()

           }else{
             alert(response.message)
           }
       })
    }

    check_stmt_exists = (stmt_txn_id, loan_doc_id) => {

        var request ={
            'txn_id' : stmt_txn_id,
            'type' : "payment_capture",
            'loan_doc_id' : loan_doc_id,
            'mode' : 'credit'
         }

        if(stmt_txn_id){
            let message = ''
            let exist_stmt = false
            let extg_stmt_data = {}
            let show_capture_page = false
            this.setState({verification_loader : true})
            validateTxnId(this.req(request))
            .then((response) => {
                this.resp(response)
                this.setState({verification_loader : false})
                if(!response){return };
                if(response.status === "success"){
                    let data = response.data
                    if(data.message){
                        if(data.message.toLowerCase().includes("no such transaction exist")){
                            message = 'No such transaction exist'
                        }else{
                            message = data.message
                            exist_stmt = true
                        }
                    }else if(data.stmt_txn_id){
                        exist_stmt = true
                        extg_stmt_data = data
                        message = "Transaction exists"
                        show_capture_page = true
                        if(data.to_capture && data.to_capture.new_status != 'settled'){
                            this.setState({is_part_payment : true})
                        }
                    }
                    this.setState({verify_message:message, stmt_exists:exist_stmt, stmt_verified : true, existing_stmt_data : extg_stmt_data, show_capture : show_capture_page})
                }
            })
        }else{
            alert('Please enter the statement transaction ID')
        }
        
    }

    handle_account_stmts = (event, id = null, data = null) => {
        if(data && data.is_numeric){
                let value = event.target.value
                if (value === '' || !isNaN(value)) {
                  this.setState({ acc_stmts: { ...this.state.acc_stmts, [event.target.id]: value } });
                  return
                }
                return
        }
        if(id != null){
            this.setState({acc_stmts:{...this.state.acc_stmts, [id]:event}})
        }else{
            if(event.target.id == 'stmt_txn_id' && event.target.value){
                this.setState({stmt_verified: false})
            }
            this.setState({acc_stmts:{...this.state.acc_stmts, [event.target.id]:event.target.value}})
        }
    }

    handleModeChange = (event) => {
        if(event == 'time'){
          this.setState({mode:true})
        }
    }

    handleApprovalModalClose = () =>{
        this.setState({approval_modal: false,show_fa_modal:false})
    }

    handleTxn=()=>{
        let id = this.state.modal_data.id
        let loan_doc_id=this.state.modal_data.loan_doc_id
        let photo_payment_evidence = this.state.modal_data.payment_proof
        // this.props.history.push({
        //     pathname:'/statement/add',
        //     state:{id,loan_doc_id,photo_payment_evidence}
        // })
        this.props.navigate('/statement/add', {state:{id,loan_doc_id,photo_payment_evidence}})
    }
    cancel_proof=(data)=>{
     var req={id:data.id, reason : this.state.remarks}
      
     if(this.state.remarks.length < 5){
        alert('Remark must contain at least 5 characters')
        return;
      }

      cancelproofs(req)		
      .then((response)=>{
          if(!response){return };
          if(response.status === "success"){
            this.handleApprovalModalClose()
            this.handleRejectReasonModal()
            this.drawTable()
     
          }
      });

    }

    handleRejectReasonModal= () =>{
        this.setState({reject_payment_proof : !this.state.reject_payment_proof})
    }

    handleModalChange = (event) =>{
        this.setState({remarks:event.target.value})
	}
    

    drawTable () {
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})		
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs) 
			}
		});
		this.list_payment_proof()
	}
    list_payment_proof=()=>{
        ListProofs(this.req())
        .then((response) => {
            if(response){
                this.setState({lists : response.data, columndata : this.columndatas(), all_count : response.data.length}, ()=>{this.filter_acc('All'); this.filter_count()})									
            }
        })

    }
        filter_count=()=>{
            var count=[]
            const filter_each=(a)=>{
            let each=this.state.lists.filter((item)=>item.acc_prvdr_code==a)
                count.push(each.length)}
            this.state.all_Acc_pvdrs.map((item)=>filter_each(item))
            this.setState({each_count:[...count]})
        }
        filter_acc=(acc)=>{
            this.setState({selected_btn:acc})
            this.setState({copy:[]})
            if(acc=='All'){
                this.setState({copy:this.state.lists})
            }
            else{
            let easy =this.state.lists.filter((item,index)=>item.acc_prvdr_code==acc)
            this.setState({copy:easy})}
        }
    

    columndatas(){
        let column
        column = [
            {
                Header : "Acc Prvdr",
                id: 'acc_prvdr_code',
                minWidth:100,
                accessor: row=><Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/>
            },
            {
                Header : "Cust ID",
                id: 'cust_id',
                minWidth:100,
                accessor: row=><Link style={{color: "#17a2b8 !important"}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link>
            },
            {
                Header : "Acc Number",
                id: 'acc_number',
                minWidth:100,
                accessor: row=>row.acc_number
            },
            {
                Header : "Paid Date",
                id: 'paid_date',
                minWidth:100,
                accessor: row=>row.uploaded_at
            },
            {
                Header : "Biz Name",
                id: 'biz_name',
                minWidth:100,
                accessor: row=>row.biz_name
            },
            {
                Header : "OS Amount",
                id: 'current_os_amount',
                minWidth:100,
                accessor: row=>row.current_os_amount
            },
            {
                Header : "Loan Doc ID",
                id: 'loan_doc_id',
                minWidth:100,
                accessor: row=><Link to={"/fa/view/"+row.loan_doc_id} target="_blank" className="on_going">{row.loan_doc_id}</Link>
            },
            {
                Header : "Paid Principal",
                id: 'paid_principal',
                minWidth:100,
                accessor: row =>{ return row.paid_principal==null ? "0.00" : row.paid_principal}
            },
            {
                Header : "Paid Fee",
                id: 'paid_fee',
                minWidth:100,
                accessor: row=>{return row.paid_fee==null ? "0.00" : row.paid_fee}
            },
            {
                Header : "Payment Proof",
                id: 'evidence_path',
                minWidth:100,
                Cell: cell => (<Button variant="primary" onClick={e => this.handleApprovalModal(e ,cell.row.original)}>view</Button> )
            }
        ]
        return column
    }

    auth_fa_action(action){
        var req ={loan_request:{action:action, task_id : this.state.view_auth_details.task_id, loan_appl_doc_id : this.state.view_auth_details.loan_appl_doc_id, country_code:this.country_code, cust_id:this.state.view_auth_details?.cust_id, credit_score:this.state.view_auth_details?.credit_score}}
        late_auth_req_action(this.req(req))
        .then((response)=>{
            this.resp(response)
            if(!response){return}
            if(response&& response.status=="success"){
                alert(response.message)
                this.setState({show_fa_modal:false})
                this.late_payment_list()
            }
        })
    }
    render(){
        return(
            <>
            <Accordion c>
                <Card>		
                    <div className={`card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between ${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
                        <div className="d-flex  align-items-center">
                        <h4 className="card-title text-white tableHead_font title-bottom "> { this.props.task_type !="fa_approval" ? "Pending Payment Capture(by evidence)" :"Late Payment - FA Approval "}</h4>
                        { this.props.task_type =="fa_approval"  &&  this.state.copy && this.state.copy.length>0 &&
                        <span className="ml-3 mt-2"> <img src='/img/late_fa_approval.gif' style={{width:"20px",height:"20px"}} alt=''/></span>
                        }
                        </div>
                       {this.props.task_type !="fa_approval"  && 
                        <>
                        {this.state.lists.length > 0 &&<>
								<div className='countREL card-title' onClick={()=>this.filter_acc('All')}>
									<button className={'filterBtns allBtn_size'}  >ALL</button>
									<div className='countABS' >
										<label>{this.state.all_count}</label>
									</div>
								</div>						
								{this.state.all_Acc_pvdrs.map((each,idx)=>									
									<div key={idx} className='countREL  card-title'>																					
											<>
												<div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.filter_acc(each)}>													  
													<img className={`img-fluid ${this.state.selected_btn==each? 'low_opacity':''}`}src={`/img/${each}_logo_color.png`}></img> 																	
												</div>
												<div className='countABS' >													
													<label>{this.state.each_count[idx]}</label>																																
												</div>
											</>										
									</div>																		
								)}
						</>
						}
                        </>
                        }
                      

                        <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle()}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>		
                    </div>
                    <div className="card-body no-padding justify-content-center">
                    <Accordion.Collapse eventKey="0">
					    <Card.Body>
                            {/* <CommonReactTable showPag={true} defaultPageSize={20} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} defaultSorted={[{id:"uploaded_at",desc:false}]} column_data={this.state.columndata} resizable={false} />   
                            
                            */}
                        { this.state.copy && this.state.copy.length>0 ?
                            <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndata} pagination={this.state.copy !== undefined && this.state.copy.length > 9 ? true : false}    default_page_size={50} 
                            //  defaultSorted={(this.props.task_type !="fa_approval")?  [{id:"uploaded_at",desc:false}]:true }
                            
                             />  
                             :
                             <p className="mb-2 text-white text-center ">NO DATA</p>
                        }


                        </Card.Body>
					</Accordion.Collapse>
                    </div>
                </Card>
            </Accordion>
            <Modal show={this.state.approval_modal} onHide={this.handleApprovalModalClose}  backdrop="static" size="lg" style={{position: "fixed"}}>
                <Modal.Header closeButton className="modal-close-btn" show={this.state.approval_modal} onHide={this.handleApprovalModalClose}>
                    <Modal.Title> {(Object.keys(this.state.modal_data).length != 0) && <p>Payment Proof : {this.state.modal_data.cust_id}</p>}</Modal.Title>
                </Modal.Header>
                <Modal.Body  style={{minHeight:'500px'}}>
                    <div>
                        {(Object.keys(this.state.modal_data).length != 0) &&
                        <>
                        <div className=' mt-4 row '>
                            <div className='col-md-6 no-padding col-lg-6' >
                                <div className='m-0 d-flex justify-content-center text-light  h-100 w-100' >
                                    <DisplayImage image_path={this.state.modal_data.evidence_path} style={{height:470,width:350}} file_name={this.state.modal_data.payment_proof}/>
                                </div> 
                            </div> 
                            <div className='col-md-6 col-lg-6 '>
                                <div className='m-0 text-light border h-80 w-100'>  
                                    <div className='align-items-center my-4 py-4 mx-2'>

                                        <p className='no-margin text-white col-6 business_operations_text '> Acc Number </p>
                                        <h2 className='text-white  pl-4 audit_kyc_text'> <b> { this.state.modal_data.acc_number}</b></h2>
                                        <br></br>

                                        <p className='no-margin text-white col-6 business_operations_text '>  Loan Doc ID </p>
                                        <h2 className='text-white  pl-4 audit_kyc_text'> <b> {this.state.modal_data.loan_doc_id}</b></h2>
                                        <br></br>

                                        <p className='no-margin text-white col-6'>  Current OS Amount </p>
                                        <h2 className='text-white  pl-4 audit_kyc_text'> <b> { this.state.modal_data.current_os_amount}</b></h2>
                                        <br></br>

                                        <p className='no-margin text-white col-6 business_operations_text '>  Paid Principal </p>
                                        <h2 className='text-white  pl-4 audit_kyc_text'> <b> {this.state.modal_data.paid_principal==null ? "0.00" : this.state.modal_data.paid_principal}</b></h2>
                                        <br></br>

                                        <p className='no-margin text-white col-6 business_operations_text '>  Paid Fee </p>
                                        <h2 className='text-white  pl-4 audit_kyc_text'> <b> {this.state.modal_data.paid_fee == null ? "0.00" : this.state.modal_data.paid_fee}</b></h2>
                                        <br></br>

                                        <p className='no-margin text-white col-6 business_operations_text '>  Penalty </p>
                                        <h2 className='text-white  pl-4 audit_kyc_text'> <b> {this.state.modal_data.penalty==0 ? "0.00" : this.state.modal_data.penalty}</b></h2>

                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="mt-3">
                            {this.state.show_capture ? 
                                <Link onClick={()=>this.back_to_the_page(false)}>Edit Transaction ID</Link>
                            :
                            this.state.stmt_exists && this.state.stmt_verified && this.state.existing_stmt_data?.stmt_txn_date ? 
                                <Link onClick={()=>this.back_to_the_page(true)}>Capture Payment</Link>
                            :
                                <div>
                                    {this.state.verification_loader ? 
                                    <div className="ml-2">
                                    <CircularProgress variant="indeterminate" thickness={4} value={100} size={30} style={{color:"white"}}/>
                                    </div>
                                    : 
                                    this.state.stmt_verified ? 
                                    <p id="socket-text" className="d-inline-block text-white m-0 ">{this.state.verify_message}</p>
                                    : 
                                    <Link onClick={()=>this.check_stmt_exists(this.state.acc_stmts?.stmt_txn_id ?? null, this.state.modal_data?.loan_doc_id ?? null)}>Verify Statement</Link>
                                    }
                                </div>
                            }
                            
                        </div>
                        <div className="mt-3 row m-0 text-light border">
                                    {this.state.show_capture && this.state.existing_stmt_data?.stmt_txn_id ? 
                                    Object.keys(this.state.capture_data).map((key, i) => {
                                        return(
                                            <div className="col-6" key={i}>
                                                <div className="my-3">
                                                    <label>{this.state.capture_data[key]}</label>
                                                    <p>{key == 'cr_amt' ? lbl_amt(this.state.existing_stmt_data[key],this.currency_code) 
                                                        : key == 'stmt_txn_date' ? moment(this.state.existing_stmt_data[key]).format('DD MMM YYYY hh:mm a') 
                                                        : this.state.existing_stmt_data[key]}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    modal_data_fields.map((data, i)=>{
                                        return(
                                            <div className="col-6" key={i}>
                                                <div className="my-3">
                                                    <label><b>{data.label}</b><font className="text-danger">   *  </font> </label>
                                                    <div>
                                                        {data.type == "text" ? 
                                                        <input autoComplete='off' value={this.state.acc_stmts[data.id] ? this.state.acc_stmts[data.id] : ""} className="form-control" type="text" id = {data.id} onChange={(e)=>this.handle_account_stmts(e, null, data)} tabIndex = '-1'  required="required" maxLength={data.id == "amount" ? "12" : ( data.id == "stmt_txn_id" ? "50" : "100")}/>:
                                                        data.type == "date" ? 
                                                        <DateTimePicker showTimeInput dateFormat="YYYY-MM-DD" value={this.state.acc_stmts[data.id] && this.state.mode ? this.state.acc_stmts[data.id] : ""} id = {data.id} onChange={(value) =>{this.setState({txn_date:moment(value).format('YYYY-MM-DD hh:mm A')});this.handle_account_stmts(value, data.id)}} onViewModeChange={(event) =>this.handleModeChange(event)} inputProps={{value:this.state.txn_date && this.state.mode ? this.state.txn_date : ""}}/>:
                                                        data.type == "dropdown"?
                                                        <select id={data.id} className="form-control" type="text" onChange={(e)=>this.handle_account_stmts(e)} required="required">
                                                        {data.id == "account_id" ? this.state.lender_accounts_dd : this.getSelectOptions("skip_txn_id_check")}
                                                        </select>:null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                        </div> 
                        
                        <div className="form-group d-flex reset-margin justify-content-center py-4">
                            {this.state.show_capture ? 
                                <Button variant="primary" type="submit" className={`mr-3 ${this.state.capturing ? 'disable_div' : ""}`} onClick={()=>this.capture_payment()}>Capture</Button>
                            :
                                <Button variant="primary" type="submit" className={`mr-3 ${(!this.state.stmt_exists && this.state.stmt_verified && !this.state.submiting) ? '' : "disable_div"}`} onClick={this.handlesubmit}>Submit</Button>
                            }
                            {/* <Button variant="primary" type="submit" className="mr-3" onClick={this.handleTxn}>Add Txn</Button> */}
                            <Button variant="primary" type="submit" className="mr-3" onClick={()=>this.handleRejectReasonModal()}>Reject</Button>
                        </div>   
                        </>
                        }  
                    </div>
                    
                </Modal.Body>
            </Modal>
            
            {/* Reject Reson Modal starts here */}
            <Modal show={this.state.reject_payment_proof} onHide={this.handleRejectReasonModal}  size="l"  >
						<Modal.Header closeButton className="modal-close-btn" show={this.state.reject_payment_proof} onHide={this.handleRejectReasonModal}>
							<Modal.Title>Reject Reason</Modal.Title>
						</Modal.Header>
						<Modal.Body  className="pt-2">
				
						<div className="row my-0 px-3 justify-content-center align-items-center" >
								<label className="col-sm-4 text-center mt-4"><b>Remarks</b></label>
							<div className="col-sm-8 reset-padding">
								<textarea id = "remarks" type="text" onChange = {this.handleModalChange} className="form-control reset-padding"/>
							</div>
						</div> 
						
						</Modal.Body>
						<Modal.Footer>

						<div >
						<Button variant="primary" disabled={!this.state.remarks} style={{ marginLeft: 10 }} onClick={()=>this.cancel_proof(this.state.modal_data )}>Submit</Button>
						</div>

					</Modal.Footer>
            </Modal> 


            <Modal show={this.state.show_fa_modal} onHide={this.handleApprovalModalClose}  size="xl"  >
						<Modal.Header closeButton className="modal-close-btn" show={this.state.reject_payment_proof} onHide={this.handleApprovalModalClose}>
                            <Modal.Title className='py-3 px-3 modal_title_bg'>Late Payment - FA Approval</Modal.Title>

						</Modal.Header>
						<Modal.Body  >
                        <div className="row mx-0 d-flex align-items-center">
                            {this.state.view_auth_details && fa_auth_modal_fields.map((item,idx)=>{return(
                                <div className={` d-flex align-items-center row mx-0 my-3 ${(item.id =="cs_reason" || item.id =="rm_reason") ? "col-12":"col-6"}`} key={idx}>
                                 <p className={`m-0 text-white sizem ${(item.id =="cs_reason" || item.id =="rm_reason") ? "col-3":"col-6"}`}>{item.label}</p>
                                 <p className={`m-0 text-white sizem ${(item.id =="cs_reason" || item.id =="rm_reason") ? "col-9":"col-6"}`}> <b>{ this.state.view_auth_details[item.id] ? this.state.view_auth_details[item.id]:"-" }</b></p>

                                </div>
                            )})}

                        </div>
                        {this.state.view_auth_details &&
                        <div>
                        {this.state.view_auth_details.recent_loans && this.state.view_auth_details.recent_loans.length>0 &&
                        <div className="my-2 px-4">
                            <p className="m-0 floatAdvanceperiodViewheader sizem px-0"><b>Recent FAs</b></p>
                            <LastNFAs fa_history = {this.state.view_auth_details.recent_loans} last_n_fas ='4' show_loan_doc_id = {true}/>

                        </div>
                        }
                        {  this.state.view_auth_details.late_repayment_loans &&this.state.view_auth_details.late_repayment_loans.length>0 &&
                        <div className="my-2 px-4">
                            <p className="m-0 floatAdvanceperiodViewheader sizem px-0"><b>Late Repayment FAs</b></p>
                            <LastNFAs fa_history = {this.state.view_auth_details.late_repayment_loans} last_n_fas ='4' />

                        </div>
                        }
                       { this.state.view_auth_details.late_authorized_loan && this.state.view_auth_details.late_authorized_loan.length>0 &&
                        <div className="my-2 px-4">
                            <p className="m-0 floatAdvanceperiodViewheader sizem px-0"><b>Late Authorized FAs</b></p>
                            <LastNFAs fa_history = {this.state.view_auth_details.late_authorized_loan} last_n_fas ='4' />

                        </div>
                        }

                        </div>
                        
                        }
					
						</Modal.Body>
						<Modal.Footer>

						<div  className="d-flex justify-content-center col-12">

                        <div className="mx-4">
                        <Button variant="primary"  style={{ marginLeft: 10 }} onClick={()=>this.auth_fa_action("reject")}>Reject</Button>
                        </div>
                        <div>
                        <Button variant="primary" style={{ marginLeft: 10 }} onClick={()=>this.auth_fa_action("approve")}>Approve</Button>
                        </div>
						


						</div>

					</Modal.Footer>
            </Modal> 
            {/* Reject Reson Modal ends here */}
        
            </>
        )
    }
}
export default withRouter(ListPaymentProof) ;