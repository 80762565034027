import React, { forwardRef } from 'react';
import {Card,Button, Accordion, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ManageCSRosters, updateCSstatus, get_cs_namelist} from '../../../actions/call_log_actions';
import {capitalize_first_letter, title_case} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import Toggle from '../../common/component/Toggle';
import { MdOutlineKeyboardArrowDown ,MdOutlineKeyboardArrowUp} from "react-icons/md";
import { Config, get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import { PiClockCountdownBold } from 'react-icons/pi';


class CSRosters_container extends FlowContainer{


    state ={
        columns: [],
        lists: [],
        copy: [],
        resourse : null,
        cs_status : null,   
        // show : true,
        cs_roster : null,
        // editable:false
        edit : true,
        actvie_tab:'cs',
        accordion:{},
        update_value : null,
        columns_field:[
        {label:'Customer Success Name',key:"name"},
        {label:'Mobile Number ',key:"number"},
        {label:'Language', key:'language'},
        {label:'Assigned for',key:"call_direction"},
        {label:'Directories',key:"directory"},
        {label:'Status',key:"status"},
        {label:'Action',key:"action"},
        {label:'Update',key:"update"},

      ],

      disable_assign : false
    

    }

    componentWillMount(){
        if(this.country_code == "UGA"){
            this.get_cs_devices()
        this.setOptionsToStateFromApi("cs_roster", {...this.def_req})
        }

        this.setState({ actvie_tab: get('role_codes')=="customer_success" ?'cs' : get('role_codes')=="rm_support" ?"rms": (get('role_codes')=="verification_officer" || get('role_codes')=="operations_auditor" || get('role_codes') == 'risk_compl_head') ?"auditor":get('role_codes')=="mce_officer" ?"mce": null})
    }

    get_cs_devices = (type=null,addl_label=null) =>{
        ManageCSRosters(this.req({resourse : "cs_devices"}))
        .then((response) => {
            if (!response) { return };
            if (response.status === "success") {
               if(type !="call_direction"){
                this.setState(state => state.columns = this.columndatas(addl_label,null,this.state.actvie_tab=="rms" ? "rms_tab": this.state.actvie_tab == 'auditor' ? 'auditor' :null))

               }
                if (response.data && response.data.records_arr) {
                    
                    this.setState({row_datas: response.data.records_arr, copy: response.data.records_arr ,update_value: null,show_alert:false,isEdit:false,update_id:''})
                    
                }
            }
        })
    }

    handlestatus = (val, row,type=null,addl_label =null) => {
      
       var if_condition =''
        var req={id:row.id,type:addl_label}
        var msg = "Are you sure want to change the status of the caller ?"
        if(type =="edit"){
          
           if_condition = (!this.state.isEdit && this.state.cs_name) ? true :false
        }else{
          req['number'] = row.number
          req['status'] = row.status == 'enabled' ? "disabled" :"enabled" 
         
          var value = this.state.update_value && this.state.update_value[row.id] ? this.state.update_value[row.id] : null

          var addl_cond = value ? 
                          ((addl_label == "webrtc" || addl_label == "rms" || addl_label == 'auditor') ? value.call_direction && (((this.state.actvie_tab == "rms" || this.state.actvie_tab == 'auditor') ? true : value.directory && value.language)) : value.directory && value.language) : 
                          false

           if_condition = (row.status=="enabled") ? true : addl_cond
        }
        if(if_condition){
           if(type=="edit"){
            req['purpose'] = "edit"
            req['cs_id'] = this.state.cs_name
           }else{
            if((row.status!="enabled")){
                req['call_direction'] = this.state.update_value[row.id].call_direction
                req['directory'] = this.state.update_value[row.id].directory
                req['language'] = this.state.update_value[row.id].language
              } 
               //  msg=false
               }
            if(this.state.disable_assign != true && (!msg ? true :window.confirm(msg))){ 
              this.setState({disable_assign:true})
                updateCSstatus(this.req(req))
                .then((response) => {
                    if (!response) { return }
                    if (response.status === "success") {             
                      alert(response.message)
                        this.get_cs_devices(type,addl_label)
                        // window.location.reload();
                    }else{
                        alert(response.message + "\n" + JSON.stringify(response.data));  
                    

                    }
                    this.setState({disable_assign:false})
                })    
            }
        }else{

          
         
          this.setState({show_alert: type=="edit" ?false:true,alert_id:row.id,columns : this.columndatas(addl_label,null,this.state.actvie_tab=="rms" ? "rms_tab": this.state.actvie_tab == 'auditor' ? 'auditor' :null),name_alert:type=="edit" ? true:false,cs_name:''})

        }
      
    
    }

   

    action = (e, row) => {
        

            const cs_id = this.state.cs_rosters.cs_roster
            this.updateCsName(cs_id, row)
        
    }

     editHandler =(e,row,type,label,update_type)=>{
    
     if(this.state.update_id && this.state.update_id != row.id && this.state.cs_name ){ 
      alert("Your choosed Alredy one customer")
     }else{
      if(update_type=="save"){
  
        this.setState({ isEdit: this.state.cs_name ? !this.state.isEdit : this.state.isEdit,update_type, show_name_alert:true },()=>{this.handlestatus(e, row,type,label)})

      }else{
        this.setState({ isEdit:  !this.state.isEdit,update_type, show_name_alert:false  },()=>{this.handlestatus(e, row,type,label)})

      }
    }   
     }
 

   
      togglePrev(key_val, key) {
    const state = { ...this.state.accordion };

    if (state[key]) {
        for (const k in state) {
            state[k] = !this.state.accordion[key] ;
        }
    } else {
        for (const k in state) {
            state[k] = false;
        }
        state[key] = true;
    }

    this.setState({ accordion: state });
}

AssignHandler(e, row,type,label){
  let update_filed = this.state.update_value && this.state.update_value[this.state.update_id] ? this.state.update_value[this.state.update_id] : null
  if(this.state.update_id && this.state.update_id != row.id && update_filed && (update_filed.directory || update_filed .language || update_filed.call_direction)){ 
    alert("Your choosed Alredy one customer")
   }else{
    this.handlestatus(e, row,type,label)
  }
}


        setAssignedFor(val,data,type,label=null){
          if(type == "cs_name"){
            this.setState({[type]:val,update_id:data.id, columns : this.columndatas(label,null,this.state.actvie_tab=="rms" ? "rms_tab": this.state.actvie_tab == 'auditor' ? 'auditor' :null)})
          }else{
            let update_id = this.state.update_id ? this.state.update_id : null
            let update_value = null
  
            if(update_id){
              let value = this.state.update_value && this.state.update_value[update_id] ? this.state.update_value[update_id] : null
              update_value = {[update_id] : value}
              if(update_id != data.id && update_value){
                update_value = null
                this.setState({alert_id:""})
              }
            }
            let existing_val = this.state.update_value && this.state.update_value[data.id] ? this.state.update_value[data.id] : {}
            
            this.setState({update_value : update_value}, ()=>this.setState({update_value : {[data.id] : {...existing_val, [type] : val}},update_id:data.id, columns : this.columndatas(label,null,this.state.actvie_tab=="rms" ? "rms_tab": this.state.actvie_tab == 'auditor' ? 'auditor' :null)}))
          }
         
            
            // this.handlestatus(val,data,type)
        }
        columndatas(label,data_arr=null,type=null ) {

          var fields = data_arr ?data_arr: this.state.columns_field

          if(label == "webrtc" || label == "rms"  || label =="cs" || label == 'auditor'){
               
            if((label =="rms" || this.state.actvie_tab=="rms" || label =="auditor" || this.state.actvie_tab=="auditor"  || type=="auditor") && type ){

              fields = fields.filter(column => (column.key !== "directory" && column.key !== 'language'));
              fields = fields.filter(column => column.key !== "update");
            }

            fields = fields.filter(column => column.key !== "update")
            
          }else if(label == "mobile" ){
            fields = fields.filter(column => column.key !== "call_direction");

          }
         

           
            return fields.map((column, idx) => {
              return {
                Header: <p className='text-center m-0'> { column.label}</p>,
                id: column.key,
                minWidth: column.key == "status" ? 210 : "",
                accessor: (row) => {
                  const key = column.key;
          
                  if (key === "number") {

                    return <p className='m-0 text-center'>{row.number}</p>;
                  }else if(key == "name" ){
                    return (
                      <div className='cs_name_dropdown'>
                        {(this.state.isEdit && this.state.alert_id ==row.id  ) ? (
                          <div className={`status ${(this.state.name_alert && this.state.show_name_alert && !this.state['cs_name'] && this.state.alert_id == row.id )? "cs_rosters" :""}`}> 
                          <select className={` form-control ` } id="reason"   onChange={(e) => this.setAssignedFor(e.target.value, row, "cs_name",label)} type="text" required="required">
                            {this.state.cs_roster_dd}
                          </select>
                        </div>
                        ) : (
                          <p className='m-0 text-center'>{dd_value(row[key])}</p>

                        )}
                      </div>
                    );
                  }
                   else if (key === "status") {
                    return (
                      <p className={`col  m-0 d-flex justify-content-center align-items-center`}>
                        <div className={`text-white py-1 ${row.status === "enabled" ? "enabled_bg px-5" : "disabled_status disable_padding"}`}>
                          {row.status === "enabled" ? "Assigned" : "Unassigned"}
                        </div>
                        {this.state.accordion.accordian_0 ?
                        (row.on_break == 0 ? 
                        <PiClockCountdownBold style={{height:"25px", width:"25px", marginLeft:"10px"}}/>:
                        <OverlayTrigger placement="bottom" overlay={
                          <Tooltip id="tooltip-bottom" >
                          On Break
                          </Tooltip>
                      }
                      >
                          <PiClockCountdownBold style={{height:"25px", width:"25px", marginLeft:"10px", color:"red"}}/>
                      </OverlayTrigger>)
                        : null}
                      </p>
                    );
                  } else if (key === "call_direction" || key === "directory" || key == "language") {
                    return (
                      <>
                        {(row[key] &&row.status == "enabled" ) ? (
                          <p className='m-0 text-center'>{dd_value(row[key],row[key] )}</p>
                        ) : (
                            <div className={`${(row.status == "enabled" )?"disable_div":""} ${(this.state.show_alert && (!this.state.update_value || !this.state.update_value[row.id] || !this.state.update_value[row.id][key]) && this.state.alert_id ==row.id )? "cs_rosters" :""} `}> 
                          <select className="form-control" id="reason"  disabled={(row.status === "enabled") ? true:false} onChange={(e) => this.setAssignedFor(e.target.value, row, key,label)} type="text" required="required" value={this.state.update_value && this.state.update_value[row.id] && this.state.update_value[row.id][key] ? this.state.update_value[row.id][key] : ""}>
                            {key =="language" ? 
                              <>
                                <option value="">choose</option>
                                {Config("toll_fee_languages")[this.country_code].map((val, index)=>(
                                    <option key={index} value={val}>{dd_value(val)}</option>
                                  )
                                )}
                              </>
                             : this.getSelectOptions( key === "call_direction"  ? 'cs_call_direction' :"cs_call_directory")}
                          </select>
                          </div>
                        )}
                      </>
                    );
                  } else if (key =="action") {
                    return (
                      <p className='col m-0 d-flex justify-content-center align-items-center'>
                        <button style={{color:'#ffff', width:'100%'}} type="button" className={`btn py-2 cs_roasters_button ${row.status === "enabled" ? "adjust_padding" : ""} ${this.state.isEdit ? 'disable_div' : ""}`} onClick={e => { this.AssignHandler(e,row,null,label)}} > {row.status === "enabled" ? "UnAssign" : "Assign"}</button>
                      </p>
                    );
                  }else if (key =="update" && this.state.accordion_type!="webrtc"){
                    return (
                      <p className='col m-0 d-flex justify-content-center align-items-center'>
                        <button 
                          type="button" 
                          style={{color:'#ffff', width:'100%'}}
                          className={`btn py-2 cs_roasters_button`} 
                          onClick={(e)=>  this.editHandler(e,row,"edit",label ,this.state.isEdit ?"save":"edit"  )}
                        > {(this.state.isEdit && this.state.alert_id ==row.id )  ? "Save" : "Edit"}  </button>
                      </p>
                    );
                  }

                }
              };
            });
          }
          

    setActiveTab(key){
      
     var columns_field =[...this.state.columns_field]

      if(key!=="cs"){
        columns_field[0].label = "Relationship Manager Support Name";
        this.setState({columns_field,actvie_tab:key,columns:this.columndatas(key,columns_field,"rmso"),update_value:null,accordion:{}})

      }else{
        columns_field[0].label = "Customer Success Name";
        this.setState({columns_field,actvie_tab:key,columns:this.columndatas(key,columns_field),update_value:null,accordion:{}})

      }

    
    }



    render(){
        return(
            
            <>

                <div className='cs_table'>
                    <h5 className='text-white text-center mt-3'>{this.props.title}</h5>
                </div>

                <div className='row mx-5 d-flex justify-content-center '>
                    {this.state.copy && 
                <div className='col-lg-12 col-md-12 col-sm-12 '>
                  {Config('soft_phone_roster_priv')&&
                    <div className=' m-0 col-12 px-0 d-flex justify-content-center'>
                    {Object.entries(this.state.copy).map(([key, value],idx) => {
                      return(
                        <div key={`active_tab_div_${idx}`} className={` col-lg-3 mx-3 cs_soft_phone_rosters pointer } ${((get('role_codes') == Config('soft_phone_roster_priv')[key]) || get('role_codes')=="l2_support" )?"":  "display_none" } ${ (this.state.actvie_tab == key )? 'active_soft_phone_rosters' :"text-white"}`} onClick={()=>this.setActiveTab(key)}>
                            <p className='m-0  py-3 text-center ' style={{fontSize:'26px'}}>{get('app_config').soft_phone_rosters_titles ? get('app_config')['soft_phone_rosters_titles'][key] : dd_value(key)}</p>
                        </div>
                      )
                      
                      })}
                    </div>}


              {this.state.actvie_tab && this.state.copy && this.state.copy[this.state.actvie_tab] &&
                    <>
                   
                
                   {Object.entries(this.state.copy[this.state.actvie_tab]).map(([key, value], idx) => {
                     return (
                            <div id={`key_${idx}`} key={`subTab_${idx}`}>

                                  <div id={`${key}_${idx + 1}`} key={`${key}_${idx + 1}`} className={` mb-4 ${idx==0 ? "mt-5":""} `} style={{ background: '#1f283e' }}>
                               <div className='d-flex p-4  align-items-center justify-content-between  m-0 pointer' onClick={(e) => { this.setState({ columns: this.columndatas(key,null,this.state.actvie_tab=="rms" ? "rms_tab": this.state.actvie_tab == 'auditor' ? 'auditor' :null ) }, () => { this.togglePrev(e, `accordian_${idx}`) }) }}>

                                                <p className='m-0 text-white sizexm'>{get('app_config').soft_phone_rosters_titles ? get('app_config')['soft_phone_rosters_titles'][key] : dd_value(key)}</p>
                                                <p className='m-0 text-white sizexm pointer'>
                                                    {this.state.accordion[`accordian_${idx}`] ?
                                                        <MdOutlineKeyboardArrowUp size={25} className='text-white pointer bottom-arrow-left ml-3' value={this.state.accordion[`accordian_${idx}`] ? this.state.accordion[`accordian_${idx}`] : true}  />
                                                        :
                                                        <MdOutlineKeyboardArrowDown className='pointer ml-3 bottom-arrow-left ' color={"white"} size={25}  /> 
                                                    }
                                                </p>
                                  </div>
                                </div>  
                                  
                                   {this.state.accordion[`accordian_${idx}`] &&
                                    <>
                                      <div className={` cs_roaster_reactTable cust_next_follow_up mt-2 mb-4  w-100 col-lg-12 col-md-12 col-sm-12`} >
                                        <BasicReactTable row_data={value} column_data={this.state.columns} pagination={value.length > 9 ? true : false } FName={"Call log"} export_csv_datas={this.state.logs ? this.state.logs : []}   default_page_size = {10} header_class='table_background_header'/>  

                                                            {/* <CommonReactTable className='border' row_data={value} background_header={true} column_data={this.state.columns} showPag={value.length > 9 ? true : false} resizable={false} minRow={0} default_page_size={10} addBackground={true} /> */}
                                      </div>
                                    </>                                           
                                  }
                            </div>
                        )
                    })}

                    </>
                    }
                </div>}
                </div>

              {this.state.copy && this.state.copy.length>0 &&
                <div className="card-body m-auto" style={{width: '850px'}}>{console.log('this.state.copy', this.state.copy)}

                <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} FName={"Call log"}  pagination={true} default_page_size = {15}  defaultSorted={[{ id: "type", asc: true }]} />  

                    {/* <CommonReactTable showPag={true} defaultPageSize={15} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []}  column_data={this.state.columns} resizable={false} defaultSorted={[{ id: "type", asc: true }]} /> */}
                </div>}
                
            </>

            
        )
    }
}
export default CSRosters_container;