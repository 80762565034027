import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import $ from 'jquery';
import {listPerson} from "../../../actions/person_actions";
import FlowContainer from "../../common/container/core/FlowContainer";
import StatusButton from "../../common/component/core/StatusButton";
import ViewEdit from "../../common/component/ViewEdit";
import RelationshipManager from "../../relationship_manager/component/RelationshipManager";
import {create_column, person_name} from "../../../helpers/common_helper";
import { BasicReactTable } from '../../../helpers/react_table';

class ListUserContainer extends FlowContainer {

  state = {
     userList: null,
     toView: false,
     person_id: null ,
     data_prvdr: {}

  }


    componentWillMount(){


    listPerson(this.req({'associated_with':'FLOW'}))
        .then((response) => {
          if(!response){return };
          this.setState({column_data:this.column_datas(),userList:response.data})
          //     const userList = response.data.map((person,i)=>
          //     <tr key={i}>

          //       <td > {person_name(person)}</td>
          //       <td > {person.whatsapp}</td>
          //       <td > {person.mobile_num}</td>
          //       <td > {person.email_id}</td>
          //       <td > {person.designation}</td>
          //       <StatusButton id={person.id} entity="users" status={person.status}/>
          //       <td><ViewEdit id={person.id} entity="user"  person_id={person.id}/></td>
          //     </tr>
          //                                     );
          // this.setState({userList: userList});
          //        $('#user-list').removeAttr('width').dataTable({
          //           "order" : [],
          //         "columns": [
          //              {"width" : "15%"},
          //              {"width": "15%"},
          //              {"width": "15%"},
          //              {"width": "15%"},
          //              {"width": "10%"},
          //              {"width": "10%"},
          //              {"width": "10%"},
          //              {"width": "10%"}
          //            ],
          //         dom:'Bfrtip',
          //         buttons: [
          //         'csv','excel','print'
          //         ]
          //     });
        });
  }

  on_status_change = ( id, status) => {
    this.setState({
      userList: this.state.userList.map((user) => {
        if (user.id == id) {
          return {
            ...user,
            status
          };
        }
        return user;
      })
    });
    this.setState({column_data:this.column_datas()})
  };


  column_datas(can_edit){
  

    const  column = [
      {header:" Name",id:"person",is_full_name:true},
      {header:"WhatsApp Number",id:"whatsapp"},
      {header:"Mobile Number",id:"mobile_num"},
      {header:"Email ID",id:"email_id"},
      {header:"Designation",id:"designation"},
    ]
      
      let data =[... create_column(column),  
        {  id:'status',
          Header:<div className='text-center font-for-otp-table'>Status</div>,
          accessor:row=>row.value,
          Cell:(cell)=>{
            return <div className=' d-flex justify-content-center text-center font-for-otp-table' id={cell.row.original.id} >
               <StatusButton id={cell.row.original.id} entity="users" status={cell.row.original.status}  head={"1"}/>
            </div>
          },
         },
          {  id:'action',
          Header:<div className='text-center font-for-otp-table'>Action</div>,
          accessor:row=>row.value,
          Cell:(cell)=>{
            return <div className=' d-flex justify-content-center text-center font-for-otp-table' id={cell.row.original.id} >
               <StatusButton id={cell.row.original.id} entity="users" status={cell.row.original.status}  head={"2"} onStatusChange = {this.on_status_change}/>
            </div>
          },
         },
         {    id:'Edit',
              Header:<div className='text-center font-for-otp-table'>Action</div>,
              accessor:row=>row.value,
              
              Cell:(cell)=>{
                 return <div className='text-center font-for-otp-table'><ViewEdit id={cell.row.original.id} entity="user"  person_id={cell.row.original.id}/></div>
              },
  
      }  
      ]
      return data;
  
  
    }

  render(){


    return(

    <div className="container">

      

          <div id='datatable data_prvdr_table ' className={"table-responsive mt-4"}>
            <h5>Person List</h5>
                 {/* <table id ='user-list'  className="table">
                          <thead className={"text-info"}>
                          <tr>
                          <th>Name</th>
                          <th>WhatsApp Number</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                          <th>Designation</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Action</th></tr></thead>
                          <tbody>
                          {this.state.userList}
                          </tbody>
                 </table> */}
                 { (this.state.userList && this.state.userList.length>0 ) &&
                 <BasicReactTable  row_data={this.state.userList} column_data={this.state.column_data} default_page_size = {10} pagination={this.state.userList.length > 9 ? true : false} csv_file={true}  global_search={true} export_csv_datas={this.state.userList}/> 
                 }
            </div>
       

        </div>
    );
}
}
export default ListUserContainer;
