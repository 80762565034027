import { ReactNode, useReducer } from "react";
import {
  HmeAlrtCtx,
  AlertState,
  DispatchProps,
  TRIGGER_MODAL,
} from "./hmeAlrtCtx";

function reducer(state: AlertState, action: DispatchProps) {
  const { type, payload } = action;
  switch (type) {
    case TRIGGER_MODAL:
      return {
        ...state,
        showModal: payload.show,
      };

    default:
      return state;
  }
}

const initialState: AlertState = {
  showModal: false,
};

const HmeAlrtCtxProvider = ({ children }: { children: ReactNode }) => {
  //reducer
  const [hmeAlrtState, dispatch] = useReducer(reducer, initialState);

  return (
    <HmeAlrtCtx.Provider
      value={{
        hmeAlrtState,
        dispatch,
      }}
    >
      {children}
    </HmeAlrtCtx.Provider>
  );
};

export default HmeAlrtCtxProvider;
