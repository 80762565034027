import { Outlet } from "react-router-dom"
import LOGIN from "../../UI/auth/LOGIN";


const LoginLayout = ()=>{
    return(
        <div>
            <LOGIN/>
            <Outlet/>
        </div>
    )
}

export default LoginLayout;