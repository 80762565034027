



export const input_field_variants = ((theme)=> {
    return { 

      width: '100%',
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.custom_colors.secondary_a_400,
          borderRadius: '12px',
        },
      },

      '& input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px #111936 inset', // Forces the background color
        WebkitTextFillColor: '#fff', // Forces the text color to remain white
        caretColor: '#fff', // Ensures the caret color is white
        borderColor: theme.palette.custom_colors.secondary_a_400,
        borderRadius: 'initial'

        },

        '& .MuiInputLabel-root': {
          color: `${theme.palette.custom_colors.secondary_a_400} !important` ,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: `${theme.palette.custom_colors.secondary_a_400} !important` , 
        },

         // outlined
        "&.default_outlined_variant":{
      
            "& .MuiOutlinedInput-notchedOutline":{
                    borderColor:theme.palette.custom_colors.grey,
                    borderWidth:'0.5px',
                    borderRadius:'12px'
                    
            },
            "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.custom_colors.primary,
                },
            },
              "&:hover:not(.Mui-focused)": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.custom_colors.primary,
                },
              },
            
        }
    
                

}

})