import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import DatePicker from 'react-datetime';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { capitalize_first_letter, Homeacc_prvdrLogoselector } from '../../../helpers/common_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import { dd_value } from '../../../actions/common_actions';
import {FcCalendar} from "react-icons/fc";
import { Biz_operation_live_reports } from '../../../actions/report_actions';
import { get } from '../../../helpers/storage_helper';
import { listAccountProvider } from '../../../actions/account_provider_actions';
class Product_takeup_report extends FlowComponent{
    state = {
        btn:{
          All:true,
          three_Days:false,
          six_Days:false
        },
        current_btn:'All',
        start_date:'',
        end_date:'',
        dropdown_value:null,
        row_datas:[],
        column_data:[],
        no_data:false,
        all_Acc_pvdrs:[],
        customer_btn_checked:false,
        duration_btn_checked:false,
        is_duration : false
    }
    componentDidMount(){
      const ls_market = get('market')
      var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};	
      listAccountProvider({...req, status: 'enabled', biz_account: true})
      .then((response)=>{					
        if(!response){return };
        if(response.status === "success"){
          let acc_prvdrs = []            
          response.data.list.map(each=>
          acc_prvdrs.push(each.acc_prvdr_code)
          )  
          this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs)  				          
        }
      }); 
    }
   
 
    btn_status=(value)=>{
        Object.keys(this.state.btn).map((key, index)=> {
            if(key==value){
                this.setState(state=>state.btn[key]=true)}
            else{
                this.setState(state=>state.btn[key]=false)
            }
          }); 
    }
    columnDatas=()=>{
      const columns = [
        {
          Header: "Account Provider",
          id: "acc_prvdr_code",
          accessor: "acc_prvdr_code",
          show:true,
          Cell: (cell) => (<p style={{textAlign:'center'}}>{<Homeacc_prvdrLogoselector  product_takeup_print={true} logotype={cell.row.original.acc_prvdr_code}/>}</p>),
        },
        {
          Header: "Number of FAs",
          id: "num_of_fas",
          accessor: "num_of_fas",
          show:true,
          Cell: (cell) =>(<p style={{textAlign:'center'}}>{cell.row.original.num_of_fas}</p>),
        },
        {
          Header: "FA Amount",
          id: "fa_amt",
          accessor: "fa_amt",
          show:true,
          Cell: (cell) => (<p style={{textAlign:'center'}}>{cell.row.original.fa_amt}</p>)
        },
        {
          Header: "Number of Customers",
          id: "num_of_custs",
          accessor: "num_of_custs",
          show: this.state.customer_btn_checked ? false : true,
          Cell: (cell) => (<p style={{textAlign:'center'}}>{cell.row.original.num_of_custs}</p>)
        },
        {
          Header: "Active Customers",
          id: "num_of_custs",
          accessor: "num_of_custs",
          show:this.state.customer_btn_checked ? true : false,
          Cell: (cell) => (<p style={{textAlign:'center'}}>{cell.row.original.num_of_custs}</p>)
        },
        {
          Header: "Duration",
          id: "duration",
          accessor: "duration",
          show:this.state.is_duration ? true : false,
          Cell: (cell) => <p style={{ textAlign: 'center' }}>{cell.row.original.duration}</p>,
        },
      ];
      
      return columns.filter((column) => column.show !== false);
      }
     Print = (async) =>{     
      var content = document.getElementById("printablediv");
      var pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print(); 
    }
     get_product_takeup_report=()=>{
      
      if(!this.state.customer_btn_checked &&(!this.state.start_date||!this.state.end_date)){
          alert('Please fill all the * mandatory fields')
      }
      else{
        this.setState({loader:true,row_datas:[],no_data:false,column_data:[],is_duration:false})
        var request={"report_type":'product_takeup_report',
            "report_search":{
              "start_date":this.state.start_date,
              "end_date":this.state.end_date,
              "active_custs":null,
              'show_duration':null,
              "acc_prvdr_code":this.state.dropdown_value=='All'?null:this.state.dropdown_value
          },
            "country_code":this.country_code
        }
        if(this.state.customer_btn_checked){
          request={"report_type":'product_takeup_report',
          "report_search":{
            "active_custs":true,
            "show_duration":null,
            "acc_prvdr_code":this.state.dropdown_value=='All'?null:this.state.dropdown_value
        },
          "country_code":this.country_code
        }
        }
        if(this.state.duration_btn_checked){
          request={"report_type":'product_takeup_report',
          "report_search":{
            "show_duration":true,
            "start_date":this.state.start_date,
            "end_date":this.state.end_date,
            'active_custs':this.state.customer_btn_checked?true:null,
            "acc_prvdr_code":this.state.dropdown_value=='All'?null:this.state.dropdown_value
        },
          "country_code":this.country_code
        }
        }
      Biz_operation_live_reports(this.req(request))
      .then((response)=>{
        this.resp(response)
        if(!response){this.setState({no_data:true,loader:false} )};
        if(response&&response.status=="success"){
          if(response.data.length==0){
            this.setState({no_data:true})
          }
          else if(response.data[0].duration){
            this.setState({is_duration:true})
          }
          this.setState({loader:false})
          
          this.setState({column_data:this.columnDatas(), row_datas:response.data})
          } 
      })
     }
    }
    setColumn=()=>{
      this.setState({column_data:this.columnDatas()})
    }
    handleDateChange = (state, date) => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      this.setState({[state]:formattedDate})
    }
   
    render(){

    
        return(<div >
      
          <iframe id="ifmcontentstoprint" style={{display:'none'}}></iframe>
        <h4 className='mx-5 px-1 mt-4'>Product Takeup Report</h4>
        <div className='row px-5 mx-1 w-100'>
                <div className='col-sm-12 col-md-6 col-lg-3 my-4  d-flex align-items-center justify-content-center'>
                    <input id='duration' className='form-control col-1 product_check_size' type='checkbox' onChange={(e)=>this.setState({duration_btn_checked:e.target.checked},()=>this.setColumn())}/>
                    <label for='duration' className='col product_check_label'>Show Duration Wise</label>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 my-4  d-flex align-items-center justify-content-center'>
                    <input id='customer' className='form-control col-1 product_check_size' type='checkbox' onChange={(e)=>this.setState({customer_btn_checked:e.target.checked})}/>
                    <label for='customer' className='col product_check_label' >Show Active Customers</label>
                </div>
            </div> 
        <div className={`row w-100 px-5 mx-1  my-lg-5 d-flex align-items-end`}>
        {!this.state.customer_btn_checked&&<><div className='col-sm-12 col-md-6 col-lg-3 p-0'>
              <div className=' m-2 row pl-0'>
              <label className='col p-0' >Start Date <span className='product_red_star'>*</span></label>
              <label className='col-2 p-0 product_textr' htmlFor='start_date'><FcCalendar size={30}/></label>
              <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'start_date', name: 'start_date',autoComplete:'off' }} defaultValue={this.state.start_date} closeOnSelect={true}  onChange={(date) => this.handleDateChange('start_date' ,date )}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
              
              </div>
            </div>
             <div className='col-sm-12 col-md-6 col-lg-3  p-0'>
            <div className=' m-2 row pl-0'>
                <label className='col p-0' >End Date <span className='product_red_star'>*</span></label> 
                <label className='col-2 p-0 product_textr' htmlFor='end_date'><FcCalendar size={30}/></label>
                <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'end_date', name: 'end_date',autoComplete:'off' }} defaultValue={this.state.end_date} closeOnSelect={true}  onChange={(date) => this.handleDateChange('end_date' ,date )}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                
            </div>
            </div></>}
          <div className={`col-sm-12 col-md-6 col-lg-3  p-0 `}>
             <div className=' m-2 row pl-0'>
                <label className='col-12 p-0' >Network Provider  </label>
                <div className='w-100 p-0 m-0 col-12'>
                <Form.Control className='mt-3 col-12 p-0' as="select" id="visit_purpose" defaultValue={this.state.dropdown_value}  onChange={(e) =>this.setState({dropdown_value:e.currentTarget.value})}>          
                  <option value={null}>All</option>
                  {this.state.all_Acc_pvdrs.map((item)=>{return(<option value={item}>{item}</option>)})}
	              </Form.Control>
                </div>
              </div>
            </div>

            <div className={`col-md-6 col-lg-3 text-light d-flex  align-items-end justify-content-center mt-3 p-0 }`}>
                <button className='btn btn-md product_takeup_submit text-white'  onClick={()=>this.get_product_takeup_report()}>Search</button>

            </div>
            
        </div>
        {this.state.row_datas.length>0&&
        <div  className='  m-5 product_takeup_table_print  text-center'>
          {this.state.current_btn=='visits_per_customer'&&
            <h4 className='text-center text-light col-12'>Average Visits Per Customer : <span className='font font_rm_activity'>{this.state.average?this.state.average.avg_visit_per_cust:''}</span> </h4>}
            <button className='btn mx-0 btn-sm print d-flex justify-content-end' style={{float:'right'}} onClick={this.Print}>Print</button>
            <BasicReactTable className='border' row_data={this.state.row_datas}  column_data={this.state.column_data} csv_file={true} FName="RM Activity Report" global_search={false} pagination={true} excess_time_action={false} default_page_size={10} loader={this.state.rowdatas ? false : true} searchData={[{title:'Product Takeup Report',start_date:this.state.start_date,end_date:this.state.end_date,network_prvdr:this.state.dropdown_value}]}/> 
        </div>}
        {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
        {this.state.row_datas.length==0&&this.state.loader&&<>
          <p className='text-light mt-3 text-center'>Loading...</p>
          <div className="product_takeup_loader"></div>
        </>}
      </div>
        )
    }
}
export default Product_takeup_report