import { Box, Button, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AuditorsListAutoComplete from "../../CommonComponent/commonAutoCompleteDD/auditorsListAutoComplete";
import RhfDatePicker from "../../CommonComponent/rhfComponents/rhfDatePicker";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import moment from "moment";
import { MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD } from "../../../consts";

const FilterFormUI = () => {
  // form context
  const {
    formState: { isValid, errors },
  } = useFormContext();

  const enableButton = isValid && Object.keys(errors).length === 0;

  return (
    <Box sx={{ py: 4, px: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <RHFTextField
                name={"custNameOrCustIdOrMblNo"}
                label={"Customer"}
                placeholder="Name/Cust ID/Mobile No"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AuditorsListAutoComplete
                name="auditor"
                label="Initiated by"
                actAs="dd"
                roleCodes={["operations_auditor"]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <RhfDatePicker
                name="startDate"
                label="Start date"
                sx={{
                  // overides the end icon style
                  "& .MuiButtonBase-root": {
                    svg: {
                      width: "20px",
                      height: "20px",
                      color: (theme) =>
                        // @ts-ignore
                        theme.palette.custom_colors.secondary_a_200,
                    },
                  },
                }}
                format="DD MMM YYYY"
                minDate={moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD)}
                maxDate={moment()}
                disableFuture
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <RhfDatePicker
                name="endDate"
                label="End date"
                sx={{
                  // overides the end icon style
                  "& .MuiButtonBase-root": {
                    svg: {
                      width: "20px",
                      height: "20px",
                      color: (theme) =>
                        // @ts-ignore
                        theme.palette.custom_colors.secondary_a_200,
                    },
                  },
                }}
                format="DD MMM YYYY"
                minDate={moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD)}
                maxDate={moment()}
                disableFuture
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <Button
            title="Submit"
            // @ts-ignore
            variant="xs_small"
            type="submit"
            sx={{
              backgroundColor: "#0594FB",
              color: (theme) => theme.palette.text.primary,
            }}
            disabled={!enableButton}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterFormUI;
