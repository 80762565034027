import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import { pending_cashback_list, update_cashback_status,retry,pending_capture } from '../../actions/loan_actions';
import { lbl_amt, Homeacc_prvdrLogoselector, dynAccPrvdrFilter } from '../../helpers/common_helper';

import '../../styles/flow.css';

import { dd_value, touch } from "../../actions/common_actions";
import { check_priv, Config } from '../../helpers/storage_helper';

import * as moment from "moment";

import { get, set } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';

import Accordion from 'react-bootstrap/Accordion';
import { Card, Modal } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs';
import { BasicReactTable } from '../../helpers/react_table';
import { Overlay, Tooltip, OverlayTrigger } from 'react-bootstrap'


class PendingCashbacks extends FlowComponent {
    state = {
        dynamic_filter: {
            dyn_Btn_selected: [],
            all_Btn_status: false,
            filter_actions: {},
            copy: [],
        },
        refresh: false,
        rowdatas: [],
        columndatas: [],
        cashback_col_fields: [
            { key: "acc_number", label: "Acc Number" },
            { key: "cashback_doc_id", label: "Cashback ID" },
            { key: "cust_id", label: "Customer ID" },
            { key: "biz_name", label: "Biz Name" },
            { key: "amount", label: "Amount" },
            { key: "type", label: "Type" },
            { key: "created_at", label: "Initiated" },
            { key: "disbursal_status", label: "Disbursal Status" },
            { key: "action", label: "Action" }]
    }

    handleToggle = (e) => {

        switch (e) {
            case ("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
            case ("retry_modal") :
                this.setState({retry_modal:false});
                break
        }
    }

    componentWillMount() {

        const ls_market = get('market')
        const acc_prvdr_support_ussd = Config("acc_prvdr_support_ussd")
        this.setState({ acc_prvdr_support_ussd })
        var req = { country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: "" };
        listAccountProvider({ ...req, status: 'enabled', biz_account: true })
            .then((response) => {
                if (!response) { return };
                if (response.status === "success") {
                    let acc_prvdrs = []
                    response.data.list.map(each =>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                    this.setState(state => state.all_Acc_pvdrs = acc_prvdrs)
                    if (this.props.type == "pending_cashback") {
                        this.getCashBackList(acc_prvdrs)

                    }
                    set("acc_pvdrs", acc_prvdrs)
                }
            });


    }

    getCashBackList = (acc_prvdrs = this.state.all_Acc_pvdrs) => {
        pending_cashback_list(this.req())
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    this.setState({ columndatas: this.columndatas() })
                    this.setDatas(response, acc_prvdrs)
                }
            })
    }


    setDatas(response, acc_prvdrs) {
        const market = get('market')
        const market_name = market.country_code
        if (response.data.results.length > 0 || response.data.results.length === 0) {
            this.setState(state => state.all_count = response.data.results.length)
            this.setState({ rowdatas: response.data.results })
            const newArr = get([market_name] + '_' + `${this.props.type}`) === undefined ? this.state.all_Acc_pvdrs : get([market_name] + '_' + `${this.props.type}`)
            acc_prvdrs.map(each => {
                const count = this.state.rowdatas.filter(indiv => indiv.acc_prvdr_code === each)
                const length = count.length
                this.setState(state => state['dynamic_filter']['filter_actions'][each] = [{ count: 0 }, { status: newArr.includes(each) }],()=>{
                    this.dynClickhandler(this.state.dynamic_filter.filter_actions[each][1].status, each)

                })
                this.setState(state => state['dynamic_filter']['filter_actions'][each][0].count = length)

            })
        }
    }

    
    dynClickhandler(key, val) {

        var datas = dynAccPrvdrFilter(key, val, this.state.rowdatas, this.state.all_Acc_pvdrs, this.state.dynamic_filter.dyn_Btn_selected, this.state.dynamic_filter.filter_actions, this.state.dynamic_filter)
        this.setState({ dynamic_filter: datas })
    }

    failed_retry(data, value) {
        var req = {
            disbursal_status: value,
            cashback_doc_id: data.cashback_doc_id
        }
        update_cashback_status(this.req(req))
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    this.getCashBackList(this.state.all_Acc_pvdrs)
                }
            })

    }

    update_disb_status(value, data) {
        if (value == "failed") {
            this.failed_retry(data, value)
        }
        if(value=="pending_capture"){
            this.setState({retry_modal:true,activeRow:data})
        }
        
    }

   retryHandler(data){   
        var req = {
            cashback_doc_id: data.cashback_doc_id,
        }
        retry(this.req(req))
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    alert(response.message)
                    this.getCashBackList(this.state.all_Acc_pvdrs)
                }
            })
   }

   update_pending_capture(){
    var req = {
        txn_id :this.state.txn_id,
        cashback_doc_id: this.state.activeRow.cashback_doc_id,
    }
    pending_capture(this.req(req))
        .then((response) => {
            if (!response) { this.setState({retry_modal:false}); return  }
            if (response.status == "success") {
                alert(response.message)
                this.setState({retry_modal:false})
                this.getCashBackList(this.state.all_Acc_pvdrs)
            }
        })
   }

    columndatas() {
        if (this.props.type == "pending_cashback") {
            const columns = [
                this.state.cashback_col_fields.map((item, idx) => {
                    return {
                        Header: <p className='text-center m-0'>{item.label}</p>,
                        id: item.key,
                        accessor: item.key,
                        Cell: (cell) => {
                            {
                                return <div className='d-flex justify-content-center'>
                                    {item.key == "acc_number" ?
                                        <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code} /> <p className='no-margin' style={{ paddingLeft: '20px' }}> {cell.row.original.acc_number}</p>
                                        </div>
                                        :
                                        <>
                                            {(item.key == "action") ?
                                                <>{cell.row.original.disbursal_status == "failed" ?
                                                    <button type="button" class="btn btn-primary" onClick={() => { this.retryHandler(cell.row.original)}}>Retry</button> :
                                                    
                                                    <div>
                                                        {(cell.row.original.disbursal_status == "sent_to_batch_dsbrsl_queue" || cell.row.original.disbursal_status == "sent_to_disbursal_queue")  ? "-":
                                                        <select id="status" className="form-control" required="required" onChange={(e) => this.update_disb_status(e.target.value, cell.row.original)} >
                                                            <option value="">Choose</option>
                                                            <option value={"pending_capture"}>Pending Capture</option>
                                                            <option value="failed">Failed</option>

                                                        </select>}
                                                    </div>
                                                }
                                                </>
                                                :
                                                <p className='text-light m-0  '>

                                                    {
                                                        (item.key == "biz_name") ?
                                                        <OverlayTrigger key='local_balance' placement="top" overlay={
                                                            <Tooltip id="local_balance">{dd_value(cell.row.original[item.key])} {(item.key =="type" )? cell.row.original.recent_ontime_fas ? "- "+ cell.row.original.recent_ontime_fas  :"" :""}
                                                            </Tooltip>}>
                                                                <span>
                                                                    {( cell.row.original[item.key] && cell.row.original[item.key].length > 15  && item.key !== "type")?
                                                                     (dd_value(cell.row.original[item.key])).substring(0, 15)+ "..." : 
                                                                    
                                                                    <>{dd_value(cell.row.original[item.key])} {(item.key =="type" )? cell.row.original.recent_ontime_fas ? "- "+ cell.row.original.recent_ontime_fas  :"" :""}</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                            :

                                                            cell.row.original[item.key] ? ((item.key == "created_at") ? moment(cell.row.original[item.key]).format('DD MMM YYYY') :

                                                                (item.key == "amount" ? lbl_amt(cell.row.original[item.key], get('market').currency_code) : dd_value(cell.row.original[item.key],item.key=="type" ? "cashback_type":cell.row.original[item.key])))

                                                                : "NA"
                                                    }

                                                </p>
                                            }
                                        </>
                                    }
                                </div>
                            }
                        }


                    };
                }),
            ];

            return columns[0];
        }
    }

    render() {
        return (

            <Accordion className={`${this.state.retry_modal ? "disable_div":""}`} >
                <Card>
                    <div className={`${'non_empty_display_list'} `}>
                        <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                            <h4 className="card-title text-white tableHead_font title-bottom "> {this.props.type ? dd_value(this.props.type) : "FAs Pending Disbursal"}</h4>
                            {this.state.rowdatas.length > 0 &&
                                <>
                                    <div className='countREL card-title'>
                                        <button className={'filterBtns allBtn_size'} onClick={() => this.dynClickhandler(!this.state.dynamic_filter.all_Btn_status, "ALL")} style={{ filter: !this.state.dynamic_filter.all_Btn_status ? "opacity(0.5)" : '' }}>ALL</button>
                                        <div className='countABS' style={{ backgroundColor: !this.state.dynamic_filter.all_Btn_status ? "lightgrey" : '' }}>
                                            <label>{this.state.rowdatas.length}</label>
                                        </div>
                                    </div>
                                    {this.state.all_Acc_pvdrs && this.state.all_Acc_pvdrs.map((each, idx) =>
                                        <div key={idx} className='countREL card-title'>
                                            <>
                                                <div style={{ width: 60, height: 30 }} className="filterBtns" onClick={() => this.dynClickhandler(!this.state.dynamic_filter.filter_actions[each][1].status, each)}>
                                                    <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{ filter: this.state.dynamic_filter.filter_actions[each] && !this.state.dynamic_filter.filter_actions[each][1].status ? "opacity(0.5)" : '' }}></img>
                                                </div>
                                                <div className='countABS' style={{ backgroundColor: this.state.dynamic_filter.filter_actions[each] && !this.state.dynamic_filter.filter_actions[each][1].status ? "lightgrey" : '' }}>
                                                    <label>{this.state.dynamic_filter.filter_actions[each] && this.state.dynamic_filter.filter_actions[each][0].count}</label>
                                                </div>
                                            </>
                                        </div>
                                    )}
                                </>
                            }
                            <Accordion.Toggle className="col" as={Card.Header} eventKey="0" onClick={() => this.handleToggle("Account")}>
                                <BsChevronDown className={this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" />
                            </Accordion.Toggle>
                        </div>
                    </div>
                    <div className="card-body no-padding">
                        {this.state.rowdatas && this.state.rowdatas.length > 0 &&
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className='flagg_cust_table react_common_table'>
                                        <BasicReactTable className='border' hidetooltiptitle={true} row_data={this.state.dynamic_filter.copy ? this.state.dynamic_filter.copy : []} column_data={this.state.columndatas} global_search={false} pagination={true} excess_time_action={false} default_page_size={10} />
                                    </div>

                                </Card.Body>
                            </Accordion.Collapse>
                        }
                    </div>
                </Card>
                {this.state.retry_modal &&
                    <Modal className="confirm_modal pending_capture_modal" show={this.state.retry_modal} onHide={() => this.handleToggle("retry_modal")}
                     
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                       
                        <Modal.Body>
                            <p className='m-0 sizel text-white' style={{lineHeight:'25px'}}>Entry the matching transaction ID found in unknown for this referral </p>
                          
                          <div className='d-flex align-items-center my-3 row mx-0'>
                            <p className='m-0 col-3 text-white'>TXN ID <b className='text-danger'>*</b></p>
                            <div className='col-9 px-0'>
                            <input type="text"  id="model_code" autoComplete='off'  onChange={(e)=>{this.setState({txn_id:e.target.value})}} className="form-control col-12 w-100 "  />

                            </div>

                          </div>

                            <div className="d-flex align-items-center justify-content-end mt-5">
                                <button type="button" class="btn btn-confirm mx-3 " onClick={(e) => this.update_pending_capture()} >Confirm</button>
                                <button type="button" class="btn btn-cancel mx-2" onClick={() => this.handleToggle("retry_modal")} >Cancel</button>
                            </div>
                        </Modal.Body>

                    </Modal>}
            </Accordion>
        );

    }
}



export default PendingCashbacks;
