import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";

import FileUpload from '../../common/component/FileUpload';

import {get, Config, check_priv} from '../../../helpers/storage_helper';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {dd_value} from '../../../actions/common_actions';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaPhoneAlt } from "react-icons/fa";
import { connect } from 'react-redux';
import withRouter from '../../../Router/Withrouter';
import Softphone from '../../cs_management/container/cs_soft_phone';
import { setValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';


class RekycMobileNumberVerification extends FlowComponent{
    constructor() {
        super();
      this.state={
        lead:{},
        lead_num_verify_call : false
       }
    }

    componentWillMount(){
    this.setState({addl_alignment_status:this.additional_number_value_checking()}) 
    if(check_priv('lead', 'verify_lead_num_call') && get("market").country_code == 'UGA'){
      this.setState({lead_num_verify_call:true})
    }
    }
    additional_number_value_checking(){
      if( this.props.biz_mobilenumbers_sdata){
        var a;
        this.props.biz_mobilenumbers_sdata.old_cust_reg_json?.mobile_numbers?.addl_num?.map((item,idx)=>{
       
          if(item.mobile_num.value!=null){
          a="value_is_not_null"
          }

        })
        if(a){
          return a
        }
        return false
      }
   
    }

    audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }

      setAuditLeadState = (event) => {
        this.setChangeToState(event)
       }
    
       checkbox_spinner(){
        return(
          <div class="d-flex col-lg-12 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }

      rekyc_modify_checking(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){

        if(addl_key !=null && idx ==null ){
        
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected_n_modified")) &&  (cust_reg_json[json_type][json_key][key].collected_n_modified) )){
            return this.get_class_name(cust_reg_json,"collected_modified")
          }
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][key].modified))){
            return  this.get_class_name(cust_reg_json,"modified")

          }

          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected")) && (cust_reg_json[json_type][json_key][key].collected))){
            return this.get_class_name(cust_reg_json,"collected")

          }
          else{
            return false;
           }

        }

       else if(idx!=null){
          if(((cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected"))&& (cust_reg_json[json_type][json_key][addl_key][idx][key].collected)&& (cust_reg_json[json_type][json_key][addl_key][idx][key].modified))) {
            return  this.get_class_name(cust_reg_json,"collected_modified")
          }
          if(((cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("modified"))  && (cust_reg_json[json_type][json_key][addl_key][idx][key].modified))) {
            return  this.get_class_name(cust_reg_json,"modified")
          }
          if(( (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected"))&& (cust_reg_json[json_type][json_key][addl_key][idx][key].collected))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }
        else{
          if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  && (cust_reg_json[json_type][json_key][key].collected)&& (cust_reg_json[json_type][json_key][key].modified))){
		
            return  this.get_class_name(cust_reg_json,"collected_modified")
        
             }
             if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("modified"))  && (cust_reg_json[json_type][json_key][key].modified))){
		
              return  this.get_class_name(cust_reg_json,"modified")
          
               }
               if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  && (cust_reg_json[json_type][json_key][key].collected))){
		
                return  this.get_class_name(cust_reg_json,"collected")
            
                 }
                 else{
                  return false;
                 }
        }
       
      }

      handleCall = (mobile_num) => {
        this.props.setValuesIntoRedux({screen_from:"lead_num_verify", lead_id : this.props.lead_id, outgoing_call_data : {lead_id : this.props.lead_id, lead_mob_num : mobile_num},  lead_details : {lead_id : this.props.lead_id, lead_mob_num : mobile_num} , lead_num_verify : true})
      }
      

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "Re-KYCed"
         }
         
         else{
          return "MODIFIED"
         }
        }

    render(){
        return(
            <div>
           

           {(this.props.biz_mobilenumbers_sdata.audit_kyc_wizard_menus.mobile_numbers)  &&
                <div className='row no-margin justify-content-center'>
                {this.props.biz_mobilenumbers_sdata.View_lead_section_loader ? <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:

                  <>
                <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                  <h2 className='text-white text-center'>
                  <b> {`${this.props.biz_mobilenumbers_sdata.cust_reg_json.same_as_owner_person ? "7" :"8"} - Mobile Number Verification`}</b> 

                
                  </h2>
                  <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.biz_mobilenumbers_sdata.active_section && (this.props.biz_mobilenumbers_sdata.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

                 </div>
                 {this.props.biz_mobilenumbers_sdata &&
                 <>
                
                 <>
                
                 <div className='col-lg-12 col-md-12 col-sm-12 no-padding ' >
                  
                  <div className='col-lg-12 py-3  ' style={{background:' #01062c'}}>
                  <h2 className='text-white text-center'>
                   Business Mobile Numbers
                  </h2>
                 </div>
                

                <div className={`row no-margin justify-content-center ${this.props.biz_mobilenumbers_sdata.audit_kyc_mobile_numbers ? "disable_div":""}`}>
                  <div className='col-lg-10 col-md-12'>
                 <div className="row  justify-content-center d-flex justify-content-around mt-3">
                <div className="col-md-12 p-0 col-lg-5">
                <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>Business Mob Num (As per KYC)</b></h2>

                </div>
                <div className="col-md-12 col-lg-5">
                <h2 className='text-white p-2 px-3  new_re_kyc_text_bg_color text-center'> <b> Business Mob Num (As Per Re-KYC)</b></h2>

                </div>
                  </div> 

                <div className="row justify-content-center d-flex justify-content-around my-4"  >
                <div className="col-md-12 p-0 col-lg-5 old_json_text_bg_color audit_kyc_monbile_num_border" style={{background:'#4a5579'}}>
                <div  className=' ' style={{background:'#4a5579'}}>
                    <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Registered Mobile Number </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                  <div className='pointerr' id="pointerr">
                    <p className='text-white ' style={{margin:'10px 5px 0px 0px',fontSize:'15px',textAlign:'end'}}><b>Manually Verified</b> </p>
                  </div>
                    {this.props.biz_mobilenumbers_sdata.old_cust_reg_json?.mobile_numbers?.alt_biz_mobile_num_1?.value !=null?

                    <div className='p-3 py-3 mt-5'>
                    <div className='d-flex  align-items-center  d-flex justify-content-between  mx-2'>
                    <div className='d-flex align-items-center'>
                        <div className='' >

                        <div className='d-flex align-items-center'>
                        {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_serv_prvdr_code.value!=null &&

                        <div className={` disable_div ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'}`} >
                        <OverlayTrigger placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                        
                        <AiFillEdit className='text-white pointer ml-2'  size={15} onClick={()=>{this.props.previous_comments_section("reg_mobile_opertator")}}/>
                        </OverlayTrigger>

                        </div>}

                        {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_serv_prvdr_code.value!=null ?
                        <img className='ml-1 p-1 pointer' src={`/img/${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>
                          :<p className='no-margin text-white'> No Data</p>}
                        </div>
                        {this.props.biz_mobilenumbers_sdata.reg_mobile_opertator && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                        <div>
                            {this.props.operators_selection("mobile_num_serv_prvdr_code","mobile_num","reg_mobile_opertator","Registered Mobile Number")}

                        </div>
                        }
                        </div>
                        {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                        <p className='pl-4 no-margin audit_kyc_text text-white'> <b>{this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num.value}</b></p>
                        {this.state.lead_num_verify_call && 
                          <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                            <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num.value)}>
                              <FaPhoneAlt style={{color:"white"}}/>
                            </div>
                          </div>
                        }
                        
                    </div>
                    <div>
                    <p className='no-margin p-2 audit_kyc_person_icon' ><MdPersonOutline size={30}/></p>
                    <p className='no-margin text-white pl-2' > SELF</p>
                    </div>

                    </div>
                    <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM</p>
                    <div className='d-flex align-items-center  mx-2 mb-2 '>
                    <p className='no-margin px-3 py-2 audit_kyc_text_name' style={{background:`${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_name_match ? "green":"#dcdde4"}`}}><b> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.id_proof.last_name.value}</b> </p>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.hasOwnProperty('mobile_num_holder_name') )?
                    <div className='my-3'>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>
                    <div className='d-flex align-items-center justify-content-between mt-2 mb-3'>
                    <div className='d-flex align-items-center  mx-2 '>
                        <p className='no-margin px-3 py-2 audit_kyc_text_name' style={{background:`${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_name_match ? "green":"#dcdde4"}`}}><b> { this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_holder_name}</b> </p>
                    </div>
                        <div className='pl-3'> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.verified_mobile_num==1 ? < GoVerified size={30} color={"#1dd61d"}/> : (this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.rejected_mobile_num==1 &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.verified_mobile_num==1) ||(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.rejected_mobile_num==1) ?"":
                    <div className='d-flex  pl-2 align-items-center'>
                        <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.mobile_num_name_match==true ? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section.registered_mobile_num_mismatch_reason)}) </p>}</p>
                        {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                    </div>
                        }
                    </div>
                    :
                    <>
                    <div className='pt-5'>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>

                        <p className='text-white no-margin audit_kyc_addl_text pl-2 py-2'> NA</p>
                       
                    </div>

                    </>
                    }

                    </div>:
                      <div className='d-flex align-self-center text-white justify-content-center text-center float_acc_balance_view_bttn' style={{fontSize:'18px'}}>
                      NA
                       </div>
                    }
                    </div>
                </div>
                <div className="col-md-12 col-lg-5"  >
                   <fieldset className= {`${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num") ? ` ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
                  { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num") ?
                   <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num")+"_text_color"}  `}> 
                  <b> { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num")} </b></legend> :""}
                   
                   
                    <div  className='audit_kyc_monbile_num_border ' style={{background:'#4a5579',minHeight:'300px'}}>
                    <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Registered Mobile Number </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                    <div className='p-3 py-3'>
                    <div className='d-flex  align-items-center  d-flex justify-content-between  mx-2'>
                    <div className='d-flex align-items-center'>
                        <div className='' >

                        <div className='d-flex align-items-center'>
                        
                        <div className={`${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'} ${this.props.biz_mobilenumbers_sdata.active_section.verify ?'disable_div' :''}`} >
                        <OverlayTrigger placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                        
                        <AiFillEdit className='text-white pointer ml-2'  size={15} onClick={()=>{this.props.previous_comments_section("reg_mobile_opertator")}}/>
                        </OverlayTrigger>
                        </div>
                        <img className={`ml-1 p-1 pointer `} src={`/img/${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>

                        </div>
                        {this.props.biz_mobilenumbers_sdata.reg_mobile_opertator && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                        <div>
                            {this.props.operators_selection("mobile_num_serv_prvdr_code","mobile_num","reg_mobile_opertator","Registered Mobile Number")}

                        </div>
                        }
                        </div>
                        {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                        <p className={`pl-4 py-2  no-margin audit_kyc_text text-white  `}> <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num.value}</b></p>
                        {this.state.lead_num_verify_call && 
                          <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                            <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num.value)}>
                              <FaPhoneAlt style={{color:"white"}}/>
                            </div>
                          </div>
                        }
                    </div>
                    <div>
                    <p className='no-margin p-2 audit_kyc_person_icon' ><MdPersonOutline size={30}/></p>
                    <p className='no-margin text-white pl-2' > SELF </p>
                    </div>

                    </div>
                    <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM</p>
                    <div className='d-flex align-items-center  mx-2 mb-2 '>
                    <p className= {`no-margin px-3 py-2 audit_kyc_text_name `} style={{background:`${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_name_match ? "green":"#dcdde4"}`}}><b> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value}</b> </p>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty('mobile_num_holder_name') )?
                    <div>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>
                    <div className='d-flex align-items-center justify-content-between mt-2 mb-3'>
                    <div className='d-flex align-items-center  mx-2 '>
                        <p className={`no-margin px-3 py-2 audit_kyc_text_name  ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num_holder_name",null,null,"biz_mobile_numbers") }`} style={{background:`${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_name_match ? "green":"#dcdde4"}`}}><b> { this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_holder_name}</b> </p>
                    </div>
                        <div className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_mobile_num==1 ? < GoVerified size={30} color={"#1dd61d"}/> : (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_mobile_num==1 &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_mobile_num==1) ||(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_mobile_num==1) ?"":
                    <div className='d-flex  pl-2 align-items-center'>
                        <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_name_match==true ? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section.registered_mobile_num_mismatch_reason)}) </p>}</p>
                        {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                    </div>
                        }
                    </div>
                    :
                    <>
                        <div className='col-lg-12 p-3 d-flex mt-4' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black'}}> <b className='pr-2' style={{color:'red'}}>Note</b> Unable to retrieve the name of the mobile number.</p>
                        </div>

                    </>
                    }

                    </div>
                    </div>

                    <div  className={`  d-flex pt-3 pb-3 justify-content-around no-margin ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'}`}  >
                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty('mobile_num_holder_name')) ?
                    <>

                        {((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_mobile_num==1) || (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_mobile_num==1)) ? "" :
                        <>
                        <button type="button" class="btn btn-primary "  disabled={this.props.biz_mobilenumbers_sdata.active_section.registered_mobile_num_verify ? true: false}  onClick={ ()=> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_name_match == false ? this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers","mobile_num",null,"registered_mobile_num_verify",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num.value,"reg_num") : this.props.verify_handler("mobile_num_verify", "registered_mobile_num_verify")} }>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_name_match == false ? "Verify Number" : "Validate"}</button>
                        <button type="button" class="btn btn-primary " onClick={()=>this.props.audit_kyc_confirm_identity("Registered Mobile Number ",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num.value, this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num_holder_name,"mobile_num")}  disabled={this.props.biz_mobilenumbers_sdata.active_section.registered_mobile_num_verify ? false: true} >Verify Identity</button>
                        </>
                        }

                    </>
                    :
                        <>
                        <button type="button" class="btn btn-primary "  onClick={()=>this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers","mobile_num",null,"registered_mobile_num_verify",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.mobile_num.value,"reg_num")}>Verify Name</button>
                        <button type="button" class="btn btn-primary " disabled={this.props.biz_mobilenumbers_sdata.active_section.registered_mobile_num_verify ? false:true}>Verify Identity</button>
                        </>
                    
                    }

                    </div>
                    </fieldset>
                </div>
                </div> 

                <div className="row justify-content-center d-flex justify-content-around my-4"  >
                <div className="col-md-12 p-0 col-lg-5 old_json_text_bg_color audit_kyc_monbile_num_border" style={{background:'#4a5579'}}>
                <div  className=' ' style={{background:'#4a5579',minHeight:'300px'}}>
                    <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Alternate Mobile Number I </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                    <div className='pointerr' id="pointerr">
                          <p className='text-white ' style={{margin:'10px 5px 0px 0px',fontSize:'15px',textAlign:'end'}}><b>Manually Verified</b> </p>
                        </div>
                    {this.props.biz_mobilenumbers_sdata.old_cust_reg_json?.mobile_numbers?.alt_biz_mobile_num_1?.value !=null?

                    <div className='p-3 py-3 mt-5'>
                    <div className='d-flex align-items-center  d-flex justify-content-between my-2 mx-2'>
                    <div className='d-flex align-items-center'>

                    <div className='' >
                    <div className='d-flex align-items-center'>
                
                    {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt1_serv_prvdr_code.value!=null &&

                    <div  className={` disable_div ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'}`}>
                    <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                        
                        <AiFillEdit className='text-white pointer ml-2' size={15} onClick={()=>{this.props.previous_comments_section("alt_biz1_mobile_opertator")}}/>
                    </OverlayTrigger>
                    </div>
                  }
                    {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt1_serv_prvdr_code.value!=null ?

                    <img className='ml-1 p-1 pointer' src={`/img/${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt1_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>
                    :<p className='text-white'>No Data</p>
                  }

                    </div>
                    {this.props.biz_mobilenumbers_sdata.alt_biz1_mobile_opertator && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                    <div>
                    {this.props.operators_selection("alt1_serv_prvdr_code","alt_biz_mobile_num_1","alt_biz1_mobile_opertator","Alternate Mobile Number 1")}
                    </div>
                    }
                    </div>
                        {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                        <p className='pl-4 no-margin audit_kyc_text text-white'> <b>{this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value}</b></p>
                        {this.state.lead_num_verify_call && 
                        <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                          <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value)}>
                            <FaPhoneAlt style={{color:"white"}}/>
                          </div>
                        </div>
                        }
                    </div>
                    <div>
                    <p className='no-margin p-2 audit_kyc_person_icon' ><MdPersonOutline size={30}/></p>
                    <p className='no-margin text-white pl-2' > SELF</p>
                    </div>

                    </div>
                    <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM</p>
                    <div className='d-flex align-items-center my-1 mx-2'>
                    <p className='no-margin px-3 py-2 audit_kyc_text_name' style={{background:`${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match ? "green":"#dcdde4"}`}}><b> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.id_proof.last_name.value}</b> </p>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.hasOwnProperty('alt_biz_mobile_num_1_holder_name') )?
                    <div>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                    <div className='d-flex align-items-center  mx-2 '>
                        <p className='no-margin px-3 py-2 audit_kyc_text_name' style={{background:`${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match ? "green":"#dcdde4"}`}}><b> { this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_holder_name}</b> </p>
                    </div>
                        <div className='pl-3'> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_1==1 ? < GoVerified size={30} color={"#1dd61d"}/> : (this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_1==1 &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                    </div>

                    {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_1==1) ||(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_1==1) ?"":
                    <div className='d-flex  pl-2 align-items-center'>
                        <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match==true ? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_1_mismatch_reason)}) </p>}</p>
                        {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                    </div>
                        }
                    
                    </div>
                    :
                    <>
                          <div className='pt-5'>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>

                        <p className='text-white no-margin audit_kyc_addl_text pl-2 py-2'> NA</p>
                       
                        </div>

                    </>
                    }

                    </div>
                    :
                    <div className='d-flex align-self-center text-white justify-content-center text-center float_acc_balance_view_bttn' style={{fontSize:'18px'}}>
                    NA
                     </div>
                    }
                    </div>
                </div>

                <div className="col-md-12 col-lg-5"  >
                <fieldset className= {` h-100 ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_1") ? ` ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_1")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
                  { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_1") ?
                   <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_1")+"_text_color"}  `}> 
                  <b> { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_1")} </b></legend> :""}
                   
                   
                    <div  className='  audit_kyc_monbile_num_border' style={{background:'#4a5579',minHeight:'300px'}}>
                    <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Alternate Mobile Number I </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                    <div className='p-3 py-3'>
                    <div className='d-flex align-items-center  d-flex justify-content-between my-2 mx-2'>
                    <div className='d-flex align-items-center'>

                    <div className='' >
                    <div className='d-flex align-items-center'>

                    <div  className={`${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'} ${this.props.biz_mobilenumbers_sdata.active_section.verify ?'disable_div' :''}`}>
                    <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                        
                        <AiFillEdit className='text-white pointer ml-2' size={15} onClick={()=>{this.props.previous_comments_section("alt_biz1_mobile_opertator")}}/>
                    </OverlayTrigger>
                    </div>
                    <img className={`ml-1 p-1 pointer `} src={`/img/${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt1_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>


                    </div>
                    {this.props.biz_mobilenumbers_sdata.alt_biz1_mobile_opertator && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                    <div>
                    {this.props.operators_selection("alt1_serv_prvdr_code","alt_biz_mobile_num_1","alt_biz1_mobile_opertator","Alternate Mobile Number 1")}
                    </div>
                    }
                    </div>
                        {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                        <p className= {`pl-4 py-2 no-margin audit_kyc_text text-white  `}> <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value}</b></p>
                        {this.state.lead_num_verify_call && 
                        <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                          <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value)}>
                            <FaPhoneAlt style={{color:"white"}}/>
                          </div>
                        </div>
                        }
                    </div>
                    <div>
                    <p className='no-margin p-2 audit_kyc_person_icon' ><MdPersonOutline size={30}/></p>
                    <p className='no-margin text-white pl-2' > SELF</p>
                    </div>

                    </div>
                    <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM</p>
                    <div className='d-flex align-items-center my-1 mx-2'>
                    <p className= {`no-margin px-3 py-2 audit_kyc_text_name  `} style={{background:`${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match ? "green":"#dcdde4"}`}}><b> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value}</b> </p>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty('alt_biz_mobile_num_1_holder_name') )?
                    <div>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                    <div className='d-flex align-items-center  mx-2 '>
                        <p className= {`no-margin px-3 py-2 audit_kyc_text_name  `} style={{background:`${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match ? "green":"#dcdde4"}`}}><b> { this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_holder_name}</b> </p>
                    </div>
                        <div className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_1==1 ? < GoVerified size={30} color={"#1dd61d"}/> : (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_1==1 &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                    </div>

                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_1==1) ||(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_1==1) ?"":
                    <div className='d-flex  pl-2 align-items-center'>
                        <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match==true ? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_1_mismatch_reason)}) </p>}</p>
                        {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                    </div>
                        }
                    
                    </div>
                    :
                    <>
                        <div className='col-lg-12 p-3 d-flex mt-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black'}}> <b className='pr-2' style={{color:'red'}}>Note</b> Unable to retrieve the name of the mobile number.</p>
                        </div>

                    </>
                    }

                    </div>
                    </div>
               

                    <div className={` d-flex pt-3 pb-3  justify-content-around no-margin ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'} `}>
                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty('alt_biz_mobile_num_1_holder_name')) ?
                    
                        <>
                        {((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_1==1) || (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_1==1)) ? "" :
                        <>
                        <button type="button" class="btn btn-primary "  disabled={this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_1_verify ? true: false}  onClick={ ()=> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match == false ?this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers","alt_biz_mobile_num_1",null,"alternate_mobile_num_1_verify",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value,"alt1_serv_prvdr_code") : this.props.verify_handler("mobile_num_verify", "alternate_mobile_num_1_verify")} }>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_name_match == false ? "Verify Number" : "Validate"}</button>
                        <button type="button" class="btn btn-primary " onClick={()=>this.props.audit_kyc_confirm_identity("Alternate Mobile Number 1 ",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value, this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1_holder_name,"alt_biz_mobile_num_1")}  disabled={this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_1_verify ? false: true} >Verify Identity</button>
                        </>
                        }
                        </>
                    
                    :
                        <>
                        <button type="button" class="btn btn-primary " onClick={()=>this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers","alt_biz_mobile_num_1",null,"alternate_mobile_num_1_verify",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_1.value,"alt1_serv_prvdr_code")}>Verify Name</button>
                        <button type="button" class="btn btn-primary " disabled={this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_1_verify? false:true}>Verify Identity</button>
                        </>
                    
                    }

                    </div>
                  </fieldset>
                </div>


                </div> 
                { (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value) &&

                <div className="row justify-content-center d-flex justify-content-around my-4"  >
              

                <div className="col-md-12 p-0 col-lg-5 old_json_text_bg_color audit_kyc_monbile_num_border" style={{background:'#4a5579'}}>
                    <div  className=' ' style={{background:'#4a5579',minHeight:'300px'}}>
                    <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Alternate Mobile Number II </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                    <div className='pointerr' id="pointerr">
                          <p className='text-white ' style={{margin:'10px 5px 0px 0px',fontSize:'15px',textAlign:'end'}}><b>Manually Verified</b> </p>
                        </div>
                    {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value !=null?

                    <div className='p-3 py-3 mt-5'>
                    <div className='d-flex align-items-center  d-flex justify-content-between my-2 mx-2'>
                    <div className='d-flex align-items-center'>

                    <div className='' >

                        <div className='d-flex align-items-center'>
                        {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt2_serv_prvdr_code.value!=null &&

                        <div className={`disable_div ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'}`} >
                        <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                        <AiFillEdit className='text-white pointer ml-2'  size={15} onClick={()=>{this.props.previous_comments_section("alt_biz2_mobile_opertator")}}/>
                        </OverlayTrigger>
                        </div>
                        }
                       {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt2_serv_prvdr_code.value!=null ?

                        <img className='ml-1 p-1 pointer' src={`/img/${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt2_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>
                        :<p className='text-white no-margin'>No Data</p>}
                        </div>
                        {this.props.biz_mobilenumbers_sdata.alt_biz2_mobile_opertator && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                        <div>
                        {this.props.operators_selection("alt2_serv_prvdr_code","alt_biz_mobile_num_2","alt_biz2_mobile_opertator","Alternate Mobile Number 2")}
                        </div>
                        }
                        </div>

                            {/* <img src={`/img/${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt2_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/> */}
                            {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                            <p className='pl-4 no-margin audit_kyc_text text-white'> <b>{this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value} </b></p>
                            {this.state.lead_num_verify_call && 
                            <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                              <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value)}>
                                <FaPhoneAlt style={{color:"white"}}/>
                              </div>
                            </div>
                            }
                    </div>
                  
                    <div>
                    <p className='no-margin p-2 audit_kyc_person_icon' ><MdPersonOutline size={30}/></p>
                    <p className='no-margin text-white pl-2' > SELF</p>
                    </div>

                    </div>
                    <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM</p>
                    <div className='d-flex align-items-center  mx-2 '>
                        <p className='no-margin px-3 py-2 audit_kyc_text_name' style={{background:`${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match ? "green":"#dcdde4"}`}}><b> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.id_proof.last_name.value}</b> </p>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.hasOwnProperty('alt_biz_mobile_num_2_holder_name') )?
                        <div>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-4 py-2'>Retrieved Name</p>
                        <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center mx-2  '>
                            <p className='no-margin px-3 py-2 audit_kyc_text_name' style={{background:`${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match ? "green":"#dcdde4"}`}}><b> { this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_holder_name}</b> </p>
                        </div>
                            <div className='pl-3'> {this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_2==1 ? < GoVerified size={30} color={"#1dd61d"}/> : (this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_2==1 &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                        </div>
                        

                    {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_2==1) ||(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_2==1) ? "":
                    <div className='d-flex  pl-2 align-items-center'>
                        <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match==true  ? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_2_mismatch_reason)}) </p>}</p>
                        {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                    </div>
                        }
                        </div>
                        :
                        <>
                        <div className='pt-5'>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>

                        <p className='text-white no-margin audit_kyc_addl_text pl-2 py-2'> NA</p>
                       
                        </div>
                    
                        </>
                    }

                    </div>:
                    <div className='d-flex align-self-center text-white justify-content-center text-center float_acc_balance_view_bttn' style={{fontSize:'18px'}}>
                     NA
                      </div>
                    }
                    </div>  
                </div>
    

                <div className="col-md-12 col-lg-5"  >
                <fieldset className= {`${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_2") ? ` ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_2")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
                  { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_2") ?
                   <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_2")+"_text_color"}  `}> 
                  <b> { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","alt_biz_mobile_num_2")} </b></legend> :""}
                   
                    <div  className='audit_kyc_monbile_num_border ' style={{background:'#4a5579',minHeight:'300px'}}>
                    <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Alternate Mobile Number II </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                      <>
                    {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value !=null?

                    <div className='p-3 py-3'>
                    <div className='d-flex align-items-center  d-flex justify-content-between my-2 mx-2'>
                    <div className='d-flex align-items-center'>

                    <div className='' >

                        <div className='d-flex align-items-center'>
                        
                        <div className={`${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'} ${this.props.biz_mobilenumbers_sdata.active_section.verify ?'disable_div' :''}`} >
                        <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                        <AiFillEdit className='text-white pointer ml-2'  size={15} onClick={()=>{this.props.previous_comments_section("alt_biz2_mobile_opertator")}}/>
                        </OverlayTrigger>
                        </div>
                        <img className= {`ml-1 p-1 pointer  `} src={`/img/${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt2_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>

                        </div>
                        {this.props.biz_mobilenumbers_sdata.alt_biz2_mobile_opertator && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                        <div>
                        {this.props.operators_selection("alt2_serv_prvdr_code","alt_biz_mobile_num_2","alt_biz2_mobile_opertator","Alternate Mobile Number 2")}
                        </div>
                        }
                        </div>

                            {/* <img src={`/img/${this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.alt2_serv_prvdr_code.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/> */}
                            {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                            <p className= {`pl-4 no-margin audit_kyc_text text-white  `}> <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value}</b></p>
                            {this.state.lead_num_verify_call && 
                            <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                              <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value)}>
                                <FaPhoneAlt style={{color:"white"}}/>
                              </div>
                            </div>
                            }
                    </div>
                    <div>
                    <p className='no-margin p-2 audit_kyc_person_icon' ><MdPersonOutline size={30}/></p>
                    <p className='no-margin text-white px-2 py-2' > SELF</p>
                    </div>

                    </div>
                    <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM</p>
                    <div className='d-flex align-items-center  mx-2 '>
                        <p className= {`no-margin px-3 py-2 audit_kyc_text_name  `} style={{background:`${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match ? "green":"#dcdde4"}`}}><b> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value}</b> </p>
                    </div>
                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty('alt_biz_mobile_num_2_holder_name') )?
                        <div>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-4 py-2'>Retrieved Name</p>
                        <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center mx-2  '>
                            <p className= {`no-margin px-3 py-2 audit_kyc_text_name  `} style={{background:`${this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match ? "green":"#dcdde4"}`}}><b> { this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_holder_name}</b> </p>
                        </div>
                            <div className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_2==1 ? < GoVerified size={30} color={"#1dd61d"}/> : (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_2==1 &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                        </div>
                        

                    {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_2==1) ||(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_2==1) ? "":
                    <div className='d-flex  pl-2 align-items-center'>
                        <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match==true  ? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_2_mismatch_reason)}) </p>}</p>
                        {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                    </div>
                        }
                        </div>
                        :
                        <>
                        <div className='col-lg-12 p-3 d-flex mt-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black'}}> <b className='pr-2' style={{color:'red'}}>Note</b> Unable to retrieve the name of the mobile number.</p>
                        </div>
                    
                        </>
                    }

                    </div>:""}
                    </>
                    </div>
                     {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value !=null?
                     <div  className={`  d-flex pt-3 pb-3 justify-content-around no-margin ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'}`}  >
                        {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty('alt_biz_mobile_num_2_holder_name')) ?
                        
                        <>
                        {((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.verified_alt_biz_mobile_num_2==1) || (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.rejected_alt_biz_mobile_num_2==1)) ? "" :
                        <>
                        <button type="button" class="btn btn-primary "  disabled={this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_2_verify ? true: false}  onClick={ ()=> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.name_match == false ? this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers","alt_biz_mobile_num_2",null,"alternate_mobile_num_2_verify",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value ,"alt2_serv_prvdr_code") : this.props.verify_handler("mobile_num_verify", "alternate_mobile_num_2_verify")} }>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2_name_match == false ? "Verify Number" : "Validate"}</button>
                        <button type="button" class="btn btn-primary " onClick={()=>this.props.audit_kyc_confirm_identity("Alternate Mobile Number 2 ",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value, this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.holder_name,"alt_biz_mobile_num_2")}  disabled={this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_2_verify ? false: true} >Verify Identity</button>
                        </>
                        }
                        </>

                        :
                            <>
                            <button type="button" class="btn btn-primary"  onClick={()=>this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers","alt_biz_mobile_num_2",null,"alternate_mobile_num_2_verify",this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.alt_biz_mobile_num_2.value,"alt2_serv_prvdr_code")}>Verify Name</button>
                            <button type="button" class="btn btn-primary " disabled={this.props.biz_mobilenumbers_sdata.active_section.alternate_mobile_num_2_verify? false:true}>Verify Identity</button>
                            </>
                        
                        }

                     </div>:''
                     }
                    </fieldset>
                </div>


                </div> 
                }


                  </div>
                 
                  <div className='col-lg-12 py-3 mt-3 ' style={{background:' #01062c'}}>
                    <h2 className='text-white text-center'>
                    Additional Mobile Number
                    </h2>
                    </div>

                
                  <div className='col-lg-10 col-md-12' >
                  <div className={`row no-margin justify-content-center justify-content-around ${(this.props.biz_mobilenumbers_sdata.audit_kyc_mobile_numbers || this.props.biz_mobilenumbers_sdata.audit_kyc_confirm_identity) ? "disable_div" :""} `}>
                 
                  

                   

                    <div className='col-md-12 col-lg-6  py-3'> 
                    {this.props.biz_mobilenumbers_sdata.old_cust_reg_json?.mobile_numbers?.addl_num.map((item,idx)=>{return(
                    <>
                   

                    <div className='col-lg-12  px-5 py-3 ' style={{}}>
                            {idx==0 &&
                          <h2 className='text-white p-2 px-3  mb-3  old_json_text_bg_color text-center'> <b>Add Mob Num (As Per KYC)</b></h2>
                            }

                         <div  className='audit_kyc_monbile_num_border old_json_text_bg_color mt-4' style={{background:'#4a5579',minHeight:`${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"old_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers")  ? "389px" : "360px"}`}}>

                        <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Additional Mobile Number {idx+1} </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                        <div className='pointerr' id="pointerr">
                          <p className='text-white ' style={{margin:'10px 5px 0px 0px',fontSize:'15px',textAlign:'end'}}><b>Manually Verified</b> </p>
                        </div>

                      {item.mobile_num.value!=null ? 
                       <div className='p-3 py-3 mt-5'>
                       <div className='d-flex align-items-center justify-content-between my-2 mx-2'>
                         <div className='d-flex align-items-center'>


                              <div className='' >
                                {item.serv_prvdr.value!=null?
                                <div className={`d-flex align-items-center ` }>
                                 <div  className={` ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'} ` } >
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                                <AiFillEdit className='text-white pointer ml-2' size={15} onClick={()=>{this.props.previous_comments_section(`addl_num_${idx+1}_opertator`)}}/>
                                </OverlayTrigger>
                                 </div>
                                <img className= {`ml-1 p-1 pointer  `} src={`/img/${item.serv_prvdr.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>

                                </div>
                                :<p className='text-white no-margin'>No Data</p>
                               }
                                {this.props.biz_mobilenumbers_sdata[`addl_num_${idx+1}_opertator`] && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                                <div>
                                  {this.props.operators_selection( item.serv_prvdr.value,`addl_num_${idx}` ,`addl_num_${idx+1}_opertator`, `Additional Mobile Number ${idx+1}`,item.mobile_num.value)}
                                </div>
                                }
                              </div>


                               {/* <img src={`/img/${item.serv_prvdr.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/> */}
                               {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                                <p className= {`pl-4 no-margin audit_kyc_text text-white  `}> <b>{item.mobile_num.value}</b></p>
                                {this.state.lead_num_verify_call && 
                                <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                                  <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(item.mobile_num.value)}>
                                    <FaPhoneAlt style={{color:"white"}}/>
                                  </div>
                                </div>
                                }
                          </div>
                          <div className='text-center'>
                         
                            <p className='no-margin px-2 py-2 audit_kyc_person_icon' style={{display:'inline-block'}}><MdPersonOutline size={30}/></p>
                            <p className={`no-margin text-white pl-2  `} > {dd_value(item.relation.value)}</p>
                          </div>
                       
                       </div>
                       <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM </p>
                       <div className='d-flex align-items-center  mx-2  '>
                           <p className= {`no-margin px-3 py-2 audit_kyc_text_name `} style={{background:`${item.name_match ? "green":"#dcdde4"}`}}><b> {item.name.value}</b>  </p>
                       </div>
                        {(item.hasOwnProperty('holder_name') )?
                           <div className='mt-3'>
                              <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>
                             <div className='d-flex align-items-center justify-content-between mb-2'>
                             <div className='d-flex align-items-center mx-2 '>
                               <p className= {`no-margin px-3 py-2 audit_kyc_text_name  `} style={{background:`${item.name_match ? "green":"#dcdde4"}`}}><b> { item.holder_name}</b> </p>
                             </div>
                               <div className='pl-3'> {(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.addl_num[idx][`verified_addl_mobile_num_${idx}`]==1 || this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_verify`])? < GoVerified size={30} color={"#1dd61d"}/> : ((this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.addl_num[idx][`rejected_addl_mobile_num_${idx}`]==1) &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                             </div>
                            
                             {((this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.addl_num[idx][`verified_addl_mobile_num_${idx}`]==1) || (this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.addl_num[idx][`rejected_addl_mobile_num_${idx}`]==1)) ?"":
                            <div className='d-flex  pl-2 align-items-center'>
                              <p className='no-margin text-white'>{(this.props.biz_mobilenumbers_sdata.old_cust_reg_json.mobile_numbers.addl_num[idx].name_match==true )? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_mismatch_reason`])}) </p>}</p>
                              {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                            </div>
                           }
                           </div>
                             :
                             <>
                             <div className='pt-5'>
                        <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>

                        <p className='text-white no-margin audit_kyc_addl_text pl-2 py-2'> NA</p>
                       
                        </div>
                        
                             </>
                          }
   
                      </div>
                      :   <div className='d-flex align-self-center text-white justify-content-center text-center float_acc_balance_view_bttn' style={{fontSize:'18px'}}>
                      NA
                       </div> }
                         </div>
                     
                        
                     </div>
                     
                      
                      </>
                     )})}
                    </div>
                       
                    <div className={`  py-3 col-md-12 col-lg-6  `}> 
                    {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num.map((item,idx)=>{return(
                    <>
                          <div className='col-lg-12  px-5 py-3 ' >
                            {idx==0 &&
                          <h2 className='text-white p-2 px-3  mb-3  new_re_kyc_text_bg_color text-center'> <b>Add Mob Num (As Per Re-KYC)</b></h2>
                            }

                        {/* <fieldset className= {`${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers") ? ` ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers")+"_field_set"}`:"" } ` }>
                         { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers") ? <legend className="legend_rekyc"> <b> { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers")} </b></legend> :""} */}

                      <fieldset className= {`mt-3 ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers") ? ` ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56',minHeight:`${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers") ? "395px":"363px"}`}}>
                      { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers") ?
                      <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers")+"_text_color"}  `}> 
                      <b> { this.rekyc_modify_checking(this.props.biz_mobilenumbers_sdata,"new_cust_reg_json","mobile_numbers","mobile_num","addl_num",idx,"biz_mobile_numbers")} </b></legend> :""}
                      

                         <div  className='audit_kyc_monbile_num_border '  style={{background:'#4a5579'}}>
                        <p  className="no-margin audit_kyc_text py-3 px-4 text-center text-white" style={{color:'black'}}>Additional Mobile Number {idx+1} </p><hr className='no-margin audit_kyc_monbile_num_border'/>
                       <div className='p-3 py-3'>
                       <div className='d-flex align-items-center justify-content-between my-2 mx-2'>
                         <div className='d-flex align-items-center'>


                              <div className='' >
                                
                                <div className='d-flex align-items-center'>
                                {item.serv_prvdr.value !=null &&

                                 <div  className={`${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'} ${this.props.biz_mobilenumbers_sdata.active_section.verify ?'disable_div' :''}`} >
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>  
                                <AiFillEdit className='text-white pointer ml-2' size={15} onClick={()=>{this.props.previous_comments_section(`addl_num_${idx+1}_opertator`)}}/>
                                </OverlayTrigger>
                                 </div>
                                  }   
                                 {item.serv_prvdr.value !=null?
                                <img className= {`ml-1 p-1 pointer  `} src={`/img/${item.serv_prvdr.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>
                                :<p className='no-margin'>No Data</p>}
                                </div>
                                {this.props.biz_mobilenumbers_sdata[`addl_num_${idx+1}_opertator`] && this.props.biz_mobilenumbers_sdata.mobile_operators &&
                                <div>
                                  {this.props.operators_selection( item.serv_prvdr.value,`addl_num_${idx}` ,`addl_num_${idx+1}_opertator`, `Additional Mobile Number ${idx+1}`,item.mobile_num.value)}
                                </div>
                                }
                              </div>


                               {/* <img src={`/img/${item.serv_prvdr.value}_logo_color.png`}  style={{width:'60px',height:'30px'}}/> */}
                               {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                                <p className= {`pl-4 no-margin audit_kyc_text text-white  $`}> <b>{item.mobile_num.value}</b></p>
                                {this.state.lead_num_verify_call && 
                                <div  className={`${(((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) && !this.props.softPhoneState.lead_num_verify) ?"":'disable_div'}`}>
                                  <div id="dial-icon" className={"call-icon lead_call_icon ml-3"} type="button" onClick={()=>this.handleCall(item.mobile_num.value)}>
                                    <FaPhoneAlt style={{color:"white"}}/>
                                  </div>
                                </div>
                                }
                          </div>
                          <div className='text-center'>
                         
                            <p className='no-margin p-2 audit_kyc_person_icon' style={{display:'inline-block'}}><MdPersonOutline size={30}/></p>
                            <p className= {`no-margin text-white pl-2  `} > {dd_value(item.relation.value)}</p>
                          </div>
                       
                       </div>
                       <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Name as Entered by RM </p>
                       <div className='d-flex align-items-center  mx-2  '>
                           <p className= {`no-margin px-3 py-2 audit_kyc_text_name  `} style={{background:`${item.name_match ? "green":"#dcdde4"}`}}><b> {item.name.value}</b>  </p>
                       </div>
                        {(item.hasOwnProperty('holder_name') )?
                           <div>
                              <p className='text-white no-margin audit_kyc_addl_text pl-2 pt-2 py-2'>Retrieved Name</p>
                             <div className='d-flex align-items-center justify-content-between mb-2'>
                             <div className='d-flex align-items-center mx-2 '>
                               <p className= {`no-margin px-3 py-2 audit_kyc_text_name `} style={{background:`${item.name_match ? "green":"#dcdde4"}`}}><b> { item.holder_name}</b> </p>
                             </div>
                               <div className='pl-3'> {(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx][`verified_addl_mobile_num_${idx}`]==1 || this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_verify`])? < GoVerified size={30} color={"#1dd61d"}/> : ((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx][`rejected_addl_mobile_num_${idx}`]==1) &&<BiXCircle size={30} color={"red"}/> )  }  </div>
                             </div>
                            
                             {((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx][`verified_addl_mobile_num_${idx}`]==1) || (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx][`rejected_addl_mobile_num_${idx}`]==1)) ?"":
                            <div className='d-flex  pl-2 align-items-center'>
                              <p className='no-margin text-white'>{(this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx].name_match==true )? " Exact Name Match" : <p className='no-margin text-white'> Name Not match (Reason : {dd_value(this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_mismatch_reason`])}) </p>}</p>
                              {/* <BiCheck className='audit_bi-check pl-2' size={30}/> */}
                            </div>
                           }
                           </div>
                             :
                             <>
                              <div className='col-lg-12 p-3 d-flex mt-4 mb-4' style={{background:'#fe9f9f'}}>
                              <p className='no-margin pl-3' style={{color:'black'}}> <b className='pr-2' style={{color:'red'}}>Note</b> Unable to retrieve the name of the mobile number.</p>
                              </div>
                        
                             </>
                          }
   
                      </div>
                         </div>
                         <div  className={`  d-flex py-4  justify-content-around no-margin ${((this.props.biz_mobilenumbers_sdata.responseData.audited_by== get("user_id")) || (this.props.biz_mobilenumbers_sdata.responseData.audited_by==null )) ?"":'disable_div'}`}  >
                           {(item.hasOwnProperty('holder_name')) ?
                             <>
                             {((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx][`verified_addl_mobile_num_${idx}`]==1) || (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[idx][`rejected_addl_mobile_num_${idx}`]==1)) ? "" :
                             <>
                             {this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_verify`] ? "" :
                              <button type="button" class="btn btn-primary "  disabled={this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_verify`] ? true: false}  onClick={ ()=> {item.name_match == false ? this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers",`addl_num`,idx,`addl_mobile_num_${idx+1}_verify`,item.mobile_num.value) : this.props.verify_handler("mobile_num_verify", `addl_mobile_num_${idx+1}_verify`)} }>{item.name_match == false ? "Verify Number" : "Validate"}</button>
                             }
                          {/* <button type="button" class="btn btn-primary " onClick={()=>this.props.audit_kyc_confirm_identity(`Additional Mobile Number - ${idx+1} `,item.mobile_num.value, item.holder_name,`addl_mobile_num_${idx}`,"addl_num")} disabled={this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_verify`] ? false: true} >Verify Identity</button> */}
                             </>
                             }
                             </>
                             :
                               <>
                               <button type="button" class="btn btn-primary " onClick={()=>this.props.account_owner_name_handler(true,"audit_kyc_mobile_numbers",`addl_num`,idx,`addl_mobile_num_${idx+1}_verify`,item.mobile_num.value)}>Verify Name</button>
                               {/* <button type="button" class="btn btn-primary " disabled={this.props.biz_mobilenumbers_sdata.active_section[`addl_mobile_num_${idx+1}_verify`] ? false: true}>Verify Identity</button> */}
                               </>
                             
                           }
                       
                         </div>
                      </fieldset>

                         </div>

                      
                      </>
                     )})}
                    </div>
                
               
                  </div>
                  </div>
                </div>

               
                
                  </div>
              
                  <Modal size='lg' show={this.props.biz_mobilenumbers_sdata.audit_kyc_mobile_numbers} onHide={()=>this.props.audit_kyc_handleClose()} backdrop="static" keyboard={false}>
                     <Modal.Header className='no-margin' closeButton>
                     <Modal.Title>VERIFY NAME</Modal.Title>
                    </Modal.Header>
                  <Modal.Body>
                    {
                      <>
                      <div className='row justify-content-end no-margin'>
                        <div>
                          <img src={`/img/${this.props.biz_mobilenumbers_sdata.active_serv_prvdr&& this.props.biz_mobilenumbers_sdata.active_serv_prvdr }_logo_color.png`} style={{width:'60px',height:'30px'}}/>
                          <br/>
                          <p className='no-margin text-white'>{this.props.biz_mobilenumbers_sdata.validate_mob_num && this.props.biz_mobilenumbers_sdata.validate_mob_num }</p>
                        </div>
                      </div>
                      
                      <>
                      
                      { ((this.props.biz_mobilenumbers_sdata.section_key_type && ((this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num" ? (!this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].holder_name) : (!this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.hasOwnProperty( `${this.props.biz_mobilenumbers_sdata.section_key_type}_holder_name`))))) )? 
                      <div className='col-12 no-padding'>
                          <div className='d-flex row align-items-center no-margin py-3'>
                          <p className='no-margin col-4 text-white'> Name as Entered by RM</p>
                          <h2 className='px-3 col-8 py-2 audit_kyc_text text-white'  style={{background:'#464c56'}}> {(this.props.biz_mobilenumbers_sdata.section_key_type && this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num") ? <b> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].name.value }</b> : <><b> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value}</b></> }</h2>
                         </div>
                         {(this.props.biz_mobilenumbers_sdata.seconds[this.props.biz_mobilenumbers_sdata.retrival_loader_index ? this.props.biz_mobilenumbers_sdata.retrival_loader_index:3]!=0 && this.props.holder_num_verify()) ?
                         this.checkbox_spinner()
                         :
                         <>
                         <div className='row no-margin justify-content-center py-3'>
                          <div className='col-12'>
                          <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                           onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={(e)=>this.props.setAuditLeadState(e)} />
                          </div>
                       
                        </div>
                    
                        <div className='row justify-content-center no-margin pt-4 px-2' >
                          <div className='col-lg-12 p-3 ' style={{background:'#fe9f9f'}}>
                          
                           {/* <p className='no-margin pl-3' style={{color:'black'}}> <b className='pr-2' style={{color:'red'}}>Note</b> 1 .    
                           Unable to retrieve the mobile number holder name. <b>{this.props.biz_mobilenumbers_sdata.responseData.retrieval_line.length>0 ?this.props.biz_mobilenumbers_sdata.responseData.retrieval_line[this.props.biz_mobilenumbers_sdata.active_serv_prvdr].holder_name:"-"}</b> manages the phone that uses the SIM ( <b> {this.props.biz_mobilenumbers_sdata.responseData.retrieval_line.length>0?this.props.biz_mobilenumbers_sdata.responseData.retrieval_line[this.props.biz_mobilenumbers_sdata.active_serv_prvdr].number:"-"}</b>) to retrieve the name. You shall follow-up with <b>{this.props.biz_mobilenumbers_sdata.responseData.retrieval_line.length>0 ?this.props.biz_mobilenumbers_sdata.responseData.retrieval_line[this.props.biz_mobilenumbers_sdata.active_serv_prvdr].holder_name:"-"}</b> about this issue.</p> */}

                         <p className='no-margin pl-3' style={{color:'black'}}><b className='pr-2' style={{color:'red'}}>Note</b> 1.Alternatively, You can use the USSD code ( <b> {this.props.biz_mobilenumbers_sdata.ussd_code ? this.props.biz_mobilenumbers_sdata.ussd_code:''}</b>) from your <b>{this.props.biz_mobilenumbers_sdata.active_serv_prvdr?this.props.biz_mobilenumbers_sdata.active_serv_prvdr:"-"}</b> phone to retrieve and upload the screenshot manually.</p>
                           
                           <p className='no-margin pl-3' style={{color:'black'}}> 2. Once retrieved on your phone upload here.</p>

                          </div>
                        </div>

                         <div className='d-flex row align-items-center no-margin pt-3 '>
                          <p className='no-margin col-4 text-white'> Enter Name</p>
                          
                          <div class="form-group col-8 no-padding mt-4 mb-4">
                          <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.props.account_owner_name_handler(e.target.value,"register_mobile_holder_name",null,null,null)}/>
                          </div>  
                         </div>
                         <div className=' d-flex align-items-center no-margin justify-content-end'>
                         <div className=''>
                               <button type="button" class="btn btn-primary btn-sm " disabled={(this.props.biz_mobilenumbers_sdata.lead.account_holder_name_proof && this.props.biz_mobilenumbers_sdata.register_mobile_holder_name) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.biz_mobilenumbers_sdata.register_mobile_holder_name,`${this.props.biz_mobilenumbers_sdata.section_key_type =="addl_num"? `addl_num_${this.props.biz_mobilenumbers_sdata.section_active_index}` : this.props.biz_mobilenumbers_sdata.section_key_type}`,'account_holder_name_proof',this.props.biz_mobilenumbers_sdata.validate_mob_num ? this.props.biz_mobilenumbers_sdata.validate_mob_num:"")}>submit</button>
                          </div>
                          <div className='ml-3'>
                               <button type="button" class="btn btn-secondary btn-sm " onClick={()=>this.props.audit_kyc_handleClose()}>cancel</button>
                          </div>
                         </div>
                         </>
                         }
                      </div>
                      :
                      <>
                       {(this.props.biz_mobilenumbers_sdata.section_key_type && (this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num" ? (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].name_match==true) : ((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers[`${this.props.biz_mobilenumbers_sdata.section_key_type}_name_match`] ==true))))&&
                       <div>
                       <div className='d-flex row align-items-center no-margin py-3'>
                        <p className='no-margin col-4 text-white'> Name as Entered by RM</p>
                        <h2 className='px-3 col-8 py-2 audit_kyc_text text-white'  style={{background:'#464c56'}}> {(this.props.biz_mobilenumbers_sdata.section_key_type && (this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num")) ? this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].name.value :<b> <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value}</b></b>} </h2>
                       </div>

                       <div className='d-flex row align-items-center no-margin py-3'>
                        <p className='no-margin col-4 text-white'>Retrieved Name  </p>
                        <h2 className='px-3 col-8 py-2 audit_kyc_text text-white'  style={{background:'green'}}><b> { (((this.props.biz_mobilenumbers_sdata.section_key_type && this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num") ? this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].holder_name  : this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers[`${this.props.biz_mobilenumbers_sdata.section_key_type}_holder_name`]))} </b></h2>
                       </div>

                       <div className='d-flex align-items-center justify-content-end'>
                        <Button variant="secondary btn-sm" className='btn-sm'  onClick={()=>this.props.audit_kyc_handleClose()}>Cancel</Button>
                        <Button variant="primary" className='ml-4 btn-sm' onClick={()=>this.props.verify_handler("mobile_num_verify",this.props.biz_mobilenumbers_sdata.audit_kyc_validate_key)}> Submit</Button>
                        </div>
                       
                    </div>

                      }
                       
                      {(this.props.biz_mobilenumbers_sdata.section_key_type && (this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num" ? (this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].name_match==false) : ((this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers[`${this.props.biz_mobilenumbers_sdata.section_key_type}_name_match`]==false))))&&
  
                       <div>
                         <div className='d-flex row align-items-center no-margin py-3'>
                          <p className='no-margin col-4 text-white'> Name as Entered by RM</p>
                          <h2 className='px-3 col-8 py-2 text-white audit_kyc_text'  style={{background:'#464c56'}}>{(this.props.biz_mobilenumbers_sdata.section_key_type && (this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num")) ? this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].name.value :<b> <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value}</b> <b className='pl-3'> {this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value}</b></b>} </h2>
                         </div>

                          <div className='d-flex row align-items-center no-margin py-3'>
                          <p className='no-margin col-4 text-white'> { this.props.biz_mobilenumbers_sdata.modify_status ? "Enter Name" :'Entered Name' }</p>

                  
                          <div className={`d-flex align-items-center col-8  no-padding`}>
                          <input type='text' className={` md-form-group w-100 is-filled px-3  ${this.props.biz_mobilenumbers_sdata.modify_status &&'modify_input_bg is-focused'} audit_kyc_name_mismatch_text_color py-2 audit_kyc_text  `}  disabled={this.props.biz_mobilenumbers_sdata.modify_status?false:true} onChange={(e)=>this.props.account_owner_name_handler(e.target.value,"re_entered_name")}  value={(this.props.biz_mobilenumbers_sdata.hasOwnProperty('re_entered_name') ? this.props.biz_mobilenumbers_sdata.re_entered_name : ((this.props.biz_mobilenumbers_sdata.section_key_type && this.props.biz_mobilenumbers_sdata.section_key_type == "addl_num") ? this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers.addl_num[this.props.biz_mobilenumbers_sdata.section_active_index].holder_name  : this.props.biz_mobilenumbers_sdata.new_cust_reg_json.mobile_numbers[`${this.props.biz_mobilenumbers_sdata.section_key_type}_holder_name`]))}/>
                      
                        
                          {this.props.biz_mobilenumbers_sdata.modify_status==false && (this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason !="retrieved_franchise_name" ) &&
                           <BiEdit className='audit_kyc_modify_icon'  onClick={()=>this.props.modify_handler("modify_status",this.props.biz_mobilenumbers_sdata.audit_kyc_validate_key )}/>
                          }
                         
                          </div>
                    
                         
                         </div>


                             
                           {this.props.biz_mobilenumbers_sdata.modify_status==false &&
                            <>
                          <div className='d-flex row align-items-center no-margin py-4'>
                            <p className='no-margin col-lg-4 text-white'> Name Mismatch Reason </p>
                            <div className='col-lg-8 '>
                            <select id="holder_name_mismatch_reason text-white" className = "form-control col-md-12 col-lg-12"  value={this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason ? this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason: this.props.biz_mobilenumbers_sdata.active_section.mismatch_reason} type="text" required="required" onChange={(e)=>this.props.account_owner_name_handler(e.target.value,"reg_account_holder_name_mismatch_reason")} >
                            {this.getSelectOptions("holder_name_mismatch_reason")}
                            </select>
                            </div>

                            {this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason=="retrieved_franchise_name" &&
                              <div className='col-lg-12 px-0'>
                              <div className='row no-margin justify-content-center py-3'>
                              <div className='col-12'>
                                  <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                                onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.props.setAuditLeadState} />
                              </div>

                              </div>
                                <div className='d-flex row align-items-center no-margin pt-1 '>
                                <p className='no-margin col-4 text-white'> Enter Name</p>
                                
                                <div class="form-group col-8 no-padding mt-4 mb-4">
                                <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.props.account_owner_name_handler(e.target.value,"register_mobile_holder_name",null,null,null)}/>
                                </div>  
                               </div>

                               </div>
                            }
                          
                          </div>
                          <div className='row justify-content-center no-margin pt-5 px-4' >
                            <div className='col-lg-12 p-3 d-flex ' style={{background:'#fe9f9f'}}>
                            <h2 className='no-margin' style={{fontSize:'13px'}}> <b>Note</b> </h2>
                            {(this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason=="retrieved_franchise_name") ?
                            <p className='no-margin pl-3' style={{color:'black'}}>If the  SMS is shown on the mobile screen, please merge them into a single image using any photo editing software and upload it.</p>

                            :<p className='no-margin pl-3' style={{color:'black'}}>The name of the customer doesn’t match either with customer name nor business name.</p>
                          }</div>
                          </div>
                           </>
                          }

                        {this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason !="retrieved_franchise_name" ?
                        <div className='d-flex align-items-center mt-3 justify-content-end'>
                        <Button variant="secondary btn-sm" className='btn-sm'  onClick={()=>this.props.audit_kyc_handleClose()}> <b>Cancel</b></Button>
                        <Button variant="primary" className='ml-4 btn-sm' onClick={()=>this.props.biz_mobilenumbers_sdata.modify_status ? this.props.audit_kyc_modify_handler(this.props.biz_mobilenumbers_sdata.register_mobile_holder_name,`${this.props.biz_mobilenumbers_sdata.section_key_type =="addl_num"? `addl_num_${this.props.biz_mobilenumbers_sdata.section_active_index}` : this.props.biz_mobilenumbers_sdata.section_key_type}`,'account_holder_name_proof',true)  : this.props.verify_handler("mobile_num_verify",this.props.biz_mobilenumbers_sdata.audit_kyc_validate_key )} 
                        disabled={ this.props.biz_mobilenumbers_sdata.modify_status ?(this.props.biz_mobilenumbers_sdata.re_entered_name?false:true) :(this.props.biz_mobilenumbers_sdata.reg_account_holder_name_mismatch_reason ? false:true)}><b> {this.props.biz_mobilenumbers_sdata.modify_status? "Update":"Submit"}</b> </Button>
                        </div>
                        :
                        <div className=' d-flex align-items-center no-margin pt-3 justify-content-end'>
                        <div className=''>
                        <button type="button" class="btn btn-primary btn-sm " disabled={(this.props.biz_mobilenumbers_sdata.lead.account_holder_name_proof && this.props.biz_mobilenumbers_sdata.register_mobile_holder_name) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.biz_mobilenumbers_sdata.register_mobile_holder_name,`${this.props.biz_mobilenumbers_sdata.section_key_type =="addl_num"? `addl_num_${this.props.biz_mobilenumbers_sdata.section_active_index}` : this.props.biz_mobilenumbers_sdata.section_key_type}`,'account_holder_name_proof',this.props.biz_mobilenumbers_sdata.validate_mob_num ? this.props.biz_mobilenumbers_sdata.validate_mob_num:"")}>submit</button>
                         </div>
                         <div className='ml-3'>
                              <button type="button" class="btn btn-secondary btn-sm " onClick={()=>this.props.audit_kyc_handleClose()}>cancel</button>
                         </div>
                        </div>   

                        }
                        
                      
                      </div>
                      }
                      </>
                     }
                      </>
                  
                  
                      </>
                     }
                  
                   

                  </Modal.Body>

                  <Modal.Footer>
                  
                  </Modal.Footer>
                  </Modal>

                  <Modal show={this.props.biz_mobilenumbers_sdata.audit_kyc_confirm_identity} size={"xl"} onHide={()=>this.props.audit_kyc_handleClose()}   backdrop="static"  keyboard={false}>
                  <Modal.Header className='no-padding' closeButton show={this.props.biz_mobilenumbers_sdata.audit_kyc_confirm_identity} onHide={()=>this.props.audit_kyc_handleClose()}>
                      <Modal.Title style={{fontSize:'20px'}} className="bg-secondary p-2">{<p className='no-margin'>  Verify - {this.props.biz_mobilenumbers_sdata.call_log_title}</p>  }</Modal.Title><br/>
                  </Modal.Header>

                <Modal.Body style={{padding:'5px 10px'}}>      
                <div style={{border:'1px solid grey',margin:'15px',padding:'20px 30px'}}>
                        <br/>
                    <div className='row no-margin' >
                      <div className='col-md-9' style={{background:'#c4c7ca',borderRadius:'0px 20px 20px 0px'}}>
                    <div className='row' >
                     <div className='col-md-2 ' style={{background:'#59687c',borderRadius:'0px 20px 20px 0px'}}>
                     <p className='no-margin text-white agreementPersonalDetailstext' style={{padding:`${(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" ) ? "30px 10px" : "20px 10px"}`}}> <b>Step 1</b></p>

                     </div>
                     <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                     <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{color:'black'}}> Please dial the number and verify who picks the call </p>
                     </div>
                  
                     </div>
                      </div>
                      <div className='col-1'></div>
                     <div className='col-md-2 align-self-center'>
                     <p className='no-margin text-white agreementPersonalDetailstext'> <BiPhoneCall size={25} color={'white'} style={{paddingRight:'5px'}}/> {this.props.biz_mobilenumbers_sdata.mobile_num_value}</p>
                     </div>
                    </div>

                  
                    <br/><br/>

                    <div className='row no-margin' >
                      <div className='col-md-9' style={{background:'#c4c7ca',borderRadius:'0px 20px 20px 0px'}}>
                    <div className='row' >
                     <div className='col-md-2' style={{background:'#59687c',borderRadius:'0px 20px 20px 0px'}}>
                     <p className='no-margin text-white agreementPersonalDetailstext' style={{padding:`${(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" ) ?   "30px 10px" : "20px 10px"}`}}> <b>Step 2</b></p>

                     </div>
                     <div className='col-md-10 align-self-center'>
                        {(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" )?
                        <>
                         <p className='no-margin  agreementPersonalDetailstext  ' style={{color:'black'}} >If recipient is not related to <b className='pr-2'>{this.props.biz_mobilenumbers_sdata.active_holder_name}</b>
                        click <b>Reject</b>   button</p>
                        {/* <p className='no-margin  ' style={{color:'black',fontSize:'17px',paddingBottom:'6px'}}> <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.national_idfirst_name.value +" "+ this.props.cust_reg_json.owner_person.last_name.value }</b> </p> */}
                        </>
                        :
                        <p className='no-margin  agreementPersonalDetailstext  ' style={{color:'black'}} >If it is <b>{this.props.biz_mobilenumbers_sdata.active_holder_name}</b> click <b>VERIFY</b>    button</p>

                        }
                      
                     </div>
                  
                     </div>
                      </div>
                      <div className='col-1'></div>
                     <div className='col-md-2 align-self-center'>
                    
                      <p className='no-margin text-white agreementPersonalDetailstext'>
                        {(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" )?

                          <button type="button" class="btn btn-danger"  data-dismiss="modal" onClick={()=>this.props.callLogRejectHandler()} >Reject</button>

                          :
                          <button type="button" class="btn btn-info" data-dismiss="modal"  onClick={()=>this.props.audit_kyc_submit_callLog()}>Verify</button>
                        }
                      </p>
                     
                    
                     </div>
                    </div>

                    <br/><br/>
                       <div className='row no-margin' >
                       <div className='col-md-9' style={{background:'#c4c7ca',borderRadius:'0px 20px 20px 0px'}}>
                     <div className='row' >
                      <div className='col-md-2' style={{background:'#59687c',borderRadius:'0px 20px 20px 0px'}}>
                      <p className='no-margin text-white agreementPersonalDetailstext' style={{padding:`${(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" ) ?  "30px 10px" : "20px 10px" }`}}> <b>Step 3</b></p>
                      </div>
                      <div className='col-md-10 align-self-center'>
                        {(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" )?
                            <>
                                                    
                            <div className='row d-flex no-margin  align-items-center' style={{paddingTop:'5px'}}>
                              <p className='no-margin  agreementPersonalDetailstext ' style={{paddingRight:'25px',color:'black'}}>The Recipient's name is </p> 
                              <input type="text"  style={{width:'300px',color:'black'}} class="form-control"  placeholder= "Enter Name" id="exampleInputEmail1"
                                onChange={(e)=>this.props.account_owner_name_handler(e.target.value,"recipents_name")} autocomplete="off" aria-describedby="emailHelp" />
                              
                              <p className='no-margin  agreementPersonalDetailstext ' style={{paddingLeft:'25px',color:'black'}}>and he / she is </p> 
                            </div>
                            <div className='row d-flex  no-margin  align-items-center' style={{paddingBottom:'10px'}}>
                            <div>
                                
                              <select  id="csf_type" className="form-control select_option_bg" style={{width:'200px',color:'black'}}  onChange={(e)=>this.props.account_owner_name_handler( e.target.value,"recipents_relation")}>
                               {this.getSelectOptions("relation_with_owner")}
                              </select>
                              </div>	
                              <p className='no-margin  agreementPersonalDetailstext ' style={{paddingLeft:'25px',color:'black'}}>to   <b>{this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.first_name.value +" "+ this.props.biz_mobilenumbers_sdata.new_cust_reg_json.id_proof.last_name.value }</b>  click <b>SUBMIT .</b> </p> 
                             </div>

                            </>

                          :
                          <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{color:'black'}} >If the information mismatches click <b>REJECT</b> button</p>   

                        }
                      </div>
                   
                      </div>
                       </div>
                       <div className='col-1'></div>
                      <div className='col-md-2 align-self-center'>
                      {(this.props.biz_mobilenumbers_sdata.call_log_key_type && this.props.biz_mobilenumbers_sdata.call_log_key_type == "addl_num" )?
                      <button type="button" class="btn btn-info" data-dismiss="modal" disabled={this.props.biz_mobilenumbers_sdata.recipents_name && this.props.biz_mobilenumbers_sdata.recipents_relation ?false:true} onClick={()=>this.props.audit_kyc_submit_callLog()}> Submit </button>

                      :
                      <p className='no-margin text-white agreementPersonalDetailstext'>
                         <button type="button" class="btn btn-danger"  data-dismiss="modal" onClick={()=>this.props.callLogRejectHandler()} >Reject</button>
                         </p>  
                      }
                           
                      </div>
                       </div>
                       

                    <br/>
                   
                    <br/>

                    </div>
                </Modal.Body>

         
                 </Modal> 
                 </>
                 </>}
                 </>}
            </div>
            }
      
            {
              this.props.softPhoneState.lead_num_verify && 
              <Softphone screen_from ={"lead_num_verify"} is_direct_outgoing = {true} lead_id={this.props.lead_id} outgoing_call_data = {this.props.softPhoneState.outgoing_call_data}/>
            }
           
            </div>

        )        
     }
}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RekycMobileNumberVerification));