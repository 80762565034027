import { Box, useTheme } from "@mui/material";
import { HeaderVarientH3Typo } from "../../CommonComponent/typography";
import BorderContainer from "./container/borderContainer";

/**
 *
 * @param param0 text (shown while loading)
 * @returns center aligned loading text
 */
const AuditDashboardInfoUI = ({
  text,
  height,
}: {
  text: string;
  height: number;
}) => (
  <BorderContainer>
    <VerticallyCenterAlignedInfo height={height} text={text} />
  </BorderContainer>
);

export default AuditDashboardInfoUI;

export const VerticallyCenterAlignedInfo = ({
  height,
  text,
}: {
  height: number;
  text: string;
}) => {
  // hook
  const theme = useTheme();
  return (
    <Box
      sx={{
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HeaderVarientH3Typo
        text={text}
        overRideStyles={{
          color: theme.palette.text.primary,
          fontWeight: 700,
          fontFamily: "Poppins",
        }}
      />
    </Box>
  );
};
