import React from 'react';
import Sms_modal from '../loan/container/Sms_modal';
import { Accordion, Button, Card, Modal, Spinner } from 'react-bootstrap';
import { ExcesstimeAction, TooltipLabel } from '../common/partial';
import { Homeacc_prvdrLogoselector, dynAccPrvdrFilter, title_case } from '../../helpers/common_helper';
import { Link } from 'react-router-dom';
import { listAccountProvider } from '../../actions/account_provider_actions';
import { Config, check_priv, get, set } from '../../helpers/storage_helper';
import { searchLoan } from '../../actions/loan_actions';
import CallLog from '../call_log/component/CallLog';
import FlowComponent from '../common/component/core/FlowComponent';
import { dd_value } from '../../actions/common_actions';
import { BsChevronDown } from 'react-icons/bs';
import { BasicReactTable } from '../../helpers/react_table';
import { lbl_amt } from '../../helpers/product_helper';
import{setValuesIntoRedux} from '../../Redux/Slices/CommonSlice';
import { connect } from 'react-redux';
import Softphone from '../cs_management/container/cs_soft_phone';



class PendingWithCustomer extends FlowComponent {
    state = {
        dynamic_filter: {
            dyn_Btn_selected: [],
            all_Btn_status: false,
            filter_actions: {},
            copy: [],
        },
        refresh: false,
        rowdatas: [],
        columndatas: [],
      
    }

    handleToggle = (e) => {

        switch (e) {
            case ("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
            case ("retry_modal") :
                this.setState({retry_modal:false});
                break
        }
    }

    componentWillMount() {

        const ls_market = get('market')
        const acc_prvdr_support_ussd = Config("acc_prvdr_support_ussd")
        this.setState({ acc_prvdr_support_ussd })
        var req = { country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: "" };
        listAccountProvider({ ...req, status: 'enabled', biz_account: true })
            .then((response) => {
                if (!response) { return };
                if (response.status === "success") {
                    let acc_prvdrs = []
                    response.data.list.map(each =>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                    this.setState(state => state.all_Acc_pvdrs = acc_prvdrs)
                    if (this.props.type == "pending_w_cust") {
                        this.pendingCustList(acc_prvdrs)

                    }
                    set("acc_pvdrs", acc_prvdrs)
                }
            });


    }

    pendingCustList = (acc_prvdrs = this.state.all_Acc_pvdrs) => {
        const fa_rm_approval_late1 = Config("fa_rm_approval_late1")
		const fa_rm_approval_late2 = Config("fa_rm_approval_late2")
		const fa_late1 = Config("fa_late1")
		const fa_late2 = Config("fa_late2")
		const fa_cust_late1 = Config("fa_cust_late1")
		const fa_cust_late2 = Config("fa_cust_late2")

        let loan_search_criteria = {pending_w_cust: 'true', mode : 'search', alert: false}
				  searchLoan(this.req({loan_search_criteria, home_table : true}))
                  .then((response) => {
                    if (!response) { return }
                    if (response.status == "success") {
                        this.setState({ columndatas: this.columndatas(), server_time:response.server_time ,fa_rm_approval_late2,fa_late2,fa_cust_late2})
                        this.setDatas(response, acc_prvdrs)
                    }
                })
				// this.setState(state => state.sortVariable = "loan_doc_id")
				// if(resp && resp.status === 'success'){ data = resp.data.results }	
        // pending_cashback_list(this.req())
            // .then((response) => {
            //     if (!response) { return }
            //     if (response.status == "success") {
            //         this.setState({ columndatas: this.columndatas() })
            //         this.setDatas(response, acc_prvdrs)
            //     }
            // })


            
    }


    setDatas(response, acc_prvdrs) {
        const market = get('market')
        const market_name = market.country_code
        console.log('results', response.data.results)
        if (response.data.results.length > 0 || response.data.results.length === 0) {
            this.setState(state => state.all_count = response.data.results.length)
            this.setState({ rowdatas: response.data.results },()=>{
                const newArr = get([market_name] + '_' + `${this.props.type}`) === undefined ? this.state.all_Acc_pvdrs : get([market_name] + '_' + `${this.props.type}`)
                acc_prvdrs.map(each => {
                    const count = (response.data.results).filter(indiv => indiv.acc_prvdr_code === each)
                    const length = count.length
                    this.setState(state => state['dynamic_filter']['filter_actions'][each] = [{ count: 0 }, { status: newArr.includes(each) }],()=>{
                        this.dynClickhandler(this.state.dynamic_filter.filter_actions[each][1].status, each,response.data.results )
    
                    })
                    this.setState(state => state['dynamic_filter']['filter_actions'][each][0].count = length)
    
                })
            })

            
        }
    }

    
    dynClickhandler(key, val , row_data =this.state.rowdatas) {
        var datas = dynAccPrvdrFilter(key, val, row_data, this.state.all_Acc_pvdrs, this.state.dynamic_filter.dyn_Btn_selected, this.state.dynamic_filter.filter_actions, this.state.dynamic_filter)
        this.setState({ dynamic_filter: datas })
    }


    remove_state_value(){
        
        this.setState({customer_data:null})
    }
  

    columndatas() {
        return [
            {
                Header : "A/C Number",
                sortable: false,
                maxWidth:250,
                id: 'acc_number',
                accessor: row =>  <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" >{row.acc_number}</Link>,

            },
            {
                Header: 'Channel',
                id: 'channel',						
                sortable: false,
                accessor: row => {return <p className='text-light text-uppercase '>{row.channel?title_case(row.channel):''}</p>}
            },
            {
                Header : "ACC PRVDR",
                id: 'acc_prvdr_code' ,
                sortable: false,
                maxWidth:85,
                accessor:row => <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/>
            },
            {
                Header: 'FA ID',
                id: 'loan_doc_id',						
                sortable: false,
                accessor: row => <Link to={"fa/view/"+row.loan_doc_id} target="_blank" >{row.loan_doc_id}</Link>
            },
            // {
            // 	Header : "Mobile Number",
            // 	sortable: false,
            // 	id: 'cust_mobile_num',
            // 	accessor: row => {return row.cust_mobile_num}
            // },
            {
                Header : <>Biz Name & 
                            <br/>Mobile Number
                         </> ,
                id: 'biz_name',
                // sortMethod: (a,b) => this.sortBiz(a,b),
                accessor: row => <div>
                                    <TooltipLabel full_text={row.biz_name} overlay={row.cust_name} no_reduce={true} />
                                    <label className='no-margin d-block'>{row.cust_mobile_num}</label> 
                                </div>
            },{
                Header : ()=>(<div className='tableAmount_rightAlign' style={{paddingRight:15}}>FA Amount</div>),
                id: 'loan_principal',							
                // sortMethod : (a,b) => this.sortAmount(a,b),	        			
                accessor: row => <div className='tableAmount_rightAlign' style={{paddingRight:15}}>{lbl_amt(row.loan_principal,this.currency_code)}</div>
            },
            {
                Header : "Applied & Approved",									
                id: 'loan_appl_date',
                minWidth:210,
                maxWidth:275,
                // sortMethod: (a,b) => this.sortTime(a,b),
                accessor:row => <div>
                                    <span>Applied : <ExcesstimeAction data={row} timedata={this.state.server_time} item={"applied"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span><br/>
                                    <span>Approved : <ExcesstimeAction data={row} timedata={this.state.server_time} item={"approved"} time_limit={[this.state.fa_cust_late1,this.state.fa_cust_late2]}/></span>
                                </div> 
            },
            // {
            // 	Header : "RM Approved",	
            // 	id:'loan_approved_date',
            // 	width: 120,
            // 	sortable: false,											
            // 	accessor:row => <ExcesstimeAction data={row} timedata={this.state.server_time.server_time_db} item={"approved"} time_limit={[this.state.fa_cust_late1,this.state.fa_cust_late2]}/>
            // },
            // {
            // 	Header : "No. of Conf. Codes Sent",
            // 	accessor: 'otp_count',
            // 	sortable: false,
            // },
            
            {
                Header : ()=>(<div className={'tableAmount_centerAlign'}>Next Action</div>),
                id: 'action',
                width: 190,
                Cell: cell => <div>
                    {check_priv("cs_mgnt", "soft_phone")  && this.country_code == 'UGA' ? 
                                <div className='text-center'>
                                    
                                     {(this.props.softPhoneState.pending_cust_loader && this.state.customer_data && this.state.customer_data.cust_id ===  cell.row.original.cust_id )  ?
                                      <Button variant="primary" className='px-3' disabled>
                                      <Spinner as="span"   animation="border"  size="sm" role="status"  aria-hidden="true"  />  Loading... 
                                      </Button>
                                     :
                                    <Button className={`${this.props.softPhoneState.pending_cust_loader ?"disable_div": ""} btn-std1`}  onClick={() => {this.call_to_customer(cell.row.original)}} 
                                    >  
                                   Get Phone Confirmation
                                    </Button>

                                     }
                                </div> 
                    :
                     <ExcesstimeAction data={cell.row.original} timedata={this.state.server_time} item={"next_action_conf"} action={this.props.handleOpen} time_limit={[this.state.fa_late1,this.state.fa_late2]}/> }
                     </div>
            },
            {
                Header: 'SMS Logs',
                id: 'sms_logs',						
                sortable: false,
                Cell: cell => <Sms_modal btn_style={'btn-sm'} view={'home'} loan_doc_id={cell.row.original.loan_doc_id}/>
            },]
    }

    call_to_customer(data) {
        console.log('data', data)
        this.setState({customer_data:data})

        this.props.setValuesIntoRedux({cust_id:data.cust_id,pending_cust_loader : true ,pending_cust_details:data})
      }

    render() {
        const soft_phone_modal = (!this.props.softPhoneState.isIncomingCall && !this.props.softPhoneState.outgoingCall ) 
        return (

            <Accordion  >
                <Card>
                {this.props.type == 'pending_w_cust' && <Modal dialogClassName= "rec-form-modal" show={this.state.modal} onHide={this.handleClose}>
                    <Modal.Body closeButton>
						<CallLog is_conf match={{params: {id: this.state.call_id, cust_id: this.state.call_cust_id}}} onComplete={() => window.location.reload()} onCancel={() => this.setState({modal: false})}/>
						</Modal.Body>
				</Modal>}

                {this.props.softPhoneState.pending_cust_details && this.props.type == "pending_w_cust" && soft_phone_modal &&( get('market').country_code =='UGA') &&
						<div className='Call_log_section zoomIn_animation'>
						<Softphone screen_from ={"pending_w_cust"} is_direct_outgoing = {true} cust_id={this.props.softPhoneState.pending_cust_details.cust_id ?this.props.softPhoneState.pending_cust_details.cust_id :null } outgoing_call_data = {this.props.softPhoneState.pending_cust_details} remove_state_value = {()=>{this.remove_state_value()}}/>
						</div>
					}

                    <div className={`${'non_empty_display_list'} `}>
                        <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                            <h4 className="card-title text-white tableHead_font title-bottom "> FAs Pending Customer Confirmation</h4>
                            {this.state.rowdatas &&this.state.rowdatas.length > 0 &&
                                <>
                                    <div className='countREL card-title'>
                                        <button className={'filterBtns allBtn_size'} onClick={() => this.dynClickhandler(!this.state.dynamic_filter.all_Btn_status, "ALL")} style={{ filter: !this.state.dynamic_filter.all_Btn_status ? "opacity(0.5)" : '' }}>ALL</button>
                                        <div className='countABS' style={{ backgroundColor: !this.state.dynamic_filter.all_Btn_status ? "lightgrey" : '' }}>
                                            <label>{this.state.rowdatas.length}</label>
                                        </div>
                                    </div>
                                    {this.state.all_Acc_pvdrs && this.state.all_Acc_pvdrs.map((each, idx) =>
                                        <div key={idx} className='countREL card-title'>
                                            <>
                                                <div style={{ width: 60, height: 30 }} className="filterBtns" onClick={() => this.dynClickhandler(!this.state.dynamic_filter.filter_actions[each][1].status, each)}>
                                                    <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{ filter: this.state.dynamic_filter.filter_actions[each] && !this.state.dynamic_filter.filter_actions[each][1].status ? "opacity(0.5)" : '' }}></img>
                                                </div>
                                                <div className='countABS' style={{ backgroundColor: this.state.dynamic_filter.filter_actions[each] && !this.state.dynamic_filter.filter_actions[each][1].status ? "lightgrey" : '' }}>
                                                    <label>{this.state.dynamic_filter.filter_actions[each] && this.state.dynamic_filter.filter_actions[each][0].count}</label>
                                                </div>
                                            </>
                                        </div>
                                    )}
                                </>
                            }
                            <Accordion.Toggle className="col" as={Card.Header} eventKey="0" onClick={() => this.handleToggle("Account")}>
                                <BsChevronDown className={this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18" />
                            </Accordion.Toggle>
                        </div>
                    </div>
                    <div className="card-body no-padding">
                        {this.state.rowdatas && this.state.rowdatas.length > 0 && this.state.dynamic_filter.copy && this.state.dynamic_filter.copy.length>0 &&
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className='flagg_cust_table react_common_table'>
                                        {/* <BasicReactTable className='border' hidetooltiptitle={true} row_data={this.state.dynamic_filter.copy ? this.state.dynamic_filter.copy : []} column_data={this.state.columndatas} global_search={false} pagination={true} excess_time_action={false} default_page_size={10} /> */}

                                        <BasicReactTable  row_data={this.state.dynamic_filter.copy ? this.state.dynamic_filter.copy : []} column_data={this.state.columndatas} pagination={this.state.dynamic_filter.copy.length>9 } default_page_size = {10}    excessTimeaction={true}
										appliedRowblink={false} appliedRowblinktime={this.state.fa_rm_approval_late2} approveRowblinktime={this.state.fa_late2} csv_file = {false}  blink={ false} timeServer={this.state.server_time} />  

                                    </div>

                                </Card.Body>
                            </Accordion.Collapse>
                        }
                    </div>
                </Card>
             
            </Accordion>
        );

    }
}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default connect(mapStateToProps, mapDispatchToProps)(PendingWithCustomer);



