import { useTheme } from '@emotion/react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import CustomFlag from '../../UI/common/component/CustomFlag';

const CustomDropdown = ({ label = false, style = {}, contains_img= false , menu_items=[] , default_value = null , onChange}) => {

  const [dropdownVal, setdropdownVal] = React.useState(default_value);

  const handleChange = (event) => {
    setdropdownVal(event.target.value);
    onChange(event.target.value)
  };
  const theme = useTheme()

  const dropdown_style = {

    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.custom_colors.secondary_a_400,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.custom_colors.secondary_a_400,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.custom_colors.secondary_a_400,
    },
    '.MuiSvgIcon-root ': {
      fontSize: '30px'
    }

  }

  return (
    <div>
      <FormControl sx={style}  size="small">
        {label && <InputLabel id="demo-select-small-label">{label}</InputLabel>}
        <Select
          value= {dropdownVal}
          onChange={handleChange}
          sx={dropdown_style}
        >
        {menu_items.length>0 && menu_items.map((item,idx)=>{return(
          <MenuItem value={item.id}>
            <Box alignItems="center" justifyContent="space-between">
            {contains_img &&
            <CustomFlag country_code={item.id} height={30} width={30} />
            }
            <span className='mx-2'>{item.label} </span>
            </Box>
          </MenuItem>
          )})
         }
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomDropdown;