import { useEffect, useState } from "react"
import { setScreachedFields } from "../../../Redux/Slices/CommonSearchSlice"
import { useDispatch, useSelector } from "react-redux"
import { FiX } from "react-icons/fi";


const CommonFilterShow = (props) => {

    const dispatch = useDispatch()
    const common_search_data = useSelector(state => state.CommonSearch)

    

    const handleCancelFilter = (event, id, type, data) => {
        let common_data = { ...common_search_data[props.searched_filter_id] };
    
        if (type !== 'multi_select') {
            if (common_data[id]) {
                delete common_data[id];
            }
        } else {
            if (common_data[id]?.selected_data?.value) {
                let commonDataClone = { 
                    ...common_data[id], 
                    selected_data: { 
                        ...common_data[id].selected_data, 
                        value: [...common_data[id].selected_data.value] 
                    } 
                };
            
                let filter_data = commonDataClone.selected_data.value.filter(val => val.value !== data);
            
                if (filter_data.length > 0) {
                    commonDataClone.selected_data.value = filter_data;  
                    common_data[id] = commonDataClone;  
                } else {
                    delete common_data[id]; 
                }
            }
        }
    
        const key = props.searched_filter_id;
        const value = common_data;
    
        dispatch(setScreachedFields({ key, value }));
        event.target.id = "searchbtn"
        setTimeout(() => {
            props.call_search_api(event); 
        }, 0);
    };

    const showListOfFilters = (filter_name, data, filter_type, key) => {
        return(
            <div className="filter_box">
                <span className="filter_label">{filter_name} - </span>
                <span className="filter_value">{data.label}</span>
                <FiX className="filter_cancel" onClick={(e) => handleCancelFilter(e, key, filter_type, data.value)} />
            </div>
        )
        
    }
    const searchedFilterData = common_search_data[props.searched_filter_id] ? common_search_data[props.searched_filter_id] : {}

    return(
        <>
            {props.searched_filter_id && (
                <div className="row">
                    <div className="col-auto mt-2">
                        <p className='no-margin text-white'><b>Total Entries: {props.total_count}</b></p>
                    </div>

                    {Object.keys(searchedFilterData).map((key, i) => {
                        if (props.excepted_filters && props.excepted_filters.includes(key)) {
                            return null;
                        }

                        const filterData = searchedFilterData[key];
                        const filterType = filterData.type;

                        if (filterType === 'multi_select' && filterData.selected_data && filterData.selected_data.value) {
                            return filterData.selected_data.value.map((item, index) => (
                                <div className="col-auto" key={`${key}_${index}`}>
                                    {showListOfFilters(filterData.filter_name, item, filterType, key)}
                                </div>
                            ));
                        }else{
                            return (
                                <div className="col-auto" key={key}>
                                    {showListOfFilters(filterData.filter_name, filterData.selected_data ?? {}, filterType, key)}
                                </div>
                            );
                        }
                        
                    })}
                </div>
            )}
        </>
    )
}

export default CommonFilterShow