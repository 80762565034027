    import React, { useEffect, useState } from 'react';
    import { BasicReactTable } from '../../../helpers/react_table';
    import DatePicker from 'react-datetime';
    import { dd_value, getNameList } from '../../../actions/common_actions';
    import moment from 'moment';
    import {Form, Col , Row, Button,checkbox ,Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
    import { AiOutlineClose } from 'react-icons/ai';
import { submit_handler } from '../../../actions/lead_actions';
import { getSelectOptions, req, setOptionsToStateFromApi } from '../../../helpers/common_helper';
import { cs_activities } from '../../../actions/call_log_actions';
import { check_priv, get } from '../../../helpers/storage_helper';

    const CsRmsoActivityReport = () => {

    const title_name = check_priv('cs_mgnt', 'auditor_activity_report') ? "Auditor" : "CS"
    const [column_datas,setcolumn_datas] =  useState(null);
    const [activity_datas,setactivity_datas] =  useState(null);
    const [row_datas,setrow_datas] =  useState([]);

    const [submit_obj,setsubmit_obj] =  useState({
        start_date :  moment().subtract(1, 'days').format('YYYY-MM-DD'),
        end_date : moment().format('YYYY-MM-DD')
    });
    const [csv_datas,setcsv_datas] =  useState({});
    const [loader,setloader] =  useState(false);
    const [activityModal,setactivityModal] =  useState(false);
    const [cs_name,setcs_name] =  useState(false);
    const csv_headers = ['Caller Name','Date','Total Shift','Time','Break Time','directory','call direction','Language']
  
    useEffect(()=>{
    setcolumn_datas(getColunmdatas())
    const cs_names = get_name_list()
    
    getActivityReport()
    },[])

    const getActivityReport = () =>{
        setloader(true)
        var request = submit_obj
        request['type'] = check_priv('cs_mgnt', 'auditor_activity_report') ? "auditor" : "cs";
        cs_activities(req(request))
        .then((response)=>{
            setloader(false)

            if(!response){return}
            if(response && response.status =="success"){
                setrow_datas(response.data)
                var csv_data= convert_to_Csv_data(response.data)        
                 setcsv_datas(csv_data)
            }
        })
    }

    const get_name_list = () =>{
        let name = title_name == "Auditor" ? "auditor" : "cs_roster";
        getNameList(name, req())
        .then((response) => {
         if(!response){return };
             if(response.status === "success"){
                setcs_name(response.data)   
             }else{
               alert(response.message + "\n" + JSON.stringify(response.data));  
             }
           }
         ); 
		
    
    }

    const cashback_col_fields = [
        { key: "created_at", label: "Date" },
        { key: "cs_name", label: "Caller Name" },
        { key: "call_log_activities", label: "Total Shift" },
        { key: "login_time", label: "Log In" },
        { key: "logout_time", label: "Log Out" },
        { key: "total_work_time_min", label: "Total Work Time" },
        { key: "total_break_sec", label: "Break Time " },
        { key: "activity_btn", label: "Activities" }
    ]

    const convert_to_Csv_data = (response_data) => {
         var csv_data  = []
        for(var i =0; i<response_data.length;i++){
           var obj = {}
           obj['Caller Name'] = response_data[i].cs_name
           obj['Date'] = response_data[i].created_at
            let call_log_activities = response_data[i].call_log_activities ? response_data[i].call_log_activities :{}
           Object.keys(call_log_activities).forEach(function (key) {
          
            obj['Total Shift'] = 'shift - ' + key 
            const values = response_data[i].call_log_activities[key] ?  response_data[i].call_log_activities[key] :{};
            for (const datas in values) {
              if (values.hasOwnProperty(datas)) {
                obj['Time'] = moment(values.assigned).format('HH:mm a')  + (values.unassigned ?  " - " + moment(values.unassigned).format('HH:mm a') : "")
                obj['Break Time'] =  moment.duration(response_data[i].total_break_sec, 'seconds').minutes() + " Mins"
                obj['directory'] =  dd_value(values.directory,'directory')
                obj['call direction'] =  dd_value(values.call_direction,'call_direction')
                obj['Language'] =  values.language
                // obj[datas] = values[datas];
              }
            }
            csv_data.push(obj);
          });

             
        }
        return csv_data
        
        
    }

    const handleOnChange = (value,type) =>{
        var obj = {...submit_obj}
        obj[type] = value
        setsubmit_obj(obj)
    }

    const activityHandler = (value) =>{
        console.log('value', value)
        value['call_activities'] = convert_to_table_data(value)
        setactivity_datas(value)
        setactivityModal(true)
        
    }

   const convert_to_table_data = (data)=>{
    let arr = [];
    let call_log_activities = data.call_log_activities ? data.call_log_activities : {}
    Object.keys(call_log_activities).forEach(function (key) {
      let obj = { shift: 'shift - ' + key };
      const values = call_log_activities[key];
      for (const datas in values) {
        if (values.hasOwnProperty(datas)) {
          obj[datas] = values[datas];
        }
      }
      arr.push(obj);
    });
     return arr
   }

    const submit_handler =()=>{

        getActivityReport()
        console.log('submit_obj', submit_obj)
    }
    const styles = {
        table: {
            width: '100%',
            border: '1px solid white',
            borderCollapse: 'collapse',
          },
          tableHeader: {
            border: '1px solid white',
            padding: '8px',
          },
          tableBody: {
            // backgroundColor: '#ffffff',
          },
          tableRow: {
            borderBottom: '1px solid white',
          },
          tableData: {
            padding: '8px',
            border: '1px solid white',
          },

    
    }

    const calculatebreaktime = (time,type) =>{
        let minutes =    moment.duration(time, 'seconds').minutes() 
        let seconds =  moment.duration(time, 'seconds').seconds()
        let Total =  minutes + (minutes >=1 ?" Mins " : "Min ") +  (seconds ?  (seconds + (seconds >=1 ?" secs" :" sec")):"")
            return Total

        

    }

    const convertMinustsToHours = (value) => {
        let hours = (value / 60).toFixed(1)
        return hours + 'Hrs'
    }

    const getColunmdatas = () =>{

        const columns = [
            cashback_col_fields&& cashback_col_fields.map((item, idx) => {
                return {
                    Header: <p className='text-center m-0'>{item.label}</p>,
                    id: item.key,
                    accessor: item.key,
                    Cell: (cell) => {
                        {
                            return <div className='d-flex justify-content-center'>
                            {item.key !="activity_btn" ?

                                <p className='no-margin' style={{ paddingLeft: '10px' }}> 
                                {cell.row.original[item.key] ? 
                                <>
                                {(item.key=="created_at") ?  moment(cell.row.original[item.key]).format('DD MMM YYYY') : 
                                (item.key=="call_log_activities") ? `Shift - ${ Object.keys(cell.row.original[item.key]).length}`:
                                (item.key == "login_time" || item.key == "logout_time" )?  moment(cell.row.original[item.key]).format('HH:mm a') :
                                (item.key == "total_break_sec" )?  <>{calculatebreaktime( cell.row.original[item.key],item.key )}</> :
                                (item.key == "total_work_time_min") ? convertMinustsToHours(cell.row.original[item.key]) +" / 8 Hrs"   :
                                cell.row.original[item.key] }
                                </> :"-"}
                                </p>

                                :
                                <button type="button" class={`btn btn-primary ${(cell.row.original.call_log_activities || cell.row.original.activities)  ? "":"disable_div" }`}  onClick={() => {activityHandler(cell.row.original)}}>view</button>
                            }
                            </div>
                        }
                    },
                    minWidth: item.key=="cs_name" ? 210 :150,

                };
            }),
        ];

        return columns[0];
    }

    const filters = [{key:"start_date",type:"date"},
    {key:"end_date",type:"date"},
   
    {key:"person_id",type:"select"}]
  

        return (
            
            <div className={`${activityModal ? "disable_div":""}`}>
            
            <div className='floatAdvancetitleBox mt-4 p-3'>
            <h2 className='text-white m-0 py-2 '>{title_name} Activity Report</h2>
            </div>
            
            <div className='py-4' style={{backgroundColor: 'rgb(32, 41, 64)'}}>

                <div className='row m-0 d-flex  align-items-center'>

                    {filters&& filters.map((item,idx)=>{return(
                        <div className='col-lg-3 col-md-12 col-sm-12 my-3'>
                        <fieldset className='border border-white p-2 my-2 ' style={{borderRadius:'5px'}}>
                            <legend className='w-auto text-white ml-4 fieldset_legend px-2  mb-0'style={{fontSize:'13px'}}>{ item.key == "person_id" ? `${title_name} Name`: dd_value(item.key)}</legend>
                            <div className='flagged_cust'>
                

                        {item.type=="select" &&


                        <Form.Control as="select" id="audited_by" onChange={(e) =>{handleOnChange(e.target.value, item.key)}}>
                        <>
                        <option value={""}>{check_priv('cs_mgnt', 'auditor_activity_report') ? "Auditor" : "Customer Success Officer"} </option>
                        {cs_name&&cs_name.map((item,idx)=>{return(
                            <option key={idx} value={item.id}> {item.name}</option>

                        )})}
                
                    </>

                    
                        </Form.Control>
                    
                        }
                        {item.type=="date" && 
                        <div className='px-2'>
                        <DatePicker  closeOnSelect={true} onChange={(value) =>{handleOnChange( moment(value).format('YYYY-MM-DD'), item.key)}} defaultValue={ moment(submit_obj[item.key]).format('DD MMM YYYY')} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
                        isValidDate={(current) => {
                            const today = moment().startOf('day');
                            return current.isSameOrBefore(today, 'day');  }} 
                        inputProps={{ placeholder: `Date / Month/ Year`,autoComplete:'off' }}  />
                        </div>
                        }
                        </div>
                        </fieldset>
                        </div>
                    )})}

                </div>

                <div className='d-flex justify-content-center my-3 mb-5'>
                    <div>
                    <button type="button" class={`btn btn-primary btn-lg `} onClick={submit_handler} >Submit</button>

                    </div>
                </div>
            </div>

            <div>
            
        
            <Modal size='lg'   aria-labelledby="contained-modal-title-vcenter"
             centered show={activityModal} onHide={()=>{setactivityModal(false)}}   backdrop="static" keyboard={false}>
                        <Modal.Header className='no-margin pt-4 pb-3 rmso_activity_modal' closeButton >
                        <Modal.Title >
                        <h2 className='text-white'>Activities Performed</h2>

                        </Modal.Title>
                        </Modal.Header>
                    <Modal.Body>
                        <div>
                        {activity_datas && activity_datas.created_at &&
                        <p className='m-0 text-white'> <span className=' sizem fontweight600'>Date</span> : {moment(activity_datas.created_at).format("YYYY/MM/DD")}</p>}
                        {activity_datas?.call_activities > 0 && <>
                        <p className='mb-0 mt-2 text-white  sizem fontweight600'> Call Activities:</p>

                        <table className="my-3" style={styles.table}>
                            <thead style={styles.tableHeader}>
                                <tr>
                                <th className='text-center text-white' style={styles.tableData}>Shift's</th>
                                <th  className='text-center text-white' style={styles.tableData}>Time</th>
                                <th  className='text-center text-white' style={styles.tableData}>Break Time</th>
                                <th  className='text-center text-white' style={styles.tableData}>Language</th>
                                <th  className='text-center text-white' style={styles.tableData}>Direction</th>
                                <th  className='text-center text-white' style={styles.tableData}>Call Type</th>

                                </tr>
                            </thead>
                            <tbody style={styles.tableBody}>
                                {activity_datas.call_activities.length > 0 && activity_datas.call_activities.map((item, idx) => {
                                    return (
                                    <tr key={idx} style={styles.tableRow}>
                                        <td className='text-white text-center' style={styles.tableData}>{item.shift}</td>
                                        <td className='text-white text-center' style={styles.tableData}>
                                        {moment(item.assigned).format('HH:mm a')  + (item.unassigned ?  " - " + moment(item.unassigned).format('HH:mm a') : "")}
                                        </td> 
                                        <td className='text-white text-center' style={styles.tableData}>
                                        { moment.duration(item.shift_break_sec, 'seconds').minutes() + " Mins" }
                                        </td>
                                        <td className='text-white text-center' style={styles.tableData}>{item.language}</td>
                                        <td className='text-white text-center' style={styles.tableData}>{dd_value(item.call_direction, "call_direction")}</td>
                                        <td className='text-white text-center' style={styles.tableData}>{dd_value(item.directory, "directory")}</td>
                                    </tr>
                                    );
                                })}
                                </tbody>
                        </table></>
                        }
                       
                       <p className='m-0 text-white fontweight600 sizem mt-5'>{check_priv('cs_mgnt', 'auditor_activity_report') ? "" : "Other Activities performed:"} </p>

                       <div  className='d-flex row mx-0 col-12'>
                        {activity_datas && activity_datas.activities && Object.keys(activity_datas.activities).length > 0 &&
                            Object.keys(activity_datas.activities).map((key, index) => {
                            return (
                              
                                <div className='col-md-6'>
                                    <div style={{width:"85%"}}>
                                        <ul className='text-white'>
                                        <li>
                                            <div className='d-flex mt-3 align-items-center justify-content-between'>
                                            <p className='m-0 text-white'>{dd_value(key, 'any')}</p>
                                            <p className='m-0 text-white'>{activity_datas.activities[key].length}</p>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                             
                              
                              
                                </div>
                            );
                            })
                        }
                        </div>


                           
                        
                        </div>
                    

                    </Modal.Body>

                    <Modal.Footer>
                    
                    </Modal.Footer>
                    </Modal>
            
            {loader ?
             <div className='d-flex align-items-center mt-5'>
             <div className="product_takeup_loader "></div>
             </div>
            :
                <>
             {column_datas &&  
            <div className=''>
              {row_datas && row_datas.length > 0 ?
            <BasicReactTable className='border' row_data={row_datas}  FName="CS Activity Report"  column_data={column_datas} default_page_size={10} pagination={row_datas>10 ?true:false}  export_csv_datas={csv_datas.length>0 ? csv_datas:[] } csv_file={true} header_props={csv_headers} searchData={[{title:'CS Activity Report'}]}/>
            :
            <p className='text-white text-center my-3'>NO DATA</p>

            }
            </div>
            }
            </>}


            {/* {this.state.loader ?
            <>
            <p className='text-light mt-5 text-center' >Loading...</p>
            <div className="product_takeup_loader "></div></>:
            <>
            {this.state.row_datas && this.state.row_datas.length>0 ?
            <div className=''>
            <BasicReactTable className='border' row_data={this.state.row_datas} csv_file={true} FName="Cashback Report" a column_data={this.state.column_datas} default_page_size={10} pagination={this.state.row_datas.length>10 ?true:false}  />
            </div>
        :
        <p className='text-white text-center my-3'>NO DATA</p>
            }
            </>
            } */}
            </div>

            </div>  
            
            
        );
    };

    export default CsRmsoActivityReport;