import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BasicReactTable } from "../../helpers/react_table";

interface TableProps {
  row_data: Array<{ [key: string]: any }>;
  column_data: Array<{ [key: string]: any }>;
  export_csv?: boolean;
}

const AccordionWithTable = ({
  title,
  table_data,
}: {
  title: string;
  table_data: TableProps;
}) => {
  const theme = useTheme();
  const AccordionStyle = {
    // @ts-ignore
    background: theme.palette.custom_colors.old_primary_400,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    padding: "10px 0px",
  };
  return (
    <div>
      <Accordion
        disabled={table_data.row_data?.length > 0 ? false : true}
        sx={AccordionStyle}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: "25px" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="h4"
            sx={{ fontSize: "1.25rem", fontWeight: "300" }}
          >
            {title}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {/* @ts-ignore */}
          <BasicReactTable
            row_data={table_data.row_data}
            column_data={table_data.column_data}
            pagination={true}
            default_page_size={10}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionWithTable;
