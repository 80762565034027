import HmeAlrtCtxProvider from "../../context/adminHmeAlrtCtx/hmeAlrtCtxProvider";
import AdminHome from "./AdminHome";
import HomeAlrtUI from "./HomeAlrtUI";

const Home = () => (
  <HmeAlrtCtxProvider>
    <AdminHome />
    {/* <HomeAlrtUI /> */}
  </HmeAlrtCtxProvider>
);

export default Home;
