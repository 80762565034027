
import FlowComponent from '../../common/component/core/FlowComponent';
import React from 'react'
import moment from 'moment';
import DatePicker from 'react-datetime';
import {Form, Col , Row, Button,checkbox ,Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { dd_value } from '../../../actions/common_actions';
import { BasicReactTable } from '../../../helpers/react_table';
import { get } from '../../../helpers/storage_helper';
import { Homeacc_prvdrLogoselector, RoundOffSplitter, lbl_amt } from '../../../helpers/common_helper';
import { getCashBackReport } from '../../../actions/report_actions';
import { Link } from 'react-router-dom';


class CashbacksReports extends FlowComponent{

    state ={
            filters:[{key:"created_from",type:"date"},
               {key:"created_to",type:"date"},
               {key:"disbursed_from",type:"date"},
               {key:"disbursed_to",type:"date"},
               {key:"Customer",type:"input"},
               {key:"status",type:"select"},
               {key:"type",type:"select"},
               {key:"account_provider",type:"select"}],
            cashback_col_fields: [
                { key: "acc_number", label: "Acc Number" },
                { key: "cashback_doc_id", label: "Cashback ID" },
                { key: "cust_id", label: "Customer ID" , is_link: true},
                { key: "biz_name", label: "Biz Name" },
                { key: "amount", label: "Amount" },
                { key: "type", label: "Type" },
                { key: "created_at", label: "Created On" },
                { key: "disbursal_date", label: "Disbursed On" },
                { key: "disbursal_status", label: "Disbursal Status" },
                { key: "status", label: "Status" },
                { key: "txn_id", label: "Txn ID" }],
           criteria_arr:{
             
           },
    
    }

    componentWillMount(){
        this.setState({ column_datas:this.column_datas(),
        })
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr",null,"remove_choose");
    }

    cashbackslist(filter=null){
        var req = {}
        if(filter){
            req['search_criteria'] = this.state.criteria_arr
        }
        getCashBackReport(this.req(req))
        .then((response)=>{
            if(!response){return}
            if(response.status =="success"){
                console.log('response', response)
                this.setState({row_datas:response.data.results})
            }
        })

    }

    handleOnChange(val,type){

        var obj={...this.state.criteria_arr}
        obj[type]=val
        if(val==""){
            delete obj[type];
        }
        this.setState({criteria_arr:obj,is_onchanged:val?true:false})
    }


    get_url = (item,row) =>{
        let label;
        let link_url
        if(item.key =="cust_id"){
            label =  row['cust_id']
            link_url =  `/borrower/indiv/view/${label}`
        }
        else if(item.key == "type"){
            label = dd_value(row['type'])

            if (row['type'] == "women_day_2024") {
                return (<p className='m-0 text-white'> {label}</p>);
            }
            // cust_id = cell.row.original['type'] == "referral_onboared" ? cell.row.original["referred_person"] : cell.row.original['cust_id']
            link_url = row['type'] == "referral_onboarded" ? `/borrower/indiv/view/${row["referred_person"]}` : `/fa/view/${row['ontime_loan_doc_id']}`
        }   
        
        

        console.log('first', link_url,label)
      return(<Link to={link_url} target='_blank'>{label}</Link>)
        
        
    }

    column_datas(){
        const columns = [
            this.state.cashback_col_fields.map((item, idx) => {
                return {
                    Header: <p className='text-center m-0'>{item.label}</p>,
                    id: item.key,
                    accessor: item.key,
                    Cell: (cell) => {
                        {
                            return <div className='d-flex justify-content-center'>
                                {item.key == "acc_number" ?
                                    <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code} /> <p className='no-margin' style={{ paddingLeft: '10px' }}> {cell.row.original.acc_number}</p>
                                    </div>
                                    :
                                    <>
                                        {(item.key == "action") ?
                                            <>{cell.row.original.disbursal_status == "failed" ?
                                                <button type="button" class="btn btn-primary" onClick={() => { this.retryHandler(cell.row.original)}}>Retry</button> :
                                                
                                                <div>
                                                    {(cell.row.original.disbursal_status == "sent_to_batch_dsbrsl_queue" || cell.row.original.disbursal_status == "sent_to_disbursal_queue")  ? "-":
                                                    <select id="status" className="form-control" required="required" onChange={(e) => this.update_disb_status(e.target.value, cell.row.original)} >
                                                        <option value="">Choose</option>
                                                        <option value={"pending_capture"}>Pending Capture</option>
                                                        <option value="failed">Failed</option>

                                                    </select>}
                                                </div>
                                            }
                                            </>
                                            :
                                            <>
                                            {(item.key == "cust_id"  || item.key == "type"  )? this.get_url(item,cell.row.original):
                                           
                                            <p className='text-light m-0  '>

                                                {
                                                    (item.key == "biz_name" ||  item.key == "cashback_doc_id"  || item.key == "txn_id") ?
                                                        <div className='copy_text_color '>
                                                            <RoundOffSplitter val={cell.row.original[item.key]? dd_value(cell.row.original[item.key]) :  ""} unit={""} align_center={true} position={"top"} format={false} />
                                                        </div>
                                                        :

                                                        cell.row.original[item.key] ? ((item.key == "created_at" || item.key=="disbursal_date") ? <>{moment(cell.row.original[item.key]).format('DD MMM YYYY') } | {moment(cell.row.original[item.key]).format('HH:mm:ss')
                                                    }</>   :

                                                            (item.key == "amount" ? lbl_amt(cell.row.original[item.key], get('market').currency_code) : dd_value(cell.row.original[item.key], item.key=="type" ? "cashback_type":
                                                          
                                                            
                                                             cell.row.original[item.key]
                                                             )))

                                                            : "NA"
                                                }

                                            </p>}
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    },
                    minWidth: item.key=="type" ? 210 :150,

                };
            }),
        ];

        return columns[0];

    }

    submitHandler(){
        
        this.cashbackslist("search_filter")
    }

    render(){
        return(
            <div>
        <div className={`${this.state.modalBox1 ? "disable_div":""}`}>
          
        <div className='floatAdvancetitleBox mt-4 p-3'>
        <h2 className='text-white m-0 py-2 '>Cashback Report</h2>
        </div>
        
        <div className='py-4' style={{backgroundColor: 'rgb(32, 41, 64)'}}>

            <div className='row m-0 d-flex align-items-center'>

                {this.state.filters&& this.state.filters.map((item,idx)=>{return(
                    <div className='col-lg-3 col-md-12 col-sm-12 my-3'>
                    <fieldset className='border border-white p-2 my-2 ' style={{borderRadius:'5px'}}>
                        <legend className='w-auto text-white ml-4 fieldset_legend px-2  mb-0'style={{fontSize:'13px'}}>{dd_value(item.key)}</legend>
                        <div className='flagged_cust'>
                     {(item.type=="input") &&
                        <Form.Group as={Col} className={` px-2 py-0 no-margin  `}>

                        <Form.Control id="req_parameter" placeholder='Cust ID/Mobile No' className='p-0' autoComplete='off' onChange={(e) => {this.handleOnChange(e.target.value,"cust_info")}} >
                        </Form.Control>
                    </Form.Group>
                    }

                    {item.type=="select" &&


                    <Form.Control as="select" id="audited_by" onChange={(e) =>{this.handleOnChange(e.target.value, item.key)}}>
                    <>
                    
                    <option value={"all"}> All</option>

                    {item.key=="account_provider" ? 
                    this.state.acc_prvdr_dd

                    :
                    <>
                    {
                    
                    item.key=="status" ?
                    <>
                    <option value={"pending"}> Pending</option>
                    <option value={"delivered"}> Delivered</option>
                    </>
                    
                    :
                    this.getSelectOptions("cashback_type")}
                     </>
                   }
                    </>

                   
                    </Form.Control>
                   
                    }
                    {item.type=="date" && 
                    <div className='px-2'>
                    <DatePicker  closeOnSelect={true} value={this.state.criteria_arr[item.key]? moment(this.state.criteria_arr[item.key]).format("DD MMM YYYY"):"" }  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
                    isValidDate={(current) => {
                        const today = moment().startOf('day');
                        return current.isSameOrBefore(today, 'day');  }} 
                    onChange={(value) => {this.handleOnChange( moment(value).format("YYYY-MM-DD"),item.key)}}  inputProps={{ placeholder: `Date / Month/ Year`,autoComplete:'off' }}  />
                    </div>
                    }
                    </div>
                    </fieldset>
                    </div>
                )})}

            </div>

            <div className='d-flex justify-content-center my-3 mb-5'>
                <div>
                <button type="button" class={`btn btn-primary btn-lg  ${( this.state.is_onchanged )  ? "":"disable_div"} ${this.state.loader ? "disable_div":""} `} onClick={()=>{this.submitHandler()}}>Submit</button>

                </div>

               </div>
        </div>

        <div>

        {this.state.loader ? <>
           <p className='text-light mt-5 text-center' >Loading...</p>
           <div className="product_takeup_loader "></div></>:
        <>
        {this.state.row_datas && this.state.row_datas.length>0 ?
        <div className=''>
        <BasicReactTable className='border' row_data={this.state.row_datas} csv_file={true} FName="Cashback Report" a column_data={this.state.column_datas} default_page_size={10} pagination={this.state.row_datas.length>10 ?true:false}  />
        </div>
       :
       <p className='text-white text-center my-3'>NO DATA</p>
        }
        </>
        }
        </div>

    </div>            </div>
        )
    }
}
export default CashbacksReports