import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
  useTransition,
} from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import FormProvider from "../CommonComponent/rhfComponents/formProvider";
import TabSwitchUI from "../CommonComponent/tabSwitchUI";
import FilterFormUI from "./components/filterFormUI";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getReport } from "../../actions/report_actions";
import {
  common_req,
  resolvePromiseWithDelay,
} from "../../helpers/common_helper";
import KycVerficationTabFallBackUI from "./components/kycVerficationTabFallBackUI";
import FieldAuditTabUIFallBackUI from "./components/fieldAuditTabUIFallBackUI";
import {
  AUD_REQUIRED,
  DATE_REQUIRED,
  MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD,
  QUARTERS_IN_YEAR,
  SPLIT_TYPE_REQUIRED,
  START_DATE_MIN_DATE_INFO,
  START_DATE_WONT_FUTURE,
} from "../../consts";

// lazy
const KycVerficationTabUI = lazy(() =>
  resolvePromiseWithDelay(import("./components/kycVerficationTabUI"))
);
const FieldAuditTabUI = lazy(() =>
  resolvePromiseWithDelay(import("./components/fieldAuditTabUI"))
);

type FieldAuditDashboardResProps = {
  data: FieldAuditDashboardDataProps;
};

type KYCVerificationDashboardResProps = {
  data: KYCVerificationDashboardDataProps;
};

export type FieldAuditDashboardDataProps = {
  cust_vs_avg_time: {
    Duration: string[];
    "Vists Count": string[];
  };
  flagged_cust: {
    customers: string[];
    rms: string[];
  };
  fld_clk_in: Record<string, string>[];
  start_n_end_date: Record<string, string>;
  audit_coverage: Record<string, string[]>;
  flagged_reports: Record<string, Record<string, string[]>>;
  month: string[];
};

export type KYCVerificationDashboardDataProps = {
  kyc_efficiency: Record<string, string>[];
  kyc_reassigned: Record<string, string[]>;
  kyc_vs_watchlist: Record<string, string[]>;
  month: string[];
};

const defaultValues = {
  auditor: { name: "All (Auditors)", id: "all" },
  splitType: "Monthly",
  date: moment(),
  quarterlySplit: "Jan - Mar",
};

const FormSchema = Yup.object().shape({
  auditor: Yup.mixed().required(AUD_REQUIRED),
  splitType: Yup.string().required(SPLIT_TYPE_REQUIRED),
  date: Yup.mixed<moment.Moment>()
    .required(DATE_REQUIRED)
    .test("date", START_DATE_MIN_DATE_INFO, (val) => {
      const minDate = moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD);
      return !val.isBefore(minDate, "day");
    })
    .test("date", START_DATE_WONT_FUTURE, (val) => {
      const minDate = moment();
      return !val.isAfter(minDate, "day");
    }),
  quarterlySplit: Yup.string().required(DATE_REQUIRED),
});

const tabItems = [
  {
    label: "KYC Verification",
    icon: <></>,
  },
  {
    label: "Field Audit",
    icon: <></>,
  },
];

/**
 *
 * @returns the overall performance dashboard UI with filter form UI, tab UI, chart UI and performance table UI
 */
const PerformanceDashboardForAuditorUI = () => {
  // state
  const [tab, setTab] = useState(0);

  // transition
  const [isPending, startTransition] = useTransition();

  // hookForm
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  // rhf returns
  const { handleSubmit, watch, setValue: rhfSetValue, resetField } = methods;

  // const
  const choosenSplitType = watch("splitType");
  const choosenAuditor = watch("auditor") as unknown as {
    id: string;
    name: string;
  };
  const choosenDate = watch("date");
  const quarterlyVal = watch("quarterlySplit");
  const isQuarterlySplitTypeChoosen = choosenSplitType === "Quarterly";

  // cb
  const setValCb = useCallback(() => {
    // used to set the relevant form values with respect to the isQuarterlySplitTypeChoosen
    if (isQuarterlySplitTypeChoosen)
      rhfSetValue("date", moment(new Date()), {
        shouldValidate: true,
      });
    else {
      rhfSetValue("quarterlySplit", QUARTERS_IN_YEAR[moment().quarter() - 1], {
        shouldValidate: true,
      });
    }
  }, [isQuarterlySplitTypeChoosen]);

  const getFilterValue = () =>
    // conditionally send the filter_value value to api, for daily YYYY-MM-DD format, for monthly YYYMM format and for quarterly MM - MM (Jan - Mar)
    choosenSplitType === "Daily"
      ? choosenDate
        ? choosenDate.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
      : choosenSplitType === "Monthly"
      ? choosenDate
        ? choosenDate.format("YYYYMM")
        : moment().format("YYYYMM")
      : quarterlyVal;

  // effect
  useEffect(() => {
    setValCb();
  }, [isQuarterlySplitTypeChoosen]);

  const reqObj = common_req({} as any);

  // query
  const {
    data: dashboardData,
    isLoading,
    isError,
  } = useQuery<FieldAuditDashboardResProps | KYCVerificationDashboardResProps>({
    queryKey: [
      "performance_dashboard_auditors-kycVerification",
      choosenAuditor.id,
      choosenSplitType,
      tab === 0 ? "kyc_verification" : "field_auditor",
      getFilterValue(),
    ],
    queryFn: () =>
      getReport({
        ...reqObj,
        report_type: "auditor_performance",
        filters: {
          tab_name: tab === 0 ? "kyc_verification" : "field_auditor", // dashboard tab name with respect to active tab
          filter_type: choosenSplitType.toLowerCase(), // filter type (daily, monthly, quarterly)
          auditor_id: choosenAuditor.id, // for all (auditors) val would be "all" or other auditors id
          filter_value: getFilterValue(),
        },
      }),
  });
  const queryClient = useQueryClient();

  // handler
  const onSubmit = handleSubmit(async (formData) => {
    // here no need for the form submit, api req will be triggered for every field change.
    console.log(formData, "formdata is here....");
  });

  const tabChangeHandler = (tab: number) => {
    const auditorRoleCodes =
      tab === 0
        ? ["operations_auditor", "verification_officer"]
        : ["operations_auditor"];
    // taking the correct cached auditors with role codes
    const cachedAuditors = queryClient.getQueryData([
      "list_of_auditors",
      ...auditorRoleCodes,
    ]) as unknown as Record<
      string,
      Record<string, { id: string; name: string }[]>
    >;
    if (cachedAuditors) {
      // this condition for, we are having 2 tabs here and the auditors lists with seperate role codes, if the first tab's (0) selected auditor is available in the next tab's auditors list we are keeping the filter as unchanged or else we have changed the auditor field to "All (Auditors)" by reset the field
      if (
        cachedAuditors.data.list.filter((c) => c.id === choosenAuditor.id)
          .length === 0
      )
        resetField("auditor");
    }
    startTransition(() => setTab(tab));
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {/* filter form UI */}
        <FilterFormUI tab={tab} />
        {/* tab UI */}
        <TabSwitchUI
          tabItems={tabItems}
          tab={tab}
          setTab={tabChangeHandler}
          activeColor={"#fff"}
          inActiveColor={"#83858b"}
          textOverRideStyles={{
            fontFamily: "Poppins !important",
            fontWeight: "700 !important",
            fontSize: "24px !important",
            textTransform: "capitalize",
          }}
          viewOverRideStyles={{
            pb: 4,
          }}
        />
      </FormProvider>
      {/* tab sections UI */}
      {tab === 0 ? (
        <Suspense
          fallback={
            <KycVerficationTabFallBackUI isError={false} isLoading={false} />
          }
        >
          <KycVerficationTabUI
            dashboardData={
              dashboardData?.data as KYCVerificationDashboardDataProps
            }
            isLoading={isLoading}
            isError={isError}
          />
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <FieldAuditTabUIFallBackUI isError={false} isLoading={false} />
          }
        >
          <FieldAuditTabUI
            dashboardData={dashboardData?.data as FieldAuditDashboardDataProps}
            isLoading={isLoading}
            isError={isError}
          />
        </Suspense>
      )}
    </>
  );
};

export default PerformanceDashboardForAuditorUI;
