import { get_path } from '../helpers/common_helper';
import { admin_path, app_path, call_fetch } from './../helpers/fetch_helper';
import {remove_all,get, set_logout} from  './../helpers/storage_helper';

export const userService = {  
    login,
    logout,
    send_login_otp,
    verify_login_otp,
    reset_password
 };



function login(email, password, uuid,remember_me) {
    
    const kyc_path = get_path("kyc")
    const master_data_version = get('master_data_version');
    
    return call_fetch(kyc_path +`/user/login`, { email, password, master_data_version, uuid , remember_me}, false, false);
}


function send_login_otp(email) {
    
    const kyc_path = get_path("kyc")
    
    return call_fetch(kyc_path +`/user/send_otp`, { email}, false, false);
}

function verify_login_otp(otp,otp_id,email) {
    
    const kyc_path = get_path("kyc")
    
    return call_fetch(kyc_path +`/user/verify_otp`, { otp,otp_id,email}, false, false);
}
function reset_password(token,password) {
    
    const kyc_path = get_path("kyc")
    
    return call_fetch(kyc_path +`/user/reset_password`, { token,password}, false, false);
}


function logout(req) {

    const kyc_path = get_path("kyc")
//    persistor.purge()
   // remove user from local storage to log user out
   call_fetch(kyc_path +`/user/logout`);
   remove_all();
   set_logout();
}
