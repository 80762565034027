import React from 'react';
import FlowComponent from '../common/component/core/FlowComponent';
import {get, set, check_priv} from '../../helpers/storage_helper';
import {listAccountProvider} from '../../actions/account_provider_actions';
import {searchLoan} from '../../actions/loan_actions';
import {Config} from '../../helpers/storage_helper';
import ReconStatus from '../lender/component/ReconStatus';
import {title_case, lbl_amt, Homeacc_prvdrLogoselector, format_date_tbl, rt_algn, filter} from '../../helpers/common_helper';
import CaptureFailedDisbursal from '../loan/component/CaptureFailedDisbursal';
import SyncPayment from '../lender/component/SyncPayment';
import { sortBizcommon,sortAmtcommon, sortTimecommon } from '../../helpers/sortingHelpers';
import {list_lender_txn_stmts} from '../../actions/lender_actions';
import {getRefAccounts} from '../../actions/account_txn_actions';
import {Link} from "react-router-dom";
import {dd_value, getListByUrl} from "../../actions/common_actions";
import {reinitiateRecon} from '../../actions/loan_actions';

import {OverlayTrigger, Popover} from "react-bootstrap";
import {FcInfo} from 'react-icons/fc';
import {ExcesstimeAction, TooltipLabel, AccountTransactionToolTipLabel} from "../common/partial";
import CommonReactTable from '../../helpers/commonReact_table';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import moment from 'moment';
import { BasicReactTable } from '../../helpers/react_table';

class DisplayAccStmtList extends FlowComponent{

    state = {
        refresh: false,
        lists:[],
        columndatas:[],
        copy:[],  
        dyn_Btn_selected:[],
		all_count:0,		
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],
        show_table: false,
    }

    handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount(){

        if(this.props.refresh){
            setInterval(() => this.refreshList(),this.props.refresh)
        }

        const capture_payment_late1 = Config("capture_payment_late1")
        const capture_payment_late2 = Config("capture_payment_late2")

        this.setState({capture_payment_late1, capture_payment_late2})

        const market = get('market');
        var req = {country_code: market.country_code, time_zone: market.time_zone, status: ""};
        listAccountProvider({...req, status: 'enabled', biz_account: true})
        .then((response) => {
			if(!response){ return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each => acc_prvdrs.push(each.acc_prvdr_code))  
				this.setState(state => state.all_Acc_pvdrs = acc_prvdrs) 
                // set("acc_pvdrs", acc_prvdrs)                      
			}
		});	
        this.getList();
        // this.drawTable();  
    }


    chk_fa_id(record){

        const loan_doc_id = record.loan_doc_id
        const recon_status = record.recon_status
    
        if(loan_doc_id){
            return <Link to={"/fa/view/"+loan_doc_id} style = {{color:"grey"}}  target="_blank" >{loan_doc_id}</Link>
        }
        else{
            return "NA"
        } 
    }

    handleReinitiateRecon = (event, row)=>{
        const amount = row.stmt_txn_type == 'credit' ? row.cr_amt : row.stmt_txn_type == 'debit' && row.dr_amt

        const resp = window.confirm("Are you sure to reinitiate this recon transaction ?")
        if(resp == false){
          return;
         }
         reinitiateRecon(this.req({acc_stmt_id: row.id, txn_id : row.stmt_txn_id, amount : amount}))
        .then((response) => {
          this.resp(response)
          if(!response){return };
          if(response.status === "success"){
            alert(response.message);  
            this.getList()
        }
        })		
    }

    getList = async() => {     
        const market = get('market')
		const market_name = market.country_code
        // await this.server_time(this.time_zone)
        
        this.getListRows().then((lists) => {
            let columndatas = this.getListColumns()
            this.setState({lists,columndatas})
            
            this.setState(state=>state.all_count = lists ?lists.length :0)

            if(this.props.list_name === 'unmatched_credits'){
                this.setState(state => state.copy = [])

                if( lists &&lists.length !== 0){																						
					const newArr = get([market_name]+'_unMatchedCredits') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_unMatchedCredits') 																						
					this.state.all_Acc_pvdrs.map(each=>{																				
						const count = lists.filter(indiv => indiv.acc_prvdr_code === each)		
						const length = count.length										
						this.setState(state=>state.filter_actions[each] = [{count:length},{status:newArr.includes(each)}],()=>{
                            this.unMatchPaydynClickhandler(this.state.filter_actions[each][1].status,each)

                        })
						// this.setState(state=>state.filter_actions[each][0].count = length)																
					})
				}
            }
            else if(this.props.list_name === 'review_pending_payments'){
                this.setState(state=>state.copy = [])				
				if(lists.length !== 0){												
					const newArr = get([market_name]+'_reviewPendingPayments') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_reviewPendingPayments')																						
					this.state.all_Acc_pvdrs.map(each=>{
						const count = lists.filter(indiv => indiv.acc_prvdr_code === each)		
						const length = count.length										
						this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
						this.reviewPendingPaydynClickhandler(this.state.filter_actions[each][1].status,each)									
						this.setState(state=>state.filter_actions[each][0].count = length)											
					})
				}
            }
            else if(this.props.list_name === 'unmatched_debits'){
                this.setState(state=>state.copy = [])				
				if( lists && lists.length !== 0){												
					const newArr = get([market_name]+'_unMatchedDebits') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_unMatchedDebits')																						
					this.state.all_Acc_pvdrs.map(each=>{
						const count = lists.filter(indiv => indiv.acc_prvdr_code === each)		
						const length = count.length										
						this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
                            this.unMatchDebitdynClickhandler(this.state.filter_actions[each][1].status,each)									

                        })
						this.setState(state=>state.filter_actions[each][0].count = length)											
					})
				}
            }
        })
    }   

    getListRows = async () => {
		let resp, data;
		// await this.server_time(this.time_zone)		
		switch(this.props.list_name){
			case 'unmatched_credits':
                const statement_search = {stmt_txn_type: 'credit', recon_status: 'unknown_txns', stmt_txn_date__from: moment().subtract(5, 'days').format('YYYY-MM-DD'), stmt_txn_date__to: moment().format('YYYY-MM-DD')};
                resp = await list_lender_txn_stmts(this.req({statement_search}, false, 'statement_search'))
                // this.setState(state => state.sortVariable = "stmt_txn_date")
				if(resp && resp.status === 'success'){ data = resp.data.account_txns}	
                break;						
			case 'review_pending_payments':
                const pending_payment_criteria = {stmt_txn_type: 'credit', recon_status: 'review_pending_payments' };
                resp = await list_lender_txn_stmts(this.req({statement_search: pending_payment_criteria, home_table:true}, false, 'statement_search'))
                // this.setState(state => state.sortVariable = "stmt_txn_date")
				if(resp && resp.status === 'success'){ data = resp.data.account_txns}	
                break;
            case 'unmatched_debits':
                const unmatched_debit_criteria = {stmt_txn_type: 'debit', recon_status: 'unknown_txns', stmt_txn_date__from: moment().subtract(5, 'days').format('YYYY-MM-DD'), stmt_txn_date__to: moment().format('YYYY-MM-DD') };
                resp = await list_lender_txn_stmts(this.req({statement_search: unmatched_debit_criteria}, false, 'statement_search'))
                // this.setState(state => state.sortVariable = "stmt_txn_date")
                if(resp && resp.status === 'success'){ data = resp.data.account_txns}	                            
        }

        if(resp && resp.status === 'success'){
			this.setState(state => state.server_time = resp.server_time)
			return data;
		}
    }

    unMatchPaydynClickhandler(key, val) {
        const market = get('market')
		const market_name = market.country_code											
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state => state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state => state.all_Btn_status = true)
					}
				}				
                localStorage.setItem([market_name]+'_unMatchedCredits',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e => {
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state => state.dyn_Btn_selected = newOne)																															
                localStorage.setItem([market_name]+'_unMatchedCredits', JSON.stringify(newOne))
				this.setState(state => state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state => state.filter_actions[val][1].status = key)
				}							
				this.setState(state => state.all_Btn_status = false)													
                localStorage.setItem([market_name]+'_unMatchedCredits',JSON.stringify(this.state.dyn_Btn_selected))								
			}else{				
				this.state.all_Acc_pvdrs.map(e => this.state.filter_actions[e][1].status = false)				
				this.setState(state=>state.dyn_Btn_selected = [])								
                localStorage.setItem([market_name]+'_unMatchedCredits',JSON.stringify([]))
				this.setState(state => state.all_Btn_status=key)
			}
		}					
        const newArr = get([market_name]+'_unMatchedCredits')			
		let combinedArr = []
		newArr.map(each => {			
			const filterArr = this.state.lists.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)			
		})		
		this.setState(state=>state.copy = combinedArr)									
	}  

    unMatchDebitdynClickhandler(key, val) {
        const market = get('market')
		const market_name = market.country_code											
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state => state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state => state.all_Btn_status = true)
					}
				}				
                localStorage.setItem([market_name]+'_unMatchedDebits',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e => {
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state => state.dyn_Btn_selected = newOne)																															
                localStorage.setItem([market_name]+'_unMatchedDebits', JSON.stringify(newOne))
				this.setState(state => state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state => state.filter_actions[val][1].status = key)
				}							
				this.setState(state => state.all_Btn_status = false)													
                localStorage.setItem([market_name]+'_unMatchedDebits',JSON.stringify(this.state.dyn_Btn_selected))								
			}else{				
				this.state.all_Acc_pvdrs.map(e => this.state.filter_actions[e][1].status = false)				
				this.setState(state=>state.dyn_Btn_selected = [])								
                localStorage.setItem([market_name]+'_unMatchedDebits',JSON.stringify([]))
				this.setState(state => state.all_Btn_status=key)
			}
		}					
        const newArr = get([market_name]+'_unMatchedDebits')			
		let combinedArr = []
		newArr.map(each => {			
			const filterArr = this.state.lists.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)			
		})		
		this.setState(state=>state.copy = combinedArr)									
	}  

    reviewPendingPaydynClickhandler(key, val) {
        const market = get('market')
		const market_name = market.country_code											
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state => state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state => state.all_Btn_status = true)
					}
				}				
                localStorage.setItem([market_name]+'_reviewPendingPayments',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e => {
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state => state.dyn_Btn_selected = newOne)																															
                localStorage.setItem([market_name]+'_reviewPendingPayments', JSON.stringify(newOne))
				this.setState(state => state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state => state.filter_actions[val][1].status = key)
				}							
				this.setState(state => state.all_Btn_status = false)													
                localStorage.setItem([market_name]+'_reviewPendingPayments',JSON.stringify(this.state.dyn_Btn_selected))								
			}else{				
				this.state.all_Acc_pvdrs.map(e => this.state.filter_actions[e][1].status = false)				
				this.setState(state=>state.dyn_Btn_selected = [])								
                localStorage.setItem([market_name]+'_reviewPendingPayments',JSON.stringify([]))
				this.setState(state => state.all_Btn_status=key)
			}
		}					
        const newArr = get([market_name]+'_reviewPendingPayments')			
		let combinedArr = []
		newArr.map(each => {	
			const filterArr = this.state.lists.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)	
		})		
		this.setState(state=>state.copy = combinedArr)
       								
	}

    handle_capture = (data) => {
        return <SyncPayment mode="recon" title="Review & Sync" data={data} parent="home"/>
        // window.location.reload();
    }

    getListColumns = () => {
        switch(this.props.list_name){
            case 'unmatched_credits':
                return [
                    {
                        Header : "Network",
                        accessor: "network_prvdr_code",  
                        sortable: false,
                        width:85,             
                        Cell :row => <Homeacc_prvdrLogoselector logotype={row.value}/>
                    },
                    {
                        Header : "Account Number",
                        accessor: "acc_prvdr_code",
                        sortable: false,
                        width: 150,   
                        Cell: row => <div className='d-flex align-items-center' >
                                      <div><Homeacc_prvdrLogoselector logotype={row.value}/></div>
                                      <div style={{paddingLeft:'10px'}}>{row.row.original.acc_number}</div>
                                    </div>
                    },
                    // {  
                    //     Header:"Account Number",
                    //     accessor: "acc_number",
                    //     sortable: false,
                    //     maxWidth: 120,
                    //     width:120,
                    //     Cell: row => <span>{row.value}</span>    
                    // },
                    {
                        Header : "Stmt Txn Date",
                        // width:125
                        accessor: 'stmt_txn_date',
                        Cell: row => format_date_tbl(row.value, true)
                    }, 
                    {
                        Header : <div className='text-right'>Amount</div>,
                        // width:130,
                        accessor: 'cr_amt',
                        Cell: row => {return rt_algn(lbl_amt(row.value, this.currency_code))}
                    },
                    {
                        Header : "Stmt Txn ID",
                        // width:110,
                        accessor: 'stmt_txn_id'
                    },
                    {
                        Header  : "Description",
                        accessor: 'descr',
                        width:230,
                        Cell    : row => <TooltipLabel full_text={dd_value(row.value)} no_reduce={true} />
                    },
                    {
                        Header  : "Last Updated at",
                        // width:130,
                        sortable: false, 
                        id      : "updated_at",
                        // sortMethod: (a,b) => this.sortTime(a,b),
                        accessor:row => <ExcesstimeAction data={row} timedata={this.state.server_time} item={"last_updated_at"} time_limit={[this.state.capture_payment_late1,this.state.capture_payment_late2]}/>
                    },
                    {
                        Header : <div className='text-center'>Matching FA ID</div>,
                        // minWidth:140,
                        // maxWidth:200, 
                        id: 'chk_fa_id',               
                        accessor : row => <div className="text-center"> {this.chk_fa_id(row)}</div>
                    },
                    {
                        Header : "Matching Status",
                        // minWidth:130,
                        // maxWidth:200,                
                        id : 'recon_status',
                        accessor: row => <TooltipLabel full_text={dd_value(row.recon_status)} overlay={row.recon_desc} no_reduce={true} />
                    },{
                        Header : "Action",
                        id: "action",
                        minWidth:140,
                        maxWidth: 180, 
                        show: check_priv('recon', 'reinitiate_recon') ? true : false,
                        accessor:row => <ReconStatus parent = "home" show_btn = {true} data={row}  onSync={this.getList}/>
                    },
                    {
                        Header : "Action",
                        id: "reinitiate",
                        minWidth:140,
                        maxWidth: 180,                 
                        show: check_priv('recon', 'reinitiate_recon') ? true : false,
                
                        accessor: row => <> { (row.recon_status != "80_recon_done" && row.recon_status != "10_capture_payment_pending" ) || (row.recon_status != null && (row.recon_status != "10_capture_payment_pending" && row.loan_doc_id == null)) ?
                                                  <input id={row.id} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, row)}}  className={`btn btn-primary btn-std}`}/>
                                                  :
                                                  <input disabled = {true} id={row.id} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, row)}}  className={`btn btn-primary btn-std}`}/>
                                            }
                
                                          </>
                      }]
            case 'unmatched_debits':
                return [
                    {
                        Header : "Network",
                        accessor: "network_prvdr_code",  
                        sortable: false,
                        maxWidth:85,               
                        Cell :row => <Homeacc_prvdrLogoselector logotype={row.value}/>
                    },
                    {
                        Header : "Account Number",
                        accessor: "acc_prvdr_code",
                        sortable: false,
                        width:150, 
                        maxWidth: 150,
                        Cell: row => <div className='d-flex align-items-center'> <div><Homeacc_prvdrLogoselector logotype={row.value} style={{width:'30px',height:'20px'}} /></div>
                                        <div style={{paddingLeft:'10px'}}>{row.row.original.acc_number}</div>
                                         </div>
                    },
                    // {
                    //     Header : "Account Number",
                    //     accessor: "acc_number",
                    //     sortable: false,
                    //     maxWidth: 150,
                    //     width:120,
                    //     Cell: row => <span style={{float:'left'}}>{row.value}</span>    
                    // },
                    {
                        Header : "Stmt Txn Date",
                        width:150,
                        maxWidth: 150,
                        accessor: 'stmt_txn_date',
                        Cell: row => format_date_tbl(row.value, true)
                    }, 
                    {
                        Header : <div className='text-right'>Amount</div>,
                        width:130,
                        maxWidth: 130,
                        accessor: 'dr_amt',
                        Cell: row => {return rt_algn(lbl_amt(row.value, this.currency_code))}
                    },
                    {
                        Header : "Stmt Txn ID",
                        width:100,
                        maxWidth: 100,                        
                        accessor: 'stmt_txn_id'
                    },
                    {
                        Header  : <div className='text-center'>Description</div>,
                        accessor: 'descr',
                        // width:210,
                        Cell    : row => <TooltipLabel full_text={dd_value(row.value)} no_reduce={true} />
                    },
                    {
                        Header  : "Last Updated at",
                        // minWxidth: 190,
                        // maxWidth: 210,
                        sortable: false, 
                        width:130,
                         maxWidth: 130,
                        id      : "updated_at",
                        accessor:row => <ExcesstimeAction data={row} timedata={this.state.server_time} item={"last_updated_at"} time_limit={[this.state.capture_payment_late1,this.state.capture_payment_late2]}/>
                    },
                    {
                        Header :<div className='text-center'>Matching FA ID</div> ,
                        minWidth:140,
                        maxWidth:180, 
                        id: 'chk_fa_id',               
                        accessor : row => <div className='text-center'>{this.chk_fa_id(row)}</div>
                    },
                    {
                        Header : "Matching Status",
                        minWidth:140,
                        maxWidth:200,                
                        id : 'recon_status',
                        accessor: row => <TooltipLabel full_text={dd_value(row.recon_status)} overlay={row.recon_desc} no_reduce={true} />
                    },
                    {
                        Header : "Action",
                        id: "action",
                        minWidth:140,
                        maxWidth: 180, 
                        show: check_priv('recon', 'reinitiate_recon') ? true : false,
                        accessor:row => <ReconStatus parent ="home" show_btn = {true} data={row} onSync={this.getList}/>
                    },
                    {
                        Header : "Action",
                        id: "reinitiate",
                        minWidth:140,
                        maxWidth: 180,                 
                        show: check_priv('recon', 'reinitiate_recon') ? true : false,
                
                        accessor: row => <> { (row.recon_status != "80_recon_done" && row.recon_status != "10_capture_payment_pending" ) || (row.recon_status != null && (row.recon_status != "10_capture_payment_pending" && row.loan_doc_id == null)) ?
                                                  <input id={row.id} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, row)}} className={`btn btn-primary btn-std}`}/>
                                                  :
                                                  <input disabled = {true} id={row.id} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, row)}} className={`btn btn-primary btn-std}`}/>
                                            }
                
                                          </>
                      }]        
            case 'review_pending_payments':
                return [
                    {
                        Header  : "Network",
                        accessor: "network_prvdr_code",  
                        sortable: false,
                        maxWidth:85,               
                        Cell    :row => <Homeacc_prvdrLogoselector logotype={row.value}/>
                    },
                    { Header : "Account Number",
                    accessor: "acc_prvdr_code",
                    sortable: false,
                    width:150, 
                    maxWidth: 150,
                    Cell: row => <div className='d-flex align-items-center'> <div><Homeacc_prvdrLogoselector logotype={row.value} style={{width:'30px',height:'20px'}} /></div>
                                    <div style={{paddingLeft:'10px'}}>{row.row.original.acc_number}</div>
                                     </div>
                    },
                    // {
                    //     Header  : "ACC PRVDR",
                    //     accessor: "acc_prvdr_code",
                    //     sortable: false,
                    //     maxWidth: 85,   
                    //     Cell    : row => <Homeacc_prvdrLogoselector logotype={row.value}/>
                    // },
                    // {
                    //     accessor: "acc_number",
                    //     sortable: false,
                    //     maxWidth: 130,
                    //     Cell    : row => <span style={{float:'left'}}>{row.value}</span>    
                    // },
                    {
                        Header  : "Stmt Txn Date",
                        width   : 110,
                        accessor: 'stmt_txn_date',
                        Cell    : row => format_date_tbl(row.value, true)
                    },
                    {
                        Header  : <div className='text-right'>Amount</div>,
                        width   :120,
                        accessor: 'cr_amt',
                        Cell    : row => {return rt_algn(lbl_amt(row.value, this.currency_code))}
                    },
                    {
                        Header  : "Stmt Txn ID",
                        width   :130,
                        accessor: 'stmt_txn_id'
                    },
                    {
                        Header  : "Description",
                        accessor: 'descr',
                        Cell    : row => <TooltipLabel full_text={dd_value(row.value)} no_reduce={true} />
                    },
                    {
                        Header  : "Last Updated at",
                        minWxidth: 190,
                        maxWidth: 190,
                        sortable: false, 
                        id      : "updated_at",
                        // sortMethod: (a,b) => this.sortTime(a,b),
                        accessor:row => <ExcesstimeAction data={row} timedata={this.state.server_time} item={"last_updated_at"} time_limit={[this.state.capture_payment_late1, this.state.capture_payment_late2]}/>
                    },
                    {
                        Header  : "Matching FA ID",
                        minWidth: 140,
                        maxWidth: 200, 
                        id      : 'chk_fa_id',               
                        accessor: row => {return this.chk_fa_id(row)}
                    },
                    {
                        Header  : "Review Reason",
                        minWidth: 140,
                        maxWidth: 200,
                        id      : 'review_reason',
                        accessor: row => {return dd_value(row.review_reason)}
                    },
                    {
                        Header  : "Action",
                        minWidth: 140,
                        maxWidth: 200,                
                        id      : 'recon_status',
                        accessor: row => {return this.handle_capture(row)}
                    }]
        }
    }

	refreshList = async() => {		
		this.setState({refresh: true})		
		await this.getList()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.refresh != this.props.refresh){
            this.refreshList()
        }		
    }

    render(){ 
        return (        
            <Accordion>
                <Card>
                    <div className={`${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
                    <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                            {this.props.list_name == 'unmatched_credits' && 
                                <>
                                    <div className='home_header_d-flex_at_response'>
                                        <h4 className="card-title text-white tableHead_font " >{this.getListTitle()}</h4>
                                        {this.state.lists.length > 0 &&
                                            <>
                                                <div className='countREL card-title'>
                                                    <button className={'filterBtns allBtn_size'} onClick={()=>this.unMatchPaydynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
                                                    <div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
                                                        <label>{this.state.all_count}</label>
                                                    </div>
                                                </div>
                                                {this.state.all_Acc_pvdrs.map((each,idx)=>
                                                    <div key={idx} className='countREL card-title'>
                                                        <>
                                                            <div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.unMatchPaydynClickhandler(!this.state.filter_actions[each][1].status,each)}>
                                                                <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img>
                                                            </div>
                                                            <div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>
                                                                <label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>
                                                            </div>
                                                        </>
                                                    </div>
                                                )}
                                            </>
                                        }
                                    </div>
                                    <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                                        <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                                    </Accordion.Toggle>
                                </>
                            }
                            {this.props.list_name == 'unmatched_debits' && 
                                <>
                                    <div className='home_header_d-flex_at_response'>                                
                                        <h4 className="card-title text-white tableHead_font  " >{this.getListTitle()}</h4><br/>
                                        {this.state.lists.length > 0 &&
                                            <>
                                                <div className='countREL card-title'>
                                                    <button className={'filterBtns allBtn_size'} onClick={()=>this.unMatchDebitdynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
                                                    <div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
                                                        <label>{this.state.all_count}</label>
                                                    </div>
                                                </div>									
                                                {this.state.all_Acc_pvdrs.map((each,idx)=>									
                                                    <div key={idx} className='countREL card-title'>																					
                                                        <div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.unMatchDebitdynClickhandler(!this.state.filter_actions[each][1].status,each)}>													  
                                                            <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img> 																	
                                                        </div>
                                                        <div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>													
                                                            <label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>																																
                                                        </div>
                                                    </div>																		
                                                )}  
                                            </>
                                        }                                     
                                    </div>
                                    <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                                        <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                                    </Accordion.Toggle>
                                </>
                            }
                            {this.props.list_name == 'review_pending_payments' &&
                                <>
                                    <div className='home_header_d-flex_at_response'>
                                        <h4 className="card-title text-white tableHead_font card-body title-bottom" >{this.getListTitle()}</h4><br/>
                                        {this.state.lists.length > 0 &&
                                            <>
                                                <div className='countREL card-title'>
                                                    <button className={'filterBtns allBtn_size'} onClick={()=>this.reviewPendingPaydynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
                                                    <div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
                                                        <label>{this.state.all_count}</label>
                                                    </div>
                                                </div>									
                                                {this.state.all_Acc_pvdrs.map((each,idx)=>									
                                                    <div key={idx} className='countREL card-title'>																					
                                                        <div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.reviewPendingPaydynClickhandler(!this.state.filter_actions[each][1].status,each)}>													  
                                                            <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img> 																	
                                                        </div>
                                                        <div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>													
                                                            <label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>																																
                                                        </div>
                                                    </div>																		
                                                )}  
                                            </>
                                        }                                     
                                    </div>
                                    <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                                        <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                                    </Accordion.Toggle>
                                </>
                            }
                        </div>
                    </div>
                        

                        <div className="card-body no-padding" style={{padding:'0 !important'}}>
                            {this.state.server_time &&
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='react_common_table' style={{marginTop:this.props.list_name=="unmatched_credits" || "unmatched_debits" ? "28px" :"0px"}}>

                                   {(this.state.copy && this.state.copy.length >0 )&&
                                    <BasicReactTable  row_data={this.state.copy } column_data={this.state.columndatas} pagination={this.state.copy.length>9? true:false} default_page_size = {10}  defaultSorted={[{id:"stmt_txn_date",desc:true}]}   timeServer = {this.state.server_time} excessTimeaction={true} appliedRowblink = {false} paymentRowblinktime = {this.state.capture_payment_late2} csv_file = {this.props.list_name !== 'review_pending_payments' ? true : false} export_csv_datas={this.state.copy !== undefined ? this.state.copy : []} csvFile={this.getListTitle()} />  
                                    }
                                       
                                        {/* <CommonReactTable showPag = {true} minRow = {0} row_data = {this.state.copy !== undefined ? this.state.copy : []} column_data = {this.state.columndatas} 
                                        defaultSorted={[{id:"stmt_txn_date",desc:true}]} resizable={false} defaultPageSize = {10} timeServer = {this.state.server_time} 
                                        excessTimeaction={true} appliedRowblink = {false} paymentRowblinktime = {this.state.capture_payment_late2} exportCSV = {this.props.list_name !== 'review_pending_payments' ? true : false} 
                                        csvData={this.state.copy !== undefined ? this.state.copy : []} csvFile={this.getListTitle()} csvstyle={true} loader={this.state.copy !== undefined ? true : false} /> */}
                                    </Card.Body>
                                </Accordion.Collapse>
                            }
                        </div>
                </Card>
            </Accordion>
        );
    }

    getListTitle = () => {
		switch ( this.props.list_name){
			case 'unmatched_credits':
				return "Unknown Credit Txns (Last 5 days) "
			case 'review_pending_payments':
				return "Review Pending Payments"
            case 'unmatched_debits': 
                return "Unknown Debit Txns (Last 5 days) "
		}
        return''
	}
}


export default DisplayAccStmtList;