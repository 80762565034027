import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { check_priv } from "../helpers/storage_helper";

/**
 *
 * @param param0 boolean
 * @param param1 ReactNode
 * @returns if the privilege is truthy it returns the children or else the user will be redirected to /404
 */
const RedirectToNotFound = ({
  privilegeKeys,
  children,
}: {
  privilegeKeys: string[];
  children: ReactNode;
}) => {
  const privilege = check_priv(privilegeKeys[0], privilegeKeys[1]);
  if (!privilege) return <Navigate to={"/not_found_page"} />;
  return <>{children}</>;
};

export default RedirectToNotFound;
