import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {dd_value} from '../../../actions/common_actions';

import {createTask} from '../../../actions/task_actions';
import {get_repayment_summary,create_capture_payment,unlink_payment,update_waiver,captureExcess, handleTransactionID,updateReconStatus, getLoan, validateTxnId} from '../../../actions/loan_actions';
import {rt_algn,lbl_amt,format_date,to_amount,title_case} from '../../../helpers/common_helper';
import {get,check_priv, Config} from '.././../../helpers/storage_helper';
import { Link} from 'react-router-dom';
import {Button,Modal,Card,OverlayTrigger,Popover} from 'react-bootstrap';
import {TooltipWithElement,Remarks} from '../../common/partial';
import Logo from '../../common/partials/logo';
import { AiFillInfoCircle } from "react-icons/ai";
import DatePicker from 'react-datetime';
import {FcInfo} from 'react-icons/fc'
import CurrentOSFA from './CurrentOSFA';
import FileUpload from '../../common/component/FileUpload';
import moment from 'moment';
import { FaCheckCircle } from "react-icons/fa";


class SyncPayment extends FlowComponent{
   state = {
      repayment_style: "disable_div",
      is_part_payment:false,
      sync_div_style:"hide_div",
      btn_name : "Apply",
      unlink_payment:{},
      reviewed : 0,
      repayment_modal : false,
      waiver_modal : false,
      waiver_field: null,
      excess_modal :false,
      recon_modal : false,
      manual_recon:{
         stmt_txn_proof:null
      },
      req_waiver_modal :false,
      modal: null,
      opacity : "",
      fa:false,
      fee:false,
      penalty:false,
      excess:false, 
      recon_data : {},
      FAcheck_box_checked:false,    
      FEEcheck_box_checked:false,    
      PENcheck_box_checked:false,    
      EXCcheck_box_checked:false, 
      is_skip_trans_id_check:false,
      txn_data : '',
      txn_failed_msg:"",
      apply_btn : 'disable_div',
      ac_dropdown : 'disable_div',
      txn_id_style : 'txn_id_style' ,
      type :"" ,
      comp_name: "loans" ,
      repayment_txn : {},
      evidence_active:false,
      excess_capture:false,
      loader : false
   }

   componentWillMount(){

      // if(this.props.mode == 'manual_recon'){
      //    const status = "enabled";
      //    const lender_code = this.props.data.lender_code;
      //    const network_prvdr_code = this.props.data.acc_prvdr_code;
      //    const lender_account = {lender_code,status, network_prvdr_code};
      //    this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);

      // }

      this.repayment_txn = {amount : 0}
      this.setState({data:this.props.data})
   }

   // componentWillReceiveProps(props){

   //    //  const recon_status = props.record.recon_status
   //      //const record = props.record
   //    this.setState({data:props.data})
   //    if(this.props.mode == 'capture'){
        
   //       const status = "enabled";
   //       const lender_code = this.props.data.lender_code;
   //       const network_prvdr_code = this.props.data.acc_prvdr_code;
   //       const cust_id = this.props.data.cust_id
   //       const acc_purpose = this.props.data.loan_purpose;
   //       const lender_account = {lender_code,status, network_prvdr_code, cust_id};
   //       const customer_account = {cust_id,status,acc_purpose};
      
   //       this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
   //       this.setOptionsListToStateFromAppApi("common","customer_accounts", this.req({customer_account}), ["account_id", "acc_num_disp_txt"]);
  
   //    }
   // }

   componentDidMount(){
      this.setState({data:this.props.data})
      if(this.props.mode == 'capture'){
       
        const status = "enabled";
        const lender_code = this.props.data.lender_code;
        const network_prvdr_code = this.props.data.acc_prvdr_code;
        const cust_id = this.props.data.cust_id
        const acc_purpose = this.props.data.loan_purpose;
        const lender_account = {lender_code,status, network_prvdr_code, cust_id};
        const customer_account = {cust_id,status,acc_purpose};
     
        this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
        this.setOptionsListToStateFromAppApi("common","customer_accounts", this.req({customer_account}), ["account_id", "acc_num_disp_txt"]);
 
     }
  }



   handleSkipTransIDChekCheckboxClick = (event, type) => {

     
      if((this.repayment_txn.acc_stmt_txn_id && this.repayment_txn.acc_stmt_txn_id.length > 5 )|| (this.state.summary.txn_id && this.state.summary.txn_id.length > 5 )){
         this.setState({is_skip_trans_id_check:this.state.is_skip_trans_id_check, type}, this.handleTxnId(event, type));

      }
   }

   enableTxnID = (event,type) => {
      if(type && ((type=='redemption_dr' || type=='investment_dr')  ||(type=='redemption_cr' || type=='investment_cr')  )){
         if(event.target && Config('ops_accounts')[this.country_code].includes(event.target.value)){
            this.setState({upload_proof:true, apply_btn :'disable_div',is_skip_trans_id_check:true,txn_failed_msg:'',txn_data:''});
            alert("Transaction import is disabled for this account, Please proceed with transaction proof.")
         }
         
      }
      if(type&& ((type=='redemption_dr' || type=='investment_dr')  ||(type=='redemption_cr' || type=='investment_cr')  )&&(this.state.recon_data.to_acc_id||this.state.recon_data.from_acc_id)&&(this.state.recon_data.redemption_amount||this.state.recon_data.investment_amount)&& this.state.recon_data.txn_date != null){
         this.setState({txn_id_style :'' });
         return;
      }
      const repayment_txn = this.repayment_txn;
      const summary = this.state.summary;
      const recon_data = this.state.recon_data
   
      if(repayment_txn && (repayment_txn.amount > 0 && this.to_acc.value != null && repayment_txn.txn_date != null)){
        this.setState({txn_id_style :''  });
      }else if (summary && (summary.amount > 0 && this.cust_acc.value != null && repayment_txn.txn_date != null && summary.from_ac_id != null)){
         this.setState({txn_id_style :'' });
      }else if(recon_data && (recon_data.loan_doc_id && (recon_data.to_acc_id || (recon_data.from_acc_id && (recon_data.reversal_amount || recon_data.amount))) && recon_data.txn_date != null )){
         this.setState({txn_id_style :'' });
      }

   }

   checkVal = (value, type = null) => {
      if(value == null || value == undefined || value == 0){
          return type == 'amt' ? `0 ${this.currency_code}` : '-';
      }else{
          if (type == 'date'){
              return moment(value).format('DD MMM YYYY hh:mm a');
          }else if(type =='amt'){
              return lbl_amt(value,this.currency_code);
          }else{
              return  value;
          }
          
      }
      
  }

   handleSpecificMessage = (message) =>{
      const pattern1 = /The transaction ID (\d+) is already matched with the ID ([A-Z]+-\d+-\d+). You can not manually capture the payment here./;
      const pattern2 = /This transaction has already been captured with the ID ([A-Z]+-\d+-\d+)./;

      let match;
      if ((match = message.match(pattern1))) {
         const [_, txn_id, loan_doc_id] = match;
         return (
            <p className='text-white px-3 m-0' style={{fontSize: '15px'}}>
                  The transaction ID {txn_id} is already matched with the ID <Link to={"/fa/view/"+loan_doc_id} target="_blank" >{loan_doc_id}</Link>. You can not manually capture the payment here.
            </p>
         );
      } else if ((match = message.match(pattern2))) {
         const [_, loan_doc_id] = match;
         return (
            <p className='text-white px-3 m-0' style={{fontSize: '15px'}}>
                  This transaction has already been captured with the ID <Link to={"/fa/view/"+loan_doc_id} target="_blank" >{loan_doc_id}</Link>.
            </p>
         );
      } else {
         return (
                  <div class="alert alert-danger ">
                     <p className='m-0 text-white' style={{fontSize:'16px'}}>{this.state.txn_failed_msg}</p>
                  </div>
         );
      }
   }


   isValidTxnID(type){
      let txn_id = this.repayment_txn && this.repayment_txn.acc_stmt_txn_id ? this.repayment_txn.acc_stmt_txn_id : null;
      if(txn_id == null){
         alert('Please enter the transaction ID.');
         return;
      }

      var request ={
         'txn_id' : txn_id,
         'type' : type,
         'loan_doc_id' : this.state.summary.loan_doc_id,
         'mode' : 'credit'
      }
      this.setState({txn_failed_msg :"", acc_stmt_data : false, loader : true});

      validateTxnId(this.req(request, true))
         .then((response) =>{
            if(!response){this.setState({loader : false}) };
            if(response.status === "success"){
               this.setState({loader : false})
               if(response.data){
                  let data = response.data;
                  if(data.hasOwnProperty('message')){
                     this.setState({txn_failed_msg : data.message, calling_api: false})
                  }else{
                     this.setState({summary : data, txn_failed_msg: '',calling_api: false, acc_stmt_data : true})
                     if(data.to_capture && data.to_capture.new_status != 'settled'){
                        this.setState({is_part_payment : true})
                     }
                  }
               }else{
                  this.setState({txn_failed_msg : 'Please check the transaction ID. No payment received to the account with this transaction ID.'});
               }
            }else{
               alert(response.message + "\n" + JSON.stringify(response.data));  
            }
         })
   }

   handleTxnId(event, type){

      

      let  txn_id  = (type == 'payment_capture' || this.state.type == 'payment_capture') ? this.repayment_txn.acc_stmt_txn_id : (type == 'paid_to_diff_acc' || type == 'duplicate_payment' ||(type=='redemption_dr' || type=='investment_dr')   || type == 'testing' || (type=='redemption_cr' || type=='investment_cr')  )  ? this.state.recon_data.txn_id : (type == 'excess_capture' && !this.state.summary) ? this.state.data.stmt_txn_id : this.state.summary.txn_id;

      let acc_id =  (type == 'payment_capture' || this.state.type == 'payment_capture') ? `credit_${this.to_acc.value },debit_${this.to_acc.value }`  : type == 'paid_to_diff_acc' ? `credit_${this.state.recon_data.to_acc_id},debit_${this.state.data.account_id}` : (type == 'duplicate_payment'||(type=='redemption_cr' || type=='investment_cr')   ) ? this.state.recon_data.from_acc_id : (type=='redemption_dr' || type=='investment_dr')   ? this.state.recon_data.to_acc_id : (type == 'excess_capture' || type == 'testing')  && !this.state.summary ? this.state.data.account_id :  this.state.summary.from_ac_id ;

      let mode =  (type == 'payment_capture' || this.state.type == 'payment_capture' || type == 'testing'|| (type=='redemption_dr' || type=='investment_dr')  ) ? 'credit' : 'debit'

      let loan_doc_id = (type == 'payment_capture' || this.state.type == 'payment_capture' || (type == 'excess_capture' && this.state.summary)) ? this.state.summary.loan_doc_id : this.state.type == 'testing' ? null  : this.state.recon_data.loan_doc_id

      let txn_date = (type == 'payment_capture' || this.state.type == 'payment_capture' || type == 'excess_capture' ) ? this.repayment_txn.txn_date : type == 'excess_capture' && !this.state.summary ? this.state.data.stmt_txn_date :  this.state.recon_data.txn_date

      this.setState({txn_data : '', txn_msg : '', txn_failed_msg :""});

      if(type == "payment_capture"){
         this.setState({evidence_active:true,excess_capture:false})
      }else if(type=="excess_capture"){
         this.setState({evidence_active:false,excess_capture:true})
      }
      
      if(txn_id.length > 2){
         
         var request={'loan_doc_id' : loan_doc_id, 'acc_id' : acc_id , 'txn_id' : txn_id, 'txn_date': txn_date, 'is_skip_trans_id_check' :false, 'mode' : mode, type}

         if ((type=='redemption_dr' || type=='investment_dr')  ){
             request={...request, 'dr_amt': this.props.data.dr_amt}
         }else if((type=='redemption_cr' || type=='investment_cr')  ){
            request={...request, 'cr_amt': this.props.data.cr_amt}
         }
         
        handleTransactionID(this.req(request,true))

        .then((response) => {
          this.resp(response);
          if(!response){return };

            if(response.status === "success"){
              this.setState({apply_btn : '', txn_data :'', txn_failed_msg: '', is_skip_trans_id_check:true,upload_proof:false});

              if(response.data){
                if(response.data.hasOwnProperty('ignore_check')){

                  this.setState({txn_failed_msg : response.data.message, apply_btn :'disable_div',is_skip_trans_id_check:true,upload_proof:true});
                  alert("Transaction import is disabled for this account, Please proceed with transaction proof.")
                }
                else if(response.data.hasOwnProperty('message') && !response.data.hasOwnProperty('acc_stmt_records') ){
                  if (response.data.message[0].includes("You cannot capture the repayment here")){
                     this.setState({apply_btn :'disable_div', txn_failed_msg : response.data.message});
                  }else if(type == 'testing'){
                     this.setState({apply_btn :'disable_div', txn_failed_msg : response.data.message});
                  }else{
                     this.setState({txn_failed_msg : response.data.message});
                     if(type == 'payment_capture'){
                        this.setState({apply_btn:'disable_div'})
                     }
                  }
                }                
                else if( !response.data.hasOwnProperty('message')  && ( 
                  ( (type == 'paid_to_diff_acc' || type =='testing') && (response.data.total_amount == this.state.data.cr_amt || response.data.total_amount == this.state.data.dr_amt)) || 
                  (this.repayment_txn && response.data.total_amount == this.repayment_txn.amount) || 
                  response.data.total_amount == this.state.recon_data.reversal_amount ||
                  (this.state.summary && response.data.total_amount == this.state.summary.amount) || 
                  (type == 'payment_capture' && response.data.total_amount == this.state.data.cr_amt) || 
                  (type == 'excess_capture' && response.data.total_amount == this.state.recon_data.amount)
                  ||((type=='redemption_dr' || type=='investment_dr')   && this.state.recon_data.redemption_amount==response.data.total_amount)||((type=='redemption_cr' || type=='investment_cr')   && this.state.recon_data.investment_amount==response.data.total_amount)
                  )){
                  this.setState({txn_data : response.data.acc_stmt_records, apply_btn: '',is_skip_trans_id_check:false});
                  if(((type=='redemption_dr' || type=='investment_dr')  ||(type=='redemption_cr' || type=='investment_cr')  ) && response.data.acc_stmt_records){
                     this.setState({matching_records:response.data.acc_stmt_records})
                  }
                  // alert('Amount and txn ID matches for this FA. Please proceed with REVIEW and SYNC');
                }
                else if(response.data.hasOwnProperty('message') && ( type == 'paid_to_diff_acc' && (response.data.total_amount == this.state.data.cr_amt || response.data.total_amount == this.state.data.dr_amt)) || (this.repayment_txn && response.data.total_amount == this.repayment_txn.amount) || 
                        (this.state.summary && response.data.total_amount == this.state.summary.amount) || (type == 'payment_capture' && response.data.total_amount == this.state.data.cr_amt || response.data.total_amount == this.state.recon_data.reversal_amount  )
                        ||((type=='redemption_dr' || type=='investment_dr')   && this.state.recon_data.redemption_amount!=response.data.total_amount)||((type=='redemption_cr' || type=='investment_cr')   && this.state.recon_data.investment_amount!=response.data.total_amount)
                        ){
                  this.setState({txn_failed_msg : response.data.message,txn_data : response.data.acc_stmt_records});
                }
                else if(type == 'payment_capture' && (response.data.total_amount != this.repayment_txn.amount) ){
                  this.setState({txn_failed_msg : ['Please check the repayment amount.']});  
                }else if(type == 'excess_capture' && ( (this.state.summary && response.data.total_amount != this.state.summary.amount) || response.data.total_amount != this.state.recon_data.amount )){
                  this.setState({txn_failed_msg : ['Please check the excess amount.']});  
                }else if(type == 'duplicate_payment' && (response.data.total_amount != this.state.recon_data.reversal_amount)){
                  this.setState({txn_failed_msg : ['Please check the amount.']});  
                }

              }
              else{
                this.setState({txn_failed_msg : ['Please check the transaction ID. No payment received to the account with this transaction ID.']});
              }

            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );

      }
   }

   

   handleClose = (capture) => {
      if(!capture){
         const resp = window.confirm("You have not sync the payment. Are you sure to close?")
         if(resp == false){
            return 
         }
      } 
      
      this.setState({repayment_modal: false,summary : null,reviewed : 0,is_part_payment:false, is_skip_trans_id_check :false, txn_id_style :'disable_div'});
         if(this.props.mode == 'capture'){
            window.location.reload();
         }
   }

   handleWaiverModalClose = (event) =>{
       this.setState({waiver_modal:false,opacity:""})
   }

  
  
   handleModalChange = (event) =>{
      var summary = this.state.summary
      summary[event.target.id] = event.target.value
      this.setState({summary})
   }
   

   handleReconModalChange = (event, type = null) =>{

      var recon_data = this.state.recon_data

      var apply_btn = this.state.apply_btn

      let recon_reason = ['redemption_int_transfer', 'charges', 'interest', ]
      let type_arr = ['excess_reversal', 'dup_disb_rvrsl', 'duplicate_disbursal','int_transfer_cr', 'int_transfer_dr','waiver_reverse', 'duplicate_float_out', 'duplicate_float_out_reversal', 'capture_against_settled_loan',
      'redemption_dr','investment_dr'

   ]

      if(type_arr.includes(type)|| (event.hasOwnProperty('target') && recon_reason.includes(event.target.value))){
         apply_btn = ''
      }else{
         apply_btn = 'disable_div'
      }

      

      if((event.hasOwnProperty('target') && event.target.value) && (event.target.value == 'investment_cr' || event.target.value == 'redemption_cr' || event.target.value == 'investment_dr' || event.target.value == 'redemption_dr')){
         this.setState({txn_id_style:''})
      }

      if((event.hasOwnProperty('target') && event.target.value) && ((event.target.value == 'duplicate_float_out_reversal' || event.target.value == 'duplicate_float_out') || (type && (type == "duplicate_float_out_reversal" || type == "duplicate_float_out")))){
         recon_data[event.target.id] = event.target.value
      }

      if((event.hasOwnProperty('target') && event.target.value) && ((event.target.value == 'capture_against_settled_loan') || (type && (type == "capture_against_settled_loan")))){
         recon_data[event.target.id] = event.target.value
      }

      if(type == "txn_date"){
         recon_data[type] = moment(event).format("YYYY-MM-DD")
      }else if(type == null|| type == 'redemption_dr' || type == 'investment_dr' || type == 'redemption_cr' || type == 'investment_cr' ||type == 'excess_reversal' || (event.hasOwnProperty('target') && (event.target.value == 'investment_cr' || event.target.value == 'redemption_cr' || event.target.value == 'investment_dr' || event.target.value == 'redemption_dr' || event.target.value == 'duplicate_payment' || type == 'int_transfer_cr' || type == 'int_transfer_dr' || type == 'testing' || type == 'dup_disb_rvrsl' || type == 'duplicate_disbursal'|| type == 'waiver_reverse')) ){
            
         recon_data[event.target.id] = event.target.value
      }
      if(type=='redemption_remarks'||type=='investment_remarks'){
         recon_data[event.target.id] = event.target.value
         apply_btn = this.state.apply_btn
      }
      if(!type && (event.target.value == 'investment_cr' || event.target.value == 'redemption_cr' || event.target.value == 'investment_dr' || event.target.value == 'redemption_dr')){
         this.handleFAID(null)
      }
     
      this.setState({recon_data, apply_btn})   
      if(this.state.recon_data && this.state.recon_data.txn_id && (this.state.recon_data.reason == 'redemption_cr' || this.state.recon_data.reason == 'investment_cr' || this.state.recon_data.reason == 'redemption_dr' || this.state.recon_data.reason == 'investment_dr')&&(type !== 'redemption_remarks' && type !== 'investment_remarks')){
         this.handleTxnId(this.state.recon_data.txn_id,this.state.recon_data.reason)
      }
   }
  
   handleWaiverModal = (field) =>{
      this.setState({waiver_modal:true,opacity :0.7, waiver_field: field})
   }

   handleExcessModal = () =>{
      this.setState({excess_modal:true,opacity :0.7})
   }

   handleModify = () =>{
      this.setState({credit_style:"show_div"})
   }


   handlePartPaymentCheckboxClick = (event) =>{
      if(event.target.checked){
         var is_part_payment = true
      }else{
         var is_part_payment = false;
      }
      this.setState({is_part_payment})
   }

   handleExcessModalClose = (event) =>{
      this.setState({excess_modal:false,opacity:"", txn_data : '', txn_failed_msg:"", apply_btn : 'disable_div',  is_skip_trans_id_check :false, txn_id_style :'disable_div',excess_capture:false})
  }


   handleSubmit = (event) =>{
      var resp = true;
      var response = true;
      const summary = this.state.summary
      const amount = summary.amount
      const cust_name = summary.cust_name
      const status = summary.new_status
      const txn_amt = to_amount(summary.amount);
      const os_amt = to_amount(summary.current_os_amount);
      const os_penalty = to_amount(summary.os_penalty);
      const charges = to_amount(summary.charges);
      if(this.props.mode == 'capture'  ){
         if(this.state.is_part_payment){

            if(txn_amt == os_amt + os_penalty + charges){

              window.alert("Unable to process. You are trying to make full payment. Make sure whether you are trying to pay full amount or partial amount");
              return;
            }
         }else{
            if(txn_amt < os_amt){
               window.alert("Unable to process. You are trying to make partial payment less than Current OS amount.");
               return;
            }
          
         }
      }

      if (status == 'settled'){
         const resp = window.confirm("On syncing this transaction, customer's FA will be settled. Are you sure to proceed the sync process?")
         if(resp == false){
           return;
         }
      }
      // else{
      //    const new_os_principal = summary.os_principal - summary.to_capture.principal
      //    const new_os_fee = summary.os_fee - summary.to_capture.fee
      //    const new_os_penalty = summary.os_penalty - summary.to_capture.penalty
      //    const new_os_charges = summary.os_charges - summary.to_capture.charges
      //    let os_charges_str = this.props.data.acc_prvdr_code == 'RMTN' ? `New OS Charges    :  ${lbl_amt(new_os_charges,this.currency_code)}` : ''
      //    const total_os = new_os_principal + new_os_fee + new_os_penalty + new_os_charges
      //    const resp = window.confirm(`On syncing this transaction, customer's FA will still be in ${status} status. Below is the new outstanding details after this sync.      
      //       New OS Principal  :  ${lbl_amt(new_os_principal,this.currency_code)} 
      //       New OS Fee        :  ${lbl_amt(new_os_fee,this.currency_code)}
      //       ${os_charges_str}
      //       New OS Penalty    :  ${lbl_amt(new_os_penalty,this.currency_code)}
      //                            ------------------------------------
      //       Total OS          :  ${lbl_amt(total_os,this.currency_code)}
      //                            ------------------------------------
      //       Are you sure to proceed the sync process?`)
      //    if (resp == false) {
      //       return
      //    }
      // }

         {/*const resp = window.confirm(`Are you sure the payment of ${lbl_amt(amount, this.currency_code)} was made by ${cust_name}`);
         if(resp == false){
           return;
         }*/}
      var request = {loan_doc_id : this.state.summary.loan_doc_id, mode : this.props.mode, amount}
      if(this.props.mode == 'recon'){
         request.acc_stmt_id = this.state.summary.acc_stmt_id 
      }else if(this.props.mode == 'capture'){
         // request = {...this.repayment_txn, ...request};
         request.acc_stmt_txn_id = this.repayment_txn.acc_stmt_txn_id;
         request.txn_date = this.state.summary.stmt_txn_date;
         request.to_ac_id = this.state.summary.account_id;
         request.is_part_payment = this.state.is_part_payment;
         request.list_date = this.state.date
         request.is_skip_txn_id_chk = this.state.is_skip_trans_id_check
         if(this.state.is_skip_trans_id_check){
            request.photo_payment_proof = this.state.repayment_txn.photo_payment_proof
         }
      }
         create_capture_payment (this.req(request,true))
         .then((response)=>{
            this.resp(response)
            if(!response){return null;}
            if(response.status === "success"){
               alert('Payment captured successfully')

               this.handleClose("capture")

               this.setState({data : response.data})
               if(this.props.parent !== "home"){
                  this.props.onSync();
               }
               if(this.props.mode == "capture" || this.props.parent == "home"){
                  window.location.reload();

               }
              // this.handleTransactionlist()
            }else{
              alert(response.message)
            }
        })
   }

   handleReqWaiverModal = () =>{
      this.setState({req_waiver_modal:true,opacity :0.7})
   }

   handleReqWaiverModalClose = (event) =>{
      this.setState({req_waiver_modal:false,opacity:""})
   }

  

 handleFAID = (event) =>{
   if(event==null){
      const status = "enabled";
      const to_recon = 1;
      const lender_account = {status, to_recon};
      this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
      this.setState({ac_dropdown: ""})
      return;
   }
   const loan_doc_id = event.target.value;
   
   this.setState({ac_dropdown: "disable_div"})

   if(loan_doc_id.length > 7){

      getLoan(this.req({loan_doc_id}))
			    .then((response) => {
          		if(!response){return };
		            if(response.status === "success"){
		            	const loan = response.data.loan;
                     const status = "enabled";
                     const lender_code = loan.lender_code;
                     const network_prvdr_code = loan.acc_prvdr_code;
                     const lender_account = {status, network_prvdr_code, lender_code};
                     this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
                     this.setState({ac_dropdown: ""})
		            }else{
		              alert(response.message + "\n" + JSON.stringify(response.data));  
		            }
		        }
		    );
      }
 }

   requestWaiver = (event) =>{
      const new_outstanding_penalty = this.state.summary.new_outstanding_penalty
      const remarks = this.state.summary.remarks
      const loan_doc_id = this.state.summary.loan_doc_id
      const waived_penalty = Number(this.state.summary.waived_penalty)
      const task_type = 'waiver_request'
      const cust_id = this.state.summary.cust_id
      const mode = this.props.mode


      
      if(!waived_penalty || !remarks){
         alert("Please enter required fields")
      }else if(new_outstanding_penalty < waived_penalty){
         alert(`Request waiver penalty of ${lbl_amt(waived_penalty, this.currency_code)} shouldn't be greater than current outstanding penalty ${lbl_amt(new_outstanding_penalty, this.currency_code)}.`)
      }else{
         const resp = window.confirm(`The customer has an outstanding penalty of ${lbl_amt(new_outstanding_penalty, this.currency_code)}, you are going to request waiver for ${lbl_amt(waived_penalty, this.currency_code)}. Are you sure?`);
         if(resp == false){
           return;
         }
         var request = {mode, remarks, loan_doc_id, new_outstanding_penalty, waived_penalty, task_type, cust_id};

         createTask(this.req(request,true))
            .then((response)=>{
               this.resp(response)
               if(!response){return null;}
               if(response.status === "success"){
                  alert(response.message)
                  this.handleClose("capture")                                
               }
         })
      }
   }


   CaptureExcessPayment = (event) =>{
      const amount = this.state.summary.amount
      const txn_date = this.repayment_txn.txn_date
      const txn_id = this.state.summary.txn_id
      const from_ac_id =  this.state.summary.from_ac_id;
      const loan_doc_id = this.state.summary.loan_doc_id
      const cust_id = this.state.summary.cust_id
      const reason_for_skip = this.repayment_txn.reason_for_skip
      
      if(!txn_date || !txn_id || !from_ac_id || !amount){
         alert("Please enter required fields")
      }else{
         if (this.state.is_skip_trans_id_check  ){
            if(!this.repayment_txn.reason_for_skip){
               alert("Please select required fields")
               return
            }else if (!this.state.repayment_txn.photo_reversal_proof){
               alert("Please upload reversal transaction proof")
               return
            }
   
         }
         const resp = window.confirm(`Are you sure to capture the excess payment of ${lbl_amt(amount, this.currency_code)}`);
         if(resp == false){
           return;
         }
         var request = {amount,txn_date,txn_id,from_ac_id,loan_doc_id,cust_id, reason_for_skip}

         if(this.state.is_skip_trans_id_check){
            request.photo_reversal_proof = this.state.repayment_txn.photo_reversal_proof
         }
         captureExcess(this.req(request,true))
               .then((response)=>{
                  this.resp(response)
                  if(!response){return null;}
                  alert(response.message);
                  this.handleClose("capture")
               })
            }
   }
   updateWaiver = (event) => {
       const field = this.state.waiver_field
      const new_outstanding_value = this.state.summary["new_outstanding_" + field]
      const waived_amount = this.state.summary["waived_" + field]
      const remarks = this.state.summary.remarks
      const loan_doc_id = this.state.summary.loan_doc_id
      const acc_stmt_id = this.state.summary.acc_stmt_id
      const mode = this.props.mode
      if(!waived_amount || !remarks){
         alert("Please enter required fields")
      }else if(new_outstanding_value < waived_amount){
         alert(`Waived ${field} of ${lbl_amt(waived_amount, this.currency_code)} shouldn't be greater than current outstanding ${field} ${lbl_amt(new_outstanding_value, this.currency_code)}.`)
      }else{
         const resp = window.confirm(`The customer has an outstanding ${field} of ${lbl_amt(new_outstanding_value, this.currency_code)}, you are going to waive off ${lbl_amt(waived_amount, this.currency_code)}. Are you sure?`);
         if(resp == false){
           return;
         }
         var request = {waived_amount,remarks,loan_doc_id,mode,new_outstanding_value, waiver_field: field};
         if(this.props.mode == 'recon'){
            request.acc_stmt_id = acc_stmt_id
         }
         update_waiver(this.req(request,true))
            .then((response)=>{
               this.resp(response)
               if(!response){return null;}
               if(response.status === "success"){
                  alert(`${title_case(field)} waived successfully`)
                  if((response.data.txn_status == "penalty_waived" || response.data.txn_status == "fee_waived") && this.props.mode == "capture"){
                     this.handleClose("capture")
                     window.location.reload();
                  }else if(this.props.mode == "recon"){
                     this.handleClose("capture")
                     this.setState({data : response.data,waiver_modal: false,opacity:""})
                     if(this.props.parent !== 'home'){
                        this.props.onSync();
                     }
                  }else{
                     this.setState({summary:response.data , waiver_modal: false,opacity:""});
                  }                  
               }
         })
      }
   }

   handleUnlinkPayment = (event) =>{
      const amount = this.state.summary.amount
      const cust_name = this.state.summary.cust_name
      const resp = window.confirm(`Are you sure this payment of ${lbl_amt(amount, this.currency_code)} is not related ${cust_name}`);
      if(resp == false){
        return;
      }
      const request = this.state.unlink_payment
      unlink_payment (this.req(request,true))
        .then((response)=>{
            this.resp(response)
            if(!response){return null;}
            if(response.status === "success"){
              this.setState({repayment_modal: false});

            }else{
              alert(response.message)
            }
      })
   }
    
   handleReviewChange = (event,val) => {
      
      const target = event.target;
      const value = target.checked;      
      var reviewed = this.state.reviewed;
      var repayment_style = "disable_div";
      var review_count = null;
      var checked =false
      if(value){
         reviewed++
         checked = true
      }else{
         reviewed--
         checked = false
      }      
      if(this.state.excess_field){
         review_count = this.props.data.acc_prvdr_code == 'RMTN' ? 5 : 4;
      }else{
         review_count = this.props.data.acc_prvdr_code == 'RMTN' ? 4 : 3;
      }
      
      if(reviewed == review_count){

       repayment_style = ""
      }
      switch (val){
         case "fa" :
            this.setState(state=>state.fa=!this.state.fa);
            this.setState(state=>state.FAcheck_box_checked=!this.state.FAcheck_box_checked);
            break;
         case "fee" :
            this.setState(state=>state.fee=!this.state.fee);
            this.setState(state=>state.FEEcheck_box_checked=!this.state.FEEcheck_box_checked);
            break;
         case "charge" :
            this.setState(state=>state.charge=!this.state.charge);
            this.setState(state=>state.CHARGEcheck_box_checked=!this.state.CHARGEcheck_box_checked);
            break;
         case "penalty" :
            this.setState(state=>state.penalty=!this.state.penalty);
            this.setState(state=>state.PENcheck_box_checked=!this.state.PENcheck_box_checked);
            break;
         case "excess" :
            this.setState(state=>state.excess=!this.state.excess);
            this.setState(state=>state.EXCcheck_box_checked=!this.state.EXCcheck_box_checked);
            break;
      }
      this.setState({reviewed, repayment_style,checked});      
   }  


    handleManualReconModal = () =>{
      this.setState({recon_modal:true,opacity :0.7})
   }

   handleManualReconModalClose = () =>{
      this.setState({ac_dropdown:'disable_div',recon_modal:false,opacity :"",recon_data : {}, txn_data : '', txn_failed_msg:"", apply_btn : 'disable_div',allow_without_evidence:false, upload_proof: false})
   }
   handleStmt_txn_proof = (event,response)=>{ 
      if(event == 'uploaded'){
         this.setState({temp_msg:this.state.txn_failed_msg})
         this.setState({apply_btn:'',txn_failed_msg:'',stmt_txn_proof:true,photo_statement_proof:response.data.file_rel_path +'/'+ response.data.file_name})
      }else if(event == 'removed'){
         this.setState({apply_btn:'disable_div',txn_failed_msg:this.state.temp_msg,stmt_txn_proof:false})
      }
   }
   capture_repayment_summary = (event, button = "null") => {
      const statesCheck={...this.state}
      const checkStates= ['fa','fee','charge','penalty','excess','checked','reviewed','FAcheck_box_checked','FEEcheck_box_checked','CHARGEcheck_box_checked','PENcheck_box_checked','EXCcheck_box_checked']
      Object.keys(statesCheck).forEach(item=>{
         if(checkStates.includes(item)){
            if(item !=='checked'){
               this.setState(state => state[item]=false)
            }else{
               this.setState(state => state[item]=0)
            }
         }
      })

      if(button == "Apply" && (this.repayment_txn.amount == 0 || this.repayment_txn.acc_stmt_txn_id == "" || 
                               this.repayment_txn.to_ac_id == "" || this.repayment_txn.txn_date == null
                                ) ){
         alert("Please enter required fields")

      }else{  
         
         if (this.state.is_skip_trans_id_check  ){
            if(!this.repayment_txn.reason_for_skip){
               alert("Please select required fields")
               return
            }else if (!this.state.repayment_txn.photo_payment_proof){
               alert("Please upload payment transaction proof")
               return
            }
         }
         
     	   var request = {mode: this.props.mode, loan_doc_id : this.props.data.loan_doc_id};
         var api_call = true
         event.preventDefault();
         if(this.props.mode == 'capture'){            
            request.amount = to_amount(this.repayment_txn.amount)
            request.txn_id = this.repayment_txn.acc_stmt_txn_id
            request.acc_id = this.repayment_txn.to_ac_id
            request.is_skip_trans_id_check = this.state.is_skip_trans_id_check
            var btn_name = this.state.btn_name
            var sync_div_style = this.state.sync_div_style 
            var repayment_style = this.state.repayment_style
            var excess_field = false                       
         }else if(this.props.mode == 'recon'){              
            request.acc_stmt_id = event.target.id
         }
         if(this.props.mode == 'capture' && button == 'Apply'){             
            request.is_part_payment = this.state.is_part_payment            
            request.txn_date = this.repayment_txn.txn_date           
         }else if(this.props.mode == 'capture'&& button == 'Modify'){                          
               var credit_style = "show_div"
               sync_div_style = "hide_div"
               btn_name = "Apply"
               repayment_style = "disable_div"
               api_call = false                                          
            }
         this.setState({unlink_payment : request,credit_style,sync_div_style,btn_name,repayment_style})
     	   if(api_call){
            get_repayment_summary(this.req(request))
         	.then((response) => {
               this.resp(response)
               if(!response){return null;}
               if(response.status === "success"){
                  var summary = response.data  
                  var checked = this.state.checked  
                  var reviewed = this.state.reviewed  

                  if(summary.to_capture.excess > 0 ){
                     excess_field = true 
                  }
                  if(summary.mode == 'capture'&& button == 'Apply'){                     
                     sync_div_style = "show_div"
                     btn_name = "Modify"
                     credit_style = "disable_div"
                     if(checked){                        
                        repayment_style = ""                                                
                        reviewed = 0
                     }else{                        
                        repayment_style = "disable_div"
                     }
                     
                     window.alert("Note : Payment is not yet captured on Flow App\n \nCustomer Payment applied below. Please review and sync ")
                  }else if(summary.mode == 'recon'){                     
                     repayment_style = "disable_div"
                  }
                 this.setState({repayment_modal: true, sync_div_style, summary,btn_name,credit_style,repayment_style,excess_field,reviewed});
               }else{                  
                 alert(response.message)
               }
     		})
           }
         
      }                  
   }
   allow_without_evidence = (e) =>{
      this.setState({allow_without_evidence:e.target.checked,upload_proof:false})
      if(e.target.checked){
         this.setState({txn_data : '', txn_failed_msg:"", apply_btn : '',stmt_txn_proof : false})
      }
      }
   updateRecon = ()=>{

      if(this.state.recon_data.txn_id == ""){
         alert("Please enter required fields")
      }
     
      const recon_reason = this.props.data.recon_status == "31_paid_to_different_acc" ? "paid_to_diff_acc" : this.state.recon_data.reason 
      const stmt_txn_id = this.state.data.stmt_txn_id
      const from_acc_id = this.state.data.account_id
      const to_acc_id = this.state.recon_data.to_acc_id
      const loan_doc_id = this.state.recon_data.loan_doc_id
      const acc_stmt_id = this.state.data.id
      const txn_id = this.state.recon_data.txn_id
      const db_amount = this.state.data.dr_amt
      const cr_amount = this.state.data.cr_amt
      const reversal_amount = this.state.recon_data.reversal_amount
      const reversal_txn_id = this.state.recon_data.reversal_txn_id
      const stmt_txn_date = this.state.data.stmt_txn_date
      const txn_date = this.state.recon_data.txn_date
      const account_id = this.state.data.account_id
      const descr = this.state.data.descr
      const stmt_txn_type = this.state.data.stmt_txn_type
      const cr_stmt_txn_id = this.state.recon_data.cr_stmt_txn_id
      const dr_stmt_txn_id = this.state.recon_data.dr_stmt_txn_id
      const redemption_remarks = this.state.recon_data.redemption_remarks
      const investment_remarks = this.state.recon_data.investment_remarks
      const matching_records = this.state.matching_records
      const stmt_txn_proof = this.state.stmt_txn_proof
      const photo_statement_proof = this.state.photo_statement_proof
      const no_matching_evidence = this.state.allow_without_evidence
      var acc_stmt_ids = [this.state.data.id]
      if(this.state.txn_data){
         this.state.txn_data.map((data) => {
            acc_stmt_ids =  [...acc_stmt_ids, data.id]
         })
      }
            
      if(this.props.data.recon_status != "31_paid_to_different_acc" && !recon_reason){
         alert("Please select required fields")
      }else if(recon_reason == 'paid_to_diff_acc' && (!to_acc_id || !txn_id || !loan_doc_id)){
         alert("Please enter required fields")
      }else if(recon_reason == 'duplicate_payment' && (!txn_id || !reversal_amount || !loan_doc_id)){
         alert("Please enter required fields")
      }else if(recon_reason == 'excess_reversal' && (!loan_doc_id)){
         alert("Please enter required fields")
      }else if(recon_reason == 'testing' && (!txn_id)){
         alert("Please enter required fields")
      }else{
         const resp = window.confirm(`${this.state.allow_without_evidence?"Are you sure you want to manually recon this transaction without providing evidence? It will be marked as pending manual recon.":"Are you sure you want to manually recon for this transaction?"}`);
         if(resp == false){
           return;
         }
         var request = {txn_date,recon_reason, loan_doc_id, stmt_txn_id, from_acc_id, db_amount, cr_amount, to_acc_id, reversal_txn_id, reversal_amount, acc_stmt_id, txn_id, stmt_txn_date, account_id, acc_stmt_ids, descr,stmt_txn_type,dr_stmt_txn_id,cr_stmt_txn_id
            ,redemption_remarks,matching_records,investment_remarks,stmt_txn_proof,photo_statement_proof,no_matching_evidence
         };

         if(recon_reason == "duplicate_float_out" || recon_reason == "duplicate_float_out_reversal" ){
            request['sales_doc_id'] = this.state.recon_data.sales_doc_id
         }
        
         updateReconStatus(this.req(request,true))
            .then((response)=>{
               this.resp(response)
               if(!response){return null;}
               if(response.status === "success"){
                  alert(`${this.state.allow_without_evidence?"The transaction has been marked as pending manual recon without evidence. Manual reconciliation completed successfully.":"This transaction manually reconed successfully"}`)
                  this.handleManualReconModalClose("capture")
                  if(this.props.parent !== 'home'){
                     this.props.onSync();
                  }else if(this.props.parent == "home"){
                     window.location.reload();
   
                  }
               }
         })
      }
   

   }
    

   ManualReconModal = (props)=>{
      if(!this.state.recon_modal){
         return null
      }
   
      return(           
        <Modal className='manual_recon_modal' show={this.state.recon_modal} onHide={this.handleManualReconModalClose} backdrop="static" size="l" style={{top:"25px"}}>
            <Modal.Header closeButton className="modal-close-btn" show={this.state.recon_modal} onHide={this.handleManualReconModalClose}>
               <Modal.Title>Manual Recon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <form>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                     <label  className="col-md-5 label__name reset-margin"><b>A/C Number</b></label>
                     <label className="col-md-5 text-right reset-margin" >{props.acc_number} </label>  
                  </div>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                           <label  className="col-md-5 label__name reset-margin"><b>Txn Date</b></label>
                           <label className="col-md-5 text-right reset-margin" >{format_date(props.stmt_txn_date)} </label>  
                  </div>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Txn ID</b></label>
                        <label className="col-md-5 text-right reset-margin" >{props.stmt_txn_id} </label>  
                  </div>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Amount</b></label>
                        <label className="col-md-5 text-right reset-margin" >{ props.stmt_txn_type == "debit" ? lbl_amt(props.dr_amt, this.currency_code)  : lbl_amt( props.cr_amt, this.currency_code)  } </label>  
                  </div>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Description</b></label>
                        <label className="col-md-5 text-right reset-margin" >{props.descr} </label>  
                  </div>
                  {props.recon_status == "31_paid_to_different_acc" && props.loan_doc_id &&
                     <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Matching FA ID</b></label>
                        <label className="col-md-5 text-right reset-margin" >
                           <Link to={"/fa/view/"+props.loan_doc_id} style = {{color:"grey"}}  target="_blank" >{props.loan_doc_id}</Link>

                        </label>  
                     </div>
                  }
                  {props.stmt_txn_type == "credit" ?
                     <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>

                        <label  className="col-md-5 label__name reset-margin"><b>Reason</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <select id="reason" value={props.recon_status == '31_paid_to_different_acc' ? "paid_to_diff_acc" : this.state.recon_data.reason } onChange={(e) => { this.handleReconModalChange(e); this.setState({upload_proof:false})}} className="form-control" type="text" required="required" disabled = {props.recon_status == '31_paid_to_different_acc' ?  true : false}>
                              <option value = ""> Choose</option>
                              <option value = "paid_to_diff_acc" disabled> Paid To Different Account</option>
                              <option value = "duplicate_payment">Duplicate / Additional Payment</option>
                              <option value = "investment_cr">Investment (credit)</option>
                              <option value = "redemption_cr">Redemption (credit)</option>
                              <option value = "interest">Interest</option>
                              <option value = "int_transfer_cr">Internal Transfer (Credit)</option>
                              <option value = "dup_disb_rvrsl">Duplicate Disbursal Reversal</option>
                              <option value = "waiver_reverse">Penalty Waiver Reversal</option>
                              <option value = "capture_against_settled_loan">Excess Payment(Settled)</option>
                              <option value = "duplicate_float_out_reversal">Duplicate Float Out Reversal</option>
                           </select>                
                        </div>
                     </div>

                     :

                     <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Reason</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <select id="reason"  onChange={(e) => { this.handleReconModalChange(e);this.setState({upload_proof:false})}} className="form-control" type="text" required="required">
                              <option value = ""> Choose</option>
                              <option value = "excess_reversal">Excess Reversal</option>
                              <option value = "testing"> Testing</option>
                              <option value = "investment_dr"> Investment (Debit)</option>
                              <option value = "redemption_dr"> Redemption (Debit)</option>
                              <option value = "charges">Charges</option>
                              <option value = "int_transfer_dr">Internal Transfer (Debit)</option>
                              <option value = "redemption_int_transfer">Internal Transfer (For disbursement)</option>
                              <option value = "duplicate_disbursal">Duplicate Disbursal</option>
                              <option value = "duplicate_float_out">Duplicate Float Out</option>
                           </select>                
                        </div>
                     </div>
                  }


                  {props.recon_status == "31_paid_to_different_acc" &&
                  <>                     
                  <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                  <p className='text-left my-3'> 1. If you think this payment has been made to the  A/C number ({props.acc_number}) incorrectly by the customer<br/></p>
                  <p className='text-left my-3'> 2. Transfer this payment from the above account to the correct account and capture the transfer details below.<br/> </p>  
                     <div className="row justify-content-center align-items-center py-1">
                        <label  className="col-sm-5 font-weight-bold reset-margin"><b>FA ID</b> <font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="loan_doc_id" type="text" autocomplete="off" onChange = {(e) =>{ this.handleReconModalChange(e);this.handleFAID(e);this.enableTxnID(e)}} className="form-control reset-padding" min="0"/>
                        </div>
                        {/* <label className="col-md-5 text-right reset-margin" >{props.loan_doc_id} </label>   */}

                     </div> 
                     <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Internal Transfer A/C</b><font className="text-danger">   *  </font></label>
                        <div className={`col-sm-5 reset-margin ${this.state.ac_dropdown}`}>
                           <select id="to_acc_id"  onChange={(e) => { this.handleReconModalChange(e);this.enableTxnID(e)}} className="form-control" type="text" required="required">
                              {this.state.lender_accounts_dd}                
                           </select>              
                        </div>
                     </div>
                     <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                        <label className="col-sm-5 label__name reset-margin"><b> Txn Date</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <DatePicker closeOnSelect={true} id = "txn_date" onChange={(value) => {this.handleReconModalChange(value, "txn_date");this.enableTxnID(value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                        </div>
                     </div> 
                     <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                        <label className="col-sm-5 label__name reset-margin"><b>Txn ID</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="txn_id" type="text" autocomplete="off" onChange ={(e) => {this.handleReconModalChange(e);this.handleTxnId(e, "paid_to_diff_acc")}} className={`form-control reset-padding ${this.state.txn_id_style}`} min="0"/>
                        </div>
                     </div> 
                    
                     
                  </>

            }
            

               {this.state.recon_data.reason == "excess_reversal" &&
                  <>                     
                  <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                  <p className='text-left my-3'> 1. Capture the FA ID of the excess transaction below <br/></p>
                  <p className='text-left my-3'> </p>
                     <div className="row justify-content-center align-items-center py-1">
                        <label  className="col-sm-5 font-weight-bold reset-margin"><b>FA ID</b> <font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="loan_doc_id" type="text" autocomplete="off" onChange={(e) => { this.handleReconModalChange(e, 'excess_reversal');}} className="form-control reset-padding" min="0"/>
                        </div>
                     </div> 
                     
                  </>

               }


               {
                  this.state.recon_data.reason == "capture_against_settled_loan" &&
                  <>
                     <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                     <p className='text-left my-3'> 1. Capture the FA ID of the excess payment for a settled loan below <br/></p>
                     <p className='text-left my-3'> </p>
                     <div className="row justify-content-center align-items-center py-1">
                        <label  className="col-sm-5 font-weight-bold reset-margin"><b>FA ID</b> <font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="loan_doc_id" type="text" autocomplete="off" onChange={(e) => { this.handleReconModalChange(e, 'capture_against_settled_loan');}} className="form-control reset-padding" min="0"/>
                        </div>
                     </div> 
                  
                  </>

               }
               


               {this.state.recon_data.reason == "testing" &&
                  <>                     
                 <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                  <p className='text-left my-3'> 1. Ensure that we have received the above testing payment <br/></p>
                  <p className='text-left my-3'> 2. Capture the transaction ID of the reversal transaction below.<br/> </p>  
                  <p className='text-left my-3'> 3. Do not use the screen to recon until the reversal has been made.</p>
                     <div className="row justify-content-center align-items-center py-1">
                        <label  className="col-sm-5 font-weight-bold reset-margin"><b>Txn ID</b> <font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="txn_id" type="text" autocomplete="off" onChange={(e) => { this.handleReconModalChange(e, 'testing');this.handleTxnId(e, "testing")}} className="form-control reset-padding" min="0"/>
                        </div>
                     </div> 
                     
                  </>

               }
               {(this.state.recon_data.reason =="redemption_cr"||this.state.recon_data.reason =="investment_cr" || this.state.recon_data.reason =="redemption_dr"||this.state.recon_data.reason =="investment_dr"    )&&
                  <div className='d-flex mt-2  justify-content-end align-items-center col-11 p-0'>
                     <input class="form-check mr-2 pointer" type="checkbox" id="allow_without_evidence" value={this.state.allow_without_evidence} onChange={(e)=>this.allow_without_evidence(e)}/>

                     <label class="form-check pointer" htmlFor='allow_without_evidence' style={{fontSize:'13px'}}>Don't have matching evidence</label>
                  </div>
               }
                 {(this.state.recon_data.reason =="redemption_dr" || this.state.recon_data.reason =="investment_dr") && !this.state.allow_without_evidence &&
                   <div className={`${this.state.allow_without_evidence?'disable_div':''}`}>                     
                   <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                   <p className='text-left my-3'>1. Capture the details of the Redeemed/Invested transaction<br/> </p>  
                      <div className="row justify-content-center align-items-center py-1">
                      <label  className="col-sm-5 font-weight-bold reset-margin"><b>To A/C</b>
                      {/* <font className="text-danger">*</font> */}
                      </label>
                      <div className={`col-sm-5 reset-margin `}>
                      <select id="to_acc_id"  onChange={(e) => { this.handleReconModalChange(e);this.setState({upload_proof:false});this.enableTxnID(e,this.state.recon_data.reason)}} className="form-control" type="text" required="required">
                        {this.state.lender_accounts_dd}
                        {this.state.lender_accounts_dd && Config('redemption_and_investment_ops_acc')[this.country_code].map((item)=><option value={item.acc_number} >{item.acc_number} {item.acc_prvdr_code}</option>)}      
                     </select>                  
                         </div>
                   </div>
                   {!this.state.upload_proof && !this.state.allow_without_evidence  && <div>

                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b> Amount</b>
                         {/* <font className="text-danger">   *  </font> */}
                         </label>
                         <div className="col-sm-5 reset-margin">
                            <input  id="redemption_amount" autocomplete="off" type="number" onChange={(e) => { this.handleReconModalChange(e);this.enableTxnID(e,this.state.recon_data.reason)}} className="form-control reset-padding" min="0"/>
                         </div>
                      </div> 
 
                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b>Txn Date</b>
                         {/* <font className="text-danger">   *  </font> */}
                         </label>
                         <div className="col-sm-5 reset-margin">
                            <DatePicker closeOnSelect={true} id = "txn_date" onChange={(value) => {this.handleReconModalChange(value, "txn_date");this.enableTxnID(value,this.state.recon_data.reason)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                         </div>
                      </div> 
 
                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b>Txn ID</b><font className="text-danger">   *  </font></label>
                         <div className="col-sm-5 reset-margin">
                            <input  id="txn_id" autocomplete="off" type="text" onChange ={(e) => {this.handleReconModalChange(e);this.handleTxnId(e,this.state.recon_data.reason)}} className={`form-control reset-padding ${this.state.txn_id_style}`}  min="0"/>
                         </div>
                      </div> 
                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b>Remarks</b></label>
                         <div className="col-sm-5 reset-margin ">
                            <textarea  id="redemption_remarks" autocomplete="off" type="text" onChange ={(e) => {this.handleReconModalChange(e,'redemption_remarks')}} className={`form-control w-100 `} />
                         </div>
                      </div> 

                      </div>}
                   </div>

               }
                {(this.state.recon_data.reason =="investment_cr" || this.state.recon_data.reason =="redemption_cr")&& !this.state.allow_without_evidence &&
                   <div className={`${this.state.allow_without_evidence?'disable_div':''}`}>                     
                   <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                   <p className='text-left my-3'>1. Capture the transaction details of the redeemed/Invested transaction<br/> </p>  
                   
                      <div className="row justify-content-center align-items-center py-1">
                      <label  className="col-sm-5 font-weight-bold reset-margin"><b>From A/C</b>
                      {/* <font className="text-danger">*</font> */}
                      </label>
                      <div className={`col-sm-5 reset-margin `}>
                      <select id="from_acc_id"  onChange={(e) => { this.handleReconModalChange(e);this.setState({upload_proof:false});this.enableTxnID(e,this.state.recon_data.reason);}} className="form-control" type="text" required="required">
                        {this.state.lender_accounts_dd}
                        {this.state.lender_accounts_dd && Config('redemption_and_investment_ops_acc')[this.country_code].map((item)=><option value={item.acc_number} >{item.acc_number} {item.acc_prvdr_code}</option>)}      
                     </select>                   
                         </div>
                   </div>
                   {!this.state.upload_proof && !this.state.allow_without_evidence  && <div>

                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b> Amount</b>
                         {/* <font className="text-danger">   *  </font> */}
                         </label>
                         <div className="col-sm-5 reset-margin">
                            <input  id="investment_amount" autocomplete="off" type="number" onChange={(e) => { this.handleReconModalChange(e);this.enableTxnID(e,this.state.recon_data.reason)}} className="form-control reset-padding" min="0"/>
                         </div>
                      </div> 
 
                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b>Txn Date</b>
                         {/* <font className="text-danger">   *  </font> */}
                         </label>
                         <div className="col-sm-5 reset-margin">
                            <DatePicker closeOnSelect={true} id = "txn_date" onChange={(value) => {this.handleReconModalChange(value, "txn_date");this.enableTxnID(value,this.state.recon_data.reason)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                         </div>
                      </div> 
 
                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b>Txn ID</b><font className="text-danger">   *  </font></label>
                         <div className="col-sm-5 reset-margin">
                            <input  id="txn_id" autocomplete="off" type="text" onChange ={(e) => {this.handleReconModalChange(e);this.handleTxnId(e,this.state.recon_data.reason)}} className={`form-control reset-padding ${this.state.txn_id_style}`}  min="0"/>
                         </div>
                      </div> 
                      <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                         <label className="col-sm-5 label__name reset-margin"><b>Remarks</b></label>
                         <div className="col-sm-5 reset-margin ">
                            <textarea  id="investment_remarks" autocomplete="off" type="text" onChange ={(e) => {this.handleReconModalChange(e,'investment_remarks')}} className={`form-control w-100 `} />
                         </div>
                      </div> 
                     </div>}
                   </div>

               }

            {this.state.recon_data.reason == "duplicate_payment" &&
                  <>                     
                  <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                  <p className='text-left my-3'> 1. Ensure that you have reversed the above duplicate payment made by the customer <br/></p>
                  <p className='text-left my-3'> 2. Capture the amount and transaction ID of the  reversal transaction below.<br/> </p>  
                  <p className='text-left my-3'> 3. Do not use the screen to recon until the reversal has been made.</p>
                     <div className="row justify-content-center align-items-center py-1">
                        <label  className="col-sm-5 font-weight-bold reset-margin"><b>FA ID</b> <font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="loan_doc_id" type="text" autocomplete="off" onChange={(e) => { this.handleReconModalChange(e);this.enableTxnID(e);this.handleFAID(e)}} className="form-control reset-padding" min="0"/>
                        </div>
                        {/* <label className="col-md-5 text-right reset-margin" >{props.loan_doc_id} </label>   */}

                     </div> 
                     <div className="row justify-content-center align-items-center py-1">
                     <label  className="col-sm-5 font-weight-bold reset-margin"><b>From A/C </b><font className="text-danger">   *  </font></label>
                     <div className={`col-sm-5 reset-margin ${this.state.ac_dropdown}`}>
                     <select id="from_acc_id"  onChange={(e) => { this.handleReconModalChange(e);this.enableTxnID(e)}} className="form-control" type="text" required="required">
                        {this.state.lender_accounts_dd}                
                    </select>                   
                        </div>
                  </div>
                     <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                        <label className="col-sm-5 label__name reset-margin"><b>Reversal Amount</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="reversal_amount" autocomplete="off" type="number" onChange={(e) => { this.handleReconModalChange(e);this.enableTxnID(e)}} className="form-control reset-padding" min="0"/>
                        </div>
                     </div> 

                     <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                        <label className="col-sm-5 label__name reset-margin"><b>Reversal Txn Date</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <DatePicker closeOnSelect={true} id = "txn_date" onChange={(value) => {this.handleReconModalChange(value, "txn_date");this.enableTxnID(value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                        </div>
                     </div> 

                     <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                        <label className="col-sm-5 label__name reset-margin"><b>Reversal Txn ID</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id="txn_id" autocomplete="off" type="text" onChange ={(e) => {this.handleReconModalChange(e);this.handleTxnId(e, "duplicate_payment")}} className={`form-control reset-padding ${this.state.txn_id_style}`}  min="0"/>
                        </div>
                     </div> 
                  </>

                 }  
                  {(this.state.recon_data.reason == "int_transfer_cr" || this.state.recon_data.reason == "int_transfer_dr") &&
                  <>
                     <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                     <p className='text-left my-3'> 1. Enter the {this.state.recon_data.reason == "int_transfer_cr" ? "debit" : "credit"} statement transaction id for this {this.state.recon_data.reason == "int_transfer_cr" ? "credit" : "debit"} transaction <br/></p> 
                     <p className='text-left my-3'> </p>
                        <div className="row justify-content-center align-items-center py-1">
                           <label  className="col-sm-5 font-weight-bold reset-margin"><b>Stmt Txn Id</b> <font className="text-danger">   *  </font></label>
                           <div className="col-sm-5 reset-margin">
                              <input  id={this.state.recon_data.reason == "int_transfer_cr" ? "dr_stmt_txn_id" : "cr_stmt_txn_id"} type="text" autocomplete="off" onChange={(e) => { this.handleReconModalChange(e, this.state.recon_data.reason);}} className="form-control reset-padding" min="0"/>
                           </div>
                        </div> 
                  </>
                 }
                 {(this.state.recon_data.reason == "duplicate_disbursal" || this.state.recon_data.reason == "dup_disb_rvrsl"|| this.state.recon_data.reason == "waiver_reverse" || this.state.recon_data.reason == "duplicate_float_out" || this.state.recon_data.reason == "duplicate_float_out_reversal") &&
                 <>
                     <p className='text-left my-3' style={{fontSize:"14px"}}> <b>Note</b></p> 
                  <p className='text-left my-3'> {this.state.recon_data.reason == "waiver_reverse"?'1.This FA should contain Penalty Waiver for this amount': (this.state.recon_data.reason == "duplicate_float_out" || this.state.recon_data.reason == "duplicate_float_out_reversal") ? "1. Capture the FA ID of the Duplicate float out transaction below" : "1. Capture the FA ID of the Duplicate disbursement transaction below"} <br/></p>
                  <p className='text-left my-3'> </p>
                     <div className="row justify-content-center align-items-center py-1">
                        <label  className="col-sm-5 font-weight-bold reset-margin"><b>FA ID</b> <font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-margin">
                           <input  id={(this.state.recon_data.reason == "duplicate_float_out" || this.state.recon_data.reason == "duplicate_float_out_reversal") ? "sales_doc_id" : "loan_doc_id"} type="text" autocomplete="off" onChange={(e) => { this.handleReconModalChange(e, this.state.recon_data.reason);}} className="form-control reset-padding" min="0"/>
                        </div>
                     </div> 
                 </>
                 }
                  {this.state.upload_proof && !this.state.allow_without_evidence &&
                   <div className={`${this.state.allow_without_evidence?'disable_div':''}`}> 
                        <div className="row my-0 justify-content-center align-items-center py-1 my-5" style={{height:42}}>
                           <label className="col-sm-6 label__name reset-margin"><b className='ml-5 pl-4 '>Upload Proof</b></label>
                           <div className="col-sm-6 reset-margin ">
                              <FileUpload stmt_txn_proof ={true} id ="stmt_txn_proof" mode = "create" entity ="loan_txns" txn_id={this.props.data.stmt_txn_id}  type="image"   handleStmt_txn_proof = {this.handleStmt_txn_proof}     
                              onCreateUpload={this.setChangeToState} onEditUpload={(e) => {this.handleSave(e, "reload")}}  label="Transaction Evidence" />  
                           
                           </div>
                        </div> 
                        </div>
                        
                     }
                     
                 <div className="row d-flex justify-content-center">
                     {this.state.txn_data &&
                        <>
                           <br/>
                           <div className="alert alert-success mt-3">
                              {this.state.txn_data.map((data)  =>
                                 <h6>Amount : {data.dr_amt ? data.dr_amt : data.cr_amt} | Txn Date : {data.stmt_txn_date} | Description : {data.descr}</h6>
                                 )
                              }
                           </div>
                        </>
                     }
                    {this.state.txn_failed_msg &&
                        <>                        <br/>
                        <div className="alert alert-danger mt-3">
                           {this.state.txn_failed_msg.map(data  =>(
                                 <h6>{data}</h6>
                           ))}
                        </div>
                        </>

                     }
                  </div> 
               </form>
            </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick = {this.handleManualReconModalClose} >Close</Button>
                  <Button variant="primary" className = {this.state.apply_btn} disabled={this.state.calling_api
                     ||this.state.txn_failed_msg?true:false
                     } style={{marginLeft:10}} onClick = {this.updateRecon}>Submit</Button>
                  </Modal.Footer>
            </Modal> 
            )
            }

   WaiverModal = (summary, field)=>{
      if(!summary){
         return null
      }
      const fieldName = title_case(field)
      return(           
        <Modal show={this.state.waiver_modal} onHide={this.handleWaiverModalClose} backdrop="static" size="l" style={{top:"25px"}}>
            <Modal.Header closeButton className="modal-close-btn" show={this.state.waiver_modal} onHide={this.handleWaiverModalClose}>
               <Modal.Title>Waive {fieldName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <form>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Current OS {fieldName}</b></label>
                        <label className="col-md-4 text-right reset-margin" >{lbl_amt(summary['os_'+field],this.currency_code)} </label>
                  </div>
                  <div className="row justify-content-center align-items-center py-1">
                     <label  className="col-sm-5 font-weight-bold reset-margin"><b>Waive {fieldName}</b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-4 reset-padding">
                        <input autoComplete='off' id={`waived_${field}`} type="number" onChange = {this.handleModalChange} className="form-control reset-padding" min="0"/>
                     </div>
                  </div> 
                  <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                     <label className="col-sm-5 reset-margin"><b>Remarks</b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-4 reset-padding">
                        <textarea id = "remarks" type="text" onChange = {this.handleModalChange} className="form-control reset-padding"/>
                     </div>
                  </div> 
               </form>
            </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick = {this.handleWaiverModalClose} >Close</Button>
                  <Button variant="primary" disabled={this.state.calling_api} style={{marginLeft:10}} onClick = {this.updateWaiver} >Waive {fieldName}</Button>
               </Modal.Footer>
            </Modal> 
      )
    }  



    RequestWaiverModal = (summary)=>{
      if(!summary){
         return null
      }
      return(           
        <Modal show={this.state.req_waiver_modal} onHide={this.handleReqWaiverModal} backdrop="static" size="l" style={{top:"25px"}}>
            <Modal.Header closeButton className="modal-close-btn" show={this.state.req_waiver_modal} onHide={this.handleReqWaiverModalClose}>
               <Modal.Title>Request Waiver</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <form>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Current OS Penalty</b></label>
                        <label className="col-md-4 text-right reset-margin" >{lbl_amt(summary.os_penalty,this.currency_code)} </label>  
                  </div>
                  <div className="row justify-content-center align-items-center py-1">
                     <label  className="col-sm-5 font-weight-bold reset-margin"><b>Request Waiver Amount</b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-4 reset-padding">
                        <input autoComplete='off'  id="waived_penalty" type="number" onChange = {this.handleModalChange} className="form-control reset-padding" min="0"/>
                     </div>
                  </div> 
                  <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                     <label className="col-sm-5 reset-margin"><b>Reason</b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-4 reset-padding">
                        <textarea id = "remarks" type="text" onChange = {this.handleModalChange} className="form-control reset-padding"/>
                     </div>
                  </div> 
               </form>
            </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick = {this.handleReqWaiverModalClose} >Close</Button>
                  <Button variant="primary" disabled={this.state.calling_api} style={{marginLeft:10}} onClick = {this.requestWaiver} >Request Waiver</Button>
               </Modal.Footer>
            </Modal> 
      )
    }  


     
    
    ReturnExcessModal = (summary)=>{
      if(!summary){
         return null
      }
      return(           
        <Modal show={this.state.excess_modal} onHide={this.handleExcessModalClose} backdrop="static" size="l" style={{top:"25px"}}>
            <Modal.Header closeButton className="modal-close-btn" show={this.state.excess_modal} onHide={this.handleExcessModalClose}>
               <Modal.Title>Capture Excess Reversal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <form>
                  <div className="row justify-content-center py-1" style={{alignItems:"center",height:42}}>
                        <label  className="col-md-5 label__name reset-margin"><b>Excess Amount</b><font className="text-danger">   *  </font></label>
                        <div className="col-sm-5 reset-padding">
                           <input autoComplete='off'  id="amount" type="text" onChange ={(e) => {this.handleModalChange(e);this.enableTxnID(e)}} className="form-control reset-padding" min="0"/>
                        </div>
                  </div>
                  <div className="row justify-content-center align-items-center py-1">
                     <label  className="col-sm-5 font-weight-bold reset-margin"><b>Refunded From A/C </b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-5 reset-padding">
                     <select id="from_ac_id"  onChange={(e) => { this.handleModalChange(e);this.enableTxnID(e)}} className="form-control" type="text" required="required">
                        {this.state.lender_accounts_dd}                
                    </select>                   
                        </div>
                  </div>
                  <div className="row justify-content-center align-items-center py-1">
                     <label  className="col-sm-5 font-weight-bold reset-margin"><b>Customer's A/C </b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-5 reset-padding">
                        
                     
                     <select id="to_ac_id"  ref={select => this.cust_acc = select} onChange={(e) => { this.handleSelfChange(e);this.enableTxnID(e)}} className="form-control" type="text" required="required">
                        {this.state.customer_accounts_dd}                
                    </select>                   
                        </div>
                  </div> 

                  <div className="row my-0 justify-content-center align-items-center py-1" style={{height:42}}>
                     <label className="col-sm-5 reset-margin"><b>Date</b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-5 reset-padding">
                     <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("txn_date", value);this.enableTxnID(value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                     </div>
                  </div> 
                  <div className="row justify-content-center align-items-center py-1">
                     <label  className="col-sm-5 font-weight-bold reset-margin"><b>Txn ID</b><font className="text-danger">   *  </font></label>
                     <div className="col-sm-5 reset-padding">
                           <input autoComplete='off'  id="txn_id" type="text" onChange={(e) => { this.handleModalChange(e);this.handleTxnId(e, "excess_capture")}}  className={`form-control reset-padding ${this.state.txn_id_style}`} min="0"/>
                     </div>

                     <div className="col-sm-5 reset-padding">
                        
                     </div>

                     {/* {check_priv('loan', 'skip_txn_id_check') &&
                        <div className={`col-sm-5 reset-padding ${this.state.txn_id_style}`}>
                           <label className={`mt-2 ${this.state.credit_style}`}><input type="checkbox" onChange={(e) => this.handleSkipTransIDChekCheckboxClick(e,'excess_capture')}/>&nbsp;
                           <b className="red_color_font">Skip transaction ID check</b></label>
                        </div>
                     } */}
                  </div> 
                  
                  <br/>
                     <div className="row d-flex justify-content-center">
                              
                              {this.state.txn_data && 
                                 <>
                                    <br/>
                                    <div class="alert alert-success">
                                       {this.state.txn_data.map((data)  =>
                                          <h6>Amount : {data.dr_amt} | Txn Date : {data.stmt_txn_date} | Description : {data.descr}</h6>
                                          )
                                       }
                                    </div>
                                 </>
                              }
                              {this.state.txn_failed_msg &&
                                 <div class="alert alert-danger ">
                                    <h6>{this.state.txn_failed_msg}</h6>
                                 </div>
                              }
                        </div>  
                  
                     {this.state.txn_failed_msg && check_priv('loan', 'skip_txn_id_check') &&

                     <>
                           {/* <br/>
                        <fieldset className = "border border-dark p-2">
                        <legend className="w-auto text-labelblue" style ={{fontSize : "15px"}} >Transaction Evidence</legend>
                       
                           <div className="row no-margin justify-content-center align_ver_center">
                              <label className="col-md-5 reset-padding no-margin"><b>Reason for Skip</b><font className="text-danger">   *  </font></label>
                                 <div className="col-sm-5 reset-padding">
                                    <select id="reason_for_skip" onChange={(e) => { this.handleSelfChange(e);}} className={`form-control ${this.state.credit_style}`} type="text" required="required">
                                       {this.getSelectOptions("skip_txn_id_check")}
                                    </select>
                                 </div>                                        
                           </div>
                           <div className="row labelTopgap align-items-center mt-2" style={{marginLeft: "40px"}}>
                              <FileUpload id="photo_reversal_proof" mode = "create" entity ='loan_txns' type="image" txn_id = {this.state.summary.txn_id}         
                                    onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.setChangeToState} label="Revesal Transaction Proof" />
                           </div>
                           </fieldset> */}
                        
                     
                        {/* <div className="row no-margin justify-content-center align_ver_center">
                           
                           <label className="col-md-5 reset-padding no-margin"><b>Reason for Skip Txn ID Check</b><font className="text-danger">   *  </font></label>
                           <div className="col-sm-5 reset-padding">
                              <select id="reason_for_skip" onChange={(e) => { this.handleSelfChange(e);}} className={`form-control ${this.state.credit_style}`} type="text" required="required">
                                 {this.getSelectOptions("skip_txn_id_check")}
                              </select>
                           </div> 
                           
                        </div>
                        <div className="row labelTopgap align-items-center mt-2" style={{marginLeft: "42px"}}>
                              <FileUpload id="photo_reversal_proof" mode = "create" entity ='loan_txns' type="image" txn_id = {this.state.summary.txn_id}      
                                    onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.setChangeToState} label="Disbursal Proof Photo" />
                        </div> */}
                     </>
                     }
                  <br/>

                              
               </form>
            </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick = {this.handleExcessModalClose} >Close</Button>
                  <Button variant="primary" disabled={this.state.calling_api} className = {this.state.apply_btn} style={{marginLeft:10}} onClick = {this.CaptureExcessPayment} >Capture Excess</Button>
               </Modal.Footer>
            </Modal> 
            /*
            <WaivePenalty
               summary = {this.state.summary}
               modal_disp = {this.state.waiver_modal}
               />*/
      )
    }

   RepaymentSummaryModal = (summary) => {
      if(!summary){
         return null
      }
      summary.paid_penalty =  summary.penalty_waived + summary.penalty_collected 
      if(summary.to_capture.penalty > (summary.to_capture.tot_prov_penalty - summary.penalty_waived -summary.penalty_collected)&& summary.to_capture.penalty < 0 ){
         window.alert("");
      }
      summary.new_outstanding_penalty = summary.to_capture.tot_prov_penalty > 0 ? summary.to_capture.tot_prov_penalty - 

                                                summary.penalty_waived - 
                                                summary.penalty_collected - summary.to_capture.penalty : 0;

      summary.new_outstanding_fee = summary.flow_fee - summary.fee_waived - summary.paid_fee - summary.to_capture.fee

      summary.penalty_overlay =     
                        <form className = "p-2">      
                           <div className="form-group row  justify-content-center reset-margin ">
                              <label className="col-sm-6 label__name " ><b>Provisional Penalty </b></label>
                              <label className="col-md-6 text-right pl-0">{lbl_amt(summary.to_capture.tot_prov_penalty,this.currency_code)} </label>  
                            </div>
                           <div className="form-group row justify-content-center reset-margin">
                              <label className="col-sm-6 label__name " ><b> Waived Penalty </b></label>
                              <label className="col-md-6 text-right pl-0">{lbl_amt(summary.penalty_waived,this.currency_code)} (-) </label>  
                           </div>
                           <div className="form-group row justify-content-center reset-margin ">
                              <label className="col-sm-6 label__name" ><b> Already Paid Penalty</b></label>
                              <label className="col-md-6 text-right pl-0" >{lbl_amt(summary.penalty_collected,this.currency_code)} (-)</label>  
                           </div>
                           <div className="form-group row justify-content-center reset-margin">
                              <label className="col-sm-6 label__name " ><b> Payment now against Penalty </b></label>
                              <label className="col-md-6 text-right pl-0">{lbl_amt(summary.to_capture.penalty,this.currency_code)} (-) </label>  
                           </div>
                           <hr/>
                           <div >
                              <label className="col-sm-6 label__name " ><b>OS Penalty after Payment</b></label>
                              <label className="col-md-6 text-right pl-0">{lbl_amt(summary.new_outstanding_penalty,this.currency_code)} </label>  
                           </div>
                           <hr/>
                        </form>  

         //  summary.payment_status_overlay =  
         // <>
         //                   <div className="form-group row justify-content-center reset-margin">
         //                      <label className="col-sm-6 label__name " ><b>Payment Status</b></label>
         //                      <label className="col-md-6 text-right pl-0">{title_case(summary.payment_status)} </label>  
         //                   </div>
         //                   {summary.payment_status == "review_pending" &&

         //                   <div className="form-group row justify-content-center reset-margin mt-2 ">
         //                      <label className="col-sm-6 label__name " ><b>Review Reason</b></label>
         //                      <label className="col-md-6 text-right pl-0">{title_case(summary.review_reason)} </label>  
         //                   </div>
         //                }
         //    </>


      
   return(
    

      <Modal show={this.state.repayment_modal}  backdrop="static" size="xl" style={{opacity:this.state.opacity}}>
         <Modal.Header closeButton className="modal-close-btn" show={this.state.repayment_modal} onHide={this.handleClose}>
            <Modal.Title>{this.props.title}</Modal.Title>
    
         </Modal.Header>
         <Modal.Body>   
            <div className="">
               <form>
                  {this.props.mode === 'recon' && 
                  <>
                  <Card>
                     <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}>
                        <h4 className='no-margin'>
                           <Logo file_name={"t_"+get('acc_prvdr_logos')[this.state.summary.acc_prvdr_code]} entity="acc_prvdr" 
                              entity_code={this.state.summary.acc_prvdr_code} 
                              country_code={this.country_code}
                              color="logo_style" />&nbsp;&nbsp;
                        Account Credit Transaction</h4>
                     </Card.Header>
                     <Card.Body>
                        <div className ="row">
                           <div className = "col-md-6">
                              <div className="row justify-content-center align-items-center py-2">
                                 <label className="col-md-4 no-margin"><b>Txn ID</b> </label>
                                 <label className="col-md-6 no-margin">{summary.stmt_txn_id}</label>  
                              </div>                              
                              <div className="row justify-content-center align-items-center py-2">
                                 <label className="col-md-4 no-margin"><b>Txn Desc</b></label>
                                 <label className="col-md-6 no-margin">{summary.descr}</label>
                              </div> 
                           </div>                      
                           <div className = "col-md-6">
                              <div className="row justify-content-center align-items-center py-2">
                                 <label className="col-md-4 no-margin"><b>Txn Date</b> </label>
                                 <label className="col-md-6 no-margin" >{format_date(summary.stmt_txn_date)}</label>  
                              </div>
                              <div className="row justify-content-center align-items-center py-2">
                                 <label className="col-md-4 no-margin"><b>Txn Amount </b></label>
                                 <label className="col-md-6 no-margin" >{lbl_amt(summary.cr_amt, this.currency_code)}  </label>  
                              </div>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
                  <Card className = "my-2">
                     <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4 style = {{fontSize : '1.7rem'}} className="no-margin"><img src="/logo_white.png"/> Matching FA Record (<Link style = {{color :"white"}}  to={"/fa/view/"+summary.loan_doc_id} target="_blank" >{summary.loan_doc_id}</Link> | <Link style = {{color :"white"}} to={"/borrower/indiv/view/"+summary.cust_id} target="_blank">{summary.cust_id}</Link>) </h4></Card.Header>
                     <Card.Body>
                        <div className = "row">
                           <div className = "col-md-6">
                              <div className="row reset-margin justify-content-center align-items-center py-2">
                                 <label className="col-md-4 no-margin" ><b>Customer</b></label>
                                 <label className="col-md-6 no-margin">{summary.biz_name}</label>  
                              </div>
                           </div>
                           <div className = "col-md-6">
                              <div className="row justify-content-center align-items-center py-2">
                                 <label className="col-md-4 no-margin" ><b>Status</b></label>
                                 <label className="col-md-6 no-margin">{summary.status}</label>  
                              </div>
                           </div>
                        </div>
                        <div className ="row">
                           <div className = "col-md-6">
                              <fieldset className = "border border-dark p-2">
                                 <legend className="w-auto" style ={{fontSize : "15px"}}>Total Due</legend>
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label className="col-md-5 no-margin"><b>FA Due</b></label>
                                    <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.loan_principal,this.currency_code)} </label>  
                                 </div>
                                 <div className="row reset-margin justify-content-center py-2 align-items-center">
                                    <label className="col-md-5 no-margin"><b>Fee Due</b></label>
                                    <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.flow_fee,this.currency_code)} </label>  
                                 </div>
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label className="col-md-5 no-margin"><b>Penalty Due</b></label>
                                    <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.to_capture.tot_prov_penalty,this.currency_code)} </label>  
                                 </div>
                              </fieldset>
                           </div>
                           <div className = "col-md-6">
                              <fieldset className = "border border-dark p-2">
                                 <legend className="w-auto" style ={{fontSize : "15px"}} >Already Paid</legend>
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 no-margin"><b>FA Paid</b></label>
                                    <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.paid_principal,this.currency_code)} </label>  
                                 </div>
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 no-margin"><b>Fee Paid</b></label>
                                    <label className="col-md-5 text-right" >{lbl_amt(summary.paid_fee,this.currency_code)} </label>  
                                 </div>
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 no-margin"><b>Penalty (Paid + Waived) </b></label>
                                    <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.paid_penalty,this.currency_code)} </label>  
                                 </div>
                              </fieldset>
                           </div>
                        </div>
                        <fieldset className = "border border-dark mt-2 p-2">
                           <legend className="w-auto" style ={{fontSize : "15px"}} >Current Outstanding</legend>
                           <div className = "row justify-content-around">
                              <div className = "col-md-3">
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 reset-padding"><b>OS Principal</b></label>
                                    <label className="col-md-7 text-right" >{lbl_amt(summary.os_principal,this.currency_code)} </label>  
                                 </div>
                              </div>
                              <div className = "col-md-3">
                                 <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 label__name reset-padding"><b>OS Fee</b></label>
                                    <label className="col-md-7 text-right">{lbl_amt(summary.os_fee,this.currency_code)} </label>
                                 </div>
                              </div>
                         <div className = "col-md-3"> 
                                 <div className="row reset-margin  justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 label__name reset-padding mb-1"><b>OS Penalty</b></label>
                                    <label className="col-md-7 text-center" >{lbl_amt(summary.os_penalty,this.currency_code)}                                          
                                    </label>  
                                    {(summary.os_penalty-summary.to_capture.penalty)>0 && summary.request_waiver == false &&
                                       <>
                                          <h6 className = "col-md-5"></h6>
                                          <h6 className = "text-center col-md-8"><Link to = "#" onClick ={this.handlePenaltyModal}>Waive Penalty</Link></h6>
                                       </>
                                    } 

                                    {(summary.os_penalty-summary.to_capture.penalty)>0 && summary.request_waiver &&
                                       <>
                                          <h6 className = "col-md-5"></h6>
                                          <h6 className = "text-center col-md-8"><Link to = "#" onClick ={this.handleReqWaiverModal}>Request Waiver</Link></h6>
                                       </>
                                    } 
                                 </div>
                              </div>
                           </div>                        
                        </fieldset>

                      </Card.Body>
                  </Card>
                  </>
                 }
                 {this.props.mode === 'capture' &&

                  <>
                  <Card className = "my-2">
                     <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4 className='no-margin'><img src="/logo_white.png"/></h4></Card.Header>
                     <Card.Body>

                        <CurrentOSFA handleExcessModal = {this.handleExcessModal} handleReqWaiverModal = {this.handleReqWaiverModal} handleWaiverModal = {this.handleWaiverModal}summary = {summary}/>


                      </Card.Body>
                  </Card>
                  {check_priv("loan","capture_repayment_new") &&
                  
                        <Card>
                           <Card.Header style = {{backgroundColor: "#01062c" , color: "white",padding:15}}>
                           <h4 className='no-margin' style = {{fontSize :20}}><Logo file_name={"t_"+get('acc_prvdr_logos')[this.state.summary.acc_prvdr_code]} entity="acc_prvdr" 
                              entity_code={this.state.summary.acc_prvdr_code} 
                              country_code={this.country_code}
                              color="logo_style" />&nbsp;&nbsp;
                           Account Credit Transaction</h4></Card.Header>
                           <Card.Body>
                             <div className='d-flex align-items-center py-4 mb-3'>
                                 <div className='col-2'>
                                    <p className='m-0 text-white' style={{fontSize:'16px'}}>Transaction ID</p>
                                 </div>

                                 <div className="col-sm-2 col-md-3 p-0">
                                    <input 
                                       autoComplete='off' 
                                       type="text" 
                                       id = "acc_stmt_txn_id" 
                                       onChange={(e) => {this.handleSelfChange(e)}}
                                       onKeyDown={(e) => { 
                                          if (e.key === 'Enter') { 
                                          e.preventDefault(); 
                                          } 
                                       }}
                                       className="form-control" 
                                       style={{fontSize:'15px'}} 
                                       placeholder='Enter txn ID here' 
                                       tabIndex = '-1'  
                                       required="required"
                                    />                                                                                 
                                 </div>

                                 <div className = "col" >
                                    <button className='btn btn-primary btn-lg mx-3 px-4 ' disabled={this.state.loader} type='button' onClick={() => this.isValidTxnID("payment_capture")}>Validate</button>
                                 </div>
                                 

                             </div>

                             {this.state.loader && 
                                 <div className='py-2'>
                                    <p className='text-light mt-3 text-center'>Loading...</p>
                                    <div className="product_takeup_loader"></div>
                                 </div>
                              }

                             { this.state.txn_failed_msg && 
                                 <div className='py-2'>
                                    {
                                       this.handleSpecificMessage(this.state.txn_failed_msg)
                                    }
                                 </div>
                              }

                              {this.state.acc_stmt_data && 
                                 <>
                                    <div className='d-flex justify-content-around align-items-center p-3 rounded' style={{backgroundColor: '#009A59', padding:'10px 18px'}}>
                                          <div className='d-flex '>
                                             <p className='text-white m-0 px-3 fw-normal font_18' >Account</p>
                                             <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.account_num)}</p>
                                          </div>
                                          <div className='d-flex'>
                                             <p className='text-white m-0 px-3 fw-normal font_18' >Repayment Amount</p>
                                             <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.cr_amt, 'amt')}</p>
                                          </div>
                                          <div className='d-flex'>
                                             <p className='text-white m-0 px-3 fw-normal font_18' >Payment Date</p>
                                             <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.stmt_txn_date, 'date')}</p>
                                          </div>
                                    </div>

                                    <div className=' d-flex justify-content-around align-items-center py-5 px-3 rounded'>
                                          <div className='d-flex '>
                                             <p className='text-white m-0 px-3 fw-normal font_18' >FA Amount</p>
                                             <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.to_capture.principal, 'amt')}</p>
                                          </div>
                                          <div className='d-flex'>
                                             <p className='text-white m-0 px-3 fw-normal font_18' >Fee</p>
                                             <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.to_capture.fee, 'amt')}</p>
                                          </div>
                                          <div className='d-flex'>
                                             <p className='text-white m-0 px-3 fw-normal font_18' >Penalty</p>
                                             <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.to_capture.penalty, 'amt')}</p>
                                          </div>
                                          {summary.to_capture.excess != 0 &&
                                             <div className='d-flex'>
                                                <p className='text-white m-0 px-3 fw-normal font_18' >Excess</p>
                                                <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.to_capture.excess, 'amt')}</p>
                                             </div>
                                          }
                                          {summary.to_capture.charges != 0 &&
                                             <div className='d-flex'>
                                                <p className='text-white m-0 px-3 fw-normal font_18' >Charges</p>
                                                <p className='text-white m-0 fw-bolder font_18' >{this.checkVal(summary.to_capture.charges, 'amt')}</p>
                                             </div>
                                          }
                                          {check_priv("loan","review_sync") &&
                                             <div className = "" >
                                                <button className='btn btn-primary btn-lg mx-3 px-4 ' type='button' onClick={this.handleSubmit} disabled={this.state.calling_api}>Submit</button>
                                             </div>
                                          }
                                    </div>
                                 </>

                              }

                           </Card.Body>
                        </Card>
                   }
                     </>
                  }                  
                  <div className={this.props.mode == "capture" ? this.state.sync_div_style : "show_div"}>                  
                     <Card >
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white",padding:15}}>
                           <h4 className="no-margin" style = {{fontSize : 20}}>Sync Payment Transaction</h4>
                        </Card.Header>
                        <Card.Body>
                           <div className="row justify-content-center py-2">
                              <label className="col-md-2 no-margin" ><b>Status after Sync</b></label>                                                                                        
                              <label className={`col-md-0 no-margin ${summary.new_status === "settled" && "text-green overdue_fontBold"} ${summary.new_status === "overdue" && "text-red overdue_fontBold excess_fieldText_blinker"}`}>{summary.new_status.toUpperCase()}  </label>                                
                                 <OverlayTrigger placement='top' trigger="hover" overlay={<Popover style={{padding:"5px",fontFamily: "Montserrat",width:'100%'}}> 
                                       <div>
                                          <div className='row no-margin'>
                                             <h6 className='col-6 reset-padding' style={{fontSize:10,margin:0,textAlign:'right'}}>Payment Status&nbsp;&nbsp;:&nbsp;&nbsp;</h6>                                       
                                             <h6 className='col-6 reset-padding' style={{fontSize:10,margin:0,textAlign:'left'}}>{title_case(summary.payment_status)}</h6>
                                          </div>
                                          {summary.payment_status == "review_pending" &&                                           
                                             <div className='row no-margin'>
                                                <h6 className='col-6 reset-padding' style={{fontSize:10,margin:0,textAlign:'right'}}>Review Reason&nbsp;&nbsp;:&nbsp;&nbsp;</h6>                                             
                                                <h6 className='col-6 reset-padding' style={{fontSize:10,margin:0,textAlign:'left'}}>{title_case(summary.review_reason)}</h6>
                                             </div>                                          
                                          }
                                       </div>
                                    </Popover>}>
                                    <FcInfo class="ml-2" size={13}/>
                                 </OverlayTrigger> 
                                                        
                              {/* <>
                                 <div className="form-group row justify-content-center reset-margin">
                                    <label className="col-sm-6 label__name " ><b>Payment Status</b></label>
                                    <label className="col-md-6 text-right pl-0">{title_case(summary.payment_status)} </label>  
                                 </div>
                                 {summary.payment_status == "review_pending" &&
                                    <div className="form-group row justify-content-center reset-margin mt-2 ">
                                       <label className="col-sm-6 label__name " ><b>Review Reason</b></label>
                                       <label className="col-md-6 text-right pl-0">{title_case(summary.review_reason)} </label>  
                                    </div>
                                 }
                              </> */}
                           </div>
                           <div className="row reset-margin justify-content-center py-2">
                              <label  className="col-md-3 no-margin text-center" ><b> Against FA Due</b></label>
                              <label  className="col-md-3 no-margin text-center"><b> Against Fee </b></label>
                              {this.props.data.acc_prvdr_code == 'RMTN' &&
                                 <label  className="col-md-3 no-margin text-center"><b> Against Charges </b></label>
                              }
                              <label  className="col-md-3 no-margin text-center"><b> Against Penalty</b></label>
                           </div>
                           <div className="row reset-margin justify-content-center py-2">
                              <div className="col-md-3">                                 
                                 <input type="checkbox" id="toggle_1" className="toggle visually-hidden" checked={this.state.FAcheck_box_checked} onChange={(event)=>this.handleReviewChange(event,"fa")} value="1" />
                                 <label className={`h-40 select_toogle reset_width reset_font_size text-right no-margin ${this.state.fa ? "bordered_green" : " "}`} for="toggle_1">{lbl_amt(summary.to_capture.principal, this.currency_code)} </label>                                 
                                 {this.state.fa ? 
                                    <h6 className = "text-center text-green"><b >(reviewed)</b></h6> 
                                    : 
                                    <h6 className = "text-center"><b >(review)</b></h6>
                                 }
                              </div>
                              <div className="col-md-3 ">
                                 <input type="checkbox" id="toggle_2" className="toggle visually-hidden" checked={this.state.FEEcheck_box_checked} onChange={(event)=>this.handleReviewChange(event,"fee")} value="2"/>
                                 <label className={`h-40 select_toogle reset_width reset_font_size text-right no-margin ${this.state.fee ? "bordered_green" : " "}`} for="toggle_2">{lbl_amt(summary.to_capture.fee, this.currency_code)} </label>
                                 {this.state.fee ?
                                    <h6 className = "text-center text-green"><b >(reviewed)</b></h6> 
                                    : 
                                    <h6 className = "text-center"><b >(review)</b></h6>
                                 }                                
                              </div>  
                              {this.props.data.acc_prvdr_code == 'RMTN' &&
                                 <div className="col-md-3">                                 
                                    <input type="checkbox" id="toggle_5" className="toggle visually-hidden" checked={this.state.CHARGEcheck_box_checked} onChange={(event)=>this.handleReviewChange(event,"charge")} value="5" />
                                    <label className={`h-40 select_toogle reset_width reset_font_size text-right no-margin ${this.state.charge ? "bordered_green" : " "}`} for="toggle_5">{lbl_amt(summary.to_capture.charges, this.currency_code)} </label>                                 
                                    {this.state.charge ? 
                                       <h6 className = "text-center text-green"><b >(reviewed)</b></h6> 
                                       : 
                                       <h6 className = "text-center"><b >(review)</b></h6>
                                    }
                                 </div>
                              }
                              <div className="col-md-3 ">                    
                                 <input type="checkbox" id="toggle_3" className="toggle visually-hidden" checked={this.state.PENcheck_box_checked} onChange={(event)=>this.handleReviewChange(event,"penalty")} value = "3" />                            
                                 <label className={`h-40 select_toogle reset_width reset_font_size text-right no-margin ${this.state.penalty ? "bordered_green" : " "}`} for="toggle_3">{lbl_amt(summary.to_capture.penalty, this.currency_code)}</label>
                                 {this.state.penalty ?
                                    <h6 className = "text-center text-green"><b >(reviewed)</b></h6> 
                                    : 
                                    <h6 className = "text-center"><b >(review)</b></h6>
                                 }
                              </div>
                           </div>
                           {this.state.excess_field &&
                              <>
                                 <div className="rule-form-group form-group row reset-margin justify-content-center">
                                       <label  className="col-md-3 label__name text-center"><b> Against Excess </b></label>
                                 </div>                           
                                 <div className = "row justify-content-center">
                                    <div className="col-md-3">
                                          <input type="checkbox" id="toggle_4" className="toggle visually-hidden" checked={this.state.EXCcheck_box_checked} onChange={(event)=>this.handleReviewChange(event,"excess")}  value = "4" /> 
                                          <div style={{position:'relative'}}>
                                             <div className='excess_fieldBlinker_box'>
                                                
                                             </div>
                                             <label className={`h-40 select_toogle reset_width reset_font_size text-right no-margin reset_position ${this.state.excess ? "bordered_green" : " "}`} for="toggle_4"> {lbl_amt(summary.to_capture.excess, this.currency_code)}</label>
                                          </div>
                                          {this.state.excess ?
                                             <h6 className = "text-center text-green"><b >(reviewed)</b></h6> 
                                             : 
                                             <h6 className = "text-center"><b >(review)</b></h6>
                                          }


                                    </div>
                                 </div>
                              </>
                           }
                        
                        </Card.Body>
                     </Card>
                  </div>
                  </form> 
            </div>              
             {this.state.processing && <p style={{color: "red",textAlign: "right"}}>{this.state.processing}</p>}
         </Modal.Body>
         <Modal.Footer>

            {this.props.id && this.props.id == 'repayment_txn' ? 
               <></>
               :
               <div className="col-md-9 reset-margin reset-padding">
                  {check_priv("loan","review_sync") &&
                     <button type="button"  className= {`btn btn-primary float-right mr-1 ${this.state.repayment_style}`} onClick={this.handleSubmit} disabled={this.state.calling_api}>Review & Sync</button>
                  }
                  {check_priv("loan","unlink_payment") && this.props.mode == "recon" &&
                     <button type="button" className= {`btn btn-danger float-right mr-1`} onClick={this.handleUnlinkPayment}  disabled={this.state.calling_api}>Unlink Payment </button>
                  }
                  <button type="button" className="btn btn-outline-primary float-right mr-5 " onClick={this.handleClose} data-dismiss="modal" aria-label="Close">Cancel</button>  
               </div>
            }
         </Modal.Footer>
      </Modal>
         
          )
    }
    
     render(){
      
         return(
             <>
               {this.WaiverModal(this.state.summary, this.state.waiver_field)}
               {this.ReturnExcessModal(this.state.summary)}
               {this.ManualReconModal(this.props.data)}
               {this.RequestWaiverModal(this.state.summary)}
               {this.RepaymentSummaryModal(this.state.summary)}

               {this.props.mode == 'recon' || this.props.mode == 'capture' ?
                  <input value={this.props.title} type="button" className="btn btn-primary repayment" id={this.props.data.id} variant="danger" onClick={(e) => {this.capture_repayment_summary(e, "capture_repayment")}} />
                  :
                  <input value={this.props.title} type="button" className="btn btn-primary repayment" id={this.props.data.id} variant="danger" onClick={this.handleManualReconModal} />
               }
             </>
                          
             );
     }
    
}
export default SyncPayment;
