//import React from 'react';
import {update, getNameList} from '../../../../actions/common_actions';
import moment from 'moment';
import CommonComponent from './../../CommonComponent';
import {get} from  '../../../../helpers/storage_helper';
import { get_path } from '../../../../helpers/common_helper';

class FlowComponent extends CommonComponent {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    if(process.env.REACT_APP_USE_ROOT_ONLY_DEV == undefined){
     this.root = "";
    }else{
    //  this.root = "http://localhost:8000";
    this.root = get_path('view_file')
    }
  }
   
  non_data_fields = ['div_style', 'show_button_style', 'edit_button_style', 'edit_mode']  ;

  handleChange = (event) => {
      this.setEditedFields(event.target.id)
      this.props.onComponentChange(event,this.props.id);
      this.handleChangeForEdit(event)    

    

  }

  setEditedFields = (field) => {
    let fieldExists = this.state.edited_fields ? this.state.edited_fields : [];

    if(!fieldExists.includes(field)){
        this.setState({
            edited_fields: [...fieldExists, field]
        });
    }
    
  }

  toUpperHandleChange = (event) =>{
    event.target.value = event.target.value.toUpperCase();
    this.handleChange(event);
  }
  
  handleChangeForEdit = (event) => {
    
    if(this.props.editable !== false && this.state.edit_mode){ 
      
        const id = event.target.id;
        const value = event.target.value;
        this.setState({[id]: value, 
                     save_button_style: "show_button"});

      }
  }



  handleElementReset = (element) => {
    this.handleElementChange(element, null);
  }


  handleElementChange = (element, value) => { 
  // To set changed value of the element in the 
  //state of parent container of the Component
    
    this.props.onComponentElementChange(this.props.id, element, value);
  }

 
  handleSelfChangeState = (event, value = null) => { 
  // To set changed values in Component's own state

    const id = event.target.id;
    if(value == null){
      value = event.target.value;
    }
      this.setState({[id]: value});
    
  }

  setChangeToState = (event) => { 
    
  // To set changed values in Component's class variable
    const comp_id = this.props.id;
    const id = event.target.id;
    const value = event.target.value; 
    var comp = this.state[comp_id];
    comp[id] = value;
    
    this.setState({[comp_id]: comp});
  }

  setChangeToProps = (event) => { 
    
  // To set changed values in Component's class variable
    const comp_id = this.props.id;
    const id = event.target.id;
    const value = event.target.value; 
    var comp = this.props[comp_id];
    comp[id] = value;
    
    this.setState({[comp_id]: comp});
  }


handleDateChange = (id, value) => {
    var event = {};
    value = moment(value).format("YYYY-MM-DD")
    event.target = {id, value};
    
    this.handleChange(event);
  }

  handleDateTimeChange = (id, value) => {
    var event = {};
    value = moment(value).format("YYYY-MM-DD HH:mm:ss")
    event.target = {id, value};
    
    this.handleChange(event);
  }
  

  handleSelfChange = (event) => { 
    
  // To set changed values in Component's class variable
    const comp_id = this.props.id;
    const id = event.target.id;
    const value = event.target.value;
    var comp = this[comp_id];
    comp[id] = value;
    this[comp_id] =  comp;  
    this.setState({[comp_id]: comp});  
  }

   handleSelfDateChange = (id, value) => {
    var event = {};
    value = moment(value).format("YYYY-MM-DD")
    event.target = {id, value};
    this.handleSelfChange(event);
  }

  

  handleMakeCopy = targetId => (event) => {
    // Copy data from one text box to another
         this.props.onComponentChange(event,this.props.id);
         const value = event.target.value;
         document.getElementById(targetId).value = value;
         //this.setState({[targetId]: value});

         this.props.data[targetId] = value;
  }

  load_dp_rel_mgr = (dp_code) => {
    this.setState({data_prvdr_rel_mgr_dd : null});
     const  associated_with =  "acc_prvdr";
     const associated_entity_code = get('acc_prvdr_code');
     
     this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with, associated_entity_code}, null, "kyc")
  }

load_flow_rel_mgr = (dp_code,addl_key=null, default_value = null) =>{
  this.setState({flow_rel_mgr_dd : null});
    var associated_with =  "flow";
    // const associated_entity_code = get('acc_prvdr_code');
    //const designation = 'relationship_manager'
    this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with},addl_key, "kyc", default_value);
}


load_flow_rel_mgrs_under_tm = (dp_code,addl_key=null) =>{
  this.setState({flow_rel_mgr_dd : null});
    var report_to = get('user_id');
    this.setOptionsToStateFromApi("rel_mgrs_under_tm", {...this.def_req, report_to},addl_key);
}

load_territory_manager = (dp_code,addl_key=null, default_value) =>{
  this.setState({flow_rel_mgr_dd : null});
  var associated_with =  "flow";
  this.setOptionsToStateFromApi("territory_mgr", {...this.def_req, associated_with},addl_key, 'admin', default_value);
}

load_sales_rep = (dp_code,addl_key=null) =>{
  this.setState({sales_rep_dd : null});
    var associated_with =  "flow";
    this.setOptionsToStateFromApi("sales_rep", {...this.def_req, associated_with},addl_key, "kyc");
}
  load_acc_prvdrs = () =>{
    this.setState({acc_prvdrs_dd : null});
    this.setOptionsListToStateFromAppApi('common', 'list', this.def_req , ['acc_prvdr_code', 'name'], "acc_prvdrs");
  }


  componentWillMount(){

      if(this.props.mode === 'view'){
        this.setState({div_style: "disable_div"});
        this.setState({edit_button_style: "show_button"});
        this.setState({save_button_style: "hide_button"});  
      }else{
        this.setState({div_style: ""});
        this.setState({edit_button_style: "hide_button"});
        this.setState({save_button_style: "hide_button"});  
      }
      if(this.props.mode === 'disabled'  || (this.props.mode === 'view' && this.props.editable === 'false')) {
          this.setState({div_style: "disable_div"});
          this.setState({button_style: "hide_button"}); 
      }

      if(this.props.cust_id){
        this.setState({cust_id: this.props.cust_id});
      }

       this.setState({edited_fields: []});
      
  } 

	 handleReset= (event)=>{
   
      this.props.onComponentReset(event,this.props.id);
   }
   
  handleEdit= (event)=>{
    this.setState({edit_mode : true});
    this.setState({edit_button_style: "hide_button"}); 
    this.setState({save_button_style: "disable_div"}); 
    this.setState({div_style : ""});
   
  }


handleSave = (event, post_action = null)=>{
     
    let request = this.getRequest();
    request[this.state.comp_name].source = 'profile'
    
      update(this.state.comp_name, this.req(request))
         .then((response) => {
          if(!response){return };
              if(response.status === "success"){
               
                
                this.setState({div_style: "disable_div"});
                this.setState({save_button_style: "hide_button"});
                this.setState({edit_button_style: "show_button"}); 
                alert(response.message);
                if(post_action == "reload"){ 
                 window.location.reload()
                }
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 
  }   

  getRequest= ()=>{
     
      var request = this.state;
      request.id = this.props.data.id;
      request = {[this.state.comp_name]: request}
      
      // TODO Code to remove non_data_fields from request
      return request;
  }


  }

 

export default FlowComponent;
