import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { softEnable } from "../../../actions/borrower_actions";
import { common_req } from "../../../helpers/common_helper";

type Props = {
  params: Record<string, Record<string, string>>;
  refetch: Function;
};

const SoftEnableUI = ({ params, refetch }: Props) => {
  // state
  const [openDialog, setopenDialog] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  // hook
  const theme = useTheme();

  // cb
  const closeDialogHandler = () => setopenDialog(false);

  const openDialogHandler = () => setopenDialog(true);

  const asyncSoftEnableHandler = async () => {
    setisLoading(true);
    const reqObj = common_req({} as any);
    const payload = {
      cust_id: params.row["Cust ID"],
      id: params.row.borrower_id,
      cust_name: params.row.first_name + " " + params.row.last_name,
      flag_id: params.row.flag_id,
    };
    const reqObjToApi = { ...reqObj, ...payload };
    softEnable(reqObjToApi).then((response) => {
      // call api for softenable the customer
      if (
        response &&
        (response.status == "success" || response.status === "error")
      ) {
        alert(response.message);
        setisLoading(false);
        setopenDialog(false);
        if (response.status == "success") refetch(); // refetch query for fresh data
      } else {
        setisLoading(false);
      }
    });
  };

  return (
    <>
      {/* dialog */}
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle>{"Soft Enable?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`By doing this action, ${params.row["first_name"]} ${params.row["last_name"]} will be soft enabled for 2 FAs or 15 days`}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: "15px" }}>
          <Button
            onClick={asyncSoftEnableHandler}
            autoFocus
            variant="contained"
            color="secondary"
            sx={{ minWidth: "75px" }}
          >
            {isLoading ? (
              <CircularProgress
                sx={{
                  "&.MuiCircularProgress-root": {
                    width: "22px!important",
                    height: "22px!important",
                  },
                }}
              />
            ) : (
              "Enable"
            )}
          </Button>
          <Button
            onClick={closeDialogHandler}
            variant="outlined"
            color="secondary"
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* button */}
      <Button
        variant="contained"
        color={
          params.row["Customer Status"] == "soft_enable"
            ? "warning"
            : "secondary"
        }
        onClick={
          params.row["Customer Status"] === "soft_enable"
            ? () => {}
            : openDialogHandler
        }
        disabled={
          params.row["Current Status"] !== "Disabled" &&
          params.row["Customer Status"] !== "soft_enable"
        }
        sx={{
          cursor:
            params.row["Customer Status"] === "soft_enable"
              ? "not-allowed !important"
              : "pointer",
        }}
      >
        {`Soft enable${
          params.row["Customer Status"] === "soft_enable" ? "d" : ""
        }`}
      </Button>
    </>
  );
};

export default SoftEnableUI;
