import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useFormContext } from "react-hook-form";

interface RhfDatePickerProps extends Omit<DatePickerProps<any>, "name"> {
  name: string;
  helperText?: string;
  type?: string;
}

const RhfDatePicker = ({
  name,
  helperText,
  type,
  ...other
}: RhfDatePickerProps) => {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
          <DatePicker
            {...field}
            onChange={(newValue) => {
              setValue(name, newValue, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error ? error?.message : helperText,
              },
              popper: {
                sx: {
                  zIndex: 100,
                },
              },
              desktopPaper: {
                sx: {
                  backgroundColor: "#111936",
                  backgroundImage: "none",
                  border: "solid 2px #919191",
                  borderRadius: "12px",
                  marginTop: "3px",
                  // overrides selected date button style
                  "& .MuiPickersDay-root.Mui-selected": {
                    backgroundColor: "#919191 !important",
                  },
                  // overrides selected month button style
                  "& .MuiPickersMonth-monthButton.Mui-selected": {
                    backgroundColor: "#919191 !important",
                  },
                  // overrides selected year button style
                  "& .MuiPickersYear-yearButton.Mui-selected": {
                    backgroundColor: "#919191 !important",
                  },
                },
              },
            }}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default RhfDatePicker;
