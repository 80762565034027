import React, {useEffect, useState} from "react";
import { Config, get } from "../../../helpers/storage_helper";
import { admin_path, call_fetch } from "../../../helpers/fetch_helper";
import { amount_currency_label, short, split_amount } from "../../../helpers/common_helper";
import CustomButton from "../component/CustomButton";
import { BsArrowUp, BsArrowDown} from "react-icons/bs";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import ConfirmModal from "../../common/component/ConfirmModal";



const DisbAccountSwitch = ()=>{



    // const acc_prvdrs = ['UEZM', 'UMTN', 'UATL']

    // const switchable_providers = ['UATL']

    const acc_prvdrs = Config('manual_switch_acc_prvdrs')[get('market').country_code]

    const switchable_providers = Config('switchable_acc_prvdrs')[get('market').country_code]

    const [AccPrvdr, setAccprvdr] = useState('UATL')
    const [Market, setMarket] = useState(get('market'))

    const [AccountDetails,setAccountDetails] = useState(null)
    const [RepaymentAccount, setRepaymentAccount] = useState(null)
    const [SwitchAccount ,setSwitchAccount] = useState(null)
    const [SwapAccounts, setSwapAccounts] = useState(null)
    
    const [CurrentDisbAccount, setCurrentDisbAccount] = useState(null)
    const [disbAccount, setDisbAccount] = useState(null)

    const [Loader, setLoader] = useState(false)
    const [isSwaped,setSwaped] = useState(false);
    const [swappedStates, setSwappedStates] = useState({float_switch : false , disbursement : false});
    const [swapAccount,setswapAccount] = useState(null);
    const [selectId, setSelectId] = useState(null);

    const [Accordian,setAccordian] = useState({});
    const [screenWidth,setscreenWidth] = useState(0);
    const [confirmMessage,setconfirmMessage] = useState(false);

    
    useEffect(()=>{
        get_disb_acc_details()
        setscreenWidth(window.screen.availWidth)
    },[AccPrvdr])



    const Header = ()=>{

        return acc_prvdrs.map((val)=>
            <div style={{ cursor:'pointer', opacity:AccPrvdr == val ? 1 : 0.6}} onClick={()=>handle_acc_prvdr_change(val)}>
                <img  style={{height:33, width:65}} src={`/img/${val}_logo_color.png`} className="img-fluid"/>
            </div>)

    }

    const handle_acc_prvdr_change = (acc_prvdr)=>{
        if(switchable_providers.includes(acc_prvdr)){
            setSelectId(null)
            setSwaped(false)
            setSwapAccounts(null) 
            setAccordian({})
            setAccprvdr(acc_prvdr)
        }
        else{
            alert(`Switching from ${AccPrvdr} to ${acc_prvdr} is currently not possible`)
        }
        
    }

    const get_disb_acc_details = async (alert_message =null)=>{
        const disbursement_accounts = [];
        const floatSwitch_accounts = [];
        const repayment_accounts = [];
        setLoader(true)
        setSwappedStates({})
        var req = {time_zone : Market.time_zone, country_code : Market.country_code, network_prvdr_code : AccPrvdr}
        await call_fetch(admin_path + '/account/get_disb_acc_details', req).then((response)=>{
            if(!response){
                setLoader(false)
                return;
            }
            if(response.status == 'success'){
               var data= response.data
                data.forEach(acc => {
                    if (acc.type === "disbursement") {
                        disbursement_accounts.push(acc);
                    } else if (acc.type === "float_switch" || acc.is_switch_account) {
                        floatSwitch_accounts.push(acc);
                    } else {
                        repayment_accounts.push(acc);
                    }
                });
                const modifiedArr = [...disbursement_accounts, ...floatSwitch_accounts, ...repayment_accounts];
                setAccountDetails(modifiedArr);
                setDisbAccount(disbursement_accounts)
                setCurrentDisbAccount(disbursement_accounts)
                setRepaymentAccount(repayment_accounts)
                setSwitchAccount(floatSwitch_accounts)
                if(alert_message!=null){
                    alert(alert_message)
                    setLoader(false)
                    setswapAccount(null)
                }else{
                    setLoader(false)

                }

                return

            }
            setLoader(false)
            return;
        })

    }

    const change_disb_acc = async (type=null)=>{

        if(type=="confirm"){
        setLoader(true)
        var req = {
            time_zone : Market.time_zone,
            country_code : Market.country_code,
            current_dib_acc_number: CurrentDisbAccount[0].acc_number,
            switch_acc_number : swapAccount.acc_number,
            network_prvdr_code : swapAccount.acc_prvdr_code
        }

        await call_fetch(admin_path + '/account/manual_switch_disb_line', req).then((response)=>{
            if(!response){
                setSwappedStates({float_switch : false , disbursement : false});
                setSelectId(null)
                setLoader(false)

                return
            }
            if (response.status == 'success'){
                setAccordian({})
                get_disb_acc_details(response.message)

            }

        })
        setSwaped(false)
    }
    }

    const toggle_modal =(type=null)=>{
        if(type =="confirm"){
            change_disb_acc("confirm")
        }
        setconfirmMessage(!confirmMessage)
    }


    const button_style = {

        backgroundColor : 'rgba(26, 32, 54, 1)',
        color : 'rgba(255, 255, 255, 1)',
        fontSize : '20px',
        fontWeight:'500'

    }

    const openAccordian = (idx,key) => {
      var key = key+"_"+idx
        setAccordian({
            ... Accordian,
             [key]: !Accordian[key]
    })

    }

    const handleCheckboxChange = (value, checked) => {

        const newState = {
            float_switch: false,
            disbursement: false,
            [value.type]: checked
        };
        setSwappedStates(newState);
    
        let toAddAcc = (value.type == 'float_switch' && checked == true) ? disbAccount : (value.type == 'disbursement' && !value.is_switch_account) ?  SwitchAccount : [];
      
        if(toAddAcc != null && toAddAcc.length > 0 ){
            setSwapAccounts([...RepaymentAccount, toAddAcc[0]])
        }else{
            setSwapAccounts(RepaymentAccount)
        }

        setSelectId(null)
        setSwaped(checked)
        setswapAccount(null)
        setCurrentDisbAccount([value])

    };

    const switchAccount=(val,idx,type,length=null)=>{
        return(
            <div className={` ${type=="repayment" ? "mx-3 mb-3" :" mb-4"} my-2 `}>

                {type!="repayment" &&
                    <p className="m-0 text-white"> {(val.type == "disbursement") ?"Default Disbursal Account":((val.type == "float_switch" )|| (val.is_switch_account))? "Switch Account": (AccountDetails[idx-1].type!="repayment")? `Repayment Account${RepaymentAccount.length > 1 ? "s" :""}`:""}</p>
                }

                <div className= {` ${type=="repayment" ? "row col-12 d-flex mx-0":""} swap_acc_div my-2 mt-4 px-3 py-3 rel_position`}>
                    {(type=="repayment") &&
                        <div className="col-1 pl-0">
                             <input type="radio" className="switch_acc_radio" id={`repayment_acc_${idx}`} checked={(selectId && selectId==idx) ? true : false}  onChange={(e)=>{setswapAccount(val);setSelectId(idx)}} name="repayment_acc" />

                        </div>
                    }

                    <div className={`${type=="repayment" ?"col-11":""}`}>
                        
                        <div className="row m-0 col-12 px-0 d-flex align-items-center justify-content-around">
                            <div className={`col-8 ${type=="disbursement" ? "pr-0":"px-0"} `}>

                                {((val.type=="disbursement" || val.type == 'float_switch') && type != 'repayment') &&
                                    <>
                                    <p className="enabled_bg m-0 px-2 py-1 text-white"> Enabled</p>

                                    <p className="mb-0 my-3 text-white agreementDateHeaderBoxdate"><b>{val.type == 'float_switch' ? "Switch Account" :val.is_switch_account ? "Disbursal & Switch Account" : "Disbursal Account" }</b> <span className="sizem"> (Primary)</span></p>
                                    </> 
                                }

                                {type=="repayment" && 
                                    <>
                                        <p className="mb-0 my-3 text-white agreementDateHeaderBoxdate"><b>{val.type == 'disbursement' ? "Disbursal" : val.type == 'float_switch' ? "Switch" : "Repayment" } Account </b></p>
                                    </>
                                }
                                <div className="d-flex align-items-center my-2">
                                    <img src={`/img/${val.acc_prvdr_code}${val.acc_prvdr_code == "UATL" ? "_disbursal_logo.png":"_logo_color.png"}`} style={{width:'51px',height:'23px'}}/>
                                
                                    <p className="mb-0 ml-2 text-white agreementDateHeaderBoxdate"><b> {val.acc_number} </b> </p>

                                </div>
                            </div>
                    
                            <div className={` ${(type=="repayment") ? "d-flex justify-content-end":"" } col-4 px-0`}>
                                {((val.type =="disbursement" || val.type == 'float_switch') && type != 'repayment') ?
                                    <div class="swap_button r" id="swap_button-9">
                                        <input type="checkbox" class="swap_checkbox" checked={swappedStates[val.type]} onChange={(e)=>{handleCheckboxChange(val, e.target.checked)}}/>
                                        <div class="toggle_left">
                                            <span></span>
                                        </div>

                                        <div class="toggle_right"> 
                                        <span className={swappedStates[val.type] ? "swap_text_left" :"swap_text_right"}> Swap</span>
                                        </div>
                                    </div>
                                    :

                                    <div className={` ${type!="repayment" ?"d-flex":"" } align-items-center justify-content-end`}>

                                        <p className="enabled_bg mb-0 mx-4 px-2 py-1 text-white"> Enabled</p>
                                        <div className={`${(type=="repayment") ?"d-flex justify-content-center ml-2 col-12 mt-2":""}`}>
                                            {Accordian[`${type}_${idx}`] ?
                                                <IoIosArrowDropup className="mr-2 pointer" onClick={()=>{openAccordian(idx,type)}} size={25} color="white" />
                                                :
                                                <IoIosArrowDropdown className="mr-2 pointer" onClick={()=>{openAccordian(idx,type)}} size={25} color="white" />
                                            } 
                                        </div>
                                    </div>
                                }
                            </div>
                    
                    
                         </div>

                        <p className="swap_acc_bottom disable_div mt-3"></p>
                        <div >
                            {(Accordian[`${type}_${idx}`] || (((val.type =="disbursement") || (val.type =="float_switch")) && type!='repayment')) &&
                                <>
                                    {(val.type!="repayment"  || (type=="repayment")) &&
                                        <div className={`${type=="disbursement" ? "mx-3":""}`}>
                                            <div className={`d-flex align-items-center`}>
                                                <p className="m-0 text-white sizem"> {(val.is_switch_account ||  val.type=="float_switch")? "Float Out": "Today’s Debit"}</p>
                                                {(val.type=="disbursement" || val.type=="float_switch") &&  (val.thresh_hold_limit != null && val.total_txn_amount >= val.thresh_hold_limit ) &&
                                                    <p className="disabled_status my-0 ml-5">Transaction limit reached </p>
                                                }
                                            </div>
                                            <p className="mb-0 mt-3 text-white agreementDateHeaderBoxdate"><b>{amount_currency_label(val.total_txn_amount, 1, Market.currency_code)} {val.txn_limit ? `/ ${amount_currency_label(val.txn_limit, 0, Market.currency_code)}` : ''} </b> 
                                            <span className="text-danger ml-3" style={{fontSize:'12px'}}>
                                            Debited <BsArrowUp color='red'/>

                                            </span> </p>
                                        
                                            { ((val.type=="disbursement" || val.type =="float_switch") && type != 'repayment' ) &&
                                                <p className="mb-0   text-white" style={{color:'#576882'}}>Swap the account when the disbursement limit reached </p>
                                            }
                                        </div>
                                    }
                                    <div className={`${type=="disbursement" ? "mx-3":""}`}>
                                        <p className="mb-0 mt-3 text-white  sizem"> {(val.is_switch_account || val.type=="float_switch") ? "Float In": "Today’s Credit"} </p>
                                        <p className="mb-0 mt-3 text-white agreementDateHeaderBoxdate"><b>{amount_currency_label(val.total_cr_amt, 1, Market.currency_code)} {val.txn_limit ? `/ ${amount_currency_label(val.txn_limit, 0, Market.currency_code)}` : ''} </b> 
                                        <span className="text-success ml-3" style={{fontSize:'12px'}}>
                                        Credited <BsArrowDown className="text-success"/>

                                            </span>
                                        </p>
                                    </div>
                                    <p className="swap_acc_bottom disable_div mt-3"></p>

                                </>
                            }
                        </div>
                    
                        <div className={`d-flex align-items-center mb-1 justify-content-between ${type=="disbursement" ?"mx-3":""}`}>
                            <p className="mb-1  text-white agreementDateHeaderBoxdate">Available Balance</p>

                            <p className="mb-1  text-white agreementDateHeaderBoxdate"><b>{split_amount(val.balance, Market.currency_code)}</b></p>

                        </div>
                    </div>
                </div>
                {type=="repayment" &&  (idx != length )&&
                
                    <p className="swap_acc_bottom disable_div mt-3 " style={{border:'1p solid '}}></p>

                }
            </div>
            )
    }

        
    

    

    return(
        <div className={confirmMessage?"disable_div":""}>
            <div className="d-flex  row justify-content-between" style={{marginTop:'40px'}}>
                <div className="d-flex clo-6 mx-2" style={{fontSize:"27px", fontWeight:500, color:"#DADCDF"}}>
                    Swap Account Lines
                </div>
                <div className="d-flex col-2 justify-content-between ml-auto">
                    {Header()}
                </div>
            </div>

            {
                Loader && Loader ? (
                    <div className=" d-flex justify-content-center" style={{marginTop:'30vh'}}>
                        <div className="acc_switch_loader" ></div>

                     </div>)
                     : 
                (
                    <div className={`mt-5 row d-flex  mx-0 `}>
                    <div className="col-md-12 col-sm-12 col-lg-5">
                        {AccountDetails && AccountDetails.map((val,idx)=>{
                                return(
                                switchAccount(val,idx,"disbursement")
                                )    
                        }
                        
                        )}
                    </div>
                    {isSwaped &&
                    <div className={`col-md-12 col-sm-12 py-2 col-lg-5 ${screenWidth > 992 ? "ml-5":""}`}>
                      <div className="disb_account_list mt-5">
                      <p className="mb-0 d-flex justify-content-center text-white disb_acc_bg w-100 px-3 py-3 agreementDateHeaderBoxdate "><b style={{fontSize:'20px'}}>{`Choose your ${swappedStates.float_switch ? 'switch' : 'disbursal'} account`}</b> </p>

                      {SwapAccounts && SwapAccounts.map((val,idx)=>{
                            // if(val.type == 'repayment' && val.switch_btn == true){
                                return(
                                    switchAccount(val,idx+1,"repayment",SwapAccounts.length-1   )
                                )
                            
                            // }
                        }
                        
                        )}


                        <div className="d-flex align-items-center  mb-3   my-1 justify-content-center">
                           <button type="button" class="btn btn-primary" disabled={swapAccount ? false:true} onClick={()=>{setconfirmMessage(true)}}>Submit</button>

                        </div>
                         {confirmMessage &&
                         <ConfirmModal alert_message={<span>Are you sure you want to switch your <b>{swapAccount.type == 'repayment' ? "Repayment Account" : (swapAccount.type == 'float_switch' ? "Float Switch" : "Disbursement")} ({swapAccount.acc_number})</b> to the {CurrentDisbAccount[0].type == 'float_switch' ? "switch" : "disbursal"} account?</span>} note ={" The default disbursal account resets daily."} is_toggle={(a)=>toggle_modal(a)} is_swap_acc={true}/>
                         
                         }

                      </div>
                    </div>
                    }
                    </div>
             
               ) 
            }
        </div>
    )

}


export default DisbAccountSwitch