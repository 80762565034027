import  FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {changeDisbursalStatus, getDisburseAttempt, retryDisbursal, searchHeldLoan, cancelLoan} from '../../actions/loan_actions';
import {format_date_tbl, format_date, title_case, lbl_amt, get_time_diff,Homeacc_prvdrLogoselector} from '../../helpers/common_helper';
import CaptureFailedDisbursal from '../loan/component/CaptureFailedDisbursal';
import DisburseLoan from '../loan/component/DisburseLoan';
import '../../styles/flow.css';
import {Link} from "react-router-dom";
import {dd_value, touch} from "../../actions/common_actions";
import { check_priv, Config } from '../../helpers/storage_helper';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FcInfo} from 'react-icons/fc'
import LoanDates from "../common/partials/LoanDates";
import * as moment from "moment";
import {TooltipLabel} from "../common/partial";
import {ExcesstimeAction} from "../common/partial";
import {GrRefresh} from "@react-icons/all-files/gr/GrRefresh";
import { get,set } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortAmtcommon, sortBizcommon, sortTimecommon } from '../../helpers/sortingHelpers';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import {Button } from 'react-bootstrap';
import { BasicReactTable } from '../../helpers/react_table';


class OnHoldList extends FlowComponent {
    state = {
        refresh: false,
        rowdatas:[],
        columndatas:[],
        copy:[],                  
        dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],     
        disb_manually: true,
        show_table: false,
    }

    handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount() {                               
        // setInterval(() => this.refreshList(),60000) 
        const fa_late1 = Config("fa_late1")
        const fa_late2 = Config("fa_late2")
        // this.setState(state=>state.fa_late1 = fa_late1)
        // this.setState(state=>state.fa_late2 = fa_late2)
        this.setState({fa_late1,fa_late2})
        const ls_market = get('market')
        const acc_prvdr_support_ussd = Config("acc_prvdr_support_ussd")
        this.setState({acc_prvdr_support_ussd}) 
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs) 
                set("acc_pvdrs",acc_prvdrs)                      
			}
		});	
        this.drawTable()                                      
    }    

    drawTable = async() => {        
        const market = get('market')
		const market_name = market.country_code
        // await this.server_time(this.time_zone)
        const loan_search_criteria = {status : "stalled", disburse_attempt: true, mode : 'search'};
        let response = await searchHeldLoan(this.req({loan_search_criteria, home_table : true}, true))
                                                 
            this.resp(response)
            if(!response){return};            
            if(response){
                this.setState(state => state.columndatas = this.columndatas()) 
                this.setState(state=>state.copy = [])
                this.setState(state => state.server_time = response.server_time)
                if (response.data.results.length > 0 || response.data.results.length === 0){
                    this.setState(state=>state.all_count = response.data.results.length)
                    this.setState(state => state.rowdatas = response.data.results)                                                                    
                    const newArr = get([market_name]+'_faPendingdisb') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_faPendingdisb')				
                    this.state.all_Acc_pvdrs&& this.state.all_Acc_pvdrs.map(each=>{																				
                        const count = response.data.results.filter(indiv => indiv.acc_prvdr_code === each)		
                        const length = count.length
                        this.setState(state=>state.filter_actions[each] = [{count:length},{status:newArr.includes(each)}],()=>{
                            this.dynClickhandler(this.state.filter_actions[each][1].status,each)									

                        })

                        this.setState(state=>state.filter_actions[each][0].count = length)
                    })
                }
            }
    }    

    dynClickhandler (key,val) {
        const market = get('market')
		const market_name = market.country_code											
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each=> each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state=>state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state=>state.all_Btn_status = true)
					}
				}				
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e=>{
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state=>state.dyn_Btn_selected = newOne)																															
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(newOne))
				this.setState(state=>state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each=>each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state=>state.filter_actions[val][1].status = key)
				}							
				this.setState(state=>state.all_Btn_status=false)													
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(this.state.dyn_Btn_selected))								
			}else{				
				this.state.all_Acc_pvdrs.map(e=>
					this.state.filter_actions[e][1].status = false
				)				
				this.setState(state=>state.dyn_Btn_selected = [])								
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify([]))
				this.setState(state=>state.all_Btn_status=key)
			}
		}					
        const newArr = get([market_name]+'_faPendingdisb')			
		let combinedArr = []
		newArr.map(each=> {			
			const filterArr = this.state.rowdatas.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)			
		})		
		this.setState(state=>state.copy = combinedArr)									
	}    

    getOverlayInfo (disb_status, loan) {
        let msg
        if (disb_status === "failed"){
            msg = "Float not transferred. App Support Will Retry"
        }
        else if (disb_status === "pending_disb_capture"){
            msg = "Float Transferred. Not Captured on Flow App. Customer Success will Capture"
        }
        else if (disb_status === "unknown"){
            msg = "App Support Will Process"
        }
        else if (disb_status === "sent_to_disbursal_queue"){
            msg = "Float will be transferred shortly"
        }
        else if (disb_status === null && !loan.customer_consent_rcvd){
            msg = "Customer has not sent the confirmation code"
        }
        return msg                
    } 

    sortAmt(a,b){          
        const curr_Amt = a.props.children
        const prev_Amt = b.props.children     
        return sortAmtcommon(curr_Amt,prev_Amt)      
    }
    sortBiz(a,b){        
        const currentBiz = a.props.children[0].props.full_text
        const prevBiz = b.props.children[0].props.full_text
        return sortBizcommon (currentBiz,prevBiz)                 
    }
    sortTime(a,b){
        const currentTime = a.props.data.loan_appl_date
        const prevTime = b.props.data.loan_appl_date
        const timeServer = this.state.server_time        
        return sortTimecommon(currentTime,prevTime,timeServer)
    }

    columndatas () {
        return [
            {
                Header : "FA ID & A/C Number",
                id: 'loan_doc_id',
                minWidth:140,
                sortable: false,
                accessor: row => <div>
                                    <Link className='d-block' to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link>
                                    <Link className='d-block' to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.acc_number} {row.branch == null ? " " : "("+title_case(row.branch)+")"}</Link>
                                 </div>
            },
            {
                Header : "Channel",
                id: "channel",  
                sortable: false,
                maxWidth:85,               
                accessor:row => {return <p className='text-light text-uppercase '>{row.channel?title_case(row.channel):''}</p>}
            },
            {
                Header : "ACC PRVDR",
                id: "acc_prvdr_code",  
                sortable: false,
                maxWidth:85,               
                accessor:row => <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/>
            },
            // {
            //     Header : "A/C Number",
            //     id: 'acc_number',
            //     accessor: row =><Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.acc_number}</Link>
            // },
            {
                Header : <>
                    Biz Name
                    <br/>& Mobile Number
                </> ,
                id: 'cust_mobile_num',
                sortMethod: (a,b) => this.sortBiz(a,b),
                minWidth:150,
                accessor: row => <div>
                                    <TooltipLabel className='d-block' full_text={row.biz_name} overlay={`${row.cust_name} / ${lbl_amt(row.loan_principal, this.currency_code)}`} no_reduce={true} />
                                    <label className='no-margin d-block'>{row.cust_mobile_num}</label>                                    
                                 </div> 
            },
            // {
            //     Header : "Biz Name",
            //     id:'biz_name',
            //     accessor: row => <TooltipLabel full_text={row.biz_name} overlay={row.cust_name} no_reduce={true} />
            // },
            // {
            //     Header : () => (<div className={'tableAmount_centerAlign'}>FA Amount</div>),
            //     id:'loan_principal',
            //     sortMethod: (a,b) => this.sortAmt(a,b),
            //     accessor:row => <div className='tableAmount_rightAlign'>{lbl_amt(row.loan_principal, this.currency_code)}</div>
            // },
            // {
            //     Header : "Total Attempts",
            //     id:'attempts',
            //     sortable: false,
            //     accessor: row => <div className={'tableAmount_centerAlign'}>{row.attempts}</div>
            // },
            
            {
                Header : "Applied & Approved",
                id: 'loan_appl_date',
                width:170,
                sortable: false,
                accessor: row => <div>
                                    <span >Applied :  <ExcesstimeAction data={row} timedata={this.state.server_time} item={"applied"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span><br/>
                                    <span >Approved :  <ExcesstimeAction data={row} timedata={this.state.server_time} item={"approved"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span>
                                 </div>
            },
            {
                Header : <div style={{paddingLeft:15}}>Attempted</div>,
                id: 'first_attempt_time',
                minWidth:190,
                maxWidth:190,                
                sortable: false,
                accessor: row => <div style={{paddingLeft:15}}>                                    
                                        {row.attempts > 1 ?
                                            <>
                                                {row.first_attempt_time &&
                                                        <>
                                                            <span >First Attempt : <ExcesstimeAction data={row} timedata={this.state.server_time} item={"first_attempt_time"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span><br/>
                                                        </>
                                                }
                                                {row.attempt_time &&
                                                        <>
                                                            <span >Last Attempt{" ("+row.attempts+")"} : <ExcesstimeAction data={row} timedata={this.state.server_time} item={"last_attempt_time"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span>
                                                        </>
                                                }
                                            </> :
                                            <span><span style={{display:row.attempts === 1 ? "inline-flex" : "none"}}>Attempted :</span><ExcesstimeAction data={row} timedata={this.state.server_time} item={"first_attempt_time"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span>
                                        }                                    
                                </div>
            },
            {
                Header : <>
                            FA Status 
                            <br/>Disbursal Status
                        </> ,
                id : 'status',
                minWidth:140,
                maxWidth:200,                
                sortable: false,
                accessor : row => 
                <div>
                    <span>{dd_value(row.status)}</span><br/>
                    
                    {(row.disbursal_status != null) ?
                        
                        <OverlayTrigger placement='auto' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "15px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}>{this.getOverlayInfo(row.disbursal_status, row)}</Popover>}><span>{title_case(row.disbursal_status || "not_initiated")} <sup><FcInfo size={14}/></sup></span></OverlayTrigger>
                    :
                        <>
                        </>
                    } 
                </div>
            },
            {
                Header : ()=>(<div className={'text-center'}>Action</div>),
                sortable: false,
                id:'btn',
                minWidth:120,
                accessor : row => this.buttonName(row)                
            },
            {
                Header : () => (<div className={'text-center'}>Action</div>),
                sortable: false,
                id:'bttn',
                minWidth:120,
                accessor : row => <div className='text-center'>
					{(check_priv('loan', 'home_screen_cancel_btn') && !['sent_to_disbursal_queue', 'in_progress', 'unknown'].includes(row.disbursal_status)) &&
						<button className='btn-primary no-padding' style={{margin:'10px !important'}} onClick={()=>this.cancelLoan(row.loan_doc_id)}>Cancel this FA</button>
            }
					</div>           
            }
        ]
    }

    cancelLoan=(loan_doc_id)=>{
		// alert("hoo");
		const resp = window.confirm("Are you sure to cancel FA?");
		if(resp == false){
			return;
		}
		const request = {"cancel_loan" : {'loan_doc_id' : loan_doc_id, 'ignore_consent_check' : true}};

		cancelLoan(this.req(request))
			.then((response) => {
				if(!response){return };
					if(response.status === "success"){   
                        this.refreshList()
					}	
			});
	}

    statusChange (event,loan) {


        let value = event.target.value
        let alert_msg = ""
        if (value === 'pending_disb_capture') {
            alert_msg = "Are you sure if the disbursal attempt is successful and there is a debit transaction to the customer found in the Data Provider A/C statement on " + loan.attempt_time+"?"
        } else if (value === 'failed') {
            alert_msg = "Are you sure the disbursal attempt got failed and there is no debit transaction to the customer found in the Data Provider A/C statement?"
        }
        if (window.confirm(alert_msg)) {
            const request = {loan_doc_id: loan.loan_doc_id, disbursal_status: value, loan_datas:loan}
            changeDisbursalStatus(this.req(request)).then((res) => {
                this.resp(res)
                if (res && res.status == 'success') {
                    if (res.message == 'its_already_exists'){
                        alert("Disbursal already exists in account statements.")
                    }
                    this.refreshList()
                }
            })

        }
    }

    refreshList = async() => {
            this.setState({refresh: true})            
            await this.drawTable()
    }


    buttonName = (loan) => {
        let value
        if(loan.disbursal_status == 'pending_disb_capture' && check_priv('loan','home_capture_disbursal')){

           value =  <CaptureFailedDisbursal id="disbursal_txn" data={loan} title="Disbursal Loan" />
        }
        else if(loan.disbursal_status == 'failed' || loan.status == 'pending_mnl_dsbrsl'){
           const loan_data = loan;
           loan_data.amount = loan.loan_principal;
           const ls_market = get('market')
           value =  
            <div className='text-center'> 
                <OverlayTrigger style={{ color: "#559f58" }} placement="top" trigger="click" overlay={(
                    <Popover style={{ textAlign: "center" }}>
                        <Popover.Content>
                            {loan.retry_disb_accs.map((retry_disb_acc) => {
                                console.log("retry_disb_acc")
                                console.log(retry_disb_acc)
                                return (
                                    <div style={{ marginBottom: '4%', marginTop: '3%' }}>
                                        {check_priv('loan', 'retry_disbursal') && (loan.status !== 'pending_mnl_dsbrsl') && (
                                            <input 
                                                style={{ marginBottom: '3%', backgroundColor: "#202940" }}
                                                id={loan.loan_doc_id}
                                                key={retry_disb_acc}
                                                type='submit'
                                                value={`Retry with ${retry_disb_acc}`}
                                                disabled={this.state.calling_api}
                                                onClick={(event) => { this.retryFA(loan.loan_doc_id, retry_disb_acc) }}
                                                className='btn btn-retry btn-sm'
                                            />
                                        )} 
                                    </div>
                                );
                            })}
                        </Popover.Content>
                    </Popover>
                )}>
                    <Button className={`btn btn-primary btn-sm ${check_priv('loan','disburse_manually') ? "mt-4":""}`} variant="success"> Retry </Button>
                </OverlayTrigger>
                {check_priv('loan','disburse_manually') && <DisburseLoan id="disbursal_txn" data={loan_data} parent="home" disabled={this.state.calling_api} onStatusChange={this.refreshList} onDisburseClick={(is_refresh_enabled) => this.setState({disb_manually: is_refresh_enabled})}/>}
            </div>
        }
        else if(loan.disbursal_status == 'unknown' && check_priv('loan','release') && loan.allow_status_change){
            value = <td><select style={{minHeight:'30px',width:90}} onChange={(event) => {this.statusChange(event,loan)}}>
                    <option disabled selected hidden className='text-white'>choose</option>
                    <option value="pending_disb_capture" className='text-white'>Pending Capture</option>
                    <option value="failed" className='text-white'>Failed</option></select></td>
        }

        return  value
    }

    retryFA = (loan_doc_id, retry_disb_acc = 'primary') => {
        if(this.state.calling_api){return}
        if(!window.confirm("Have you verified in the Data Provider's A/C statement if there is no debit transaction found related to this FA?\n\nPlease note that clicking OK will re-attempt Disbursal")){return}
        retryDisbursal(this.req({'loan_doc_id' : loan_doc_id, ap_n_int_type: retry_disb_acc}, true)).then((response) => {
            this.resp(response)
            this.refreshList()
        })


	}

    componentDidUpdate(prevProps, prevState, snapshot) {                              
        if((prevProps.refresh != this.props.refresh && this.state.disb_manually == true)){
            this.refreshList()
        }                     
    }            

    render(){                            
        return (        
            <Accordion>
                <Card>
                    <div className={`${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
                    <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                        <h4 className="card-title text-white tableHead_font title-bottom ">FAs Pending Disbursal</h4>
                        {this.state.rowdatas.length > 0 &&
                            <>
                                <div className='countREL card-title'>
                                    <button className={'filterBtns allBtn_size'} onClick={()=>this.dynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
                                    <div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
                                        <label>{this.state.all_count}</label>
                                    </div>
                                </div>									
                                {this.state.all_Acc_pvdrs.map((each,idx)=>									
                                    <div key={idx} className='countREL card-title'>																					
                                            <>
                                                <div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.dynClickhandler(!this.state.filter_actions[each][1].status,each)}>													  
                                                    <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img> 																	
                                                </div>
                                                <div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>													
                                                    <label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>																																
                                                </div>
                                            </>										
                                    </div>																		
                                )}  
                            </>
                        }                                     
                        <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
                    </div>
                    </div>
                    <div className="card-body no-padding">
                        {this.state.server_time &&
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                <div className={` react_common_table ${check_priv('loan','disburse_manually') ?"fas_disbursal_table":""}`}>
                                <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} pagination={true}    default_page_size={50}  defaultSorted={[{id:"loan_appl_date",desc:true}]} excessTimeaction={true} timeServer={this.state.server_time} appliedRowblink={false} approveRowblinktime={this.state.fa_late2} />  
                                </div>

                                    {/* <CommonReactTable showPag={false} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} 
                                    resizable={false} defaultPageSize={50} defaultSorted={[{id:"loan_appl_date",desc:true}]} excessTimeaction={true} timeServer={this.state.server_time} appliedRowblink={false} approveRowblinktime={this.state.fa_late2}/> */}
                                </Card.Body>
                            </Accordion.Collapse>
                        }
                    </div>	
                </Card>
            </Accordion>
        );

}


}
export default OnHoldList;
