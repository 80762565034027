import React from 'react';

import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import DatePicker from 'react-datetime';
import { BiCalendar, BiCheckCircle, BiGlobe } from "react-icons/bi";
import { AiFillAppstore } from "react-icons/ai";
import { lbl_date, title_case } from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import { format_date, Homeacc_prvdrLogoselector, person_name, lbl_amt, RoundOffSplitter } from '../../../helpers/common_helper';
import { RiCustomerService2Fill } from "react-icons/ri";
import CommonReactTable from "../../../helpers/commonReact_table";
import { churnReportSearch,churnReportDetails } from '../../../actions/churn_actions';
import {MdPerson} from "react-icons/md";
import { dd_value } from '../../../actions/common_actions';
import { BasicReactTable } from '../../../helpers/react_table';

class ChurnReports extends FlowComponent {

    constructor(props) {
        super(props);
        this.state = {
            no_data:false,
            churn_report_modal: false,
            row_data: [],
            criteria:{
            },
            cs_call_log:true,

           

        };
        this.datePickerStyles = {
            width: '16vw',
        };
    }

    componentWillMount() {
        this.setOptionsListToStateFromAppApi('common', 'list', this.def_req, ['acc_prvdr_code', 'name'], "acc_prvdrs")
        this.setOptionsToStateFromApi("cs_roster", { ...this.def_req })
        
    }

    get_input_handler(value, key) {

        let criteria={ ...this.state.criteria}
        criteria[key]=value

        this.setState({
            ...this.state,
            criteria,
        })
    }

    churn_report_submit_handler() {
        this.setState({loader:true})
        var call_log_type = this.state.cs_call_log ? "call_log" : "last_fa_paid_date"
        var req=this.state.criteria
        req["type"]= call_log_type
        req["filter_by"]=this.state.filter_by ? this.state.filter_by : "all"

        churnReportSearch(this.req({churn_criteria : req}))  
        .then((response)=>{
            this.setState({loader:false})
            if(!response){return}
            if(response.status=="success"){
               this.setState({row_data:response.data,column_data:this.column_data(),copy :response.data ,no_data:response.data.length>0 ?false:true})
            }
        })
      }

    column_data = () => {
        return [
            {
                Header: "Account Number",
                id: "acc_prvdr_code",
                sortable: false,
                accessor: row => <div className='d-flex align-items-center' style={{ paddingLeft: '20px' }}> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code} /> <p className='no-margin text-white' style={{ paddingLeft: '20px' }}> {row.acc_number}</p>  </div>,


            },
            {
                Header: "Customer ID",
                id: "cust_id",
                accessor: row => row.cust_id,
                Cell: (cell) => (<p className='cust_follow_text m-0' style={{ textAlign: "center" }}><Link to={"/borrower/indiv/view/" + cell.value} target="_blank" >{cell.value}</Link></p>),


            }, {
                Header: "Business Name",
                id: "biz_name",
                accessor: row => row.biz_name,
                Cell: (cell) => (<p className='cust_follow_text' style={{ textAlign: "center" }}>{title_case(cell.value)}</p>),


            },
            {
                Header: "Mobile Number",
                id: "mobile_num",
                accessor: row => row.mobile_num,
                Cell: (cell) => (<p className='cust_follow_text' style={{ textAlign: "center" }}>{title_case(cell.value)}</p>),


            },
            {
                Header: " Last FA Paid Date ",
                id: "last_loan_paid_date",
                accessor: row => row.last_loan_paid_date,
                Cell: (cell) => (<p className='cust_follow_text text-white' style={{ textAlign: "center" }}>{format_date(cell.value)}</p>),


            },
            {
                Header: "Last followed by",
                id: "csm_name",
                accessor: row => row.csm_name ? row.csm_name : 'NA',
                sortable: true,

                Cell: (cell) => (<p className='cust_follow_text' style={{ textAlign: "center" }}>{cell.value}</p>),


            },
            {
                Header: "No.of follow ups",
                id: "no_of_follow_up",
                accessor: row => row.follow_up_count ? row.follow_up_count : 'NA',
                sortable: true,

                Cell: (cell) => (<p className='cust_follow_text' style={{ textAlign: "center" }}>{cell.value}</p>),


            },
            {
                Header: <p className='text-center'>Status</p>,
                id: "status",
                accessor: row => row.status ? row.status : 'Not yet initiated',
                sortable: true,

                Cell: (cell) => (<p className='cust_follow_text' style={{ textAlign: "center" }}>{dd_value(cell.value,cell.value)}</p>),


            },
            {
                Header: "Reason",
                id: "reason",
                accessor: (row) => (
                    row.status != 'not_contacted'
                      ? <button className='btn product_takeup_submit px-4 py-3' onClick={() => this.ChurnReportDetailsHandler(row)}>Details</button>
                      : <button className='btn product_takeup_submit px-4 py-3' onClick={() => this.ChurnReportDetailsHandler(row)} disabled>Details</button>
                  ),
                  
                style: { textAlign: "center", fontSize: "13px" },

            },
        ]
    }
    ChurnReportDetailsHandler(data){
       
        var req={"id":data.id}
        churnReportDetails(this.req(req))
        .then((response)=>{
            
            if(!response){return}
            if(response.status=="success"){
                this.setState({reasons_arr:response.data, churn_report_modal: true })

            }
        })
    }
    handleClose=()=>{
        this.setState({churn_report_modal:false})
    }
    render() {
        return (
            <div>
                {this.state.churn_report_modal ?
                    
                    <Modal className='churn_report_modal' size='lg' show={this.state.churn_report_modal} onHide={this.handleClose} keyboard={false}>
                        <Modal.Header className="modal-header p-0 " style={{background:'#282d41'}}  >
                            <h4 class="modal-title py-2" id="exampleModalLabel">Follow Up Log</h4>
                            <div>
                                <button type="button" className="close d-flex align-items-center" onClick={this.handleClose}>
                                    <span aria-hidden="true" className='close_call_log'>&times;</span>
                                </button>
                            </div>
                        </Modal.Header>
                        <Modal.Body class="modal-body">
                            <div className={`m-3 p-3 churn_report_inner_border ${this.state.reasons_arr.length>5 ? "follow_up_log_scroll":""} `}>
                                {this.state.reasons_arr.length > 0 ?   (this.state.reasons_arr.reverse()).map((item, idx) => {
                                    return (
                                        <div className='p-3 mt-3' style={{ background: '#282d41', borderRadius: '10px' }}>
                                            <h2 className='text-white pl-3' style={{fontSize:'15px'}}> <b>{dd_value(item.reason,"cust_follow_up_reasons")}</b></h2>
                                            <div className='row d-flex justify-content-between m-0'>
                                                <div className='col-8 '>
                                                    <p className='m-0 text-white' >{item.remarks}</p>
                                                </div>
                                                <div className='col-3' style={{ alignSelf: 'end' ,fontSize:'11px'}}>
                                                    <p className='text-white m-0'>{format_date(item.followed_date)}</p>
                                                    <p className='text-white m-0'>{item.csm_name}</p>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }) : <p className='text-center'>No data found.</p>

                                }
                            </div>


                        </Modal.Body>
                    </Modal>
                    : ""
                }
                <h2 className='text-white mb-4 mt-3' > Search Churns</h2>
                <div className={`${this.state.churn_report_modal ?"disable_div":""}`}>
                <div className='my-3 mt-5 py-5 px-2 churn_report_card'>
                    <div className='row m-0 d-flex  justify-content-evenly'>
                        <div className='col-md-6'>
                            <div className='churn_report_inner_border py-4 px-4 my-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center' >

                                        <input type="radio" className='check_box_size pointer' id="cs_call_log" name="last_fa_paid" checked={this.state.cs_call_log ? true:false} onChange={(e) => { this.setState({cs_call_log:true}) }} />
                                        <label className="m-0 pl-2 pointer" for="cs_call_log">Last Follow up date </label>

                                    </div>
                                    <div className='d-flex align-items-center ml-4' >
                                        <input type="radio" className='check_box_size pointer' id="last_fa_paid" name="last_fa_paid" checked={!this.state.cs_call_log ? true:false} onChange={(e) => { this.setState({cs_call_log:false})}} />
                                        <label className="m-0 pl-2 pointer" for="last_fa_paid">Last FA Paid</label>
                                    </div>


                                </div>

                                <div className='d-flex align-items-center justify-content-between row col-12 mx-0 mt-2 p-0'>
                                    <div className='col-md-6 pl-0'>
                                        <div className="form-group  col-md-12 px-2 py-0 d-flex align-items-center row churn_report_date_picker " >

                                            <label className='col-1.5 no-margin d-flex p-0 churn_report_icon'   htmlFor='churn_report_start_date'>
                                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b></div>
                                            </label>

                                            <div className='col  p-0 py-1 pr-0'>
                                                <DatePicker
                                                    style={this.datePickerStyles}
                                                    isValidDate={(current) => {
                                                        const today = moment().startOf('day');
                                                        return current.isSameOrBefore(today, 'day');
                                                    }}
                                                    closeOnSelect={true} onChange={(value) => { this.get_input_handler(moment(value).format("YYYY-MM-DD"), "from_date") }} inputProps={{ placeholder: 'Start date', id: 'churn_report_start_date pl-left', name: 'start_date', autoComplete: 'off' }} dateFormat="DD MMM YYYY" timeFormat={false} required="required" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group  col-md-6 px-2 py-0 d-flex align-items-center row churn_report_date_picker" >

                                        <label className='col-1.5 no-margin d-flex p-0 churn_report_icon' htmlFor='  churn_report_end_date'>
                                            <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                            <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b> </div>
                                        </label>

                                        <div className='col  p-0 py-1 pr-0'>
                                            <DatePicker
                                                style={this.datePickerStyles}
                                                isValidDate={(current) => {
                                                    const today = moment().startOf('day');
                                                    return current.isSameOrBefore(today, 'day');
                                                }}
                                                closeOnSelect={true} onChange={(value) => { this.get_input_handler(moment(value).format("YYYY-MM-DD"), "to_date") }} inputProps={{ placeholder: 'End date', id: ' churn_report_end_date pl-left churn_report_start_date', name: 'start_date', autoComplete: 'off' }} dateFormat="DD MMM YYYY" timeFormat={false} required="required" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-md-6' style={{ alignSelf: 'center' }}>
                            <div className='row m-0'>
                                <div className='col-md-6 p-0 '>


                                    <div className="form-group mt-0  col-md-12 px-2 py-1 d-flex align-items-center row churn_report_date_picker no-border" >
                                        <label className='col-1.5 no-margin d-flex p-0 churn_report_icon '   htmlFor='churn_report_acc_prvdr'>
                                            <p className='m-0 pr-1'><BiGlobe size={20} /></p>
                                            <div className="churn_report_vl pr-2" ></div>
                                        </label>

                                        <div className='col  p-0 py-0 pr-0'>
                                            <select id="churn_report_start_date churn_report_acc_prvdr pl-left " className="form-control" onChange={(e) => { this.get_input_handler(e.target.value, "acc_prvdr_code") }} type="text" required="required">
                                                <option> Account Provider</option>
                                                {this.state.acc_prvdrs_dd}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group  mt-3 col-md-12 px-2 py-1 d-flex align-items-center row churn_report_date_picker no-border" >
                                        <label className='col-1.5 no-margin d-flex p-0 churn_report_icon '   htmlFor='churn_report_cs'>
                                            <p className='m-0 pr-1'><RiCustomerService2Fill size={20} /></p>
                                            <div className="churn_report_vl pr-2" ></div>
                                        </label>

                                        <div className='col  p-0 py-0 pr-0'>
                                            <select id="churn_report_start_date churn_report_cs pl-left" className="form-control" onChange={(e) => { this.get_input_handler(e.target.value, "cs_id") }} type="text" required="required">
                                                <option value=""> Customer Success</option>
                                                {this.state.cs_roster_dd}
                                            </select>
                                        </div>
                                    </div>


                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mt-0  col-md-12 px-2 py-1 d-flex align-items-center row churn_report_date_picker no-border" >
                                        <label className='col-1.5 no-margin d-flex p-0 churn_report_icon'   htmlFor='churn_status'>
                                            <p className='m-0 pr-1'><BiCheckCircle size={20} /></p>
                                            <div className="churn_report_vl pr-2" ></div>
                                        </label>

                                        <div className='col  p-0 py-0 pr-0'>
                                            <select id="churn_report_start_date churn_status pl-left" className="form-control" onChange={(e) => { this.get_input_handler(e.target.value, "status") }} type="text" required="required">
                                                <option> Status</option>
                                                { this.getSelectOptions("churn_status") }
                                            </select>
                                        </div>
                                    </div>

                                    <div className={`form-group mt-3  col-md-12 px-2 py-1 d-flex align-items-center row churn_report_date_picker no-border ${this.state.criteria.cs_id ? "" : "disable_div"}`} >
                                        <label className='col-1.5 no-margin d-flex p-0 churn_report_icon '   htmlFor='churn_filter'>
                                            <p className='m-0 pr-1'><AiFillAppstore size={20} /></p>
                                            <div className="churn_report_vl pr-2" ></div>
                                        </label>

                                        <div className='col  p-0 py-0 pr-0'>
                                            
                                            

                                            <select id="churn_report_start_date churn_filter pl-left" className="form-control" onChange={(e) => { this.get_input_handler(e.target.value, "filter_by") }} type="text" required="required">
                                                <option> Filter By</option>
                                                { this.getSelectOptions("churn_filter")}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='row mx-0 mt-3 my-3 mb-4 d-flex align-items-center justify-content-between'>
                        <div className='col-md-5'>
                            <div className="form-group mt-0  col-md-12 px-2 py-1 d-flex align-items-center row churn_report_date_picker no-border " >
                                <label className='col-1.5 no-margin d-flex p-0 churn_report_icon'   htmlFor='churn_report_input'>
                                    <p className='m-0 pr-1'><MdPerson size={20} /></p>
                                    <div className="churn_report_vl pr-2" ></div>
                                </label>

                                <div className='col  p-0 py-1 pr-0'>
                                    <input autoComplete='off' type="text" onChange={(e) => { this.get_input_handler(e.target.value, "search_text") }} className={`form-control `} id="churn_report_start_date churn_report_input pl-left" placeholder={'Search By Customer Name/ Customer Id/ Mobile Number '} required="required" />

                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 pr-5'>
                            <button type="button" class="btn btn-primary btn-lg btn-block"  onClick={() => { this.churn_report_submit_handler() }} disabled={(this.state.loader ? true : this.state.criteria.search_text ? false: (this.state.criteria.from_date && this.state.criteria.to_date) ? false : true)  }>search</button>

                        </div>
                    </div>
                </div>

                <div className='mt-3'>
                    {this.state.loader
                    ? <>
                     <p className='text-light mt-5 text-center'>Loading...</p>
                        <div className="product_takeup_loader"></div></>
                    :
                   <>
                    {this.state.row_data.length > 0 ?
                        <div className={`cust_next_follow_up mt-5  }`} >
                            <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
									<p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
								</div>
                            {/* <CommonReactTable className='border'  exportCSV={true} csvFile={"Churn Reports"} csvData={this.state.row_data} row_data={this.state.row_data} defaultSorted={[{ id: "next_follow_up_date", asc: true }]} column_data={this.state.column_data} FName="Customer Follow Up" global_search={false} showPag={this.state.row_data.length > 9 ? true : false} minRow={0} default_page_size={10} addBackground={true} searchData={[{...this.state.row_data,title:'Search Churns'}]}/> */}

                            <BasicReactTable row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.column_data}  pagination={this.state.row_data.length > 10} default_page_size = {10} export_csv={true} defaultSorted={[{ id: "next_follow_up_date", asc: true }]} searchData={[{...this.state.row_data,title:'Search Churns'}]} FName={"Churn Reports"} header_class='table_background_header' />  

                            

                        </div>
                        :
                        <>
                         {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}

                        </>
                    }
                    </> }

                </div>
                </div>


            </div>

        )
    }

}

export default ChurnReports;
